import dayjs from 'dayjs';
import { AvatarSVG } from 'assets/icons';
import { ucFirst } from 'helpers/functions/toUpperCase';
import HoldCard from '../../../atoms/HoldCard';
import UserStatusPin from '../../../atoms/UserStatusPin';
import * as S from './styles';
import { InsuranceInUserProfileType } from 'types/InsuranceType';

type Props = {
  row?: any;
  header: string;
};

const PatientTableData = ({ row, header }: Props) => {
  switch (header) {
    case 'Name': {
      return (
        <S.CellWrapper className="name">
          <S.Avatar>
            {row?.avatar ? <img src={row.avatar.url} alt="avatar" /> : <AvatarSVG />}
          </S.Avatar>
          {row?.profile?.firstName
            ? row?.profile.firstName + ' ' + row?.profile?.lastName
            : '-'}
          {row?.isHoldAppointment && (
            <HoldCard text="HA" reason={row?.isHoldAppointment} />
          )}
          {row?.isHoldBilling && <HoldCard text="HB" reason={row?.isHoldBilling} />}
        </S.CellWrapper>
      );
    }
    case 'Sex': {
      return row?.profile?.sex ? ucFirst(row?.profile.sex)?.slice(0, 1) : '-';
    }
    case 'Phone': {
      return row?.phone || '-';
    }
    case 'DOB': {
      return row?.profile?.dateOfBirth ? (
        dayjs(row?.profile?.dateOfBirth).format('MM/DD/YYYY')
      ) : (
        <>-</>
      );
    }
    case 'Insurance': {
      return (
        row.profile?.insurances?.filter(
          (insurance: InsuranceInUserProfileType) => insurance.isPrimary,
        )[0]?.primaryInsuranceCompany || '-'
      );
    }
    case 'LastVisitDate': {
      return row?.lastVisitDate ? dayjs(row?.lastVisitDate).format('MM/DD/YYYY') : '-';
    }
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin type={row?.isBlocked ? row?.isBlocked : row?.status} />
        </S.CellWrapper>
      );
    }
    case 'Search': {
      return (
        <S.SearchWrap>
          {row?.searchResult?.map((item: any, index: number) => {
            return item && item?.value ? (
              <S.Value key={index}>
                {item.value.slice(0, item.includesIdxFrom)}
                <span style={{ background: 'yellow' }}>
                  {item.value.slice(item.includesIdxFrom, item.includesIdxTo)}
                </span>
                {item.value.slice(item.includesIdxTo, item.value.length)}
              </S.Value>
            ) : (
              <S.Value>-</S.Value>
            );
          })}
        </S.SearchWrap>
      );
    }
    default:
      return '-';
  }
};

export default PatientTableData;
