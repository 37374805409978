import { useNavigate } from 'react-router-dom';
import { DigitalSVG, PDFBlockedSVG, PDFSVG } from '../../../../assets/icons';
import { getFileIcon } from '../../../../helpers/getFileIcon';
import { TemplateNameType } from '../../../../types/TemplateType';

export const getIconTemplate = (type: string, mimetype: string) => {
  if (type === TemplateNameType.DIGITAL) {
    return <DigitalSVG />;
  }
  if (type === TemplateNameType.PDF) {
    return <PDFBlockedSVG />;
  }
  if (type === TemplateNameType.CLIENT) {
    return getFileIcon(mimetype);
  }
};

const getBtnsTemplate = (type: string) => {};
