import { MutableRefObject, ReactNode, useEffect, useState } from 'react';
import { ClaimCardType, ClaimStatusType } from '../../../../../../types/InvoiceType';
import ClaimItem from '../ClaimItem';
import { getClaimConfig } from './config';
import * as S from './styles';
import { CircularLoader } from '../../../../../../components';
import { ClaimValues } from '../../../../../../types/AppointmentNewTypes';

type Props = {
  status: ClaimStatusType;
  children?: ReactNode;
  count?: number;
  data?: ClaimCardType[];
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  lastPage?: number;
  refetch: () => void;
  messageStartRef: MutableRefObject<null | HTMLDivElement>;
};

const ClaimColumn: React.FC<Props> = ({
  status,
  children,
  count,
  data,
  isLoading,
  page,
  setPage,
  lastPage,
  messageStartRef,
  refetch,
}) => {
  const { headercolor } = getClaimConfig(status);
  const { title } = getClaimConfig(status);

  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  return (
    <S.Wrapper>
      <S.Header color={headercolor}>
        <S.Text>
          {title}
          <S.Span>
            ({isLoading ? <CircularLoader color="#fff" size={8} /> : (count ?? 0)})
          </S.Span>
        </S.Text>
      </S.Header>
      <S.Body>
        {isLoading && (
          <S.Loader>
            <CircularLoader color="#0084B1" />
          </S.Loader>
        )}
        <div ref={messageStartRef}></div>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            const isLast = index === data.length - 1 && lastPage && page < lastPage;
            return (
              <div
                key={`${item.id}_${index}`}
                ref={isLast ? setObserverTarget : undefined}
              >
                <ClaimItem claimInformation={item} refetch={refetch} />
              </div>
            );
          })}
      </S.Body>
    </S.Wrapper>
  );
};

export default ClaimColumn;
