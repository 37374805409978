import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Wrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  margin-right: 15px;

  @media ${devices.xs} {
    background: none;
  }
`;

export const BlockContent = styled.div`
  margin-top: 23px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ${devices.xs} {
    &.hide {
      display: none;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  margin: 22px 36px 10px 23px;
  display: flex;
  flex-direction: column;

  @media ${devices.xs} {
    &.hide {
      display: none;
    }
  }
`;

export const Items = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 29px 62px;
`;

export const Item = styled.div`
  width: 208px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 4px;
`;

export const ItemArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ItemValue = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 30px 28px 28px;
  margin-top: 13px;
  border-top: 0.5px solid var(--line, #d7d7d7);

  @media ${devices.xs} {
    display: none;
    margin-top: 0px;
    border-top: none;
    &.hide {
      display: inherit;
    }
  }
`;

export const RowText = styled.div`
  margin-top: 5px;
  width: 600px;
  word-break: normal;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 100%;
    &.hide {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 120px;

  @media ${devices.xs} {
    width: 100%;
    margin-top: 12px;
    &.hide {
      margin-top: -20px;
    }
  }
`;

export const FlexContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media ${devices.xs} {
    justify-content: center;
    flex-direction: column;
  }
`;
