import { useEffect, useState } from 'react';
import { ConfirmBtnSVG, SendSurveyWhiteSVG } from '../../../assets/icons';
import Button from '../../atoms/Button';
import SearchInput from '../../atoms/SearchInput';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import DatePicker from '../../atoms/DatePicker';
import dayjs from 'dayjs';
import AlertItem from '../../atoms/Alert';
import ScrollContainer from '../../molecules/ScrollContainer';
import MailCard from './components';
import CheckBox from '../../atoms/CheckBox';
import {
  useMailListGetQuery,
  useMailListSendMutation,
  useMailListUpdateMutation,
} from '../../../store/api/mailconfirmation/mailConfirmationApi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { MailConfirmation, MailConfirmationLite } from '../../../types/MainTypes';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setMessage } from '../../../store/slices/message';
import LinearLoader from '../../atoms/LinearLoader';
import CircularLoader from '../../atoms/CircuralLoader';
import { Dialog, SuccessDialog } from '../..';

const ConfirmationMailing = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [date, setDate] = useState(dayjs());

  const getMailList = useMailListGetQuery({
    clinicId: selectedClinic.id,
    expectedSendDate: date,
    q: debouncedSearch,
  });

  const [updateMailList, statusUpdateMailList] = useMailListUpdateMutation({});
  const [sendMailList, statusSendMailList] = useMailListSendMutation({});

  const [defaultState, setDefaultState] = useState<Array<MailConfirmationLite>>([]);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (getMailList.currentData) {
      setDefaultState([]);
      getMailList.currentData?.map((item: MailConfirmation) => {
        setDefaultState(prev => [
          ...prev,
          {
            id: item.id,
            isNeedToNotify: item.isNeedToNotify,
            event: item.event,
            isNotified: item.isNotified,
          },
        ]);
      });
    }
  }, [getMailList]);

  const handleChangeCheckbox = (id: number) => {
    const newArr = defaultState.map(item => {
      if (item.id === id) {
        return { ...item, isNeedToNotify: !item.isNeedToNotify };
      } else {
        return item;
      }
    });
    setDefaultState(newArr);
    setIsDirty(true);
  };

  const handleSaveUpdates = () => {
    setIsDirty(false);
    updateMailList({ rows: defaultState })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Successfully updated',
            type: 'success',
          }),
        );
      })
      .catch(err => {
        dispatch(
          setMessage({
            message: err.data.message,
            type: 'error',
          }),
        );
      });
  };

  const handleSend = () => {
    sendMailList({
      id: selectedClinic.id,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Successfully sent',
            type: 'success',
          }),
        );
      })
      .catch(err => {
        dispatch(
          setMessage({
            message: err.data.message,
            type: 'error',
          }),
        );
      });
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <S.Wrapper>
      <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(!isOpenDialog)}>
        <SuccessDialog
          helperText="Please be aware that unchecked Users will not be notified and it will not be possible to change the selection after sending. "
          successText="Confirm"
          onClose={() => setIsOpenDialog(!isOpenDialog)}
          titleText="Are you sure you want to send confirmations?"
          onSuccess={handleSend}
        />
      </Dialog>
      <S.SubtitleBar>
        <S.SubtitleText>Confirmation mailing</S.SubtitleText>
        <S.SubtitleItems>
          <S.DatePickerWrapper>
            <DatePicker
              // disabled={visits.isFetching || visits.isLoading}
              label=""
              disableFuture
              value={date}
              onChange={value => {
                setDate(dayjs(value));
              }}
            />
          </S.DatePickerWrapper>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper className="xs">
            <Button
              text="Send"
              backgroundColor="#475BA3"
              disabled={isDirty}
              onClick={() => setIsOpenDialog(!isOpenDialog)}
            >
              <SendSurveyWhiteSVG />
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper className="xs">
            <Button text="Save" disabled={!isDirty} onClick={handleSaveUpdates}>
              <ConfirmBtnSVG className="smallsvg" />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <AlertItem
          text="This notifications will be send at 12 PM"
          type="warning"
          $isDefault
          $isHideButton
        />
        <S.MailWrapper>
          {statusUpdateMailList.isLoading ? (
            <S.LinearLoader>
              <CircularLoader color="#0084B1" />
            </S.LinearLoader>
          ) : (
            <S.MailContainer>
              {defaultState?.map((item: MailConfirmationLite) => {
                return (
                  <MailCard
                    mail={item}
                    key={item?.id}
                    onChangeCheckbox={() => handleChangeCheckbox(item.id)}
                  />
                );
              })}
            </S.MailContainer>
          )}
        </S.MailWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default ConfirmationMailing;
