import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { generateStylesConfig } from './generateStylesConfig';

export const ServicesHeaderPart = () => {
  const s = generateStylesConfig();

  return (
    <>
      <View style={s.redRow}>
        <View style={[s.column, { alignItems: 'flex-start' }]}>
          <Text style={[s.subTitle, { fontWeight: 500, paddingBottom: 0 }]}>
            24. A. DATE(S) OF SERVICE
          </Text>

          <View style={[s.row]}>
            <View style={[s.column, { alignItems: 'center', width: 58 }]}>
              <Text style={[s.subTitle, { padding: '1 0' }]}>FROM</Text>
              <View style={[s.row, { justifyContent: 'space-evenly', width: 58 }]}>
                <Text style={[s.subTitle, { padding: 0 }]}>MM</Text>
                <Text style={[s.subTitle, { padding: 0 }]}>DD</Text>
                <Text style={[s.subTitle, { padding: 0 }]}>YY</Text>
              </View>
            </View>

            <View style={[s.column, { alignItems: 'center', width: 58 }]}>
              <Text style={[s.subTitle, { padding: '1 0' }]}>TO</Text>
              <View style={[s.row, { justifyContent: 'space-evenly', width: 58 }]}>
                <Text style={[s.subTitle, { padding: 0 }]}>MM</Text>
                <Text style={[s.subTitle, { padding: 0 }]}>DD</Text>
                <Text style={[s.subTitle, { padding: 0 }]}>YY</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[s.column, s.cell, { width: 25, alignItems: 'flex-start' }]}>
          <Text style={[s.subTitle, { padding: 0, textAlign: 'center', width: 25 }]}>
            B
          </Text>
          <Text style={[s.subTitle, { padding: '0 1', textAlign: 'center', width: 25 }]}>
            PLACE OF YY SERVICE
          </Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 25,
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            },
          ]}
        >
          <Text style={[s.subTitle, { textAlign: 'center', width: 25 }]}>C</Text>
          <Text style={[s.title, { textAlign: 'center', width: 25 }]}>EMG</Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 144,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.subTitle, { fontWeight: 500, padding: '1 0 1 2.5' }]}>
            D. PROCEDURES, SERVICES, OR SUPPLIES
          </Text>
          <Text style={[s.subTitle, { fontWeight: 500, padding: '0 0 0 2.5' }]}>
            (Explain Unusual Circumstances)
          </Text>
          <View style={[s.row, { width: 144 }]}>
            <Text style={[s.subTitle, { width: 54, textAlign: 'center' }]}>
              CPT/HCPCS
            </Text>
            <Text style={[s.subTitle, { width: 90, textAlign: 'center' }]}>MODIFIER</Text>
          </View>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 45,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.title, { textAlign: 'center', padding: '1 0', width: 45 }]}>
            E
          </Text>
          <Text style={[s.subTitle, { textAlign: 'center', width: 45, fontWeight: 500 }]}>
            DIAGNOSIS POINTER
          </Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 57,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.title, { textAlign: 'center', padding: '1 0', width: 57 }]}>
            F
          </Text>
          <Text style={[s.subTitle, { textAlign: 'center', width: 57, fontWeight: 500 }]}>
            $ CHARGES
          </Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 45,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.title, { textAlign: 'center', padding: '1 0', width: 45 }]}>
            G
          </Text>
          <Text style={[s.subTitle, { textAlign: 'center', width: 45, fontWeight: 500 }]}>
            DAYS OR UNITS
          </Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 45,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.title, { textAlign: 'center', padding: '1 0', width: 45 }]}>
            H
          </Text>
          <Text
            style={[
              s.subTitle,
              { textAlign: 'center', padding: 0, width: 45, fontWeight: 500 },
            ]}
          >
            EPSDT
          </Text>
          <Text
            style={[
              s.subTitle,
              { textAlign: 'center', padding: 0, width: 45, fontSize: 3 },
            ]}
          >
            FAMILITY PLAN
          </Text>
        </View>

        <View
          style={[
            s.column,
            s.cell,
            {
              width: 57,
              alignItems: 'flex-start',
            },
          ]}
        >
          <Text style={[s.title, { textAlign: 'center', padding: '1 0', width: 57 }]}>
            L
          </Text>
          <Text style={[s.subTitle, { textAlign: 'center', padding: 0, width: 57 }]}>
            ID.
          </Text>
          <Text style={[s.subTitle, { textAlign: 'center', padding: 0, width: 57 }]}>
            QUAL.
          </Text>
        </View>
      </View>
    </>
  );
};
