import { combineReducers, configureStore } from '@reduxjs/toolkit';
import showDrawReducer from './slices/drawerSlice';
import messageReducer from './slices/message';
import notificationReducer from './slices/notification';
import authReducer from './api/auth/authSlice';
import selectedClinicReducer from './slices/selectedClinic';
import chatReducer from './slices/chatSlice';
import hoverReducer from './slices/hoverMenuSlice';
import dirtyReducer from './slices/isDirtyFormSlice';
import objectForUpdReducer from './slices/formikForUpdateStepperSlice';
import { api } from './api/api';

const reducer = combineReducers({
  drawer: showDrawReducer,
  auth: authReducer,
  message: messageReducer,
  notification: notificationReducer,
  selectedClinic: selectedClinicReducer,
  chats: chatReducer,
  isHover: hoverReducer,
  isDirty: dirtyReducer,
  objectForUpd: objectForUpdReducer,
  [api.reducerPath]: api.reducer,
});

const store = configureStore({
  devTools: (process.env.NODE_ENV || '').trim() !== 'production',
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
