import { Fade, TableCell, TableRow, Tooltip } from '@mui/material';
import { BillingAccTableType } from '../../../../../../../../types/AccTableTypes';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const AccTableHeadDoctorNames: React.FC<Props> = ({ data, index }) => {
  return (
    <TableRow>
      {index === 0 && <TableCell className="white white-header " />}
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#0084B1',
              fontFamily: 'Inter',
            },
          },
        }}
        title={data.doctorName}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 100 }}
      >
        <TableCell colSpan={data.totalInsurancesCount}>{data.doctorName}</TableCell>
      </Tooltip>
    </TableRow>
  );
};

export default AccTableHeadDoctorNames;
