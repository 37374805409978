import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);

  @media ${devices.xs} {
    width: 345px;

    /* height: calc(100vh - 210px); */
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  @media ${devices.xs} {
    /* height: calc(100vh - 210px); */

    width: 345px;
  }
`;

export const VisitReasons = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const ArticleWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
  /* 
  @media ${devices.xs} {
    width: 345px;
  } */
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;

  & svg {
    position: absolute;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 210px);
  margin-top: 15px;

  @media ${devices.xs} {
    height: 100%;
    /* height: calc(100vh - 100px); */
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const SelectInputOutputText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 538px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const PatientInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;

  @media ${devices.xs} {
    width: 320px;
  }
`;

export const PatientInputs = styled.div`
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 21px;

  @media ${devices.xs} {
    width: 320px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const MenuItemWrapper = styled.div``;

export const SelectInputValue = styled.div`
  font-family: Inter;
  font-size: 14px;
`;

export const RequiredSpan = styled.span`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #f4f4f4;

  @media ${devices.xs} {
    width: 100%;
    background: none;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 18px;
    /* margin-right: 20px; */
  }

  @media ${devices.xl} {
    /* position: relative; */
  }
`;

export const BtnWrapper = styled.div`
  margin-right: 18px;
  width: 174px;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    width: 150px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  &.payByInsurance {
    margin-top: 15px;
  }

  @media ${devices.xs} {
    gap: 5px;
    max-width: 320px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const FirstTimeVisit = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  gap: 5px;
  min-width: 48%;
  max-width: 48%;
`;

export const HelperText = styled.div`
  color: var(--icon, #dd8500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LabelText = styled.div`
  display: flex;
  gap: 5px;
`;

export const FirstVisitContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SelectInputPay = styled.div`
  width: 240px;
`;

export const PayByInsurance = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
`;
