import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import * as S from '../../styles';
import { MouseEvent, useState } from 'react';
import { Clinics } from '../../../../../../types/ClinicTypes';
import { getFileIcon } from '../../../../../../helpers/getFileIcon';
import {
  DeleteRedBtnSVG,
  Edit24pxSVG,
  EditBtnMinimalSVG,
  EditBtnSVG,
  ListCheckSVG,
  PlaySVG,
  Print24pxSVG,
  PrintSVG,
} from '../../../../../../assets/icons';
import CreateTemplateDialog from '../CreateTemplateDialog';
import { CircularLoader, Dialog, SuccessDialog } from '../../../../../../components';
import { getIconTemplate } from '../../getIconTemplate';
import { TemplateNameType, TemplateType } from '../../../../../../types/TemplateType';
import { getRouteTemplate } from '../../configTemplate';
import Play from './Play';
import {
  useDeleteTemplateMutation,
  useTemplateEmptyPrintPDFMutation,
  useTemplatesGetByIdMutation,
} from '../../../../../../store/api/templates/templateApi';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import SettingsTemplate from '../SettingsTemplate';
import Print from './Print';
import { Roles } from '../../../../../../types/Roles';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';

type Props = {
  row: any;
  header: string;
};

const TemplateData: React.FC<Props> = ({ row, header }) => {
  const navigate = useNavigate();
  const [isShowCreateDialog, setIsShowCreateDialog] = useState(false);
  const userInfo = useAppSelector(state => state.auth);

  const handleShowCreateDialog = () => {
    setIsShowCreateDialog(!isShowCreateDialog);
  };

  const [printEmptyPDF, emptyPDFStatus] = useTemplateEmptyPrintPDFMutation({});
  const [deleteTemplate] = useDeleteTemplateMutation({});

  const [isShowPrintDialog, setIsShowPrintDialog] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isShowSettings, setIsShowSettings] = useState(false);
  const handleShowPrintDialog = () => {
    setIsShowPrintDialog(!isShowPrintDialog);
  };

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [getTemplate, getTemplateStatus] = useTemplatesGetByIdMutation({});
  const dispatch = useAppDispatch();

  switch (header) {
    case 'TemplateName':
      return (
        (
          <S.CellRowWrapper className={'name'}>
            {row?.type && getIconTemplate(row?.type, row?.file?.name.slice(-3))}
            {row?.name}
            {/* {getFileIcon(row?.file?.name.slice(-3))} {row?.name} */}
          </S.CellRowWrapper>
        ) || '-'
      );
    case 'ClinicName': {
      return row?.clinics ? (
        <S.CellColumnWrapper>
          {row.clinics.map((item: Clinics) => (
            <S.ColumnText key={item?.id}>{item?.name}</S.ColumnText>
          ))}
        </S.CellColumnWrapper>
      ) : (
        '-'
      );
    }
    case 'CreatedBy':
      return (
        row?.createdBy?.profile?.firstName + ' ' + row?.createdBy?.profile?.lastName ||
        '-'
      );
    case 'Date':
      return dayjs(row?.createdAt).format('MM/DD/YYYY h:mm A') || '-';
    case 'Note':
      return <S.CellRowWrapper className="note">{row?.note || '-'}</S.CellRowWrapper>;
    case 'Settings':
      return row?.type !== TemplateNameType.CLIENT ? (
        <>
          <Dialog
            open={isShowSettings}
            onClose={() => setIsShowSettings(!isShowSettings)}
          >
            {getTemplateStatus.isLoading ? (
              <S.Loader>
                <CircularLoader color="#0084B1" />
              </S.Loader>
            ) : (
              <SettingsTemplate
                data={getTemplateStatus.data}
                onClose={() => setIsShowSettings(!isShowSettings)}
              />
            )}
          </Dialog>

          <S.LinkWrapper
            onClick={() => {
              setIsShowSettings(true);
              getTemplate({
                id: row.id,
              });
            }}
          >
            <ListCheckSVG color="#0084B1" />
            Assign Template
          </S.LinkWrapper>
        </>
      ) : (
        '-'
      );
    case 'Btns': {
      return (
        <S.Btns>
          <Dialog open={isShowCreateDialog} onClose={handleShowCreateDialog}>
            <>
              <CreateTemplateDialog onClose={handleShowPrintDialog} data={row} />
            </>
          </Dialog>
          <Dialog open={isShowPrintDialog} onClose={handleShowPrintDialog}></Dialog>
          <Dialog
            open={isShowConfirmDialog}
            onClose={() => setIsShowConfirmDialog(!isShowConfirmDialog)}
          >
            <ConfirmDeleteDialog
              onClose={() => setIsShowConfirmDialog(!isShowConfirmDialog)}
              titleText="template"
              onSuccess={() =>
                deleteTemplate({ id: row.id })
                  .unwrap()
                  .then(res => {
                    dispatch(
                      setMessage({
                        message: 'Template was successfully created',
                        type: 'success',
                      }),
                    );
                  })
                  .catch(error => {
                    dispatch(setMessage({ message: error.data.message, type: 'error' }));
                  })
              }
            />
          </Dialog>
          {row?.type === TemplateNameType.CLIENT ? <S.Empty /> : <Play row={row} />}

          <Print row={row} />

          {row?.type === TemplateNameType.CLIENT &&
          userInfo.role === Roles.SUPER_ADMIN ? (
            <>
              <Edit24pxSVG onClick={handleShowCreateDialog} />
              {userInfo.role === Roles.SUPER_ADMIN && (
                <DeleteRedBtnSVG onClick={() => setIsShowConfirmDialog(true)} />
              )}
            </>
          ) : (
            <>
              <S.Empty />
              {userInfo.role === Roles.SUPER_ADMIN && <S.Empty />}
            </>
          )}
        </S.Btns>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default TemplateData;
