import { PlaceHolderSuccessSVG } from '../../../../../assets/icons';
import * as S from './styles';

const AppointmentFinishedPlaceholder = () => {
  return (
    <S.Content>
      <PlaceHolderSuccessSVG />
      <S.Text>Appointment is finished! The forms you can find in user profile. </S.Text>
      <S.Text>
        <S.Span>NOTE:</S.Span> Please, don`t forget to change status of this Appointment
        to Completed
      </S.Text>
    </S.Content>
  );
};

export default AppointmentFinishedPlaceholder;
