import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { showDrawer } from 'store/slices/drawerSlice';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import { DrawerMode } from 'types/DrawerMode';
import { Roles } from 'types/Roles';
import { AppointmentStatus } from 'types/StatusTypes';
import * as S from './styles';
import { Event } from 'react-big-calendar';

type Props = {
  appointment: AppointmentsNew;
  visit: Visits;
  setOpenDialog: React.Dispatch<React.SetStateAction<Event | null>>;
  fullEvent: Event;
};

const ClickEventMenu: React.FC<Props> = ({
  appointment,
  visit,
  setOpenDialog,
  fullEvent,
}) => {
  const dispatch = useAppDispatch();

  const me = useAppSelector(state => state.auth);

  return (
    <S.ContentWrapper>
      <S.ContentRow
        onClick={() =>
          dispatch(
            showDrawer({
              show: true,
              mode: DrawerMode.DUPL_APPOINTMENT,
              props: appointment,
            }),
          )
        }
      >
        Duplicate
      </S.ContentRow>
      {appointment.status === AppointmentStatus.PENDING && me.role !== Roles.DOCTOR && (
        <S.ContentRow
          onClick={e => {
            e.stopPropagation();
            setOpenDialog(fullEvent);
          }}
        >
          Delete
        </S.ContentRow>
      )}
      {/* {me.role === Roles.DOCTOR && (
        <S.ContentRow>Show health record</S.ContentRow>
      )} */}
    </S.ContentWrapper>
  );
};

export default ClickEventMenu;
