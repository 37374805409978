import { getAppointmentPinColorV2 } from '../../../helpers/UserStatusPinColors/getAppointmentPinColorV2';
import * as S from './styles';

type Props = {
  type?: string | boolean;
};

const AppointmentPinV2: React.FC<Props> = ({ type }) => {
  const background = getAppointmentPinColorV2(type).backgroundColor;
  const border = getAppointmentPinColorV2(type).borderColor;
  const font = getAppointmentPinColorV2(type).fontColor;
  const text = getAppointmentPinColorV2(type).text;

  return (
    <S.StatusPinWrapper border={'none'} background={background} font={font}>
      {text}
    </S.StatusPinWrapper>
  );
};

export default AppointmentPinV2;
