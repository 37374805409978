import React from 'react';
import * as S from './styles';
import { DatePicker } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { getFirstAndLastDate } from 'helpers/functions/getFirstAndLastDate';
import { ClearFiltersSVG } from 'assets/icons';

type Props = {
  isLoading: boolean;
  firstDate: Dayjs | null | string;
  secondDate: Dayjs | null | string;
  setFirstDate: (firstDate: Dayjs | null | string) => void;
  setSecondDate: (firstDate: Dayjs | null | string) => void;
  firstLabel?: string;
  secondLabel?: string;
  withLabel?: boolean;
};

export const DatesFilter = ({
  isLoading,
  firstDate,
  secondDate,
  setFirstDate,
  setSecondDate,
  firstLabel,
  secondLabel,
  withLabel = false,
}: Props) => {
  return (
    <S.Header>
      <S.DatePickerWrapper>
        <DatePicker
          disabled={isLoading}
          label={firstLabel ?? ''}
          shouldDisableDate={date => {
            if (secondDate && date > secondDate) {
              return true;
            } else {
              return false;
            }
          }}
          value={firstDate}
          onChange={value => {
            setFirstDate(dayjs(value));
          }}
        />
      </S.DatePickerWrapper>
      {'-'}
      <S.DatePickerWrapper>
        <DatePicker
          disabled={isLoading}
          label={secondLabel ?? ''}
          shouldDisableDate={date => {
            if (firstDate && date < firstDate) {
              return true;
            } else {
              return false;
            }
          }}
          value={secondDate}
          onChange={value => {
            setSecondDate(dayjs(value));
          }}
        />
      </S.DatePickerWrapper>
      <S.ClearFilter
        onClick={() => {
          setFirstDate(getFirstAndLastDate(dayjs()).firstDate);
          setSecondDate(getFirstAndLastDate(dayjs()).lastDate);
        }}
        className={withLabel ? 'withLabel' : ''}
      >
        <ClearFiltersSVG />
      </S.ClearFilter>
    </S.Header>
  );
};
