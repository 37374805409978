import { TableCell, TableHead, TableRow } from '@mui/material';
import * as S from './styles';
import { BillingAccTableType } from '../../../../../../types/AccTableTypes';
import AccTableHeadClinicNames from './components/AccTableHeadClinicNames';
import AccTableHeadDoctorNames from './components/AccTableHeadDoctorNames';
import TableRowInOut from './components/TableRowInOut';
import TableRowInsurance from './components/TableRowInsurance';
import AccTableWorkSince from './components/AccTableHeadWorkSince';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const AccTableHead: React.FC<Props> = ({ data, index }) => {
  return (
    <TableHead>
      <AccTableHeadDoctorNames data={data} index={index} />
      <AccTableHeadClinicNames data={data} index={index} />
      <AccTableWorkSince data={data} index={index} />
      <TableRowInOut data={data} index={index} />
      <TableRowInsurance data={data} index={index} />
    </TableHead>
  );
};

export default AccTableHead;
