import { RadioGroup } from '@mui/material';
import * as S from './styles';
import { Button, RadioButton } from '../../../../../../components';
import { AccessLevel } from '../../../../../../types/enums/AppointmentEnum';
import { useEffect, useMemo, useState } from 'react';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { useUpdateProfileMutation } from '../../../../../../store/api/user/profileApi';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';

type Props = {
  billingInfo?: UserProfile;
};

const BillingAccess: React.FC<Props> = ({ billingInfo }) => {
  const [isInvoice, setIsInvoice] = useState<string | AccessLevel>(AccessLevel.READ);
  const { role } = useAppSelector(state => state.auth);

  const [isDirty, setIsDirty] = useState(false);

  const params = useParams();
  const dispatch = useAppDispatch();

  const [invoicePrePaymentAccessLevel, setInvoicePrePaymentAccessLevel] = useState<
    string | AccessLevel
  >(AccessLevel.READ);

  const [invoicePostPaymentAccessLevel, setInvoicePostPaymentAccessLevel] = useState<
    string | AccessLevel
  >(AccessLevel.READ);

  const [claimAccessLevel, setClaimAccessLevel] = useState<string | AccessLevel>(
    AccessLevel.READ,
  );

  useEffect(() => {
    billingInfo?.claimAccessLevel && setClaimAccessLevel(billingInfo.claimAccessLevel);
    billingInfo?.invoicePostPaymentAccessLevel &&
      setInvoicePostPaymentAccessLevel(billingInfo.invoicePostPaymentAccessLevel);
    billingInfo?.invoicePrePaymentAccessLevel &&
      setInvoicePrePaymentAccessLevel(billingInfo.invoicePrePaymentAccessLevel);
    billingInfo?.invoiceSheetAccessLevel &&
      setIsInvoice(billingInfo.invoiceSheetAccessLevel);
  }, [billingInfo]);

  const [updateAcess] = useUpdateProfileMutation({});

  const handleUpdate = () => {
    updateAcess({
      id: params.profileId,
      claimAccessLevel,
      invoicePrePaymentAccessLevel,
      invoicePostPaymentAccessLevel,
      invoiceSheetAccessLevel: isInvoice,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Access level was successfully updated',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const disabled = useMemo(() => {
    return role !== Roles.SUPER_ADMIN;
  }, [role]);

  return (
    <S.Wrapper>
      <S.Article>Billing access</S.Article>
      <S.HelperText>Here you can assign access level to billing specialist</S.HelperText>
      <S.InputRow>
        <RadioGroup
          value={isInvoice}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsDirty(true);
            setIsInvoice((event.target as HTMLInputElement).value);
          }}
        >
          <S.RadioItem>
            <S.Label>
              <S.LabelArticle>Invoice</S.LabelArticle>
              <S.HelperText>Invoice sheets for each patient</S.HelperText>
            </S.Label>
            <S.RadioButtons>
              <RadioButton label="Read" value={AccessLevel.READ} disabled={disabled} />
              <RadioButton label="Write" value={AccessLevel.WRITE} disabled={disabled} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.InputRow>
      <S.InputRow>
        <RadioGroup
          value={invoicePrePaymentAccessLevel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsDirty(true);
            setInvoicePrePaymentAccessLevel((event.target as HTMLInputElement).value);
          }}
        >
          <S.RadioItem>
            <S.Label>
              <S.LabelArticle>Pre-Payment</S.LabelArticle>
              <S.HelperText>Information pertaining to prepayment</S.HelperText>
            </S.Label>
            <S.RadioButtons>
              <RadioButton label="Read" value={AccessLevel.READ} disabled={disabled} />
              <RadioButton label="Write" value={AccessLevel.WRITE} disabled={disabled} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.InputRow>
      <S.InputRow>
        <RadioGroup
          value={invoicePostPaymentAccessLevel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsDirty(true);
            setInvoicePostPaymentAccessLevel((event.target as HTMLInputElement).value);
          }}
        >
          <S.RadioItem>
            <S.Label>
              <S.LabelArticle>Post-Payment</S.LabelArticle>
              <S.HelperText>Information pertaining to postpayment</S.HelperText>
            </S.Label>
            <S.RadioButtons>
              <RadioButton label="Read" value={AccessLevel.READ} disabled={disabled} />
              <RadioButton label="Write" value={AccessLevel.WRITE} disabled={disabled} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.InputRow>
      <S.InputRow>
        <RadioGroup
          value={claimAccessLevel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsDirty(true);
            setClaimAccessLevel((event.target as HTMLInputElement).value);
          }}
        >
          <S.RadioItem>
            <S.Label>
              <S.LabelArticle>Claim Status</S.LabelArticle>
              <S.HelperText>
                Sheet to show Claims and their accompanying ERA (Electronic EOBs)
              </S.HelperText>
            </S.Label>
            <S.RadioButtons>
              <RadioButton label="Read" value={AccessLevel.READ} disabled={disabled} />
              <RadioButton label="Write" value={AccessLevel.WRITE} disabled={disabled} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.InputRow>
      {!disabled && (
        <S.Line>
          <S.ButtonAssignWrapper>
            <Button text="Update access" disabled={!isDirty} onClick={handleUpdate} />
          </S.ButtonAssignWrapper>
        </S.Line>
      )}
    </S.Wrapper>
  );
};

export default BillingAccess;
