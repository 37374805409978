import { MenuItem, RadioGroup, SelectChangeEvent, Tooltip } from '@mui/material';
import {
  TooltipDatePicker,
  TooltipInput,
  RadioButton,
  TooltipSelectInput,
} from 'components';
import * as S from '../../styles';
import { StatesUSA } from 'constants/statesUSA';
import ReactInputMask from 'react-input-mask';
import { ChangeEvent, memo, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { PatientInfoFormType } from 'types/AppointmentNewTypes';
import { GroupHealthPlanEnum } from 'types/ClaimTypes';
import { patientClaimSchema } from 'pages/Billing/components/Claims/formikClaim';
import { ClaimActionEnum } from '../../ClaimForm';

type Props = {
  initialValues: PatientInfoFormType;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  setIsDirty: (isDirty: boolean) => void;
};

export const PatientInfoClaim = memo(
  ({ initialValues, getData, isSave, setIsSave, setIsDirty }: Props) => {
    const patientFormik = useFormik<PatientInfoFormType>({
      initialValues,
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: patientClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });

    const radioButtons = useMemo(() => {
      return Object.values(GroupHealthPlanEnum).map(value => (
        <RadioButton key={value} label={value} value={value} />
      ));
    }, []);

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await patientFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(patientFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (patientFormik.dirty) {
        setIsDirty(true);
      }
    }, [patientFormik.dirty]);

    return (
      <>
        <S.BlockWrapper>
          <RadioGroup
            value={patientFormik.values.defaultToGroupHealthPlan}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              patientFormik.setFieldValue('defaultToGroupHealthPlan', e.target.value);
            }}
          >
            <S.RadioItem>
              <S.Label>
                <S.LabelArticle>1. Default to Group Health Plan *</S.LabelArticle>
              </S.Label>
              <S.RadioButtons>{radioButtons}</S.RadioButtons>
            </S.RadioItem>
          </RadioGroup>
          <S.BottomLine />

          <S.Article>Patient Info</S.Article>
          <S.Line>
            <S.InputWrapper>
              <TooltipInput
                isRequired
                label="2. Patient name"
                value={patientFormik.values.patientName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  patientFormik.setFieldValue('patientName', e.target.value)
                }
                error={!!patientFormik.errors.patientName}
                tooltipHelperText={patientFormik.errors.patientName}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <TooltipDatePicker
                isRequired
                label="3. Patient`s Birth Date"
                value={dayjs(patientFormik.values.patientBirthDate)}
                onChange={date => patientFormik.setFieldValue('patientBirthDate', date)}
                error={!!patientFormik.errors.patientBirthDate}
                tooltipHelperText={patientFormik.errors.patientBirthDate}
              />
            </S.InputWrapper>
            <RadioGroup
              value={patientFormik.values.patientGender}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                patientFormik.setFieldValue('patientGender', e.target.value)
              }
            >
              <S.RadioItem>
                <S.Label>
                  <S.LabelArticle>Gender *</S.LabelArticle>
                </S.Label>
                <Tooltip
                  title={patientFormik.errors.patientGender ?? ''}
                  placement={'right'}
                >
                  <S.RadioButtons
                    className={patientFormik.errors.patientGender ? 'error' : ''}
                  >
                    <RadioButton label="M" value={'Male'} />
                    <RadioButton label="F" value={'Female'} />
                  </S.RadioButtons>
                </Tooltip>
              </S.RadioItem>
            </RadioGroup>
          </S.Line>

          <S.Line>
            <S.InputWrapper>
              <TooltipInput
                isRequired
                label="5. Patient's Address/ Street"
                value={patientFormik.values.patientAddress}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  patientFormik.setFieldValue('patientAddress', e.target.value)
                }
                error={!!patientFormik.errors.patientAddress}
                tooltipHelperText={patientFormik.errors.patientAddress}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="City"
                value={patientFormik.values.patientCity}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  patientFormik.setFieldValue('patientCity', e.target.value)
                }
                error={!!patientFormik.errors.patientCity}
                tooltipHelperText={patientFormik.errors.patientCity}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipSelectInput
                isRequired
                label="State"
                id="state"
                name="state"
                value={patientFormik.values.patientState}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  patientFormik.setFieldValue('patientState', e.target.value)
                }
                error={!!patientFormik.errors.patientState}
                tooltipHelperText={patientFormik.errors.patientState}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </TooltipSelectInput>
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="Zip Code"
                value={patientFormik.values.patientZip}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  patientFormik.setFieldValue('patientZip', e.target.value)
                }
                error={!!patientFormik.errors.patientZip}
                tooltipHelperText={patientFormik.errors.patientZip}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <ReactInputMask
                mask="+19999999999"
                value={patientFormik.values.patientPhone}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  patientFormik.setFieldValue('patientPhone', e.target.value)
                }
              >
                <TooltipInput
                  label="Phone"
                  isRequired
                  error={!!patientFormik.errors.patientPhone}
                  tooltipHelperText={patientFormik.errors.patientPhone}
                />
              </ReactInputMask>
            </S.InputWrapper>
          </S.Line>

          <S.Line>
            <S.RadioItem>
              <S.Label>
                <S.LabelArticle>10. Is Patient's Condition related to *</S.LabelArticle>
              </S.Label>
              <S.Line className="biggap">
                <RadioGroup
                  value={patientFormik.values.isPatientEmployment}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    patientFormik.setFieldValue(
                      'isPatientEmployment',
                      e.target.value === 'true',
                    )
                  }
                >
                  <S.RadioItem>
                    <S.RadioButtons>
                      <S.Caption>Employment?</S.Caption>
                      <RadioButton label="Yes" value={true} />
                      <RadioButton label="No" value={false} />
                    </S.RadioButtons>
                  </S.RadioItem>
                </RadioGroup>

                <RadioGroup
                  value={patientFormik.values.isPatientAutoAccident}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    patientFormik.setFieldValue(
                      'isPatientAutoAccident',
                      e.target.value === 'true',
                    )
                  }
                >
                  <S.RadioItem>
                    <S.RadioButtons>
                      <S.Caption>Auto Accident?</S.Caption>
                      <RadioButton label="Yes" value={true} />
                      <RadioButton label="No" value={false} />
                      <S.InputWrapper className="px180">
                        <TooltipSelectInput
                          value={patientFormik.values.state}
                          onChange={(e: SelectChangeEvent<unknown>) =>
                            patientFormik.setFieldValue('state', e.target.value)
                          }
                          label=""
                          id="state"
                          name="state"
                          renderValue={(selected: any) => {
                            if (!selected) {
                              return <S.Placeholder>Place(State)</S.Placeholder>;
                            }
                            return selected;
                          }}
                          disabled={!patientFormik.values.isPatientAutoAccident}
                        >
                          {StatesUSA.map(state => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                              <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                            </MenuItem>
                          ))}
                        </TooltipSelectInput>
                      </S.InputWrapper>
                    </S.RadioButtons>
                  </S.RadioItem>
                </RadioGroup>

                <RadioGroup
                  value={patientFormik.values.isPatientOtherAccident}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    patientFormik.setFieldValue(
                      'isPatientOtherAccident',
                      e.target.value === 'true',
                    )
                  }
                >
                  <S.RadioItem>
                    <S.RadioButtons>
                      <S.Caption>Other Accident?</S.Caption>
                      <RadioButton label="Yes" value={true} />
                      <RadioButton label="No" value={false} />
                    </S.RadioButtons>
                  </S.RadioItem>
                </RadioGroup>
              </S.Line>
            </S.RadioItem>
          </S.Line>
          <S.BottomLine />
        </S.BlockWrapper>
      </>
    );
  },
);
