import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../atoms/Button';
import * as S from './styles';
import TemplateList from './components/TemplateList';
import { useEffect, useRef, useState } from 'react';
import {
  useCancelAppointmentMutation,
  useCompleteStepMutation,
  useFinishAppointmentMutation,
  useGetAppointmentProcessMutation,
  useSetStepAsCurrentMutation,
} from '../../../store/api/start-progress/startProgressApi';
import CircularLoader from '../../atoms/CircuralLoader';
import SuccessDialog from '../../molecules/SuccessDialog';
import { Dialog } from '../..';
import ConfirmDeleteDialog from '../../molecules/ConfirmDeleteDialog';
import { AppointmentProcessInterface, Step } from '../../../types/AppointmentNewTypes';
import InitialVisitForm from '../../../pages/SuperAdmin/components/Templates/components/InitialVisitForm';
import { chooseAppointmentProcessFormik } from './configFormik';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddSignatureToPDFMutation,
  useDigitalFormSavePDFMutation,
  useDigitalFormUpdateMutation,
  useGetPdfByIdMutation,
  useTemplatePrintPDFMutation,
} from '../../../store/api/templates/templateApi';
import ConfigSelectedForm from './configSelectedForm';
import { useAppSelector } from '../../../hooks/useAppSelector';
import dayjs from 'dayjs';
import { setMessage } from '../../../store/slices/message';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { configCompleteStep } from './configCompleteStep';
import PutSignature from '../../../pages/SuperAdmin/components/Templates/components/PutSignature';
import AppointmentFinishedPlaceholder from './components/AppointmentFinishedPlaceholder';

import {
  getCurrentStep,
  getNextStep,
  isCanFinish,
  isNeedAlert,
} from './functions/appProcessFunctions';
import GeneratePTInsurance from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GeneratePTInsurance';
import { pdf } from '@react-pdf/renderer';
import { generateFileName } from '../../../helpers/generateFileName';
import { DigitalTemplateTypeByName } from '../../../types/TemplateType';
import { configGenerateDigitalPDF } from './configGeneratePDF';
import { setIsDirty } from '../../../store/slices/isDirtyFormSlice';
import DialogUnsavedChanges from '../../../services/DialogUnsavedChanges';
import {
  deleteNames,
  updateFormikValues,
} from '../../../helpers/FormikFuncs/updateFormikValues';

const AppointmentProcess = () => {
  const params = useParams();

  const [getAppointmentProcess, appointmentProcess] = useGetAppointmentProcessMutation(
    {},
  );

  const ref = useRef<Record<string, unknown>>({});

  const [cancelAppointmentProcess] = useCancelAppointmentMutation({});
  const [updateDigitalForm] = useDigitalFormUpdateMutation({});

  const [isDirtyForm, setIsDirtyForm] = useState(true);

  useEffect(() => {
    if (params?.id) {
      getAppointmentProcess({
        appointmentId: params.id,
      });
    }
  }, [params.id]);

  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);

  const navigate = useNavigate();

  const [setStepAsCurrent] = useSetStepAsCurrentMutation({});
  const [getPdfById, getPDFResponseStatus] = useGetPdfByIdMutation({});
  const [completeStep, completeStepStatus] = useCompleteStepMutation({});
  const [addSignatureToPDF, addSignatureToPDFStatus] = useAddSignatureToPDFMutation({});

  const [finishAppointment] = useFinishAppointmentMutation({});

  const isDirty = useAppSelector(state => state.isDirty.isDirty);

  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const setStepAsCurrentVoid = (id: string) => {
    setStepAsCurrent({
      stepId: id,
    })
      .unwrap()
      .then(res => {
        if (params?.id) {
          getAppointmentProcess({
            appointmentId: params.id,
          });
        }
      });
  };

  const changeStep = (id: string) => {
    isDirty
      ? dispatch(
          setIsDirty({
            isShowDialog: true,
            isDirty: true,
            action: () => setStepAsCurrentVoid(id),
          }),
        )
      : setStepAsCurrentVoid(id);
  };

  const Shape = yup.object().shape({});

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: chooseAppointmentProcessFormik(
      getCurrentStep(appointmentProcess?.data)?.clinicTemplate?.template?.name,
      appointmentProcess?.data,
      getCurrentStep(appointmentProcess?.data),
    ),
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {},
  });

  const dispatch = useAppDispatch();

  const [openSignature, setOpenSignature] = useState(false);
  const [isOpenCompletePlaceholder, setIsOpenCompletePlaceholder] = useState(false);

  const signature = useAppSelector(state => state.auth.signature);

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);

  useEffect(() => {
    if (
      appointmentProcess.isSuccess &&
      getCurrentStep(appointmentProcess?.data)?.pdfFormId
    ) {
      getPdfById({
        id: getCurrentStep(appointmentProcess?.data)?.pdfFormId,
      });
    }
  }, [appointmentProcess.data]);

  const [savePDF] = useDigitalFormSavePDFMutation({});

  const generateFile = async (res: AppointmentProcessInterface) => {
    if (res) {
      const blob = await pdf(configGenerateDigitalPDF(res, signature)).toBlob();

      const file = new File(
        [blob],
        `${generateFileName(getCurrentStep(res)?.digitalForm?.type)}.pdf`,
      );
      savePDF({
        id: getCurrentStep(res)?.digitalFormId,
        pdf: file,
        signature: null,
      });
    }
  };

  const handleCompleteStep = (res: AppointmentProcessInterface) => {
    completeStep({
      stepId: getCurrentStep(res)?.id,
    })
      .unwrap()
      .then(res => {
        if (getNextStep(appointmentProcess.data)?.id) {
          setStepAsCurrent({
            stepId: getNextStep(appointmentProcess.data)?.id,
          })
            .unwrap()
            .then(res => {
              if (params?.id) {
                getAppointmentProcess({
                  appointmentId: params.id,
                });
              }
            });
        } else {
          if (params?.id) {
            getAppointmentProcess({
              appointmentId: params.id,
            });
          }
        }
        setIsLoadingForm(false);
      });
  };

  const handleClickComplete = async (res: AppointmentProcessInterface) => {
    if (configCompleteStep(getCurrentStep(res))) {
      setOpenSignature(true);
    } else {
      if (getCurrentStep(res)?.digitalFormId) {
        await generateFile(res).then(resp => {
          handleCompleteStep(res);
        });
      }
    }
  };

  return (
    <S.Wrapper>
      <S.Main>
        <Dialog
          open={isOpenCancelDialog}
          onClose={() => setIsOpenCancelDialog(!isOpenCancelDialog)}
        >
          <ConfirmDeleteDialog
            onClose={() => setIsOpenCancelDialog(!isOpenCancelDialog)}
            onSuccess={() =>
              cancelAppointmentProcess({
                appointmentId: params.id,
              })
                .unwrap()
                .then(res => {
                  navigate('/appointments');
                })
            }
            helperText="All progress will be lost!"
            fullTitle="Are you sure want to cancel this stepper?"
          />
        </Dialog>
        <Dialog open={isOpenAlert} onClose={() => setIsOpenAlert(!isOpenAlert)}>
          <SuccessDialog
            onClose={() => setIsOpenAlert(!isOpenAlert)}
            onSuccess={() =>
              finishAppointment({
                appointmentId: appointmentProcess.data?.appointmentId,
              })
                .unwrap()
                .then(res => {
                  setIsOpenCompletePlaceholder(!isOpenCompletePlaceholder);
                  dispatch(
                    setMessage({
                      message: 'Appointment was successfully finished',
                      type: 'success',
                    }),
                  );
                })
            }
            titleText="Not all steps have been completed!"
            helperText=" Are you sure you want to complete the Stepper? In this case only the completed documents will be saved"
          />
        </Dialog>
        <Dialog
          open={isOpenUnsavedChanges}
          onClose={() => setIsOpenUnsavedChanges(!isOpenUnsavedChanges)}
        >
          unsaved changes
        </Dialog>
        <S.SubtitleItems>
          <S.Article>
            {appointmentProcess.isLoading ? (
              <S.Loader>
                <CircularLoader color="#0084B1" />
              </S.Loader>
            ) : (
              getCurrentStep(appointmentProcess.data)?.clinicTemplate?.template?.name
            )}
          </S.Article>
          <S.Buttons>
            <S.ButtonWrapper>
              <Button
                text="Cancel Stepper"
                backgroundColor="#C66060"
                onClick={() => setIsOpenCancelDialog(true)}
              />
            </S.ButtonWrapper>
            <Dialog
              open={isOpenCompletePlaceholder}
              onClose={() => {
                navigate(
                  `/patients/${appointmentProcess.data?.appointment?.patient?.id}`,
                  {
                    state: {
                      startedAppointmentDocuments: appointmentProcess.data?.appointment,
                    },
                  },
                );
                setIsOpenCompletePlaceholder(!isOpenCompletePlaceholder);
              }}
            >
              <AppointmentFinishedPlaceholder />
            </Dialog>
            {isCanFinish(appointmentProcess?.data) &&
              getCurrentStep(appointmentProcess.data)?.id && (
                <S.ButtonWrapper>
                  <Button
                    text="Finish Stepper"
                    backgroundColor="#79A030"
                    onClick={() =>
                      !isNeedAlert()
                        ? setIsOpenAlert(true)
                        : finishAppointment({
                            appointmentId: appointmentProcess.data?.appointmentId,
                          })
                            .unwrap()
                            .then(res => {
                              setIsOpenCompletePlaceholder(!isOpenCompletePlaceholder);
                              dispatch(
                                setMessage({
                                  message: 'Appointment was successfully finished',
                                  type: 'success',
                                }),
                              );
                            })
                    }
                  />
                </S.ButtonWrapper>
              )}
          </S.Buttons>
        </S.SubtitleItems>
        <S.Content>
          <S.FormContent>
            {appointmentProcess.isLoading ||
            getPDFResponseStatus.isLoading ||
            addSignatureToPDFStatus.isLoading ||
            completeStepStatus.isLoading ||
            (getCurrentStep(appointmentProcess?.data)?.digitalFormId &&
              !formik.values) ? (
              <S.Loader>
                <CircularLoader color="#0084B1" />
              </S.Loader>
            ) : (
              <S.Form>
                <ConfigSelectedForm
                  ref={ref}
                  finishClick={() =>
                    finishAppointment({
                      appointmentId: appointmentProcess.data?.appointmentId,
                    })
                      .unwrap()
                      .then(res => {
                        setIsOpenCompletePlaceholder(!isOpenCompletePlaceholder);
                        dispatch(
                          setMessage({
                            message: 'Appointment was successfully finished',
                            type: 'success',
                          }),
                        );
                      })
                  }
                  formik={formik}
                  fileUrl={
                    'https://corsproxy.io/?' +
                    encodeURIComponent(getPDFResponseStatus.data?.pdf?.url)
                  }
                  // fileUrl={getPDFResponseStatus.data?.pdf?.url}
                  data={getCurrentStep(appointmentProcess.data)}
                  appointmentInformation={appointmentProcess.data?.appointment}
                />
              </S.Form>
            )}
          </S.FormContent>
          <TemplateList
            data={appointmentProcess.data}
            changeStep={(id: string) => changeStep(id)}
            isLoading={appointmentProcess.isLoading}
            refetch={() => {
              getAppointmentProcess({
                appointmentId: params.id,
              });
            }}
          />
        </S.Content>
        <S.Footer>
          <Dialog open={openSignature} onClose={() => setOpenSignature(!openSignature)}>
            <PutSignature
              actionAfterSignature={(signature: File) => {
                setOpenSignature(!openSignature);
                addSignatureToPDF({
                  file: signature,
                  pdfId: getCurrentStep(appointmentProcess.data)?.pdfFormId,
                })
                  .unwrap()
                  .then(res => {
                    completeStep({
                      stepId: getCurrentStep(appointmentProcess.data)?.id,
                    })
                      .unwrap()
                      .then(res => {
                        if (getNextStep(appointmentProcess.data)?.id) {
                          setStepAsCurrent({
                            stepId: getNextStep(appointmentProcess.data)?.id,
                          })
                            .unwrap()
                            .then(res => {
                              if (params?.id) {
                                setIsLoadingForm(false);
                                getAppointmentProcess({
                                  appointmentId: params.id,
                                });
                              }
                            });
                        } else {
                          if (params?.id) {
                            getAppointmentProcess({
                              appointmentId: params.id,
                            });
                            setIsLoadingForm(false);
                          }
                        }
                      });
                  });
              }}
            />
          </Dialog>
          {getCurrentStep(appointmentProcess.data)?.id && (
            <S.ButtonWrapper>
              <Button
                text="Complete step"
                disabled={isLoadingForm}
                onClick={() => {
                  setIsLoadingForm(true);
                  if (getCurrentStep(appointmentProcess.data)?.pdfFormId) {
                    setOpenSignature(true);
                    setIsLoadingForm(false);
                  } else {
                    // setUserId(formik.values.userId);
                    const updatedFormikValues = updateFormikValues(
                      { ...deleteNames(formik.values) },
                      ref.current,
                    );
                    formik
                      .setValues({
                        ...updatedFormikValues,
                      })
                      .then(() => {
                        updateDigitalForm({
                          id: getCurrentStep(appointmentProcess.data)?.digitalFormId,
                          body: {
                            ...formik.values,
                          },
                        })
                          .unwrap()
                          .then(res => {
                            getAppointmentProcess({
                              appointmentId: params.id,
                            })
                              .unwrap()
                              .then(res => handleClickComplete(res));
                          });
                      });
                    setIsLoadingForm(false);
                  }
                }}
              />
              {/* <Button text="Complete step" onClick={handleClickComplete} /> */}
            </S.ButtonWrapper>
          )}
        </S.Footer>
      </S.Main>
    </S.Wrapper>
  );
};

export default AppointmentProcess;
