import React from 'react';
import { generateStylesConfig } from './generateStylesConfig';
import { ClaimValues } from 'types/AppointmentNewTypes';
import { Text, View } from '@react-pdf/renderer';
import {
  BirthDayPdf,
  CellPdf,
  CheckBoxPdf,
  ColumnPdf,
  SimplyCellPdf,
} from './components';
import { alphabeticArray, booleanOptions } from './config';

type Props = {
  data?: ClaimValues;
};

export const InjuryPart = ({ data }: Props) => {
  const s = generateStylesConfig();

  return (
    <>
      <View style={s.redRow}>
        <View style={[s.column, { alignItems: 'flex-start', marginLeft: 3, flex: 1 }]}>
          <Text style={[s.title, { padding: '2.5 0 0 0' }]}>
            14. DATE OF CURRENT ILLNESS, INJURY, or PREGNANCY (LMP)
          </Text>
          <View style={[s.row, { gap: 20 }]}>
            <BirthDayPdf date={data?.dateOfIllness} />
            <View style={[s.row, { gap: 1 }]}>
              <Text style={[s.title, { paddingBottom: 0 }]}>QUAL.</Text>
              <View style={[s.verticalBorder]} />
              <Text style={[s.title]}>{data?.dateOfIllnessQual}</Text>
            </View>
          </View>
        </View>

        <CellPdf width={157}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>15. OTHER DATE QUAL.</Text>
            <View style={[s.row, { justifyContent: 'space-between', gap: 15 }]}>
              <View style={[s.row]}>
                <Text style={[s.title, { paddingBottom: 0 }]}>QUAL.</Text>
                <View style={[s.verticalBorder]} />
                <Text style={[s.title, { width: 20 }]}>{data?.otherDateQual}</Text>
                <View style={[s.verticalBorder]} />
              </View>
              <BirthDayPdf date={data?.otherDate} />
            </View>
          </View>
        </CellPdf>

        <CellPdf>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>
              16. DATES PATIENT UNABLE TO WORK IN CURRENT OCCUPATION
            </Text>
            <View style={[s.row, { justifyContent: 'space-between', gap: 15 }]}>
              <View style={[s.row]}>
                <Text style={[s.title, { paddingBottom: 0 }]}>FROM</Text>
                <BirthDayPdf date={data?.unableToWorkStartDate} />
              </View>
              <View style={[s.row]}>
                <Text style={[s.title, { paddingBottom: 0 }]}>TO</Text>
                <BirthDayPdf date={data?.unableToWorkEndDate} />
              </View>
            </View>
          </View>
        </CellPdf>
      </View>

      <View style={s.redRow}>
        <SimplyCellPdf
          name={`17. NAME OF REFERRING PROVIDER OR OTHER SOURCE`}
          content={data?.nameOfReferringProvider}
        />

        <CellPdf width={157}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  borderBottom: 1,
                  borderStyle: 'dashed',
                  borderColor: '#EC2027',
                },
              ]}
            >
              <Text style={[s.title, { width: 15, padding: '0 0 0 2.5' }]}>17a.</Text>
              <View style={[s.verticalBorder, { height: 12.5, borderStyle: 'solid' }]} />
              <Text style={[s.title, { width: 15 }]} />
              <View style={[s.verticalBorder, { height: 12.5, borderStyle: 'solid' }]} />
              <Text style={[s.title, { width: 116 }]} />
            </View>
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.title, { width: 15, padding: '0 0 0 2.5' }]}>17b.</Text>
              <View style={[s.verticalBorder, { height: 12.5, borderStyle: 'solid' }]} />
              <Text style={[s.title, { width: 15 }]}>NPI</Text>
              <View style={[s.verticalBorder, { height: 12.5, borderStyle: 'solid' }]} />
              <Text style={[s.title, { width: 116 }]} />
            </View>
          </View>
        </CellPdf>

        <CellPdf>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>
              18. HOSPITALIZATION DATES RELATED TO CURRENT SERVICES
            </Text>
            <View style={[s.row, { justifyContent: 'space-between', gap: 15 }]}>
              <View style={[s.row]}>
                <Text style={[s.title, { paddingBottom: 0 }]}>FROM</Text>
                <BirthDayPdf date={data?.hospitalizationStartDate} />
              </View>
              <View style={[s.row]}>
                <Text style={[s.title, { paddingBottom: 0 }]}>TO</Text>
                <BirthDayPdf date={data?.hospitalizationEndDate} />
              </View>
            </View>
          </View>
        </CellPdf>
      </View>

      <View style={s.redRow}>
        <SimplyCellPdf
          name={`19. ADDITIONAL CLAIM INFORMATION (Designated by NUCC)`}
          content={data?.additionalClaimInfo}
        />

        <CellPdf>
          <View style={[s.row, { justifyContent: 'space-between', gap: 15 }]}>
            <View style={[s.column, { width: 60, alignItems: 'flex-start' }]}>
              <Text style={s.title}>20. OUTSIDE LAB?</Text>
              <View style={[s.row, { gap: 10, marginLeft: 5 }]}>
                {booleanOptions.map(({ title, subTitle }) => (
                  <CheckBoxPdf
                    key={subTitle}
                    checked={data?.isOutsideLab === title}
                    subTitle={subTitle}
                  />
                ))}
              </View>
            </View>
            <View style={[s.column, { alignItems: 'center' }]}>
              <Text style={[s.title, { paddingBottom: 0 }]}>$ CHARGES</Text>
              <View style={[s.row, { justifyContent: 'center', gap: 1 }]}>
                <View style={[s.verticalBorder, { borderStyle: 'solid' }]} />
                <Text style={[s.text, { width: 40, textAlign: 'right' }]}>
                  {data?.labCharges ? '$' + Math.trunc(data?.labCharges) : ''}
                </Text>
                <View style={[s.verticalBorder]} />
                <Text style={[s.text]}>
                  {data?.labCharges
                    ? '.' + (data?.labCharges.toString().split('.')[1] ?? '00')
                    : ''}
                </Text>
              </View>
            </View>
          </View>
        </CellPdf>
      </View>

      <View style={[s.redRow, { height: 50 }]}>
        <View style={[s.column, { alignItems: 'flex-start', flex: 1 }]}>
          <View style={[s.row, { justifyContent: 'space-between', gap: 10 }]}>
            <Text style={s.title}>
              21. DIAGNOSIS OR NATURE OF ILLNESS OR INJURY Relate A-L to service line
              below (24E)
            </Text>
            <View style={[s.row, { justifyContent: 'space-between', gap: 1 }]}>
              <Text style={s.title}>ICD Ind.</Text>
              <View style={[s.verticalBorder]} />
              <Text style={[s.text, { width: 25 }]}>{data?.typeICD}</Text>
            </View>
          </View>
          <View
            style={[
              s.row,
              {
                rowGap: 0,
                columnGap: 30,
                width: 350,
                flexWrap: 'wrap',
                marginTop: -2,
              },
            ]}
          >
            {alphabeticArray.map((value, i) => (
              <View key={i} style={[s.row, { width: 65 }]}>
                <Text style={[s.title, { width: 10 }]}>{value}</Text>
                <View style={[s.leftBorder]}>
                  <Text style={[s.text, { marginLeft: 2, marginTop: -3.5 }]}>
                    {data?.diagnosisCodes ? data?.diagnosisCodes?.at(i)?.code : ''}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={[s.column, { alignItems: 'flex-start', width: 205 }]}>
          <CellPdf>
            <View style={[s.redRow, { justifyContent: 'flex-start', gap: 10 }]}>
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text style={s.title}>22. RESUBMISSION CODE</Text>
                <Text style={[s.text, { paddingLeft: 5 }]}>{data?.resubmissionCode}</Text>
              </View>
              <View style={[s.verticalBorder, { marginTop: 10, borderStyle: 'solid' }]} />
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text style={s.title}>ORIGINAL REF. NO.</Text>
                <Text style={s.text}>{data?.originalRef}</Text>
              </View>
            </View>
          </CellPdf>

          <ColumnPdf
            name={'23. PRIOR AUTHORIZATION NUMBER'}
            content={data?.priorAuthorizationNumber}
          />
        </View>
      </View>
    </>
  );
};
