import { useState } from 'react';
import { AddRectangleSVG } from '../../../../../../../../assets/icons';
import { Button, Dialog } from '../../../../../../../../components';
import * as S from '../../styles';
import InsuredListDialog from '../InsuredListDialog';

type Props = {
  label?: string;
  insuranceId?: string;
};

const InsuredList: React.FC<Props> = ({ label, insuranceId }) => {
  const [openInsuredListDialog, setOpenInsuredListDialog] = useState(false);

  return (
    <S.Wrapper>
      <Dialog
        open={openInsuredListDialog}
        onClose={() => setOpenInsuredListDialog(!openInsuredListDialog)}
      >
        <InsuredListDialog
          insuranceId={insuranceId}
          onClose={() => setOpenInsuredListDialog(!openInsuredListDialog)}
        />
      </Dialog>
      <S.CheckBoxWrapper>
        <S.StyledSpan>{label || 'Insured list'}</S.StyledSpan>
        <S.ButtonWrapper className="xs">
          <Button
            text="Add"
            onClick={() => setOpenInsuredListDialog(!openInsuredListDialog)}
          >
            <AddRectangleSVG />
          </Button>
        </S.ButtonWrapper>
      </S.CheckBoxWrapper>
    </S.Wrapper>
  );
};

export default InsuredList;
