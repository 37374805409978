import { useDispatch } from 'react-redux';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const surveyApi = api.injectEndpoints({
  endpoints: builder => ({
    surveyGet: builder.query<Response<any>, any>({
      query: params => ({
        url: `/survey`,
        params: { ...params },
      }),
      providesTags: ['Survey'],
    }),
    surveyParticipantsGet: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/survey/${id}/participants`,
        params: { ...params },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          return;
        }
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Survey'],
    }),
    surveyCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/survey`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Survey'],
    }),
    surveySend: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/survey/${id}/send`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Survey'],
    }),
    surveyDelete: builder.mutation({
      query: ({ id }) => ({
        url: `/survey/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Survey'],
    }),
    surveyReport: builder.mutation({
      query({ id, ...params }) {
        return {
          url: `/survey/${id}/report`,
          method: 'GET',
          responseHandler: async response => {
            if (response.status === 200) {
              return window.location.assign(
                window.URL.createObjectURL(await response.blob()),
              );
            }
          },
          params: { ...params },
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const {
  useSurveyGetQuery,
  useSurveyReportMutation,
  useSurveyCreateMutation,
  useSurveyDeleteMutation,
  useSurveySendMutation,
  useSurveyParticipantsGetQuery,
} = surveyApi;
