import { useFormik } from 'formik';
import { Button, CircularLoader, Dialog } from '../../../../../../../components';
import InitialVisitForm from '../../InitialVisitForm';
import * as S from './styles';
import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import {
  useDigitalFormCreateMutation,
  useDigitalFormSavePDFMutation,
} from '../../../../../../../store/api/templates/templateApi';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { TestCreateInitialDoc } from '../../../../../../../constants/mocConst';
import PutSignature from '../../PutSignature';
import { Dayjs } from 'dayjs';
import {
  DigitalTemplateType,
  DigitalTemplateTypeByName,
} from '../../../../../../../types/TemplateType';
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  View,
  Text,
  BlobProvider,
  usePDF,
  pdf,
} from '@react-pdf/renderer';
import Observation from '../../InitialVisitForm/Observation';
import { useNavigate } from 'react-router-dom';
import SuccessSavedTemplateDialog from '../../SuccessSavedTemplateDialog';
import SymptomsBlock from '../../InitialVisitForm/SymptomsBlock';
import Assesment from '../../InitialVisitForm/Assesment';
import jsPDF from 'jspdf';
import { text } from 'stream/consumers';
import { chooseAppointmentProcessFormik } from '../../../../../../../components/organismus/AppointmentProcess/configFormik';
import { InitialVisitFormType } from './InitialVisitiFormType';
import GenerateInitialVisitForm from '../../GenerateEmptyDigital/GenerateInitialVisitForm';
import { generateFileName } from '../../../../../../../helpers/generateFileName';
import { updateFormikValues } from '../../../../../../../helpers/FormikFuncs/updateFormikValues';
import { ArrowLeftSVG } from '../../../../../../../assets/icons';

const InitialVisitFormPage = () => {
  const Schema = yup.object().shape({});
  const [isOpenDialogSign, setIsOpenDialogSign] = useState(false);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [createDigitalForm, createDigFormStatus] = useDigitalFormCreateMutation({});
  const [savePDF, savePDFStatus] = useDigitalFormSavePDFMutation({});

  const signature = useAppSelector(state => state.auth.signature);

  const [userId, setUserId] = useState<string | undefined>('');

  const ref = useRef<Record<string, unknown>>({});
  const formik = useFormik<InitialVisitFormType>({
    initialValues: chooseAppointmentProcessFormik(
      DigitalTemplateTypeByName.INITIAL,
    ) as InitialVisitFormType,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Schema,
    onSubmit: async () => {
      setIsOpenDialogSign(true);
      createDigitalForm({
        params: {
          clinicId: selectedClinic.id,
          patientId: userId,
          type: DigitalTemplateType.ACUPUNCTURE_INSURANCE,
        },
        ...formik.values,
      })
        .unwrap()
        .then(async res => {
          if (res) {
            const blob = await pdf(
              <GenerateInitialVisitForm data={res} signature={signature} />,
            ).toBlob();
            const file = new File(
              [blob],
              `${generateFileName(DigitalTemplateTypeByName.INITIAL as string)}.pdf`,
            );
            savePDF({
              id: res.id,
              pdf: file,
              signature: null,
            });
          }
        });
    },
  });

  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const deleteNames = (obj: any) => {
    delete obj?.firstName;
    delete obj?.lastName;
    delete obj?.userId;
    delete obj?.dateOfBirth;
    return obj;
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Wrapper>
        <Dialog
          open={isOpenDialogSign}
          onClose={() => {
            navigate(`/templates`);
            setIsOpenDialogSign(false);
          }}
        >
          {(createDigFormStatus.isLoading || savePDFStatus.isLoading) &&
          !createDigFormStatus.data &&
          !savePDFStatus.data &&
          !savePDFStatus.isSuccess &&
          !createDigFormStatus.isSuccess ? (
            <S.Loader>
              <CircularLoader size={24} color="#0084B1" />
            </S.Loader>
          ) : (
            <>
              <SuccessSavedTemplateDialog
                onClose={() => setIsOpenDialogSign(false)}
                navigateHere={() =>
                  navigate(`/patients/${userId}`, {
                    state: { templateDocuments: true },
                  })
                }
              />
            </>
          )}
        </Dialog>
        <S.SubtitleBar>
          <S.SubtitleText>
            <ArrowLeftSVG onClick={() => navigate('/templates')} />
            Initial visit form
          </S.SubtitleText>
          <S.SubtitleItems></S.SubtitleItems>
        </S.SubtitleBar>
        <S.Content>
          <InitialVisitForm formik={formik} ref={ref} />
        </S.Content>
      </S.Wrapper>
      <S.Footer>
        <S.FooterItems>
          <S.ButtonWrapper>
            <Button
              text="Signature"
              onClick={() => {
                setUserId(formik.values.userId);
                const updatedFormikValues = updateFormikValues(
                  { ...deleteNames(formik.values) },
                  ref.current,
                );
                formik
                  .setValues({
                    ...updatedFormikValues,
                  })
                  .then(() => {
                    formik.handleSubmit();
                  });
              }}
            />
          </S.ButtonWrapper>
        </S.FooterItems>
      </S.Footer>
    </form>
  );
};

export default InitialVisitFormPage;
