import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import {
  booleanOptions,
  genderOptions,
  healthGroupOptions,
  relationOptions,
} from './config';
import {
  BirthDayPdf,
  CellPdf,
  CheckBoxPdf,
  CityStatePdf,
  ColumnPdf,
  SimplyCellPdf,
  ZipPhonePdf,
} from './components';
import dayjs from 'dayjs';
import { generateStylesConfig } from './generateStylesConfig';
import { ClaimValues } from 'types/AppointmentNewTypes';

type Props = {
  data?: ClaimValues;
};

export const PatientInsurancePart = ({ data }: Props) => {
  const s = generateStylesConfig();

  return (
    <View style={[s.row, { justifyContent: 'space-between' }]}>
      <View style={[s.column, { alignItems: 'flex-start' }]}>
        <View style={[s.table, { marginTop: -2, borderBottom: 2 }]}>
          <View style={s.redRow}>
            <View style={[s.row, { flex: 1, alignItems: 'flex-start' }]}>
              <Text style={s.title}>1.</Text>
              {healthGroupOptions.map(({ title, subTitle }) => (
                <View style={[s.column, { padding: '0 3' }]} key={title}>
                  <Text style={s.title}>{title}</Text>
                  <CheckBoxPdf
                    checked={data?.defaultToGroupHealthPlan === title}
                    subTitle={subTitle}
                  />
                </View>
              ))}
            </View>
            <CellPdf>
              <SimplyCellPdf
                name={'1a.INSURED’S I.D. NUMBER'}
                subName={'(For Program in Item 1)'}
                content={data?.insuredIdNumber}
              />
            </CellPdf>
          </View>

          <View style={s.redRow}>
            <SimplyCellPdf
              name={`2. PATIENT'S NAME (Last Name, First Name, Middle Initial)`}
              content={
                data?.patient?.profile
                  ? data?.patient?.profile?.lastName +
                    ', ' +
                    data?.patient?.profile?.firstName +
                    (data?.patient?.profile?.mi ? ', ' + data?.patient?.profile?.mi : '')
                  : (data?.patientName ?? '')
              }
            />

            <CellPdf width={150}>
              <View style={s.column}>
                <Text style={[s.title, { paddingBottom: 0 }]}>
                  3. PATIENT'S BIRTH DATE
                </Text>
                <BirthDayPdf date={data?.patientBirthDate} />
              </View>

              <View style={[s.column, { width: 55 }]}>
                <Text style={s.title}>SEX</Text>
                <View style={[s.row, { gap: 10 }]}>
                  {genderOptions.map(({ title, prefix }) => (
                    <CheckBoxPdf
                      key={title}
                      checked={data?.patientGender.toLowerCase() === title}
                      prefix={prefix}
                    />
                  ))}
                </View>
              </View>
            </CellPdf>
            <CellPdf>
              <SimplyCellPdf
                name={`4. INSURED'S NAME (Last Name, First Name, Middle Initial)`}
                content={data?.insuredName}
              />
            </CellPdf>
          </View>

          <View style={s.redRow}>
            <SimplyCellPdf
              name={`5. PATIENT'S ADDRESS (No., Street)`}
              content={data?.patientAddress}
            />

            <CellPdf width={150}>
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text style={s.title}>6. PATIENT RELATIONSHIP TO INSURED</Text>
                <View style={[s.row, { gap: 10, marginLeft: 5 }]}>
                  {relationOptions.map(({ title, subTitle }) => (
                    <CheckBoxPdf
                      key={title}
                      checked={data?.patientRelationshipToInsured === title}
                      subTitle={subTitle}
                    />
                  ))}
                </View>
              </View>
            </CellPdf>

            <CellPdf>
              <SimplyCellPdf
                name={`7. INSURED'S ADDRESS (No., Street)`}
                content={data?.insuredAddress}
              />
            </CellPdf>
          </View>

          <View style={[s.row, { height: 50 }]}>
            <View style={[s.column, { alignItems: 'flex-start', flex: 1 }]}>
              <CityStatePdf city={data?.patientCity} state={data?.patientState} />
              <ZipPhonePdf zip={data?.patientZip} phone={data?.patientPhone} />
            </View>

            <View style={[s.redRow, { height: 50, width: 150 }]}>
              <CellPdf width={150} height={50}>
                <SimplyCellPdf
                  name={`8. RESERVED FOR NUCC USE`}
                  content={data?.reservedForNUCC}
                />
              </CellPdf>
            </View>

            <View style={[s.column, { alignItems: 'flex-start', flex: 1 }]}>
              <CityStatePdf city={data?.insuredCity} state={data?.insuredState} isCell />
              <ZipPhonePdf zip={data?.insuredZip} phone={data?.insuredPhone} isCell />
            </View>
          </View>

          <View style={[s.redRow, { height: 100 }]}>
            <View style={[s.column, { alignItems: 'flex-start', flex: 1 }]}>
              <View style={[s.redRow, { width: '205' }]}>
                <SimplyCellPdf
                  name={`9. OTHER INSURED'S NAME (Last Name, First Name, Middle Initial)`}
                  content={data?.otherInsuredName}
                />
              </View>
              <View style={[s.redRow, { width: '205' }]}>
                <SimplyCellPdf
                  name={`a. OTHER INSURED'S POLICY OR GROUP NUMBER`}
                  content={data?.insuredPolicyNumber}
                />
              </View>
              <View style={[s.redRow, { width: '205' }]}>
                <SimplyCellPdf
                  name={`b. RESERVED FOR NUUC USE`}
                  content={data?.reservedForNUCC}
                />
              </View>
              <View style={[s.row, { width: '205' }]}>
                <SimplyCellPdf
                  name={`c. RESERVED FOR NUUC USE`}
                  content={data?.reservedForNUCC}
                />
              </View>
            </View>

            <CellPdf width={150} height={100}>
              <View
                style={[
                  s.column,
                  { width: 150, gap: 0, alignItems: 'flex-start', marginLeft: 5 },
                ]}
              >
                <Text style={s.title}>10. IS PATIENT'S CONDITION RELATED TO:</Text>

                <View
                  style={[
                    s.column,
                    { width: 150, alignItems: 'flex-start', marginTop: 12 },
                  ]}
                >
                  <Text style={s.title}>a. EMPLOYMENT? (Current or Previous)</Text>
                  <View style={[s.row, { gap: 10 }]}>
                    {booleanOptions.map(({ title, subTitle }) => (
                      <CheckBoxPdf
                        key={subTitle}
                        checked={data?.isPatientEmployment === title}
                        subTitle={subTitle}
                      />
                    ))}
                  </View>
                </View>

                <View
                  style={[s.row, { gap: 10, marginTop: 2, alignItems: 'flex-start' }]}
                >
                  <View style={[s.column, { width: 70, alignItems: 'flex-start' }]}>
                    <Text style={s.title}>b. AUTO ACCIDENT?</Text>
                    <View style={[s.row, { gap: 10 }]}>
                      {booleanOptions.map(({ title, subTitle }) => (
                        <CheckBoxPdf
                          key={subTitle}
                          checked={data?.isPatientEmployment === title}
                          subTitle={subTitle}
                        />
                      ))}
                    </View>
                  </View>
                  <View
                    style={[
                      s.column,
                      { alignItems: 'flex-start', justifyContent: 'flex-start' },
                    ]}
                  >
                    <Text style={[s.title, { paddingBottom: 1 }]}>PLACE (State)</Text>
                    <Text style={[s.text, { paddingLeft: 5 }]}>{data?.state}</Text>
                  </View>
                </View>
                <View
                  style={[
                    s.column,
                    { width: 150, alignItems: 'flex-start', marginTop: 2 },
                  ]}
                >
                  <Text style={s.title}>c. OTHER ACCIDENT?</Text>
                  <View style={[s.row, { gap: 10 }]}>
                    {booleanOptions.map(({ title, subTitle }) => (
                      <CheckBoxPdf
                        key={subTitle}
                        checked={data?.isPatientEmployment === title}
                        subTitle={subTitle}
                      />
                    ))}
                  </View>
                </View>
              </View>
            </CellPdf>

            <View style={[s.column, { alignItems: 'flex-start', flex: 1 }]}>
              <View style={[s.redRow, { width: '205' }]}>
                <ColumnPdf
                  name={`9. INSURED'S POLICY GROUP OR FECA NUMBER`}
                  content={data?.insuredPolicyGroupFECANumber}
                />
              </View>
              <View style={[s.redRow, { width: '205' }]}>
                <CellPdf width={205}>
                  <View style={s.column}>
                    <Text style={[s.title, { paddingBottom: 0 }]}>
                      a. INSURED'S DATE OF BIRTH
                    </Text>
                    <BirthDayPdf date={data?.insuredBirthDate} />
                  </View>

                  <View style={[s.column, { width: 55 }]}>
                    <Text style={s.title}>SEX</Text>
                    <View style={[s.row, { gap: 10 }]}>
                      {genderOptions.map(({ title, prefix }) => (
                        <CheckBoxPdf
                          key={title}
                          checked={data?.patientGender.toLowerCase() === title}
                          prefix={prefix}
                        />
                      ))}
                    </View>
                  </View>
                </CellPdf>
              </View>

              <View style={[s.redRow, { width: '205' }]}>
                <ColumnPdf
                  name={`b. OTHER CLAIM ID (Designated by NUCC)`}
                  content={data?.otherClaimId}
                />
              </View>

              <ColumnPdf
                name={`c. INSURANCE PLAN NAME OR PROGRAM NAME AETNA`}
                content={data?.insurancePlanNameOrProgramName}
                textFontSize={8}
              />
            </View>
          </View>

          <View style={s.redRow}>
            <SimplyCellPdf
              name={`d. INSURANCE PLAN NAME OR PROGRAM NAME`}
              content={data?.insurancePlanNameOrProgramName}
              textFontSize={8}
            />
            <ColumnPdf
              name={`10d. CLAIM CODES (Designated by NUCC)`}
              content={data?.diagnosisCodes?.map(i => i.code).join(',')}
              width={150}
              textFontSize={8}
            />
            <CellPdf width={205}>
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text style={s.title}>d. IS THERE ANOTHER HEALTH BENEFIT PLAN?</Text>
                <View style={[s.row, { gap: 10, marginLeft: 5 }]}>
                  {booleanOptions.map(({ title, subTitle }) => (
                    <CheckBoxPdf
                      key={subTitle}
                      checked={data?.isAnotherHealthBenefitPlan === title}
                      subTitle={subTitle}
                    />
                  ))}
                </View>
              </View>
            </CellPdf>
          </View>

          <View style={[s.row, { height: 50 }]}>
            <View
              style={[
                s.column,
                {
                  flex: 1,
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                },
              ]}
            >
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text
                  style={[
                    s.title,
                    { paddingBottom: 1, textAlign: 'center', width: '100%' },
                  ]}
                >
                  READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS FORM.
                </Text>
                <Text style={[s.title, { paddingTop: 0 }]}>
                  12. PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE I authorize the release
                  of any medical or other information necessary to process this claim, I
                  also request payment of government benefits either to myself or to the
                  party who accepts assignment below.
                </Text>
              </View>

              <View style={[s.row, { gap: 17 }]}>
                <View style={[s.row, { gap: 7 }]}>
                  <Text style={s.title}>SIGNED</Text>
                  {data?.patientSignature ? (
                    <Text style={s.textUnderlie}>{data?.patientSignature}</Text>
                  ) : (
                    <View style={s.underline} />
                  )}
                </View>
                <View style={[s.row, { gap: 7 }]}>
                  <Text style={s.title}>DATE</Text>
                  {data?.patientSignatureDate ? (
                    <Text style={s.textUnderlie}>
                      {dayjs(data?.patientSignatureDate).format('MM/DD/YYYY')}
                    </Text>
                  ) : (
                    <View style={s.underline} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={[
                s.column,
                {
                  alignItems: 'flex-start',
                  width: 205,
                  borderLeft: 1,
                  height: 50,
                  borderColor: '#EC2027',
                  justifyContent: 'space-between',
                },
              ]}
            >
              <View style={[s.column, { alignItems: 'flex-start' }]}>
                <Text style={[s.title, { paddingBottom: 1 }]}>
                  13. INSURED'S OR AUTHORIZED PERSON'S SIGNATURE
                </Text>
                <Text style={[s.title, { paddingTop: 0 }]}>
                  I authorize payment of medical benefits to the undersigned physician or
                  supplier for services described below.
                </Text>
              </View>
              <View style={[s.row, { gap: 7, marginBottom: 2 }]}>
                <Text style={s.title}>SIGNED</Text>
                {data?.patientSignature ? (
                  <Text style={s.textUnderlie}>{data?.patientSignature}</Text>
                ) : (
                  <View style={s.underline} />
                )}
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={s.column}>
        <Text style={[s.arrowSec, { transform: 'rotate(-90deg)' }]}>{'-->'}</Text>
        <Text style={s.columnRowSec}>PATIENT AND INSURED INFORMATION</Text>
        <Text style={[s.arrowSec, { transform: 'rotate(90deg)' }]}>{'-->'}</Text>
      </View>
    </View>
  );
};
