import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const CheckBoxWrapper = styled.div``;

export const CheckBoxItem = styled(Checkbox)<{ disabled?: boolean }>`
  &.MuiCheckbox-root {
    color: #555;
    opacity: 0.5;
    border-radius: 3px;

    &.Mui-checked {
      opacity: 1;
      pointer-events: ${props => (props.disabled ? 'none' : 'all')};
      color: ${props => (props.disabled ? '#848A9B' : '#0084B1')};
    }
  }

  &.MuiButtonBase-root {
    padding: 0;
  }

  & .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

  &.big {
    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`;
