import { Popover } from '@mui/material';
import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const NotificationWrapper = styled(Popover)`
  width: 100%;

  & ::-webkit-scrollbar {
    width: 20px;
  }

  & ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  & ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin: 10px 0px 22px 16px;

  @media ${devices.xs} {
    margin: 10px 0px 12px 16px;
  }
`;

export const SeeMore = styled.div`
  color: #0084b1;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 24px;
  margin-bottom: 11px;
  align-self: center;

  cursor: pointer;
`;

export const Content = styled.div``;
