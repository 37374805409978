import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { UserStatus } from '../../../types/UserStatuses';
import { Roles } from '../../../types/Roles';
import { Clinics } from '../../../types/ClinicTypes';
import { AccessLevel, InvoiceType } from '../../../types/enums/AppointmentEnum';

export interface Signature {
  id: string;
  url: string;
  key: string;
  mimetype: string;
  createdAt: string;
}

export interface UserCred {
  role: string;
  status: string;
  phone: string;
  id: string;
  firstName: string;
  isNotificationsEnabled: boolean;
  lastName: string;
  email: string;
  avatar: {
    url: string;
    name: string;
  };
  clinics: Array<Clinics>;
  selectedClinicId: string;
  invoicePostPaymentAccessLevel: AccessLevel;
  invoicePrePaymentAccessLevel: AccessLevel;
  invoiceSheetAccessLevel: AccessLevel;
  claimAccessLevel: AccessLevel;
  signature: Signature | null;
}

const initialState: UserCred = {
  role: '',
  status: '',
  phone: '',
  id: '',
  email: '',
  isNotificationsEnabled: true,
  firstName: '',
  lastName: '',
  avatar: {
    url: '',
    name: '',
  },
  clinics: [],
  selectedClinicId: '',
  invoicePostPaymentAccessLevel: AccessLevel.READ,
  invoicePrePaymentAccessLevel: AccessLevel.READ,
  invoiceSheetAccessLevel: AccessLevel.READ,
  claimAccessLevel: AccessLevel.READ,
  signature: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        role,
        status,
        phone,
        id,
        firstName,
        lastName,
        email,
        avatar,
        clinics,
        isNotificationsEnabled,
        selectedClinicId,
        invoicePostPaymentAccessLevel,
        invoicePrePaymentAccessLevel,
        invoiceSheetAccessLevel,
        claimAccessLevel,
        signature,
      } = action.payload;
      return {
        ...state,
        phone,
        role,
        email,
        isNotificationsEnabled,
        status,
        id,
        firstName,
        lastName,
        avatar,
        clinics,
        selectedClinicId,
        invoicePostPaymentAccessLevel,
        invoicePrePaymentAccessLevel,
        invoiceSheetAccessLevel,
        claimAccessLevel,
        signature,
      };
    },
  },
});

export const { setUser } = authSlice.actions;

export const role = (state: RootState) => state.auth?.role;
export const status = (state: RootState) => state.auth?.status;
export const userPhone = (state: RootState) => state.auth?.phone;
export const userId = (state: RootState) => state.auth?.id;
export const userInfo = (state: RootState) => state.auth;

export default authSlice.reducer;
