import dayjs from 'dayjs';
import { AppointmentProcessInterface, Step } from '../../../../types/AppointmentNewTypes';
import { DigitalTemplateType } from '../../../../types/TemplateType';

export const acuInsuranceFormik = (
  name?: string,
  data?: AppointmentProcessInterface,
  currentStep?: Step,
) => {
  return {
    mdType: DigitalTemplateType.ACUPUNCTURE_INSURANCE,
    date: data ? dayjs().format('MM/DD/YYYY') : '',
    birthDate: '',
    age: 0,
    gender: '',
    patientName: '',
    insuranceCo: currentStep ? currentStep?.digitalForm?.payload?.insuranceCo : '',
    memberId: currentStep ? currentStep?.digitalForm?.payload?.memberId : '',
    planGroup: currentStep ? currentStep?.digitalForm?.payload?.planGroup : '',
    userId: '',
    patientPhone: '',
    coverAcupunture: currentStep
      ? currentStep?.digitalForm?.payload?.coverAcupunture
      : false,
    coverNetworkAcupunture: currentStep
      ? currentStep?.digitalForm?.payload?.coverNetworkAcupunture
      : false,
    countTreatmensPerCalendarYear: currentStep
      ? currentStep?.digitalForm?.payload?.countTreatmensPerCalendarYear
      : 0,
    countTreatmensUsedThisYear: currentStep
      ? currentStep?.digitalForm?.payload?.countTreatmensUsedThisYear
      : 0,
    deductible: {
      inNet: {
        met: currentStep ? currentStep?.digitalForm?.payload?.deductible?.inNet?.met : 0,
        total: currentStep
          ? currentStep?.digitalForm?.payload?.deductible?.inNet?.total
          : 0,
      },
      outNet: {
        met: currentStep ? currentStep?.digitalForm?.payload?.deductible?.outNet?.met : 0,
        total: currentStep
          ? currentStep?.digitalForm?.payload?.deductible?.outNet?.total
          : 0,
      },
    },
    pocketLimit: {
      inStart: currentStep ? currentStep?.digitalForm?.payload?.pocketLimit?.inStart : 0,
      inEnd: currentStep ? currentStep?.digitalForm?.payload?.pocketLimit?.inEnd : 0,
      outStart: currentStep
        ? currentStep?.digitalForm?.payload?.pocketLimit?.outStart
        : 0,
      outEnd: currentStep?.digitalForm?.payload?.pocketLimit?.outEnd ?? 0,
    },
    insuranceCoOnPolicy: {
      inNetwork: currentStep?.digitalForm?.payload?.insuranceCoOnPolicy?.inNetwork ?? '',
      outNetwork:
        currentStep?.digitalForm?.payload?.insuranceCoOnPolicy?.outNetwork ?? '',
    },
    copay: {
      in: currentStep?.digitalForm?.payload?.copay?.in ?? 0,
      out: currentStep?.digitalForm?.payload?.copay?.out ?? 0,
    },
    isReferralRequired: currentStep?.digitalForm?.payload?.isReferralRequired ?? false,
    isPriorAuthRequired: currentStep?.digitalForm?.payload?.isPriorAuthRequired ?? false,
    notes: currentStep?.digitalForm?.payload?.notes ?? '',
    associatesName: currentStep?.digitalForm?.payload?.associatesName ?? '',
    reference: currentStep?.digitalForm?.payload?.reference ?? '',
    time: '',
    procCodeCovered: {
      '97813': currentStep?.digitalForm?.payload?.procCodeCovered[97813] ?? false,
      '97814': currentStep?.digitalForm?.payload?.procCodeCovered[97814] ?? false,
      '97810': currentStep?.digitalForm?.payload?.procCodeCovered[97810] ?? false,
      '97811': currentStep?.digitalForm?.payload?.procCodeCovered[97811] ?? false,
    },
  };
};
