import { useEffect, useState } from 'react';
import { useAppointmentUpdateStatusMutation } from '../../../../../../../store/api/appointment/appointmentApi';
import { AppointmentsNew } from '../../../../../../../types/AppointmentNewTypes';
import { AppointmentStatus } from '../../../../../../../types/StatusTypes';
import AppointmentPinV2 from '../../../../../../atoms/AppointmentPinV2';
import RadioButton from '../../../../../../atoms/RadioButton';
import * as S from './styles';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../store/slices/message';

type Props = {
  appointment: AppointmentsNew;
};

const ChangeStatusPopoverContent: React.FC<Props> = ({ appointment }) => {
  const selectedStatus = appointment.status;
  const [changeStatus] = useAppointmentUpdateStatusMutation({});

  const [newStatus, setNewStatus] = useState(appointment.status);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newStatus !== appointment.status) {
      changeStatus({
        id: appointment.id,
        status: newStatus,
      })
        .unwrap()
        .then(res =>
          dispatch(
            setMessage({
              message: 'Status was successfully updated',
              type: 'success',
            }),
          ),
        )
        .catch(err => dispatch(setMessage({ message: err.data.message, type: 'error' })));
    }
  }, [newStatus]);

  return (
    <S.ContentWrapper>
      {appointment.status !== AppointmentStatus.COMPLETED && (
        <S.ContentRow
          onClick={() => {
            setNewStatus(AppointmentStatus.CANCELED);
          }}
          $checked={newStatus === AppointmentStatus.CANCELED}
        >
          <RadioButton checked={newStatus === AppointmentStatus.CANCELED} />
          <AppointmentPinV2 type={AppointmentStatus.CANCELED} />
        </S.ContentRow>
      )}

      {appointment.status !== AppointmentStatus.CANCELED &&
        appointment.status !== AppointmentStatus.COMPLETED &&
        appointment.status !== AppointmentStatus.ACTIVE && (
          <S.ContentRow
            onClick={() => {
              setNewStatus(AppointmentStatus.PENDING);
            }}
            $checked={newStatus === AppointmentStatus.PENDING}
          >
            <RadioButton checked={newStatus === AppointmentStatus.PENDING} />
            <AppointmentPinV2 type={AppointmentStatus.PENDING} />
          </S.ContentRow>
        )}
      {appointment.status !== AppointmentStatus.CANCELED &&
        appointment.status !== AppointmentStatus.COMPLETED && (
          <S.ContentRow
            $checked={selectedStatus === AppointmentStatus.ACTIVE}
            onClick={() => {
              setNewStatus(AppointmentStatus.ACTIVE);
            }}
          >
            <RadioButton checked={newStatus === AppointmentStatus.ACTIVE} />
            <AppointmentPinV2 type={AppointmentStatus.ACTIVE} />
          </S.ContentRow>
        )}

      {appointment.status !== AppointmentStatus.CANCELED &&
        appointment.status !== AppointmentStatus.PENDING && (
          <S.ContentRow
            onClick={() => {
              setNewStatus(AppointmentStatus.COMPLETED);
            }}
            $checked={newStatus === AppointmentStatus.COMPLETED}
          >
            <RadioButton checked={newStatus === AppointmentStatus.COMPLETED} />
            <AppointmentPinV2 type={AppointmentStatus.COMPLETED} />
          </S.ContentRow>
        )}
    </S.ContentWrapper>
  );
};

export default ChangeStatusPopoverContent;
