import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px;
  gap: 10px;
`;

export const SearchLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ArticleWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SelectWrapper = styled.div`
  width: 160px;

  &.xl {
    width: 250px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;

  & svg {
    position: absolute;
  }
`;

export const SelectInputOutputText = styled.div`
  margin-left: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Footer = styled.div`
  width: 100%;
  background: #f4f4f4;
  height: 60px;
`;

export const ButtonWrapper = styled.div`
  width: 100px;
`;

export const Btns = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  gap: 10px;
`;
