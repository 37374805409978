import React, { useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { TableCell, TableRow } from '@mui/material';
import { ServiceType } from 'types/ServicesType';
import { ServiceTableCell } from './ServiceTableCell';
import { useDeleteServiceMutation } from 'store/api/services/servicesApi';
import { setMessage } from 'store/slices/message';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { Dialog } from 'components';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data?: ServiceType[];
  headers: HeaderValue[];
  setShowEditServiceDrawer: (serviceId: string) => void;
};

export const ServiceTableRow = ({ data, headers, setShowEditServiceDrawer }: Props) => {
  const dispatch = useAppDispatch();
  const [showConfirmDialog, setShowConfirmDialog] = useState<string | null>(null);
  const [deleteService] = useDeleteServiceMutation({});

  const handleSuccessDelete = () => {
    !!showConfirmDialog &&
      deleteService({
        id: showConfirmDialog,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Service was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        })
        .finally(() => setShowConfirmDialog(null));
  };

  return (
    <>
      {data &&
        data?.map((row, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => setShowEditServiceDrawer(row.id)}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <ServiceTableCell
                  header={header.key}
                  row={row}
                  setShowConfirmDialog={setShowConfirmDialog}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}

      <Dialog open={!!showConfirmDialog} onClose={() => setShowConfirmDialog(null)}>
        <ConfirmDeleteDialog
          onClose={() => setShowConfirmDialog(null)}
          titleText="service"
          helperText="This process is irreversible"
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
    </>
  );
};
