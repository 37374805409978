import { ClinicInsuranceType } from '../../types/enums/AppointmentEnum';

export const getNetworkTypeName = (str: ClinicInsuranceType | string) => {
  switch (str) {
    case ClinicInsuranceType.IN_NETWORK:
      return 'In network';
    case ClinicInsuranceType.OUT_OF_NETWORK:
      return 'Out of network';
    default:
      return null;
  }
};
