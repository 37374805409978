import styled from 'styled-components';

export const Wrapper = styled.div``;

export const RecordItem = styled.div`
  width: 100%;
  min-height: 48px;
  /* border: 1px solid red;
  box-sizing: border-box; */
  display: flex;
  flex-direction: row;

  /* cursor: pointer; */
`;

export const BtnColumn = styled.div`
  min-width: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;

  &.signatures {
    color: var(--icon, #848a9b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  svg {
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
`;
