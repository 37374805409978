import { ChangeEvent, useMemo, useState } from 'react';
import Input from '../../../atoms/Input';
import * as S from './styles';
import Button from '../../../atoms/Button';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CheckBox from '../../../atoms/CheckBox';
import { Roles } from '../../../../types/Roles';
import { useNotificationCreateMutation } from '../../../../store/api/notifications/notificationsApi';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../store/slices/message';

type Props = {
  onClose: () => void;
};

const CreateNotificationDialog: React.FC<Props> = ({ onClose }) => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [isForAll, setIsForAll] = useState(false);

  const [createNotification] = useNotificationCreateMutation({});

  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const userInfo = useAppSelector(state => state.auth);

  const disableButton = useMemo(() => !text || !title, [text, title]);
  const dispatch = useAppDispatch();

  const handleConfirmClick = () => {
    onClose();
    createNotification({
      title: title,
      message: text,
      clinicId: isForAll ? null : selectedClinic.id,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Notification was successfully created',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <S.Content>
      <S.Article>Create Notification</S.Article>
      <S.InputWrapper>
        <Input
          label="Title"
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTitle(e.target.value);
          }}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Input
          multiline
          rows={5}
          label="Text"
          value={text}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setText(e.target.value);
          }}
        />
      </S.InputWrapper>
      {userInfo.role === Roles.SUPER_ADMIN && (
        <S.CheckboxWrapper>
          <S.Label>Notify all clinics</S.Label>
          <CheckBox checked={isForAll} onChange={() => setIsForAll(!isForAll)} />
        </S.CheckboxWrapper>
      )}
      <S.ButtonWrapper>
        <Button text="Send" onClick={handleConfirmClick} disabled={disableButton} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default CreateNotificationDialog;
