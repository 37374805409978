import styled, { css } from 'styled-components';
import { devices } from '../../../../../../../constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const ScrollDiv = styled.div`
  width: 100%;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const SubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 362px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  @media ${devices.xs} {
    width: 250px;
  }
`;

export const Patient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Information = styled.div`
  width: 940px;
`;

export const PatientTitle = styled.div`
  padding-left: 6px;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 5px;

  svg,
  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
`;

export const HelperText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 500px;
`;

export const Reason = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  max-width: 350px;
  line-height: normal;
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Header = styled.div`
  width: 940px;
  height: 38px;
  display: flex;
  align-items: center;

  background: #e7e8ec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const DateHeader = styled.div`
  min-width: 160px;
  width: 160px;
  margin-left: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ClaimHeader = styled.div`
  min-width: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InsuranceHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ExistingLines = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  overflow: auto;
`;

export const Loader = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Placeholder = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
`;

export const LineWrapper = styled.div<{ $isSelected: boolean }>`
  width: 100%;
  display: flex;

  background: ${props => (props.$isSelected ? '#e5f0f6' : '#F9F9FC')};

  &:hover {
    background-color: #ebf1f5;
    cursor: pointer;
  }
`;

export const SelectInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid var(--icon, #e7e8ec);
  box-sizing: border-box;
`;

export const Date = styled.div`
  width: 160px;
  max-width: 160px;
  min-width: 160px;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  text-align: center;
  word-wrap: break-word;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid var(--icon, #e7e8ec);
  box-sizing: border-box;
`;

export const Insurance = styled.div`
  width: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid var(--icon, #e7e8ec);
  box-sizing: border-box;
`;
