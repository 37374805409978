import dayjs from 'dayjs';

export const getLastYears = () => {
  const currentYear = dayjs().year();
  const arrYears = [];
  let year = currentYear;

  while (year > currentYear - 15) {
    arrYears.push(year);
    year--;
  }

  return arrYears;
};
