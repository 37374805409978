import { useParams } from 'react-router-dom';
import { ErrorHistoricalMessageSVG, ErrorMessageSVG } from 'assets/icons';
import {
  useLazyGetClaimErrorsHistoryQuery,
  useLazyGetClaimErrorsMessagesHistoryQuery,
} from 'store/api/claims/claimsApi';
import * as S from '../../styles';
import ClaimMessageErrorCard from '../ClaimMessageErrorCard';
import React, { useEffect, useState } from 'react';
import { CircularLoader } from 'components';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type Props = {
  claimId: string;
};

const ClaimHistoryMessages = ({ claimId }: Props) => {
  const params = useParams();

  const [getAllErrorHistory, statusErrorHistory] = useLazyGetClaimErrorsHistoryQuery({});

  const [getAllErrorMessages, statusMessages] = useLazyGetClaimErrorsMessagesHistoryQuery(
    {},
  );

  const [page, setPage] = useState(1);

  useEffect(() => {
    getAllErrorMessages({
      page: page,
      take: 100,
      claimId: claimId,
      type: 'Payer Messages',
    });
    getAllErrorHistory({
      page: page,
      take: 100,
      claimId: claimId,
      type: 'History Errors',
    });
  }, [claimId, page]);

  return (
    <>
      {statusMessages.isFetching || statusMessages.isLoading ? (
        <S.Loader>
          <CircularLoader color="#0084B1" size={20} />
        </S.Loader>
      ) : (
        <>
          <S.TopLine />
          <Accordion
            disableGutters
            sx={{
              background: '#f9f9fc',
              boxShadow: 'none',
              border: '1px solid #efefef',
              '&:before': {
                display: 'none',
              },

              '&.Mui-expanded': {
                background: '#f9f9fc',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              sx={{
                '& .MuiAccordionSummary-expandIconWrapper': {
                  position: 'absolute',
                  right: 20,
                },
              }}
            >
              <S.ArticleSmallWrapper>
                <ErrorMessageSVG />
                <S.Title>Payer Messages ({statusMessages.data?.count || '0'})</S.Title>
              </S.ArticleSmallWrapper>
            </AccordionSummary>

            <AccordionDetails
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {statusMessages.data &&
                statusMessages.data.rows?.map(item => {
                  return (
                    <ClaimMessageErrorCard
                      key={item.id}
                      color="#f2bfbf"
                      title={ucFirst(item?.field?.split('.')?.at(-1) ?? '')}
                      claimError={item}
                      clickable
                    />
                  );
                })}
            </AccordionDetails>
          </Accordion>
          {/*<S.Line>
            <ErrorMessageSVG />
            <S.Title>Payer Messages ({statusMessages.data?.count || '0'})</S.Title>
          </S.Line>
          {statusMessages.data &&
            statusMessages.data.rows?.map(item => {
              return (
                <ClaimMessageErrorCard
                  key={item.id}
                  color="#f2bfbf"
                  title={ucFirst(item?.field?.split('.')?.at(-1) ?? '')}
                  claimError={item}
                  clickable
                />
              );
            })}*/}

          {/*<S.TopLine />*/}
          <Accordion
            disableGutters
            sx={{
              background: '#f9f9fc',
              boxShadow: 'none',
              border: '1px solid #efefef',
              '&:before': {
                display: 'none',
              },

              '&.Mui-expanded': {
                background: '#f9f9fc',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              sx={{
                '& .MuiAccordionSummary-expandIconWrapper': {
                  position: 'absolute',
                  right: 20,
                },
              }}
            >
              <S.ArticleSmallWrapper>
                <ErrorHistoricalMessageSVG />
                <S.Title>
                  Historical Errors ({statusErrorHistory.data?.count || '0'})
                </S.Title>
              </S.ArticleSmallWrapper>
            </AccordionSummary>

            <AccordionDetails
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {statusErrorHistory.data &&
                statusErrorHistory.data.rows?.map(item => {
                  return (
                    <ClaimMessageErrorCard
                      color="#faceb9"
                      key={item.id}
                      title={ucFirst(item?.field?.split('.')?.at(-1) ?? '')}
                      claimError={item}
                      clickable
                    />
                  );
                })}
            </AccordionDetails>
          </Accordion>
          {/*<S.Line>
            <ErrorHistoricalMessageSVG />
            <S.Title>Historical Errors ({statusErrorHistory.data?.count || '0'})</S.Title>
          </S.Line>
          {statusErrorHistory.data &&
            statusErrorHistory.data.rows?.map(item => {
              return (
                <ClaimMessageErrorCard
                  color="#faceb9"
                  key={item.id}
                  title={ucFirst(item?.field?.split('.')?.at(-1) ?? '')}
                  claimError={item}
                  clickable
                />
              );
            })}*/}
        </>
      )}
    </>
  );
};

export default ClaimHistoryMessages;
