import { useEffect, useState } from 'react';
import { ApproveBtnSVG, EditBtnSVG, ResetApproveBtnSVG } from '../../../../assets/icons';
import { UserProfile } from '../../../../types/UserProfileTypes';
import * as S from './styles';
import CheckBox from '../../../atoms/CheckBox';
import { useUpdateOnlyProfileMutation } from '../../../../store/api/profile/profileApi';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../store/slices/message';
import { useParams } from 'react-router-dom';

type Props = {
  rows: UserProfile | undefined;
};

const AdditionalHealthInfo: React.FC<Props> = ({ rows }) => {
  const [isEditable, setIsEditable] = useState(false);

  const [isContagiousDisease, setIsContagiousDisease] = useState(false);
  const [isCoagulation, setIsCoagulation] = useState(false);
  const [isPacemaker, setIsPacemaker] = useState(false);
  const [isMetalSupport, setIsMetalSupport] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();

  const [updateProfile] = useUpdateOnlyProfileMutation({});

  useEffect(() => {
    if (rows) {
      setIsContagiousDisease(rows?.profile.health.isContagiousDisease || false);
      setIsCoagulation(rows?.profile.health.isCoagulationDysfunction || false);
      setIsPacemaker(rows?.profile.health.isPacemaker || false);
      setIsMetalSupport(rows?.profile.health.isMetalSupport || false);
      setIsPregnant(rows?.profile.health.isPregnant || false);
    }
  }, [rows]);

  return (
    <S.Wrapper>
      <S.ArticleWrapper>
        <S.EditBtnWrapper>
          <S.Article>Health info</S.Article>
          {isEditable ? (
            <S.ButtonsEdit>
              <ResetApproveBtnSVG
                onClick={() => {
                  setIsEditable(false);
                  if (rows) {
                    setIsContagiousDisease(rows?.profile.health.isContagiousDisease);
                    setIsCoagulation(rows?.profile.health.isCoagulationDysfunction);
                    setIsPacemaker(rows?.profile.health.isPacemaker);
                    setIsMetalSupport(rows?.profile.health.isMetalSupport);
                    setIsPregnant(rows?.profile.health.isPregnant);
                  }
                }}
              />
              <ApproveBtnSVG
                onClick={() => {
                  const health = {
                    isContagiousDisease,
                    isCoagulationDysfunction: isCoagulation,
                    isPacemaker,
                    isMetalSupport,
                    isPregnant,
                  };
                  setIsEditable(false);
                  updateProfile({ id: rows?.profile?.id, health })
                    .unwrap()
                    .then(res => {
                      setIsEditable(false);
                      dispatch(
                        setMessage({
                          message: res.message,
                          type: 'success',
                        }),
                      );
                    })
                    .catch(error => {
                      dispatch(
                        setMessage({
                          message: error.data.message,
                          type: 'error',
                        }),
                      );
                    });
                }}
              />
            </S.ButtonsEdit>
          ) : (
            <EditBtnSVG onClick={() => setIsEditable(true)} />
          )}
        </S.EditBtnWrapper>
      </S.ArticleWrapper>
      <S.Content>
        <S.CheckBoxes>
          <S.CheckBoxWrapper>
            <S.CheckBoxLabel>
              Do you have a<S.StyledSpan> contagious disease?</S.StyledSpan>
            </S.CheckBoxLabel>
            <CheckBox
              checked={isContagiousDisease}
              value={isContagiousDisease}
              disabled={!isEditable}
              onChange={() => setIsContagiousDisease(!isContagiousDisease)}
            />
          </S.CheckBoxWrapper>
          <S.CheckBoxWrapper>
            <S.CheckBoxLabel>
              Do you have
              <S.StyledSpan> coagulation dysfunction disease?</S.StyledSpan>
            </S.CheckBoxLabel>
            <CheckBox
              checked={isCoagulation}
              value={isCoagulation}
              disabled={!isEditable}
              onChange={() => setIsCoagulation(!isCoagulation)}
            />
          </S.CheckBoxWrapper>
          <S.CheckBoxWrapper>
            <S.CheckBoxLabel>
              Do you have <S.StyledSpan> pacemaker?</S.StyledSpan>
            </S.CheckBoxLabel>
            <CheckBox
              checked={isPacemaker}
              value={isPacemaker}
              disabled={!isEditable}
              onChange={() => setIsPacemaker(!isPacemaker)}
            />
          </S.CheckBoxWrapper>
          <S.CheckBoxWrapper>
            <S.CheckBoxLabel>
              Do you have
              <S.StyledSpan> metal support?</S.StyledSpan>
            </S.CheckBoxLabel>
            <CheckBox
              checked={isMetalSupport}
              value={isMetalSupport}
              disabled={!isEditable}
              onChange={() => setIsMetalSupport(!isMetalSupport)}
            />
          </S.CheckBoxWrapper>
          <S.CheckBoxWrapper>
            <S.CheckBoxLabel>
              Are you <S.StyledSpan> pregnant?</S.StyledSpan>
            </S.CheckBoxLabel>
            <CheckBox
              checked={isPregnant}
              value={isPregnant}
              disabled={!isEditable}
              onChange={() => setIsPregnant(!isPregnant)}
            />
          </S.CheckBoxWrapper>
        </S.CheckBoxes>
      </S.Content>
    </S.Wrapper>
  );
};

export default AdditionalHealthInfo;
