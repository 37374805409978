import { useDoctorClaimListQuery } from 'store/api/billing/billingApi';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { DialogClaimListProps } from '../AccTableBody';
import { DoctorClaimResponse } from 'types/AccTableTypes';
import { useNavigate } from 'react-router-dom';
import useInfinityScroll from 'hooks/useInfinityScroll';
import CircularLoader from 'components/atoms/CircuralLoader';

type Props = {
  onClose: () => void;
  dialogProps: DialogClaimListProps | null;
};

const CLAIM_IN_LIST_LIMIT = 10;

const ClaimList: React.FC<Props> = ({ onClose, dialogProps }) => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const getClaimList = useDoctorClaimListQuery({
    clinicId: dialogProps?.clinicId ?? '',
    date: dialogProps?.date ?? '',
    doctorId: dialogProps?.doctorId ?? '',
    insuranceType: dialogProps?.insuranceType ?? '',
    insuranceName: dialogProps?.insuranceName ?? '',
    take: CLAIM_IN_LIST_LIMIT,
    page,
  });

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(page + 1),
    hasMore:
      (getClaimList.currentData?.page ?? 0) < (getClaimList.currentData?.lastPage ?? 0),
  });

  useEffect(() => {
    if (dialogProps) {
      getClaimList.refetch();
    }
  }, [dialogProps, page]);

  return (
    <S.Content>
      {getClaimList.isFetching && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={16} />
        </S.Loader>
      )}
      <S.Article>Claim list</S.Article>
      <S.Content>
        {getClaimList.data?.rows?.map((item: DoctorClaimResponse, index, { length }) => {
          const refLast = index === length - 1 ? setLastElement : null;

          return (
            <S.Row
              key={item.id}
              onClick={() => navigate(`/claim-form/${item.id}`)}
              ref={refLast}
            >
              <S.Text>Claim {item.id}</S.Text>
              <S.PatientName>• {item.patientName}</S.PatientName>
            </S.Row>
          );
        })}
      </S.Content>
    </S.Content>
  );
};

export default ClaimList;
