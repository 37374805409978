import { Input } from '../../../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from '../../styles';

const ListAllConditions = () => {
  return (
    <S.Lines>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Condition 1" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Years" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Months" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Days" />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Condition 2" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Years" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Months" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Days" />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Condition 3" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Years" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Months" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Days" />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <CheckBoxWithLabel label="Hot or Cold" />
        <CheckBoxWithLabel label="Spontaneously" />
        <CheckBoxWithLabel label="Night Sweating" />
        <CheckBoxWithLabel label="Headeche" />
        <CheckBoxWithLabel label="Dizziness" />
        <CheckBoxWithLabel label="Cold or warm weather" />
        <CheckBoxWithLabel label="Up stair" />
        <CheckBoxWithLabel label="Down stair" />
      </S.Line>
    </S.Lines>
  );
};

export default ListAllConditions;
