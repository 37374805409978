import { DateObject } from 'react-multi-date-picker';
import { getDay } from './getDay';
import { getWeekInitial } from './getWeekInitial';
import { getMonth } from './getMonth';
import { getYear } from './getYear';

export const handleBackDate = (
  mode: string,
  setSelectedDate: (value: React.SetStateAction<DateObject[]>) => void,
  selectedDate: DateObject[],
  setPage?: (value: React.SetStateAction<number>) => void,
) => {
  if (setPage) {
    setPage(1);
  }
  if (mode === 'Day') {
    setSelectedDate(getDay(selectedDate[0].subtract(1, 'days')));
  }
  if (mode === 'Week') {
    setSelectedDate(getWeekInitial(selectedDate[0].subtract(7, 'days')));
  }
  if (mode === 'Month') {
    setSelectedDate(getMonth(selectedDate[0].subtract(1, 'month')));
  }
  if (mode === 'Year') {
    setSelectedDate(getYear(selectedDate[0].subtract(1, 'year')));
  }
};

export const handleForwardDate = (
  mode: string,
  setSelectedDate: (value: React.SetStateAction<DateObject[]>) => void,
  selectedDate: DateObject[],
  setPage?: (value: React.SetStateAction<number>) => void,
) => {
  if (setPage) {
    setPage(1);
  }
  if (mode === 'Day') {
    setSelectedDate(getDay(selectedDate[0].add(1, 'days')));
  }
  if (mode === 'Week') {
    setSelectedDate(getWeekInitial(selectedDate[0].add(7, 'days')));
  }
  if (mode === 'Month') {
    setSelectedDate(getMonth(selectedDate[0].add(1, 'month')));
  }
  if (mode === 'Year') {
    setSelectedDate(getYear(selectedDate[0].add(1, 'year')));
  }
};

export const handgleChangeMode = (
  mode: string,
  setSelectedDate: (value: React.SetStateAction<DateObject[]>) => void,
  setPage?: (value: React.SetStateAction<number>) => void,
) => {
  if (setPage) {
    setPage(1);
  }
  if (mode === 'Day') {
    setSelectedDate(getDay(new DateObject()));
  }
  if (mode === 'Week') {
    setSelectedDate(getWeekInitial(new DateObject()));
  }
  if (mode === 'Month') {
    setSelectedDate(getMonth(new DateObject()));
  }
  if (mode === 'Year') {
    setSelectedDate(getYear(new DateObject()));
  }
};

export const handleChangeDate = (
  mode: string,
  setSelectedDate: (value: React.SetStateAction<DateObject[]>) => void,
  value: any,
  setPage?: (value: React.SetStateAction<number>) => void,
) => {
  if (setPage) {
    setPage(1);
  }
  if (mode === 'Day') {
    setSelectedDate([]);
    setSelectedDate([getDay(value.slice(-1)[0])[0], getDay(value.slice(-1)[0])[1]]);
  }
  if (mode === 'Week') {
    setSelectedDate(value);
  }
  if (mode === 'Month') {
    setSelectedDate([]);
    setSelectedDate([getMonth(value.slice(-1)[0])[0], getMonth(value.slice(-1)[0])[1]]);
  }
  if (mode === 'Year') {
    setSelectedDate([]);
    setSelectedDate([getYear(value.slice(-1)[0])[0], getYear(value.slice(-1)[0])[1]]);
  }
};
