import styled from 'styled-components';

export const CardWrapper = styled.div<{ $editable: boolean }>`
  border-radius: 10px;
  background: var(--hover, #f5f5f5);
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
  border: ${props => (props.$editable ? '1px solid #0084B1' : 'none')};
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Date = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
`;

export const HeaderCard = styled.div`
  padding: 14px 20px;
  border-bottom: 0.5px solid var(--line, #d7d7d7);
`;

export const ContentWrapper = styled.div`
  padding: 15px 20px;
  height: calc(100% - 170px);
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  gap: 25px;
  flex-direction: column;
`;

export const WorkingHours = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 150px;
`;

export const Time = styled.div`
  color: var(--text, #202e5f);
  text-align: right;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ArticleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 50px;
  margin-top: 15px;
`;

export const Article14 = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const ChangeScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    path {
      stroke: #0084b1;
    }
    rect {
      stroke: #0084b1;
    }
  }
`;

export const BtnText = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export const DayOff = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background: #e6e6e6;
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  max-width: 176px;
  width: 176px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  max-width: 500px;

  .line {
    margin-top: 15px;
  }
`;
