import { FormikProps } from 'formik';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import Input from 'components/atoms/Input';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { ChangeEvent, useEffect, useState } from 'react';
import OptionContent from '../OptionContent';
import ReactInputMask from 'react-input-mask';
import { OptionType } from '../OptionContent/OptionContent';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  setLastElement: any;
  data: any;
  isLoading: boolean;
  setPage: (page: number) => void;
};

const Phone: React.FC<Props> = ({
  formik,
  byClick,
  userProfile,
  selectedAppointment,
  duplAppointment,
  selectedValue,
  setSelectedValue,
  setLastElement,
  data,
  isLoading,
  setPage,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    if (!selectedValue) {
      formik.setFieldValue('phone', searchValue);
    }
  }, [searchValue]);

  return (
    <>
      {byClick ? (
        <AutoCompleteInput
          setPage={setPage}
          data={data || []}
          isRequired
          customRender={true}
          mainLabel={'Phone'}
          loading={isLoading}
          searchValue={searchValue}
          options={data || []}
          setSearchValue={setSearchValue}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          disabled={!!selectedValue || selectedAppointment?.isPossibleToUpdate === false}
          getOptionLabel={(option: any) =>
            !selectedValue ? formik.values.phone : option && option?.phone
          }
          error={!!formik.errors.phone}
          helperText={formik.errors.phone as string}
          renderOption={(props: any, option: any) => {
            const refLast =
              data?.length === props['data-option-index'] + 1 ? setLastElement : null;

            return (
              <OptionContent
                type={OptionType.PHONE}
                searchValue={searchValue}
                textToHighlight={option?.phone || ''}
                key={option.id}
                otherText={option.profile?.firstName || ''}
                props={props}
                option={option}
                loading={isLoading}
                // @ts-ignore
                ref={refLast}
              />
            );
          }}
        />
      ) : (
        <ReactInputMask
          mask="+19999999999"
          value={formik.values.phone}
          onChange={handleChangeInput}
          disabled={
            !!userProfile?.phone ||
            !!selectedAppointment?.patient?.phone ||
            !!duplAppointment?.patient?.phone ||
            !!selectedValue
          }
        >
          <Input
            label="Phone"
            id="phone"
            isRequired
            name="phone"
            error={!!formik.errors.phone}
            helperText={formik.errors.phone as string}
          />
        </ReactInputMask>
      )}
    </>
  );
};

export default Phone;
