import dayjs from 'dayjs';
import { CustomTable } from '../../../../../../../../components';
import { ClaimEventHistoryType } from '../../../../../../../../types/ClaimTypes';
import * as S from './styles';
import ModifiedInformationRow from './TableData/ModifiedInformationRow';

type Props = {
  claimEvent?: ClaimEventHistoryType;
};

const DialogModifiedInformation = ({ claimEvent }: Props) => {
  const headers = [
    { name: 'Description', key: 'Description' },
    { name: 'New Value', key: 'NewValue' },
    { name: 'Old Value', key: 'OldValue' },
  ];

  return (
    <S.Content>
      <S.Article>Modified</S.Article>
      <S.Item>
        <S.Header>Date/Time:</S.Header>
        <S.Text>{dayjs(claimEvent?.createdAt).format('MM/DD/YYYY h:mm A')}</S.Text>
      </S.Item>
      <S.Item>
        <S.Header>Message:</S.Header>
        <S.Text>The transaction has been modified</S.Text>
      </S.Item>
      <S.Item>
        <S.Header>Modifier:</S.Header>
        <S.Text>
          {claimEvent?.modifier
            ? claimEvent.modifier.profile.firstName +
              ' ' +
              claimEvent.modifier.profile.lastName
            : 'System'}
        </S.Text>
      </S.Item>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <ModifiedInformationRow headers={headers} data={claimEvent?.changeList} />
        </CustomTable>
      </S.TableWrapper>
    </S.Content>
  );
};

export default DialogModifiedInformation;
