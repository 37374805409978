import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-left: 36px;
  padding-top: 25px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Article14 = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SchedLine = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 15px;
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const SchedBtnWrapper = styled.div<{ disabled?: boolean }>`
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 0px;
`;

export const ArticleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 50px;
  margin-bottom: 20px;
`;

export const AddClinicBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const AddClinicBtn = styled.div`
  width: 200px;
`;
