import React, { useMemo } from 'react';
import { Document, Page, View, Text, PDFViewer } from '@react-pdf/renderer';
import { generateStylesConfig } from './generateStylesConfig';
import { ClaimValues } from 'types/AppointmentNewTypes';
import { HeaderPdf, FooterPdf } from './components';
import { PatientInsurancePart } from './PatientInsurancePart';
import { InjuryPart } from './InjuryPart';
import { ProviderPart } from './ProviderPart';
import { ServicesHeaderPart } from './ServicesHeaderPart';
import { ServiceRow } from './ServiceRow';

type Props = {
  data?: ClaimValues;
};

const SERVICES_PER_PAGE = 6;

export const GenerateClaimFormPdf = ({ data }: Props) => {
  const s = generateStylesConfig();

  const getPages = useMemo(() => {
    const pagesCount = Math.ceil((data?.services?.length || 1) / SERVICES_PER_PAGE);
    return Array.from(new Array(pagesCount));
  }, [data?.services?.length]);

  const getServices = useMemo(() => {
    return Array.from(new Array(getPages?.length * SERVICES_PER_PAGE));
  }, [getPages]);

  return (
    <PDFViewer style={s.viewer}>
      <Document>
        {getPages.map((_, outIndex) => {
          return (
            <Page size="A4" style={s.page} key={outIndex}>
              <HeaderPdf />

              <PatientInsurancePart data={data} />

              <View style={[s.row, { justifyContent: 'space-between' }]}>
                <View style={[s.column, { alignItems: 'flex-start' }]}>
                  <View style={[s.table, { marginTop: -1, height: 384 }]}>
                    <InjuryPart data={data} />

                    <ServicesHeaderPart />

                    {getServices
                      ?.slice(
                        outIndex * SERVICES_PER_PAGE,
                        outIndex * SERVICES_PER_PAGE + SERVICES_PER_PAGE,
                      )
                      .map((value: any, inIndex) => {
                        return (
                          <ServiceRow
                            key={inIndex}
                            index={inIndex + 1}
                            row={data?.services?.at(
                              outIndex * SERVICES_PER_PAGE + inIndex,
                            )}
                          />
                        );
                      })}

                    <ProviderPart data={data} />
                  </View>
                </View>

                <View style={s.column}>
                  <Text style={[s.arrowSec, { transform: 'rotate(-90deg)' }]}>
                    &mdash;&gt;
                  </Text>
                  <Text style={[s.columnRowSec, { marginTop: 170, marginBottom: 170 }]}>
                    PHYSICAN OR SUPPLIER INFORMATION
                  </Text>
                  <Text style={[s.arrowSec, { transform: 'rotate(90deg)' }]}>
                    &mdash;&gt;
                  </Text>
                </View>
              </View>

              <FooterPdf />
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
};
