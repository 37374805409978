import styled from 'styled-components';
import { devices } from '../../../../constants/device';
import { Stepper } from '@mui/material';

export const WindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
  width: 100%;
`;

export const InputSmall = styled.div`
  width: 350px;
`;

export const DontAnswerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-left: 3px;
`;

export const Header = styled.div`
  height: 64px;
  width: 100%;
  border-bottom: 0.5px solid #fff;
  background: #fff;
  display: flex;
  align-items: center;

  & img {
    width: 110px;
    height: 49px;
    object-fit: contain;
    margin-left: 138px;
  }

  @media ${devices.xs} {
    margin-top: 20px;
    background: none;
    border-bottom: none;
    align-items: center;
    justify-content: center;

    & img {
      margin: 0;
    }
  }
`;

export const ContentWrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;

  height: calc(100vh - 100px);

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 28px;
    margin-top: 20px;
    width: 348px;
  }
`;

export const PageArticle = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: none;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
  }
`;

export const AvatarDiv = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 262px;

  @media ${devices.xs} {
    width: 100%;
    margin-top: 0px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const AvatarHelper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  flex-direction: column;

  @media ${devices.xs} {
    width: 262px;
    margin-top: 8px;
  }
`;

export const TextHelper = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &.xs {
    font-size: 12px;
  }
`;

export const AvatarClickZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const AvatarZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 144px;
    height: 144px;
    object-fit: cover;
    border-radius: 144px;
  }
  svg {
    width: 144px;
    height: 144px;
  }
`;

export const DeleteCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background: #c66060;
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 70px;
  right: 65px;
  cursor: pointer;
`;

export const InputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 806px;
  margin-top: 17px;
  margin-bottom: 31px;

  @media ${devices.xs} {
    width: 346px;
    margin-bottom: 0px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 5px;
`;

export const ArticleInsurance = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Inputs = styled.div`
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 18px;

  @media ${devices.xs} {
    margin-top: 18px;
    gap: 10px;
  }
`;

export const ArticleH3 = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  margin-left: 5px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 784px;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 5px;

  @media ${devices.xs} {
    width: 330px;
  }
`;

export const CheckBoxLabel = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.xl {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
`;

export const StyledSpan = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledColorSpan = styled.span`
  color: var(--text, #0084b1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const InsuranceInformation = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const InsuranceInputs = styled.div`
  margin-top: 25px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;

  @media ${devices.xs} {
    gap: 5px;

    &.label {
      flex-direction: column;
    }

    &.devide {
      flex-wrap: nowrap;
    }
  }
`;

export const CheckBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;

  @media ${devices.xs} {
  }
`;

export const DropzoneWrapper = styled.div`
  width: 100%;

  &.insurance {
    margin-top: 15px;
  }
`;

const getColor = ({ isDragAccept, isDragReject }: any) => {
  if (isDragAccept) {
    return '#0084B1';
  }
  if (isDragReject) {
    return '#ff1744';
  }
  return '#D7D7D7';
};

export const DropzoneContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed ${props => getColor(props)};
  background: #fff;

  &.insurance {
    height: 60px;
  }
`;

export const TextDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UpperText = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DownText = styled.div`
  color: #202e5f;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 171px;
  height: 16px;
`;

export const AsideFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  padding-bottom: 40px;
`;

export const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & .icon {
    width: 24px;
    height: 24px;
    margin-left: 9px;
  }

  & .delete {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 9px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  @media ${devices.xs} {
    width: 300px;
  }
`;

export const Footer = styled.div`
  height: 80px;
  width: 100%;
  background: #fff;
  box-shadow: 0px -2px 27px 0px rgba(0, 84, 113, 0.2);
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;

  @media ${devices.xs} {
    background: none;
    background: #f9f9fc;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    position: relative;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  height: 257px;

  @media ${devices.xs} {
    width: 320px;
    height: 245px;
  }
`;

export const DialogArticle = styled.div`
  margin-top: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const DialogSubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 362px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 280px;
    margin-bottom: 21px;
  }
`;

export const DialogCheckboxWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 18px;

  @media ${devices.xs} {
    gap: 10px;
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  align-self: end;
  margin-top: 6px;
  margin-right: 10px;
  cursor: pointer;
`;

export const DialogTextCheckbox = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 375px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 174px;

  &.xs {
    width: 130px;
  }
  @media ${devices.xs} {
    width: 280px;
  }
`;

export const ButtonWrapperNext = styled.div`
  width: 174px;
  margin: 10px;

  @media ${devices.xs} {
    width: 80px;
    margin-bottom: 20px;
  }
`;

export const StepperWrapper = styled.div`
  padding-top: 50px;
  padding-left: 200px;
  padding-right: 200px;

  @media ${devices.xs} {
    padding: 0;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .MuiSvgIcon-root.Mui-active {
    color: #0084b1;
  }

  & .MuiSvgIcon-root.Mui-completed {
    color: #0084b1;
  }

  & .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
  }

  & .MuiStepIcon-text {
    font-family: Inter;
    font-size: 12px;
    color: #fff;
  }

  & .MuiStepLabel-label {
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media ${devices.xs} {
      display: none;
    }
  }

  & .MuiStepLabel-label.Mui-active {
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const FooterButtonWrapper = styled.div`
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${devices.xs} {
    padding: 0;
  }
`;

export const StepContent = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 280px;
  margin-right: 280px;
  width: 100%;

  @media ${devices.xs} {
    margin: 0;
  }
`;

export const ContactInfoWrapper = styled.div`
  margin-top: 30px;
  width: 800px;
  @media ${devices.xs} {
    width: 375px;
    margin-top: 10px;
  }
`;

export const HandleButtons = styled.div`
  display: flex;
  padding-right: 158px;
`;
