import { useState } from 'react';
import { CheckBox } from '../../../../../../components';
import * as S from '../../styles';

type Props = {
  formik: any;
};

const HealthSignUp: React.FC<Props> = ({ formik }) => {
  return (
    <>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.ArticleH3>Health info</S.ArticleH3>
          <S.Inputs>
            <S.CheckBoxes>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel>
                  Do you have a<S.StyledSpan> contagious disease?</S.StyledSpan>
                </S.CheckBoxLabel>
                <CheckBox
                  id="isContagiousDisease"
                  name="isContagiousDisease"
                  checked={formik.values.isContagiousDisease}
                  value={formik.values.isContagiousDisease}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel>
                  Do you have{' '}
                  <S.StyledSpan> coagulation dysfunction disease?</S.StyledSpan>
                </S.CheckBoxLabel>
                <CheckBox
                  checked={formik.values.isCoagulation}
                  id="isCoagulation"
                  name="isCoagulation"
                  value={formik.values.isCoagulation}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel>
                  Do you have <S.StyledSpan> pacemaker?</S.StyledSpan>
                </S.CheckBoxLabel>
                <CheckBox
                  checked={formik.values.isPacemaker}
                  id="isPacemaker"
                  name="isPacemaker"
                  value={formik.values.isPacemaker}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel>
                  Do you have
                  <S.StyledSpan> metal support?</S.StyledSpan>
                </S.CheckBoxLabel>
                <CheckBox
                  checked={formik.values.isMetalSupport}
                  id="isMetalSupport"
                  name="isMetalSupport"
                  value={formik.values.isMetalSupport}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel>
                  Are you <S.StyledSpan> pregnant?</S.StyledSpan>
                </S.CheckBoxLabel>
                <CheckBox
                  id="isPregnant"
                  name="isPregnant"
                  checked={formik.values.isPregnant}
                  value={formik.values.isPregnant}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
            </S.CheckBoxes>
          </S.Inputs>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default HealthSignUp;
