import React, { useEffect, useState } from 'react';
import { Roles } from 'types/Roles';
import {
  Button,
  CustomPagination,
  CustomTable,
  Dialog,
  SearchInput,
  SelectInput,
} from 'components';
import { AddClinicIconSVG } from 'assets/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDebounce } from 'use-debounce';
import { useLazyInsuranceListWithGroupsGetQuery } from 'store/api/insuranceRelative/insuranceRelativeApi';
import { InsuranceTableRow } from 'pages/SuperAdmin/components/Insurance/components';
import { useNavigate } from 'react-router-dom';
import { searchKeys, headers } from './config';
import { MenuItem } from '@mui/material';
import * as S from './styles';

const INSURANCES_LIMIT = 20;

export const Insurance = () => {
  const navigate = useNavigate();
  const userInfo = useAppSelector(state => state.auth);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState<string>(searchKeys[0].key);

  const [getInsuranceList, listResult] = useLazyInsuranceListWithGroupsGetQuery();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    getInsuranceList({
      take: INSURANCES_LIMIT,
      asc: 'DESC',
      searchKey: debouncedSearch ? searchKey : undefined,
      page,
      q: debouncedSearch || undefined,
    });
  }, [debouncedSearch, getInsuranceList, page, searchKey]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Insurance</S.SubtitleText>
        <S.SubtitleItems>
          {userInfo.role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button text="Add Insurance" onClick={() => navigate(`new`)}>
                <AddClinicIconSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.SubtitleBar>

      <S.Content>
        <S.HeaderTable>
          <CustomPagination
            isFetching={listResult.isFetching}
            setPage={setPage}
            currentPage={listResult.currentData?.page}
            lastPage={listResult.currentData?.lastPage}
            count={listResult?.currentData?.count}
            text={'insurances'}
            limit={INSURANCES_LIMIT}
          />
          <S.RightFilters>
            <S.SearchWrapper>
              <SelectInput
                label=""
                background="#fff"
                value={searchKey}
                onChange={e => {
                  setSearchKey(e.target.value as string);
                }}
              >
                {searchKeys.map(item => {
                  return (
                    <MenuItem key={item.name} value={item.key}>
                      <S.MenuItemContent>{item.name}</S.MenuItemContent>
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </S.SearchWrapper>
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.RightFilters>
        </S.HeaderTable>
        <S.TableBody>
          <CustomTable headers={headers} isFetching={listResult.isFetching}>
            <InsuranceTableRow
              data={listResult?.currentData?.rows}
              headers={headers}
              clickable
            />
          </CustomTable>
        </S.TableBody>
      </S.Content>
    </S.Wrapper>
  );
};
