import dayjs from 'dayjs';
import { DeleteBtnSVG } from '../../../../../../../../assets/icons';
import { ucFirst } from '../../../../../../../../helpers/functions/toUpperCase';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { useRelativeDeleteMutation } from '../../../../../../../../store/api/insuranceRelative/insuranceRelativeApi';
import { setMessage } from '../../../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';

type Row = {
  id: string;
  firstName: string;
  lastName: string;
  sex: string;
  dateOfBirth: string;
  cellPhone: string;
  linkedUserId: string;
};

type Props = {
  row: Row;
};

const RelatedRow: React.FC<Props> = ({ row }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteRow] = useRelativeDeleteMutation({});

  return (
    <S.Wrapper>
      <S.Information>
        {row.linkedUserId ? (
          <S.Text
            className="link"
            onClick={() => navigate(`/patients/${row.linkedUserId}`)}
          >
            {row?.firstName + ' ' + row?.lastName}
          </S.Text>
        ) : (
          <S.Text className="bold">{row?.firstName + ' ' + row?.lastName}</S.Text>
        )}
        <S.Text>{row.sex ? ucFirst(row.sex) : '-'}</S.Text>
        <S.Text>
          {row.dateOfBirth ? dayjs(row.dateOfBirth).format('MM/DD/YYYY') : '-'}
        </S.Text>
        <S.Text>{row.cellPhone || '-'}</S.Text>

        <S.DeleteBtnWrapper
          onClick={() =>
            deleteRow({
              id: row.id,
            })
              .unwrap()
              .then(res =>
                dispatch(
                  setMessage({
                    message: 'Relation was successfully deleted',
                    type: 'success',
                  }),
                ),
              )
              .catch(err =>
                dispatch(
                  setMessage({
                    message: 'Something went wrong',
                    type: 'error',
                  }),
                ),
              )
          }
        >
          <DeleteBtnSVG />
        </S.DeleteBtnWrapper>
      </S.Information>
    </S.Wrapper>
  );
};

export default RelatedRow;
