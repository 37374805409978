import { AppointmentProcessInterface } from '../../../../types/AppointmentNewTypes';
import { configCompleteStep } from '../configCompleteStep';

export const getCurrentStep = (data?: AppointmentProcessInterface) => {
  if (data) {
    const currentStep = data.steps.find(item => item.isCurrent === true);
    return currentStep;
  }
};

export const getNextStep = (data?: AppointmentProcessInterface) => {
  if (data) {
    const currentStep = data.steps.find(
      item => item.isCompleted === false && item.isCurrent === false,
    );
    return currentStep;
  }
};

export const isCanFinish = (data?: AppointmentProcessInterface) => {
  if (data) {
    const isCan = data.steps.some(item => item.isCompleted === true);
    return isCan;
  }
};

export const isNeedAlert = (data?: AppointmentProcessInterface) => {
  if (data) {
    const isNeed = data.steps.some(item => item.isCompleted === false);
    return isNeed;
  }
};

export const handleCompleteStep = (
  data: AppointmentProcessInterface,
  setOpenSignature: React.Dispatch<React.SetStateAction<boolean>>,
  completeStep: any,
) => {
  if (configCompleteStep(getCurrentStep(data))) {
    setOpenSignature(true);
  } else {
    completeStep({});
  }
};
