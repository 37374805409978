import { FormikProps } from 'formik';
import { Input } from '../../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';
import * as S from '../../styles';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  setIsDirty?: () => void;
};
const Assesment = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => {
    return (
      <>
        <S.Article>Assesment</S.Article>
        <S.Line>
          <CheckBoxWithLabel
            label="Patient tolerated treatment"
            defaultChecked={formik.values?.assessment.treatment.isTolerated}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'assessment.treatment.isTolerated', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Patient presents with the following deficits"
            defaultChecked={formik.values?.assessment.treatment.isDeficits}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'assessment.treatment.isDeficits', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Line>
          <S.InputWrapper>
            <Input
              label="Pain"
              isSum="/10"
              defaultValue={formik.values?.assessment.pain}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'assessment.pain', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="ROM"
              defaultValue={formik.values?.assessment.pom}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'assessment.pom', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="Flexibility"
              defaultValue={formik.values?.assessment.flexibility}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'assessment.flexibility', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="STRENGTH"
              defaultValue={formik.values?.assessment.strength}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'assessment.strength', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <Input
            label="Functional Deficits"
            defaultValue={formik.values?.assessment.funcDificits}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'assessment.funcDificits', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Line>
          <Input
            label="Plan"
            rows={3}
            multiline
            defaultValue={formik.values?.assessment.plan}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'assessment.funcDificits', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Article>SHORT TERM GOALS (2-3weeks)</S.Article>
        <S.Line>
          <S.InputWrapper>
            <Input
              label="Pain"
              isSum="/10"
              defaultValue={formik.values?.shortTermGoals.pain}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'shortTermGoals.pain', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="ROM"
              defaultValue={formik.values?.shortTermGoals.pom}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'shortTermGoals.pom', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="Flexibility"
              defaultValue={formik.values?.shortTermGoals.flexibility}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'shortTermGoals.flexibility', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="STRENGTH"
              defaultValue={formik.values?.shortTermGoals.strength}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'shortTermGoals.strength', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <Input
            label="Functional Deficits"
            defaultValue={formik.values?.shortTermGoals.funcDificits}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'shortTermGoals.funcDificits', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Article>LONG TERM GOALS (2-3weeks)</S.Article>
        <S.Line>
          <S.InputWrapper>
            <Input
              label="Pain"
              isSum="/10"
              defaultValue={formik.values?.longTermGoals.pain}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'longTermGoals.pain', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="ROM"
              defaultValue={formik.values?.longTermGoals.pom}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'longTermGoals.pom', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="Flexibility"
              defaultValue={formik.values?.longTermGoals.flexibility}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'longTermGoals.flexibility', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="STRENGTH"
              defaultValue={formik.values?.longTermGoals.strength}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'longTermGoals.strength', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <Input
            label="Functional Deficits"
            defaultValue={formik.values?.longTermGoals.funcDificits}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'longTermGoals.funcDificits', ref, setIsDirty)
            }
          />
        </S.Line>
      </>
    );
  },
);

export default Assesment;
