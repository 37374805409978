import { Link, useParams } from 'react-router-dom';
import {
  AddRectSVG,
  DeleteBtnSVG,
  DeleteRedBtnSVG,
  JPGSVG,
  PDFSVG,
} from '../../../../../../assets/icons';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import {
  useDeleteFileMutation,
  useFilesCreateToProfileMutation,
  useFilesGetQuery,
  useUploadInitialDocsMutation,
} from '../../../../../../store/api/files/files';
import * as S from './styles';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { Roles } from '../../../../../../types/Roles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../../../store/slices/message';
import {
  Accordeon,
  CircularLoader,
  Dialog,
  LinearLoader,
  NotificationPopover,
} from '../../../../../../components';
import dayjs from 'dayjs';
import PDFDrawer from '../../../../../../components/organismus/PDFDrawer/PDFDrawer';
import { useGetTestPDFMutation } from '../../../../../../store/api/debug/debugApi';
import {
  useFoldersDocumentsGetQuery,
  useFormsDocumentsMutation,
  useInitialDocumentsMutation,
  useOtherDocumentsMutation,
} from '../../../../../../store/api/treatment/treatmentApi';
import { getFileName } from '../../../../../../helpers/functions/fileName';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';
import { userId } from '../../../../../../store/api/auth/authSlice';
import useDispatchSetMessage from '../../../../../../hooks/useDispatchSetMessage';

type Props = {
  rows: UserProfile | undefined;
};

const Documents: React.FC<Props> = ({ rows }) => {
  const id = useAppSelector(state => state.auth.id);
  const params = useParams();
  const userInfo = useAppSelector(state => state.auth);
  const dispatch = useDispatch();

  const createdById = params.patientId ? params.patientId : id;

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const files = useFilesGetQuery({
    createdById,
  });

  const getFolders = useFoldersDocumentsGetQuery({
    clinicId: selectedClinic.id,
    userId: createdById,
  });

  const [deleteFile, deleteStatus] = useDeleteFileMutation({});
  const [uploadFiles, uploadStatus] = useFilesCreateToProfileMutation({});
  const [getInitialDocuments, initialDocsStatus] = useInitialDocumentsMutation({});

  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadInitialDocsMutation({});

  const [getOtherDocuments, otherDocsStatus] = useOtherDocumentsMutation({});

  const [getFormsDocuments, formsDocsStatus] = useFormsDocumentsMutation({});

  const [newFiles, setNewFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (newFiles.length > 0) {
      uploadInitialFiles({ files: newFiles, userId: params.patientId })
        .unwrap()
        .then(res => setNewFiles([]))
        .catch(error => setNewFiles([]));
    } else {
      return;
    }
  }, [newFiles]);

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      setNewFiles([...file]);
    }
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [file, setFile] = useState(null);

  const handleClickDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  const [getTestPDF, getTestPDFStatus] = useGetTestPDFMutation({});

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);

  const handleExpanded = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  const [idForDelete, setIdForDelete] = useState<string>('');

  const handleSuccessDelete = () => {
    if (rows && idForDelete) {
      deleteFile({ id: idForDelete })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Document was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(setMessage({ message: err.data.message, type: 'error' }));
        });
    }
  };

  return (
    <S.DocumentsWrapper>
      <Dialog
        open={isOpenSuccessDialog}
        onClose={() => {
          setIdForDelete('');
          setIsOpenSuccessDialog(!isOpenSuccessDialog);
        }}
      >
        <ConfirmDeleteDialog
          onClose={() => setIsOpenSuccessDialog(!isOpenSuccessDialog)}
          titleText={'file'}
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
      <S.Content>
        <S.Article>Documents</S.Article>
        <S.Files>
          {getFolders.isLoading || getFolders.isFetching ? (
            <LinearLoader />
          ) : (
            <>
              <Accordeon
                docsCount={getFolders.data?.initialDocumentsCount}
                title="Initial documents"
                isInitial={userInfo.role !== Roles.PATIENT}
                onClick={() =>
                  getInitialDocuments({
                    userId: createdById,
                  })
                }
              >
                <S.AccordeonContent onClick={e => e.stopPropagation()}>
                  {initialDocsStatus.isLoading ? (
                    <S.Loader>
                      <CircularLoader color="#0084B1"></CircularLoader>
                    </S.Loader>
                  ) : (
                    <S.AccordeonItems>
                      {initialDocsStatus.data?.map(item => (
                        <S.FileRow
                          key={item.id}
                          onClick={() => window.open(item.url, '_blank')}
                        >
                          <S.InfoRow>
                            {item.name.includes('.pdf') ? (
                              <PDFSVG className="icon" />
                            ) : (
                              <JPGSVG className="icon" />
                            )}
                            {item.name}
                          </S.InfoRow>
                          {userInfo.role !== Roles.PATIENT && (
                            <DeleteRedBtnSVG
                              onClick={e => {
                                setIdForDelete(item.id);
                                e.stopPropagation();
                                setIsOpenSuccessDialog(true);
                              }}
                            />
                          )}
                        </S.FileRow>
                      ))}
                    </S.AccordeonItems>
                  )}
                </S.AccordeonContent>
              </Accordeon>
              {userInfo.role !== Roles.PATIENT && (
                <>
                  <Accordeon
                    title="Other documents"
                    docsCount={getFolders.data?.otherDocumentsCount}
                    onClick={() =>
                      getOtherDocuments({
                        userId: createdById,
                        clinicId: selectedClinic.id,
                      })
                    }
                  >
                    <S.AccordeonContent onClick={e => e.stopPropagation()}>
                      {otherDocsStatus.isLoading ? (
                        <S.Loader>
                          <CircularLoader color="#0084B1"></CircularLoader>
                        </S.Loader>
                      ) : (
                        <S.AccordeonItems>
                          {otherDocsStatus.data?.map(item => (
                            <S.FileRow
                              key={item.id}
                              onClick={() => {
                                window.open(item.url);
                              }}
                            >
                              <S.InfoRow>
                                {item.name.includes('.pdf') ? (
                                  <PDFSVG className="icon" />
                                ) : (
                                  <JPGSVG className="icon" />
                                )}
                                {item.name}
                              </S.InfoRow>
                              <S.CreatedBy>
                                <S.Text className="small">
                                  Date: {dayjs(item.createdAt).format('MM/DD/YYYY')}
                                </S.Text>
                                {item.createdByDoctor?.profile?.firstName ? (
                                  <S.Text>
                                    Created by:{' '}
                                    {item.createdByDoctor?.profile?.firstName +
                                      ' ' +
                                      item.createdByDoctor?.profile?.lastName}
                                  </S.Text>
                                ) : (
                                  <S.Text>Created by: -</S.Text>
                                )}
                                <DeleteRedBtnSVG
                                  onClick={e => {
                                    setIdForDelete(item.id);
                                    e.stopPropagation();
                                    setIsOpenSuccessDialog(true);
                                  }}
                                />
                              </S.CreatedBy>
                            </S.FileRow>
                          ))}
                        </S.AccordeonItems>
                      )}
                    </S.AccordeonContent>
                  </Accordeon>
                </>
              )}
            </>
          )}
        </S.Files>
      </S.Content>
    </S.DocumentsWrapper>
  );
};

export default Documents;
