import * as S from './styles';
import dayjs from 'dayjs';
import { AttachFileSVG, ClinicPlaceholderSVG } from 'assets/icons';
import { ChatItemType } from 'types/ChatTypes';

type Props = {
  children?: React.ReactNode;
  clinicName?: string;
  text?: string;
  src?: string | undefined;
  msgCount: number;
  chat: ChatItemType;
  $active: boolean;
};
const ChatItem: React.FC<Props> = ({
  children,
  clinicName,
  text,
  src,
  msgCount,
  chat,
  $active,
}) => {
  return (
    <S.ChatItemWrapper $active={$active}>
      <S.Content>
        <S.AvatarDiv>
          {src ? <img src={src} alt="logoClinic" /> : <ClinicPlaceholderSVG />}
        </S.AvatarDiv>
        <S.InformationDiv>
          <S.Article>{clinicName}</S.Article>
          {chat.lastMessageText && <S.Text>{chat.lastMessageText}</S.Text>}
          <>
            {chat.lastMessageFilesCount > 0 && (
              <S.Text>
                <AttachFileSVG /> {' ' + chat.lastMessageFilesCount} files
              </S.Text>
            )}
          </>
        </S.InformationDiv>
      </S.Content>
      <S.DateDiv>
        {dayjs(chat.updatedAt).format('DD MMM')}
        {chat.unreadMessagesCount > 0 && <S.Circle>{chat.unreadMessagesCount}</S.Circle>}
      </S.DateDiv>
    </S.ChatItemWrapper>
  );
};

export default ChatItem;
