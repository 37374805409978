import { FormikProps } from 'formik';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import OptionContent from '../OptionContent';
import Input from 'components/atoms/Input';
import { ChangeEvent, useEffect, useState } from 'react';
import { OptionType } from '../OptionContent/OptionContent';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  setLastElement: any;
  data: any;
  isLoading: boolean;
  setPage: (page: number) => void;
};

const Email: React.FC<Props> = ({
  formik,
  byClick,
  userProfile,
  selectedAppointment,
  duplAppointment,
  selectedValue,
  setSelectedValue,
  setLastElement,
  data,
  isLoading,
  setPage,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    if (!selectedValue) {
      formik.setFieldValue('email', searchValue);
    }
  }, [searchValue]);

  return (
    <>
      {byClick ? (
        <AutoCompleteInput
          setPage={setPage}
          data={data || []}
          isRequired
          customRender={true}
          mainLabel={'Email'}
          loading={isLoading}
          searchValue={searchValue}
          options={data || []}
          setSearchValue={setSearchValue}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          disabled={!!selectedValue || selectedAppointment?.isPossibleToUpdate === false}
          getOptionLabel={(option: any) =>
            !selectedValue ? formik.values.email : option && option?.email
          }
          error={!!formik.errors.email}
          helperText={formik.errors.email as string}
          renderOption={(props: any, option: any) => {
            const refLast =
              data?.length === props['data-option-index'] + 1 ? setLastElement : null;

            return (
              <OptionContent
                searchValue={searchValue}
                key={option.id}
                textToHighlight={option.email || ''}
                otherText={option.profile?.lastName || ''}
                props={props}
                type={OptionType.EMAIL}
                option={option}
                loading={isLoading}
                // @ts-ignore
                ref={refLast}
              />
            );
          }}
        />
      ) : (
        <Input
          label="Email"
          id="email"
          name="email"
          error={!!formik.errors.email}
          helperText={formik.errors.email as string}
          value={formik.values.email}
          onChange={handleChangeInput}
          disabled={
            !!selectedValue ||
            !!userProfile?.email ||
            !!selectedAppointment?.patient?.email ||
            !!duplAppointment?.patient?.email
          }
        />
      )}
    </>
  );
};

export default Email;
