import { GroupHealthPlanEnum } from 'types/ClaimTypes';
import { Relation } from 'types/enums/AppointmentEnum';

export const healthGroupOptions = [
  { title: GroupHealthPlanEnum.MEDICARE, subTitle: `(${GroupHealthPlanEnum.MEDICARE}#)` },
  { title: GroupHealthPlanEnum.MEDICAID, subTitle: `(${GroupHealthPlanEnum.MEDICAID}#)` },
  { title: GroupHealthPlanEnum.TRICARE, subTitle: '(ID#/DoD#)' },
  { title: GroupHealthPlanEnum.CHAMPVA, subTitle: '(Member ID#)' },
  { title: GroupHealthPlanEnum.GROUPHEALTHPLAN, subTitle: '(ID#)' },
  { title: GroupHealthPlanEnum.FECABLKLUNG, subTitle: '(ID#)' },
  { title: GroupHealthPlanEnum.OTHER, subTitle: '(ID#)' },
];

export const relationOptions = [
  { title: Relation.SELF, subTitle: `Self` },
  { title: Relation.SPOUSE, subTitle: `Spouse` },
  { title: Relation.CHILD, subTitle: 'Child' },
  { title: Relation.OTHER, subTitle: 'Other' },
];

export const genderOptions = [
  { title: 'male', prefix: `M` },
  { title: 'female', prefix: `F` },
];

export const booleanOptions = [
  { title: true, subTitle: `Yes` },
  { title: false, subTitle: `No` },
];

export const alphabeticArray = [
  'A.',
  'B.',
  'C.',
  'D.',
  'E.',
  'F.',
  'G.',
  'H.',
  'I.',
  'J.',
  'K.',
  'L.',
];
