import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  ConfirmationMailing,
  Drawer,
  HorizontalHeader,
  MenuSectionItem,
  VerticalMenu,
} from '../../components';
import * as S from './styles';
import {
  AvatarSVG,
  CalendarSVG,
  CashSVG,
  ChatsSVG,
  ConfirmationMailingSVG,
  DashboardSVG,
  TasksSVG,
  TemplatesSVG,
  TicketSVG,
} from '../../assets/icons';
import Patients from './components/Patients';
import { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import PatientProfile from './components/PatientProfile';
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import { useLastSelectedClinicMutation } from 'store/api/user/profileApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import Tickets from './components/Tickets';
import Cash from './components/Cash';
import Chats from './components/Chats';
import { showDrawer } from 'store/slices/drawerSlice';
import ChooseDrawerContent from '../../services/chooseDrawerContent';
import { DrawerMode } from 'types/DrawerMode';
import { getChats } from 'hooks/useChat';
import AppointmentsView from 'components/organismus/AppointmentsView';
import { Roles } from 'types/Roles';
import Templates from '../SuperAdmin/components/Templates';
import AppointmentProcess from 'components/organismus/AppointmentProcess';
import InitialVisitFormPage from '../SuperAdmin/components/Templates/components/pages/InitialVisitFormPage';
import PagePTInsurance from '../SuperAdmin/components/Templates/components/pages/PagePhysicalTherapyInsurance';
import PageACAInsurance from '../SuperAdmin/components/Templates/components/pages/PageACAInsurance';
import PagePTInitialConsultation from '../SuperAdmin/components/Templates/components/pages/PagePTInitialConsultation';
import PageClientRegistration from '../SuperAdmin/components/Templates/components/pages/ClientRegistrationPage';
import { setIsDirty } from 'store/slices/isDirtyFormSlice';

const Receptionist = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const userInfo = useAppSelector(state => state.auth);
  const [clinic, setClinics] = useState('');

  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [changeSelectedClinic, status] = useLastSelectedClinicMutation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const drawerState = useAppSelector(state => state.drawer);

  const connected = useAppSelector(({ chats }) => chats.connected);
  const chats = useAppSelector(({ chats }) => chats.chats);
  const pendingAppointmentCount = useAppSelector(
    ({ chats }) => chats.pendingAppointmentCount,
  );
  const countMessages = useAppSelector(({ chats }) => chats.chats?.count);
  const { isHover } = useAppSelector(state => state.isHover);
  const { isDirty } = useAppSelector(state => state.isDirty);

  useEffect(() => {
    if (connected) {
      getChats();
    }
  }, [connected]);

  const routeList = [
    {
      article: 'Appointments',
      route: '/appointments',
      icon: (
        <S.IconWrapper>
          <CalendarSVG />
          {
            <S.AppointmentCircle $isHover={isHover}>
              {pendingAppointmentCount?.pendingAppointmentCount}
            </S.AppointmentCircle>
          }
        </S.IconWrapper>
      ),
      appointmentCount: pendingAppointmentCount?.pendingAppointmentCount,
    },
    { article: 'Patients', route: '/patients', icon: <AvatarSVG /> },
    {
      article: 'Chats',
      route: '/chats',
      icon: (
        <S.IconWrapper>
          <ChatsSVG />
          {<S.ChatsCircle $isHover={isHover}>{countMessages}</S.ChatsCircle>}
        </S.IconWrapper>
      ),
      count: countMessages,
    },
    { article: 'Tickets', route: '/tickets', icon: <TasksSVG /> },
    { article: 'Templates', route: '/templates', icon: <TemplatesSVG /> },
    { article: 'Dashboard', route: '/dashboard', icon: <DashboardSVG /> },
    { article: 'Cash', route: '/cash', icon: <CashSVG /> },
    {
      article: 'Confirmation mailing',
      route: '/confirmation-mailing',
      icon: <ConfirmationMailingSVG />,
    },
  ];

  const routeListDoctor = [
    {
      article: 'Appointments',
      route: '/appointments',
      icon: (
        <S.IconWrapper>
          <CalendarSVG />
          {
            <S.AppointmentCircle $isHover={isHover}>
              {pendingAppointmentCount?.pendingAppointmentCount}
            </S.AppointmentCircle>
          }
        </S.IconWrapper>
      ),
      appointmentCount: pendingAppointmentCount?.pendingAppointmentCount,
    },
    { article: 'Patients', route: '/patients', icon: <AvatarSVG /> },
    {
      article: 'Chats',
      route: '/chats',
      icon: (
        <S.IconWrapper>
          <ChatsSVG />
          {<S.ChatsCircle $isHover={isHover}>{countMessages}</S.ChatsCircle>}
        </S.IconWrapper>
      ),
      count: countMessages,
    },
    { article: 'Tickets', route: '/tickets', icon: <TasksSVG /> },
    { article: 'Templates', route: '/templates', icon: <TemplatesSVG /> },
    // { article: "Dashboard", route: "/dashboard", icon: <DashboardSVG /> },
    { article: 'Cash', route: '/cash', icon: <CashSVG /> },
  ];

  return (
    <>
      <Drawer
        open={drawerState.show}
        onClose={() =>
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }))
        }
      >
        <ChooseDrawerContent />
      </Drawer>
      <S.Wrapper>
        <HorizontalHeader isNotify={true} />
        {selectedClinic.id ? (
          <S.Content $isHover={isHover}>
            <Routes>
              {/* Routes forms */}
              <Route
                path="templates/initial-visit-form"
                element={<InitialVisitFormPage />}
              />
              <Route
                path="templates/physical-therapy-insurance"
                element={<PagePTInsurance />}
              />
              <Route
                path="templates/client-registration-form"
                element={<PageClientRegistration />}
              />
              <Route
                path="/templates/acupuncture-insurance"
                element={<PageACAInsurance />}
              />
              <Route
                path="/templates/pt-initial-evaluation"
                element={<PagePTInitialConsultation />}
              />
              {/* Routes forms */}
              <Route path="patients/" element={<Patients />} />
              <Route path="patients/:patientId" element={<PatientProfile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="cash" element={<Cash />} />
              <Route path="chats" element={<Chats />} />
              <Route path="appointments" element={<AppointmentsView />} />
              <Route path="templates" element={<Templates />} />
              <Route path="appointment-process/:id" element={<AppointmentProcess />} />
              <Route path="confirmation-mailing" element={<ConfirmationMailing />} />
              <Route path="*" element={<Navigate to="/appointments" />} />
            </Routes>
          </S.Content>
        ) : (
          <S.Loader>
            <LinearProgress />
          </S.Loader>
        )}
        <VerticalMenu>
          {/* <S.SelWrapper $isHover={isHover}>
              <S.SelectInputWrapper $isHover={isHover}></S.SelectInputWrapper>
            </S.SelWrapper> */}
          <S.Items>
            {userInfo.role === Roles.DOCTOR
              ? routeListDoctor.map(route => {
                  return (
                    <MenuSectionItem
                      key={route.article}
                      text={route.article}
                      onClick={() => {
                        isDirty
                          ? dispatch(
                              setIsDirty({
                                isShowDialog: true,
                                isDirty: true,
                                action: () => navigate(`${route.route}`),
                              }),
                            )
                          : navigate(`${route.route}`);
                      }}
                      $active={location.includes(`${route.route}`) && true}
                      count={route?.count}
                      appointmentCount={route?.appointmentCount}
                    >
                      {route.icon}
                    </MenuSectionItem>
                  );
                })
              : routeList.map(route => {
                  return (
                    <MenuSectionItem
                      key={route.article}
                      text={route.article}
                      onClick={() => navigate(`${route.route}`)}
                      $active={location.includes(`${route.route}`) && true}
                      count={route?.count}
                      appointmentCount={route?.appointmentCount}
                    >
                      {route.icon}
                    </MenuSectionItem>
                  );
                })}
          </S.Items>
          <S.Buttons>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover ? 'Make a ticket' : ''}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      show: true,
                      mode: DrawerMode.TICKET_CREATE,
                    }),
                  )
                }
              >
                <TicketSVG />
              </Button>
            </S.ButtonWrapper>
          </S.Buttons>
        </VerticalMenu>
      </S.Wrapper>
    </>
  );
};

export default Receptionist;
