import { Radio, RadioProps } from '@mui/material';
import * as S from './styles';

type Props = {
  label?: string;
  rightPos?: boolean;
  isBlack?: boolean;
} & RadioProps;

const RadioButton: React.FC<Props> = ({ label, rightPos, isBlack, ...props }) => {
  return (
    <S.Wrapper>
      {label && !rightPos && <S.Label $isBlack={isBlack}>{label}</S.Label>}
      <Radio size="small" {...props} />
      {label && rightPos && <S.Label $isBlack={isBlack}>{label}</S.Label>}
    </S.Wrapper>
  );
};

export default RadioButton;
