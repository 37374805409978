import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '../../../atoms/Button';
import Input from '../../../atoms/Input';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import DatePicker from '../../../atoms/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import SelectInput from '../../../atoms/SelectInput';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  coupounsApi,
  useCouponCreateMutation,
  useCouponSourceGetQuery,
  useCouponUpdateMutation,
} from '../../../../store/api/coupons/couponsApi';
import { ucFirst } from '../../../../helpers/functions/toUpperCase';
import { useClinicsGetQuery } from '../../../../store/api/clinics/clinicApi';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Clinics } from '../../../../types/ClinicTypes';
import { AddRectSVG, CouponNoteSVG, DeleteBtnSVG } from '../../../../assets/icons';
import { getFileIcon } from '../../../../helpers/getFileIcon';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../types/DrawerMode';
import { setMessage } from '../../../../store/slices/message';
import { Coupon } from '../../../../types/CouponTypes';
import { useDeleteFileMutation } from 'store/api/files/files';

type FormValues = {
  title: string;
  from: Dayjs;
  to: Dayjs;
  source: string;
  benefits: string;
  other: string;
  clinics: Clinics[];
};

type Props = {
  coupon: Coupon;
};

const AddCoupon: React.FC<Props> = ({ coupon }) => {
  const Shape = yup.object().shape({});

  const [createCoupon] = useCouponCreateMutation({});
  const [updateCoupon] = useCouponUpdateMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});

  const dispatch = useAppDispatch();
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const handleCreate = () => {
    const data = {
      title: values.title,
      validFrom: values.from.format('YYYY-MM-DD'),
      validTo: values.to.format('YYYY-MM-DD'),
      source: values.source === 'Other' ? values.other : values.source,
      benefit: values.benefits,
      file: newFiles[0],
      clinicId: selectedClinic.id,
    };
    createCoupon(data)
      .unwrap()
      .then(res => {
        dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
        dispatch(
          setMessage({
            message: 'Coupon was successfully created',
            type: 'success',
          }),
        );
        resetForm();
      })
      .catch(error => {
        dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
        dispatch(setMessage({ message: 'Validation error', type: 'error' }));
      });
  };

  const handleUpdate = async () => {
    const data = {
      title: values.title,
      validFrom: values.from.format('YYYY-MM-DD'),
      validTo: values.to.format('YYYY-MM-DD'),
      source: values.source === 'Other' ? values.other : values.source,
      benefit: values.benefits,
      file: newFiles[0],
      clinicId: selectedClinic.id,
      id: coupon?.id,
    };
    if (coupon.fileId && !newFiles?.length) {
      await deleteFile({ id: coupon.fileId });
    }
    await updateCoupon(data)
      .unwrap()
      .then(res => {
        dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
        dispatch(
          setMessage({
            message: 'Coupon was successfully updated',
            type: 'success',
          }),
        );
        resetForm();
      })
      .catch(error => {
        dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
        dispatch(setMessage({ message: 'Validation error', type: 'error' }));
      });
  };

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      title: '',
      from: dayjs(),
      to: dayjs(),
      source: '',
      benefits: '',
      other: '',
      clinics: [],
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {
      if (coupon) {
        handleUpdate();
      } else {
        handleCreate();
      }
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const getSource = useCouponSourceGetQuery({});
  const clinics = useClinicsGetQuery({});

  const userInfo = useAppSelector(state => state.auth);

  const inputRef = useRef<HTMLInputElement>(null);

  const [newFiles, setNewFiles] = useState<Array<File>>([]);

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      setNewFiles([...file]);
    }
  };

  useEffect(() => {
    if (values.to && values.from && values.to < values.from) {
      setFieldValue('to', values.to);
    }
  }, [values.to]);

  useEffect(() => {
    if (coupon) {
      setValues({
        ...values,
        title: coupon.title,
        from: dayjs(coupon.validFrom),
        to: dayjs(coupon.validTo),
        source: coupon.source.value,
        benefits: coupon.benefit,
      });
      !!coupon.file && setNewFiles([coupon.file]);
    }
  }, [coupon]);

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>{coupon ? 'Edit Coupon' : 'Add Coupon'}</S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="title"
                name="title"
                label="Title"
                isRequired
                value={values.title}
                onChange={handleChangeInput}
                error={!!errors.title}
                helperText={errors.title}
              />
            </S.InputRow>
            <S.InputRow>
              <DatePicker
                id="from"
                name="from"
                isRequired
                shouldDisableDate={date => {
                  if (values.to && date > values.to) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                label="Validity from"
                value={values.from}
                onChange={value => {
                  setFieldError('from', '');
                  setFieldValue('from', value);
                }}
                error={!!errors.from}
                helperText={errors.from ? 'Not valid date format' : ''}
              />
              <DatePicker
                id="to"
                shouldDisableDate={date => {
                  if (values.from && date < values.from) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                isRequired
                name="to"
                label="Validity to"
                value={values.to}
                onChange={value => {
                  setFieldError('to', '');
                  setFieldValue('to', value);
                }}
                error={!!errors.to}
                helperText={errors.to ? 'Not valid date format' : ''}
              />
            </S.InputRow>
            <S.InputRow>
              <SelectInput
                label="Source"
                isRequired
                id="source"
                name="source"
                error={!!errors.source}
                helperText={errors.source}
                value={values.source}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('source', '');
                  handleChange(e);
                }}
              >
                {getSource.currentData?.map((source: string) => (
                  <MenuItem key={source} value={source}>
                    <S.MenuItemContent>
                      {/* <CheckBox checked={clinic.indexOf(clinic.id) > -1} /> */}
                      {source}
                    </S.MenuItemContent>
                  </MenuItem>
                ))}
                <MenuItem value={'Other'}>
                  <S.MenuItemContent>Other</S.MenuItemContent>
                </MenuItem>
              </SelectInput>
            </S.InputRow>
            {values.source === 'Other' && (
              <>
                <S.NoteText>
                  <CouponNoteSVG />
                  Fill in the "Other" field to create a new source
                </S.NoteText>
                <S.InputRow>
                  <Input
                    id="other"
                    name="other"
                    label="Other"
                    isRequired
                    value={values.other}
                    onChange={handleChangeInput}
                    error={!!errors.other}
                    helperText={errors.other}
                  />
                </S.InputRow>
              </>
            )}
            <S.InputRow>
              <Input
                id="benefits"
                name="benefits"
                label="Benefits distribution "
                isRequired
                multiline
                rows={4}
                value={values.benefits}
                onChange={handleChangeInput}
                error={!!errors.benefits}
                helperText={errors.benefits}
              />
            </S.InputRow>

            <S.InputRow>
              <S.DownloadBtnWrapper>
                <label htmlFor="file">
                  <S.Btn onClick={() => {}}>
                    <AddRectSVG className="svg" />
                    Add Coupon File
                  </S.Btn>
                  <input
                    ref={inputRef}
                    type="file"
                    id="file"
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg, image/jpg, application/pdf, .doc, .docx, .xslx, .xsl, .csv, .mp4"
                    hidden
                    multiple={false}
                  />
                </label>
              </S.DownloadBtnWrapper>
            </S.InputRow>
            <S.InputRow>
              {newFiles.map((item: File, index) => {
                return (
                  <S.RowItem key={item?.name + index}>
                    {getFileIcon(item?.name.slice(-3))}
                    <S.RowText>{item?.name}</S.RowText>
                    <DeleteBtnSVG
                      onClick={() => {
                        if (inputRef.current) {
                          inputRef.current.value = '';
                        }
                        setNewFiles([]);
                      }}
                    />
                  </S.RowItem>
                );
              })}
            </S.InputRow>
            {/* <S.NoteText>
              <CouponNoteSVG /> This coupon will be sent to all patients
            </S.NoteText> */}
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text={coupon ? 'Update' : 'Create'} type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddCoupon;
