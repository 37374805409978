import { useEffect, useState } from 'react';
import { AppointmentsNew } from '../../../../../types/AppointmentNewTypes';
import { Response } from '../../../../../types/Response';
import CircularLoader from '../../../../atoms/CircuralLoader';
import HeaderLine from '../PlannerView/components/HeaderLine';
import * as S from './styles';
import PendingRecordItem from './components/PendingRecordItem';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { DrawerMode } from '../../../../../types/DrawerMode';

type Props = {
  isModePending: boolean;
  isFetching: boolean;
  allAppointments?: Response<AppointmentsNew>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
};

const PendingView: React.FC<Props> = ({
  isModePending,
  isFetching,
  allAppointments,
  setPage,
  page,
}) => {
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  const handleAppointmentClick = (item: AppointmentsNew) => {
    dispatch(showDrawer({ show: true, mode: DrawerMode.INFO_APPOINTMENT, props: item }));
  };

  return (
    <S.Wrapper>
      <HeaderLine isModePending={isModePending} />
      <S.PlannerItems>
        <S.Records>
          {allAppointments?.rows.map((item, index, { length }) => {
            const isLast =
              index === allAppointments.rows.length - 1 &&
              page < allAppointments.lastPage;

            return (
              <S.ScrollDiv
                key={item.id + index}
                ref={isLast ? setObserverTarget : undefined}
              >
                <PendingRecordItem
                  key={item.id}
                  row={item}
                  onClick={e => {
                    e.stopPropagation();
                    handleAppointmentClick(item);
                  }}
                />
                {isFetching && isLast && (
                  <S.Loader>
                    <CircularLoader color="#0084B1" />
                  </S.Loader>
                )}
              </S.ScrollDiv>
            );
          })}
        </S.Records>
      </S.PlannerItems>
    </S.Wrapper>
  );
};

export default PendingView;
