import styled, { css } from 'styled-components';

export const TemplateList = styled.div`
  background: #edf0f1;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const NavigateButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const LinkButton = styled.div`
  cursor: pointer;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  min-height: 38px;
  height: auto;

  &:hover {
    background-color: #ebf5f9;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Text = styled.div`
  display: flex;
  flex-wrap: wrap;
  word-break: normal;
`;

export const LinkContent = styled.div`
  display: flex;
  margin-left: 15px;
  gap: 10px;
  align-items: center;
`;

export const TempRow = styled.div<{ $isCurrent: boolean }>`
  cursor: pointer;
  color: var(--main, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  height: auto;
  min-height: 36px;
  padding-top: 2px;
  padding-bottom: 2px;

  ${props =>
    props.$isCurrent &&
    css`
      background-color: #d5dce1;
    `}

  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Article = styled.div`
  display: flex;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%);
`;

export const Templates = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 3px;
  align-items: center;
`;

export const TempRowContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 10px;

  svg {
    min-width: 15px;
    min-height: 15px;
  }
`;

export const EditButtonWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 10px;
`;
