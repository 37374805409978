import { useDispatch } from 'react-redux';
import * as S from './styles';
import { Button, DatePicker, Input, SelectInput } from '../../../../../../components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { EyeVisibleSVG } from '../../../../../../assets/icons';
import {
  useCashboxAddRowMutation,
  useCashboxUpdateMutation,
} from '../../../../../../store/api/cash/cashboxApi';
import { setMessage } from '../../../../../../store/slices/message';
import AutoCompleteInput from '../../../../../../components/atoms/AutoComplete';
import { usePatientsGetQuery } from '../../../../../../store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import dayjs, { Dayjs } from 'dayjs';
import { PaymentMethods } from '../../../../../../types/enums/Payment';

type Props = {
  onClose: () => void;
};

const AddRowDialog: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();

  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [startDate, setStartDate] = useState<string | Dayjs | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [paymentMethod, setPaymentMethod] = useState<string | PaymentMethods>(
    PaymentMethods.CLIENT_BY_CASH,
  );

  const rowData = usePatientsGetQuery({
    q: debouncedSearchValue,
    id: selectedClinic.id,
    page: 1,
  });

  const [sum, setSum] = useState('');
  const [note, setNote] = useState('');

  const disableButton = useMemo(
    () => !sum || !selectedValue || !dayjs(startDate).isValid(),
    [startDate, sum, selectedValue],
  );

  const [createRow] = useCashboxAddRowMutation({});

  const handleCreate = () => {
    const data = {
      clinicId: selectedClinic.id,
      ...(paymentMethod === PaymentMethods.CLIENT_BY_CASH && {
        in: Number(sum),
      }),
      ...(paymentMethod === PaymentMethods.CLIENT_BY_CARD && {
        creditCardCash: Number(sum),
        in: 0,
      }),
      note: note,
      patientId: selectedValue.userId,
      date: dayjs(startDate).format('YYYY-MM-DD'),
    };
    onClose();
    createRow(data)
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Row was successfully created',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <S.Content>
      <S.Article>Add Row </S.Article>
      <AutoCompleteInput
        data={rowData?.currentData?.rows || []}
        mainLabel={'Username'}
        loading={!!(rowData.isFetching || rowData.isLoading)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />

      <DatePicker
        // error={!dayjs(startDate).isValid()}
        label="Date"
        value={startDate}
        disableFuture
        onChange={value => setStartDate(value)}
      />
      <SelectInput
        label="Payment method"
        value={paymentMethod}
        onChange={(e: SelectChangeEvent<unknown>) => {
          setPaymentMethod(e.target.value as string);
        }}
      >
        <MenuItem value={PaymentMethods.CLIENT_BY_CASH}>
          <S.MenuItemContent>Cash</S.MenuItemContent>
        </MenuItem>
        <MenuItem value={PaymentMethods.CLIENT_BY_CARD}>
          <S.MenuItemContent>Credit card</S.MenuItemContent>
        </MenuItem>
      </SelectInput>
      <S.InputWrapper>
        <Input
          type="number"
          isSum={'USD'}
          label="Sum"
          value={sum}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSum(e.target.value)}
        />
      </S.InputWrapper>
      <Input
        multiline
        rows={4}
        label="Note"
        value={note}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
      />
      <S.ButtonWrapper>
        <Button text="Add" onClick={handleCreate} disabled={disableButton} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AddRowDialog;
