import { Appointments } from '../../../types/AppointmentsTypes';
import { Note } from '../../../types/NoteTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
  pinnedCount: number;
};

export const dashboardApi = api.injectEndpoints({
  endpoints: builder => ({
    visitsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    appointmentsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/appointments`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    visitsTableGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit/table`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashTicketsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/ticket`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    invoicesGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/invoice`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    masseursGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/masseur`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    patientStatusesGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/patient-statuses`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashVisitReasonsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit-reasons`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashMarketingGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/marketing`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashCashGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/cash`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useVisitsGetQuery,
  useAppointmentsGetQuery,
  useVisitsTableGetQuery,
  useDashTicketsGetQuery,
  useInvoicesGetQuery,
  useMasseursGetQuery,
  usePatientStatusesGetQuery,
  useDashVisitReasonsGetQuery,
  useDashMarketingGetQuery,
  useDashCashGetQuery,
} = dashboardApi;
