export enum Speciality {
  MASSEUR = 'masseur',
  THERAPIST = 'therapist',
  ACUPUNCTURIST = 'acupuncturist',
  PHYSICAL_THERAPIST = 'physical_therapist',
}

export enum FinancialSpecialistAccessLevel {
  LEVEL_1 = 'level_1',
  LEVEL_2 = 'level_2',
  LEVEL_3 = 'level_3',
}
