import { useState } from 'react';
import { AppointmentStatus } from '../types/StatusTypes';

export const getStatusColor = (status: string) => {
  switch (status) {
    case AppointmentStatus.ACTIVE:
      return {
        status: '#79A030',
      };
    case AppointmentStatus.CANCELED:
      return {
        status: '#C66060',
      };
    case AppointmentStatus.COMPLETED:
      return {
        status: '#4570DE',
      };
    case AppointmentStatus.PENDING:
      return {
        status: '#FFC960',
      };
    default: {
      return {
        status: '#FFC960',
      };
    }
  }
};
