import { FormikProps } from 'formik';
import Button from '../../../../atoms/Button';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { add15min } from '../../../../../helpers/functions/add15min';
import {
  useAppointmentCheckBillingMutation,
  useAppointmentCreateMutation,
  useAppointmentUpdateMutation,
} from '../../../../../store/api/appointment/appointmentApi';
import dayjs from 'dayjs';
import SelectInput from '../../../../atoms/SelectInput';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { appointmentStatuses } from '../../../../../constants/constants';
import { ucFirst } from '../../../../../helpers/functions/toUpperCase';
import { AppointmentsNew, Visits } from '../../../../../types/AppointmentNewTypes';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../types/DrawerMode';
import { setMessage } from '../../../../../store/slices/message';
import { configStatuses } from './configStatuses';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { Roles } from '../../../../../types/Roles';
import { useGetTemplateListMutation } from '../../../../../store/api/start-progress/startProgressApi';
import { Dialog, SuccessDialog } from '../../../..';
import DialogStartAppointment from '../DialogStartAppointment';
import { useNavigate } from 'react-router-dom';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
};

const AppointmentFooter: React.FC<Props> = ({
  formik,
  selectedAppointment,
  duplAppointment,
}) => {
  const [createAppointment, createAppointmentStatus] = useAppointmentCreateMutation({});

  const userInfo = useAppSelector(state => state.auth);

  const [updateAppointment] = useAppointmentUpdateMutation({});
  const [getTemplateList, templateListStatus] = useGetTemplateListMutation({});

  const [markCheckedByBilling] = useAppointmentCheckBillingMutation({});

  const hasVisitWithSpecialistId = (visits: Visits[], specialistId: string): boolean => {
    return visits.some(visit => visit.specialistId === specialistId);
  };

  const [isOpenStartProcessDialog, setIsOpenStartProcessDialog] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpenApproveDialog, setIsOpenApproveDialog] = useState(false);

  return (
    <S.Footer>
      <Dialog
        open={isOpenApproveDialog}
        onClose={() => setIsOpenApproveDialog(!isOpenApproveDialog)}
      >
        <SuccessDialog
          helperText="Appointment will become unavailable for editing"
          successText="Approve"
          onClose={() => {
            dispatch(
              showDrawer({
                show: false,
                mode: DrawerMode.DEFAULT,
                props: null,
              }),
            );
          }}
          titleText="
          Are you sure, that you want to approve an information on appointment?"
          onSuccess={() => {
            markCheckedByBilling({
              id: selectedAppointment?.id,
              isCheckBilling: true,
              mainVisitReason: formik.values.mainCoveredService,
              authSigs: formik.values.authSigs,
              globalNote: formik.values.globalNote,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Appointment was successfully mark as checked',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              });
          }}
        />
      </Dialog>
      <S.FooterContent>
        {selectedAppointment &&
          selectedAppointment.status !== AppointmentStatus.COMPLETED &&
          selectedAppointment.status !== AppointmentStatus.CANCELED &&
          userInfo.role !== Roles.BILLING && (
            <S.SelectWrapper>
              <SelectInput
                label=""
                id="status"
                name="status"
                value={formik.values.status}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  formik.setFieldError('status', '');
                  formik.handleChange(e);
                }}
                error={!!formik.errors.status}
                helperText={formik.errors.status}
              >
                {selectedAppointment.status &&
                  configStatuses(selectedAppointment.status as AppointmentStatus)?.map(
                    item => {
                      return (
                        <MenuItem value={item} key={item}>
                          <S.MenuItemContent>{ucFirst(item)}</S.MenuItemContent>
                        </MenuItem>
                      );
                    },
                  )}
              </SelectInput>
            </S.SelectWrapper>
          )}
        <S.Buttons>
          {selectedAppointment &&
          selectedAppointment.status !== AppointmentStatus.COMPLETED &&
          selectedAppointment.status !== AppointmentStatus.CANCELED &&
          !selectedAppointment.inProgress ? (
            <S.BtnWrapper>
              <Button text="Update" type="submit" />
            </S.BtnWrapper>
          ) : (
            <S.BtnWrapper>
              <Button
                text={selectedAppointment?.inProgress ? '(In progress) Back' : 'Back'}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      show: false,
                      mode: DrawerMode.DEFAULT,
                      props: null,
                    }),
                  )
                }
                backgroundColor="#848A9B"
              />
            </S.BtnWrapper>
          )}
          {(!selectedAppointment || duplAppointment) && (
            <S.BtnWrapper>
              <Button text="Create" type="submit" />
            </S.BtnWrapper>
          )}
          {selectedAppointment &&
            !selectedAppointment.inProgress &&
            !selectedAppointment.isCompleted &&
            selectedAppointment.status === AppointmentStatus.ACTIVE &&
            hasVisitWithSpecialistId(selectedAppointment.visits, userInfo.id) &&
            userInfo.role === Roles.DOCTOR && (
              <S.BtnWrapper>
                <Button
                  backgroundColor="#79A030"
                  text="Start Appointment"
                  onClick={() => {
                    setIsOpenStartProcessDialog(!isOpenStartProcessDialog);
                    getTemplateList({
                      appointmentId: selectedAppointment.id,
                      clinicId: selectedAppointment.clinicId,
                    });
                  }}
                />
              </S.BtnWrapper>
            )}
          {selectedAppointment &&
            selectedAppointment.status === AppointmentStatus.COMPLETED &&
            !selectedAppointment.isCheckBilling &&
            userInfo.role === Roles.BILLING && (
              <S.BtnWrapper>
                <Button
                  backgroundColor="#79A030"
                  text="Approve"
                  onClick={() => setIsOpenApproveDialog(!isOpenApproveDialog)}
                />
              </S.BtnWrapper>
            )}

          {selectedAppointment &&
            selectedAppointment.inProgress &&
            hasVisitWithSpecialistId(selectedAppointment.visits, userInfo.id) &&
            userInfo.role === Roles.DOCTOR && (
              <S.BtnWrapper>
                <Button
                  backgroundColor="#79A030"
                  text="Continue Appointment"
                  onClick={() => {
                    dispatch(
                      showDrawer({
                        show: false,
                        mode: DrawerMode.DEFAULT,
                        props: null,
                      }),
                    );
                    navigate(`/appointment-process/${selectedAppointment.id}`);
                    // setIsOpenStartProcessDialog(!isOpenStartProcessDialog);
                    // getTemplateList({
                    //   appointmentId: selectedAppointment.id,
                    //   clinicId: selectedAppointment.clinicId,
                    // });
                  }}
                />
              </S.BtnWrapper>
            )}
        </S.Buttons>
        <Dialog
          open={isOpenStartProcessDialog}
          onClose={() => setIsOpenStartProcessDialog(!isOpenStartProcessDialog)}
        >
          <DialogStartAppointment
            onClose={() => setIsOpenStartProcessDialog(!isOpenStartProcessDialog)}
            isLoading={templateListStatus.isLoading}
            data={templateListStatus.data}
            appointment={selectedAppointment}
          />
        </Dialog>
      </S.FooterContent>
    </S.Footer>
  );
};

export default AppointmentFooter;
