import { NonNullChain } from 'typescript';
import { api } from '../api';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    signUp: builder.mutation({
      query: credentials => ({
        url: '/auth/sign-up',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    verify: builder.mutation({
      query: credentials => ({
        url: '/auth/verify',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    verifyResetPassword: builder.mutation({
      query: credentials => ({
        url: '/auth/verify-reset-password',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    resendCode: builder.mutation({
      query: credentials => ({
        url: '/auth/resend-code',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    authMe: builder.mutation({
      query: () => ({
        url: '/auth/me',
        method: 'GET',
      }),
      invalidatesTags: ['Me'],
    }),
    forgotPassword: builder.mutation({
      query: credentials => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    resetPassword: builder.mutation({
      query: credentials => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    changePhone: builder.mutation({
      query: credentials => ({
        url: '/auth/change-auth-field',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAuthMeMutation,
  useSignUpMutation,
  useLogoutMutation,
  useVerifyMutation,
  useResendCodeMutation,
  useChangePhoneMutation,
  useVerifyResetPasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
