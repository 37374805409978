import { FormikProps } from 'formik';
import { DatePicker, Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';
import { ChangeEvent, forwardRef } from 'react';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<PagePTInitialType>;

  setIsDirty?: () => void;
  // ref: React.MutableRefObject<{}>;
};
const History = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => {
    return (
      <>
        <S.Article>History</S.Article>
        <S.InputWrapper>
          <DatePicker
            label="Date of Onset"
            defaultValue={dayjs(formik.values?.history.dateOfOnset)}
            onChange={value => {
              handleChangeDatePicker(value, 'history.dateOfOnset', ref, setIsDirty);
            }}
          />
        </S.InputWrapper>
        <Input
          label="HPI"
          defaultValue={formik.values?.history.hpi}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'history.hpi', ref, setIsDirty)
          }
        />
        <Input
          label="Past Medical History"
          defaultValue={formik.values?.history.pastMedicalHistory}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'history.pastMedicalHistory', ref, setIsDirty)
          }
        />
      </>
    );
  },
);

export default History;
