import { FormikProps } from 'formik';
import { Input, SelectInput } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { SelectChangeEvent } from '@mui/material';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  setIsDirty?: () => void;
};
const ReasonType = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => {
    return (
      <>
        <S.Article>Reason type</S.Article>
        <S.Line>
          <S.InputWrapper>
            <SelectInput
              label="Reason"
              defaultValue={formik.values?.reasonType.reason.physicalTherary}
              onChange={(e: SelectChangeEvent<unknown>) =>
                handleChangeInputRef(
                  e as ChangeEvent<HTMLInputElement>,
                  'reasonType.reason.physicalTherary',
                  ref,
                  setIsDirty,
                )
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <Input
            label="Special tests"
            defaultValue={formik.values?.reasonType.specialTests}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'reasonType.specialTests', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Line>
          <S.InputWrapper>
            <Input
              label="Gait"
              defaultValue={formik.values?.reasonType.gait}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'reasonType.gait', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="Palpation"
              defaultValue={formik.values?.reasonType.palpation}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'reasonType.palpation', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="Sensation"
              defaultValue={formik.values?.reasonType.sensation}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'reasonType.sensation', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Article>First Treatment</S.Article>
        <S.Line>
          <S.InputWrapper>
            <Input
              label="Manual Tx"
              defaultValue={formik.values?.firstTreatment.manualTx}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'firstTreatment.manualTx', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput
              label="AROM"
              defaultValue={formik.values?.firstTreatment.arom.centralizng}
              onChange={(e: SelectChangeEvent<unknown>) =>
                handleChangeInputRef(
                  e as ChangeEvent<HTMLInputElement>,
                  'firstTreatment.arom.centralizng',
                  ref,
                  setIsDirty,
                )
              }
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              label="STRENGTH"
              defaultValue={formik.values?.firstTreatment.strength}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'firstTreatment.strength', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.InputWrapper>
            <SelectInput
              label="HEP"
              defaultValue={formik.values?.firstTreatment.hep.sit}
              onChange={(e: SelectChangeEvent<unknown>) =>
                handleChangeInputRef(
                  e as ChangeEvent<HTMLInputElement>,
                  'firstTreatment.hep.sit',
                  ref,
                  setIsDirty,
                )
              }
            />
          </S.InputWrapper>
        </S.Line>
      </>
    );
  },
);

export default ReasonType;
