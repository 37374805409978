import { CardDashboardIconSVG } from '../../../assets/icons';
import CircularLoader from '../../atoms/CircuralLoader';
import * as S from './styles';

type Props = {
  article: string;
  number?: number;
  $isGray?: boolean;
  $isLoading?: boolean;
};

const DashboardCard: React.FC<Props> = ({
  article,
  number,
  $isGray = false,
  $isLoading,
}) => {
  return (
    <S.DashboardCardWrapper $isGray={$isGray}>
      <S.Content>
        <S.ArticleWrap>
          <S.CountBox $isGray={$isGray}>
            <CardDashboardIconSVG />
          </S.CountBox>
          <S.Article $isGray={$isGray}>{article}</S.Article>
        </S.ArticleWrap>
        {$isLoading ? <CircularLoader color="#0084B1" /> : <S.Number>{number}</S.Number>}
      </S.Content>
    </S.DashboardCardWrapper>
  );
};

export default DashboardCard;
