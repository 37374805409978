import { api } from '../api';

export const profileApi = api.injectEndpoints({
  endpoints: builder => ({
    profileCreate: builder.mutation({
      query: data => ({
        url: '/profile',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    approve: builder.mutation({
      query: ({ id }) => ({
        url: `/profile/approve/${id}`,
        method: 'GET',
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    updateOnlyProfile: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/profile/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    updateHealthAdditionalInfo: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/profile/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    markAsPrimaryInsurance: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
  }),
});

export const {
  useProfileCreateMutation,
  useApproveMutation,
  useUpdateOnlyProfileMutation,
  useUpdateHealthAdditionalInfoMutation,
  useMarkAsPrimaryInsuranceMutation,
} = profileApi;
