import styled, { css } from 'styled-components';
import { devices } from '../../../../constants/device';

export const Wrapper = styled.div<{ done: string }>`
  background: #f9f9fc;
  width: 100%;
  height: auto;
  border: 0.5px solid var(--line, #d7d7d7);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
  overflow: hidden;
  background: #f9f9fc;

  /* pointer-events: ${props => (props.done === 'true' ? 'none' : 'all')}; */
  opacity: ${props => (props.done === 'true' ? '0.5' : '1')};
`;

export const Span = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ColorSpan = styled.span`
  color: var(--icon, #9663be);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
`;

export const ColorSpanBold = styled.span`
  color: var(--icon, #9663be);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Link = styled.span`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const SpanBlack = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 26px;
  width: 100%;

  padding: 5px 5px 5px 0px;
`;

export const StatusBlock = styled.div`
  width: 7px;
  height: 74px;
`;

export const DateDiv = styled.div`
  display: flex;
  width: 20px;
  flex-direction: column;
  gap: 4px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const Line = styled.div`
  width: 0.5px;
  height: 50px;
  background: #d7d7d7;

  @media ${devices.xs} {
    display: none;
  }
`;

export const WeekDay = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
`;

export const NumberDay = styled.div`
  color: #0084b1;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const RecordInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media ${devices.xs} {
    gap: 8px;
    margin-left: 14px;
  }
`;

export const ArticleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`;

export const StatusPinWrapper = styled.div``;

export const MiddleDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;

  @media ${devices.xs} {
    gap: 10px;
    flex-wrap: wrap;
  }
`;

export const RecordTime = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ClinicName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DoctorName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const DownDiv = styled.div`
  display: flex;
  align-items: start;
  gap: 5px;
  margin-left: -2px;
`;

export const NoteText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: normal;
  width: 100%;

  @media ${devices.xs} {
    width: 100%;
    margin-right: 10px;
  }
`;

export const TreatmentBtn = styled.div`
  display: flex;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;

  & .red {
    fill: red;
  }
`;
