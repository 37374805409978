import * as S from './styles';
import { useAppSelector } from 'hooks/useAppSelector';
import { CurrentServiceCard } from './CurrentServiceCard';

const Agenda = () => {
  const services = useAppSelector(state => state.selectedClinic.services);

  return (
    <S.Wrapper>
      {services?.map(service => {
        return <CurrentServiceCard key={service.id} service={service} />;
      })}
    </S.Wrapper>
  );
};
export default Agenda;
