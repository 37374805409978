import { useDispatch } from 'react-redux';
import { AvatarSVG, CloseSVG } from '../../../../../../../assets/icons';
import { Button } from '../../../../../../../components';
import * as S from '../../../styles';
import { useRef, useState } from 'react';

type Props = {
  formik: any;
};

const AvatarZone: React.FC<Props> = ({ formik }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleClearImg = (e: any) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    formik.setFieldValue('avatar', null);
  };

  const handleChangeFile = (e: any) => {
    const file = e.target.files.item(0);
    if (file) {
      formik.setFieldValue('avatar', file);
    }
  };

  return (
    <>
      <S.AvatarDiv>
        <S.AvatarClickZone>
          <label htmlFor="file">
            <S.AvatarZone>
              {formik.values.avatar ? (
                <>
                  <img src={URL.createObjectURL(formik.values.avatar)} alt="" />
                </>
              ) : (
                <AvatarSVG />
              )}
              <S.AvatarHelper>
                Your photos must be in JPEG, or PNG format before you upload them,
                <S.TextHelper>max size 50MB</S.TextHelper>
              </S.AvatarHelper>
            </S.AvatarZone>
          </label>
          {formik.values.avatar && (
            <S.DeleteCircle onClick={handleClearImg}>
              <CloseSVG />
            </S.DeleteCircle>
          )}
        </S.AvatarClickZone>

        <input
          ref={inputRef}
          type="file"
          id="file"
          onChange={handleChangeFile}
          accept="image/png, image/jpeg"
          hidden
        ></input>
        <S.ButtonWrapper>
          <Button text="Upload photo" onClick={() => handleClick()} />
        </S.ButtonWrapper>
      </S.AvatarDiv>
    </>
  );
};

export default AvatarZone;
