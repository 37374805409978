import { useState } from 'react';
import { TabsItem } from 'components';
import * as S from './styles';
import { Tab } from '@mui/material';
import StaffReceptionists from './components/StaffReceptionists';
import StaffDoctors from './components/StaffDoctors';
import StaffBilling from './components/StaffBilling';
import StaffLocal from './components/StaffLocal';
import ReferringProviders from './components/ReferringProviders/ReferringProviders';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';

const Staff = () => {
  const { role } = useAppSelector(state => state.auth);
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Staff</S.SubtitleText>
      </S.SubtitleBar>

      <S.Content>
        <S.MenuStaffWrapper>
          <S.SubtitleItems>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              {role === Roles.SUPER_ADMIN && (
                <Tab disableRipple label="Local Admin"></Tab>
              )}
              <Tab disableRipple label="Billing Specialists"></Tab>
              <Tab disableRipple label="Doctors"></Tab>
              <Tab disableRipple label="Receptionists"></Tab>
              <Tab disableRipple label="Specialists"></Tab>
              <Tab disableRipple label="Referring providers"></Tab>
            </TabsItem>
            {/* {handleButtonShow()} */}
          </S.SubtitleItems>
          {tabIndex === 0 && role === Roles.SUPER_ADMIN && (
            <StaffLocal fromClinic={false} />
          )}
          {tabIndex === (role === Roles.SUPER_ADMIN ? 1 : 0) && <StaffBilling />}
          {tabIndex === (role === Roles.SUPER_ADMIN ? 2 : 1) && (
            <StaffDoctors deletable={false} />
          )}
          {tabIndex === (role === Roles.SUPER_ADMIN ? 3 : 2) && (
            <StaffReceptionists fromClinic={false} />
          )}
          {tabIndex === (role === Roles.SUPER_ADMIN ? 4 : 3) && (
            <StaffDoctors isSpecialists deletable={false} />
          )}
          {tabIndex === (role === Roles.SUPER_ADMIN ? 5 : 4) && <ReferringProviders />}
        </S.MenuStaffWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Staff;
