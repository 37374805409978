import { ChangeEvent } from 'react';
import Button from '../../../atoms/Button';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../../../atoms/Input';
import { useChangePasswordMutation } from '../../../../store/api/user/profileApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../store/slices/message';

type Props = {
  onClose: () => void;
};

type FormValues = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

const ChangePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .min(5, 'Must be at least 5 characters')
    .required('Is required'),
  password: yup.string().min(5, 'Must be at least 5 characters').required('Is required'),
  confirmPassword: yup
    .string()
    .required('Is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const ChangePasswordDialog: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
      validateOnChange: false,
      validationSchema: ChangePasswordSchema,
      onSubmit: async ({ password, confirmPassword, oldPassword }) => {
        await changePassword({ password, confirmPassword, oldPassword })
          .unwrap()
          .then(res => {
            onClose();
            dispatch(setMessage({ message: res.message, type: 'success' }));
          })
          .catch(error => {
            dispatch(setMessage({ message: error.data.message, type: 'error' }));
          });
      },
    });

  const [changePassword] = useChangePasswordMutation();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const handleConfirmClick = () => {
    onClose();
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Content>
        <S.Article>Change password</S.Article>
        <S.SubArticle>
          To change the current password, enter the new password in both fields
        </S.SubArticle>
        <S.Items>
          <Input
            isPassword
            id="oldPassword"
            name="oldPassword"
            label="Previous password"
            value={values.oldPassword}
            onChange={handleChangeInput}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword}
          />
          <Input
            isPassword
            id="password"
            name="password"
            label="Password"
            value={values.password}
            onChange={handleChangeInput}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Input
            isPassword
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChangeInput}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
          <S.ButtonWrapper>
            <Button text="Submit" type="submit" />
          </S.ButtonWrapper>
        </S.Items>
      </S.Content>
    </form>
  );
};

export default ChangePasswordDialog;
