import { CustomTable, RadioButton } from 'components';
import { TableCell, TableRow } from '@mui/material';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';

import * as S from './styles';
import { useEffect } from 'react';

const clinicHeaders = [
  { name: '', key: 'checkbox' },
  { name: 'Name', key: 'name' },
  { name: 'NPI', key: 'npi' },
  { name: 'Address', key: 'address' },
];

type Props = {
  selectedItem: string | null;
  setSelectedItem: (selectedItem: string | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  currentValue?: string;
};

export const ClinicsTable = ({
  selectedItem,
  setSelectedItem,
  setIsLoading,
  currentValue,
}: Props) => {
  const clinics = useClinicsGetQuery({});

  useEffect(() => {
    if (clinics.isFetching) {
      setIsLoading(true);
    } else setIsLoading(false);
  }, [clinics.isFetching]);

  useEffect(() => {
    if (currentValue) {
      setSelectedItem(
        clinics.currentData?.rows?.find(i => i.id === currentValue)?.id ?? null,
      );
    }
  }, [clinics.currentData]);

  return (
    <S.Table className={'all'}>
      <CustomTable headers={clinicHeaders}>
        {!!clinics.currentData?.rows.length &&
          clinics.currentData.rows?.map((row, index, { length }) => {
            return (
              <TableRow
                key={`${row}-${index}`}
                sx={{
                  '& .MuiTableCell-root': {
                    background: selectedItem === row.id ? '#e7e8ec' : '',
                  },
                }}
              >
                <TableCell>
                  <RadioButton
                    onClick={() =>
                      selectedItem === row.id
                        ? setSelectedItem(null)
                        : setSelectedItem(row.id)
                    }
                    checked={selectedItem === row.id}
                  />
                </TableCell>
                <TableCell>
                  <S.Text>{row.name}</S.Text>
                </TableCell>
                <TableCell>
                  <S.Text>{row?.npi ?? '-'}</S.Text>
                </TableCell>
                <TableCell>
                  <S.Text>
                    {`${row?.street ? row?.street + ', ' : ''}${row?.city ? row?.city + ', ' : ''}${row?.state ?? '-'}`}
                  </S.Text>
                </TableCell>
              </TableRow>
            );
          })}
      </CustomTable>
    </S.Table>
  );
};
