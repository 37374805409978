import { DrawerMode } from 'types/DrawerMode';
import { AllNotesDrawer } from '../components';
import { default as TreatmentFormDrawer } from '../components/organismus/ProfileViews/TreatmentForm';
import { useAppSelector } from 'hooks/useAppSelector';
import TicketCreate from '../components/organismus/Tickets/TicketCreate';
import AddPatientDrawer from '../pages/Receptionist/components/Patients/components/AddPatientDrawer';
import TreatmentForm from '../components/molecules/TreatmentForm';
import AddReceptionist from '../components/organismus/Drawers/AddReceptionist';
import AddDoctor from '../components/organismus/Drawers/AddDoctor';
import AddClinic from '../components/organismus/Drawers/AddClinic';
import AddLocal from '../components/organismus/Drawers/AddLocal';
import AddBilling from '../components/organismus/Drawers/AddBilling';
import AddHealthRecords from '../components/organismus/Drawers/AddHealthRecords';
import AddCoupon from '../components/organismus/Drawers/AddCoupon';
import AddSurvey from '../components/organismus/Drawers/AddSurvey';
import ShowInvoiceHistory from '../pages/Billing/components/InvoiceDetail/components/ShowInvoiceHistory';
import AuthDrawerContent from '../pages/Auth/components/AuthDrawerContent';
import AppointmentDrawer from '../components/organismus/AppointmentDrawer';
import AddReferringProvider from '../components/organismus/Drawers/AddReferringProvider';
import InsuranceLogs from '../pages/Users/components/Profile/components/InsuranceInformation_v2/components/InsuranceLogs';

type Props = {};

const ChooseDrawerContent: React.FC<Props> = () => {
  const drawerMode = useAppSelector(state => state.drawer.mode);

  const props = useAppSelector(state => state.drawer.props);

  const chooseContent = () => {
    switch (drawerMode) {
      case DrawerMode.NEW_APPOINTMENT:
        return <AppointmentDrawer userProfile={props} />;
      case DrawerMode.INFO_APPOINTMENT:
        return <AppointmentDrawer selectedAppointment={props} byClick={true} />;
      case DrawerMode.DUPL_APPOINTMENT:
        return <AppointmentDrawer duplAppointment={props} />;
      case DrawerMode.NEW_APPOINTMENT_TIME:
        return <AppointmentDrawer recordTime={props} byClick={true} />;
      case DrawerMode.NEW_TREATMENT:
        return <TreatmentForm selectedAppointment={props} />;
      case DrawerMode.TREATMENT:
        return <TreatmentFormDrawer rows={props} />;
      case DrawerMode.ALL_NOTES:
        return <AllNotesDrawer userProfile={props} />;
      case DrawerMode.TICKET_CREATE:
        return <TicketCreate ticket={props} />;
      case DrawerMode.ADD_PATIENTS:
        return <AddPatientDrawer selectedClinic={props} />;
      case DrawerMode.ADD_RECEPTIONIST:
        return <AddReceptionist />;
      case DrawerMode.ADD_DOCTOR:
        return <AddDoctor isSpeciality={props} />;
      case DrawerMode.ADD_CLINIC:
        return <AddClinic />;
      case DrawerMode.ADD_LOCAL:
        return <AddLocal />;
      case DrawerMode.ADD_BILLING:
        return <AddBilling />;
      case DrawerMode.HEALTH_RECORDS:
        return <AddHealthRecords />;
      case DrawerMode.ADD_COUPON:
        return <AddCoupon coupon={props} />;
      case DrawerMode.ADD_SURVEY:
        return <AddSurvey />;
      case DrawerMode.SHOW_INVOICE_HISTORY:
        return <ShowInvoiceHistory props={props} />;
      case DrawerMode.INSURANCE_LOGS:
        return <InsuranceLogs props={props} />;
      case DrawerMode.NEW_APPOINTMENT_USER:
        return <AuthDrawerContent />;
      case DrawerMode.INFO_APPOINTMENT_USER:
        return <AuthDrawerContent selectedAppointment={props} />;
      case DrawerMode.ADD_REF_PROVIDER:
        return <AddReferringProvider refProvider={props} />;
      default:
        return null;
    }
  };

  return chooseContent();
};

export default ChooseDrawerContent;
