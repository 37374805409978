import { useState } from 'react';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { AddRectangleSVG } from 'assets/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { Button, CustomPagination, CustomTable, SearchInput } from 'components';
import SurveyRow from './TableData/SurveyRow';
import { useSurveyGetQuery } from 'store/api/survey/surveyApi';

const headers = [
  { name: 'Title', key: 'Title' },
  { name: 'Date', key: 'Date' },
  { name: 'Link', key: 'Link' },
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Survey recipients', key: 'NumberPart' },
  { name: '', key: 'Edit' },
];

const COUPONS_LIMIT = 20;

const Surveys = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [page, setPage] = useState(1);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useSurveyGetQuery({
    ...(selectedClinic.id && { clinicId: selectedClinic.id }),
    page,
    q: debouncedSearchValue,
  });

  return (
    <S.TableBody>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'coupons'}
          limit={COUPONS_LIMIT}
        />
        <S.Btns>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            <Button
              text="Create Survey"
              onClick={() =>
                dispatch(
                  showDrawer({
                    show: true,
                    props: null,
                    mode: DrawerMode.ADD_SURVEY,
                  }),
                )
              }
            >
              <AddRectangleSVG />
            </Button>
          </S.ButtonWrapper>
        </S.Btns>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <SurveyRow data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default Surveys;
