import React from 'react';
import { generateStylesConfig } from './generateStylesConfig';
import { Text, View } from '@react-pdf/renderer';
import { CellPdf, CheckBoxPdf, SimplyCellPdf } from './components';
import { booleanOptions } from './config';
import dayjs from 'dayjs';
import { ClaimValues } from 'types/AppointmentNewTypes';

type Props = {
  data?: ClaimValues;
};

export const ProviderPart = ({ data }: Props) => {
  const s = generateStylesConfig();

  return (
    <>
      <View style={s.redRow}>
        <View
          style={[
            s.row,
            {
              width: 170,
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            },
          ]}
        >
          <View style={[s.column, { width: 150, alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>
              25. FEDERAL TAX I.D. NUMBER
            </Text>
            <Text style={[s.text, { paddingBottom: 0, marginLeft: 5 }]}>
              {data?.federalTaxId}
            </Text>
          </View>

          <View
            style={[
              s.row,
              {
                justifyContent: 'flex-end',
                columnGap: 10,
                marginTop: 5,
                paddingRight: 10,
              },
            ]}
          >
            <View style={[s.column, { alignItems: 'flex-end' }]}>
              <Text style={[s.title, { padding: 0 }]}>SSN</Text>
              <View style={s.square}>
                <Text style={s.check}>{data?.isFederalSSN ? 'X' : ''}</Text>
              </View>
            </View>
            <View style={s.column}>
              <Text style={[s.title, { padding: 0 }]}>EIN</Text>
              <View style={s.square}>
                <Text style={s.check}>{data?.isFederalEIN ? 'X' : ''}</Text>
              </View>
            </View>
          </View>
        </View>

        <CellPdf width={105}>
          <SimplyCellPdf
            name={`26. PATIENT'S ACCOUNT NO.`}
            content={data?.patientAccountNumber}
          />
        </CellPdf>
        <CellPdf width={106}>
          <View style={[s.column, { alignItems: 'flex-start', marginLeft: 4 }]}>
            <Text style={[s.title, { padding: '2.5 0 0 0' }]}>
              27. ACCEPT ASSIGNMENT?
            </Text>
            <Text style={[s.subTitle, { padding: 0, marginTop: -1 }]}>
              (For govt. claims, see back)
            </Text>
            <View style={[s.row, { columnGap: 10, marginTop: -1 }]}>
              {booleanOptions.map(({ title, subTitle }) => (
                <CheckBoxPdf
                  key={subTitle}
                  checked={data?.isAcceptAssignment === title}
                  subTitle={subTitle}
                />
              ))}
            </View>
          </View>
        </CellPdf>

        <CellPdf width={75}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>28. TOTAL CHARGE</Text>
            <View style={[s.row, { justifyContent: 'flex-start', gap: 1 }]}>
              <Text style={[s.title, { paddingBottom: 0 }]}>$</Text>
              <Text style={[s.text, { width: 30, textAlign: 'right' }]}>
                {data?.totalCharge ? Math.trunc(data?.totalCharge) : ''}
              </Text>
              <View style={[s.verticalBorder]} />
              <Text style={[s.text]}>
                {data?.totalCharge
                  ? '.' + (data?.totalCharge?.toString().split('.')[1] ?? '00')
                  : ''}
              </Text>
            </View>
          </View>
        </CellPdf>
        <CellPdf width={65}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>29. AMOUNT PAID</Text>
            <View style={[s.row, { justifyContent: 'flex-start', gap: 1 }]}>
              <Text style={[s.title, { paddingBottom: 0 }]}>$</Text>
              <Text style={[s.text, { width: 20, textAlign: 'right' }]}>
                {data?.totalPaid ? Math.trunc(data?.totalPaid) : ''}
              </Text>
              <View style={[s.verticalBorder]} />
              <Text style={[s.text]}>
                {data?.totalPaid
                  ? '.' + (data?.totalPaid.toString().split('.')[1] ?? '00')
                  : ''}
              </Text>
            </View>
          </View>
        </CellPdf>
        <CellPdf width={80}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { paddingBottom: 0 }]}>30. Rsvd for NUCC Use</Text>
            <View style={[s.row, { justifyContent: 'flex-start', gap: 1 }]}>
              <Text style={[s.title, { paddingBottom: 0 }]}>$</Text>
              <Text style={[s.text, { width: 30, textAlign: 'right' }]}>
                {data?.reservedForNUCC ? Math.trunc(+data?.reservedForNUCC) : ''}
              </Text>
              <View style={[s.verticalBorder]} />
              <Text style={[s.text]}>
                {data?.reservedForNUCC
                  ? '.' + (data?.reservedForNUCC.toString().split('.')[1] ?? '00')
                  : ''}
              </Text>
            </View>
          </View>
        </CellPdf>
      </View>

      <View style={[s.redRow, { height: 62 }]}>
        <View
          style={[
            s.column,
            {
              alignItems: 'flex-start',
              width: 159,
              justifyContent: 'space-between',
              height: 60,
            },
          ]}
        >
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <Text style={[s.title, { padding: '2.5 2.5 1 2.5' }]}>
              31. SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING DEGREES OR CREDENTIALS
            </Text>
            <Text style={[s.subTitle, { padding: '0 2.5' }]}>
              (I certify that the statements on the reverse apply to this bill and are
              made a part thereof.)
            </Text>
            <Text style={[s.text, { fontSize: 8, marginLeft: 5 }]}>
              {data?.renderingName ? data?.renderingName : ''}
            </Text>
            <Text style={[s.text, { fontSize: 8, marginLeft: 5 }]}>
              {data?.renderingNpi ? data?.renderingNpi : ''}
            </Text>
          </View>

          <View style={[s.row, { justifyContent: 'space-between', width: '100%' }]}>
            <Text style={s.title}>SIGNED</Text>
            <Text style={s.text}>
              {data?.services
                ? dayjs(data?.services?.at(0)?.dateFrom).format('MM/DD/YYYY')
                : ''}
            </Text>
            <Text style={[s.title, { marginRight: 25 }]}>DATE</Text>
          </View>
        </View>

        <CellPdf width={197.5} height={62}>
          <View
            style={[
              s.column,
              {
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                height: 62,
              },
            ]}
          >
            <View
              style={[
                s.column,
                {
                  alignItems: 'flex-start',
                  width: '100%',
                  height: 47,
                  borderBottom: 1,
                  borderColor: '#EC2027',
                },
              ]}
            >
              <Text style={[s.title, { paddingBottom: 1 }]}>
                32. SERVICE FACILITY LOCATION INFORMATION
              </Text>

              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {data?.serviceFacilityName ?? ''}
              </Text>
              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {(data?.serviceOffice ?? '') + ' ' + (data?.serviceStreet ?? '')}
              </Text>
              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {(data?.serviceCity ?? '') +
                  ' ' +
                  (data?.serviceState ? ', ' + data?.serviceState : '') +
                  ' ' +
                  (data?.serviceZip ?? '')}
              </Text>
            </View>
            <View style={s.row}>
              <View style={[s.row, { width: '50%' }]}>
                <Text style={[s.title]}>a.</Text>
                <Text style={[s.text]}>{data?.serviceNpi ?? ''}</Text>
              </View>
              <View style={[s.verticalBorder, { borderStyle: 'solid', height: 15 }]} />
              <View style={[s.row, { width: '50%' }]}>
                <Text style={[s.title]}>b.</Text>
              </View>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={206} height={62}>
          <View
            style={[
              s.column,
              {
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                height: 62,
              },
            ]}
          >
            <View
              style={[
                s.column,
                {
                  alignItems: 'flex-start',
                  width: '100%',
                  height: 47,
                  borderBottom: 1,
                  borderColor: '#EC2027',
                },
              ]}
            >
              <Text style={[s.title, { paddingBottom: 1 }]}>
                33. BILLING PROVIDER INFO & PH # ( )
              </Text>

              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {data?.billingFacilityName ?? ''}
              </Text>
              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {(data?.billingOffice ?? '') + ' ' + (data?.billingStreet ?? '')}
              </Text>
              <Text style={[s.text, { fontSize: 8, paddingLeft: 5, marginTop: -1 }]}>
                {(data?.billingCity ?? '') +
                  ' ' +
                  (data?.billingState ? ', ' + data?.billingState : '') +
                  ' ' +
                  (data?.billingZip ?? '')}
              </Text>
            </View>
            <View style={s.row}>
              <View style={[s.row, { width: '50%' }]}>
                <Text style={[s.title]}>a.</Text>
                <Text style={[s.text]}>{data?.billingNpi ?? ''}</Text>
              </View>
              <View style={[s.verticalBorder, { borderStyle: 'solid', height: 15 }]} />
              <View style={[s.row, { width: '50%' }]}>
                <Text style={[s.title]}>b.</Text>
              </View>
            </View>
          </View>
        </CellPdf>
      </View>
    </>
  );
};
