export const transformNoteText = (str: string) => {
  if (str.length > 100) {
    return str.slice(0, 120) + '...';
  } else return str;
};

export const transformNoteTextMore = (str: string) => {
  if (str.length > 20) {
    return str?.slice(0, 15) + '...';
  } else return str;
};
