import { CoveredServicesVR } from 'types/enums/AppointmentEnum';
import { api } from '../api';
import { InsuranceWithGroupsType, NewInsuranceType } from 'types/InsuranceType';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const insuranceRelativeApi = api.injectEndpoints({
  endpoints: builder => ({
    insuranceListGet: builder.query<Response<NewInsuranceType>, any>({
      query: ({ ...params }) => ({
        url: `/insurance`,
        params: { ...params },
      }),
      providesTags: ['Insurance'],
    }),
    insuranceListWithGroupsGet: builder.query<Response<InsuranceWithGroupsType>, any>({
      query: ({ ...params }) => ({
        url: `/insurance-group-number`,
        params: { ...params },
      }),
      providesTags: ['Insurance'],
    }),
    getOneInsurance: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/insurance/${id}`,
      }),
      providesTags: ['Insurance'],
    }),
    getOneInsuranceWithGroups: builder.query<InsuranceWithGroupsType, any>({
      query: ({ id }) => ({
        url: `/insurance-group-number/${id}`,
      }),
      providesTags: ['Insurance'],
    }),
    insuranceWithGroupsCreate: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: `/insurance-group-number`,
          body: { ...body },
          method: 'POST',
        };
      },
      invalidatesTags: ['User', 'Insurance'],
    }),
    insuranceWithGroupsUpdate: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: `/insurance-group-number`,
          body: { ...body },
          method: 'PATCH',
        };
      },
      invalidatesTags: ['User', 'Insurance'],
    }),
    relativeCreate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance-relative`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    relativeDelete: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/insurance-relative/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'User' }],
    }),
    insuranceAdd: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    insuranceAddToPatient: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance-group-number/add-group-number`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['User'],
    }),
    addCoveredService: builder.mutation({
      query: ({ insuranceGroupNumberId, ...body }) => ({
        url: `/covered-service?insuranceGroupNumberId=${insuranceGroupNumberId}`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    deleteCoveredService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/covered-service/${id}`,
        method: 'DELETE',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    updateCoveredService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/covered-service?id=${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    insuranceDelete: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance/${id}`,
        method: 'DELETE',
        body: { ...body },
      }),
      invalidatesTags: ['User', 'Insurance'],
    }),
    insuranceWithGroupNumbersDelete: builder.mutation({
      query: ({ id }) => ({
        url: `/insurance-group-number/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User', 'Insurance'],
    }),
    insuranceUpdate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/insurance/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    coveredServicesGetVisitReason: builder.mutation<CoveredServicesVR[], any>({
      query: ({ userId, clinicId, ...params }) => ({
        url: `/covered-service/visits-reason/${userId}/${clinicId}`,
        method: 'GET',
        params: { ...params },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    insuranceDeleteFiles: builder.mutation({
      query: ({ id, insuranceId, ...body }) => ({
        url: `/insurance/delete-file/${id}/${insuranceId}`,
        method: 'DELETE',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
  }),
});

export const {
  useInsuranceListGetQuery,
  useInsuranceListWithGroupsGetQuery,
  useLazyInsuranceListWithGroupsGetQuery,
  useGetOneInsuranceQuery,
  useLazyGetOneInsuranceWithGroupsQuery,
  useInsuranceWithGroupsCreateMutation,
  useInsuranceWithGroupsUpdateMutation,
  useRelativeCreateMutation,
  useRelativeDeleteMutation,
  useInsuranceAddMutation,
  useInsuranceAddToPatientMutation,
  useAddCoveredServiceMutation,
  useDeleteCoveredServiceMutation,
  useUpdateCoveredServiceMutation,
  useInsuranceDeleteMutation,
  useInsuranceWithGroupNumbersDeleteMutation,
  useInsuranceUpdateMutation,
  useCoveredServicesGetVisitReasonMutation,
  useInsuranceDeleteFilesMutation,
} = insuranceRelativeApi;
