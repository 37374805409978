import { FormikProps } from 'formik';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import Input from 'components/atoms/Input';
import OptionContent from '../OptionContent';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { OptionType } from '../OptionContent/OptionContent';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  setLastElement: any;
  data: any;
  isLoading: boolean;
  setPage: (page: number) => void;
};

export const LastNameV2 = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      setLastElement,
      data,
      isLoading,
      setPage,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('lastName', searchValue);
      }
    }, [searchValue]);

    return (
      <>
        {byClick ? (
          <AutoCompleteInput
            setPage={setPage}
            data={data || []}
            isRequired
            customRender={true}
            mainLabel={'Last name'}
            loading={isLoading}
            searchValue={searchValue}
            options={data || []}
            setSearchValue={setSearchValue}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            disabled={
              !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
            }
            getOptionLabel={(option: any) =>
              !selectedValue
                ? formik?.values?.lastName || ''
                : option && option?.profile?.lastName
            }
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName as string}
            renderOption={(props: any, option: any) => {
              const refLast =
                data?.length === props['data-option-index'] + 1 ? setLastElement : null;

              return (
                <OptionContent
                  type={OptionType.LASTNAME}
                  searchValue={searchValue}
                  textToHighlight={option.profile?.lastName || ''}
                  key={option.id}
                  otherText={option.profile?.firstName || ''}
                  props={props}
                  option={option}
                  loading={isLoading}
                  // @ts-ignore
                  ref={refLast}
                />
              );
            }}
          />
        ) : (
          <Input
            label="Last name"
            id="lastName"
            isRequired
            name="lastName"
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName as string}
            value={formik.values.lastName}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.lastName ||
              !!selectedAppointment?.patient?.profile?.lastName ||
              !!duplAppointment?.patient?.profile?.lastName
            }
          />
        )}
      </>
    );
  },
);
