import { useEffect, useRef, useState } from 'react';
import CircularLoader from 'components/atoms/CircuralLoader';
import * as S from './styles';
import { useLazyGetAllClaimsForExistingSearchQuery } from 'store/api/claims/claimsApi';
import ExistingClaimLine from './ExistingClaimLine';
import Button from 'components/atoms/Button';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import SearchInput from 'components/atoms/SearchInput';
import { useDebounce } from 'use-debounce';

type Props = {
  onClose: () => void;
  refetch: () => void;
};

const CreateExistingClaimDialog: React.FC<Props> = ({ onClose, refetch }) => {
  const [getClaimList, statusAllClaims] = useLazyGetAllClaimsForExistingSearchQuery({});

  const [selectedClaim, setSelectedClaim] = useState<null | string>(null);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [page, setPage] = useState(1);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const refreshList = () => {
    setPage(1);
    if (selectedClinic.id) {
      getClaimList({
        clinicId: selectedClinic.id,
        take: 7,
        page: 1,
        q: debouncedSearchValue,
      });
    }
    scrollToTop();
  };

  useEffect(() => {
    refreshList();
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (selectedClinic.id) {
      getClaimList({
        clinicId: selectedClinic.id,
        take: 7,
        page: page,
        q: debouncedSearchValue,
      });
    }
  }, [selectedClinic, page]);

  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && page !== statusAllClaims.data?.lastPage) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  useEffect(() => {
    if (page !== 1) {
      refetch();
    }
  }, [selectedClinic]);

  const messageStartRef = useRef<null | HTMLDivElement>(null);
  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView();
  };

  const navigate = useNavigate();
  return (
    <S.Content>
      <S.Article>Create from existing</S.Article>
      <S.InputWrapper>
        <SearchInput
          isSearch={searchValue.length ? true : false}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </S.InputWrapper>
      <S.Information>
        <S.Header>
          <S.DateHeader>Date</S.DateHeader>
          <S.ClaimHeader>Claim</S.ClaimHeader>
          <S.ClaimHeader>Patient name</S.ClaimHeader>
          <S.ClaimHeader>Account number</S.ClaimHeader>
          <S.InsuranceHeader>Insurance Co</S.InsuranceHeader>
        </S.Header>
        <S.ExistingLines>
          {statusAllClaims.isLoading ? (
            <S.Loader>
              <CircularLoader color="#0084B1" />
            </S.Loader>
          ) : statusAllClaims.data?.count === 0 ? (
            <S.Placeholder>No any existing claims</S.Placeholder>
          ) : (
            <S.ScrollDiv>
              <div ref={messageStartRef} />
              {statusAllClaims.data &&
                statusAllClaims.data.rows.map((claim, index) => {
                  const length = statusAllClaims.data?.rows.length;
                  const lastPage = statusAllClaims.data?.lastPage;
                  return (
                    <div
                      key={`${claim.id}_${index}`}
                      ref={
                        length && lastPage && index === length - 1 && page < lastPage
                          ? setObserverTarget
                          : undefined
                      }
                    >
                      <ExistingClaimLine
                        claim={claim}
                        selectedClaim={selectedClaim}
                        setSelectedClaim={setSelectedClaim}
                      />
                    </div>
                  );
                })}
            </S.ScrollDiv>
          )}
        </S.ExistingLines>
      </S.Information>
      {statusAllClaims.data?.count !== 0 && !statusAllClaims.isLoading && (
        <S.ButtonWrapper>
          <Button
            text="Create"
            disabled={selectedClaim ? false : true}
            onClick={e => {
              e.preventDefault();
              onClose();
              navigate(`/claim-form?claimId=${selectedClaim}`);
            }}
          />
        </S.ButtonWrapper>
      )}
    </S.Content>
  );
};

export default CreateExistingClaimDialog;
