import { useState } from 'react';
import { Button, Dialog, SuccessDialog } from '../../../../../../components';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { useAppointmentUpdateStatusMutation } from '../../../../../../store/api/appointment/appointmentApi';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { setMessage } from '../../../../../../store/slices/message';
import { AppointmentsNew } from '../../../../../../types/AppointmentNewTypes';
import { DrawerMode } from '../../../../../../types/DrawerMode';
import { AppointmentStatus } from '../../../../../../types/StatusTypes';
import * as S from '../../styles';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';

type Props = {
  selectedAppointment?: AppointmentsNew;
};

const FooterBtns: React.FC<Props> = ({ selectedAppointment }) => {
  const dispatch = useAppDispatch();

  const [updateStatus] = useAppointmentUpdateStatusMutation();

  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <S.Footer>
        <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
          <ConfirmDeleteDialog
            onClose={() => setOpenDialog(!openDialog)}
            fullTitle="Are you sure want to cancel this appointment?"
            onSuccess={() =>
              updateStatus({
                id: selectedAppointment?.id,
                status: AppointmentStatus.CANCELED,
              })
                .unwrap()
                .then(res => {
                  dispatch(
                    showDrawer({
                      show: false,
                      mode: DrawerMode.DEFAULT,
                      props: null,
                    }),
                  );
                  dispatch(
                    setMessage({
                      message: res.message,
                      type: 'success',
                    }),
                  );
                })
                .catch(error => {
                  dispatch(
                    showDrawer({
                      show: false,
                      mode: DrawerMode.DEFAULT,
                      props: null,
                    }),
                  );
                  dispatch(
                    setMessage({
                      message: error.data.message,
                      type: 'error',
                    }),
                  );
                })
            }
          />
        </Dialog>
        <S.BtnWrapper>
          {selectedAppointment &&
            selectedAppointment.status === AppointmentStatus.PENDING && (
              <Button
                text="Cancel"
                backgroundColor="#C66060"
                onClick={() => setOpenDialog(!openDialog)}
              />
            )}
        </S.BtnWrapper>

        <S.BtnWrapper>
          {!selectedAppointment ? (
            <Button text="Submit" type="submit" />
          ) : (
            <Button
              text="Back"
              backgroundColor="#848A9B"
              onClick={() =>
                dispatch(
                  showDrawer({
                    show: false,
                    mode: DrawerMode.DEFAULT,
                    props: null,
                  }),
                )
              }
            />
          )}
        </S.BtnWrapper>
      </S.Footer>
    </>
  );
};

export default FooterBtns;
