import { Appointments } from '../../../types/AppointmentsTypes';
import { Note } from '../../../types/NoteTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const notificationsApi = api.injectEndpoints({
  endpoints: builder => ({
    notificationsAllGet: builder.query<any, any>({
      query: ({ userId, ...params }) => ({
        url: `/notification?userId=${userId}`,
        params: { ...params },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          return;
        }
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Notifications'],
    }),
    notificationCreate: builder.mutation({
      query: data => ({
        url: '/notification',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Notifications'],
    }),
    hideNotificationPopups: builder.mutation({
      query: () => ({
        url: '/user/update-notifications-status',
        method: 'PATCH',
      }),
      invalidatesTags: ['Me'],
    }),
    readAllNotifications: builder.mutation({
      query: () => ({
        url: '/notification/mark-as-read-all',
        method: 'PATCH',
      }),
      //invalidatesTags: ["Notifications"],
    }),
    readNotificationById: builder.mutation({
      query: ({ id }) => ({
        url: `/notification/${id}/mark-as-read`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useNotificationsAllGetQuery,
  useHideNotificationPopupsMutation,
  useNotificationCreateMutation,
  useReadAllNotificationsMutation,
  useReadNotificationByIdMutation,
} = notificationsApi;
