import React, { useEffect, useState } from 'react';
import { useLazyGetCptQuery } from 'store/api/claims/claimsApi';
import * as S from './styles';
import { CustomTable, RadioButton } from 'components';
import Button from 'components/atoms/Button';
import { TableCell, TableRow } from '@mui/material';

const headers = [
  { name: '', key: 'checkbox' },
  { name: 'Code', key: 'code' },
];

type Props = {
  onApplyClick: (code: string[]) => void;
  currentValue?: string[];
  title?: string;
};

export const ChooseCptCodeMultiple = ({
  onApplyClick,
  currentValue,
  title = 'Choose HCPCS/CPT Code',
}: Props) => {
  const [getData, statusCpt] = useLazyGetCptQuery({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    getData({});
  }, [getData]);

  useEffect(() => {
    if (currentValue?.length) {
      setSelectedItems(statusCpt.data?.filter(i => currentValue.includes(i)) ?? []);
    }
  }, [statusCpt]);

  return (
    <S.Content>
      <S.Article>{title}</S.Article>
      <S.Table className={'all'}>
        <CustomTable headers={headers}>
          {!!statusCpt.data?.length &&
            statusCpt.data?.map(row => (
              <TableRow
                key={row}
                sx={{
                  '& .MuiTableCell-root': {
                    background: selectedItems.includes(row) ? '#e7e8ec' : '',
                  },
                }}
              >
                <TableCell>
                  <RadioButton
                    onClick={() =>
                      selectedItems.includes(row)
                        ? setSelectedItems(prev => prev.filter(i => i !== row))
                        : setSelectedItems(prev => [...prev, row])
                    }
                    checked={selectedItems.includes(row)}
                  />
                </TableCell>
                <TableCell>
                  <S.Text>{row}</S.Text>
                </TableCell>
              </TableRow>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItems ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
