import { useEffect, useState } from 'react';
import { LinearLoader, TicketRecord } from '../../../../../../components';
import {
  useLazyTicketsIncomingGetQuery,
  useTicketsIncomingGetQuery,
} from '../../../../../../store/api/tickets/ticketApi';
import { Roles } from '../../../../../../types/Roles';
import * as S from '../../styles';
import TicketsPlaceholder from '../TicketsPlaceholder';

type Props = {
  role: Roles;
  clinicId: string;
  debouncedSearch: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
};

const Incoming = ({ role, clinicId, debouncedSearch, page, setPage }: Props) => {
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);
  const [isRefetch, setIsRefetch] = useState(false);

  useEffect(() => {
    const currentElement = observerTarget;

    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
      }
      return;
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  const [getIncomingTickets, status] = useLazyTicketsIncomingGetQuery({});

  useEffect(() => {
    getIncomingTickets({
      'clinicId[]': [clinicId],
      page: page,
      take: 40,
      userName: debouncedSearch,
    });
  }, [page]);

  useEffect(() => {
    setPage(1);
    getIncomingTickets({
      'clinicId[]': [clinicId],
      page: 1,
      take: 40,
      userName: debouncedSearch,
    });
  }, [debouncedSearch, clinicId]);

  return (
    <S.RecordsWrapper>
      {status.data?.rows && status.data?.rows.length > 0
        ? status.data?.rows.map((item, index) => {
            const isLast =
              status.data?.rows &&
              index === status.data?.rows?.length - 1 &&
              status.data &&
              page < status.data.lastPage;

            return (
              <div key={item.id + index} ref={isLast ? setObserverTarget : undefined}>
                <TicketRecord
                  key={item.id}
                  ticket={item}
                  setPage={setPage}
                  setIsRefetch={setIsRefetch}
                />
              </div>
            );
          })
        : !(status.isLoading || status.isFetching) && (
            <TicketsPlaceholder type="incoming" />
          )}
      {status.isLoading ||
        (status.isFetching && (
          <S.Loader>
            <LinearLoader />
          </S.Loader>
        ))}
    </S.RecordsWrapper>
  );
};

export default Incoming;
