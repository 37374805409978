import dayjs from 'dayjs';
import * as S from '../../../styles';
import { Dialog, SuccessDialog, UserStatusPin } from '../../../../../../../components';
import {
  AvatarSVG,
  DeleteBtnSVG,
  DeleteRedBtnSVG,
  EditBtnMinimalSVG,
} from '../../../../../../../assets/icons';
import { Clinics } from '../../../../../../../types/ClinicTypes';
import { getNetworkTypeName } from '../../../../../../../helpers/functions/getInOutNetworkName';
import { useRemoveInsuranceFromDoctorMutation } from '../../../../../../../store/api/staff/staffApi';
import ConfirmDeleteDialog from '../../../../../../../components/molecules/ConfirmDeleteDialog';
import { useState } from 'react';
import { setMessage } from '../../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import DialogAddInsuranceTo from '../../Dialog_AddInsuranceTo';
import { useParams } from 'react-router-dom';
import { InsuranceToDoctor } from '../InsuranceTo';

type Props = {
  row: any;
  header: string;
  setEditedInsurance: React.Dispatch<React.SetStateAction<InsuranceToDoctor | null>>;
};

const InsuranceToData: React.FC<Props> = ({ row, header, setEditedInsurance }) => {
  const [deleteRow] = useRemoveInsuranceFromDoctorMutation({});

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();

  switch (header) {
    case 'Clinic': {
      return row.clinicUser?.clinic?.name || '-';
    }
    case 'Type': {
      return getNetworkTypeName(row.type) || '-';
    }
    case 'Edit': {
      return (
        <S.CellWrapper>
          <Dialog
            open={isShowDeleteDialog}
            onClose={() => setIsShowDeleteDialog(!isShowDeleteDialog)}
          >
            <ConfirmDeleteDialog
              onClose={() => setIsShowDeleteDialog(!isShowDeleteDialog)}
              fullTitle={'Are you sure want to unassign insurance from doctor?'}
              helperText={'You will delete this row permanently'}
              onSuccess={() =>
                deleteRow({
                  id: row.id,
                })
                  .unwrap()
                  .then(res => {
                    dispatch(
                      setMessage({
                        message: 'Insurance wass successfully unassigned',
                        type: 'success',
                      }),
                    );
                  })
                  .catch(error => {
                    dispatch(
                      setMessage({
                        message: error.data.message,
                        type: 'error',
                      }),
                    );
                  })
              }
            />
          </Dialog>
          <EditBtnMinimalSVG onClick={() => setEditedInsurance(row)} />
          <DeleteRedBtnSVG onClick={() => setIsShowDeleteDialog(true)} />
        </S.CellWrapper>
      );
    }
    case 'PayerID':
      return row.payerId || '-';
    case 'PayerName':
      return row.payerName || '-';
    default:
      return row[header] || '-';
  }
};

export default InsuranceToData;
