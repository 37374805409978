import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.px13 {
    font-size: 13px;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 4px;
  gap: 20px 29px;
`;

export const Caption = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  color: #848a9b;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
`;

export const BlockItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Text = styled.div`
  color: #000000;
  font-size: 13px;
  font-family: Inter;
  font-weight: 400;
`;

export const InputWrapper = styled.div`
  width: 170px;

  &.px360 {
    width: 360px;
  }
`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;
