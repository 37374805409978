import ReactInputMask from 'react-input-mask';
import {
  Button,
  CircularLoader,
  DatePicker,
  Input,
  LinearLoader,
  SelectInput,
} from '../../../../../../../../components';
import * as S from './styles';
import { SearchIconSVG } from '../../../../../../../../assets/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import SearchContentList from '../SearchContentList';
import { useDebounce } from 'use-debounce';
import { Profile } from '../../../../../../../../types/ProfileTypes';
import { Roles } from '../../../../../../../../types/Roles';
import { api } from '../../../../../../../../store/api/api';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import {
  searchApi,
  useUsersGetDeepSearchQuery,
} from '../../../../../../../../store/api/searchApi/searchApi';
import { useRelativeCreateMutation } from '../../../../../../../../store/api/insuranceRelative/insuranceRelativeApi';

type Props = {
  onClose: () => void;
  insuranceId?: string;
  title?: string;
  onSubmit?: () => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  cellPhone: string;
  sex: string;
  dateOfBirth: Dayjs | string;
};

type Row = {
  id: string;
  profile: Profile;
  role: Roles;
  phone: string;
};

const InsuredListDialog: React.FC<Props> = ({
  onClose,
  insuranceId,
  title,
  onSubmit,
}) => {
  const Shape = yup.object().shape({});
  const [page, setPage] = useState(1);

  const [selectedRow, setSelectedRow] = useState<Row | null>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      cellPhone: '',
      sex: '',
      dateOfBirth: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {
      onClose();
      createRelative({
        firstName: values.firstName,
        lastName: values.lastName,
        cellPhone: values.cellPhone,
        sex: values.sex,
        dateOfBirth: values.dateOfBirth,
        ...(selectedRow && { linkedUserId: selectedRow.id }),
        insuranceId: insuranceId,
      });
    },
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const [createRelative] = useRelativeCreateMutation({});

  const [firstName] = useDebounce(values.firstName, 500);
  const [lastName] = useDebounce(values.lastName, 500);
  const [cellPhone] = useDebounce(values.cellPhone, 500);
  const [dateOfBirth] = useDebounce(values.dateOfBirth, 500);

  const getSearchList = useUsersGetDeepSearchQuery({
    page: page,
    ...(firstName && !selectedRow && { firstName: firstName }),
    ...(lastName && !selectedRow && { lastName: lastName }),
    ...(cellPhone && !selectedRow && { cellPhone: cellPhone }),
    ...(dateOfBirth &&
      !selectedRow && { dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD') }),
    ...(values.sex && !selectedRow && { sex: values.sex }),
  });

  const messageStartRef = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView();
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedRow) {
      setPage(1);
    }
  }, [
    values.firstName,
    values.lastName,
    values.cellPhone,
    values.dateOfBirth,
    values.sex,
  ]);

  const disableButton = useMemo(
    () =>
      !values.firstName ||
      !values.lastName ||
      !values.cellPhone ||
      !values.dateOfBirth ||
      !values.sex,
    [values.firstName, values.lastName, values.cellPhone, values.dateOfBirth, values.sex],
  );

  useEffect(() => {
    setValues({
      ...values,
      firstName: selectedRow?.profile?.firstName || '',
      lastName: selectedRow?.profile?.lastName || '',
      cellPhone: selectedRow?.phone || '',
      sex: selectedRow?.profile?.sex || '',
      dateOfBirth: selectedRow?.profile?.dateOfBirth
        ? dayjs(selectedRow?.profile?.dateOfBirth)
        : '',
    });
  }, [selectedRow]);

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Content>
        <S.Article>{title || 'Initially insured'}</S.Article>
        <S.MainContent>
          <S.InputItems>
            <S.HelperText>
              Please enter the required data and check if the requested person is in the
              Patient lists of the App. If not, click Save to create a new entry.
            </S.HelperText>
            <S.InputRow>
              <Input
                label={'First name'}
                id="firstName"
                disabled={selectedRow?.profile?.firstName ? true : false}
                name="firstName"
                isRequired
                value={values.firstName}
                onChange={handleChangeInput}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <Input
                label={'Last name'}
                id="lastName"
                name="lastName"
                isRequired
                disabled={selectedRow?.profile?.lastName ? true : false}
                value={values.lastName}
                onChange={handleChangeInput}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                disabled={selectedRow?.phone ? true : false}
                value={values.cellPhone}
                onChange={handleChangeInput}
              >
                <Input
                  label="Cell Phone "
                  id="cellPhone"
                  name="cellPhone"
                  disabled={selectedRow?.phone ? true : false}
                  isRequired
                  error={!!errors.cellPhone}
                  helperText={errors.cellPhone}
                />
              </ReactInputMask>
            </S.InputRow>
            <S.InputRow>
              <SelectInput
                label={'Sex'}
                id="sex"
                name="sex"
                isRequired
                disabled={selectedRow?.profile?.sex ? true : false}
                error={!!errors.sex}
                helperText={errors.sex}
                value={values.sex}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('sex', '');
                  handleChange(e);
                }}
              >
                <MenuItem value="male">
                  <S.MenuItemContent>Male</S.MenuItemContent>
                </MenuItem>
                <MenuItem value="female">
                  <S.MenuItemContent>Female</S.MenuItemContent>
                </MenuItem>
              </SelectInput>
              <DatePicker
                label="Date of Birth"
                id="dateOfBirth"
                name="dateOfBirth"
                isRequired
                disabled={selectedRow?.profile?.dateOfBirth ? true : false}
                disableFuture
                value={values.dateOfBirth}
                onChange={value => {
                  setFieldError('dateOfBirth', '');
                  setFieldValue('dateOfBirth', value);
                }}
                error={!!errors.dateOfBirth}
                helperText={errors.dateOfBirth ? 'Not valid date format' : ''}
              />
            </S.InputRow>

            <S.Footer>
              <S.ButtonWrapper>
                <Button
                  text="Save"
                  onClick={() => handleSubmit()}
                  disabled={disableButton}
                />
              </S.ButtonWrapper>
            </S.Footer>
          </S.InputItems>
          <S.SearchContentWrapper>
            <S.ResetFilter
              onClick={() => {
                setSelectedRow(null);
                resetForm();
                setPage(1);
                scrollToTop();
              }}
            >
              Reset filters
            </S.ResetFilter>
            <S.SearchContent>
              {getSearchList.isLoading ? (
                <CircularLoader color="#0084B1" />
              ) : (
                <SearchContentList
                  count={getSearchList.currentData?.count}
                  data={getSearchList.currentData}
                  page={page}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  setPage={setPage}
                  messageStartRef={messageStartRef}
                  scroll={scrollToTop}
                  isFetching={getSearchList.isLoading}
                />
              )}
            </S.SearchContent>
          </S.SearchContentWrapper>
        </S.MainContent>
      </S.Content>
    </form>
  );
};

export default InsuredListDialog;
