import styled from 'styled-components';

export const SchedBtn = styled.div<{ background: string; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 38px;
  border-radius: 5px;
  border: 0.5px solid var(--line, #d7d7d7);

  background: ${props => props.background};

  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const BtnText = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  user-select: none;
`;
