import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  titleText: string;
  rejectText?: string;
  onReject?: () => void;
  successText?: string;
  helperText?: string;
  reason?: string;
  noteText?: string;
};

const SuccessDialog: React.FC<Props> = ({
  onClose,
  titleText,
  onSuccess,
  onReject,
  successText,
  rejectText,
  helperText,
  reason,
  noteText,
}) => {
  const handleConfirmClick = () => {
    onClose();
    onSuccess();
  };
  return (
    <S.Content>
      <S.Article>{titleText}</S.Article>
      <S.HelperText>{helperText}</S.HelperText>
      {reason && (
        <S.Reason>
          <S.Span>Reason: </S.Span>
          {reason}
        </S.Reason>
      )}
      {noteText && (
        <S.Reason>
          <S.Span>NOTE: </S.Span>
          {noteText}
        </S.Reason>
      )}
      <S.Buttons>
        <S.ButtonWrapper>
          <Button
            text={rejectText || 'No'}
            onClick={onReject || onClose}
            backgroundColor="#848A9B"
          />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button text={successText || 'Yes'} onClick={handleConfirmClick} />
        </S.ButtonWrapper>
      </S.Buttons>
    </S.Content>
  );
};

export default SuccessDialog;
