import { SwipeableDrawer } from '@mui/material';
import * as S from './styles';
import { useState } from 'react';
import * as React from 'react';
import { CloseBtnSVG } from '../../../assets/icons';

type DrawerProps = {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
};

const Drawer: React.FC<DrawerProps> = ({ open, onClose, children }) => {
  return (
    <S.Drawer anchor={'right'} open={open} onClose={onClose} onOpen={() => {}}>
      <S.DrawerContent>
        <S.DrawerHeader>
          <CloseBtnSVG onClick={onClose} />
        </S.DrawerHeader>
        <S.DrawerMain>{children}</S.DrawerMain>
      </S.DrawerContent>
    </S.Drawer>
  );
};

export default Drawer;
