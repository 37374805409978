import { useParams } from 'react-router-dom';
import { AddClinicIconSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, Dialog } from 'components';
import { useDoctorInsurancesQuery } from 'store/api/staff/staffApi';
import * as S from '../../styles';
import InsuranceToRow from './TableData/InsuranceToRow';
import { useEffect, useState } from 'react';
import DialogAddInsuranceTo from '../Dialog_AddInsuranceTo';
import { Clinics } from 'types/ClinicTypes';

type Props = {
  assignClinics?: Array<Clinics>;
};

export type InsuranceToDoctor = {
  id: string;
  clinicUserId: string;
  payerName: string;
  payerId: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  clinicUser: {
    id: string;
    userId: string;
    clinicId: string;
    position: string;
    speciality: any;
    workingStartDate: string;
    workingEndDate: any;
    isWorkInClinic: boolean;
    accountNumber: any;
    isPatient: boolean;
    createdAt: string;
    updatedAt: string;
    clinic: {
      id: string;
      name: string;
      logo: {
        id: string;
        url: string;
      };
    };
  };
};

const headers = [
  { name: 'Clinic', key: 'Clinic' },
  { name: 'Payer ID', key: 'PayerID' },
  { name: 'Payer Name', key: 'PayerName' },
  { name: 'Type', key: 'Type' },
  { name: '', key: 'Edit' },
];

const DOCTOR_INSURANCES_LIMIT = 20;

const InsuranceTo: React.FC<Props> = ({ assignClinics }) => {
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [editedInsurance, setEditedInsurance] = useState<InsuranceToDoctor | null>(null);

  const getDoctorInsurances = useDoctorInsurancesQuery({
    id: params.profileId,
    page,
    take: 20,
  });

  const handleChangeOpen = () => {
    setEditedInsurance(null);
    setOpen(!open);
  };

  useEffect(() => {
    if (editedInsurance) {
      setOpen(true);
    }
  }, [editedInsurance]);

  return (
    <>
      <Dialog open={open} onClose={handleChangeOpen}>
        <DialogAddInsuranceTo
          onClose={handleChangeOpen}
          assignClinics={assignClinics}
          doctorId={params.profileId}
          editedInsurance={editedInsurance}
        />
      </Dialog>
      <S.InsuranceWrapper>
        <S.Line className="margin">
          <CustomPagination
            isFetching={getDoctorInsurances.isFetching}
            setPage={setPage}
            currentPage={getDoctorInsurances.currentData?.page}
            lastPage={getDoctorInsurances.currentData?.lastPage}
            count={getDoctorInsurances?.currentData?.count}
            text={'insurances'}
            limit={DOCTOR_INSURANCES_LIMIT}
          />
          <S.ButtonWrapper>
            <Button text="Add Insurance" onClick={handleChangeOpen}>
              <AddClinicIconSVG />
            </Button>
          </S.ButtonWrapper>
        </S.Line>
        <CustomTable headers={headers} isFetching={getDoctorInsurances.isFetching}>
          <InsuranceToRow
            data={getDoctorInsurances.currentData?.rows}
            headers={headers}
            setEditedInsurance={setEditedInsurance}
          />
        </CustomTable>
      </S.InsuranceWrapper>
    </>
  );
};

export default InsuranceTo;
