import {
  IconButton,
  Input,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import * as S from './styles';
import { AttachFileSVG, SendMessageBtnSVG } from '../../../assets/icons';
import { useRef } from 'react';

type Props = {
  background?: string;
  sendMsg: () => void;
  handleChangeFile: (e: any) => void;
  loading: boolean;
  hasFiles: boolean;
} & TextFieldProps;

const MessageInput: React.FC<Props> = ({
  background,
  sendMsg,
  handleChangeFile,
  loading,
  hasFiles,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };
  return (
    <S.MessageInputWrapper>
      <TextField
        maxRows={3}
        multiline
        placeholder="Write a message"
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ position: 'absolute', bottom: 30, right: 0 }}
            >
              <label htmlFor="file">
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  onChange={handleChangeFile}
                  accept="image/png, image/jpeg, application/pdf"
                  hidden
                  multiple={true}
                ></input>
              </label>
              <IconButton className="attachFile" onClick={handleClick}>
                <AttachFileSVG />
              </IconButton>

              <IconButton
                disabled={(props.value || hasFiles) && !loading ? false : true}
                className={(props.value || hasFiles) && !loading ? '' : 'disabled'}
                onClick={sendMsg}
              >
                <SendMessageBtnSVG />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </S.MessageInputWrapper>
  );
};

export default MessageInput;
