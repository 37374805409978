import * as S from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
  title?: string;
  stackedData?: any;
  records?: Array<any>;
  labels?: Array<String>;
  propsOptions?: any;
};

const VerticalChart: React.FC<Props> = ({
  title,
  labels,
  stackedData,
  records,
  propsOptions,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: title,
        data: records?.map(item => item.count as number),
        backgroundColor: '#E1EBEE',
        borderRadius: 7,
        hoverBackgroundColor: '#0084B1',
      },
    ],
  };
  return (
    <S.VerticalChartWrapper>
      <Bar options={propsOptions || options} data={stackedData || data} className="bar" />
    </S.VerticalChartWrapper>
  );
};

export default VerticalChart;
