import { FormikProps } from 'formik';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { AutoCompleteDatePicker } from 'components/atoms/AutoComplete';
import Input from 'components/atoms/Input';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { useLazyUsersGetDeepSearchQuery } from 'store/api/searchApi/searchApi';
import { useDebounce } from 'use-debounce';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  clinic?: string;
  error?: string;
  appointmentInformation?: AppointmentsNew;
  emptyDatePicker?: boolean;
  setEmptyDatePicker: (emptyDatePicker: boolean) => void;
};

const DOB = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      clinic,
      appointmentInformation,
      emptyDatePicker,
      setEmptyDatePicker,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);

    const [allPatientsGet, allPatientsStatus] = useLazyUsersGetDeepSearchQuery({});

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (
        !userProfile &&
        !duplAppointment &&
        !appointmentInformation &&
        debouncedSearchValue &&
        (!selectedAppointment || selectedAppointment?.isPossibleToUpdate)
      ) {
        allPatientsGet({
          dateOfBirth: searchValue ? dayjs(searchValue).format('YYYY-MM-DD') : undefined,
          page: 1,
          take: 100,
          ...(clinic && { clinicId: clinic }),
        });
      }
    }, [debouncedSearchValue, clinic]);

    useEffect(() => {
      if (selectedValue) {
        formik.setValues({
          ...formik.values,
          firstName: selectedValue.profile?.firstName || '',
          lastName: selectedValue.profile?.lastName || '',
          phone: selectedValue?.phone || '',
          userId: selectedValue?.id || '',
          email: selectedValue?.email || '',
          dateOfBirth:
            dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY') || '',
        });
        setSearchValue(dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY'));
      }
    }, [selectedValue]);

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('dateOfBirth', searchValue);
      }
    }, [searchValue]);

    useEffect(() => {
      if (emptyDatePicker) {
        setSearchValue('');
        setEmptyDatePicker(false);
      }
    }, [emptyDatePicker]);

    return (
      <>
        {byClick ? (
          <AutoCompleteDatePicker
            mainLabel={'DOB'}
            isRequired
            data={allPatientsStatus?.data?.rows || []}
            loading={allPatientsStatus.isFetching}
            searchValue={searchValue}
            disabled={
              !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
            }
            selectedValue={selectedValue}
            setSearchValue={setSearchValue}
            setSelectedValue={setSelectedValue}
            error={!!formik.errors.dateOfBirth}
            helperText={formik.errors.dateOfBirth as string}
          />
        ) : (
          <Input
            label="DOB"
            id="dateOfBirth"
            name="dateOfBirth"
            error={!!formik.errors.dateOfBirth}
            helperText={formik.errors.dateOfBirth as string}
            value={dayjs(formik.values.dateOfBirth).format('MM/DD/YYYY') || ''}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.dateOfBirth ||
              !!selectedAppointment?.patient?.profile?.dateOfBirth ||
              !!duplAppointment?.patient?.profile?.dateOfBirth ||
              !!selectedValue
            }
          />
        )}
      </>
    );
  },
);

export default DOB;
