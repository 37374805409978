import dayjs from 'dayjs';
import { AvatarSVG, ClaimsSVG, ClockPinSVG } from 'assets/icons';
import { EventView } from 'types/AppointmentsTypes';
import UserStatusPin from 'components/atoms/UserStatusPin';
import * as S from './styles';
import moment from 'moment';

type Props = {
  event: EventView;
};

const HoverInformation: React.FC<Props> = ({ event }) => {
  return (
    <S.Wrapper>
      <S.FirstLineWrapper>
        <S.Line>
          <S.Title>
            <S.Article>{event.resource?.type ?? '-'}</S.Article>
            <UserStatusPin type={event.resource.status} />
          </S.Title>
          {event.resource.isFirstTimeVisit && (
            <S.FirstTimeVisit>
              <ClaimsSVG />
              <S.FTVText>First time visit</S.FTVText>
            </S.FirstTimeVisit>
          )}
        </S.Line>
        <S.TimeDiv>
          <ClockPinSVG />
          {dayjs(event.start).format('h:mm')}-{dayjs(event.end).format('h:mm A')}
        </S.TimeDiv>
      </S.FirstLineWrapper>
      <S.SecondLine>
        <S.UserInfo>
          {event.resource.selectedEvent?.patient?.avatar?.url ? (
            <img src={event.resource.selectedEvent?.patient?.avatar.url} alt="img" />
          ) : (
            <AvatarSVG />
          )}
          <S.UserName>
            {event.resource.selectedEvent?.patient?.profile?.firstName}{' '}
            {event.resource.selectedEvent?.patient?.profile?.lastName}
          </S.UserName>
          <S.UserName>
            {' '}
            {moment().diff(
              event.resource.selectedEvent?.patient?.profile?.dateOfBirth,
              'years',
            )}{' '}
            y.o.
          </S.UserName>
        </S.UserInfo>
        <S.Phone>{event.resource.selectedEvent?.patient?.phone}</S.Phone>
      </S.SecondLine>
      <S.FirstLineWrapper>
        <S.Line>
          <S.Note>{event.resource.selectedEvent.note}</S.Note>
        </S.Line>
      </S.FirstLineWrapper>
    </S.Wrapper>
  );
};

export default HoverInformation;
