import { useEffect, useState } from 'react';
import { AppointmentsNew, TemplateList } from '../../../../../types/AppointmentNewTypes';
import CheckBoxWithLabel from '../../../../atoms/CheckboxWithLabel';
import CircularLoader from '../../../../atoms/CircuralLoader';
import * as S from './styles';
import { Item } from '../../../LogoutDiv/styles';
import Button from '../../../../atoms/Button';
import { useStartAppointmentMutation } from '../../../../../store/api/start-progress/startProgressApi';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../types/DrawerMode';
import { setMessage } from '../../../../../store/slices/message';

type Props = {
  onClose: () => void;
  data?: TemplateList[];
  isLoading?: boolean;
  appointment?: AppointmentsNew;
};

const DialogStartAppointment: React.FC<Props> = ({
  onClose,
  data,
  isLoading,
  appointment,
}) => {
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateList[]>([]);
  useEffect(() => {
    data && setSelectedTemplates(data);
  }, [data]);

  const handleChangeList = (id: string) => {
    const newArr = selectedTemplates.map(template => {
      if (id === template.id) {
        return {
          ...template,
          isChecked: !template.isChecked,
        };
      } else return template;
    });
    setSelectedTemplates(newArr);
  };

  const handleStart = () => {
    const ids = selectedTemplates
      .filter(template => template.isChecked)
      .map(item => item.id);
    return ids;
  };

  const navigate = useNavigate();

  const [startAppointment, startAppointmentStatus] = useStartAppointmentMutation({});

  const dispatch = useAppDispatch();

  return (
    <S.Content>
      <S.Article>Start Appointment</S.Article>
      <S.HelperText>
        {startAppointmentStatus.isLoading
          ? 'Please, wait until the appointment starts'
          : 'Please, choose needed templates before start'}
      </S.HelperText>
      {isLoading || startAppointmentStatus.isLoading ? (
        <S.Loader>
          <CircularLoader color="#0084B1" />
        </S.Loader>
      ) : (
        <>
          {selectedTemplates &&
            selectedTemplates.map(item => {
              return (
                <S.Row key={item.id}>
                  <CheckBoxWithLabel
                    label={item.template.name}
                    checked={item.isChecked}
                    value={item.isChecked}
                    onChange={() => handleChangeList(item.id)}
                  />
                </S.Row>
              );
            })}
          <S.ButtonWrapper>
            <Button
              text="Start"
              onClick={() => {
                const clinicTemplatesIds = handleStart();
                startAppointment({
                  appointmentId: appointment?.id,
                  clinicTemplatesIds: clinicTemplatesIds,
                })
                  .unwrap()
                  .then(res => {
                    onClose();
                    dispatch(
                      showDrawer({
                        show: false,
                        mode: DrawerMode.DEFAULT,
                        props: null,
                      }),
                    );
                    navigate(`/appointment-process/${res.appointmentId}`);
                  })
                  .catch(err => {
                    dispatch(
                      setMessage({
                        type: 'error',
                        message: 'Something went wrong!',
                      }),
                    );
                    onClose();
                  });
              }}
            />
          </S.ButtonWrapper>
        </>
      )}
    </S.Content>
  );
};

export default DialogStartAppointment;
