import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 29px;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 38px;
  }
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 117%; /* 18.72px */
  width: 377px;
  margin-top: 14px;

  @media ${devices.xs} {
    width: 284px;
    font-size: 13px;
    margin-top: 12px;
  }
`;

export const Items = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  margin-top: 24px;

  width: 430px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const ReceiveCode = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ResendSpan = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

export const ChangeEmailText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  width: 174px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media ${devices.xs} {
    width: 300px;
  }
`;
