import { ChangeEvent, useEffect, useState } from 'react';
import {
  CircularLoader,
  DatePicker,
  Dialog,
  Input,
  SelectInput,
  SuccessDialog,
} from '../../../../../../components';
import * as S from '../../styles';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Relation } from '../../../../../../types/enums/AppointmentEnum';
import InformationDialog from '../../../../../../components/molecules/InformationDialog';
import { AddRectSVG, CloseSVG } from '../../../../../../assets/icons';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { File, ResponseFile } from '../../../../../../types/FileType';
import {
  useDeleteFileMutation,
  useFilesCreateMutation,
} from '../../../../../../store/api/files/files';
import { FormikProps } from 'formik';
import { FormValuesAuth } from '../../SignUpDetails';

type Props = {
  formik: FormikProps<FormValuesAuth>;
  step: number;
};

const SecondarySignUp: React.FC<Props> = ({ formik, step }) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    setOpen(true);
  }, [step]);

  const [open, setOpen] = useState(false);

  const [uploadFiles, statusUploadFiles] = useFilesCreateMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});

  const [filesValues, setFilesValues] = useState<Array<File>>([]);

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      uploadFiles({ files: [...file] })
        .unwrap()
        .then(res => {
          const newItemsArr: File[] = [];
          res.map((item: ResponseFile, index: number) => {
            newItemsArr.push(item.value);
          });
          setFilesValues([...filesValues, ...newItemsArr]);
          formik.setFieldValue('secFiles', [...formik.values.secFiles, ...newItemsArr]);
        })
        .catch(error => console.log(error));
    }
  };

  const handleDelete = (id: string) => {
    deleteFile({ id: id })
      .unwrap()
      .then(res => {
        const filteredArr = filesValues.filter(element => element.id !== id);
        const filteredFormikArr = formik.values.secFiles.filter(
          element => element.id !== id,
        );
        setFilesValues(filteredArr);
        formik.setFieldValue('secFiles', filteredFormikArr);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <InformationDialog
          onClose={() => setOpen(!open)}
          helperText="If you have a secondary insurance please fill out the fields and press next, otherwise press skip. By pressing skip you are confirming that you do not have secondary insurance."
          titleText="You can skip this step"
          onSuccess={() => setOpen(!open)}
        />
      </Dialog>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.Inputs>
            <S.ArticleH3>Secondary Insurance Company</S.ArticleH3>
            <S.InputRow className="label">
              <Input
                id="secPrimaryInsurance"
                name="secPrimaryInsurance"
                value={formik.values.secPrimaryInsurance}
                onChange={handleChangeInput}
                error={!!formik.errors.secPrimaryInsurance}
                helperText={formik.errors.secPrimaryInsurance}
                isRequired
                label="Secondary Insurance Company"
                background="#fff"
              />
              <Input
                id="secCompanyPhone"
                name="secCompanyPhone"
                value={
                  formik.values.secCompanyPhone[0] === '+' ||
                  formik.values.secCompanyPhone[0] === '+1' ||
                  !formik.values.secCompanyPhone.length
                    ? formik.values.secCompanyPhone
                    : '+1' + formik.values.secCompanyPhone
                }
                onChange={handleChangeInput}
                error={!!formik.errors.secCompanyPhone}
                helperText={formik.errors.secCompanyPhone}
                label="Company phone"
                background="#fff"
              />
            </S.InputRow>
            <S.InputRow className="label">
              <Input
                id="secSubscriberName"
                name="secSubscriberName"
                value={formik.values.secSubscriberName}
                onChange={handleChangeInput}
                error={!!formik.errors.secSubscriberName}
                helperText={formik.errors.secSubscriberName}
                label="Insured/ Subscriber Name"
                background="#fff"
                isRequired
              />
              <S.InputRow className="devide">
                <DatePicker
                  id="secSubscriberDOB"
                  name="secSubscriberDOB"
                  disableFuture
                  value={formik.values.secSubscriberDOB}
                  onChange={value => {
                    formik.setFieldError('secSubscriberDOB', '');
                    formik.setFieldValue('secSubscriberDOB', value);
                  }}
                  error={!!formik.errors.secSubscriberDOB}
                  helperText={formik.errors.secSubscriberDOB}
                  isRequired
                  label="Date of Birth"
                  background="#fff"
                />
                <SelectInput
                  id="secRelationshipOfPatient"
                  name="secRelationshipOfPatient"
                  value={formik.values.secRelationshipOfPatient}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('secRelationshipOfPatient', '');
                    formik.handleChange(e);
                  }}
                  error={!!formik.errors.secRelationshipOfPatient}
                  helperText={formik.errors.secRelationshipOfPatient}
                  isRequired
                  label="Relationship to Patient"
                  background="#fff"
                >
                  <MenuItem value={Relation.SELF}>
                    <S.MenuItemContent>Self</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.CHILD}>
                    <S.MenuItemContent>Child</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.SPOUSE}>
                    <S.MenuItemContent>Spouse</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.OTHER}>
                    <S.MenuItemContent>Other</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputRow>
            </S.InputRow>
            <S.InputRow className="devide">
              <Input
                id="secInsuredSSN"
                name="secInsuredSSN"
                isRequired
                value={formik.values.secInsuredSSN}
                onChange={handleChangeInput}
                error={!!formik.errors.secInsuredSSN}
                helperText={formik.errors.secInsuredSSN}
                label="Insured SSN"
                background="#fff"
              />
              <Input
                id="secInsuredID"
                name="secInsuredID"
                value={formik.values.secInsuredID}
                onChange={handleChangeInput}
                error={!!formik.errors.secInsuredID}
                helperText={formik.errors.secInsuredID}
                label="ID"
                isRequired
                background="#fff"
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="secInsuredGroup"
                name="secInsuredGroup"
                type="number"
                value={formik.values.secInsuredGroup}
                onChange={handleChangeInput}
                error={!!formik.errors.secInsuredGroup}
                helperText={formik.errors.secInsuredGroup}
                label="Group #"
                isRequired
                background="#fff"
              />
              <Input
                id="secInsuredGroupName"
                name="secInsuredGroupName"
                value={formik.values.secInsuredGroupName}
                onChange={handleChangeInput}
                error={!!formik.errors.secInsuredGroupName}
                helperText={formik.errors.secInsuredGroupName}
                label="Group name"
                isRequired
                background="#fff"
              />
            </S.InputRow>
          </S.Inputs>
          <S.UploadPhotoWrapper>
            <S.PhotoWrapper>
              <S.PhotoLabel>
                Please upload photo of your Insurance card
                <S.RequiredSpan> *</S.RequiredSpan>
              </S.PhotoLabel>
              <S.SubArticle>Available formats JPG, JPEG, PNG, max size 50MB</S.SubArticle>
              {!!formik.errors?.secFiles && (
                <S.ErrorText>{'You must upload at least 1 photo'}</S.ErrorText>
              )}
            </S.PhotoWrapper>
            <label htmlFor="secFiles">
              <S.AddPhotoBtn>
                <AddRectSVG />
                Upload photo
              </S.AddPhotoBtn>
              <input
                type="file"
                id="secFiles"
                onChange={e => {
                  formik.setFieldError('secFiles', '');
                  handleChangeFile(e);
                }}
                accept="image/png, image/jpeg"
                hidden
                multiple={true}
              ></input>
            </label>
          </S.UploadPhotoWrapper>
          <S.UploadedPhotos>
            <PhotoProvider>
              {formik.values.secFiles?.map((item: File) => {
                return (
                  <PhotoView key={item.id} src={item?.url}>
                    <S.ImgWrapper>
                      <S.DeleteCircleSmall
                        onClick={e => {
                          e.stopPropagation();
                          handleDelete(item?.id);
                        }}
                      >
                        <CloseSVG />
                      </S.DeleteCircleSmall>
                      <img src={item?.url} alt=""></img>
                    </S.ImgWrapper>
                  </PhotoView>
                );
              })}
            </PhotoProvider>
            {statusUploadFiles.isLoading && (
              <S.LoaderWrapper>
                <CircularLoader color="#0084B1" />
              </S.LoaderWrapper>
            )}
          </S.UploadedPhotos>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default SecondarySignUp;
