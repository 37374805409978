import { Clinics, Patients } from '../../../types/ClinicTypes';
import { TicketType } from '../../../types/TicketType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export type StaffSchedule = {
  id: string;
  weekDay: number;
  workingHoursStart: string;
  workingHoursEnd: string;
  isWorkDay?: boolean;
  clinicId: string;
  clinic: {
    name: string;
  };
};

export const clinicScheduleApi = api.injectEndpoints({
  endpoints: builder => ({
    clinicScheduleGet: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/clinic-schedule?clinicId=${id}`,
        params: { ...params },
      }),
      providesTags: ['ClinicSchedule'],
    }),
    clinicScheduleCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/clinic-schedule`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'ClinicSchedule' }],
    }),
    clinicScheduleUpdate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/clinic-schedule`,
        body: { ...body },
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: 'ClinicSchedule' }],
    }),
    clinicScheduleCheckWorkingTime: builder.query<any, any>({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/check-working-time`,
        params: { ...params },
      }),
      providesTags: ['ClinicSchedule'],
    }),
    clinicScheduleCheckWorkingTimeDiapason: builder.query<any, any>({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/check-working-time/diapason`,
        params: { ...params },
      }),
      providesTags: ['ClinicSchedule'],
    }),
    clinicCheckWorkingTimeDiapason: builder.mutation({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/check-working-time/diapason`,
        params: { ...params },
        method: 'GET',
      }),
    }),
    getClinicWorkingTime: builder.mutation({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/check-working-time`,
        params: { ...params },
        method: 'GET',
      }),
    }),
    clinicScheduleWeekends: builder.query<any, any>({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/get-weekends`,
        params: { ...params },
      }),
      providesTags: ['ClinicSchedule'],
    }),
    getClinicWeekends: builder.mutation({
      query: ({ clinicId, ...params }) => ({
        url: `/clinic-schedule/${clinicId}/get-weekends`,
        params: { ...params },
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useClinicScheduleGetQuery,
  useClinicScheduleCreateMutation,
  useClinicScheduleUpdateMutation,
  useClinicScheduleCheckWorkingTimeQuery,
  useClinicScheduleWeekendsQuery,
  useClinicScheduleCheckWorkingTimeDiapasonQuery,
  useGetClinicWeekendsMutation,
  useGetClinicWorkingTimeMutation,
  useClinicCheckWorkingTimeDiapasonMutation,
} = clinicScheduleApi;
