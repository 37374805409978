import styled from 'styled-components';
import { SwipeableDrawer } from '@mui/material';

export const Drawer = styled(SwipeableDrawer)`
  width: 100%;
  & .MuiPaper-root {
  }
`;

export const DrawerContent = styled.div``;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: end;
  height: 30px;
  & svg {
    margin-top: 9px;
    margin-right: 9px;
    cursor: pointer;
  }
`;

export const DrawerMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;
