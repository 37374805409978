import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import * as S from './styles';
import { useCallback } from 'react';

type Props = {
  mainLabel?: string;
  isRequired?: boolean;
  data: number[];
  value: number;
  onChange: (value: number) => void;
  error?: boolean;
  helperText?: string;
  suffix?: string;
  disabled?: boolean;
  defaultServiceTime?: number;
};

export const AutoCompleteFromEnum: React.FC<Props> = ({
  mainLabel,
  isRequired,
  data,
  value,
  error,
  helperText,
  suffix,
  onChange,
  disabled,
  defaultServiceTime,
}) => {
  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
      return (
        <Box component="li" {...props} key={option}>
          <S.LiWrapper>
            <S.OptionWrap>{option}</S.OptionWrap>
            {option === defaultServiceTime && <S.DateOfBirth>Default</S.DateOfBirth>}
          </S.LiWrapper>
        </Box>
      );
    },
    [defaultServiceTime],
  );

  return (
    <S.Wrapper>
      <S.Label htmlFor="input">
        {mainLabel} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <Autocomplete
        freeSolo
        value={value}
        disabled={disabled}
        options={data || []}
        filterOptions={x => x}
        inputValue={`${value}`}
        onInputChange={(e, newInputValue) => {
          onChange(+newInputValue.replace(/[^0-9]/g, ''));
        }}
        renderOption={renderOption}
        renderInput={params => (
          <TextField
            placeholder=""
            error={error}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderColor: error ? 'red' : '',
              },
            }}
            helperText={helperText}
            {...params}
            FormHelperTextProps={{
              focused: false,
            }}
            InputProps={{
              ...params?.InputProps,
              inputMode: 'numeric',
              endAdornment: (
                <>
                  {!!suffix && <InputAdornment position="end">{suffix}</InputAdornment>}
                  {params?.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </S.Wrapper>
  );
};
