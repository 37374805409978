import * as yup from 'yup';
import { emailValidator, phoneValidator } from 'constants/constants';

export const AppointmentWithoutReg = yup.object().shape({
  firstName: yup.string().required('Is required'),
  lastName: yup.string().required('Is required'),
  phone: phoneValidator,
  email: emailValidator,
  date: yup.string().required('Is required'),
  clinicId: yup.string().required('Is required'),
  visits: yup.array().of(
    yup.object().shape({
      typeServiceId: yup.string().required('Is required'),
      startTime: yup.string().required('Is required'),
      visitLengthMinutes: yup.number().min(1, 'Is required'),
      specialistId: yup.string(),
    }),
  ),

  typeServiceIdes: yup.array().of(yup.string()).min(1, 'Is required'),
  paymentMethod: yup.string().nullable(),
});
