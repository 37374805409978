import dayjs from 'dayjs';
import {
  ClockPinSVG,
  DeleteRedBtnSVG,
  NoteIconSVG,
  PlacePinSVG,
} from '../../../../assets/icons';
import { TicketType } from '../../../../types/TicketType';
import CheckBox from '../../../atoms/CheckBox';
import * as S from './styles';
import {
  useTicketsDeleteMutation,
  useTicketsMarkDoneMutation,
} from '../../../../store/api/tickets/ticketApi';
import { Dialog, SuccessDialog } from '../../..';
import { MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../store/slices/message';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../types/DrawerMode';
import { transformNoteText } from '../../../../helpers/functions/transformNoteText';
import { Fade, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppointmentGetOneMutation } from '../../../../store/api/appointment/appointmentApi';
import { getRoleName } from '../../../../helpers/functions/roleName';
import ConfirmDeleteDialog from '../../../molecules/ConfirmDeleteDialog';
import { useAppSelector } from '../../../../hooks/useAppSelector';

type Props = {
  ticket: TicketType;
  isCanDelete?: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setIsRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  isFromOutgoing?: boolean;
};

const TicketRecord: React.FC<Props> = ({
  ticket,
  isCanDelete,
  setPage,
  setIsRefetch,
  isFromOutgoing,
}) => {
  const [markDone] = useTicketsMarkDoneMutation();
  const dispatch = useDispatch();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const handleCloseDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    setIsOpenDialog(!isOpenDialog);
  };

  const userInfo = useAppSelector(state => state.auth);
  const handleShowDrawerTicket: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    dispatch(
      showDrawer({
        show: true,
        mode: DrawerMode.TICKET_CREATE,
        props: ticket,
      }),
    );
  };

  const handleShowDoneDialog: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    handleCloseDialog(e);
  };

  const navigate = useNavigate();

  const [getAppointmentOne] = useAppointmentGetOneMutation({});
  const [ticketsDelete] = useTicketsDeleteMutation({});

  return (
    <S.Wrapper done={ticket.isDone.toString()}>
      <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
        <SuccessDialog
          helperText="Are you sure, that you want to mark this ticket as Done?"
          successText="Mark as Done"
          onClose={handleCloseDialog}
          titleText="Mark as Done?"
          onSuccess={() =>
            markDone({ id: ticket.id })
              .unwrap()
              .then(res => {
                setPage(1);
                setIsRefetch(true);
                dispatch(
                  setMessage({
                    message: 'Ticket marked as Done',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              })
          }
        />
      </Dialog>
      <Dialog
        open={isOpenDeleteDialog}
        onClose={() => {
          setIsOpenDeleteDialog(!isOpenDeleteDialog);
        }}
      >
        <ConfirmDeleteDialog
          onClose={() => {
            setIsOpenDeleteDialog(!isOpenDeleteDialog);
          }}
          fullTitle={'Are you sure you want to delete this ticket?'}
          helperText={'You will delete this ticket permanently'}
          onSuccess={() =>
            ticketsDelete({ id: ticket.id })
              .unwrap()
              .then(res => {
                setPage(1);
                dispatch(
                  setMessage({
                    message: 'Ticket was successfully deleted',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(
                  setMessage({
                    message: error.data.message,
                    type: 'error',
                  }),
                );
              })
          }
        />
      </Dialog>
      <S.LeftBlock>
        <S.StatusBlock />
        <S.DateDiv>
          <CheckBox
            style={{
              pointerEvents:
                ticket.isDone ||
                (ticket.role !== userInfo.role && ticket.createdById !== userInfo.id)
                  ? 'none'
                  : 'all',
            }}
            className="big"
            checked={ticket.isDone}
            onClick={handleShowDoneDialog}
          ></CheckBox>
        </S.DateDiv>
        <S.Line />
        <S.RecordInformation>
          <S.ArticleDiv>
            <S.Article>
              <S.Span>#{ticket?.id?.slice(0, 8) + ' '}</S.Span>
              {ticket.name}
              <S.ColorSpan>
                Assign to: <S.ColorSpanBold>{getRoleName(ticket.role)}</S.ColorSpanBold>
              </S.ColorSpan>
            </S.Article>
            <S.SubArticle>
              Created by:
              <S.SpanBlack>
                {' '}
                {ticket.createdBy?.profile?.firstName}{' '}
                {ticket.createdBy?.profile?.lastName},{' '}
                {dayjs(ticket.createdAt).format('MM/DD/YYYY h:mm A')}
              </S.SpanBlack>
              {isCanDelete && !ticket.isDone && (
                <DeleteRedBtnSVG onClick={() => setIsOpenDeleteDialog(true)} />
              )}
            </S.SubArticle>
          </S.ArticleDiv>
          <S.MiddleDiv>
            <S.RecordTime>
              Estimated date of completion:
              <S.SpanBlack>{dayjs(ticket.date).format('MMMM DD YYYY ')} </S.SpanBlack>
            </S.RecordTime>
            {ticket.appointmentId && (
              <S.RecordTime>
                Appointment:
                <Tooltip
                  title="Click to show appointment"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                >
                  <S.Link
                    onClick={() =>
                      getAppointmentOne({
                        id: ticket.appointmentId,
                      })
                        .unwrap()
                        .then(res => {
                          dispatch(
                            showDrawer({
                              show: true,
                              mode: DrawerMode.INFO_APPOINTMENT,
                              props: res,
                            }),
                          );
                        })
                    }
                  >
                    #{ticket.appointmentId.slice(0, 8)}{' '}
                  </S.Link>
                </Tooltip>
              </S.RecordTime>
            )}
            {ticket.users && ticket.users.length > 0 && (
              <S.RecordTime>
                Users:
                {ticket.users.map(user => {
                  return (
                    <Tooltip
                      key={user.id}
                      title="Click to show profile"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                    >
                      <S.Link
                        onClick={e => {
                          e.stopPropagation();
                          navigate(`/patients/${user.id}`);
                        }}
                      >
                        {user.profile?.firstName + ' ' + user.profile?.lastName}
                      </S.Link>
                    </Tooltip>
                  );
                })}
              </S.RecordTime>
            )}
          </S.MiddleDiv>
          {ticket.description && (
            <S.DownDiv>
              <NoteIconSVG />
              <S.NoteText>{transformNoteText(ticket.description)}</S.NoteText>
            </S.DownDiv>
          )}
        </S.RecordInformation>
      </S.LeftBlock>
    </S.Wrapper>
  );
};

export default TicketRecord;
