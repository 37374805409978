import styled, { css } from 'styled-components';

export const DashboardCardWrapper = styled.div<{ $isGray: boolean }>`
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 84, 113, 0.1);
  width: 100%;
  max-height: 150px;

  ${props =>
    props.$isGray &&
    css`
      background: #f9f9fc;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 15px;
  height: auto;
`;

export const Article = styled.div<{ $isGray: boolean }>`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${props =>
    props.$isGray &&
    css`
      font-size: 16px;
      color: var(--icon, #848a9b);
    `}
`;

export const CountBox = styled.div<{ $isGray: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background: #f9f9fc;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.$isGray &&
    css`
      background: #fff;
      width: 64px;
      height: 64px;

      svg {
        width: 30px;
        height: 30px;
      }
    `}
`;

export const Number = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
  padding-bottom: 15px;
`;

export const ArticleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
`;
