import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';
export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const SubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 362px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const InputWrapper = styled.div`
  width: 750px;
  display: flex;

  @media ${devices.xs} {
    width: 250px;
  }
`;

export const Patient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PatientTitle = styled.div`
  padding-left: 6px;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 5px;

  svg,
  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
`;

export const FilterBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyResult = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background: #f9f9fc;
  width: 750px;
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SVG = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 15px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const PlaceholderText = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SearchResultDiv = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  border-bottom: 1px solid #e7e8ec;
`;
export const SearchText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;
