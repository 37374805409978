import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import FieldValue from '../DocElements/FieldValue';
import FieldWithCheckbox from '../DocElements/FieldWithCheckbox';
import dayjs from 'dayjs';
import { PagePTInsuranceType } from '../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { generateStylesConfig } from '../DocElements/generateStylesConfig';
import { Signature } from '../../../../../../../store/api/auth/authSlice';
import FieldSection from '../DocElements/FieldSection';
import FieldArticle from '../DocElements/Article';
import { ClientRegistrationType } from '../../pages/ClientRegistrationPage/ClientRegistrationType';

type Props = {
  data?: any;
  signature?: Signature | null;
  setDocument?: (doc: any) => void;
};

const GenerateClientRegistrationForm: React.FC<Props> = ({
  data,
  setDocument,
  signature,
}) => {
  const styles = generateStylesConfig();

  const GenerateClientRegistrationFormContent = () => {
    const payload: ClientRegistrationType | undefined = data?.payload;

    return (
      <Document style={styles.viewer}>
        <Page size="A4" style={styles.page}>
          <FieldWithCheckbox
            article="Did you have any Physical Therapy this year?"
            styles={styles}
            isChecked={payload && payload.isHaveTherapy}
          />
          <FieldSection>
            <FieldValue
              article="Patient name"
              text={payload?.patientName ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Patient's Birth Date"
              text={payload?.birthDate ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Age"
              text={payload?.age ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Gender"
              text={payload?.gender ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldValue
            article="Billing Address"
            text={payload?.billingAddress ?? ''}
            styles={styles}
            isPadding
          />
          <FieldValue
            article="Physical Address"
            text={payload?.physicalAddress ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="City"
              text={payload?.city ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="State"
              text={payload?.state ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Zip Code"
              text={payload?.zipCode ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Driver lic #"
              text={payload?.driversLic ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Last 4 Digits of SSN #"
              text={payload?.last4DigitsOfSSN ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Contacts" />
          <FieldSection>
            <FieldValue
              article="Home Phone"
              text={payload?.contacts.homePhone ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Work Phone"
              text={payload?.contacts.workPhone ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Cell Phone"
              text={payload?.contacts.cellPhone ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>How do you prefer to receive your statements:</Text>
            <FieldWithCheckbox
              article="E-mail"
              styles={styles}
              isChecked={payload && payload.contacts.receiveStatements.isEmail}
            />
            <FieldWithCheckbox
              article="Fax"
              styles={styles}
              isChecked={payload && payload.contacts.receiveStatements.isFax}
            />
            <FieldWithCheckbox
              article="Mail"
              styles={styles}
              isChecked={payload && payload.contacts.receiveStatements.isMail}
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="E-mail"
              text={payload?.contacts.email ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Fax"
              text={payload?.contacts.fax ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Occupation" />
          <FieldSection>
            <FieldValue
              article="Employer"
              text={payload?.occupation.employer ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Occupation"
              text={payload?.occupation.occupation ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>How did you hear about us?</Text>
            <FieldWithCheckbox
              article="Doctor"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isDoctor}
            />
            <FieldWithCheckbox
              article="Friend"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isFriend}
            />
            <FieldWithCheckbox
              article="Patient"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isPatient}
            />
            <FieldWithCheckbox
              article="Facebook"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isFaceBook}
            />
            <FieldWithCheckbox
              article="Internet"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isInternet}
            />
            <FieldWithCheckbox
              article="Instagram"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isInstagram}
            />
            <FieldWithCheckbox
              article="Ad"
              styles={styles}
              isChecked={payload && payload.occupation.howHearAboutUs.isAd}
            />
          </FieldSection>
          <FieldArticle text="Other contacts" />
          <FieldSection>
            <FieldValue
              article="Referring Physician"
              text={payload?.otherContacts.referringPhysician ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Phone"
              text={payload?.otherContacts.phone ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Marital Status</Text>
            <FieldWithCheckbox
              article="Single"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isSingle}
            />
            <FieldWithCheckbox
              article="Married"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isMarried}
            />
            <FieldWithCheckbox
              article="Divorced"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isDivorced}
            />
            <FieldWithCheckbox
              article="Widowed"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isWidowed}
            />
            <FieldWithCheckbox
              article="Separated"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isSeparated}
            />
            <FieldWithCheckbox
              article="Domestic"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isDomestic}
            />
            <FieldWithCheckbox
              article="Partner"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isParner}
            />
            <FieldWithCheckbox
              article="Minor Child"
              styles={styles}
              isChecked={payload && payload.otherContacts.maritalStatus.isMinorClild}
            />
          </FieldSection>
          <FieldValue
            article="Name of Spouse"
            text={payload?.otherContacts.nameOfSpouse ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="Patient's Birth Date"
              text={
                payload?.otherContacts.birthDate
                  ? dayjs(payload?.otherContacts.birthDate).format('MM/DD/YYYY')
                  : ''
              }
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Age"
              text={payload?.otherContacts.age ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldValue
            article="Physical Address"
            text={payload?.otherContacts.physicalAddress ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="City"
              text={payload?.otherContacts.city ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="State"
              text={payload?.otherContacts.state ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Zip Code"
              text={payload?.otherContacts.zipCode ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Spouse Employer"
              text={payload?.otherContacts.spouseEmployer ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Occupation"
              text={payload?.otherContacts.occupation ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Work Phone"
              text={payload?.otherContacts.workPhone ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Cell Phone"
              text={payload?.otherContacts.cellPhone ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Name and address of closest relative (other than spouse) in case of emergency:" />
          <FieldSection>
            <FieldValue
              article="Emergency contact"
              text={payload?.relativeContacts?.emergencyContact ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Relationship"
              text={payload?.relativeContacts?.relationship ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Phone"
              text={payload?.relativeContacts?.phone ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldValue
            article="Address"
            text={payload?.relativeContacts?.address ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="City"
              text={payload?.relativeContacts?.city ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="State"
              text={payload?.relativeContacts?.state ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Zip Code"
              text={payload?.relativeContacts?.zipCode ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Insurance" />
          <FieldSection>
            <Text style={styles.text}>Insurance:</Text>
            <FieldWithCheckbox
              article="Medicare"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isMedicaid}
            />
            <FieldWithCheckbox
              article="Medicaid"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isMedicaid}
            />
            <FieldWithCheckbox
              article="Worker's Comp"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isWorkersComp}
            />
            <FieldWithCheckbox
              article="MVI"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isMVI}
            />
            <FieldWithCheckbox
              article="HMSA"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isHMSA}
            />
            <FieldWithCheckbox
              article="Quest"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isQuest}
            />
            <FieldWithCheckbox
              article="Other Private Insurance"
              styles={styles}
              isChecked={payload && payload.insurance.insurance.isOtherPrivateInsurance}
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Primary Insurance"
              text={payload?.insurance.primaryInsurance ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Policy #"
              text={payload?.insurance.primaryPolicy ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldValue
            article="Insured name"
            text={payload?.insurance.primaryInsuredName ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="Social Sec# "
              text={payload?.insurance.primarySocialSec ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Patient's Birth Date"
              text={payload?.insurance.primaryBirthDate ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Secondary Insurance"
              text={payload?.insurance.secondaryInsurance ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Policy #"
              text={payload?.insurance.secondaryPolicy ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldValue
            article="Insured name"
            text={payload?.insurance.secondaryInsuredName ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldValue
              article="Social Sec# "
              text={payload?.insurance.secondarySocialSec ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Patient's Birth Date"
              text={payload?.insurance.secondaryBirthDate ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="All professional services rendered are the ultimate responsibility of the patient" />
          <FieldSection>
            <FieldValue article="Patient signature" text={''} styles={styles} isPadding />
            <FieldValue
              article="Date"
              text={payload ? dayjs().format('MM/DD/YYYY') : ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
        </Page>
      </Document>
    );
  };

  return data ? (
    <GenerateClientRegistrationFormContent />
  ) : (
    <PDFViewer style={styles.viewer}>
      <GenerateClientRegistrationFormContent />
    </PDFViewer>
  );
};
export default GenerateClientRegistrationForm;
