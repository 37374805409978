export enum FileType {
  ppt = 'ppt',
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  pdf = 'pdf',
  doc = 'doc',
  docx = 'docx',
  pptx = 'pptx',
  xls = 'xls',
  xlsx = 'xlsx',
  csv = 'csv',
  mp4 = 'mp4',
}
