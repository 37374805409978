import { FormikProps } from 'formik';
import { DatePicker, Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  setIsDirty?: () => void;
};
const CRInsurance = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Article>Insurance</S.Article>
      <S.Line>
        <S.Caption>Insurance:</S.Caption>
        <CheckBoxWithLabel label="Medicare" />
        <CheckBoxWithLabel label="Medicaid" />
        <CheckBoxWithLabel label="Worker's Comp" />
        <CheckBoxWithLabel label="MVI" />
        <CheckBoxWithLabel label="HMSA" />
        <CheckBoxWithLabel label="Quest" />
        <CheckBoxWithLabel label="Other Private Insurance" />
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Primary Insurance'}
            defaultValue={formik.values.insurance.primaryInsurance}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.primaryInsurance', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Policy #'}
            defaultValue={formik.values.insurance.primaryPolicy}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.primaryPolicy', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Insured Name'}
            defaultValue={formik.values.insurance.primaryInsuredName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.primaryInsuredName', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Social Sec #'}
            defaultValue={formik.values.insurance.primarySocialSec}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.primarySocialSec', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <DatePicker
            label="Patient`s Birth Date"
            defaultValue={dayjs(formik.values?.insurance.primaryBirthDate)}
            onChange={value => {
              handleChangeDatePicker(
                value,
                'insurance.primaryBirthDate',
                ref,
                setIsDirty,
              );
            }}
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Secondary Insurance'}
            defaultValue={formik.values.insurance.secondaryInsurance}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.secondaryInsurance', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Policy #'}
            defaultValue={formik.values.insurance.secondaryPolicy}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.secondaryPolicy', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Insured Name'}
            defaultValue={formik.values.insurance.secondaryInsuredName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.secondaryInsuredName', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Social Sec #'}
            defaultValue={formik.values.insurance.secondarySocialSec}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'insurance.secondarySocialSec', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <DatePicker
            label="Patient`s Birth Date"
            defaultValue={dayjs(formik.values?.insurance.secondaryBirthDate)}
            onChange={value => {
              handleChangeDatePicker(
                value,
                'insurance.secondaryBirthDate',
                ref,
                setIsDirty,
              );
            }}
          />
        </S.InputWrapper>
      </S.Line>
    </>
  ),
);

export default CRInsurance;
