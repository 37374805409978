import { Clinics, Patients } from '../../../types/ClinicTypes';
import {
  InvoiceDetail,
  InvoiceHistoryOperation,
  InvoiceItem,
  InvoicePrepaymentDetail,
  InvoiceShowHistory,
  ItemInInvoiceSheetType,
} from '../../../types/InvoiceType';
import { TicketType } from '../../../types/TicketType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const invoiceApi = api.injectEndpoints({
  endpoints: builder => ({
    invoiceListGet: builder.query<Response<any>, any>({
      query: ({ ...params }) => ({
        url: `invoice/sheet`,
        params: { ...params },
      }),
      providesTags: ['Invoice'],
    }),
    invoicePrePaymentAll: builder.query<Response<any>, any>({
      query: ({ ...params }) => ({
        url: `invoice/pre-payment`,
        params: { ...params },
      }),
      providesTags: ['Invoice'],
    }),
    invoicePostPaymentAll: builder.query<Response<any>, any>({
      query: ({ ...params }) => ({
        url: `invoice/post-payment`,
        params: { ...params },
      }),
      providesTags: ['Invoice'],
    }),
    invoicePrePaymentOne: builder.query<Response<InvoicePrepaymentDetail>, any>({
      query: ({ id, ...params }) => ({
        url: `invoice/pre-payment/${id}/items`,
        params: { ...params },
      }),
      providesTags: ['Invoice'],
    }),
    InvoicePostPaymentOne: builder.query<Response<InvoicePrepaymentDetail>, any>({
      query: ({ id, ...params }) => ({
        url: `invoice/post-payment/${id}/items`,
        params: { ...params },
      }),
      providesTags: ['Invoice'],
    }),
    invoiceGetById: builder.query<InvoiceDetail, any>({
      query: ({ id, ...params }) => ({
        url: `invoice/sheet/${id}`,
        params: { ...params },
      }),

      providesTags: ['Invoice'],
    }),
    invoiceSheetGetItemsById: builder.query<Response<ItemInInvoiceSheetType>, any>({
      query: ({ id, ...params }) => ({
        url: `invoice/sheet/${id}/items`,
        params: { ...params },
      }),

      providesTags: ['Invoice'],
    }),
    invoiceGetEditHistory: builder.query<Response<InvoiceShowHistory>, any>({
      query: ({ id, ...params }) => ({
        url: `invoice/${id}/edit-history`,
        params: { ...params },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          return;
        }
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Invoice'],
    }),
    invoiceGetPdf: builder.mutation({
      query({ id, ...params }) {
        return {
          url: `/invoice/${id}/pdf`,
          method: 'GET',
          params: { ...params },
          cache: 'no-cache',
          responseHandler: async (response: any) => {
            const blobItem = await response.blob();
            const blobURL = URL.createObjectURL(blobItem);
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.src = blobURL;
            iframe.onload = () => {
              setTimeout(() => {
                iframe.focus();
                iframe.contentWindow?.print();
              }, 1);
            };
          },
        };
      },
    }),
    invoiceItemUpdate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/invoice/item/${id}`,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: ['Invoice'],
    }),
    prepaymentInvoiceItemUpdate: builder.mutation({
      query: ({ invoiceId, id, ...data }) => ({
        url: `/invoice/pre-payment/items/${id}`,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: ['Invoice'],
    }),
    postPaymentInvoiceItemUpdate: builder.mutation({
      query: ({ invoiceId, id, ...data }) => ({
        url: `/invoice/post-payment/${invoiceId}/items/${id}`,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: ['Invoice'],
    }),
    postPaymentInvoiceMarkAsChecked: builder.mutation({
      query: ({ id, itemId }) => ({
        url: `/invoice/post-payment/${id}/items/${itemId}/mark-as-checked`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Invoice'],
    }),
  }),
});

export const {
  useInvoiceListGetQuery,
  useInvoiceGetByIdQuery,
  useInvoiceGetPdfMutation,
  useInvoiceGetEditHistoryQuery,
  useInvoiceItemUpdateMutation,
  usePrepaymentInvoiceItemUpdateMutation,
  usePostPaymentInvoiceItemUpdateMutation,
  useInvoicePrePaymentAllQuery,
  useInvoicePrePaymentOneQuery,
  useInvoicePostPaymentAllQuery,
  useInvoicePostPaymentOneQuery,
  useInvoiceSheetGetItemsByIdQuery,
  usePostPaymentInvoiceMarkAsCheckedMutation,
} = invoiceApi;
