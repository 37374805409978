import {
  PlaceHolderChatSVG,
  PlaceHolderTreatmentsSVG,
  PlaceholderAttentionSVG,
} from '../../../../../../../../assets/icons';
import * as S from './styles';

const ClinicWorkSchedulePlaceholder = () => {
  return (
    <S.Wrapper>
      <PlaceholderAttentionSVG />
      <S.HelperText>
        Please, press <S.Span>‘Change settings’</S.Span> button to create default schedule
        settings.
      </S.HelperText>
    </S.Wrapper>
  );
};

export default ClinicWorkSchedulePlaceholder;
