import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const Wrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  height: 100%;
  margin-right: 15px;

  @media ${devices.xs} {
    background: none;
  }
`;

export const BlockContent = styled.div`
  margin-top: 23px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const RecordItems = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: 16px;
  margin-right: 16px;
  gap: 10px;

  @media ${devices.xs} {
  }
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 7px;
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin: 22px 36px 10px 23px;
  display: flex;
  flex-direction: column;
`;

export const Items = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 29px 62px;
`;

export const Item = styled.div`
  width: 208px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 4px;
`;

export const ItemArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ItemValue = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 30px 28px 28px;
  margin-top: 13px;
  border-top: 0.5px solid var(--line, #d7d7d7);
`;

export const RowText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  width: 120px;
`;

export const FlexContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
`;

export const CheckBoxLabel = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Article14 = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DocumentsDiv = styled.div`
  margin-top: 37px;
`;

export const Files = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Row = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 19px 0px 19px 14px;
`;

export const DatePickerWrapper = styled.div`
  width: 150px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 25px;
  gap: 10px;
`;

export const ClearFilter = styled.div`
  user-select: none;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 3px;
  margin-top: 15px;
`;
