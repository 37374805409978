import styled from 'styled-components';

export const CustomTableWrapper = styled.div`
  width: 100%;

  & .MuiTable-root {
    border-collapse: separate;
  }

  & .MuiTableHead-root {
    background-color: #e7e8ec;

    & .MuiTableCell-root {
      color: var(--text, #202e5f);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  & .MuiTableBody-root {
    background-color: #fff;

    & .MuiTableRow-root {
      border-bottom: 0.5px solid #e7e8ec;
      border: 0.1px solid #e7e8ec;

      &.grayRow {
        background: #f9f9f9;
      }

      &.colored {
        background: #d8f2fb;
      }

      &:hover {
        border: 0.1px solid #0084b1;

        cursor: pointer;
        background: var(--hover-1, rgba(0, 132, 177, 0.08));
      }
    }
  }

  & .MuiTableCell-root {
    padding: 0px 33px;
    width: fit-content;
    text-align: center;
    height: 38px;
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0.5px solid #e7e8ec;
  }
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
    /* width: 100px !important;
    height: 100px !important; */
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
    /* width: 100px !important;
    height: 100px !important; */
  }
`;
