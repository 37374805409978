import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 25px;
  gap: 10px;
  width: 100%;
`;

export const DatePickerWrapper = styled.div`
  width: 150px;
`;

export const ClearFilter = styled.div`
  user-select: none;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 3px;

  &.withLabel {
    margin-top: 15px;
  }
`;
