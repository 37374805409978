import { TextField } from '@mui/material';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
  width: 100%;
`;

export const InputItem = styled(TextField)`
  width: 100%;
  border-radius: 5px;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-background-clip: text;
    border: none;
    box-shadow: inset 0 0 20px 30px #f9f9fc;
    -webkit-text-fill-color: #202e5f !important;
  }

  & .Mui-focused {
    border: 0.5px solid var(--line, #0084b1) !important;

    &.MuiFormHelperText-root {
      border: none !important;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }
  & .MuiButtonBase-root {
    font-size: 1rem;
    cursor: auto;
  }
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
