import { MenuItem } from '@mui/material';
import {
  CheckBox,
  DatePicker,
  Input,
  SelectInput,
} from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import ListAllConditions from './components/ListAllConditions';
import * as S from './styles';
import { FormikProps } from 'formik';
import { ChangeEvent, forwardRef, ForwardedRef } from 'react';
import { InitialVisitFormType } from '../../pages/InitialVisitFormPage/InitialVisitiFormType';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from '../../../../../../../helpers/FormikFuncs/formikFuncs';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<InitialVisitFormType>;
  setIsDirty?: () => void;
  // ref: React.MutableRefObject<Record<string, unknown>>;
};

const SymptomsBlock = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => {
    const handleChangeInput =
      (name: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (typeof ref !== 'function' && ref && ref?.current !== null) {
          ref.current[name] = e.currentTarget.value;
        }
      };

    return (
      <>
        <S.Article>Symptoms</S.Article>
        <Input
          label={'Major complaint'}
          isRequired
          id="majorComplaint"
          name="majorComplaint"
          defaultValue={formik.values?.symptoms.majorComplaint}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'symptoms.majorComplaint', ref, setIsDirty)
          }
        />
        <S.Line>
          <S.Caption>When did feel worse?</S.Caption>
          <CheckBoxWithLabel
            label="Morning"
            defaultChecked={formik.values?.symptoms.feelWorse.isMorning}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isMorning', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Daytime"
            defaultChecked={formik.values?.symptoms.feelWorse.isDaytime}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isDaytime', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Evening"
            defaultChecked={formik.values?.symptoms.feelWorse.isEvening}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isEvening', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Night"
            defaultChecked={formik.values?.symptoms.feelWorse.isNight}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isNight', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Rain"
            defaultChecked={formik.values?.symptoms.feelWorse.isRain}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isRain', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Cold or warm weather"
            defaultChecked={formik.values?.symptoms.feelWorse.isColdOrWarmWeather}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(
                e,
                'symptoms.feelWorse.isColdOrWarmWeather',
                ref,
                setIsDirty,
              )
            }
          />
          <CheckBoxWithLabel
            label="Up stair"
            defaultChecked={formik.values?.symptoms.feelWorse.isUpStair}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isUpStair', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="Down stair"
            defaultChecked={formik.values?.symptoms.feelWorse.isDownStair}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'symptoms.feelWorse.isDownStair', ref, setIsDirty)
            }
          />
        </S.Line>
        <S.Line>
          <S.Caption>Have you even try:</S.Caption>
          <S.BlockItems>
            <CheckBoxWithLabel
              label="Acupuncture"
              defaultChecked={formik.values?.symptoms.haveYouEvenTry.isAcupuncture}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(
                  e,
                  'symptoms.haveYouEvenTry.isAcupuncture',
                  ref,
                  setIsDirty,
                )
              }
            />
            <S.Text>When</S.Text>
            <S.InputWrapper>
              <DatePicker
                label=""
                defaultValue={dayjs(
                  formik.values?.symptoms.haveYouEvenTry.whenAcupuncture,
                )}
                onChange={value => {
                  handleChangeDatePicker(
                    value,
                    'symptoms.haveYouEvenTry.whenAcupuncture',
                    ref,
                    setIsDirty,
                  );
                }}
              />
            </S.InputWrapper>
          </S.BlockItems>
          <S.BlockItems>
            <CheckBoxWithLabel
              label="Chinese Medicine Herb"
              defaultChecked={
                formik.values?.symptoms.haveYouEvenTry.isChineseMedicineHerb
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(
                  e,
                  'symptoms.haveYouEvenTry.isChineseMedicineHerb',
                  ref,
                  setIsDirty,
                )
              }
            />
            <S.Text>When</S.Text>
            <S.InputWrapper>
              <DatePicker
                label=""
                defaultValue={dayjs(
                  formik.values?.symptoms.haveYouEvenTry.whenChineseMedicineHerb,
                )}
                onChange={value => {
                  handleChangeDatePicker(
                    value,
                    'symptoms.haveYouEvenTry.whenChineseMedicineHerb',
                    ref,
                    setIsDirty,
                  );
                }}
              />
            </S.InputWrapper>
          </S.BlockItems>
        </S.Line>
        <S.Article className="px13">List all your conditions</S.Article>
        <ListAllConditions />
        <S.Article className="px13">Body</S.Article>
        <S.Line>
          <S.Caption>Common information:</S.Caption>
          <CheckBoxWithLabel label="Weak" />
          <CheckBoxWithLabel label="Numbness" />
          <CheckBoxWithLabel label="Drink More" />
          <CheckBoxWithLabel label="Drink Less" />
          <CheckBoxWithLabel label="Prefer Hot Drink" />
          <CheckBoxWithLabel label="Prefer Cold Drink" />
        </S.Line>
        <S.Line>
          <S.InputWrapper>
            <SelectInput label="Appetite">
              <MenuItem>
                <S.MenuItemContent>Normal</S.MenuItemContent>
              </MenuItem>
            </SelectInput>
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Food">
              <MenuItem>
                <S.MenuItemContent>Food</S.MenuItemContent>
              </MenuItem>
            </SelectInput>
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.Caption>Do you have:</S.Caption>
          <CheckBoxWithLabel label="Chest Pain" />
          <CheckBoxWithLabel label="Press" />
          <CheckBoxWithLabel label="Palpitation" />
        </S.Line>
        <S.Article className="px13">Sleep</S.Article>
        <S.Line>
          <S.Caption>How many hours per day?</S.Caption>
          <S.InputWrapper>
            <Input label="Hours" />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Sleep Quality" />
          </S.InputWrapper>
        </S.Line>
        <S.Article className="px13">Urine</S.Article>
        <S.Line>
          <S.Caption>How many times per night?</S.Caption>
          <S.InputWrapper>
            <Input label="Times" />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Urine" />
          </S.InputWrapper>
        </S.Line>
        <S.Article className="px13">Stool</S.Article>
        <S.Line>
          <S.Caption>Once in?</S.Caption>
          <S.InputWrapper>
            <Input label="Days" />
          </S.InputWrapper>
          <CheckBoxWithLabel label="Do you have constipation ?" />
          <CheckBoxWithLabel label="Do you have loose ? " />
        </S.Line>
        <S.Article className="px13">Menstruation</S.Article>
        <S.Line>
          <S.InputWrapper>
            <Input label="Start age" />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input label="Cycle days" />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input label="Last days" />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Amount" />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Color" />
          </S.InputWrapper>
          <S.InputWrapper>
            <SelectInput label="Clots" />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <CheckBoxWithLabel label="Do you have cramp pain?" />
          <CheckBoxWithLabel label="Are you pregnant?" />
          <S.Text>Date of last period:</S.Text>
          <S.InputWrapper>
            <DatePicker label="" />
          </S.InputWrapper>
        </S.Line>
        <S.Article>Personal Life style</S.Article>
        <S.Line>
          <CheckBoxWithLabel label="Do you smoke?" />
          <CheckBoxWithLabel label="Do you drink alcohol ?" />
        </S.Line>
      </>
    );
  },
);

SymptomsBlock.displayName = 'SymptomsBlock';

export default SymptomsBlock;
