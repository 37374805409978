import { TableCell, TableRow } from '@mui/material';
import {
  AccTableInsuranceType,
  BillingAccTableType,
} from '../../../../../../../../types/AccTableTypes';
import { ClinicInsuranceType } from '../../../../../../../../types/enums/AppointmentEnum';
import React from 'react';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const TableRowInOut: React.FC<Props> = ({ data, index }) => {
  const outNameInOut = (str: string) => {
    switch (str) {
      case ClinicInsuranceType.IN_NETWORK:
        return 'In';
      case ClinicInsuranceType.OUT_OF_NETWORK:
        return 'Out';
      default:
        return;
    }
  };

  return (
    <TableRow>
      {index === 0 && (
        <TableCell className="white white-header white-header-top"></TableCell>
      )}
      {data.clinics.map((clinic, index: number) => {
        return (
          <React.Fragment key={clinic.clinicName + index}>
            {clinic.inColumnCount > 0 && (
              <TableCell colSpan={clinic.inColumnCount}>In</TableCell>
            )}
            {clinic.outColumnCount > 0 && (
              <TableCell colSpan={clinic.outColumnCount}>Out</TableCell>
            )}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

export default TableRowInOut;
