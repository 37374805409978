import dayjs from 'dayjs';
import { getTextHistory } from './config';
import * as S from './styles';
import { useGetInsuranceEditHistoryQuery } from '../../../../../../../../store/api/healthrecords/healthrecordApi';
import { useEffect, useState } from 'react';

type Props = {
  props: any;
};

const InsuranceLogs = ({ props }: Props) => {
  const [page, setPage] = useState(1);
  const { data } = useGetInsuranceEditHistoryQuery({
    page: page,
    take: 15,
    userId: props,
  });
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && page !== data?.lastPage) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.ArticleWrap>
          <S.Article>Show Edit History</S.Article>
        </S.ArticleWrap>
        <S.RowContent>
          {data?.rows?.map((item, index, { length }) => {
            const isLast = index === length - 1 && length < data?.count;
            return (
              <div key={item.id} ref={isLast ? setObserverTarget : undefined}>
                <S.RowItem>
                  {getTextHistory(item)}
                  <S.Time>{dayjs(item.createdAt).format('h:mm a MM/DD/YYYY')}</S.Time>
                </S.RowItem>
              </div>
            );
          })}
        </S.RowContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default InsuranceLogs;
