import styled from 'styled-components';

export const ArticleSmallWrapper = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sub {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ButtonMark = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: #e5f0f6;
    border-radius: 10px;
  }

  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  svg {
    width: 22px;
    height: 22px;
  }
`;
