import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dialog } from 'components';
import SelectUserDialog from '../SelectUserDialog';
import { useNavigate } from 'react-router-dom';
import CreateExistingClaimDialog from 'components/organismus/AppointmentsView/components/PlannerView/components/CreateExistingClaimDialog';
import { ClaimTypeEnum } from 'types/InvoiceType';

type Props = {
  selectedType: ClaimTypeEnum | null;
  refetch: () => void;
  setSelectedType: Dispatch<SetStateAction<ClaimTypeEnum | null>>;
};

const ConfigCreateNewClaim = ({ selectedType, setSelectedType, refetch }: Props) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedType) {
      setIsOpenDialog(true);
    }
  }, [selectedType]);

  switch (selectedType) {
    case ClaimTypeEnum.ON_THE_PATIENT_RECORDS:
      return (
        <Dialog
          open={isOpenDialog}
          onClose={() => {
            setSelectedType(null);
            setIsOpenDialog(!isOpenDialog);
          }}
        >
          <SelectUserDialog
            title="Select a user to create a claim"
            onClose={() => {
              setSelectedType(null);
              setIsOpenDialog(!isOpenDialog);
            }}
            onPressSubmit={selectedRow =>
              navigate(`/claim-form?patientId=${selectedRow.id}`)
            }
          />
        </Dialog>
      );
    case ClaimTypeEnum.ON_THE_PREVIOUS_CLAIM:
      return (
        <Dialog
          open={isOpenDialog}
          onClose={() => {
            setSelectedType(null);
            setIsOpenDialog(!isOpenDialog);
          }}
        >
          <CreateExistingClaimDialog
            refetch={refetch}
            onClose={() => {
              setSelectedType(null);
              setIsOpenDialog(!isOpenDialog);
            }}
          />
        </Dialog>
      );

    default:
      return null;
  }
};

export default ConfigCreateNewClaim;
