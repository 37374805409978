import { Fade, Tab, Tooltip } from '@mui/material';
import TabsItem from '../../atoms/Tabs';
import * as S from './styles';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Coupons from './components/Coupons';

import Surveys from './components/Surveys';
import { AlertIconSVG } from '../../../assets/icons';
import NotificationPopover from '../NotificationPopover';
import { setMessage } from '../../../store/slices/message';

const Marketing = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAnchor = (e: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Marketing</S.SubtitleText>
      </S.SubtitleBar>

      <S.Content>
        <S.MenuStaffWrapper>
          <S.SubtitleItems>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="Coupons"></Tab>
              <Tab disableRipple label="Surveys"></Tab>
            </TabsItem>
            <NotificationPopover
              article="Notifications"
              showSeeMore={true}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
            />
            {tabIndex === 1 && (
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#0084B1',
                      fontFamily: 'Inter',
                    },
                  },
                }}
                title="To create a new Survey, you need to have a link to an already created Google Form, which DEFINITELY needs to provide editing access for the account:
              forms-943@dotted-spot-411713.iam.gserviceaccount.com"
              >
                <S.Alert
                  onClick={() => {
                    navigator.clipboard
                      .writeText('forms-943@dotted-spot-411713.iam.gserviceaccount.com')
                      .then(() =>
                        dispatch(setMessage({ message: 'Сopied', type: 'success' })),
                      );
                  }}
                >
                  <AlertIconSVG />
                  <S.AlertText>Click to copy email link</S.AlertText>
                </S.Alert>
              </Tooltip>
            )}
          </S.SubtitleItems>
          {tabIndex === 0 && <Coupons />}
          {tabIndex === 1 && <Surveys />}
        </S.MenuStaffWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Marketing;
