import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import * as S from './styles';
import { TooltipInput } from 'components/atoms/Input';
import { useFormik } from 'formik';
import { FormValues, Schema, headers, SERVICE_ASSIGN } from './config';
import {
  CheckBox,
  CircularLoader,
  CustomTable,
  Dialog,
  TooltipSelectInput,
} from 'components/index';
import { MenuItem, TableCell, TableRow } from '@mui/material';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { hex2hsl, hsl2hex } from 'helpers/colorsConverter';
import Button from 'components/atoms/Button';
import {
  useAssignServiceMutation,
  useCreateServiceMutation,
  useHoldServiceMutation,
  useUpdateServiceMutation,
} from 'store/api/services/servicesApi';
import { setMessage } from 'store/slices/message';
import { ServiceType } from 'types/ServicesType';
import { ColoredServiceBox } from 'components/atoms/ColoredServiceBox';
import { CancelRedSVG, CancelGreySVG } from 'assets/icons';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { visitLength } from 'constants/constants';
import { AutoCompleteFromEnum } from 'components/atoms/AutoComplete';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import { ucFirst } from 'helpers/functions/toUpperCase';

type Props = {
  onClose: () => void;
  currentService?: ServiceType;
  services: ServiceType[];
};

export const AddService = ({ currentService, services, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.auth);
  const clinics = useClinicsGetQuery({});
  const [createService] = useCreateServiceMutation({});
  const [updateService] = useUpdateServiceMutation({});
  const [assignService, assignData] = useAssignServiceMutation({});
  const [holdService, holdData] = useHoldServiceMutation({});
  const [showConfirmDialog, setShowConfirmDialog] = useState<{
    clinicId: string;
    isHold: boolean;
  } | null>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      serviceName: '',
      clinicIds: [],
      color: 0,
      bg: 0.5,
      firstVisitColor: 0,
      firstVisitBg: 0.5,
      isUseForFirstTimeVisit: false,
      defaultServiceTime: 0,
      serviceCategory: '',
      isMassageType: false,
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async values => {
      currentService
        ? updateService({
            id: currentService.id,
            serviceName: values.serviceName,
            color: hsl2hex(+(values.color ?? 0), +(values.bg ?? 0)) ?? '#ff0000',
            firstVisitColor: values.isUseForFirstTimeVisit
              ? (hsl2hex(+(values.firstVisitColor ?? 0), +(values.firstVisitBg ?? 0)) ??
                '#ff0000')
              : undefined,
            isUseForFirstTimeVisit: values.isUseForFirstTimeVisit,
            clinicIds: values.clinicIds,
            defaultServiceTime: values.defaultServiceTime,
            serviceCategory: values.serviceCategory,
            isMassageType:
              values.serviceCategory === SERVICE_ASSIGN.SPECIALIST
                ? values.isMassageType
                : null,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Service was successfully updated',
                  type: 'success',
                }),
              );
            })
            .catch(err => {
              dispatch(
                setMessage({
                  message: err.data.message,
                  type: 'error',
                }),
              );
            })
        : createService({
            serviceName: values.serviceName,
            color: hsl2hex(+(values.color ?? 0), +(values.bg ?? 0)) ?? '#ff0000',
            firstVisitColor: values.isUseForFirstTimeVisit
              ? (hsl2hex(+(values.firstVisitColor ?? 0), +(values.firstVisitBg ?? 0)) ??
                '#ff0000')
              : undefined,
            isUseForFirstTimeVisit: values.isUseForFirstTimeVisit,
            clinicIds: values.clinicIds,
            defaultServiceTime: values.defaultServiceTime,
            serviceCategory: values.serviceCategory,
            isMassageType:
              values.serviceCategory === SERVICE_ASSIGN.SPECIALIST
                ? values.isMassageType
                : null,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Service was successfully created',
                  type: 'success',
                }),
              );
            })
            .catch(err => {
              dispatch(
                setMessage({
                  message: err.data.message,
                  type: 'error',
                }),
              );
            });
    },
  });

  const onChangeChecked = (clinicId: string) => {
    if (currentService) {
      assignService({
        clinicId,
        typeServiceId: currentService.id,
        isAssign: !currentService?.clinics?.some(i => i.id === clinicId),
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Service was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(
            setMessage({
              message: err.data.message,
              type: 'error',
            }),
          );
        });
    } else
      formik.setFieldValue(
        'clinicIds',
        formik.values.clinicIds?.includes(clinicId)
          ? formik.values.clinicIds?.filter(i => i !== clinicId)
          : [...formik.values.clinicIds, clinicId],
      );
  };

  const onChangeHold = (clinicId: string, isHold: boolean) => {
    if (currentService) {
      holdService({
        clinicId,
        typeServiceId: currentService.id,
        isHold,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Service was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(
            setMessage({
              message: err.data.message,
              type: 'error',
            }),
          );
        });
    }
  };

  const colors = useMemo(() => {
    return services
      ?.map(service => service.color)
      ?.concat(hsl2hex(formik.values?.color, formik.values?.bg));
  }, [services, formik.values?.color, formik.values?.bg]);

  const firstVisitColors = useMemo(() => {
    return services
      ?.filter(service => service.isUseForFirstTimeVisit)
      ?.map(service => service.firstVisitColor)
      ?.concat(hsl2hex(formik.values?.firstVisitColor, formik.values?.firstVisitBg ?? 0));
  }, [services, formik.values?.firstVisitColor, formik.values?.firstVisitBg]);

  useEffect(() => {
    if (currentService) {
      formik.setValues({
        ...formik.values,
        serviceName: currentService.serviceName,
        clinicIds: currentService?.clinics?.length
          ? currentService?.clinics?.map(i => i.id)
          : [],
        color: hex2hsl(currentService?.color)?.h ?? 0,
        bg: hex2hsl(currentService?.color)?.l ?? 0.5,
        firstVisitColor: hex2hsl(currentService?.firstVisitColor)?.h ?? 0,
        firstVisitBg: hex2hsl(currentService?.firstVisitColor)?.l ?? 0.5,
        isUseForFirstTimeVisit: currentService.isUseForFirstTimeVisit,
        defaultServiceTime: currentService.defaultServiceTime,
        serviceCategory: currentService?.serviceCategory ?? '',
        isMassageType: currentService?.isMassageType ?? false,
      });
    }
  }, [currentService]);

  const availableClinics = useMemo(() => {
    return userInfo.role === Roles.LOCATION_MANAGER
      ? userInfo.clinics
      : clinics.currentData?.rows;
  }, [clinics.currentData?.rows, userInfo.clinics, userInfo.role]);

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      {(assignData?.isLoading || holdData?.isLoading) && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={20} />
        </S.Loader>
      )}
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>{currentService ? 'Edit' : 'Add'} Service</S.Article>
          </S.ArticleWrap>
          <S.Information>
            <S.InputRow>
              <TooltipInput
                label="Service name"
                isRequired
                {...formik.getFieldProps('serviceName')}
                error={!!formik.errors.serviceName}
                tooltipHelperText={formik.errors.serviceName}
              />
            </S.InputRow>

            {formik.values.serviceCategory === SERVICE_ASSIGN.SPECIALIST && (
              <S.CheckBoxWrapper>
                <S.CheckBoxTitle className={'ml'}>
                  <CheckBox
                    checked={formik.values.isMassageType}
                    onChange={e =>
                      formik.setFieldValue('isMassageType', e.target.checked)
                    }
                  />
                  <S.CheckBoxLabel>Is it massage type?</S.CheckBoxLabel>
                </S.CheckBoxTitle>
              </S.CheckBoxWrapper>
            )}

            <S.InputRow className={'withoutMargin'}>
              <TooltipSelectInput
                label="Assign to"
                isRequired
                value={formik.values.serviceCategory}
                onChange={e => {
                  formik.setFieldValue('serviceCategory', e.target.value);
                  formik.setFieldValue('isMassageType', false);
                }}
                error={!!formik.errors.serviceCategory}
                tooltipHelperText={formik.errors.serviceCategory}
              >
                <MenuItem value={SERVICE_ASSIGN.DOCTOR}>
                  <S.MenuItemContent>{ucFirst(SERVICE_ASSIGN.DOCTOR)}</S.MenuItemContent>
                </MenuItem>
                <MenuItem value={SERVICE_ASSIGN.SPECIALIST}>
                  <S.MenuItemContent>
                    {ucFirst(SERVICE_ASSIGN.SPECIALIST)}
                  </S.MenuItemContent>
                </MenuItem>
              </TooltipSelectInput>
            </S.InputRow>

            <CustomTable headers={headers} isFetching={false}>
              {availableClinics?.length &&
                availableClinics?.map((clinic, index) => {
                  const isHoldService = currentService?.clinics?.some(
                    i => i.id === clinic.id && i.ServiceClinic?.isHold,
                  );

                  return (
                    <TableRow
                      key={clinic.id}
                      className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
                    >
                      <TableCell>
                        <S.CheckBoxWrapper>
                          <S.CheckBoxTitle>
                            <CheckBox
                              checked={formik.values.clinicIds?.includes(clinic.id)}
                              onChange={() => onChangeChecked(clinic.id)}
                            />
                            <S.CheckBoxLabel>{clinic?.name}</S.CheckBoxLabel>
                          </S.CheckBoxTitle>

                          {formik.values.clinicIds?.includes(clinic.id) ? (
                            isHoldService ? (
                              <CancelRedSVG
                                onClick={() => onChangeHold(clinic.id, false)}
                              />
                            ) : (
                              <CancelGreySVG
                                onClick={() =>
                                  setShowConfirmDialog({
                                    clinicId: clinic.id,
                                    isHold: true,
                                  })
                                }
                              />
                            )
                          ) : null}
                        </S.CheckBoxWrapper>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </CustomTable>

            <S.CheckBoxTitle className={'mt'}>
              <CheckBox
                checked={formik.values.isUseForFirstTimeVisit}
                onChange={e =>
                  formik.setFieldValue('isUseForFirstTimeVisit', e.target.checked)
                }
                className={'big'}
              />
              <S.CheckBoxLabel className={'xl'}>
                Use different color for First time visit
              </S.CheckBoxLabel>
            </S.CheckBoxTitle>

            <S.CheckBoxLabel className={'xl'}>Choose color</S.CheckBoxLabel>
            <S.Services>
              {colors?.map((color, i) => (
                <React.Fragment key={i}>
                  <ColoredServiceBox color={color} disabled={i !== colors?.length - 1} />
                </React.Fragment>
              ))}
            </S.Services>
            <S.StyledSlider
              {...formik.getFieldProps('color')}
              sx={{
                margin: '18px auto 5px',
                width: '460px',
                height: '.75rem',
                borderRadius: '0.3rem',
                padding: '0',
                '& .MuiSlider-rail': {
                  height: 0,
                },
                '& .MuiSlider-track': {
                  height: 0,
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  color: `hsl(${formik.values.color}, 100%, 50%)`,
                  border: '3px solid white',
                  width: '1.875rem',
                  height: '1.8125rem',
                },
              }}
              min={0}
              max={360}
            />
            <S.AlphaStyledSlider
              {...formik.getFieldProps('bg')}
              sx={{
                margin: '5px auto 18px',
                width: '460px',
                height: '.75rem',
                borderRadius: '0.3rem',
                padding: '0',
                '& .MuiSlider-rail': {
                  height: 0,
                },
                '& .MuiSlider-track': {
                  height: 0,
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  color: `hsl(0, 0%, ${formik.values.bg * 100}%)`,
                  border: '3px solid white',
                  width: '1.875rem',
                  height: '1.8125rem',
                },
              }}
              min={0}
              max={1}
              step={0.01}
            />

            {formik.values.isUseForFirstTimeVisit && (
              <>
                <S.CheckBoxLabel className={'xl'}>
                  Choose color for first visit
                </S.CheckBoxLabel>
                <S.Services>
                  {firstVisitColors?.map((color, i) => (
                    <React.Fragment key={i}>
                      <ColoredServiceBox
                        color={color}
                        disabled={i !== firstVisitColors?.length - 1}
                      />
                    </React.Fragment>
                  ))}
                </S.Services>
                <S.StyledSlider
                  {...formik.getFieldProps('firstVisitColor')}
                  sx={{
                    margin: '18px auto 5px',
                    width: '460px',
                    height: '.75rem',
                    borderRadius: '0.3rem',
                    padding: '0',
                    '& .MuiSlider-rail': {
                      height: 0,
                    },
                    '& .MuiSlider-track': {
                      height: 0,
                      border: 'none',
                    },
                    '& .MuiSlider-thumb': {
                      color: `hsl(${formik.values.firstVisitColor}, 100%, 50%)`,
                      border: '3px solid white',
                      width: '1.875rem',
                      height: '1.8125rem',
                    },
                  }}
                  min={0}
                  max={360}
                />
                <S.AlphaStyledSlider
                  {...formik.getFieldProps('firstVisitBg')}
                  sx={{
                    margin: '5px auto 40px',
                    width: '460px',
                    height: '.75rem',
                    borderRadius: '0.3rem',
                    padding: '0',
                    '& .MuiSlider-rail': {
                      height: 0,
                    },
                    '& .MuiSlider-track': {
                      height: 0,
                      border: 'none',
                    },
                    '& .MuiSlider-thumb': {
                      color: `hsl(0, 0%, ${(formik.values.firstVisitBg ?? 0) * 100}%)`,
                      border: '3px solid white',
                      width: '1.875rem',
                      height: '1.8125rem',
                    },
                  }}
                  min={0}
                  max={1}
                  step={0.01}
                />
              </>
            )}
            <AutoCompleteFromEnum
              mainLabel={'Choose default length time'}
              data={
                currentService?.defaultServiceTime
                  ? [
                      currentService.defaultServiceTime,
                      ...visitLength.filter(
                        i => i !== currentService?.defaultServiceTime,
                      ),
                    ]
                  : visitLength
              }
              value={formik.values?.defaultServiceTime ?? 0}
              onChange={val => formik.setFieldValue(`defaultServiceTime`, val)}
              suffix={'min'}
            />
            <S.Empty height={'10px'} />
          </S.Information>
        </S.Content>

        <S.Footer>
          <S.BtnWrapper>
            <Button text={'Save'} type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>

      <Dialog open={!!showConfirmDialog} onClose={() => setShowConfirmDialog(null)}>
        <ConfirmDeleteDialog
          onClose={() => setShowConfirmDialog(null)}
          fullTitle={`Do you really want to make '${formik.values.serviceName}' Service 
            in '${clinics.currentData?.rows?.find(i => i.id === showConfirmDialog?.clinicId)?.name ?? 'this'}' Clinic inactive?`}
          helperText={`During this time in '${clinics.currentData?.rows?.find(i => i.id === showConfirmDialog?.clinicId)?.name ?? 'the selected'}' Clinic 
          it will not be possible to create new Appointments containing '${formik.values.serviceName}' Service. 
          You can reactivate '${formik.values.serviceName}' Service in '${clinics.currentData?.rows?.find(i => i.id === showConfirmDialog?.clinicId)?.name ?? 'the selected'}' Clinic 
          at any time. `}
          onSuccess={() =>
            !!showConfirmDialog &&
            onChangeHold(showConfirmDialog?.clinicId, showConfirmDialog?.isHold)
          }
          cancelText={'Cancel'}
          confirmText={'Deactivate'}
        />
      </Dialog>
    </form>
  );
};
