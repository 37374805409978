import dayjs, { Dayjs } from 'dayjs';
import * as S from '../styles';
import { DotsSVG, EditBtnMinimalSVG, FileDocumentSVG } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { ChangeEvent, useState } from 'react';
import { ClickAwayListener, IconButton, InputAdornment } from '@mui/material';
import InputCell from 'components/atoms/InputCell';
import { usePostPaymentInvoiceItemUpdateMutation } from 'store/api/invoices/invoiceApi';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, Dialog, HoldCard } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { getFileName } from 'helpers/functions/fileName';
import { setMessage } from 'store/slices/message';
import { AccessLevel } from 'types/enums/AppointmentEnum';
import NotificationPopover from 'components/organismus/NotificationPopover';
import Button from 'components/atoms/Button';

type Props = {
  row: any;
  header: string;
};

const InvInfPostData: React.FC<Props> = ({ row, header }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const me = useAppSelector(state => state.auth);
  const { invoicePostPaymentAccessLevel } = useAppSelector(state => state.auth);
  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);
  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [editDate, setEditDate] = useState(false);
  const [date, setDate] = useState<Dayjs | string>(dayjs());

  const [editIssueDate, setEditIssueDate] = useState(false);
  const [issueDate, setIssueDate] = useState<Dayjs | string>(dayjs());

  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState('');

  const [editCheckNumber, setEditCheckNumber] = useState(false);
  const [checkNumber, setCheckNumber] = useState('');

  const [editAmount, setEditAmount] = useState(false);
  const [amount, setAmount] = useState(0);

  const [changeItem] = usePostPaymentInvoiceItemUpdateMutation({});

  const handleSave = ({ ...params }) => {
    changeItem({
      ...params,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Invoice item was updated successfully',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const onEobClick = (eob: { name: string; url: string }) => {
    const link = document.createElement('a');
    link.href = eob?.url;
    link.setAttribute('download', eob?.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  switch (header) {
    case 'ID': {
      return row?.id || '-';
    }
    case 'PatientName': {
      return (
        <S.Link onClick={() => navigate('/patients/' + row.claim?.patient?.id)}>
          {row.claim?.patient?.profile?.firstName
            ? row.claim?.patient?.profile?.firstName +
              ' ' +
              row.claim?.patient?.profile?.lastName
            : '-'}
          {row.claim?.patient?.isHoldBilling && (
            <HoldCard text="HB" reason={row.claim.patient.isHoldBilling} />
          )}
          {row.claim?.patient?.isHoldAppointment && (
            <HoldCard text="HA" reason={row.claim.patient.isHoldAppointment} />
          )}
        </S.Link>
      );
    }
    case 'Account': {
      return <>{row.accountNumber ? row.accountNumber : '-'}</>;
    }
    case 'Claim': {
      return (
        <S.Link onClick={() => !!row.claimId && navigate(`/claim-form/${row.claimId}`)}>
          {row.claimId ? row.claimId : '-'}
        </S.Link>
      );
    }
    case 'SubmittedOn': {
      return (
        <S.CellWrapper
          className="min"
          editable={invoicePostPaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePostPaymentAccessLevel === AccessLevel.WRITE) {
              setDate(dayjs(row.submittedOnDate) || '');
              setEditDate(true);
            }
          }}
        >
          {editDate ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditDate(false);
                editDate &&
                  dayjs(date).format('MM/DD/YYYY') !==
                    dayjs(row.submittedOnDate).format('MM/DD/YYYY') &&
                  handleSave({
                    id: row.id,
                    submittedOnDate: dayjs(date).format('YYYY-MM-DD'),
                    checkedById: row?.checkedById ? me?.id : undefined,
                    checkDate: row?.checkedById
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <DatePicker
                  background="none"
                  isCell
                  label=""
                  value={date}
                  onChange={value => setDate(dayjs(value).format('MM/DD/YYYY'))}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>
              {row.submittedOnDate
                ? dayjs(row.submittedOnDate).format('MM/DD/YYYY')
                : '-'}
            </>
          )}
        </S.CellWrapper>
      );
    }
    case 'Paid': {
      return (
        <S.CellWrapper
          editable={invoicePostPaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePostPaymentAccessLevel === AccessLevel.WRITE) {
              setAmount(row.paid || 0);
              setEditAmount(true);
            }
          }}
        >
          {editAmount ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditAmount(false);
                editAmount &&
                  amount !== row.paid &&
                  !(amount === 0 && row.paid === null) &&
                  handleSave({
                    id: row.id,
                    paid: amount,
                    checkedById: row?.checkedById ? me?.id : undefined,
                    checkDate: row?.checkedById
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disableRipple={true}
                          aria-label="toggle password visibility"
                        >
                          USD
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={amount}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAmount(+e.target.value)
                  }
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{(row.paid ?? 0) + ' USD'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Check': {
      return (
        <S.CellWrapper
          editable={invoicePostPaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePostPaymentAccessLevel === AccessLevel.WRITE) {
              setCheckNumber(row?.checkNumber || '');
              setEditCheckNumber(true);
            }
          }}
        >
          {editCheckNumber ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditCheckNumber(false);
                editCheckNumber &&
                  checkNumber !== row?.checkNumber &&
                  !(checkNumber === '' && row?.checkNumber === null) &&
                  handleSave({
                    id: row.id,
                    checkNumber,
                    checkedById: row?.checkedById ? me?.id : undefined,
                    checkDate: row?.checkedById
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  value={checkNumber}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCheckNumber(e.target.value)
                  }
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row?.checkNumber ?? '-'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'IssueDate': {
      return (
        <S.CellWrapper
          className="min"
          editable={invoicePostPaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePostPaymentAccessLevel === AccessLevel.WRITE) {
              setIssueDate(dayjs(row.issueDate) || '');
              setEditIssueDate(true);
            }
          }}
        >
          {editIssueDate ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditIssueDate(false);
                editIssueDate &&
                  dayjs(issueDate).format('MM/DD/YYYY') !==
                    dayjs(row.issueDate).format('MM/DD/YYYY') &&
                  handleSave({
                    id: row.id,
                    issueDate: dayjs(issueDate).format('YYYY-MM-DD'),
                    checkedById: row?.checkedById ? me?.id : undefined,
                    checkDate: row?.checkedById
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <DatePicker
                  background="none"
                  isCell
                  label=""
                  value={date}
                  onChange={value => setIssueDate(dayjs(value).format('MM/DD/YYYY'))}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row?.issueDate ? dayjs(row.issueDate).format('MM/DD/YYYY') : '-'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'PaymentNotes': {
      return (
        <S.CellWrapper
          editable={invoicePostPaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePostPaymentAccessLevel === AccessLevel.WRITE) {
              setNote(row?.notes?.at(0) || '');
              setEditNote(true);
            }
          }}
        >
          {editNote ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditNote(false);
                editNote &&
                  note !== row?.notes?.at(0) &&
                  !(note === '' && row?.notes?.at(0) === null) &&
                  handleSave({
                    id: row.id,
                    notes: [note],
                    checkedById: row?.checkedById ? me?.id : undefined,
                    checkDate: row?.checkedById
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  multiline
                  rows={3}
                  value={note}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row.notes?.length ? row?.notes?.at(0) : '-'}</>
          )}
        </S.CellWrapper>
      );
    }

    case 'EOB': {
      return row.eob ? (
        <S.LinkWrapper onClick={() => onEobClick(row?.eob)}>
          <FileDocumentSVG />

          {getFileName(row.eob?.name)}
        </S.LinkWrapper>
      ) : (
        '-'
      );
    }

    case 'CheckedBy': {
      return (
        <>
          {row?.checkedById ? (
            <S.CellWrapper editable={AccessLevel.READ}>
              {row.checkedBy?.profile
                ? row.checkedBy?.profile?.firstName +
                  ' ' +
                  row.checkedBy?.profile?.lastName
                : '-'}
            </S.CellWrapper>
          ) : (
            <S.DotsWrapper>
              <S.CellWrapper>-</S.CellWrapper>
              {invoicePostPaymentAccessLevel === AccessLevel.WRITE && (
                <S.Dots onClick={handleAnchorReport}>
                  <DotsSVG />
                </S.Dots>
              )}
              <NotificationPopover
                open={Boolean(anchorElReport)}
                anchorEl={anchorElReport}
                onClose={() => setAnchorElReport(null)}
              >
                <S.ContentWrapper>
                  <S.ContentRow onClick={() => setOpenConfirmDialog(true)}>
                    <EditBtnMinimalSVG />
                    Mark as Checked
                  </S.ContentRow>
                </S.ContentWrapper>
              </NotificationPopover>
            </S.DotsWrapper>
          )}
          <Dialog
            open={openConfirmDialog}
            onClose={() => {
              setAnchorElReport(null);
              setOpenConfirmDialog(false);
            }}
          >
            <S.Content>
              <S.Article>Mark as checked</S.Article>
              <S.HelperText>
                Do you really want to mark this item as checked?
              </S.HelperText>

              <S.ButtonWrapper>
                <Button
                  text="Submit"
                  onClick={() => {
                    handleSave({
                      id: row.id,
                      checkedById: me?.id,
                      checkDate: dayjs().format('YYYY-MM-DD'),
                      invoiceId: Number(params.id),
                    });
                    setAnchorElReport(null);
                    setOpenConfirmDialog(false);
                  }}
                />
              </S.ButtonWrapper>
            </S.Content>
          </Dialog>
        </>
      );
    }

    case 'CheckedDate': {
      return row?.checkDate ? dayjs(row.checkDate).format('MM/DD/YYYY') : '-';
    }

    default:
      return row[header] || '-';
  }
};

export default InvInfPostData;
