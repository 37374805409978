import { MenuItem, SelectChangeEvent } from '@mui/material';
import { ArrowLeftSVG, ArrowRightSVG, ChoosedDateCalendarSVG } from 'assets/icons';
import { handleBackDate, handleForwardDate } from 'helpers/CalendarHelpers/handleDates';
import { ModeEnum } from 'types/enums/AppointmentEnum';
import * as S from './styles';
import SelectInput from 'components/atoms/SelectInput';
import { DateObject } from 'react-multi-date-picker';
import { withoutYearModeTypes } from 'constants/constants';
import Agenda from 'components/molecules/CalendarEvent/Agenda';

type Props = {
  isFetching: boolean;
  mode: ModeEnum;
  setMode: React.Dispatch<React.SetStateAction<ModeEnum>>;
  selectedDate: DateObject[];
  setSelectedDate: React.Dispatch<React.SetStateAction<DateObject[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isModePending?: boolean;
};

const AppointmentsToolbar: React.FC<Props> = ({
  isFetching,
  mode,
  selectedDate,
  isModePending,
  setMode,
  setSelectedDate,
  setPage,
}) => {
  return (
    <S.HeaderInformation>
      <S.ChoosedDateWrapper $isModePending={isModePending}>
        <ArrowLeftSVG
          className={isFetching ? 'btn disabled' : 'btn'}
          onClick={() => handleBackDate(mode, setSelectedDate, selectedDate, setPage)}
        />
        <ChoosedDateCalendarSVG />
        <S.ChoosedDateText>
          {mode === ModeEnum.DAY
            ? selectedDate[0]?.format('MMM DD, YYYY')
            : selectedDate[0]?.format('MMM DD, YYYY') +
              selectedDate[1]?.format(' - MMM DD, YYYY')}
        </S.ChoosedDateText>
        <ArrowRightSVG
          className={isFetching ? 'btn disabled' : 'btn'}
          onClick={() => handleForwardDate(mode, setSelectedDate, selectedDate, setPage)}
        />
      </S.ChoosedDateWrapper>
      <Agenda />
      <S.ModeButtons $isModePending={isModePending}>
        <S.SelectWrapper>
          <SelectInput
            label=""
            value={mode}
            onChange={(e: SelectChangeEvent<unknown>) =>
              setMode(e.target.value as ModeEnum)
            }
          >
            {withoutYearModeTypes.map(mode => (
              <MenuItem value={mode} key={mode}>
                <S.MenuItemRow>{mode}</S.MenuItemRow>
              </MenuItem>
            ))}
          </SelectInput>
        </S.SelectWrapper>
      </S.ModeButtons>
    </S.HeaderInformation>
  );
};

export default AppointmentsToolbar;
