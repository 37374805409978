import dayjs from 'dayjs';
import { AppointmentProcessInterface, Step } from '../../../../types/AppointmentNewTypes';
import { DigitalTemplateType } from '../../../../types/TemplateType';

export const clientRegFormik = (
  name?: string,
  data?: AppointmentProcessInterface,
  currentStep?: Step,
) => {
  return {
    mdType: DigitalTemplateType.CLIENT_REGISTRATION,
    isHaveTherapy: currentStep?.digitalForm?.payload?.isHaveTherapy ?? false,
    patientName: currentStep?.digitalForm?.payload?.patientName ?? '',
    billingAddress: currentStep?.digitalForm?.payload?.billingAddress ?? '',
    physicalAddress: currentStep?.digitalForm?.payload?.physicalAddress ?? '',
    driversLic: currentStep?.digitalForm?.payload?.driversLic ?? '',
    birthDate: currentStep?.digitalForm?.payload?.birthDate ?? '',
    gender: currentStep?.digitalForm?.payload?.gender ?? '',
    age: currentStep?.digitalForm?.payload?.age ?? 0,
    mi: currentStep?.digitalForm?.payload?.mi ?? '',
    city: currentStep?.digitalForm?.payload?.city ?? '',
    state: currentStep?.digitalForm?.payload?.state ?? '',
    zipCode: currentStep?.digitalForm?.payload?.zipCode ?? '',
    last4DigitsOfSSN: currentStep?.digitalForm?.payload?.last4DigitsOfSSN ?? '',
    contacts: {
      cellPhone: currentStep?.digitalForm?.payload?.contacts?.cellPhone ?? '',
      email: currentStep?.digitalForm?.payload?.contacts?.email ?? '',
      fax: currentStep?.digitalForm?.payload?.contacts?.fax ?? '',
      workPhone: currentStep?.digitalForm?.payload?.contacts?.workPhone ?? '',
      homePhone: currentStep?.digitalForm?.payload?.contacts?.homePhone ?? '',
      receiveStatements: {
        isEmail:
          currentStep?.digitalForm?.payload?.contacts?.receiveStatements?.isEmail ??
          false,
        isMail:
          currentStep?.digitalForm?.payload?.contacts?.receiveStatements?.isMail ?? false,
        isFax:
          currentStep?.digitalForm?.payload?.contacts?.receiveStatements?.isFax ?? false,
      },
    },
    occupation: {
      employer: currentStep?.digitalForm?.payload?.occupation?.employer ?? '',
      occupation: currentStep?.digitalForm?.payload?.occupation?.occupation ?? '',
      howHearAboutUs: {
        isDoctor:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isDoctor ??
          false,
        isFriend:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isFriend ??
          false,
        isPatient:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isPatient ??
          false,
        isFaceBook:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isFaceBook ??
          false,
        isInternet:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isInternet ??
          false,
        isInstagram:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isInstagram ??
          false,
        isAd:
          currentStep?.digitalForm?.payload?.occupation?.howHearAboutUs?.isAd ?? false,
      },
    },
    otherContacts: {
      referringPhysician:
        currentStep?.digitalForm?.payload?.otherContacts?.referringPhysician ?? '',
      phone: currentStep?.digitalForm?.payload?.otherContacts?.phone ?? '',
      maritalStatus: {
        isSingle:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isSingle ??
          false,
        isMarried:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isMarried ??
          false,
        isDivorced:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isDivorced ??
          false,
        isWidowed:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isWidowed ??
          false,
        isSeparated:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isSeparated ??
          false,
        isDomestic:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isDomestic ??
          false,
        isParner:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isParner ??
          false,
        isMinorClild:
          currentStep?.digitalForm?.payload?.otherContacts?.maritalStatus?.isMinorClild ??
          false,
      },
      nameOfSpouse: currentStep?.digitalForm?.payload?.otherContacts?.nameOfSpouse ?? '',
      physicalAddress:
        currentStep?.digitalForm?.payload?.otherContacts?.physicalAddress ?? '',
      spouseEmployer:
        currentStep?.digitalForm?.payload?.otherContacts?.spouseEmployer ?? '',
      workPhone: currentStep?.digitalForm?.payload?.otherContacts?.workPhone ?? '',
      cellPhone: currentStep?.digitalForm?.payload?.otherContacts?.cellPhone ?? '',
      occupation: currentStep?.digitalForm?.payload?.otherContacts?.occupation ?? '',
      birthDate: currentStep?.digitalForm?.payload?.otherContacts?.birthDate ?? '',
      age: currentStep?.digitalForm?.payload?.otherContacts?.age ?? 0,
      city: currentStep?.digitalForm?.payload?.otherContacts?.city ?? '',
      state: currentStep?.digitalForm?.payload?.otherContacts?.state ?? '',
      zipCode: currentStep?.digitalForm?.payload?.otherContacts?.zipCode ?? '',
    },
    relativeContacts: {
      emergencyContact:
        currentStep?.digitalForm?.payload?.relativeContacts?.emergencyContact ?? '',
      relationship:
        currentStep?.digitalForm?.payload?.relativeContacts?.relationship ?? '',
      phone: currentStep?.digitalForm?.payload?.relativeContacts?.phone ?? '',
      address: currentStep?.digitalForm?.payload?.relativeContacts?.address ?? '',
      city: currentStep?.digitalForm?.payload?.relativeContacts?.city ?? '',
      state: currentStep?.digitalForm?.payload?.relativeContacts?.state ?? '',
      zipCode: currentStep?.digitalForm?.payload?.relativeContacts?.zipCode ?? '',
    },
    insurance: {
      primaryInsurance:
        currentStep?.digitalForm?.payload?.insurance?.primaryInsurance ?? '',
      primaryInsuredName:
        currentStep?.digitalForm?.payload?.insurance?.primaryInsuredName ?? '',
      primaryPolicy: currentStep?.digitalForm?.payload?.insurance?.primaryPolicy ?? '',
      primarySocialSec:
        currentStep?.digitalForm?.payload?.insurance?.primarySocialSec ?? '',
      primaryBirthDate:
        currentStep?.digitalForm?.payload?.insurance?.primaryBirthDate ?? '',
      secondaryInsurance:
        currentStep?.digitalForm?.payload?.insurance?.secondaryInsurance ?? '',
      secondaryInsuredName:
        currentStep?.digitalForm?.payload?.insurance?.secondaryInsuredName ?? '',
      secondaryPolicy:
        currentStep?.digitalForm?.payload?.insurance?.secondaryPolicy ?? '',
      secondarySocialSec:
        currentStep?.digitalForm?.payload?.insurance?.secondarySocialSec ?? '',
      secondaryBirthDate:
        currentStep?.digitalForm?.payload?.insurance?.secondaryBirthDate ?? '',
      insurance: {
        isMedicaid:
          currentStep?.digitalForm?.payload?.insurance?.insurance?.isMedicaid ?? false,
        isWorkersComp:
          currentStep?.digitalForm?.payload?.insurance?.insurance?.isWorkersComp ?? false,
        isMVI: currentStep?.digitalForm?.payload?.insurance?.insurance?.isMVI ?? false,
        isHMSA: currentStep?.digitalForm?.payload?.insurance?.insurance?.isHMSA ?? false,
        isQuest:
          currentStep?.digitalForm?.payload?.insurance?.insurance?.isQuest ?? false,
        isOtherPrivateInsurance:
          currentStep?.digitalForm?.payload?.insurance?.insurance
            ?.isOtherPrivateInsurance ?? false,
      },
    },
  };
};
