import React from 'react';
import { FormikProps, getIn } from 'formik';
import { NewInsuranceGroupsType } from './config';
import { TooltipInput, TooltipSelectInput } from 'components';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import * as S from './styles';
import { DeleteRedBtnSVG } from 'assets/icons';
import { ClinicInsuranceType } from 'types/enums/AppointmentEnum';
import CheckBoxWithLabel from 'components/atoms/CheckboxWithLabel';
import { InsuranceFormValues } from 'pages/Users/components/Profile/components/InsuranceInformation_v2/components/AddInsuranceDialog/AddInsuranceDialog';
import { useGetAllServicesQuery } from 'store/api/services/servicesApi';

type Props = {
  formik: FormikProps<NewInsuranceGroupsType> | FormikProps<InsuranceFormValues>;
  groupIndex: number;
  serviceIndex: number;
  fromInsurancePage: boolean;
  setIsDirtyForm: (isDirtyForm: boolean) => void;
};

export const CoveredServiceCard = ({
  formik,
  serviceIndex,
  groupIndex,
  fromInsurancePage,
  setIsDirtyForm,
}: Props) => {
  const listResult = useGetAllServicesQuery({
    take: 100,
    asc: 'DESC',
    page: 1,
  });

  const onDeleteCardClick = () => {
    setIsDirtyForm(true);
    formik.setFieldValue(
      `groups[${groupIndex}].coveredServices`,
      formik.values?.groups
        ?.at(groupIndex)
        ?.coveredServices?.filter((item, index) => index !== serviceIndex),
    );
  };

  return (
    <S.CoveredCard>
      <S.DeleteBtn>
        <DeleteRedBtnSVG
          style={{ width: '19px', height: '19px' }}
          onClick={onDeleteCardClick}
        />
      </S.DeleteBtn>

      <S.Line>
        <S.InputWrapper className="percent50">
          <TooltipSelectInput
            label="Service"
            error={
              !!getIn(
                formik.errors,
                `groups[${groupIndex}].coveredServices[${serviceIndex}].typeServiceId`,
              )
            }
            tooltipHelperText={getIn(
              formik.errors,
              `groups[${groupIndex}].coveredServices[${serviceIndex}].typeServiceId`,
            )}
            value={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.typeServiceId
            }
            onChange={(e: SelectChangeEvent<unknown>) => {
              setIsDirtyForm(true);
              formik.setFieldValue(
                `groups[${groupIndex}].coveredServices[${serviceIndex}].typeServiceId`,
                e.target.value as string,
              );
              formik.setFieldValue(
                `groups[${groupIndex}].coveredServices[${serviceIndex}].type`,
                '',
              );
            }}
          >
            {listResult?.currentData?.rows?.map(reason => (
              <MenuItem key={reason.id} value={reason.id}>
                <S.MenuItemContent>{reason.serviceName}</S.MenuItemContent>
              </MenuItem>
            ))}
          </TooltipSelectInput>
        </S.InputWrapper>

        <S.InputWrapper className="percent50">
          <TooltipSelectInput
            label="Type"
            error={
              !!getIn(
                formik.errors,
                `groups[${groupIndex}].coveredServices[${serviceIndex}].type`,
              )
            }
            tooltipHelperText={getIn(
              formik.errors,
              `groups[${groupIndex}].coveredServices[${serviceIndex}].type`,
            )}
            value={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.type
            }
            onChange={(e: SelectChangeEvent<unknown>) => {
              setIsDirtyForm(true);
              formik.setFieldValue(
                `groups[${groupIndex}].coveredServices[${serviceIndex}].type`,
                e.target.value,
              );
            }}
            disabled={
              !formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.typeServiceId
            }
          >
            <MenuItem
              value={ClinicInsuranceType.OUT_OF_NETWORK}
              disabled={formik.values?.groups[groupIndex]?.coveredServices?.some(
                service =>
                  service.typeServiceId ===
                    formik.values?.groups
                      ?.at(groupIndex)
                      ?.coveredServices?.at(serviceIndex)?.typeServiceId &&
                  service.type === ClinicInsuranceType.OUT_OF_NETWORK,
              )}
            >
              <S.MenuItemContent>Out of network</S.MenuItemContent>
            </MenuItem>
            <MenuItem
              value={ClinicInsuranceType.IN_NETWORK}
              disabled={formik.values?.groups
                ?.at(groupIndex)
                ?.coveredServices?.some(
                  service =>
                    service.typeServiceId ===
                      formik.values?.groups
                        ?.at(groupIndex)
                        ?.coveredServices?.at(serviceIndex)?.typeServiceId &&
                    service.type === ClinicInsuranceType.IN_NETWORK,
                )}
            >
              <S.MenuItemContent>In network</S.MenuItemContent>
            </MenuItem>
          </TooltipSelectInput>
        </S.InputWrapper>

        <S.InputWrapper className="percent50">
          <TooltipInput
            type={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.isUnlimited
                ? 'text'
                : 'number'
            }
            label="No. of Visit Authorized"
            value={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.isUnlimited
                ? 'UV'
                : formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                    ?.visitAuthorizet
            }
            disabled={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.isUnlimited
            }
            error={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.isUnlimited
                ? undefined
                : !!getIn(
                    formik.errors,
                    `groups[${groupIndex}].coveredServices[${serviceIndex}].visitAuthorizet`,
                  )
            }
            tooltipHelperText={
              formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                ?.isUnlimited
                ? undefined
                : getIn(
                    formik.errors,
                    `groups[${groupIndex}].coveredServices[${serviceIndex}].visitAuthorizet`,
                  )
            }
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(
                `groups[${groupIndex}].coveredServices[${serviceIndex}].visitAuthorizet`,
                e.target.value,
              );
            }}
          />
        </S.InputWrapper>

        {!fromInsurancePage && (
          <S.InputWrapper className="percent50">
            <TooltipInput
              type={
                formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                  ?.isUnlimited
                  ? 'text'
                  : 'number'
              }
              label="No. of Visit Used"
              value={
                formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                  ?.isUnlimited
                  ? 'UV'
                  : formik.values.groups
                      ?.at(groupIndex)
                      ?.coveredServices?.at(serviceIndex)?.visitUsed
              }
              disabled={
                formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                  ?.isUnlimited
              }
              error={
                formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                  ?.isUnlimited
                  ? undefined
                  : !!getIn(
                      formik.errors,
                      `groups[${groupIndex}].coveredServices[${serviceIndex}].visitUsed`,
                    )
              }
              tooltipHelperText={
                formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
                  ?.isUnlimited
                  ? undefined
                  : getIn(
                      formik.errors,
                      `groups[${groupIndex}].coveredServices[${serviceIndex}].visitUsed`,
                    )
              }
              onChange={e => {
                setIsDirtyForm(true);
                formik.setFieldValue(
                  `groups[${groupIndex}].coveredServices[${serviceIndex}].visitUsed`,
                  e.target.value,
                );
              }}
            />
          </S.InputWrapper>
        )}
      </S.Line>

      <S.Line className={'mt13'}>
        <CheckBoxWithLabel
          isArticle
          label="Unlimited"
          checked={
            formik.values.groups?.at(groupIndex)?.coveredServices?.at(serviceIndex)
              ?.isUnlimited
          }
          onChange={e => {
            setIsDirtyForm(true);
            formik.setFieldValue(
              `groups[${groupIndex}].coveredServices[${serviceIndex}].isUnlimited`,
              e.target.checked,
            );
          }}
        />
      </S.Line>
    </S.CoveredCard>
  );
};
