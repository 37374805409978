import { AuthFieldType } from '../types/enums/AppointmentEnum';

export const isTypeAuthField = (str: string) => {
  switch (!isNaN(Number(str))) {
    case true:
      return AuthFieldType.PHONE;
    case false:
      return AuthFieldType.EMAIL;
  }
};
