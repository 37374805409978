import { AppointmentProcessInterface } from '../../../types/AppointmentNewTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const startProgressApi = api.injectEndpoints({
  endpoints: builder => ({
    getTemplateList: builder.mutation({
      query: ({ appointmentId, clinicId, ...body }) => ({
        url: `/appointment-process/${appointmentId}/get-templates/${clinicId}`,
        method: 'GET',
      }),
      //   invalidatesTags: () => [{ type: "Progress" }],
    }),
    startAppointment: builder.mutation({
      query: ({ appointmentId, ...body }) => ({
        url: `/appointment-process/${appointmentId}/start`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
    getAppointmentProcess: builder.mutation<AppointmentProcessInterface, any>({
      query: ({ appointmentId, ...body }) => ({
        url: `/appointment-process/${appointmentId}`,
        method: 'GET',
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
    setStepAsCurrent: builder.mutation({
      query: ({ stepId }) => ({
        url: `/appointment-process/step/${stepId}/set-as-current`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
    cancelAppointment: builder.mutation({
      query: ({ appointmentId }) => ({
        url: `/appointment-process/${appointmentId}/cancel`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
    finishAppointment: builder.mutation({
      query: ({ appointmentId }) => ({
        url: `/appointment-process/${appointmentId}/finish`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
    completeStep: builder.mutation({
      query: ({ stepId }) => ({
        url: `/appointment-process/step/${stepId}/finish`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Appointments', 'Progress'],
    }),
  }),
});

export const {
  useGetTemplateListMutation,
  useStartAppointmentMutation,
  useGetAppointmentProcessMutation,
  useSetStepAsCurrentMutation,
  useCancelAppointmentMutation,
  useFinishAppointmentMutation,
  useCompleteStepMutation,
} = startProgressApi;
