import { createSlice } from '@reduxjs/toolkit';
import { ToastOptions, TypeOptions } from 'react-toastify';

type Notification = {
  message?: string;
  type?: TypeOptions;
};

const initialState: Notification = {};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationMsg: (state, action) => {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      };
    },
  },
});

const { reducer, actions } = notificationSlice;

export const { setNotificationMsg } = actions;
export default reducer;
