import { Fade, TableCell, TableRow, Tooltip } from '@mui/material';
import {
  AccTableClinicsType,
  BillingAccTableType,
} from '../../../../../../../../types/AccTableTypes';
import * as S from '../../../../styles';
import dayjs from 'dayjs';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const AccTableWorkSince: React.FC<Props> = ({ data, index }) => {
  return (
    <TableRow>
      {index === 0 && (
        <TableCell className="white white-header white-header-top"></TableCell>
      )}
      {data.clinics.map((clinic, index: number) => {
        return (
          <Tooltip
            key={clinic.clinicName + index}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#0084B1',
                  fontFamily: 'Inter',
                },
              },
            }}
            title={
              clinic.workingStartDate
                ? `Work since ${dayjs(clinic.workingStartDate).format('MM/DD/YYYY')}`
                : 'Work since MM/DD/YYYY'
            }
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TableCell
              className="clinics since"
              colSpan={clinic.outColumnCount + clinic.inColumnCount}
            >
              <S.DivWrapper>
                <S.SpanSince>Working since</S.SpanSince>{' '}
                {clinic.workingStartDate
                  ? dayjs(clinic.workingStartDate).format('MM/DD/YYYY')
                  : '-'}
              </S.DivWrapper>
            </TableCell>
          </Tooltip>
        );
      })}
    </TableRow>
  );
};

export default AccTableWorkSince;
