import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Input, SelectInput } from '../../../../../../components';
import {
  TemplateFieldsForFill,
  TemplateType,
} from '../../../../../../types/TemplateType';
import * as S from './styles';
import {
  useClinicsGetQuery,
  usePatientsGetQuery,
} from '../../../../../../store/api/clinics/clinicApi';
import AutoCompleteInput from '../../../../../../components/atoms/AutoComplete';
import { useDebounce } from 'use-debounce';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useGetAllDoctorsQuery } from '../../../../../../store/api/staff/staffApi';
import { FormikProps } from 'formik';
import { FillTemplateValues } from './StartPDFDialog';

type Props = {
  item: TemplateFieldsForFill;
  formik: FormikProps<FillTemplateValues>;
};

const CaseInputs: React.FC<Props> = ({ item, formik }) => {
  const clinics = useClinicsGetQuery({});

  const [searchPatientValue, setSearchPatientValue] = useState('');
  const [debouncedSearchPatientValue] = useDebounce(searchPatientValue, 500);
  const [selectedPatientValue, setSelectedPatientValue] = useState<any>(null);

  const [searchDoctorValue, setSearchDoctorValue] = useState('');
  const [debouncedSearchDoctorValue] = useDebounce(searchDoctorValue, 500);

  const [selectedDoctorValue, setSelectedDoctorValue] = useState<any>(null);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const rowData = usePatientsGetQuery({
    q: debouncedSearchPatientValue,
    id: selectedClinic.id,
    page: 1,
  });

  useEffect(() => {
    selectedPatientValue && formik.setFieldValue('patient', selectedPatientValue?.userId);
    formik.setFieldError('patient', '');
  }, [selectedPatientValue]);

  useEffect(() => {
    selectedDoctorValue && formik.setFieldValue('doctor', selectedDoctorValue?.userId);
    formik.setFieldError('doctor', '');
  }, [selectedDoctorValue]);

  const getAllDoctors = useGetAllDoctorsQuery({
    q: debouncedSearchDoctorValue,
    page: 1,
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  switch (item) {
    case TemplateFieldsForFill.PATIENT:
      return (
        <S.InputWrapper>
          <AutoCompleteInput
            data={rowData?.currentData?.rows || []}
            mainLabel={'Patient'}
            loading={rowData.isFetching || rowData.isLoading ? true : false}
            searchValue={searchPatientValue}
            setSearchValue={setSearchPatientValue}
            selectedValue={selectedPatientValue}
            helperText={formik.errors.patient}
            error={!!formik.errors.patient}
            setSelectedValue={setSelectedPatientValue}
          />
        </S.InputWrapper>
      );
    case TemplateFieldsForFill.GUARDIAN:
      return (
        <S.InputWrapper>
          <Input
            label="Guardian"
            value={formik.values.guardian}
            onChange={handleChangeInput}
            id="guardian"
            name="guardian"
            error={!!formik.errors.guardian}
            helperText={formik.errors.guardian}
          />
        </S.InputWrapper>
      );
    case TemplateFieldsForFill.DOCTOR:
      return (
        <S.InputWrapper>
          <AutoCompleteInput
            data={getAllDoctors?.currentData?.rows || []}
            mainLabel={'Doctor'}
            loading={getAllDoctors.isLoading ? true : false}
            searchValue={searchDoctorValue}
            setSearchValue={setSearchDoctorValue}
            selectedValue={selectedDoctorValue}
            setSelectedValue={setSelectedDoctorValue}
          />
        </S.InputWrapper>
      );

    default:
      return <></>;
  }
};

export default CaseInputs;
