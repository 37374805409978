import { FormikProps } from 'formik';
import MedicalHistory from './MedicalHistory';
import SymptomsBlock from './SymptomsBlock';
import UserInformation from './UserInformation';
import * as S from './styles';
import FamilyHistory from './FamilyHistory';
import Observation from './Observation';
import Assesment from './Assesment';
import TreatmentPlan from './TreatmentPlan';
import { forwardRef } from 'react';
import { InitialVisitFormType } from '../pages/InitialVisitFormPage/InitialVisitiFormType';
import { AppointmentsNew, Step } from 'types/AppointmentNewTypes';

type Props = {
  formik: FormikProps<InitialVisitFormType>;
  isFromStepper?: boolean;
  stepperValues?: Step;
  digitalFormId?: number;
  appointmentInformation?: AppointmentsNew;
};

const InitialVisitForm = forwardRef<Record<string, unknown>, Props>(
  (
    { formik, isFromStepper, stepperValues, appointmentInformation, digitalFormId },
    ref,
  ) => (
    <S.Wrapper>
      <S.Inputs>
        <UserInformation
          formik={formik}
          ref={ref}
          appointmentInformation={appointmentInformation}
        />
        <SymptomsBlock formik={formik} ref={ref} />
        <MedicalHistory />
        <FamilyHistory />
        <Observation />
        <Assesment />
        <TreatmentPlan />
      </S.Inputs>
    </S.Wrapper>
  ),
);

InitialVisitForm.displayName = 'InitialVisitForm';

export default InitialVisitForm;
