import { CheckBox } from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import { Clinics } from '../../../../../../../types/ClinicTypes';
import { AppointmentTemplateType } from '../../../../../../../types/TemplateType';
import * as S from '../styles';

type Props = {
  card: Clinics;
  onChangeSettings: (type: AppointmentTemplateType) => void;
};

const SettingsCard: React.FC<Props> = ({ card, onChangeSettings }) => {
  return (
    <S.SettingsCardWrapper>
      <S.LeftBlock>
        <CheckBoxWithLabel
          isArticle
          label={card.name}
          checked={!card.TemplateClinic?.isHidden || false}
          value={!card.TemplateClinic?.isHidden || false}
          onChange={() => onChangeSettings(AppointmentTemplateType.HIDDENCLINIC)}
        />
      </S.LeftBlock>
      <S.RightBlock $isDisabled={card.TemplateClinic?.isHidden || false}>
        <S.Caption>Appointment type</S.Caption>
        <CheckBoxWithLabel
          label="Initial consultation"
          checked={card.TemplateClinic?.isInitialConsultation || false}
          value={card.TemplateClinic?.isInitialConsultation || false}
          onChange={() => onChangeSettings(AppointmentTemplateType.INITIAL)}
        />
        <CheckBoxWithLabel
          label="Re-evaluation"
          checked={card.TemplateClinic?.isReEvaluation || false}
          value={card.TemplateClinic?.isReEvaluation || false}
          onChange={() => onChangeSettings(AppointmentTemplateType.REEVAL)}
        />
        <CheckBoxWithLabel
          label="Follow-up"
          checked={card.TemplateClinic?.isFollowUp || false}
          value={card.TemplateClinic?.isFollowUp || false}
          onChange={() => onChangeSettings(AppointmentTemplateType.FOLLOWUP)}
        />
      </S.RightBlock>
    </S.SettingsCardWrapper>
  );
};

export default SettingsCard;
