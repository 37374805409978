import React from 'react';
import { ImportErrorType } from '../../Patients';
import { CustomTable } from 'components';
import { TableCell, TableRow } from '@mui/material';
import * as S from 'pages/Billing/components/Claims/components/ClaimForm/components/AlphabetLines/styles';

const headers: { name: string; key: keyof ImportErrorType }[] = [
  { name: 'String number', key: 'index' },
  { name: 'Column name', key: 'field' },
  { name: 'Description', key: 'message' },
];

type Props = {
  errors: ImportErrorType[] | null;
};

export const ImportErrors = ({ errors }: Props) => {
  return (
    <S.Content>
      <S.Article>Errors</S.Article>

      <CustomTable headers={headers}>
        {!!errors?.length &&
          errors?.map((row, index) => {
            return (
              <TableRow key={`${row}-${index}`}>
                {headers.map(({ key }) => (
                  <TableCell>
                    <S.Text>{row[key] ?? '-'}</S.Text>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
      </CustomTable>
    </S.Content>
  );
};
