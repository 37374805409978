import { useEffect, useState } from 'react';
import {
  AppointmentsNew,
  Step,
  TemplateList,
} from '../../../../../types/AppointmentNewTypes';
import CheckBoxWithLabel from '../../../../atoms/CheckboxWithLabel';
import CircularLoader from '../../../../atoms/CircuralLoader';
import * as S from './styles';
import Button from '../../../../atoms/Button';
import { useEditStepListMutation } from '../../../../../store/api/templates/templateApi';
import { useGetTemplateListMutation } from '../../../../../store/api/start-progress/startProgressApi';
import { useAppSelector } from '../../../../../hooks/useAppSelector';

type Props = {
  onClose: () => void;
  data?: TemplateList[];
  isLoading?: boolean;
  appointment?: AppointmentsNew;
  steps?: Step[];
  refetch: () => void;
};

const DialogEditAppointmentProcess = ({
  onClose,
  data,
  isLoading,
  appointment,
  steps,
  refetch,
}: Props) => {
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateList[]>([]);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [initialStatusTemplates, setInitialStatusTemplates] = useState<TemplateList[]>(
    [],
  );
  const delClinicTemplatesIds: string[] = [];
  const addClinicTemplatesIds: string[] = [];

  const [getTemplateList, templateListStatus] = useGetTemplateListMutation({});
  const [editStepsList, editStepsListStatus] = useEditStepListMutation({});

  useEffect(() => {
    if (data) {
      const newArr = data.map(template => {
        if (steps?.some(obj => obj.clinicTemplateId === template.id)) {
          return {
            ...template,
            isChecked: true,
          };
        } else
          return {
            ...template,
            isChecked: false,
          };
      });
      setInitialStatusTemplates(newArr);
      setSelectedTemplates(newArr);
    }
  }, [data]);

  const handleChangeList = (id: string) => {
    const newArr = selectedTemplates.map(template => {
      if (id === template.id) {
        return {
          ...template,
          isChecked: !template.isChecked,
        };
      } else return template;
    });
    setSelectedTemplates(newArr);
  };

  const handleEditList = () => {
    selectedTemplates.map(item => {
      if (
        initialStatusTemplates?.some(
          obj =>
            obj.id === item.id &&
            !obj.isCompleted &&
            item.isChecked &&
            obj.isChecked !== item.isChecked,
        )
      ) {
        addClinicTemplatesIds.push(item.id);
      }

      if (
        initialStatusTemplates?.some(
          obj =>
            obj.id === item.id &&
            !obj.isCompleted &&
            !item.isChecked &&
            obj.isChecked !== item.isChecked,
        )
      ) {
        delClinicTemplatesIds.push(item.id);
      }
    });
  };

  return (
    <>
      <S.Content>
        <S.Article>Edit Appointment Process</S.Article>
        <S.HelperText>
          {editStepsListStatus.isLoading
            ? 'Please, wait until the appointment updates'
            : 'Please, choose needed templates for update'}
        </S.HelperText>
        {isLoading || editStepsListStatus.isLoading ? (
          <S.Loader>
            <CircularLoader color="#0084B1" />
          </S.Loader>
        ) : (
          <>
            {selectedTemplates &&
              selectedTemplates.map(item => {
                return (
                  <S.Row key={item.id}>
                    <CheckBoxWithLabel
                      label={item.template.name}
                      checked={item.isChecked}
                      value={item.isChecked}
                      disabled={item.isCompleted}
                      onChange={() => handleChangeList(item.id)}
                    />
                  </S.Row>
                );
              })}
            <S.ButtonWrapper>
              <Button
                text="Edit"
                onClick={() => {
                  handleEditList();
                  appointment &&
                    editStepsList({
                      id: appointment?.id,
                      addClinicTemplatesIds,
                      delClinicTemplatesIds,
                    })
                      .unwrap()
                      .then(res => {
                        refetch();
                        onClose();
                      })
                      .catch(err => {
                        refetch();
                        onClose();
                      });
                }}
              />
            </S.ButtonWrapper>
          </>
        )}
      </S.Content>
    </>
  );
};

export default DialogEditAppointmentProcess;
