import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceType } from 'types/ServicesType';

type SelectedClinics = {
  id: string;
  services: ServiceType[];
};

const initialState: SelectedClinics = {
  id: '',
  services: [],
};

const selectedClinicsSlice = createSlice({
  name: 'selectedClinic',
  initialState,
  reducers: {
    setSelectedClinic: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
      };
    },
    setServicesByClinic: (state, action: PayloadAction<{ services: ServiceType[] }>) => {
      return {
        ...state,
        services: action.payload.services,
      };
    },
  },
});

const { reducer, actions } = selectedClinicsSlice;

export const { setSelectedClinic, setServicesByClinic } = actions;
export default reducer;
