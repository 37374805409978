import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledMUIButton = styled(Button)`
  width: 100%;
  &.MuiButton-root {
    display: flex;

    height: 38px;
    padding: 13px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #0084b1;

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-decoration: none;
    text-transform: none;

    &:hover {
      background: #0084b1;
    }
    &:disabled {
      background: #848a9b !important;
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
