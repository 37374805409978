import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const Wrapper = styled.div``;

export const ProfileWrapper = styled.div``;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-left: 0px;
    margin-right: 0px;
    height: none;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const TabWrapper = styled.div`
  padding-left: 12px;

  & .demographics {
    display: none;
  }

  @media ${devices.xs} {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .demographics {
      display: inherit;
    }
  }
`;

export const SubtitleItems = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 170px;

  svg {
    &.white {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;

  gap: 10px;

  @media ${devices.xs} {
    margin-left: 0px;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  height: calc(100vh - 130px);
  background-color: #fff;
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
  /* margin-left: 15px;
  margin-right: 15px; */
`;

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  min-width: 330px;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 10px;
  /* scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  } */

  @media ${devices.xs} {
    width: 90%;
    background: #fff;
    border: none;
    margin-left: 20px;

    &.hide {
      display: none;
    }
  }
`;

export const TabInformation = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  border-radius: 10px;
  margin-right: 10px;
`;

export const LogoutDivWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
`;

export const InvoiceTypeSpan = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CircularWrapper = styled.div``;
