import { Input } from '../../../../../../../../../components';
import * as S from '../../styles';

const MedicineAndDosage = () => {
  return (
    <S.Lines>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Medicine 1" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Dosage" />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Medicine 2" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Dosage" />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px360">
          <Input label="Medicine 3" />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input label="Dosage" />
        </S.InputWrapper>
      </S.Line>
    </S.Lines>
  );
};

export default MedicineAndDosage;
