import dayjs from 'dayjs';
import { MouseEvent, useState } from 'react';
import {
  DeleteSVG,
  DownloadReportSVG,
  ManIconSVG,
  SendSurveySVG,
  TrashSVG,
} from '../../../../../../assets/icons';
import { useCouponReportMutation } from '../../../../../../store/api/coupons/couponsApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import * as S from '../styles';
import { Link, useNavigate } from 'react-router-dom';
import { getLinkName } from '../../../../../../helpers/functions/linkName';
import { Fade, Tooltip } from '@mui/material';
import {
  useSurveyDeleteMutation,
  useSurveyReportMutation,
} from '../../../../../../store/api/survey/surveyApi';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { setMessage } from '../../../../../../store/slices/message';
import { Dialog } from '../../../../..';
import ConfirmDeleteDialog from '../../../../../molecules/ConfirmDeleteDialog';
import SendSurveyDialog from '../SendSurveyDialog';
import ShowParticipants from '../ShowParticipants';

type Props = {
  row: any;
  header: string;
};

const SurveyData: React.FC<Props> = ({ row, header }) => {
  const [downloadReport] = useSurveyReportMutation({});
  const [deleteSurvey] = useSurveyDeleteMutation({});

  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const [openSendDialog, setOpenSendDialog] = useState(false);
  const handleChangeSendDialog = () => {
    setOpenSendDialog(openSendDialog => !openSendDialog);
  };

  const [openParticipantDialog, setOpenParticipantsDialog] = useState(false);
  const handleChangeParticipantsDialog = () => {
    setOpenParticipantsDialog(openParticipantDialog => !openParticipantDialog);
  };

  const handleSuccessDelete = () => {
    deleteSurvey({ id: row.id })
      .then(res => {
        dispatch(
          setMessage({
            message: 'Survey was successfully deleted',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  switch (header) {
    case 'Title': {
      return row.title || '';
    }
    case 'Date': {
      return dayjs(row.createdAt).format('MM/DD/YYYY');
    }

    case 'Link': {
      return row.link ? (
        <S.LinkWrapper>
          <Link to={row.link} target="_blank">
            {getLinkName(row.link)}
          </Link>
        </S.LinkWrapper>
      ) : (
        '-'
      );
    }
    case 'ClinicName': {
      return row.clinic?.name || '-';
    }
    case 'NumberPart': {
      return (
        <>
          {' '}
          <Dialog open={openParticipantDialog} onClose={handleChangeParticipantsDialog}>
            <ShowParticipants onClose={handleChangeParticipantsDialog} row={row} />
          </Dialog>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#0084B1',
                  fontFamily: 'Inter',
                },
              },
            }}
            title="Click to show participants"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <S.LinkWrapper onClick={handleChangeParticipantsDialog}>
              <ManIconSVG />
              {row.numberOfParticipants || '0'}
            </S.LinkWrapper>
          </Tooltip>
        </>
      );
    }

    case 'Edit': {
      return (
        <S.CellWrapper>
          <Dialog open={openDialog} onClose={handleChangeDialog}>
            <ConfirmDeleteDialog
              onClose={handleChangeDialog}
              titleText={'survey'}
              onSuccess={handleSuccessDelete}
            />
          </Dialog>
          <Dialog open={openSendDialog} onClose={handleChangeSendDialog}>
            <SendSurveyDialog onClose={handleChangeSendDialog} row={row} />
          </Dialog>
          <DownloadReportSVG
            onClick={() =>
              downloadReport({ id: row.id })
                .unwrap()
                .then(res => {})
                .catch(err =>
                  dispatch(
                    setMessage({
                      message: 'Failed to upload report',
                      type: 'error',
                    }),
                  ),
                )
            }
          />
          <SendSurveySVG onClick={handleChangeSendDialog} />
          <TrashSVG className="delete" onClick={handleChangeDialog} />
        </S.CellWrapper>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default SurveyData;
