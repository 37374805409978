import { Input } from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from './styles';

const FamilyHistory = () => {
  return (
    <>
      <S.Article>Family History</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Diabetes" />
        <CheckBoxWithLabel label="Heart Disease" />
        <CheckBoxWithLabel label="High blood Pressure" />
        <CheckBoxWithLabel label="Cancer" />
        <S.Text>Other:</S.Text>
        <S.InputWrapper>
          <Input />
        </S.InputWrapper>
      </S.Line>
    </>
  );
};

export default FamilyHistory;
