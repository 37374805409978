import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const DialogItem = styled(Dialog)`
  & .MuiDialogContent-root {
    padding: 0;
  }

  & .MuiPaper-root {
    max-width: none;
  }
`;
