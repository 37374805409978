import { CheckboxProps, FormHelperText } from '@mui/material';
import * as S from './styles';

type Props = {
  disabled?: boolean;
  label?: string;
  isArticle?: boolean;
  gap?: string;
} & CheckboxProps;

const CheckBoxWithLabel: React.FC<Props> = ({
  disabled,
  label,
  isArticle,
  gap,
  ...props
}) => {
  return (
    <label htmlFor={props.id}>
      <S.Wrapper gap={gap} disabled={disabled}>
        <S.CheckBoxItem
          id={props.id}
          disabled={disabled}
          inputProps={{ 'aria-label': 'controlled' }}
          {...props}
        />
        <S.Label className={isArticle ? 'article' : ''}>{label}</S.Label>
      </S.Wrapper>
    </label>
  );
};

export default CheckBoxWithLabel;
