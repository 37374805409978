import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import * as S from './styles';
import { Patients } from 'types/ClinicTypes';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { AvatarSVG } from 'assets/icons';
import dayjs from 'dayjs';

type Props = {
  mainLabel?: string;
  isRequired?: boolean;
  data: Array<any>;
  loading: boolean;
  searchValue: string;
  setSearchValue: any;
  selectedValue: any;
  setSelectedValue: any;
  onDoubleClick?: () => void;
  renderOption?: any;
  getOptionLabel?: any;
  options?: any;
  customRender?: boolean;
  disabled?: boolean;
  customRenderInput?: any;
  error?: boolean;
  helperText?: string;
  serverSelected?: Array<Patients>;
  isShowBirthday?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  defaultValue?: string;
  setUserLastElement?: Dispatch<
    SetStateAction<HTMLDivElement | HTMLTableRowElement | null>
  >;
  setPage?: (page: number) => void;
  isOptionEqualToValue?: (option: any, val: any) => boolean;
};

const AutoCompleteInput: React.FC<Props> = ({
  mainLabel,
  isRequired,
  data,
  loading,
  searchValue,
  disabled,
  selectedValue,
  setSearchValue,
  setSelectedValue,
  onDoubleClick,
  renderOption,
  options,
  getOptionLabel,
  customRender,
  customRenderInput,
  error,
  helperText,
  serverSelected,
  getOptionDisabled,
  isShowBirthday,
  defaultValue,
  setUserLastElement,
  setPage,
  isOptionEqualToValue,
}) => {
  const fixedVal: Array<any> = [];
  // const [val, setVal] = useState([...fixedVal]);
  const CustomChip = (chipVal: any, getTagProps: any) => {
    return chipVal.map((item: any, index: number) => (
      <Chip
        label={item.label}
        {...getTagProps({ index })}
        disabled={fixedVal.indexOf(item) !== -1}
      />
    ));
  };

  useEffect(() => {
    if (data && defaultValue) {
      const currentValue = data?.find(i => i?.userId === defaultValue);
      if (currentValue) {
        setSelectedValue(currentValue);
      }
    }
  }, [defaultValue]);

  return (
    <S.Wrapper onDoubleClick={onDoubleClick}>
      <S.Label htmlFor="input">
        {mainLabel} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      {customRender ? (
        <Autocomplete
          value={selectedValue}
          onChange={(e, newValue) => {
            setSelectedValue(newValue);
          }}
          disabled={disabled}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={getOptionDisabled}
          renderOption={renderOption}
          inputValue={searchValue}
          onInputChange={(e, newInputValue) => {
            setPage && setPage(1);
            setSearchValue(newInputValue);
          }}
          filterOptions={x => x}
          disableClearable={true}
          renderInput={params => (
            <TextField
              placeholder="Enter a search value"
              key={params.id}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderColor: error ? 'red' : '',
                },
              }}
              error={error}
              helperText={helperText}
              FormHelperTextProps={{
                focused: false,
              }}
              {...params}
              InputProps={{
                ...params?.InputProps,
                style: {
                  borderColor: error ? 'red' : '',
                },
              }}
            />
          )}
          // renderTags={CustomChip}
        />
      ) : (
        <Autocomplete
          value={selectedValue}
          onChange={(e, newValue) => {
            setSelectedValue(newValue);
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionDisabled={getOptionDisabled}
          options={data || []}
          getOptionLabel={option =>
            option
              ? option.user?.profile?.firstName + ' ' + option.user?.profile?.lastName
              : ''
          }
          filterOptions={x => x}
          renderOption={(props: any, option) => {
            const refLast =
              data?.length === props['data-option-index'] + 1 && setUserLastElement
                ? setUserLastElement
                : null;

            return (
              <Box component="li" {...props} key={option?.userId} ref={refLast}>
                {loading ? (
                  'Searching...'
                ) : (
                  <S.LiWrapper>
                    <S.UserInfo>
                      {option.user?.avatar ? (
                        <S.Avatar>
                          <img src={option.user?.avatar?.url} alt="ava" />
                        </S.Avatar>
                      ) : (
                        <AvatarSVG />
                      )}
                      <div>
                        {option.user?.profile?.firstName +
                          ' ' +
                          option.user?.profile?.lastName}
                      </div>
                    </S.UserInfo>
                    {isShowBirthday && (
                      <S.DateOfBirth>
                        {option.user?.profile?.dateOfBirth
                          ? dayjs(option.user?.profile?.dateOfBirth).format('MM/DD/YYYY')
                          : ''}
                      </S.DateOfBirth>
                    )}
                  </S.LiWrapper>
                )}
              </Box>
            );
          }}
          inputValue={searchValue}
          onInputChange={(e, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          renderInput={params => (
            <TextField
              placeholder="Enter a search value"
              key={params.id}
              error={error}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderColor: error ? 'red' : '',
                },
              }}
              helperText={helperText}
              {...params}
              FormHelperTextProps={{
                focused: false,
              }}
            />
          )}
          // renderTags={CustomChip}
        />
      )}
    </S.Wrapper>
  );
};

export default AutoCompleteInput;
