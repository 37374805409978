import DatePicker, {
  Calendar,
  CalendarProps,
  DateObject,
  DatePickerProps,
} from 'react-multi-date-picker';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';

type Props = {
  // value: DateObject[] | DateObject;
  value?: any;
  onChange?(value: DateObject[] | DateObject): void;
  weekPicker?: boolean;
  onlyMonthPicker?: boolean;
  onlyYearPicker?: boolean;
  multiple?: boolean;
  mode?: string;
  disabledClick?: boolean;
} & CalendarProps &
  DatePickerProps;

const MultiDatePicker: React.FC<Props> = ({
  value,
  onChange,
  weekPicker = false,
  onlyMonthPicker = false,
  onlyYearPicker = false,
  multiple,
  mode,
  disabledClick,
  ...props
}) => {
  const calendarRef = useRef<any>();

  useEffect(() => {
    if (calendarRef) {
      calendarRef.current?.date?.set({
        year: value[0]?.year,
        month: value[0]?.month,
        day: value[0]?.day,
      });
    }
  }, [calendarRef, value]);

  return (
    <S.MultiDatePickerWrapper $disabledClick={disabledClick}>
      <Calendar
        disabled={disabledClick || props.disabled}
        multiple={multiple}
        ref={calendarRef}
        onlyMonthPicker={onlyMonthPicker}
        onlyYearPicker={onlyYearPicker}
        weekPicker={weekPicker}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        showOtherDays
        value={value}
        onChange={onChange}
        {...props}
      />
    </S.MultiDatePickerWrapper>
  );
};

export default MultiDatePicker;
