import { HoldCard, UserStatusPin } from 'components';
import { AvatarSVG } from 'assets/icons';
import { ucFirst } from 'helpers/functions/toUpperCase';

import * as S from '../../Invoices/styles';

type Props = {
  row: any;
  header: string;
};

const InvoiceData: React.FC<Props> = ({ row, header }) => {
  switch (header) {
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin type={row.status} />
        </S.CellWrapper>
      );
    }
    case 'InvoiceNumber': {
      return row.id || '-';
    }
    case 'InvoiceType': {
      return row.type ? ucFirst(row.type) : '-';
    }
    case 'ChargeAmount': {
      return row.totalChargeAmount ? row.totalChargeAmount + ' USD' : '0 USD';
    }
    case 'VisitReason': {
      return row.totalItemsCount || '0';
    }
    case 'Name': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.patient?.avatar ? (
              <img src={row.patient.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          {row.patient?.profile?.firstName + ' ' + row.patient?.profile?.lastName || '-'}
          {row?.patient?.isHoldAppointment && (
            <HoldCard text="HA" reason={row?.patient?.isHoldAppointment} />
          )}
          {row?.patient?.isHoldBilling && (
            <HoldCard text="HB" reason={row?.patient?.isHoldBilling} />
          )}
        </S.CellWrapper>
      );
    }

    default:
      return row[header] || '-';
  }
};

export default InvoiceData;
