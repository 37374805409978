import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media ${devices.xs} {
    min-height: 100vh;
  }
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ${devices.xs} {
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  margin-top: 10px;
  gap: 12px;
`;

export const ButtonWrapper = styled.div`
  width: 178px;

  &.xs {
    width: 130px;
    svg {
      width: 28px;
      height: 28px;

      &.smallsvg {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media ${devices.xs} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 200px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: calc(100vh - 110px);
  margin: 7px 10px;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

export const SearchWrapper = styled.div`
  width: 300px;
  margin-right: 6px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const AlertBlock = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 231, 104, 0.2);
  gap: 10px;
`;

export const MailWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const LinearLoader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

export const MailContainer = styled.div`
  display: flex;
  gap: 10px 10px;
  width: 100%;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;
