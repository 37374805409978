import styled from 'styled-components';

export const CellRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  width: 100%;
  word-break: normal;
  gap: 10px;
  padding: 5px;

  &.name {
    justify-content: start;
    text-align: start;
    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }
  &.note {
    max-width: 250px;
  }
`;

export const Btns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #848a9b;

  svg {
    width: 24px;
  }

  & :hover {
    color: #0084b1;
  }
`;
