import dayjs from 'dayjs';
import { UserProfile } from 'types/UserProfileTypes';
import * as S from './styles';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { HoldCard } from 'components';
import { useNavigate } from 'react-router-dom';

type Props = {
  patient?: UserProfile;
};

const InvPatientInformation = ({ patient }: Props) => {
  const navigate = useNavigate();
  return (
    <S.ItemsWrap>
      <S.Item>
        <S.Article>Patient name</S.Article>
        <S.Link onClick={() => navigate(`/patients/${patient?.id}`)}>
          {patient?.profile?.firstName && patient.profile?.lastName
            ? patient?.profile?.firstName + ' ' + patient?.profile?.lastName
            : '-'}
          {patient?.isHoldAppointment && (
            <HoldCard text="HA" reason={patient?.isHoldAppointment} />
          )}
          {patient?.isHoldBilling && (
            <HoldCard text="HB" reason={patient?.isHoldBilling} />
          )}
        </S.Link>
      </S.Item>
      <S.Item>
        <S.Article>DOB</S.Article>
        <S.Text>
          {patient?.profile?.dateOfBirth
            ? dayjs(patient.profile.dateOfBirth).format('MM/DD/YYYY')
            : '-'}
        </S.Text>
      </S.Item>
      <S.Item>
        <S.Article>Sex</S.Article>
        <S.Text>{patient?.profile?.sex ? ucFirst(patient.profile.sex) : '-'}</S.Text>
      </S.Item>
      <S.Item>
        <S.Article>Primary Insurance</S.Article>
        <S.Text>
          {patient?.profile?.insurances?.filter(insurance => insurance.isPrimary)[0]
            ?.primaryInsuranceCompany || '-'}
        </S.Text>
      </S.Item>
      <S.Item>
        <S.Article>ID</S.Article>
        <S.Text>
          {patient?.profile?.insurances?.filter(insurance => insurance.isPrimary)[0]
            ?.insuranceId || '-'}
        </S.Text>
      </S.Item>
      <S.Item>
        <S.Article>Group #</S.Article>
        <S.Text>
          {patient?.profile?.insurances?.filter(insurance => insurance.isPrimary)[0]
            ?.groupName || '-'}
        </S.Text>
      </S.Item>
      <S.Item>
        <S.Article>Insured</S.Article>
        <S.Text>
          {patient?.profile?.insurances?.filter(insurance => insurance.isPrimary)[0]
            ?.relation || '-'}
        </S.Text>
      </S.Item>
      <S.Item>
        <S.Article>Address</S.Article>
        <S.Text>{patient?.profile?.address || '-'}</S.Text>
      </S.Item>
      {/*<S.Item>
        <S.Article>ICD Codes</S.Article>
        <S.Text>-</S.Text>
      </S.Item>*/}
    </S.ItemsWrap>
  );
};

export default InvPatientInformation;
