import DonutChart from 'components/atoms/DonutChart';
import * as S from '../../styles';
import { useAppSelector } from 'hooks/useAppSelector';
import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getFirstAndLastDate } from 'helpers/functions/getFirstAndLastDate';
import { useDebounce } from 'use-debounce';
import { useInvoicesGetQuery } from 'store/api/dashboard/dashboardApi';
import { DatesFilter } from 'pages/Receptionist/components/Dashboard/components/DatesFilter';
import { CircularLoader } from 'components';

const InvoicesCount = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).firstDate,
  );
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).lastDate,
  );

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  const getStat = useInvoicesGetQuery({
    clinicId: selectedClinic.id,
    startDate: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
    endDate: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
  });

  const labels = ['Paid Invoices', 'Unpaid Invoices'];

  const data = [
    getStat.currentData?.at(0)?.paidInvoices,
    getStat.currentData?.at(0)?.unpaidInvoices,
  ];

  const commonDataLength = useMemo(() => {
    return (
      getStat.currentData?.at(0)?.paidInvoices +
      getStat.currentData?.at(0)?.unpaidInvoices
    );
  }, [getStat.currentData]);

  const options = {
    type: 'doughnut',
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data: any, i: number) => ({
              text: commonDataLength ? `${chart.data.labels[i]} (${data}%)` : 'No Data',
              fillStyle: commonDataLength
                ? datasets[0].backgroundColor[i]
                : 'rgb(205,205,205)',
              index: i,
            }));
          },
          font: {
            family: 'Inter',
            size: '14px',
          },
          usePointStyle: true,
        },
      },
    },
  };

  return (
    <S.MainInfoCard>
      <S.MainContent>
        <S.TitleBox>
          <S.SubtitleText>Invoices</S.SubtitleText>
          <DatesFilter
            firstDate={firstDate}
            secondDate={secondDate}
            setFirstDate={setFirstDate}
            setSecondDate={setSecondDate}
            isLoading={getStat.isFetching || getStat.isLoading}
          />
        </S.TitleBox>

        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : commonDataLength ? (
              <DonutChart labels={labels} records={data} options={options} />
            ) : (
              <DonutChart labels={labels} records={data} options={options} isEmpty />
            )}
          </S.Chart>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInfoCard>
  );
};

export default InvoicesCount;
