import { useEffect, useState } from 'react';
import {
  MultiDatePicker,
  ScrollContainer,
  SearchInput,
  TabsItem,
} from '../../../../components';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { Tab } from '@mui/material';
import { getWeekInitial } from '../../../../helpers/CalendarHelpers/getWeekInitial';
import { handleChangeDate } from '../../../../helpers/CalendarHelpers/handleDates';
import Incoming from './components/Incoming';
import Outgoing from './components/Outgoing';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Roles } from '../../../../types/Roles';

const Tickets = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);

  const { role } = useAppSelector(state => state.auth);
  const selectedClinic = useAppSelector(state => state.selectedClinic.id);

  const [page, setPage] = useState(1);

  const [tabIndex, setTabIndex] = useState(0);

  // useEffect(() => {
  //   setPage(1);
  // }, [tabIndex, debouncedSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Tickets</S.SubtitleText>
        <S.SearchWrapper>
          <SearchInput
            value={searchValue}
            onChange={handleSearch}
            placeholder={'Search by Username'}
          />
        </S.SearchWrapper>
      </S.SubtitleBar>
      <S.Content>
        <S.CalendarBar>
          <MultiDatePicker multiple={false} className={'disabledClick'} />
        </S.CalendarBar>
        <S.Block>
          <S.TabWrapper>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="All tickets"></Tab>
              <Tab disableRipple label="Outgoing"></Tab>
            </TabsItem>
            <S.TicketsWrapper>
              <ScrollContainer>
                {tabIndex === 0 && (
                  <Incoming
                    page={page}
                    setPage={setPage}
                    role={role as Roles}
                    clinicId={selectedClinic}
                    debouncedSearch={debouncedSearch}
                  />
                )}
                {tabIndex === 1 && (
                  <Outgoing
                    page={page}
                    setPage={setPage}
                    clinicId={selectedClinic}
                    debouncedSearch={debouncedSearch}
                  />
                )}
              </ScrollContainer>
            </S.TicketsWrapper>
          </S.TabWrapper>
        </S.Block>
        {/* {patients.isFetching ? (
      <LinearLoader />
    ) : ( */}
      </S.Content>
    </S.Wrapper>
  );
};

export default Tickets;
