import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../../store/api/auth/authApi';
import { setUser } from '../../../store/api/auth/authSlice';
import { Roles } from '../../../types/Roles';
import Button from '../../atoms/Button';
import * as S from './styles';
import { UserStatus } from '../../../types/UserStatuses';
import ChangePasswordDialog from './ChangePasswordDialog';
import { useState } from 'react';
import { Dialog } from '../..';
import { useAppSelector } from '../../../hooks/useAppSelector';
import PutSignature from '../../../pages/SuperAdmin/components/Templates/components/PutSignature';
import { useUploadDoctorSignatureMutation } from '../../../store/api/user/profileApi';
import {
  DeleteAppointmentSVG,
  DeleteBtnSVG,
  JPGSVG,
  PDFSVG,
} from '../../../assets/icons';
import { PhotoView } from 'react-photo-view';
import dayjs from 'dayjs';

type Props = {
  className?: string;
  signature?: {
    id: string;
    name: string;
    url: string;
    createdAt: string;
  };
};

const LogoutDiv: React.FC<Props> = ({ className, signature }) => {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [openImgSignature, setOpenImgSignature] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const userInfo = useAppSelector(state => state.auth);

  const [uploadSignature] = useUploadDoctorSignatureMutation({});

  return (
    <>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <ChangePasswordDialog onClose={handleChangeDialog} />
      </Dialog>
      <Dialog
        open={openImgSignature}
        onClose={() => setOpenImgSignature(!openImgSignature)}
      >
        <S.ContentDialog>
          <img src={signature?.url} alt="" />
        </S.ContentDialog>
      </Dialog>
      <Dialog
        open={openSignatureDialog}
        onClose={() => setOpenSignatureDialog(!openSignatureDialog)}
      >
        <PutSignature
          actionAfterSignature={(signature: File) => {
            uploadSignature({
              signature: signature,
            });
          }}
        />
      </Dialog>
      <S.LogoutDivWrapper>
        {userInfo.role === Roles.DOCTOR && (
          <S.RowContent className={className}>
            <S.Article>Signature</S.Article>
            <S.FlexContentRow>
              {!signature?.id ? (
                <>
                  <S.RowText>Please, add your signature to your profile</S.RowText>

                  <S.ButtonWrapper>
                    <Button
                      backgroundColor="#79A030"
                      text="Add Signature"
                      onClick={() => setOpenSignatureDialog(!openSignatureDialog)}
                    />
                  </S.ButtonWrapper>
                </>
              ) : (
                <S.BlockContent>
                  <S.RowText>To remove a signature, contact the administrator</S.RowText>

                  <S.FileRow onClick={() => setOpenImgSignature(true)}>
                    <S.InfoRow>
                      <JPGSVG className="icon" />
                      {'Your signature. Click to open view'}
                    </S.InfoRow>
                    <S.Date>
                      Date: {dayjs(signature?.createdAt).format('MM/DD/YYYY')}
                    </S.Date>
                    {/* <DeleteAppointmentSVG /> */}
                  </S.FileRow>
                </S.BlockContent>
              )}
            </S.FlexContentRow>
          </S.RowContent>
        )}
        <S.RowContent className={className}>
          <S.Article>Change password</S.Article>
          <S.FlexContentRow>
            <S.RowText>
              You can change your password for security reasons or reset it if you forget
              it. Press “Change” button and we send you confirmation code
            </S.RowText>
            <S.ButtonWrapper>
              <Button text="Change" onClick={handleChangeDialog} />
            </S.ButtonWrapper>
          </S.FlexContentRow>
        </S.RowContent>
        <S.RowContent className={className}>
          <S.Article className="hide">Log out</S.Article>
          <S.FlexContentRow>
            <S.RowText className="hide">
              Press ”Log out” button to leave the account
            </S.RowText>
            <S.ButtonWrapper className="hide">
              <Button
                text="Logout"
                backgroundColor="#C66060"
                onClick={() => {
                  logout({}).then(() => {
                    window.location.reload();
                    dispatch(
                      setUser({
                        role: Roles.UNAUTH,
                        status: UserStatus.UNVERIFIED,
                      }),
                    );
                  });
                }}
              />
            </S.ButtonWrapper>
          </S.FlexContentRow>
        </S.RowContent>
      </S.LogoutDivWrapper>
    </>
  );
};

export default LogoutDiv;
