import {
  NotificationBellSVG,
  NotificationCircleSVG,
  NotificationSVG,
} from '../../../assets/icons';
import { NotificationItemType } from '../../../types/NotificationsTypes';
import * as S from './styles';

type Props = {
  src?: string;
  visitName?: string;
  isApproved: boolean;
  isHideRead?: boolean;
  notificationItem?: NotificationItemType;
};

const NotificationRow: React.FC<Props> = ({
  src,
  visitName,
  isApproved,
  notificationItem,
  isHideRead,
}) => {
  return (
    <S.NotificationRowWrapper
      // onClick={() => console.log("asd")}
      $isRead={(!isHideRead && !notificationItem?.isRead) || false}
    >
      <S.AvatarDiv>
        <NotificationBellSVG />
      </S.AvatarDiv>
      <S.Text>
        <S.Title>{notificationItem?.title}</S.Title>
        <S.Message>{notificationItem?.message}</S.Message>
      </S.Text>
    </S.NotificationRowWrapper>
  );
};

export default NotificationRow;
