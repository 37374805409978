import { FileType } from '../types/enums/FileType';
import { DOCSVG, JPGSVG, MP4SVG, PDFSVG, PPTSVG, XLSSVG } from '../assets/icons';

export const getFileIcon = (format: string) => {
  switch (format) {
    case FileType.pdf:
      return <PDFSVG />;
    case FileType.png:
      return <JPGSVG />;
    case FileType.jpg:
      return <JPGSVG />;
    case FileType.jpeg:
      return <JPGSVG />;
    case FileType.xls:
      return <XLSSVG />;
    case FileType.xlsx:
      return <XLSSVG />;
    case FileType.csv:
      return <XLSSVG />;
    case FileType.mp4:
      return <MP4SVG />;
    case FileType.ppt:
      return <PPTSVG />;
    case FileType.pptx:
      return <PPTSVG />;
    case FileType.doc:
      return <DOCSVG />;
    case FileType.docx:
      return <DOCSVG />;
    default: {
      return;
    }
  }
};
