import { View, Text } from '@react-pdf/renderer';

type Props = {
  article: string;
  text: string | number;
  styles: any;
  isPadding?: boolean;
  placeholder?: string;
};

const FieldValue = ({ article, text, styles, isPadding, placeholder }: Props) => {
  return (
    <View style={styles.element}>
      {article && <Text style={styles.span}>{article}: </Text>}
      {isPadding ? (
        <Text style={styles.textWithPadding}>{text}</Text>
      ) : (
        <Text style={styles.text}>{text}</Text>
      )}
      {placeholder && (
        <Text
          style={text ? styles.placeholder : { ...styles.placeholder, marginLeft: 50 }}
        >
          {placeholder}
        </Text>
      )}
    </View>
  );
};

export default FieldValue;
