import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  svg {
    user-select: none;
  }

  @media ${devices.xs} {
    min-height: 100vh;
  }
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const CalendarPlaceholderWrapper = styled.div`
  /* height: calc(100vh - 500px); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const PlaceText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  width: 400px;

  &.noopacity {
    opacity: 1;
  }
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
    /* width: 100px !important;
    height: 100px !important; */
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
    /* width: 100px !important;
    height: 100px !important; */
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ${devices.xs} {
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 210px;

  @media ${devices.xs} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  height: 100%;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

export const ShowMore = styled.div``;

export const CalendarBar = styled.div`
  width: 255px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  height: 100%;

  @media ${devices.xs} {
    width: auto;
    gap: none;
    justify-content: center;
  }
`;

export const Information = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;

  @media ${devices.xs} {
    /* height: none; */
  }
`;

export const SearchWrapper = styled.div`
  width: 394px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const HeaderInformation = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: 16px;

  & .btn {
    cursor: pointer;
  }

  @media ${devices.xs} {
    display: none;
  }
`;

export const ChoosedDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const ChoosedDateText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 250px;
`;

export const SelectWrapper = styled.div`
  width: 160px;

  & .MuiInputBase-root {
    background-color: #fff;
  }
`;

export const MenuItemRow = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const FilterWrapper = styled.div`
  width: 238px;

  & .forMobile {
    display: none;

    @media ${devices.xs} {
      display: flex;
    }
  }
  & .MuiInputBase-root {
    background-color: #fff;
  }

  /* @media ${devices.xs} {
    display: none; 
  } */
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;

  & svg {
    position: absolute;
  }
`;

export const SelectInputOutputText = styled.div`
  margin-left: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RecordItems = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: 16px;
  margin-right: 16px;
  gap: 10px;

  @media ${devices.xs} {
  }
`;
