import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, DatePicker, SelectInput } from 'components';
import {
  useClinicsGetQuery,
  useStaffAssignToClinicMutation,
} from 'store/api/clinics/clinicApi';
import * as S from './styles';
import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Clinics } from 'types/ClinicTypes';
import { Roles } from 'types/Roles';
import { GetDoctorList } from './GetDoctorList';
import { GetLocalAdminList } from './GetLocalAdminList';
import { GetReceptionistList } from './GetReceptionistList';
import { useAppSelector } from 'hooks/useAppSelector';

type Props = {
  userId?: string;
  assignInfo?: Clinics;
  onClose: () => void;
  role?: Roles;
  clinicId?: string;

  isSpecialists?: boolean;
};

const AssignClinicDialog = ({
  userId,
  assignInfo,
  onClose,
  role,
  clinicId,
  isSpecialists,
}: Props) => {
  const clinics = useClinicsGetQuery({});
  const me = useAppSelector(state => state.auth);

  const [clinicName, setClinicName] = useState('');
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [assignOn] = useStaffAssignToClinicMutation({});

  const dispatch = useAppDispatch();

  const disableButton = useMemo(
    () => !clinicName || !firstWorkDay,
    [clinicName, firstWorkDay],
  );

  const getTitle = useMemo(() => {
    switch (role) {
      case Roles.DOCTOR: {
        return isSpecialists ? 'Specialist' : 'Doctor';
      }
      case Roles.RECEPTIONIST: {
        return 'Receptionist';
      }
      case Roles.LOCATION_MANAGER: {
        return 'Local Admin';
      }
      default: {
        return 'Clinic';
      }
    }
  }, [role, isSpecialists]);

  useEffect(() => {
    if (assignInfo) {
      setClinicName(assignInfo.id);
      setFirstWorkDay(dayjs(assignInfo.workingStartDate));
      assignInfo.workingEndDate && setLastWorkDay(dayjs(assignInfo.workingEndDate));
    }
  }, [assignInfo]);

  useEffect(() => {
    if (clinicId) {
      setClinicName(clinicId);
    }
  }, [clinicId]);

  return (
    <S.Content>
      <S.Article>Assign {getTitle}</S.Article>
      <S.InputWrapper>
        {!userId && role === Roles.DOCTOR && (
          <S.InputAutoCompleteWrapper>
            <GetDoctorList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.DOCTOR}
              isSpecialist={!!isSpecialists}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.RECEPTIONIST && (
          <S.InputAutoCompleteWrapper>
            <GetReceptionistList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.RECEPTIONIST}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.LOCATION_MANAGER && (
          <S.InputAutoCompleteWrapper>
            <GetLocalAdminList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.LOCATION_MANAGER}
            />
          </S.InputAutoCompleteWrapper>
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        <SelectInput
          label="Clinic"
          value={clinicName}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setClinicName(e.target.value as string);
          }}
          disabled={!!clinicId}
        >
          {clinics.currentData?.rows.map(clinic => {
            return me?.role === Roles.SUPER_ADMIN ? (
              <MenuItem key={clinic.id} value={clinic.id}>
                <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
              </MenuItem>
            ) : (
              me.clinics?.some(i => i.id === clinic.id) && (
                <MenuItem key={clinic.id} value={clinic.id}>
                  <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                </MenuItem>
              )
            );
          })}
        </SelectInput>
      </S.InputWrapper>
      <S.InputWrapper>
        <DatePicker
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
        />
        <DatePicker
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
        />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button
          text="Save"
          disabled={disableButton}
          onClick={() =>
            assignOn({
              clinicId: clinicName,
              userId: userId || selectedValue.userId,
              workingStartDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
              ...(lastWorkDay && {
                workingEndDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
              }),
            })
              .unwrap()
              .then(res => {
                onClose();
                dispatch(
                  setMessage({
                    message: 'Clinic was succesfully assigned to user',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                onClose();
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              })
          }
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AssignClinicDialog;
