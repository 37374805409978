import * as S from '../../../styles';
import { Dialog, UserStatusPin } from 'components';
import { AvatarSVG, DeleteBtnSVG } from 'assets/icons';
import { Clinics } from 'types/ClinicTypes';
import { MouseEvent, useState } from 'react';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { useParams } from 'react-router-dom';
import { useStaffRemoveFromClinicMutation } from 'store/api/clinics/clinicApi';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/slices/message';

type Props = {
  row: any;
  header: string;
  deletable?: boolean;
  setIsClickedDelete?: React.Dispatch<React.SetStateAction<boolean>>;
};

const StaffDoctorData: React.FC<Props> = ({
  row,
  header,
  deletable,
  setIsClickedDelete,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [removeStaffFromClinic] = useStaffRemoveFromClinicMutation({});
  const dispatch = useDispatch();

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const params = useParams();

  const handleSuccessDelete = () => {
    setOpenDialog(false);
    setIsClickedDelete && setIsClickedDelete(false);
    removeStaffFromClinic({ clinicId: params.clinicId, staffId: row.userId })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
  };

  switch (header) {
    case 'DoctorName': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.user.avatar ? (
              <img src={row.user.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          {row.user.profile?.firstName + ' ' + row.user.profile?.lastName || '-'}
        </S.CellWrapper>
      );
    }

    case 'ClinicName': {
      return row.user.clinics?.length ? (
        <S.CellColumnWrapper>
          {row.user.clinics?.map((item: Clinics) => (
            <S.ColumnText key={item.id}>{item.name}</S.ColumnText>
          ))}
        </S.CellColumnWrapper>
      ) : (
        '-'
      );
    }
    case 'Speciality': {
      return row?.speciality?.length ? (
        <S.CellColumnWrapper>
          {row.speciality?.map((spec: { serviceName: string; typeServiceId: string }) => (
            <S.ColumnText key={spec.typeServiceId}>{spec.serviceName}</S.ColumnText>
          ))}
        </S.CellColumnWrapper>
      ) : (
        '-'
      );
    }
    case 'Phone': {
      return row.user.phone || '-';
    }
    case 'Email': {
      return row.user.email || '-';
    }
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin
            type={row.user.isBlocked ? row.user.isBlocked : row.user.status}
          />
        </S.CellWrapper>
      );
    }
    case 'Edit': {
      return (
        <>
          <S.DotsWrapper
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              deletable && setIsClickedDelete && setIsClickedDelete(true);
              deletable && handleChangeDialog();
            }}
            className={deletable ? 'deletable' : ''}
          >
            <DeleteBtnSVG />
          </S.DotsWrapper>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <ConfirmDeleteDialog
              onClose={() => {
                setOpenDialog(false);
                setIsClickedDelete && setIsClickedDelete(false);
              }}
              titleText={'doctor from current clinic'}
              onSuccess={handleSuccessDelete}
            />
          </Dialog>
        </>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default StaffDoctorData;
