import { SearchOperatorsForKey } from 'helpers/FilterSearch/filterSearch';

export enum NewClaimType {
  COPY = 'copy',
  PATIENT = 'from patient',
  BLANK = 'blank',
}

export enum ClaimSearchKey {
  NAME = 'name',
  ACC_NUMBER = 'accountNumber',
  PAYER_ID = 'payerId',
  INSURANCE_NAME = 'insuranceName',
}

export const searchKeys: SearchOperatorsForKey[] = [
  {
    name: 'Patient Name',
    key: ClaimSearchKey.NAME,
  },
  {
    name: 'Patient Account Number',
    key: ClaimSearchKey.ACC_NUMBER,
  },
  {
    name: 'PayerID',
    key: ClaimSearchKey.PAYER_ID,
  },
  {
    name: 'Insurance Name',
    key: ClaimSearchKey.INSURANCE_NAME,
  },
];
