import { Appointments } from '../../../types/AppointmentsTypes';
import { Note } from '../../../types/NoteTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
  pinnedCount: number;
};

export const notesApi = api.injectEndpoints({
  endpoints: builder => ({
    noteCreate: builder.mutation({
      query: data => ({
        url: '/notes',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Notes'],
    }),
    notesGetAll: builder.query<Response<Note>, any>({
      query: ({ id, ...data }) => ({
        url: `/notes?patientId=${id}`,
        // params: { sort: "isPinned", asc: "DESC", ...data },
      }),
      providesTags: ['Notes'],
    }),
    noteDelete: builder.mutation({
      query: ({ id }) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notes'],
    }),
    noteUpdate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Notes'],
    }),
  }),
});

export const {
  useNoteCreateMutation,
  useNotesGetAllQuery,
  useNoteDeleteMutation,
  useNoteUpdateMutation,
} = notesApi;
