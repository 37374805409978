import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;

  & .MuiRadio-root.Mui-checked {
    color: #0084b1;
  }
`;

export const Label = styled.div<{ $isBlack?: boolean }>`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${props =>
    props.$isBlack &&
    css`
      color: var(--text, #202e5f);
    `}
`;
