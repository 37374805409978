const breakpoints = {
  xs: '450px',
  md: '767px',
  pl: '1000px',
  xl: '1900px',
};

export const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  md: `(max-width: ${breakpoints.md})`,
  pl: `(max-width: ${breakpoints.pl})`,
  xl: `(max-width: ${breakpoints.xl})`,
};
