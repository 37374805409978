import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Font,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import FieldValue from '../DocElements/FieldValue';
import FieldWithCheckbox from '../DocElements/FieldWithCheckbox';
import dayjs from 'dayjs';
import { PagePTInsuranceType } from '../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { generateStylesConfig } from '../DocElements/generateStylesConfig';
import FieldSection from '../DocElements/FieldSection';
import FieldArticle from '../DocElements/Article';
import { PagePTInitialType } from '../../pages/PagePTInitialConsultation/PagePTInitialType';
import { Signature } from '../../../../../../../store/api/auth/authSlice';

type Props = {
  data?: any;
  signature?: Signature | null;
  setDocument?: (doc: any) => void;
};

const GeneratePTInitial: React.FC<Props> = ({ data, setDocument, signature }) => {
  const styles = generateStylesConfig();

  const GeneratePTInitialContent = () => {
    const payload: PagePTInitialType | undefined = data?.payload;

    return (
      <Document style={styles.viewer}>
        <Page size="A4" style={styles.page}>
          <FieldArticle text="Patient info" />
          <FieldSection>
            <FieldValue
              article="Diagnostis/ICD-10"
              text={payload?.patientInfo.diagnostics ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Date"
              text={payload?.patientInfo.date ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Patient name"
              text={payload?.patientInfo.patientName ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Patient`s Birth Date"
              text={
                payload?.patientInfo.birthDate
                  ? dayjs(payload?.patientInfo.birthDate).format('MM/DD/YYYY')
                  : ''
              }
              styles={styles}
              isPadding
            />
            <FieldValue
              article="CPT"
              text={payload?.patientInfo.cpt ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="History" />
          <FieldSection>
            <FieldValue
              article="Date of Onset"
              text={payload?.history.dateOfOnset ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="HPI"
              text={payload?.history.hpi ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Past Medical History"
              text={payload?.history.pastMedicalHistory ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Pain" />
          <FieldSection>
            <FieldValue
              article="Location"
              text={payload?.pain.location.lumbar ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Intensity"
              text={payload?.pain.intensity ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Frequency"
              text={payload?.pain.frequency ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Worse with"
              text={payload?.pain.worseWith ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Better with"
              text={payload?.pain.betterWith ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Prior history"
              text={payload?.pain.priorHistory ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Frequency"
              text={payload?.pain.frequency ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Exercise history"
              text={payload?.pain.exetciseHistory ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Posture"
              text={payload?.pain.postune ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="ROM"
              text={payload?.pain.rom ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="STRENGTH"
              text={payload?.pain.strength ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Reason type" />
          <FieldSection>
            <FieldValue
              article="Reason"
              text={payload?.reasonType.reason.physicalTherary ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Special tests"
              text={payload?.reasonType.specialTests ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Gait"
              text={payload?.reasonType.gait ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Palpation"
              text={payload?.reasonType.palpation ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Sensation"
              text={payload?.reasonType.sensation ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="First Treatment" />
          <FieldSection>
            <FieldValue
              article="Manual Tx"
              text={payload?.firstTreatment.manualTx ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="AROM"
              text={payload?.firstTreatment.arom.centralizng ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="STRENGTH"
              text={payload?.firstTreatment.strength ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="HEP"
              text={payload?.firstTreatment.hep.sit ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Assesment"></FieldArticle>
          <FieldSection>
            <FieldWithCheckbox
              article="Patient tolerated treatment"
              styles={styles}
              isChecked={payload && payload.assessment.treatment.isTolerated}
            />
            <FieldWithCheckbox
              article="Patient presents with the following deficits"
              styles={styles}
              isChecked={payload && payload.assessment.treatment.isDeficits}
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Pain"
              text={payload?.assessment.pain ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="ROM"
              text={payload?.assessment.pom ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Flexibility"
              text={payload?.assessment.flexibility ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="STRENGTH"
              text={payload?.assessment.strength ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Functional Deficits"
              text={payload?.assessment.funcDificits ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Plan"
              text={payload?.assessment.plan ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="SHORT TERM GOALS (2-3weeks)" />
          <FieldSection>
            <FieldValue
              article="Pain"
              text={payload?.shortTermGoals.pain ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="ROM"
              text={payload?.shortTermGoals.pom ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Flexibility"
              text={payload?.shortTermGoals.flexibility ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="STRENGTH"
              text={payload?.shortTermGoals.strength ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Functional Deficits"
              text={payload?.shortTermGoals.funcDificits ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text break></Text>
          <FieldArticle text="LONG TERM GOALS (2-3weeks)" />
          <FieldSection>
            <FieldValue
              article="Pain"
              text={payload?.longTermGoals.pain ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="ROM"
              text={payload?.longTermGoals.pom ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Flexibility"
              text={payload?.longTermGoals.flexibility ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="STRENGTH"
              text={payload?.longTermGoals.strength ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Functional Deficits"
              text={payload?.longTermGoals.funcDificits ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>

          <FieldSection>
            <FieldValue
              article="Physician"
              text={payload?.longTermGoals.physician ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="License #"
              text={payload?.longTermGoals.licence ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          {/* {signature && payload && (
            <View
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Image
                style={{
                  marginRight: 50,
                  marginTop: 10,
                  height: 25,
                  width: 100,
                }}
                // src={userInfo.signature.url}
                src={
                  "https://corsproxy.io/?" + encodeURIComponent(signature.url)
                }
              />
            </View>
          )} */}
        </Page>
      </Document>
    );
  };

  return data ? (
    <GeneratePTInitialContent />
  ) : (
    <PDFViewer style={styles.viewer}>
      <GeneratePTInitialContent />
    </PDFViewer>
  );
};
export default GeneratePTInitial;
