import { useParams } from 'react-router-dom';
import { EyeVisibleSVG } from 'assets/icons';
import { CustomPagination, CustomTable } from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useInvoicePrePaymentOneQuery } from 'store/api/invoices/invoiceApi';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { InvoicesMainType } from 'types/InvoiceType';
import InvInfPrepRow from './TableData/InvInfPrepRow';
import { useState } from 'react';

import * as S from './styles';

const headers = [
  { name: 'ID', key: 'ID' },
  { name: 'Patient Name', key: 'PatientName' },
  { name: 'Account #', key: 'Account' },
  { name: 'Claim #', key: 'Claim' },
  { name: 'Submitted on', key: 'SubmittedOn' },
  { name: 'DOS', key: 'DOS' },
  { name: 'Charged', key: 'Charged' },
  { name: 'Dr.', key: 'Dr' },
  { name: 'Employee', key: 'Employee' },
  { name: 'Network', key: 'Network' },
];

const ITEMS_PRE_PAYMENT_LIMIT = 20;

export const InvInformPrepaymentTable = () => {
  const dispatch = useAppDispatch();

  const params = useParams();

  const [page, setPage] = useState(1);

  const detailInvoice = useInvoicePrePaymentOneQuery({
    id: params.id,
    page,
    take: ITEMS_PRE_PAYMENT_LIMIT,
  });

  return (
    <S.Wrapper>
      <S.Header>
        <CustomPagination
          isFetching={detailInvoice.isFetching}
          setPage={setPage}
          currentPage={detailInvoice.currentData?.page}
          lastPage={detailInvoice.currentData?.lastPage}
          count={detailInvoice?.currentData?.count}
          text={'invoices'}
          limit={ITEMS_PRE_PAYMENT_LIMIT}
        />
        <S.ButtonShow
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.SHOW_INVOICE_HISTORY,
                props: { id: params.id, type: InvoicesMainType.PREPAYMENT },
              }),
            )
          }
        >
          <EyeVisibleSVG />
          <S.Text>Show Edit History</S.Text>
        </S.ButtonShow>
      </S.Header>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <InvInfPrepRow data={detailInvoice.currentData?.rows} headers={headers} />
        </CustomTable>
      </S.TableWrapper>
    </S.Wrapper>
  );
};
