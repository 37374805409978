import { createSlice } from '@reduxjs/toolkit';

type objectForUpdType = {
  objectForUpd: any;
};

const initialState: objectForUpdType = {
  objectForUpd: null,
};

const objectForUpdSlice = createSlice({
  name: 'objectForUpd',
  initialState,
  reducers: {
    setObjectForUpd: (state, action) => {
      return {
        ...state,
        objectForUpd: action.payload.objectForUpd,
      };
    },
  },
});

const { reducer, actions } = objectForUpdSlice;

export const { setObjectForUpd } = actions;
export default reducer;
