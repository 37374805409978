import { PlaceholderBlueIconSVG } from '../../../../../assets/icons';
import Button from '../../../../atoms/Button';
import * as S from './styles';

type Props = {
  finishClick: () => void;
};

const SuccessPlaceholder = ({ finishClick }: Props) => {
  return (
    <S.Wrapper>
      <PlaceholderBlueIconSVG />
      <S.Text>
        All steps are completed! Please press the button below to finish appointment or
        click Edit Template List to continue the process!
      </S.Text>
      <S.ButtonWrapper>
        <Button text="Finish Stepper" backgroundColor="#79A030" onClick={finishClick} />
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default SuccessPlaceholder;
