export const getTableColumnColor = (index: number) => {
  let number = (index + 1) % 4;
  switch (number) {
    case 0: {
      return '#EBF3DF';
    }
    case 1: {
      return '#FFFAE1';
    }
    case 2: {
      return '#E1EBEE';
    }
    case 3: {
      return '#F5DEDE';
    }
  }
};
