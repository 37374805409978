import { RadioButton } from '../../../../../../../../components';
import { PayerRow } from '../../../../../../../../types/Payer';
import * as S from './styles';

type Props = {
  row: PayerRow;
  selectedRow: PayerRow | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<PayerRow | null>>;
  index: number;
};

const SearchContentRow = ({ row, index, selectedRow, setSelectedRow }: Props) => {
  return (
    <S.Wrapper>
      <S.Information>
        <RadioButton
          onClick={() => row && setSelectedRow(row)}
          checked={
            selectedRow?.payerName === row.payerName &&
            selectedRow.payerName === row.payerId
              ? true
              : false
          }
        />
        <S.Text> {row?.payerId || '-'}</S.Text>
        <S.Text>{row?.payerName || '-'}</S.Text>
      </S.Information>
    </S.Wrapper>
  );
};

export default SearchContentRow;
