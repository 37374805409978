import { FormikProps } from 'formik';
import { Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  setIsDirty?: () => void;
};
const CROccupation = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Article>Occupation</S.Article>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Employer'}
            defaultValue={formik.values.occupation.employer}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'occupation.employer', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Occupation'}
            defaultValue={formik.values.occupation.occupation}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'occupation.occupation', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.Caption>How did you hear about us?</S.Caption>
        <CheckBoxWithLabel label="Doctor" />
        <CheckBoxWithLabel label="Friend" />
        <CheckBoxWithLabel label="Patient" />
        <CheckBoxWithLabel label="Facebook" />
        <CheckBoxWithLabel label="Internet" />
        <CheckBoxWithLabel label="Instagram" />
        <CheckBoxWithLabel label="Ad" />
      </S.Line>
    </>
  ),
);

export default CROccupation;
