import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  & .MuiPaper-root {
    box-shadow: none;
    border-radius: 15px;
    border: 0.5px solid var(--line, #d7d7d7);
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #f9f9fc;

    & .nested {
      border: none;
      width: 100%;
      border: 0.5px solid var(--line, #d7d7d7);
      /* &:hover {
        background: #ebf5f9;
      } */
    }
  }

  & .MuiAccordionDetails-root {
    font-size: 14px;
    /* padding: 0 12px; */
  }

  & .MuiButtonBase-root {
    min-height: 60px;
    &:hover {
      border-radius: 10px;
      background: #ebf5f9;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  gap: 20px;
`;

export const TitleCount = styled.div`
  display: flex;
  align-items: center;
`;

export const Loader = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
`;

export const TitleReason = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const NestedTitleWrapper = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NestedDate = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Btn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
