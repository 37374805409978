import { FormikProps } from 'formik';
import Input from 'components/atoms/Input';
import OptionContent from '../OptionContent';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { OptionType } from '../OptionContent/OptionContent';
import * as S from '../styles';
import { Autocomplete, TextField } from '@mui/material';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  setLastElement: any;
  data: any;
  isLoading: boolean;
  setPage: (page: number) => void;
};

export const FirstNameV2 = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      setLastElement,
      data,
      isLoading,
      setPage,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('firstName', searchValue);
      }
    }, [searchValue]);

    return (
      <>
        {byClick ? (
          <S.CompleteWrapper>
            <S.Label htmlFor="input">
              First name
              <S.RequiredSpan>*</S.RequiredSpan>
            </S.Label>
            <Autocomplete
              value={selectedValue}
              onChange={(e, newValue) => {
                setSelectedValue(newValue);
              }}
              disabled={
                !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
              }
              options={data || []}
              getOptionLabel={(option: any) =>
                !selectedValue
                  ? formik?.values?.firstName || ''
                  : option && option?.profile?.firstName
              }
              filterOptions={x => x}
              renderOption={(props: any, option: any) => {
                const refLast =
                  data?.length === props['data-option-index'] + 1 ? setLastElement : null;

                return (
                  <OptionContent
                    searchValue={searchValue}
                    key={option.id}
                    textToHighlight={option.profile?.firstName || ''}
                    props={props}
                    type={OptionType.FIRSTNAME}
                    option={option}
                    loading={isLoading}
                    // @ts-ignore
                    ref={refLast}
                  />
                );
              }}
              inputValue={searchValue}
              onInputChange={(e, newInputValue) => {
                setPage(1);
                setSearchValue(newInputValue);
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  placeholder="Enter a search value"
                  name="firstName"
                  key={params.id}
                  error={!!formik.errors.firstName}
                  helperText={formik.errors.firstName as string}
                  FormHelperTextProps={{
                    focused: false,
                  }}
                  {...params}
                  InputProps={{
                    ...params?.InputProps,
                    style: {
                      borderColor: formik.errors.firstName ? 'red' : '',
                    },
                  }}
                />
              )}
            />
          </S.CompleteWrapper>
        ) : (
          <Input
            label="First name"
            id="firstName"
            isRequired
            name="firstName"
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName as string}
            value={formik.values.firstName}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.firstName ||
              !!selectedAppointment?.patient?.profile?.firstName ||
              !!duplAppointment?.patient?.profile?.firstName
            }
          />
        )}
      </>
    );
  },
);
