import { PlaceholderAttentionSVG } from '../../../../../../assets/icons';
import * as S from './styles';

type Props = {
  type: string;
};

const TicketsPlaceholder = ({ type }: Props) => {
  return (
    <S.Wrapper>
      <PlaceholderAttentionSVG />
      {type === 'incoming' ? (
        <S.HelperText>No tickets</S.HelperText>
      ) : (
        <S.HelperText>
          No new outgoing tickets. Please, press <S.Span>‘Make a ticket’</S.Span> in menu
          on the left to create a new ticket
        </S.HelperText>
      )}
    </S.Wrapper>
  );
};

export default TicketsPlaceholder;
