import { useEffect, useState } from 'react';
import { Dialog, SuccessDialog } from '../components';

type Props = {
  handleSubmit: () => void;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const useUnsavedChangesWarning = ({ handleSubmit, isDirty, setIsDirty }: Props) => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ''; // required for Chrome
        setShowDialog(true);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    setIsDirty(false);
    setShowDialog(false);
    handleSubmit();
  };

  const handleLeavePage = () => {
    if (isDirty) {
      setShowDialog(true);
    }
  };

  return [
    () => (
      <Dialog open={showDialog} onClose={handleDialogClose}>
        <SuccessDialog
          rejectText="Discard changes"
          successText="Save and leave"
          titleText={'You have unsaved changes!'}
          onClose={handleDialogClose}
          onSuccess={handleDialogConfirm}
        />
      </Dialog>
    ),
    handleLeavePage,
  ];
};

export default useUnsavedChangesWarning;
