import dayjs from 'dayjs';
import { RadioButton } from '../../../../../../../../components';
import { ucFirst } from '../../../../../../../../helpers/functions/toUpperCase';
import { Profile } from '../../../../../../../../types/ProfileTypes';
import { Roles } from '../../../../../../../../types/Roles';
import * as S from './styles';

type Row = {
  id: string;
  profile?: Profile;
  role: Roles;
  phone: string;
};

type Props = {
  row: Row;
  selectedRow?: Row | null;
  setSelectedRow?: any;
  isTable: boolean;
};

const SearchContentRow: React.FC<Props> = ({
  row,
  selectedRow,
  setSelectedRow,
  isTable,
}) => {
  return (
    <S.Wrapper>
      <S.Information>
        {isTable && (
          <RadioButton
            onClick={() => setSelectedRow(row)}
            checked={selectedRow?.id === row.id ? true : false}
          />
        )}
        <S.Text> {row.profile?.firstName + ' ' + row.profile?.lastName}</S.Text>
        <S.Text>{row.profile?.sex ? ucFirst(row.profile.sex) : '-'}</S.Text>
        <S.Text>{row.phone || '-'}</S.Text>
        <S.Text>
          {row.profile?.dateOfBirth
            ? dayjs(row.profile?.dateOfBirth).format('MM/DD/YYYY')
            : '-'}
        </S.Text>
      </S.Information>
    </S.Wrapper>
  );
};

export default SearchContentRow;
