import { FormikProps } from 'formik';
import { Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { PageACAInsuranceType } from '../../../pages/PageACAInsurance/PageACAInsuranceType';

type Props = {
  formik: FormikProps<PageACAInsuranceType>;
  setIsDirty?: () => void;
};
const Deductible = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Caption>3. What is the deductible?</S.Caption>
      <S.Block>
        <S.BlockColumn>
          <S.Article>In-Net</S.Article>
          <S.Block>
            <S.InputWithText>
              <S.Text>Met</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.deductible.inNet.met}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'deductible.inNet.met', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
            <S.InputWithText>
              <S.Text>Total</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.deductible.inNet.total}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'deductible.inNet.total', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
          </S.Block>
        </S.BlockColumn>
        <S.BlockColumn>
          <S.Article>Out-Net</S.Article>
          <S.Block>
            <S.InputWithText>
              <S.Text>Met</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.deductible.outNet.met}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'deductible.outNet.met', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
            <S.InputWithText>
              <S.Text>Total</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.deductible.outNet.total}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'deductible.outNet.total', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
          </S.Block>
        </S.BlockColumn>
      </S.Block>
      <S.Caption>What is out of pocket limit?</S.Caption>
      <S.Block>
        <S.BlockColumn>
          <S.Block>
            <S.InputWithText>
              <S.Text>In</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.pocketLimit.inStart}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'pocketLimit.inStart', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
              <BoldLineSVG />
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.pocketLimit.inEnd}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'pocketLimit.inEnd', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
          </S.Block>
        </S.BlockColumn>
        <S.BlockColumn>
          <S.Block>
            <S.InputWithText>
              <S.Text>Out</S.Text>
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.pocketLimit.outStart}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'pocketLimit.outStart', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
              <BoldLineSVG />
              <S.InputWrapper>
                <Input
                  isSum="$"
                  type="number"
                  defaultValue={formik.values?.pocketLimit.outEnd}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeInputRef(e, 'pocketLimit.outEnd', ref, setIsDirty)
                  }
                />
              </S.InputWrapper>
            </S.InputWithText>
          </S.Block>
        </S.BlockColumn>
      </S.Block>
    </>
  ),
);

export default Deductible;
