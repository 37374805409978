import { FormikProps } from 'formik';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { AutoCompleteDatePicker } from 'components/atoms/AutoComplete';
import Input from 'components/atoms/Input';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  error?: string;
  setLastElement: any;
  data: any;
  isLoading: boolean;
  setPage: (page: number) => void;
  emptyDatePicker?: boolean;
  setEmptyDatePicker: (emptyDatePicker: boolean) => void;
};

export const DOBV2 = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      setLastElement,
      data,
      isLoading,
      setPage,
      emptyDatePicker,
      setEmptyDatePicker,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('dateOfBirth', searchValue);
      }
    }, [searchValue]);

    useEffect(() => {
      if (emptyDatePicker) {
        setSearchValue('');
        setEmptyDatePicker(false);
      }
    }, [emptyDatePicker]);

    return (
      <>
        {byClick ? (
          <AutoCompleteDatePicker
            mainLabel={'DOB'}
            isRequired
            data={data || []}
            loading={isLoading}
            searchValue={
              formik.values.dateOfBirth ? formik.values.dateOfBirth : searchValue
            }
            disabled={
              !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
            }
            selectedValue={selectedValue}
            setSearchValue={setSearchValue}
            setSelectedValue={setSelectedValue}
            error={!!formik.errors.dateOfBirth}
            helperText={formik.errors.dateOfBirth as string}
            setLastElement={setLastElement}
            setPage={setPage}
          />
        ) : (
          <Input
            label="DOB"
            id="dateOfBirth"
            name="dateOfBirth"
            error={!!formik.errors.dateOfBirth}
            helperText={formik.errors.dateOfBirth as string}
            value={dayjs(formik.values.dateOfBirth).format('MM/DD/YYYY') || ''}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.dateOfBirth ||
              !!selectedAppointment?.patient?.profile?.dateOfBirth ||
              !!duplAppointment?.patient?.profile?.dateOfBirth ||
              !!selectedValue
            }
          />
        )}
      </>
    );
  },
);
