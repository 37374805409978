import styled from '@emotion/styled';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const WrapperHistory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const WrapperHistoryBilling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 6px;
  width: 502px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const Line = styled.div`
  width: 501px;
  height: 1px;
  background: #d7d7d7;
`;

export const Side = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const Text = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 5px;
`;

export const HelperText = styled.div`
  color: var(--icon, #dd8500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;
