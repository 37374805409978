import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { CellPdf } from './components';
import { generateStylesConfig } from './generateStylesConfig';
import { ServiceInfoFormType } from 'types/AppointmentNewTypes';
import dayjs from 'dayjs';

type Props = {
  index: number;
  row?: ServiceInfoFormType | null;
};

export const ServiceRow = ({ index, row }: Props) => {
  const s = generateStylesConfig();

  return (
    <View style={s.row}>
      <View style={s.column}>
        <Text style={[s.subHeader, { marginLeft: -12, paddingRight: 2, fontSize: 10 }]}>
          {index}
        </Text>
      </View>
      <View style={s.redRow}>
        <View style={[s.column, { alignItems: 'flex-start' }]}>
          <View
            style={[
              s.row,
              {
                height: 12.5,
                backgroundColor: '#FFDCCD',
                width: 58,
              },
            ]}
          />
          <View style={[s.row, { height: 12.5 }]}>
            <Text style={[s.text, { width: 16, padding: '0 0 0 2.5' }]}>
              {row?.dateFrom ? dayjs(row?.dateFrom).format('MM') : ''}
            </Text>
            <View style={[s.verticalBorder, { height: 12.5 }]} />
            <Text style={[s.text, { width: 14 }]}>
              {row?.dateFrom ? dayjs(row?.dateFrom).format('DD') : ''}
            </Text>
            <View style={[s.verticalBorder, { height: 12.5 }]} />
            <Text style={[s.text, { width: 18 }]}>
              {row?.dateFrom ? dayjs(row?.dateFrom).format('YY') : ''}
            </Text>
          </View>
        </View>

        <CellPdf width={58}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 58,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.text, { width: 16, padding: '0 0 0 2.5' }]}>
                {row?.dateTo ? dayjs(row?.dateTo).format('MM') : ''}
              </Text>
              <View style={[s.verticalBorder, { height: 12.5 }]} />
              <Text style={[s.text, { width: 14 }]}>
                {row?.dateTo ? dayjs(row?.dateTo).format('DD') : ''}
              </Text>
              <View style={[s.verticalBorder, { height: 12.5 }]} />
              <Text style={[s.text, { width: 18 }]}>
                {row?.dateTo ? dayjs(row?.dateTo).format('YY') : ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={25}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 25,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.text, { width: 25, padding: '0 0 0 2.5' }]}>
                {row?.placeOfService ?? ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={25}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 25,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.text, { width: 25, padding: '0 0 0 2.5' }]}>
                {row?.emg ?? ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={144}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 144,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.text, { width: 40, padding: '0 0 0 2.5' }]}>
                {row?.cpt ?? ''}
              </Text>
              <View style={[s.verticalBorder, { height: 12.5, borderStyle: 'solid' }]} />
              <View style={[s.row, { height: 12.5 }]}>
                <Text style={[s.text, { width: 20, padding: '0 0 0 1' }]}>
                  {row?.modifier?.at(0) ?? ''}
                </Text>
                <View style={[s.verticalBorder, { height: 12.5 }]} />
                <Text style={[s.text, { width: 20, padding: '0 0 0 1' }]}>
                  {row?.modifier?.at(1) ?? ''}
                </Text>
                <View style={[s.verticalBorder, { height: 12.5 }]} />
                <Text style={[s.text, { width: 20, padding: '0 0 0 1' }]}>
                  {row?.modifier?.at(2) ?? ''}
                </Text>
                <View style={[s.verticalBorder, { height: 12.5 }]} />
                <Text style={[s.text, { width: 20, padding: '0 0 0 1' }]}>
                  {row?.modifier?.at(3) ?? ''}
                </Text>
              </View>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={45}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 45,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5 }]}>
              <Text style={[s.text, { width: 45, padding: '0 0 0 2.5' }]}></Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={57}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 57,
                },
              ]}
            />
            <View
              style={[s.row, { height: 12.5, justifyContent: 'flex-end', width: 57 }]}
            >
              <Text style={[s.text]}>
                {row?.charges ? '$' + Math.trunc(+row?.charges) : ''}
              </Text>
              <View style={[s.verticalBorder, { height: 12.5 }]} />
              <Text style={[s.text, { width: 18, padding: '0 0 0 1' }]}>
                {row?.charges
                  ? '.' + (row?.charges?.toString().split('.')[1] ?? '00')
                  : ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={45}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 45,
                },
              ]}
            />
            <View
              style={[s.row, { height: 12.5, justifyContent: 'flex-end', width: 45 }]}
            >
              <Text style={[s.text, { width: 45, textAlign: 'right', paddingRight: 5 }]}>
                {row?.units ?? ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={45}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 45,
                },
              ]}
            />
            <View
              style={[s.row, { height: 12.5, justifyContent: 'flex-end', width: 45 }]}
            >
              <Text style={[s.text, { width: 45, textAlign: 'right', paddingRight: 5 }]}>
                {row?.epsdt ?? ''}
              </Text>
            </View>
          </View>
        </CellPdf>

        <CellPdf width={57}>
          <View style={[s.column, { alignItems: 'flex-start' }]}>
            <View
              style={[
                s.row,
                {
                  height: 12.5,
                  backgroundColor: '#FFDCCD',
                  width: 57,
                },
              ]}
            />
            <View style={[s.row, { height: 12.5, justifyContent: 'center', width: 57 }]}>
              <Text
                style={[s.title, { width: 57, textAlign: 'center', paddingRight: 5 }]}
              >
                {row?.idQual ?? ''}
              </Text>
            </View>
          </View>
        </CellPdf>
      </View>
    </View>
  );
};
