import styled from 'styled-components';
import { devices } from 'constants/device';
import { Link } from 'react-router-dom';

export const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 430px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-top: 23px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CheckBoxLabel = styled.div`
  color: var(--text, #3c3c60);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledLink = styled(Link)`
  color: #0084b1;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
`;

export const ButtonWrapper = styled.div`
  width: 174px;

  @media ${devices.xs} {
    width: 300px;
  }
`;
