import { useAppSelector } from 'hooks/useAppSelector';
import { Relation } from 'types/enums/AppointmentEnum';
import { InsuranceInUserProfileType } from 'types/InsuranceType';
import { Roles } from 'types/Roles';
import InsuredList from '../../../InsuranceInformation/components/InsuredList';
import RelatedRow from '../../../InsuranceInformation/components/RelatedRow';
import * as S from '../../styles';
import ItemValue from '../ItemValue';
import PhotoInsurancesBlock from '../PhotoInsurancesBlock';
import dayjs from 'dayjs';
import CoveredServices from '../CoveredServices';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import { AddCoveredServicesDialog } from '../AddCoveredServiceDialog';
import { AddCircleBlueSVG } from 'assets/icons';
import { GroupNumberInfo } from './components';

type Props = {
  row?: InsuranceInUserProfileType;
};

const InsuranceItemBlock = ({ row }: Props) => {
  const userInfo = useAppSelector(state => state.auth);

  const [isOpenAddCoveredServiceDialog, setIsOpenAddCoveredServiceDialog] =
    useState(false);

  return (
    <S.InsuranceItemWrapper>
      <Dialog
        open={isOpenAddCoveredServiceDialog}
        onClose={() => setIsOpenAddCoveredServiceDialog(false)}
      >
        <AddCoveredServicesDialog
          insuranceGroupNumber={row?.insuranceTemplate?.insuranceGroupNumbers?.at(0)}
          onClose={() => setIsOpenAddCoveredServiceDialog(false)}
        />
      </Dialog>
      <S.Line>
        <ItemValue
          article="Effective Start Date"
          value={
            row?.effectiveStartDate && dayjs(row.effectiveStartDate).format('MM/DD/YYYY')
          }
        />
        <ItemValue
          article="Effective Stop Date"
          value={
            row?.effectiveStopDate && dayjs(row.effectiveStopDate).format('MM/DD/YYYY')
          }
        />
      </S.Line>
      <S.Line>
        <ItemValue article="PayerId" value={row?.insuranceTemplate?.payerId} />
        <ItemValue article="Insurance Company" value={row?.primaryInsuranceCompany} />
        <ItemValue article="Company phone" value={row?.companyPhone} />
        <ItemValue article="Insured/ Subscriber Name" value={row?.subscriberName} />
        <ItemValue article="Relationship to Patient" value={row?.relation} />
        {/*<ItemValue article="Insured SSN" value={row?.ssn} />*/}
        <ItemValue article="ID" value={row?.insuranceId} />
        <ItemValue
          article="Group #"
          value={row?.insuranceTemplate?.insuranceGroupNumbers
            ?.map(i => i?.groupNumber)
            ?.join(', ')}
        />
        <ItemValue article="Group name" value={row?.groupName} />
        {row?.insuranceTemplate?.insuranceGroupNumbers?.length ? (
          <GroupNumberInfo
            insuranceGroupNumber={row?.insuranceTemplate?.insuranceGroupNumbers[0]}
          />
        ) : null}
      </S.Line>
      <PhotoInsurancesBlock row={row} />
      {userInfo.role !== Roles.PATIENT && row && row.relation !== Relation.SELF && (
        <S.BlockContent>
          <InsuredList label="Insured list for insurance" insuranceId={row?.id} />
          <S.InsuredListWrapper>
            {row &&
              row.related?.map(item => {
                return <RelatedRow key={item.id} row={item} />;
              })}
          </S.InsuredListWrapper>
        </S.BlockContent>
      )}

      {!!row?.insuranceTemplate?.insuranceGroupNumbers?.at(0)?.id && (
        <S.ArticleSmallWrapper className="sub">
          Covered services
          <S.ShowBtnsLogs onClick={() => setIsOpenAddCoveredServiceDialog(true)}>
            <AddCircleBlueSVG />
            Add service
          </S.ShowBtnsLogs>
        </S.ArticleSmallWrapper>
      )}
      {row?.insuranceTemplate?.insuranceGroupNumbers
        ?.at(0)
        ?.coveredServices?.map(item => {
          return (
            <CoveredServices
              key={item.id}
              service={item}
              insuranceGroupNumber={row?.insuranceTemplate?.insuranceGroupNumbers?.at(0)}
            />
          );
        })}
    </S.InsuranceItemWrapper>
  );
};

export default InsuranceItemBlock;
