export const hsl2hex = (h?: number, l: number = 0.5) => {
  if (!h) {
    return '#ff0000';
  }

  const s = 1;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  const rgb = [f(0), f(8), f(4)];

  return (
    '#' +
    rgb
      .map(x =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, '0'),
      )
      .join('')
  );
};

export const hex2hsl = (hex?: string | null) => {
  if (!hex) {
    return;
  }

  const parseData = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const r = parseData ? parseInt(parseData[1], 16) : 0;
  const g = parseData ? parseInt(parseData[2], 16) : 0;
  const b = parseData ? parseInt(parseData[3], 16) : 0;

  const v = Math.max(r, g, b);
  const c = v - Math.min(r, g, b);

  const min = Math.min(r, g, b) / 255;
  const max = Math.max(r, g, b) / 255;
  const l = Math.round((max + min) * 50) / 100;

  const h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
  return { h: 60 * (h < 0 ? h + 6 : h), l };
};
