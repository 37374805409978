import { useDispatch } from 'react-redux';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const coupounsApi = api.injectEndpoints({
  endpoints: builder => ({
    couponsGet: builder.query<Response<any>, any>({
      query: params => ({
        url: `/coupon`,
        params: { ...params },
      }),
      providesTags: ['Coupons'],
    }),
    couponSourceGet: builder.query<any, any>({
      query: params => ({
        url: `/coupon/source`,
        params: { ...params },
      }),
      providesTags: ['Coupons'],
    }),
    couponCheck: builder.mutation({
      query: params => ({
        url: `/coupon/check`,
        params: { ...params },
        method: 'GET',
      }),
      invalidatesTags: ['Coupons'],
    }),
    couponReport: builder.mutation({
      query({ id, ...params }) {
        return {
          url: `/coupon/${id}/report`,
          method: 'GET',
          responseHandler: async response => {
            if (response.status === 200) {
              return window.location.assign(
                window.URL.createObjectURL(await response.blob()),
              );
            }
          },
          params: { ...params },
          cache: 'no-cache',
        };
      },
    }),
    couponCreate: builder.mutation({
      query: body => {
        const newData = new FormData();
        body.file && newData.append('file', body.file);
        newData.append('title', body.title);
        newData.append('validFrom', body.validFrom);
        newData.append('validTo', body.validTo);
        newData.append('source', body.source);
        newData.append('benefit', body.benefit);
        newData.append('clinicId', body.clinicId);
        return {
          url: `/coupon`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Coupons' }],
    }),
    couponUpdate: builder.mutation({
      query: body => {
        const newData = new FormData();
        body.file && newData.append('file', body.file);
        newData.append('title', body.title);
        newData.append('validFrom', body.validFrom);
        newData.append('validTo', body.validTo);
        newData.append('source', body.source);
        newData.append('benefit', body.benefit);
        newData.append('clinicId', body.clinicId);
        return {
          url: `/coupon/${body.id}`,
          method: 'PUT',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Coupons' }],
    }),
  }),
});

export const {
  useCouponsGetQuery,
  useCouponReportMutation,
  useCouponSourceGetQuery,
  useCouponCreateMutation,
  useCouponUpdateMutation,
  useCouponCheckMutation,
} = coupounsApi;
