import { AvatarSVG } from '../../../../assets/icons';
import { MailConfirmation, MailConfirmationLite } from '../../../../types/MainTypes';
import CheckBox from '../../../atoms/CheckBox';
import * as S from './styles';

type Props = {
  mail: MailConfirmationLite;
  onChangeCheckbox: () => void;
};

const MailCard: React.FC<Props> = ({ mail, onChangeCheckbox }) => {
  const { isNeedToNotify, isNotified } = mail;

  return (
    <S.Wrapper $isChecked={isNeedToNotify} $isSend={isNotified}>
      <S.Content>
        <S.LeftBlock>
          <S.UsernameWrapper>
            <S.Avatar>
              {mail.event?.patient?.avatar?.url ? (
                <img src={mail.event.patient.avatar.url} alt="avatar" />
              ) : (
                <AvatarSVG />
              )}
            </S.Avatar>
            <S.Username>
              {mail.event?.patient?.profile?.firstName +
                ' ' +
                mail.event?.patient?.profile?.lastName}
            </S.Username>
          </S.UsernameWrapper>
          <S.SentThrough>by Email</S.SentThrough>
        </S.LeftBlock>
        <S.RightBlock>
          <CheckBox
            className="big"
            checked={isNeedToNotify}
            onChange={onChangeCheckbox}
            value={isNeedToNotify}
          />
        </S.RightBlock>
      </S.Content>
    </S.Wrapper>
  );
};

export default MailCard;
