import { useEffect, useState } from 'react';
import {
  ApproveBtnSVG,
  DeleteRedBtnSVG,
  EditBtnCurrentColorSVG,
  ResetApproveBtnSVG,
} from '../../../../../../../../assets/icons';
import { CheckBox } from '../../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import { UserProfile } from '../../../../../../../../types/UserProfileTypes';
import * as S from '../../styles';
import { useUpdateCommonInformationMutation } from '../../../../../../../../store/api/user/profileApi';
import { setMessage } from '../../../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';

type Props = {
  rows: UserProfile | undefined;
};

const CommonInformationBlock = ({ rows }: Props) => {
  const dispatch = useAppDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [isWorkersCompensation, setIsWorkersCompensation] = useState(
    rows?.profile?.isWorkersCompensation,
  );
  const [isNoFault, setIsNoFault] = useState(rows?.profile?.isNoFault);

  const [updateCommonInformation] = useUpdateCommonInformationMutation({});

  useEffect(() => {
    setIsNoFault(rows?.profile?.isNoFault);
    setIsWorkersCompensation(rows?.profile.isWorkersCompensation);
  }, [rows]);

  return (
    <S.CommonInformationWrapper>
      <S.ArticleSmallWrapper>
        <S.Btns>
          Common information
          {isEditable ? (
            <S.ButtonsEdit>
              <ResetApproveBtnSVG
                onClick={() => {
                  setIsEditable(false);
                  setIsNoFault(rows?.profile?.isNoFault);
                  setIsWorkersCompensation(rows?.profile?.isWorkersCompensation);
                }}
              />
              <ApproveBtnSVG
                onClick={() => {
                  updateCommonInformation({
                    id: rows?.id,
                    isNoFault,
                    isWorkersCompensation,
                  })
                    .unwrap()
                    .then(res => {
                      dispatch(
                        setMessage({
                          message: 'Common information was updated',
                          type: 'success',
                        }),
                      );
                    })
                    .catch(error => {
                      dispatch(
                        setMessage({
                          message: error.data.message,
                          type: 'error',
                        }),
                      );
                    });
                  setIsEditable(false);
                }}
              />
            </S.ButtonsEdit>
          ) : (
            <EditBtnCurrentColorSVG
              color="#848A9B"
              onClick={e => {
                setIsEditable(true);
                e.stopPropagation();
              }}
            />
          )}
        </S.Btns>
      </S.ArticleSmallWrapper>
      <S.CheckBoxWrapper>
        <CheckBox
          disabled={!isEditable}
          id="isNoFault"
          name="isNoFault"
          value={isNoFault}
          checked={isNoFault}
          onChange={() => setIsNoFault(!isNoFault)}
        />
        <S.Label>Is this insurance a No-Fault insurance?</S.Label>
      </S.CheckBoxWrapper>
      <S.CheckBoxWrapper>
        <CheckBox
          disabled={!isEditable}
          id="isWorkersCompensation"
          name="isWorkersCompensation"
          value={isWorkersCompensation}
          checked={isWorkersCompensation}
          onChange={() => setIsWorkersCompensation(!isWorkersCompensation)}
        />
        <S.Label>Is this insurance Workers' Compensation Insurance?</S.Label>
      </S.CheckBoxWrapper>
    </S.CommonInformationWrapper>
  );
};

export default CommonInformationBlock;
