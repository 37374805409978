import { forwardRef, useEffect } from 'react';
import { Input } from '../../../../../../components';
import CheckBoxWithLabel from '../../../../../../components/atoms/CheckboxWithLabel';
import CoInsurancePolicy from './components/CoInsurancePolicy';
import Deductible from './components/Deductible';
import PreviousPT from './components/PreviousPT';
import * as S from './styles';
import { AppointmentsNew, Step } from '../../../../../../types/AppointmentNewTypes';
import { PageACAInsuranceType } from '../pages/PageACAInsurance/PageACAInsuranceType';
import { FormikProps } from 'formik';
import ACAInsuranceUserBlock from './components/ACAInsuranceUserBlock';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setIsDirty } from '../../../../../../store/slices/isDirtyFormSlice';
import { setObjectForUpd } from '../../../../../../store/slices/formikForUpdateStepperSlice';
import { updateFormikValues } from '../../../../../../helpers/FormikFuncs/updateFormikValues';
import { RefType } from '../../../../../../types/Roles';

type Props = {
  formik: FormikProps<PageACAInsuranceType>;
  isFromStepper?: boolean;
  stepperValues?: Step;
  digitalFormId?: number;
  appointmentInformation?: AppointmentsNew;
  showDialog?: () => void;
  // ref: React.MutableRefObject<{}>;
};
const ACAInsurance = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      isFromStepper,
      stepperValues,
      appointmentInformation,
      digitalFormId,
      showDialog,
    },
    ref,
  ) => {
    const dispatch = useAppDispatch();

    const deleteNames = (obj: any) => {
      delete obj?.firstName;
      delete obj?.lastName;
      delete obj?.userId;
      delete obj?.dateOfBirth;
      return obj;
    };

    const handleDirty = () => {
      if (stepperValues) {
        const updatedFormikValues = updateFormikValues(
          { ...deleteNames(formik.values) },
          (ref as RefType)?.current,
        );

        dispatch(
          setIsDirty({
            isDirty: true,
            isShowDialog: false,
            action: () => {},
            objectForUpd: updatedFormikValues,
          }),
        );
      }
    };

    return (
      <>
        <S.Wrapper>
          <S.Inputs>
            <ACAInsuranceUserBlock
              formik={formik}
              ref={ref}
              appointmentInformation={appointmentInformation}
              setIsDirty={handleDirty}
            />
            <PreviousPT ref={ref} formik={formik} setIsDirty={handleDirty} />
            <Deductible ref={ref} formik={formik} setIsDirty={handleDirty} />
            <CoInsurancePolicy ref={ref} formik={formik} setIsDirty={handleDirty} />
          </S.Inputs>
        </S.Wrapper>
      </>
    );
  },
);

export default ACAInsurance;
