import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftSVG, PrintWhiteSVG } from 'assets/icons';
import * as S from './styles';
import {
  useInvoiceGetByIdQuery,
  useInvoiceGetPdfMutation,
} from 'store/api/invoices/invoiceApi';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { Button, CircularLoader, LinearLoader, UserStatusPin } from 'components';
import InvPatientInformation from './components/InvPatientInformation';
import InvInformationTable from './components/InvInformationTable';
import { useAppSelector } from 'hooks/useAppSelector';

const InvoiceDetail = () => {
  const navigate = useNavigate();

  const params = useParams();

  const detailInvoiceUser = useInvoiceGetByIdQuery({ id: params.invoiceId });

  const [getPdf, loadingPdfStatus] = useInvoiceGetPdfMutation();
  const { claimAccessLevel } = useAppSelector(state => state.auth);

  return (
    <S.Wrapper>
      {detailInvoiceUser.isFetching || detailInvoiceUser.isLoading ? (
        <LinearLoader />
      ) : (
        <>
          <S.SubtitleBar>
            <S.SubtitleText onClick={() => navigate('/invoices')}>
              <ArrowLeftSVG /> Invoice {params.invoiceId}
              <S.InvoiceTypeSpan>•</S.InvoiceTypeSpan>
              <S.InvoiceTypeSpan>
                {detailInvoiceUser.currentData &&
                  ucFirst(detailInvoiceUser.currentData?.type)}
              </S.InvoiceTypeSpan>
              <S.InvoiceTypeSpan>
                {detailInvoiceUser.currentData?.status && (
                  <UserStatusPin type={detailInvoiceUser.currentData?.status} />
                )}
              </S.InvoiceTypeSpan>
            </S.SubtitleText>
            <S.SubtitleItems>
              {loadingPdfStatus.isLoading && (
                <S.CircularWrapper>
                  <CircularLoader color="#0084B1" size={20} />
                </S.CircularWrapper>
              )}
              <S.ButtonWrapper>
                <Button
                  text="Print"
                  backgroundColor="#848A9B"
                  onClick={() => {
                    getPdf({
                      id: params.invoiceId,
                      type: detailInvoiceUser.currentData?.type,
                    })
                      .unwrap()
                      .then((res: any) => {})
                      .catch(err => console.log(err, 'rrr'));
                  }}
                >
                  <PrintWhiteSVG />
                </Button>
              </S.ButtonWrapper>
              {/* {detailInvoiceUser.currentData?.status !== "close" &&
                claimAccessLevel === AccessLevel.WRITE && (
                  <S.ButtonWrapper>
                    <Button text="Create Claim">
                      <ClaimsWhiteSVG />
                    </Button>
                  </S.ButtonWrapper>
                )} */}
            </S.SubtitleItems>
          </S.SubtitleBar>
          <S.ContentWrapper>
            <S.Content>
              <InvPatientInformation patient={detailInvoiceUser.currentData?.patient} />
              <InvInformationTable />
            </S.Content>
          </S.ContentWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default InvoiceDetail;
