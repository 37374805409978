import * as S from './styles';
import { ServiceType } from 'types/ServicesType';

type Props = {
  isFirstTimeVisit?: boolean;
  service?: ServiceType;
};

const StatusPin: React.FC<Props> = ({ isFirstTimeVisit = false, service }) => {
  const background = '#ffdbb0';
  const border = '#d17e00';
  const font = '#D17E00';
  const text = 'ACA';

  return service ? (
    <S.StatusPinWrapper
      border={'#848A9B'}
      background={
        isFirstTimeVisit && service.isUseForFirstTimeVisit && service.firstVisitColor
          ? service.firstVisitColor
          : (service?.color ?? '')
      }
      font={'#202e5f'}
    >
      {service.serviceName}
    </S.StatusPinWrapper>
  ) : (
    <S.StatusPinWrapper border={border} background={background} font={font}>
      {text}
    </S.StatusPinWrapper>
  );
};

export default StatusPin;
