import { FormikProps } from 'formik';
import { Input } from '../../../../../../components';
import * as S from '../../styles';
import { ChangeEvent } from 'react';
import { UserCred } from '../../../../../../store/api/auth/authSlice';
import ReactInputMask from 'react-input-mask';
import { AppointmentFormValues } from '../../../../../../components/organismus/AppointmentDrawer/AppointmentDrawer';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userInfo: UserCred;
};
const AuthWithoutRegPatientInformation: React.FC<Props> = ({ formik, userInfo }) => {
  const { values, errors } = formik;

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };
  return (
    <>
      <S.PatientInformation>
        <S.SubArticle>Patient information</S.SubArticle>

        <S.PatientInputs>
          <S.InputRow className="devide">
            <Input
              id="firstName"
              name="firstName"
              label="First name"
              isRequired
              disabled={userInfo.firstName ? true : false}
              error={!!errors.firstName}
              helperText={errors.firstName}
              onChange={handleChangeInput}
              value={values.firstName}
            />
            <Input
              label="Last name"
              isRequired
              id="lastName"
              disabled={userInfo.lastName ? true : false}
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName}
              value={values.lastName}
              onChange={handleChangeInput}
            />
          </S.InputRow>
          <S.InputRow>
            <ReactInputMask
              mask="+19999999999"
              value={values.phone}
              onChange={handleChangeInput}
              disabled={userInfo.phone ? true : false}
            >
              <Input
                label="Phone"
                isRequired
                id="phone"
                name="phone"
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </ReactInputMask>
            <Input
              label="Email"
              id="email"
              isRequired
              disabled={userInfo.email ? true : false}
              name="email"
              error={!!errors.email}
              helperText={errors.email}
              value={values.email}
              onChange={handleChangeInput}
            />
          </S.InputRow>
        </S.PatientInputs>
      </S.PatientInformation>
    </>
  );
};

export default AuthWithoutRegPatientInformation;
