import { devices } from '../../../../../constants/device';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const AppointmentInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;
  margin-top: 15px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;

  & svg {
    position: absolute;
  }
`;

export const SelectInputOutputText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SelectInputWrap = styled.div`
  width: 240px;
`;

export const RequiredSpan = styled.span`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  gap: 5px;
  min-width: 48%;
  max-width: 48%;
`;

export const VisitReasons = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const Label = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CheckboxWrapper = styled.div<{
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 7px;

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const HelperText = styled.div`
  color: var(--icon, #dd8500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const Text = styled.div<{ color: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${props => props.color && props.color};
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  width: 100%;
`;
