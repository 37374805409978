import { Roles } from 'types/Roles';

export const getRoleName = (str?: Roles) => {
  switch (str) {
    case Roles.BILLING:
      return 'Billing specialist';
    case Roles.LOCATION_MANAGER:
      return 'Location manager';
    case Roles.DOCTOR:
      return 'Doctor';
    case Roles.PATIENT:
      return 'Patient';
    case Roles.RECEPTIONIST:
      return 'Receptionist';
    case Roles.SUPER_ADMIN:
      return 'Super admin';
    case Roles.UNAUTH:
      return 'Unauthorized';
    default:
      return '';
  }
};
