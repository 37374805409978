import { Fade, TableCell, TableRow, Tooltip } from '@mui/material';
import {
  AccTableClinicsType,
  BillingAccTableType,
} from '../../../../../../../../types/AccTableTypes';
import * as S from '../../styles';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const AccTableHeadClinicNames: React.FC<Props> = ({ data, index }) => {
  return (
    <TableRow>
      {index === 0 && (
        <TableCell className="white white-header white-header-top"></TableCell>
      )}
      {data.clinics.map((clinic, index: number) => {
        return (
          <Tooltip
            key={clinic.clinicName + index}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#0084B1',
                  fontFamily: 'Inter',
                },
              },
            }}
            title={clinic.clinicName}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TableCell
              className="clinics"
              colSpan={clinic.outColumnCount + clinic.inColumnCount}
            >
              {clinic.clinicName}
            </TableCell>
          </Tooltip>
        );
      })}
    </TableRow>
  );
};

export default AccTableHeadClinicNames;
