import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftSVG, PrintWhiteSVG } from 'assets/icons';
import * as S from './styles';
import { useInvoiceGetPdfMutation } from 'store/api/invoices/invoiceApi';
import { Button, CircularLoader } from 'components';
import { InvInformPrepaymentTable } from './components/InvInformPrepaymentTable';
import { InvoicesMainType } from 'types/InvoiceType';

const InvoicePrepaymentDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [getPdf, loadingPdfStatus] = useInvoiceGetPdfMutation();

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate('/invoices/pre-payment')}>
          <ArrowLeftSVG /> Invoice {params.id}
          <S.InvoiceTypeSpan>•</S.InvoiceTypeSpan>
          <S.InvoiceTypeSpan>Pre-payment</S.InvoiceTypeSpan>
          <S.InvoiceTypeSpan>
            {/* {detailInvoice.currentData?.status && (
              <UserStatusPin type={detailInvoice.currentData?.status} />
            )} */}
          </S.InvoiceTypeSpan>
        </S.SubtitleText>
        <S.SubtitleItems>
          {loadingPdfStatus.isLoading && (
            <S.CircularWrapper>
              <CircularLoader color="#0084B1" size={20} />
            </S.CircularWrapper>
          )}
          <S.ButtonWrapper>
            <Button
              text="Print"
              backgroundColor="#848A9B"
              onClick={() => {
                getPdf({
                  id: params.id,
                  type: InvoicesMainType.PREPAYMENT,
                });
              }}
            >
              <PrintWhiteSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.ContentWrapper>
        <S.Content>
          <InvInformPrepaymentTable />
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default InvoicePrepaymentDetail;
