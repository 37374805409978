import React from 'react';
import * as S from 'pages/SuperAdmin/components/InsuranceProfile/styles';
import { RadioButton, TooltipInput, TooltipSelectInput } from 'components';
import { MenuItem, RadioGroup } from '@mui/material';
import { Shares } from 'pages/SuperAdmin/components/InsuranceProfile/config';
import { GroupNumbersInInsuranceType } from 'types/InsuranceType';

type Props = {
  insuranceGroupNumber?: GroupNumbersInInsuranceType;
};

export const GroupNumberInfo = ({ insuranceGroupNumber }: Props) => {
  return (
    <div>
      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>What is the deductible?</S.ArticleText>
      </S.Line>

      <S.Line className={'m7'}>
        <S.ArticleText className={'bold'}>In-Net</S.ArticleText>
        <S.ArticleText className={'bold'}>Out-Net</S.ArticleText>
      </S.Line>
      <S.Line className={'m7'}>
        <S.ArticleText className={'normal'}>Met</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.inNetMet ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Total</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.inNetTotal ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Met</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.inOutMet ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Total</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.inOutTotal ?? '-'}
            disabled
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'fs13'}>What is out of pocket limit?</S.ArticleText>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'normal'}>In</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.pocketLimitInFrom ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>-</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.pocketLimitInTo ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.pocketLimitOutFrom ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>-</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.pocketLimitIOutTo ?? '-'}
            disabled
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'mt'}>
        <S.ArticleText className={'fs13'}>
          What is the co/insurance on policy?
        </S.ArticleText>

        <S.EmptyDiv width={'40px'} />
        <S.ArticleText className={'normal'}>In-Network:</S.ArticleText>
        <S.InputWrapper className="px180">
          <TooltipSelectInput
            displayEmpty
            label=""
            value={insuranceGroupNumber?.insuranceOnPolicyIn ?? '-'}
            disabled
          >
            {Shares.map(item => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
          </TooltipSelectInput>
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out-Network:</S.ArticleText>
        <S.InputWrapper className="px180">
          <TooltipSelectInput
            displayEmpty
            label=""
            value={insuranceGroupNumber?.insuranceOnPolicyOut ?? '-'}
            disabled
          >
            {Shares.map(item => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
          </TooltipSelectInput>
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'fs13'}>Does the patient have a copay?</S.ArticleText>

        <S.EmptyDiv width={'62px'} />
        <S.ArticleText className={'normal'}>In</S.ArticleText>
        <S.InputWrapper className="px240">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.copayIn ?? '-'}
            disabled
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out</S.ArticleText>
        <S.InputWrapper className="px240">
          <TooltipInput
            type="number"
            isSum="$"
            value={insuranceGroupNumber?.copayOut ?? '-'}
            disabled
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>Is referral required?</S.ArticleText>

        <S.EmptyDiv width={'150px'} />
        <RadioGroup value={insuranceGroupNumber?.isReferallRequired ?? false}>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Yes" value={true} rightPos disabled />
              <RadioButton label="No" value={false} rightPos disabled />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.Line>

      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>Is prior authorization required?</S.ArticleText>

        <S.EmptyDiv width={'82px'} />
        <RadioGroup value={insuranceGroupNumber?.isAuthorizationRequired ?? false}>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Yes" value={true} rightPos disabled />
              <RadioButton label="No" value={false} rightPos disabled />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.Line>
    </div>
  );
};
