import { Switch, SwitchProps } from '@mui/material';
import * as S from './styles';

type Props = {} & SwitchProps;

const SwitchCustom: React.FC<Props> = ({ ...props }) => {
  return (
    <S.Wrapper>
      <Switch {...props} />
    </S.Wrapper>
  );
};

export default SwitchCustom;
