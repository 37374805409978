import { useDrag } from 'react-dnd';
import { ApproveIconSVG, DragDropSVG } from 'assets/icons';
import * as S from './styles';
import { DragTypes } from 'types/DragTypes';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import dayjs from 'dayjs';
import AppointmentPinV2 from 'components/atoms/AppointmentPinV2';
import { AppointmentStatus } from 'types/StatusTypes';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { MouseEvent, useState } from 'react';
import NotificationPopover from '../../../../../NotificationPopover';
import ChangeStatusPopoverContent from '../ChangeStatusPopoverContent';
import { Fade, Tooltip } from '@mui/material';
import UserInformationPopoverContent from '../UserInformationPopoverContent';
import HoldCard from 'components/atoms/HoldCard';
import { convertAMtoPM } from 'helpers/functions/convertAMtoPM';
import { getAppointmentType } from './configAppointmentType';
import { useAppSelector } from 'hooks/useAppSelector';

type Props = {
  appointment: AppointmentsNew;
  time?: string;
  isOnlyPending?: boolean;
  isOnlyView?: boolean;
  visit?: Visits;
  isUserView?: boolean;
};

const AppointmentCard: React.FC<Props> = ({
  appointment,
  time,
  isOnlyPending,
  isOnlyView,
  isUserView,
  visit,
}) => {
  const clinicServices = useAppSelector(state => state.selectedClinic.services);

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: DragTypes.BOX,
    item: visit,
    options: {
      dropEffect: 'move',
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.6 : 1,
      item: monitor.getItem(),
    }),
  }));

  const dispatch = useAppDispatch();

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);

  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);

  const [isShowModal, setIsShowModal] = useState(false);

  const handleAnchorProfile = (e: MouseEvent<HTMLDivElement>) => {
    if (anchorElProfile) {
      e.stopPropagation();
      setAnchorElProfile(null);
    } else {
      setAnchorElProfile(e.currentTarget);
    }
  };

  const currentService = clinicServices?.find(i => i.id === visit?.typeServiceId);

  return (
    <S.Wrapper
      ref={preview}
      style={{ opacity }}
      background={
        appointment.isFirstTimeVisit &&
        currentService?.isUseForFirstTimeVisit &&
        currentService?.firstVisitColor
          ? currentService?.firstVisitColor
          : (currentService?.color ?? '')
      }
      border={'#848A9B'}
      onClick={() => {
        !isShowModal &&
          dispatch(
            showDrawer({
              show: true,
              mode: DrawerMode.INFO_APPOINTMENT,
              props: appointment,
            }),
          );
      }}
    >
      {isOnlyPending && (
        <S.DateDiv>
          <S.NumberDay>{dayjs(appointment.date).format('MMM DD, YYYY')}</S.NumberDay>
          <S.RecordTime>
            {convertAMtoPM(appointment.startTime) +
              '-' +
              convertAMtoPM(appointment.endTime)}
          </S.RecordTime>
        </S.DateDiv>
      )}
      <S.LeftSide>
        <S.UpperLine>
          {!isUserView && appointment.isCheckBilling && <ApproveIconSVG />}
          <S.Article>
            {visit && visit?.reason + ` (${visit?.visitLengthMinutes ?? 0} min)`}
          </S.Article>
          <S.NameWrapper>
            <Tooltip
              title={isUserView ? '' : 'Click to show profile'}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <S.Name
                onClick={e => {
                  if (!isUserView) {
                    setIsShowModal(true);
                    e.stopPropagation();
                    handleAnchorProfile(e);
                  }
                }}
              >
                {appointment?.patient?.profile?.firstName +
                  ' ' +
                  appointment?.patient?.profile?.lastName}
              </S.Name>
            </Tooltip>
            {!isUserView && appointment?.patient?.isHoldAppointment && (
              <HoldCard text="HA" reason={appointment.patient.isHoldAppointment} />
            )}
            {!isUserView && appointment?.patient?.isHoldBilling && (
              <HoldCard text="HB" reason={appointment.patient.isHoldBilling} />
            )}
          </S.NameWrapper>
          <NotificationPopover
            open={Boolean(anchorElProfile)}
            anchorEl={anchorElProfile}
            onClose={() => {
              setIsShowModal(false);
              setAnchorElProfile(null);
            }}
          >
            <UserInformationPopoverContent row={appointment} />
          </NotificationPopover>
          {!isUserView && appointment.patient?.clinics[0]?.ClinicUser?.accountNumber && (
            <S.Text className="italic">
              <S.Span>Acc N: </S.Span>
              {appointment.patient?.clinics[0]?.ClinicUser?.accountNumber}
            </S.Text>
          )}
          <S.Text>
            <S.Span>DOB: </S.Span>
            {dayjs(appointment.patient?.profile?.dateOfBirth).format('MM/DD/YYYY')}
          </S.Text>
          <S.Text>
            <S.Span>Phone: </S.Span>
            {appointment.patient?.phone}
          </S.Text>
        </S.UpperLine>
        {isUserView ? (
          <S.UnderLine>
            <S.Span>Note: </S.Span>
            <S.Text>{appointment?.patientNote || ''}</S.Text>
          </S.UnderLine>
        ) : (
          appointment?.globalNote && (
            <S.UnderLine>
              <S.Span>Note: </S.Span>
              <S.Text>{appointment.globalNote}</S.Text>
            </S.UnderLine>
          )
        )}
      </S.LeftSide>
      <S.RightSide>
        <S.SelectBlock>
          {appointment.countSystemClaims > 0 && (
            <S.ClaimCountCard>SYSTEM: {appointment.countSystemClaims}</S.ClaimCountCard>
          )}
          {!isUserView && (
            <S.AppointmentType>{getAppointmentType(appointment)}</S.AppointmentType>
          )}
          <AppointmentPinV2 type={appointment.status} />
          <NotificationPopover
            open={Boolean(anchorElReport)}
            anchorEl={anchorElReport}
            onClose={() => setAnchorElReport(null)}
            margin={5}
          >
            <ChangeStatusPopoverContent appointment={appointment} />
          </NotificationPopover>
        </S.SelectBlock>
        <S.SelectBlock>
          {appointment.inProgress && <S.StatusProcess>In progress</S.StatusProcess>}
          {appointment.isCompleted && <S.StatusProcess>Finished</S.StatusProcess>}
          {appointment.status !== AppointmentStatus.COMPLETED &&
            appointment.status !== AppointmentStatus.CANCELED &&
            !isOnlyView && (
              <S.MovableBlock style={{ opacity }} ref={drag}>
                <DragDropSVG />
              </S.MovableBlock>
            )}
        </S.SelectBlock>
      </S.RightSide>
    </S.Wrapper>
  );
};

export default AppointmentCard;
