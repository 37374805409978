import { useDispatch } from 'react-redux';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const cashboxApi = api.injectEndpoints({
  endpoints: builder => ({
    cashboxGet: builder.query<Response<any>, any>({
      query: params => ({
        url: `/cash-box`,
        params: { ...params },
      }),
      providesTags: ['CashBox'],
    }),
    cashboxUpdate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/cash-box/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['CashBox'],
    }),
    cashboxCalculate: builder.mutation({
      query(params) {
        return {
          url: `/cash-box/calculate`,
          method: 'GET',
          responseHandler: async response => {
            if (response.status === 200) {
              return window.location.assign(
                window.URL.createObjectURL(await response.blob()),
              );
            }
          },
          params: { ...params },
          cache: 'no-cache',
        };
      },
    }),
    cashboxAddRow: builder.mutation({
      query(body) {
        return {
          url: `/cash-box`,
          method: 'POST',
          body: { ...body },
        };
      },
      invalidatesTags: ['CashBox'],
    }),
    cashboxDeleteRow: builder.mutation({
      query: ({ id }) => ({
        url: `/cash-box/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CashBox'],
    }),
  }),
});

export const {
  useCashboxGetQuery,
  useCashboxUpdateMutation,
  useCashboxCalculateMutation,
  useCashboxAddRowMutation,
  useCashboxDeleteRowMutation,
} = cashboxApi;
