import styled from 'styled-components';

export const Loader = styled.div<{ color?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .MuiCircularProgress-root {
    color: ${props => props.color || `#fff`};
    width: 100%;
    height: 100%;
    /* width: 100px !important;
    height: 100px !important; */
  }
`;
