import { CloseFileItemSVG, MessageDocumentSVG } from '../../../../../assets/icons';
import { getFileName } from '../../../../../helpers/functions/fileName';
import * as S from '../styles';

type Props = {
  name: string;
  onDelete: () => void;
};

const FileItem: React.FC<Props> = ({ name, onDelete }) => {
  return (
    <S.FileItem>
      <S.Icons>
        <MessageDocumentSVG className="document" />
        <CloseFileItemSVG className="close" onClick={onDelete} />
      </S.Icons>
      <S.Text>{getFileName(name)}</S.Text>
    </S.FileItem>
  );
};

export default FileItem;
