import { useParams } from 'react-router-dom';
import { AddRectSVG, JPGSVG, PDFSVG } from '../../../../../../assets/icons';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../types/DrawerMode';
import * as S from './styles';
import HealthRecordItem from './HealthRecordItem';
import { Accordeon, CircularLoader, LinearLoader } from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import {
  useFoldersDocumentsGetQuery,
  useFormsDocumentsMutation,
} from '../../../../../../store/api/treatment/treatmentApi';
import { useState } from 'react';
import dayjs from 'dayjs';

const HealthRecords = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const id = useAppSelector(state => state.auth.id);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const createdById = params.patientId ? params.patientId : id;

  const getFolders = useFoldersDocumentsGetQuery({
    clinicId: selectedClinic.id,
    userId: createdById,
  });

  const [expanded, setExpanded] = useState<string | null>(null);

  const [getFormsDocuments, formsDocsStatus] = useFormsDocumentsMutation({});

  const handleExpanded = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.EditBtnWrapper>
          <S.Article>Health Records</S.Article>
        </S.EditBtnWrapper>
        <S.Files>
          {getFolders.data?.appointmentsForms.map((item, index) => {
            return (
              item.formsCount > 0 && (
                <Accordeon
                  key={index}
                  expanded={expanded === item.appointmentId}
                  title={'Appointment: #' + item.appointmentId.slice(0, 8)}
                  clinic={getFolders.data?.clinicName}
                  appointmentForms={item}
                  docsCount={item.formsCount}
                  onClick={() => {
                    handleExpanded(item.appointmentId);
                    getFormsDocuments({
                      userId: createdById,
                      clinicId: selectedClinic.id,
                      appointmentId: item.appointmentId,
                    });
                  }}
                >
                  <S.AccordeonContent onClick={e => e.stopPropagation()}>
                    {formsDocsStatus.isLoading ? (
                      <S.Loader>
                        <CircularLoader color="#0084B1"></CircularLoader>
                      </S.Loader>
                    ) : (
                      <S.AccordeonItems>
                        {formsDocsStatus.data?.map(item => (
                          <S.FileRow
                            key={item.id}
                            onClick={() => {
                              window.open(item.url);
                            }}
                          >
                            <S.InfoRow>
                              {item.name.includes('.pdf') ? (
                                <PDFSVG className="icon" />
                              ) : (
                                <JPGSVG className="icon" />
                              )}
                              {item.name}
                            </S.InfoRow>
                            <S.CreatedBy>
                              <S.Text className="small">
                                Date: {dayjs(item.createdAt).format('MM/DD/YYYY')}
                              </S.Text>
                              {item.createdByDoctor?.profile?.firstName ? (
                                <S.Text>
                                  Created by:{' '}
                                  {item.createdByDoctor?.profile?.firstName +
                                    ' ' +
                                    item.createdByDoctor?.profile?.lastName}
                                </S.Text>
                              ) : (
                                <S.Text>Created by: -</S.Text>
                              )}
                              <S.EmptyBtn />
                            </S.CreatedBy>
                          </S.FileRow>
                        ))}
                      </S.AccordeonItems>
                    )}
                  </S.AccordeonContent>
                </Accordeon>
              )
            );
          })}
        </S.Files>
      </S.Content>
    </S.Wrapper>
  );
};

export default HealthRecords;
