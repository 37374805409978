import { CheckboxProps, FormHelperText } from '@mui/material';
import * as S from './styles';

type Props = {
  disabled?: boolean;
} & CheckboxProps;

const CheckBox: React.FC<Props> = ({ disabled, ...props }) => {
  return (
    <S.CheckBoxItem
      inputProps={{ 'aria-label': 'controlled' }}
      {...props}
      disabled={disabled}
    />
  );
};

export default CheckBox;
