import { Input } from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from './styles';

const TreatmentPlan = () => {
  return (
    <>
      <S.Article>Treatment Plan</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Promote flow of Qi and Blood in affected Meridians" />
        <CheckBoxWithLabel label="Relieve muscle spasm/pain" />
        <CheckBoxWithLabel label="Inactivated local trigger points" />
        <CheckBoxWithLabel label="Clear Mind, Calm Heart" />
        <CheckBoxWithLabel label="Boost Tendon Strength" />
      </S.Line>
      <S.Article className="px13">Treatment Provided</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Acupuncture" />
        <CheckBoxWithLabel label="Acupuncture with electrical stimulation" />
        <CheckBoxWithLabel label="Cupping" />
        <CheckBoxWithLabel label="Manuel therapy" />
        <CheckBoxWithLabel label="Tui Na" />
        <CheckBoxWithLabel label="Electromagnetic Health Lamp" />
        <CheckBoxWithLabel label="Ear Seed" />
        <CheckBoxWithLabel label="Moxa" />
        <CheckBoxWithLabel label="Hot Pack" />
        <CheckBoxWithLabel label="Therapeutic Exercise" />
      </S.Line>
      <S.Article className="px13">
        Insertion points are chosen according to the Traditional Chinese Medical Theory
        from the affected channels:
      </S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Acupuncture" />
        <CheckBoxWithLabel label="LU-Hand Tai Yin" />
        <CheckBoxWithLabel label="LI-Hand Yang Ming" />
        <CheckBoxWithLabel label="ST-Foot Yang Ming" />
        <CheckBoxWithLabel label="SP-Foot Tai Yin" />
        <CheckBoxWithLabel label="HT-Hand Shao Yin" />
        <CheckBoxWithLabel label="SI-Hand Tai Yang" />
        <CheckBoxWithLabel label="UB-Foot Tai Yang" />
        <CheckBoxWithLabel label="KI-Foot Shao Yin" />
        <CheckBoxWithLabel label="PC-Hand Jue Yin" />
        <CheckBoxWithLabel label="SJ-Hand Shao Yang" />
        <CheckBoxWithLabel label="GB-Foot Shao Yang" />
        <CheckBoxWithLabel label="LIV-Foot Jue Yin" />
        <CheckBoxWithLabel label="Du Meridian" />
        <CheckBoxWithLabel label="Ren Meridian" />
      </S.Line>
      <S.Line>
        <Input label={'Acupuncture Points'} />
      </S.Line>
      <S.Gap />
    </>
  );
};

export default TreatmentPlan;
