import { LinearProgress, Tab } from '@mui/material';
import { ProfileInfoSVG } from 'assets/icons';
import { Button, Dialog, MainProfileInformation, TabsItem } from 'components';
import * as S from './styles';
import { useState } from 'react';
import ProfileInfo from './components/ProfileInfo';
import Documents from './components/Documents';
import DialogChangeProfile from './components/DialogChangeProfile';
import { useUserProfileGetQuery } from 'store/api/user/profileApi';

const Profile = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const profile = useUserProfileGetQuery({});

  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogChangeProfile onClose={() => setOpenDialog(false)} />
      </Dialog>
      {profile.isLoading || profile.isFetching ? (
        <S.Loader>
          <LinearProgress />
        </S.Loader>
      ) : (
        <S.ProfileWrapper>
          <S.SubtitleBar>
            <S.SubtitleText>My Profile</S.SubtitleText>
            <S.SubtitleItems>
              <S.ButtonWrapper>
                <Button text="Change Profile info" onClick={() => setOpenDialog(true)}>
                  <ProfileInfoSVG />
                </Button>
              </S.ButtonWrapper>
            </S.SubtitleItems>
          </S.SubtitleBar>
          <S.TabWrapper>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="Profile Info"></Tab>
              <Tab disableRipple label="Demographics" className="demographics"></Tab>
              {/* <Tab disableRipple label="Insurance Info"></Tab> */}
              {/* <Tab disableRipple label="Visit history"></Tab> */}
              <Tab disableRipple label="Documents"></Tab>
            </TabsItem>
          </S.TabWrapper>
          <S.Content>
            <S.ProfileInformation className={tabIndex !== 0 ? 'hide' : 'default'}>
              <MainProfileInformation rows={profile.currentData} />
            </S.ProfileInformation>
            <S.TabInformation>
              {tabIndex === 0 && (
                <ProfileInfo rows={profile.currentData} className="hide" />
              )}
              {tabIndex === 1 && <ProfileInfo rows={profile.currentData} />}
              {/* {tabIndex === 2 && (
                <InsuranceInformationV2 rows={profile.currentData} />
              )} */}
              {/* {tabIndex === 3 && <VisitHistory />} */}
              {tabIndex === 2 && <Documents rows={profile.currentData} />}
            </S.TabInformation>
          </S.Content>
        </S.ProfileWrapper>
      )}
    </>
  );
};

export default Profile;
