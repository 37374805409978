import { useState } from 'react';
import { AddPatientsSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, SearchInput } from 'components';
import * as S from '../../styles';
import { useDebounce } from 'use-debounce';
import { DrawerMode } from 'types/DrawerMode';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { useGetAllRefProvidersQuery } from 'store/api/staff/staffApi';
import RefProvidersRow from './TableData/RefProvidersRow';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';

const headers = [
  { name: 'First name', key: 'Name' },
  { name: 'Last name', key: 'Last Name' },
  { name: 'Speciality', key: 'speciality' },
  { name: 'Phone', key: 'phone' },
];

const REFERRING_PROVIDERS_LIMIT = 20;

const ReferringProviders = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(state => state.auth);

  const getList = useGetAllRefProvidersQuery({
    page: page,
    q: debouncedSearchValue,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.TableBody>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'referring providers'}
          limit={REFERRING_PROVIDERS_LIMIT}
        />
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          {role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button
                text="Add referring provider"
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_REF_PROVIDER,
                      props: null,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <RefProvidersRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default ReferringProviders;
