import dayjs from 'dayjs';
import * as S from '../../../styles';
import { UserStatusPin } from '../../../../../../../components';
import { AvatarSVG } from '../../../../../../../assets/icons';
import { Clinics } from '../../../../../../../types/ClinicTypes';

type Props = {
  row: any;
  header: string;
};

const StaffRecepData: React.FC<Props> = ({ row, header }) => {
  switch (header) {
    case 'ReceptionistName': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.user.avatar ? (
              <img src={row.user.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          {row.user.profile?.firstName + ' ' + row.user.profile?.lastName || '-'}
        </S.CellWrapper>
      );
    }

    case 'ClinicName': {
      return row.user.clinics ? (
        <S.CellColumnWrapper>
          {row.user.clinics.map((item: Clinics) => (
            <S.ColumnText key={item.id}>{item.name}</S.ColumnText>
          ))}
        </S.CellColumnWrapper>
      ) : (
        '-'
      );
    }
    case 'Phone': {
      return row.user.phone || '-';
    }
    case 'Email': {
      return row.user.email || '-';
    }
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin
            type={row.user.isBlocked ? row.user.isBlocked : row.user.status}
          />
        </S.CellWrapper>
      );
    }
    // case "Edit": {
    //   return (
    //     <S.CellWrapper>тпх
    //       <EditBtnMinimalSVG onClick={showCashBox} />
    //     </S.CellWrapper>
    //   );
    // }
    default:
      return row[header] || '-';
  }
};

export default StaffRecepData;
