export const addXmin = (prefferedTime: string, minutes: number) => {
  let newTime = new Date(
    new Date('1970/01/01 ' + prefferedTime).getTime() + minutes * 60000,
  ).toLocaleTimeString('en-UK', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return newTime;
};
