import { FC, useState } from 'react';
import * as S from './styles';
import Alert, { AlertProps } from '@mui/material/Alert';
import { CloseBtnSVG } from '../../../assets/icons';
import { Collapse } from '@mui/material';

type AlertItemProps = {
  width?: string;
  $isDefault?: boolean;
  height?: string;
  text: string;
  condition?: Array<string>;
  $isHideButton?: boolean;
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
} & AlertProps;

const AlertItem: React.FC<AlertItemProps> = ({
  width,
  text,
  type,
  height,
  condition,
  $isDefault = false,
  $isHideButton = false,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  return (
    <S.AlertWrapper $isDefault={$isDefault}>
      <Collapse in={open}>
        <S.AlertItem
          severity={type}
          style={{
            width,
            height: height || '54px',
          }}
          {...props}
        >
          <S.AlertItemContent>
            <S.AlertText>
              <S.Line>{text}</S.Line>
              {condition &&
                condition.map((item, index: number) => {
                  return (
                    <S.Line key={item}>
                      {index + 1}
                      {') '}
                      {item}
                    </S.Line>
                  );
                })}
            </S.AlertText>
            {!$isHideButton && (
              <CloseBtnSVG className="closeBtn" onClick={() => setOpen(false)} />
            )}
          </S.AlertItemContent>
        </S.AlertItem>
      </Collapse>
    </S.AlertWrapper>
  );
};

export default AlertItem;
