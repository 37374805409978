import { useDispatch } from 'react-redux';
import { useState } from 'react';
import * as S from './styles';

type Props = {
  text: string;
  active?: boolean;
  isWorkDay?: boolean;
  disabled?: boolean;
};

const SchedBtn: React.FC<Props> = ({ text, active, isWorkDay, disabled }) => {
  const dispatch = useDispatch();

  const getSchedStyle = () => {
    if (active === false) {
      if (isWorkDay === true) {
        return { background: '#F9F9FC', color: '#202E5F' };
      } else {
        return { background: '#E6E8EB', color: '#848A9B' };
      }
    } else {
      return { background: '#0084B1', color: '#fff' };
    }
  };

  return (
    <>
      <S.SchedBtn background={getSchedStyle().background} disabled={disabled}>
        <S.BtnText color={getSchedStyle().color}>{text}</S.BtnText>
      </S.SchedBtn>
    </>
  );
};

export default SchedBtn;
