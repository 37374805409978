import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Wrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;

  margin-right: 15px;

  @media ${devices.xs} {
    background: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 30px;
  height: 300px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 30px;
  display: flex;
`;

export const DayPickerWrapper = styled.div`
  width: 370px;
`;

export const ArticleWrapper = styled.div`
  margin-top: 18px;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 30px;
  margin-top: 5px;
`;

export const ArticleBtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 18px;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  width: 200px;
  margin-right: 30px;
  margin-top: 15px;

  & svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    path {
      stroke: #fff;
    }
  }
`;

export const ScheduleEdit = styled.div`
  border-radius: 10px;
  background: var(--hover, #f5f5f5);
  display: flex;
  width: 100%;

  height: 300px;
`;
