import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import * as S from './cardStyles';
import { DeleteRedBtnSVG } from 'assets/icons';
import { NewInsuranceGroupsType } from './config';
import { FormikProps } from 'formik';
import { GroupNumberDetails } from './GroupNumberDetails';
import { InsuranceFormValues } from 'pages/Users/components/Profile/components/InsuranceInformation_v2/components/AddInsuranceDialog/AddInsuranceDialog';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type Props = {
  formik: FormikProps<NewInsuranceGroupsType> | FormikProps<InsuranceFormValues>;
  index: number;
  fromInsurancePage?: boolean;
  setIsDirtyForm: (isDirtyForm: boolean) => void;
};

export const GroupNumberCard = ({
  formik,
  index,
  fromInsurancePage = true,
  setIsDirtyForm,
}: Props) => {
  return (
    <Accordion
      disableGutters
      sx={{
        background: '#f9f9fc',
        boxShadow: 'none',
        border: '1px solid #efefef',
        '&:before': {
          display: 'none',
        },

        '&.Mui-expanded': {
          background: '#f9f9fc',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowUpIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: 50,
          },
        }}
      >
        <S.ArticleSmallWrapper>
          <S.LeftSide>#{formik.values?.groups?.at(index)?.groupNumber ?? ''}</S.LeftSide>
          <S.Btns>
            <DeleteRedBtnSVG
              style={{ width: '19px', height: '19px' }}
              onClick={e => {
                e.stopPropagation();
                setIsDirtyForm(true);
                // @ts-ignore
                formik.setValues({
                  ...formik.values,
                  groupNumber: '',
                  groups: formik.values?.groups?.filter((item, i) => i !== index),
                });
              }}
            />
          </S.Btns>
        </S.ArticleSmallWrapper>
      </AccordionSummary>

      <AccordionDetails>
        <GroupNumberDetails
          formik={formik}
          index={index}
          fromInsurancePage={fromInsurancePage}
          setIsDirtyForm={setIsDirtyForm}
        />
      </AccordionDetails>
    </Accordion>
  );
};
