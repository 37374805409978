import React from 'react';
import * as S from './styles';
import { DisableSVG } from 'assets/icons';

type Props = {
  color?: string | number;
  disabled?: boolean;
  size?: 'l' | 'xs';
};

export const ColoredServiceBox = ({ color, disabled = false, size = 'l' }: Props) => {
  return (
    <S.ServiceBox>
      <S.ServiceCircle
        color={
          color
            ? typeof color === 'string'
              ? color
              : `hsl(${color}, 100%, 50%)`
            : 'transparent'
        }
        disabled={disabled}
        className={size}
      />
      {disabled && <DisableSVG />}
    </S.ServiceBox>
  );
};
