import { useState } from 'react';
import { Print24pxSVG } from '../../../../../../assets/icons';
import { CircularLoader, Dialog } from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useTemplateEmptyPrintPDFMutation } from '../../../../../../store/api/templates/templateApi';
import {
  DigitalTemplateType,
  TemplateNameType,
} from '../../../../../../types/TemplateType';
import * as S from '../../styles';
import GeneratePTInsurance from '../GenerateEmptyDigital/GeneratePTInsurance';
import GenerateACAInsurance from '../GenerateEmptyDigital/GenerateACAInsurance/GenerateACAInsurance';
import GeneratePTInitial from '../GenerateEmptyDigital/GeneratePTInitial';
import GenerateInitialVisitForm from '../GenerateEmptyDigital/GenerateInitialVisitForm';
import GenerateClientRegistrationForm from '../GenerateEmptyDigital/GenerateClientRegistrationForm';

type Props = {
  row: any;
};

const Print = ({ row }: Props) => {
  const [isOpenDigital, setIsOpenDigital] = useState(false);
  const [printEmptyPDF, emptyPDFStatus] = useTemplateEmptyPrintPDFMutation({});
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const signature = useAppSelector(state => state.auth.signature);

  const getPrintRoute = () => {
    switch (row.type as TemplateNameType) {
      case TemplateNameType.CLIENT: {
        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = row.file.url;
        iframe.onload = () => {
          setTimeout(() => {
            iframe.focus();
            iframe.contentWindow?.print();
          }, 1);
        };
        break;
      }
      case TemplateNameType.PDF: {
        return printEmptyPDF({
          templateName: row.key,
          clinicId: selectedClinic.id,
        })
          .unwrap()
          .then(res => {
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.src = res;
            iframe.onload = () => {
              setTimeout(() => {
                iframe.focus();
                iframe.contentWindow?.print();
              }, 1);
            };
          });
      }
      case TemplateNameType.DIGITAL: {
        setIsOpenDigital(true);
        break;
      }
      default:
        return;
    }
  };

  const generateDigitalPDF = () => {
    switch (row.key) {
      case DigitalTemplateType.PT_THERAPY:
        return <GeneratePTInsurance signature={signature} />;
      case DigitalTemplateType.ACUPUNCTURE_INSURANCE:
        return <GenerateACAInsurance signature={signature} />;
      case DigitalTemplateType.CLIENT_REGISTRATION:
        return <GenerateClientRegistrationForm signature={signature} />;
      case DigitalTemplateType.INITIAL:
        return <GenerateInitialVisitForm signature={signature} />;
      case DigitalTemplateType.PT_INITIAL:
        return <GeneratePTInitial signature={signature} />;
    }
  };

  return (
    <>
      <Dialog open={isOpenDigital} onClose={() => setIsOpenDigital(!isOpenDigital)}>
        {generateDigitalPDF()}
      </Dialog>
      {emptyPDFStatus.isLoading ? (
        <S.CircLoader>
          <CircularLoader color="#0084B1" size={24} />
        </S.CircLoader>
      ) : (
        <Print24pxSVG onClick={() => getPrintRoute()} />
      )}
    </>
  );
};

export default Print;
