import { FormikProps } from 'formik';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from 'helpers/FormikFuncs/formikFuncs';
import { DatePicker, Dialog, Input } from 'components';
import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FirstName from 'components/organismus/AppointmentDrawer/components/PatientInformation/FirstName';
import { useAppSelector } from 'hooks/useAppSelector';
import LastName from 'components/organismus/AppointmentDrawer/components/PatientInformation/LastName';
import { ClearFiltersSVG, SearchIconSVG } from 'assets/icons';
import DOB from 'components/organismus/AppointmentDrawer/components/PatientInformation/DOB';
import { AppointmentsNew, Step } from 'types/AppointmentNewTypes';
import * as S from '../../styles';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';
import { ChooseCptCode } from 'pages/Billing/components/Claims/components/ClaimForm/components/ServicesTable/ChooseCptCode';
import { ChooseInjuryCode } from 'pages/Billing/components/Claims/components/ClaimForm/components/AlphabetLines/ChooseInjuryCode';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  stepperValues?: Step;
  appointmentInformation?: AppointmentsNew;

  setIsDirty?: () => void;
};
const PTUserBlock = forwardRef<Record<string, unknown>, Props>(
  ({ formik, stepperValues, appointmentInformation, setIsDirty }, ref) => {
    const [selectedValue, setSelectedValue] = useState<any>('');
    const { id } = useAppSelector(state => state.selectedClinic);
    const [emptyDatePicker, setEmptyDatePicker] = useState(false);
    const [showCpt, setShowCpt] = useState(false);
    const [showIcd, setShowIcd] = useState(false);
    const [cpt, setCpt] = useState(formik.values?.patientInfo?.cpt ?? '');
    const [icd, setIcd] = useState(formik.values?.patientInfo?.diagnostics ?? '');

    useEffect(() => {
      if (appointmentInformation) {
        setSelectedValue(appointmentInformation.patient);
      }
    }, [appointmentInformation]);

    useEffect(() => {
      if (selectedValue) {
        formik.setErrors({
          patientInfo: {
            patientName: '',
          },
        });
        formik.setValues({
          ...formik.values,
          userId: selectedValue?.id,
          patientInfo: {
            birthDate: selectedValue.profile?.dateOfBirth,
            patientName:
              selectedValue.profile?.firstName + ' ' + selectedValue.profile?.lastName,
          },
        });
      } else
        formik.setValues({
          ...formik.values,
          userId: '',
          patientInfo: {
            cpt: '',
            diagnostics: '',
            birthDate: '',
            date: '',
            patientName: '',
          },
        });
    }, [selectedValue]);

    return (
      <>
        <S.Article>Patient information</S.Article>
        <S.Line>
          <>
            <S.InputWrapper className="px240">
              <Input
                label="Diagnosis/ICD-10:"
                defaultValue={formik.values.patientInfo.diagnostics}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChangeInputRef(e, 'patientInfo.diagnostics', ref, setIsDirty);
                  setIcd(e.target.value);
                }}
                isSum={
                  <S.Btn onClick={() => setShowIcd(true)}>
                    <SearchIconSVG className="svg" width={20} />
                  </S.Btn>
                }
                value={icd}
              />
            </S.InputWrapper>
            <Dialog open={showIcd} onClose={() => setShowIcd(false)}>
              <ChooseInjuryCode
                onApplyClick={code => {
                  if (typeof ref !== 'function' && ref && ref?.current !== null) {
                    ref.current['patientInfo.diagnostics'] = code;
                    setIsDirty && setIsDirty();
                    setShowIcd(false);
                    setIcd(code);
                  }
                }}
                title={'ICD-10 Codes'}
                currentValue={
                  formik.values.patientInfo.diagnostics
                    ? formik.values.patientInfo.diagnostics
                    : ''
                }
              />
            </Dialog>
          </>
          <S.InputWrapper className="px240">
            <S.InputWrapper>
              <DatePicker
                label="Date"
                defaultValue={dayjs(formik.values?.patientInfo.date)}
                onChange={value => {
                  handleChangeDatePicker(value, 'patientInfo.date', ref, setIsDirty);
                }}
              />
            </S.InputWrapper>
          </S.InputWrapper>
        </S.Line>
        {selectedValue && !appointmentInformation && (
          <S.ResetButton
            onClick={() => {
              setEmptyDatePicker(true);
              setSelectedValue('');
            }}
          >
            <ClearFiltersSVG />
            Change user
          </S.ResetButton>
        )}

        {formik.errors.patientInfo?.patientName && (
          <S.Line>
            <S.ErrorText>{'USER INFORMATION IS REQUIRED!'}</S.ErrorText>
          </S.Line>
        )}

        <S.Line>
          <S.InputWrapper className="px240">
            <FirstName
              error={formik.errors.patientInfo?.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <LastName
              error={formik.errors.patientInfo?.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <DOB
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
              emptyDatePicker={emptyDatePicker}
              setEmptyDatePicker={setEmptyDatePicker}
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <>
            <S.InputWrapper className="px240">
              <Input
                label="CPT"
                defaultValue={formik.values.patientInfo.cpt}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChangeInputRef(e, 'patientInfo.cpt', ref, setIsDirty);
                  setCpt(e.target.value);
                }}
                isSum={
                  <S.Btn onClick={() => setShowCpt(true)}>
                    <SearchIconSVG className="svg" width={20} />
                  </S.Btn>
                }
                value={cpt}
              />
            </S.InputWrapper>
            <Dialog open={showCpt} onClose={() => setShowCpt(false)}>
              <ChooseCptCode
                onApplyClick={code => {
                  if (typeof ref !== 'function' && ref && ref?.current !== null) {
                    ref.current['patientInfo.cpt'] = code;
                    setIsDirty && setIsDirty();
                    setShowCpt(false);
                    setCpt(code);
                  }
                }}
                currentValue={
                  formik.values.patientInfo.cpt ? formik.values.patientInfo.cpt : ''
                }
                title={'CPT Codes'}
              />
            </Dialog>
          </>
        </S.Line>
      </>
    );
  },
);

export default PTUserBlock;
