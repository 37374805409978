import { useDispatch } from 'react-redux';
import * as S from './styles';
import {
  StaffSchedule,
  useStaffScheduleDeleteMutation,
  useStaffScheduleUpdateMutation,
} from '../../../../../store/api/staff-schedule/staffScheduleApi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { convertAMtoPM } from '../../../../../helpers/functions/convertAMtoPM';
import SelectInput from '../../../../atoms/SelectInput';
import { useClinicsGetQuery } from '../../../../../store/api/clinics/clinicApi';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { constantTimePickerOUT } from '../../../../../constants/constants';
import {
  ApproveBtnSVG,
  CloseBtnSVG,
  EditBtnMinimalSVG,
  EditBtnSVG,
  HorizontalLineSVG,
  ResetApproveBtnSVG,
} from '../../../../../assets/icons';
import { useEffect, useState } from 'react';
import { Dialog } from '../../../..';
import ConfirmDeleteDialog from '../../../../molecules/ConfirmDeleteDialog';
import { setMessage } from '../../../../../store/slices/message';
import { ScheduleValuesProps } from '../../WorkSchedule';
import { FormikProps } from 'formik';
import { compareTime } from '../../../../../helpers/functions/compareDates';
import { compareId } from '../../../../../helpers/functions/compareId';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { Clinics } from '../../../../../types/ClinicTypes';

type Props = {
  dayInfo: StaffSchedule;
  index: number;
  $active: boolean;
  formik: FormikProps<ScheduleValuesProps>;
  todayList?: StaffSchedule[];
  staffId?: string;
  assignClinics?: Array<Clinics>;
};

const SchedRecord: React.FC<Props> = ({
  dayInfo,
  index,
  $active,
  formik,
  todayList,
  staffId,
  assignClinics,
}) => {
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);

  const clinic = useClinicsGetQuery({});

  const [isEditable, setIsEditable] = useState(false);

  const userInfo = useAppSelector(state => state.auth);

  const [open, setOpen] = useState(false);

  const [deleteRecord] = useStaffScheduleDeleteMutation({});
  const [updateRecord] = useStaffScheduleUpdateMutation({});

  const handleClose = () => {
    setOpen(!open);
  };

  const handleSuccessDelete = () => {
    deleteRecord({ id: dayInfo.id })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Your schedule has been deleted successfully',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleUpdate = () => {
    const data = {
      ...formik.values,
    };

    updateRecord({ id: dayInfo.id, ...formik.values })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Your schedule has been updated successfully',
            type: 'success',
          }),
        );
        setIsEditable(false);
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      clinicId: dayInfo.clinicId,
      workingHoursEnd: dayInfo.workingHoursEnd.slice(0, 5),
      workingHoursStart: dayInfo.workingHoursStart.slice(0, 5),
    });
  }, [isEditable]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <ConfirmDeleteDialog
          onClose={handleClose}
          titleText="schedule"
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
      <S.ManageBtn>
        <S.EditBtns>
          {isEditable ? (
            <S.ApproveBtns>
              <ResetApproveBtnSVG
                onClick={() => {
                  setIsEditable(false);
                }}
              />
              <ApproveBtnSVG onClick={handleUpdate} />
            </S.ApproveBtns>
          ) : (
            <EditBtnMinimalSVG className="bigger" onClick={() => setIsEditable(true)} />
          )}
          <CloseBtnSVG onClick={handleClose} />
        </S.EditBtns>
      </S.ManageBtn>
      <S.Wrapper $active={$active}>
        <S.Content>
          <S.LeftBlock>
            <S.ArticleWrap>
              <S.Article>Clinic #{index + 1}</S.Article>
              {isEditable && (
                <S.HelperText>Please, choose one clinic from the dropdown </S.HelperText>
              )}
            </S.ArticleWrap>

            {isEditable ? (
              <S.SelectInputWrapper>
                <SelectInput
                  label="Clinic name"
                  isRequired
                  id="clinicId"
                  name="clinicId"
                  value={formik.values.clinicId}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('clinicId', '');
                    formik.handleChange(e);
                  }}
                  error={!!formik.errors.clinicId}
                  helperText={formik.errors.clinicId}
                >
                  {assignClinics
                    ? assignClinics?.map(item => {
                        return (
                          <MenuItem
                            value={item.id}
                            key={item.id}
                            disabled={
                              todayList ? !!compareId(item.id, todayList as any) : false
                            }
                          >
                            <S.MenuItemContent>{item.name}</S.MenuItemContent>
                          </MenuItem>
                        );
                      })
                    : userInfo?.clinics?.map(item => {
                        return (
                          <MenuItem
                            value={item.id}
                            key={item.id}
                            disabled={
                              todayList ? !!compareId(item.id, todayList as any) : false
                            }
                          >
                            <S.MenuItemContent>{item.name}</S.MenuItemContent>
                          </MenuItem>
                        );
                      })}
                </SelectInput>
              </S.SelectInputWrapper>
            ) : (
              <S.Article>{dayInfo.clinic.name}</S.Article>
            )}
          </S.LeftBlock>
          <S.RightBlock>
            <S.ArticleWrap>
              <S.Article>Working Hours</S.Article>
              {isEditable && (
                <S.HelperText>Please, choose your working hours</S.HelperText>
              )}
            </S.ArticleWrap>

            {isEditable ? (
              <S.WorkTime>
                <SelectInput
                  label="Start"
                  isRequired
                  id="workingHoursStart"
                  name="workingHoursStart"
                  error={!!formik.errors.workingHoursStart}
                  helperText={formik.errors.workingHoursStart}
                  value={formik.values.workingHoursStart}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('workingHoursStart', '');
                    formik.handleChange(e);
                  }}
                >
                  {constantTimePickerOUT.map(time => (
                    <MenuItem
                      key={time.name}
                      value={time.value}
                      disabled={compareTime(formik.values.workingHoursEnd, time.value)}
                    >
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
                <HorizontalLineSVG className="line" />
                <SelectInput
                  label="End"
                  isRequired
                  id="workingHoursEnd"
                  name="workingHoursEnd"
                  error={!!formik.errors.workingHoursEnd}
                  helperText={formik.errors.workingHoursEnd}
                  value={formik.values.workingHoursEnd}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('workingHoursEnd', '');
                    formik.handleChange(e);
                  }}
                >
                  {constantTimePickerOUT.map(time => (
                    <MenuItem
                      key={time.name}
                      value={time.value}
                      disabled={compareTime(time.value, formik.values.workingHoursStart)}
                    >
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.WorkTime>
            ) : (
              <S.SubArticle>
                {convertAMtoPM(dayInfo.workingHoursStart) +
                  ' - ' +
                  convertAMtoPM(dayInfo.workingHoursEnd)}
              </S.SubArticle>
            )}
          </S.RightBlock>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default SchedRecord;
