import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material';
import * as S from './styles';
import { AddRectSVG, ArrowDownSVG, ArrowLSVG } from '../../../assets/icons';
import { ucFirst } from '../../../helpers/functions/toUpperCase';
import StatusPin from '../StatusPin';
import { EventType, EventsInfo } from '../../../types/AppointmentsTypes';
import UserStatusPin from '../UserStatusPin';
import dayjs from 'dayjs';
import { AppointmentsForms } from '../../../types/TemplateType';
import { ChangeEvent, useState } from 'react';
import { ResponseFile } from '../../../types/FileType';
import {
  useFilesCreateMutation,
  useUploadInitialDocsMutation,
} from '../../../store/api/files/files';
import { useParams } from 'react-router-dom';
import CircularLoader from '../CircuralLoader';

type Props = {
  eventsInfo?: EventsInfo;
  index?: number;
  title?: string;
  events?: EventType;
  isInitial?: boolean;
  docsCount?: number;
  clinic?: string;
  appointmentForms?: AppointmentsForms;
} & AccordionProps;

const Accordeon: React.FC<Props> = ({
  eventsInfo,
  index,
  title,
  docsCount,
  events,
  isInitial,
  clinic,
  appointmentForms,
  ...props
}) => {
  const [uploadFiles, statusUpload] = useFilesCreateMutation({});
  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadInitialDocsMutation({});
  const params = useParams();

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const fileList = e.target.files;

    if (fileList) {
      const files = [...fileList];

      uploadInitialFiles({ files: files, userId: params.patientId })
        .unwrap()
        .then(res => {})
        .catch(error => console.log(error));
    }
  };
  return (
    <S.Wrapper>
      <Accordion {...props}>
        <AccordionSummary expandIcon={<ArrowDownSVG />}>
          <S.TitleWrapper>
            <S.TitleReason>{title}</S.TitleReason>
            {isInitial && (
              <S.Btn
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <label htmlFor="fileclick">
                  <S.Btn>
                    <input
                      id="fileclick"
                      type="file"
                      hidden
                      multiple={true}
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handleChangeFile}
                    />
                    <AddRectSVG className="svg" />
                    Add Documents
                  </S.Btn>
                </label>
              </S.Btn>
            )}
            {appointmentForms && appointmentForms.formsCount > 0 && (
              <>
                <S.HelperText>
                  Date: {dayjs(appointmentForms.appointmentDate).format('MM/DD/YYYY')}
                </S.HelperText>
              </>
            )}

            <S.HelperText>Docs: {docsCount}</S.HelperText>
            {clinic && <S.HelperText>Clinic: {clinic}</S.HelperText>}

            {uploadInitialFilesStatus.isLoading && (
              <S.Loader>
                <CircularLoader color="#0084B1" size={16} />
              </S.Loader>
            )}
          </S.TitleWrapper>
        </AccordionSummary>
        <AccordionDetails onClick={e => e.stopPropagation()}>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </S.Wrapper>
  );
};

export default Accordeon;
