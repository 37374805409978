import { View, Text, Svg, Polygon, Rect, Path } from '@react-pdf/renderer';
import { FillCheckboxSVG, NotFillCheckboxSVG } from '../../../../../../../assets/icons';

type Props = {
  article?: string;
  text?: string;
  styles: any;
  isPadding?: boolean;
  isChecked?: boolean;
  isOnlyCheckbox?: boolean;
};

const FieldWithCheckbox = ({
  article,
  text,
  styles,
  isPadding,
  isOnlyCheckbox,
  isChecked,
}: Props) => {
  return (
    <View style={styles.element}>
      {isOnlyCheckbox ? (
        <Text style={styles.span}></Text>
      ) : (
        <Text style={styles.span}>{article}: </Text>
      )}
      {isChecked ? (
        <Svg viewBox="0 0 15 15" fill="none" width={15} height={15}>
          <Rect width="15" height="15" rx="3" fill="#0084B1" />
          <Path
            d="M3.5 7.5L7 10L12 4"
            stroke="white"
            fill="none"
            stroke-linecap="round"
          />
        </Svg>
      ) : (
        <Svg width={15} height={15}>
          <Rect x="0.5" y="0.5" width="15" height="15" rx="2.5" stroke="#848A9B" />
        </Svg>
      )}
    </View>
  );
};

export default FieldWithCheckbox;
