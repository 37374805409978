import styled from 'styled-components';

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
    /* width: 100px !important;
    height: 100px !important; */
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
    /* width: 100px !important;
    height: 100px !important; */
  }
`;
