import dayjs from 'dayjs';
import { ClaimType } from '../../../../../../../../types/AppointmentNewTypes';
import RadioButton from '../../../../../../../atoms/RadioButton';
import * as S from '../styles';
import { ClaimCardType } from '../../../../../../../../types/InvoiceType';

type Props = {
  claim: ClaimCardType;
  setSelectedClaim: React.Dispatch<React.SetStateAction<string | null>>;
  selectedClaim: string | null;
};

const ExistingClaimLine: React.FC<Props> = ({
  claim,
  setSelectedClaim,
  selectedClaim,
}) => {
  return (
    <S.LineWrapper
      onClick={() => setSelectedClaim(String(claim.id))}
      $isSelected={selectedClaim === String(claim.id) ? true : false}
    >
      <S.SelectInput>
        <RadioButton checked={selectedClaim === String(claim.id) ? true : false} />
      </S.SelectInput>
      <S.Date>{dayjs(claim.createdAt).format('MM/DD/YYYY')}</S.Date>
      <S.Date># {claim.id}</S.Date>
      <S.Date>{claim.patientName || '-'}</S.Date>
      <S.Date>{claim.patientAccountNumber || '-'}</S.Date>
      <S.Insurance>{claim.insuredName || '-'}</S.Insurance>
    </S.LineWrapper>
  );
};

export default ExistingClaimLine;
