import { useAppSelector } from '../../../hooks/useAppSelector';
import * as S from './styles';

type Props = {
  text?: string;
  $active?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  count?: number;
  appointmentCount?: number;
};

const MenuSectionItem: React.FC<Props> = ({
  text,
  $active = false,
  children,
  onClick,
  className,
  count,
  appointmentCount,
}) => {
  const { isHover } = useAppSelector(state => state.isHover);
  return (
    <S.MenuSectionItemWrapper
      className={className}
      $active={$active}
      $isHover={isHover}
      onClick={onClick}
    >
      <S.Content>
        {children}
        {<S.Text $isHover={isHover}>{text}</S.Text>}
        {count && count !== 0 ? <S.Circle>{count}</S.Circle> : ''}
        {appointmentCount && appointmentCount !== 0 ? (
          <S.Circle style={{ backgroundColor: '#D67300' }}>{appointmentCount}</S.Circle>
        ) : (
          ''
        )}
      </S.Content>
    </S.MenuSectionItemWrapper>
  );
};

export default MenuSectionItem;
