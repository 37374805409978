import * as S from './styles';
import * as AuthS from '../../styles';
import logo from '../../../../assets/images/logo.png';
import { Button, Input } from '../../../../components';
import { useEffect, useState } from 'react';
import { ArrowLSVG, ArrowLeftSVG } from '../../../../assets/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  useLogoutMutation,
  useResendCodeMutation,
  useVerifyResetPasswordMutation,
} from '../../../../store/api/auth/authApi';
import { setMessage } from '../../../../store/slices/message';
import { setUser } from '../../../../store/api/auth/authSlice';
import { Roles } from '../../../../types/Roles';
import { UserStatus } from '../../../../types/UserStatuses';

type FormValues = {
  code: string;
};

const ResetPasswordVerify = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [verifyResetPassword] = useVerifyResetPasswordMutation();
  const [resendCode] = useResendCodeMutation();
  const [logout] = useLogoutMutation();
  useEffect(() => {
    if (!state) {
      navigate('/auth');
    }
  }, []);

  const verificationSchema = yup.object().shape({
    code: yup
      .string()
      .min(6, 'Must be exactly 6 characters')
      .max(6, 'Must be exactly 6 characters')
      .required('Is required'),
  });

  const { values, handleChange, handleSubmit, errors } = useFormik<FormValues>({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validationSchema: verificationSchema,
    onSubmit: async ({ code }) => {
      await verifyResetPassword({ code })
        .unwrap()
        .then(res => {
          navigate('/changepassword', {
            state: { code },
          });
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const [timerValue, setTimerValue] = useState(30);

  const [isWaiting, setIsWaiting] = useState(true);

  const tick = () => {
    if (timerValue > 1) {
      const newVal = timerValue - 1;
      setTimerValue(newVal);
    } else {
      setIsWaiting(true);
      setTimerValue(30);
    }
  };

  const startTimer = () => {
    if (isWaiting === false) {
      setTimeout(tick, 1000);
      return (
        <S.ReceiveCode>
          Please wait until the timer ends to resend the code:
          <S.ResendSpan> {timerValue}</S.ResendSpan>
        </S.ReceiveCode>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <AuthS.AuthWrapper>
          <AuthS.LoginBlock>
            <img className="logo" src={logo} alt="logotype"></img>
            <S.ResetPasswordWrapper>
              <S.Article>
                <ArrowLeftSVG
                  onClick={() => {
                    logout({}).then(() => {
                      dispatch(
                        setUser({
                          role: Roles.UNAUTH,
                          status: UserStatus.UNVERIFIED,
                        }),
                      );
                      navigate('/resetpassword');
                    });
                  }}
                />{' '}
                Reset Password
              </S.Article>
              <S.SubArticle>
                Enter the confirmation code we sent to your{' '}
                {state?.authFieldType ?? 'phone'}
              </S.SubArticle>
              <S.Items>
                <Input
                  id="code"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  label="Confirmation code"
                  error={!!errors.code}
                  helperText={errors.code}
                />
                <S.ButtonWrapper>
                  <Button text="Submit" type="submit" />
                </S.ButtonWrapper>
                {isWaiting && (
                  <S.ReceiveCode>
                    Didn’t receive a code?{' '}
                    <S.ResendSpan
                      onClick={() => {
                        resendCode({ phone: state.phone });
                        setIsWaiting(false);
                      }}
                    >
                      Resend
                    </S.ResendSpan>
                  </S.ReceiveCode>
                )}
                <div>{startTimer()}</div>
              </S.Items>
            </S.ResetPasswordWrapper>
          </AuthS.LoginBlock>
        </AuthS.AuthWrapper>
      </form>
    </>
  );
};

export default ResetPasswordVerify;
