import { ChangeEvent, useEffect, useState } from 'react';
import { CheckBox, Input, SelectInput } from '../../../../../../components';
import * as S from '../../styles';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { FormikProps } from 'formik';
import { FormValuesAuth } from '../../SignUpDetails';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from '../../../../../../constants/statesUSA';
import { race } from '../../../../../../constants/constants';

type Props = {
  formik: FormikProps<FormValuesAuth>;
};

const DemographicInfo: React.FC<Props> = ({ formik }) => {
  const userInfo = useAppSelector(state => state.auth);
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  return (
    <>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.ArticleH3>Demographic Info</S.ArticleH3>
          <S.Inputs>
            <S.InputRow>
              <Input
                label="Ethnicity"
                background="#fff"
                id="ethnicity"
                name="ethnicity"
                value={formik.values.ethnicity}
                error={!!formik.errors.ethnicity}
                helperText={formik.errors.ethnicity}
                onChange={handleChangeInput}
              />
              <Input
                id="nationality"
                name="nationality"
                value={formik.values.nationality}
                error={!!formik.errors.nationality}
                helperText={formik.errors.nationality}
                label="Nationality"
                background="#fff"
                onChange={handleChangeInput}
              />
              <SelectInput
                id="race"
                name="race"
                value={formik.values.race}
                error={!!formik.errors.race}
                helperText={formik.errors.race}
                label="Race"
                background="#fff"
                onChange={(e: SelectChangeEvent<unknown>) => {
                  formik.setFieldError('state', '');
                  formik.handleChange(e);
                }}
              >
                {race.map(race => (
                  <MenuItem key={race.value} value={race.value}>
                    <S.MenuItemContent>{race.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
            </S.InputRow>
            <S.InputRow>
              <Input
                id="gender"
                onChange={handleChangeInput}
                name="gender"
                value={formik.values.gender}
                error={!!formik.errors.gender}
                helperText={formik.errors.gender}
                label="Gender"
                background="#fff"
              />
              <Input
                id="religion"
                name="religion"
                onChange={handleChangeInput}
                value={formik.values.religion}
                error={!!formik.errors.religion}
                helperText={formik.errors.religion}
                label="Religion"
                background="#fff"
              />
            </S.InputRow>
            <S.DontAnswerWrapper>
              <S.ArticleH3>Job Info</S.ArticleH3>
              <S.CheckBoxWrapper>
                <S.CheckBoxLabel className="xl">
                  I do not want to answer
                  <S.TextHelper className="xs">
                    Checkmark if you do not want to fill in all fields
                  </S.TextHelper>
                </S.CheckBoxLabel>
                <CheckBox
                  id="isDoNotAnswer"
                  name="isDoNotAnswer"
                  checked={formik.values.isDoNotAnswer}
                  value={formik.values.isDoNotAnswer}
                  onChange={e => formik.handleChange(e)}
                />
              </S.CheckBoxWrapper>
            </S.DontAnswerWrapper>
            <S.InputRow className="devide">
              <Input
                isRequired
                label="Occupation"
                background="#fff"
                id="employerPosition"
                name="employerPosition"
                value={formik.values.employerPosition}
                error={!!formik.errors.employerPosition}
                helperText={formik.errors.employerPosition}
                onChange={handleChangeInput}
              />
              <Input
                isRequired
                label="Employer name"
                background="#fff"
                id="employerName"
                name="employerName"
                value={formik.values.employerName}
                error={!!formik.errors.employerName}
                helperText={formik.errors.employerName}
                onChange={handleChangeInput}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                isRequired
                label="Employee address"
                background="#fff"
                id="employerAddress"
                name="employerAddress"
                value={formik.values.employerAddress}
                error={!!formik.errors.employerAddress}
                helperText={formik.errors.employerAddress}
                onChange={handleChangeInput}
              />
            </S.InputRow>
          </S.Inputs>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default DemographicInfo;
