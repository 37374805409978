import { ChangeEvent, useEffect, useState } from 'react';
import {
  useTemplateInsertSignatureMutation,
  useTemplatePrintPDFMutation,
  useTemplatesGetFieldByIdMutation,
} from '../../../../../../store/api/templates/templateApi';
import {
  TemplateFieldsForFill,
  TemplateType,
} from '../../../../../../types/TemplateType';
import * as S from './styles';
import { Button, CircularLoader, DatePicker } from '../../../../../../components';
import CaseInputs from './caseInputs';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import PDFDrawer from '../../../../../../components/organismus/PDFDrawer';
import ReactSignatureCanvas from 'react-signature-canvas';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  row: TemplateType;
  setIsShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
};

export type FillTemplateValues = {
  guardian: string | null;
  patient: string | null;
  patientSignature: any;
  doctor: string | null;
  clinic: string | null;
  date: string | Dayjs;
};

const StartPDFDialog: React.FC<Props> = ({
  onClose,
  row,
  isOpen,
  setIsShowSuccess,
  setUserId,
}) => {
  const [getFields, getFieldsStatus] = useTemplatesGetFieldByIdMutation({});
  useEffect(() => {
    isOpen && getFields({ id: row.key });
  }, [isOpen]);

  const validationSchema = yup.object().shape({
    ...(getFieldsStatus.data &&
      getFieldsStatus.data.includes(TemplateFieldsForFill.PATIENT) && {
        patient: yup.string().required('Is required'),
      }),
  });

  const [printPDF, printPDFStatus] = useTemplatePrintPDFMutation({});
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [pdfBlobURL, setPdfBlobURL] = useState(null);

  const [insertSignature, insertSignatureStatus] = useTemplateInsertSignatureMutation({});

  const formik = useFormik<FillTemplateValues>({
    initialValues: {
      guardian: '',
      patient: '',
      patientSignature: '',
      doctor: '',
      clinic: '',
      date: dayjs(),
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: () => {
      const nonEmptyFormValues = Object.fromEntries(
        Object.entries(formik.values).filter(([_, value]) => value !== ''),
      );

      printPDF({
        templateName: row.key,
        date: dayjs(formik.values.date).format('MM/DD/YYYY'),
        ...(nonEmptyFormValues.patient && {
          patientId: nonEmptyFormValues.patient,
        }),
        ...(nonEmptyFormValues.guardian && {
          guardian: nonEmptyFormValues.guardian,
        }),
        ...(nonEmptyFormValues.doctor && {
          doctorId: nonEmptyFormValues.doctor,
        }),
        clinicId: selectedClinic.id,
        // payload: JSON.stringify({
        //   patient: { accountNumber: "S-FCC-00002" },
        //   clinic: { name: "Family clinic" },
        // }),
      })
        .unwrap()
        .then(res => setPdfBlobURL(res))
        .catch(err => onClose());
    },
  });

  const [sig, setSig] = useState<any>(null);

  const dataURItoBlob = (dataURI: string) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Content>
        {getFieldsStatus.isLoading ||
        printPDFStatus.isLoading ||
        insertSignatureStatus.isLoading ? (
          <S.Loader>
            <CircularLoader color="#0084B1" />
          </S.Loader>
        ) : (
          <>
            {pdfBlobURL ? (
              <>
                <PDFDrawer file={pdfBlobURL} />
                <S.Article>Please, put yor signature</S.Article>
                <ReactSignatureCanvas
                  backgroundColor="#fff"
                  penColor="black"
                  canvasProps={{
                    width: '400px',
                    height: '100px',
                    className: 'sigCanvas',
                  }}
                  ref={ref => setSig(ref)}
                />
                <S.ButtonWrapper>
                  <Button
                    text="Confirm with signature"
                    onClick={() => {
                      const nonEmptyFormValues = Object.fromEntries(
                        Object.entries(formik.values).filter(
                          ([_, value]) => value !== '',
                        ),
                      );

                      const blob = dataURItoBlob(sig.getCanvas().toDataURL('image/png'));

                      insertSignature({
                        templateName: row.key,
                        date: dayjs(formik.values.date).format('MM/DD/YYYY'),
                        ...(nonEmptyFormValues.patient && {
                          patientId: nonEmptyFormValues.patient,
                        }),
                        ...(nonEmptyFormValues.guardian && {
                          guardian: nonEmptyFormValues.guardian,
                        }),
                        ...(nonEmptyFormValues.doctor && {
                          doctorId: nonEmptyFormValues.doctor,
                        }),
                        clinicId: selectedClinic.id,
                        file: blob,
                      })
                        .unwrap()
                        .then(res => {
                          formik.values.patient && setUserId(formik.values.patient);
                          setIsShowSuccess(true);
                        });
                    }}
                  />
                </S.ButtonWrapper>
              </>
            ) : (
              <>
                <S.Article>Fill Template</S.Article>
                {getFieldsStatus.data &&
                  getFieldsStatus.data.map(item => {
                    return <CaseInputs key={item} item={item} formik={formik} />;
                  })}
                <S.InputWrapper>
                  <DatePicker
                    label="Date"
                    id={`date`}
                    name={`date`}
                    value={dayjs(formik.values.date)}
                    onChange={value => {
                      formik.setFieldError(`date`, '');
                      formik.setFieldValue(`date`, value);
                    }}
                  />
                </S.InputWrapper>
                <S.ButtonWrapper>
                  <Button text="Start" type="submit" />
                </S.ButtonWrapper>
              </>
            )}
          </>
        )}
      </S.Content>
    </form>
  );
};

export default StartPDFDialog;
