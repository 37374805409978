import * as S from './styles';
import logo from '../../../../assets/images/logo.png';
import { CloseBtnSVG } from '../../../../assets/icons';
import { Button, CheckBox, Dialog } from '../../../../components';
import { ChangeEvent, useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProfileSignUp from './components/ProfileSignUp';
import ContactSignUp from './components/ContactSignUp';
import HealthSignUp from './components/HealthSignUp';
import InsuranceSignUp from './components/InsuranceSignUp/InsuranceSignUp';
import SecondarySignUp from './components/SecondarySignUp';
import DocumentsSignUp from './components/DocumentsSignUp';
import { useFormik, Form } from 'formik';
import * as yup from 'yup';
import { useProfileCreateMutation } from '../../../../store/api/profile/profileApi';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import {
  useFilesCreateMutation,
  useUploadInitialDocsMutation,
} from '../../../../store/api/files/files';
import {
  useUpdateAvatarMutation,
  useUpdateProfileAvatarMutation,
} from '../../../../store/api/user/profileApi';
import { setMessage } from '../../../../store/slices/message';
import { useLogoutMutation } from '../../../../store/api/auth/authApi';
import { setUser } from '../../../../store/api/auth/authSlice';
import { Roles } from '../../../../types/Roles';
import { UserStatus } from '../../../../types/UserStatuses';
import DemographicInfo from './components/DemographicInfo';
import { File, FileInsurance } from '../../../../types/FileType';
import { emailValidator, phoneValidator } from 'constants/constants';

export type FormValuesAuth = {
  firstName: string;
  lastName: string;
  sex: string;
  mi: string;
  gender: string;
  dateOfBirth: Date | null;
  height: string;
  weight: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  cellPhone: string;
  homePhone: string;
  email: string;
  emergencyContact: string;
  emergencyPhone: string;
  employerName: string;
  employerPosition: string;
  employerAddress: string;
  isContagiousDisease: boolean;
  isCoagulation: boolean;
  isPacemaker: boolean;
  isMetalSupport: boolean;
  isPregnant: boolean;
  isNoFault: boolean;
  isWorkersCompensation: boolean;
  primaryInsurance: string;
  companyPhone: string;
  subscriberName: string;
  subscriberDOB: Date | null;
  relationshipOfPatient: string;
  insuredSSN: string;
  insuredID: string;
  insuredGroup: string;
  insuredGroupName: string;
  secPrimaryInsurance: string;
  secCompanyPhone: string;
  secSubscriberName: string;
  secSubscriberDOB: Date | null;
  secRelationshipOfPatient: string;
  secInsuredSSN: string;
  ethnicity: string;
  secInsuredID: string;
  secInsuredGroup: string;
  secInsuredGroupName: string;
  avatar: File | null;
  documents: File[] | null;
  nationality: string;
  insurancefileId: string;
  race: string;
  religion: string;
  isDoNotAnswer: boolean;
  files: Array<File>;
  secFiles: Array<File>;
};

const SignUpDetails = () => {
  const userId = useAppSelector(state => state.auth.id);
  const userInfo = useAppSelector(state => state.auth);
  const [logout] = useLogoutMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const handleShowDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const [profile, statusProfileCreate] = useProfileCreateMutation();
  const [uploadPhoto, statusUploadPhoto] = useUpdateProfileAvatarMutation();
  const [filesCreate, statusFilesCreate] = useFilesCreateMutation();
  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadInitialDocsMutation({});
  const dispatch = useDispatch();

  const [doNotDisturb, setDoNotDisturb] = useState(false);

  const navigate = useNavigate();
  const handleSubmitButton = async () => {
    const filesToServ = values.files.map(item => item.id);
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      sex: values.sex,
      gender: values.gender,
      mi: values.mi,
      email: values.email,
      dateOfBirth: values.dateOfBirth,
      height: values.height,
      weight: Number(values.weight),
      address: values.address,
      secondAddressLine: values.address2,
      city: values.city,
      state: values.state,
      zipCode: values.zipcode,
      cellPhone: values.cellPhone,
      phone: values.cellPhone,
      ...(values.homePhone && { homePhone: values.homePhone }),
      emergencyContact: values.emergencyContact,
      emergencyContactPhone: values.emergencyPhone,
      employerName: values.employerName,
      employerPosition: values.employerPosition,
      employerAddress: values.employerAddress,
      userId: userId,
      ethnicity: values.ethnicity,
      ...(values.race && { race: values.race }),

      nationality: values.nationality,
      religion: values.religion,
      health: {
        isContagiousDisease: values.isContagiousDisease,
        isCoagulationDysfunction: values.isCoagulation,
        isPacemaker: values.isPacemaker,
        isMetalSupport: values.isMetalSupport,
        isPregnant: values.isPregnant,
      },
      isNoFault: values.isNoFault,
      isWorkersCompensation: values.isWorkersCompensation,
      insurances: [] as any[],
    };
    values.primaryInsurance &&
      data.insurances.push({
        primaryInsuranceCompany: values.primaryInsurance,
        insuranceName: values.primaryInsurance,
        isPrimary: true,
        companyPhone: values.companyPhone,
        subscriberName: values.subscriberName,
        dateOfBirth: values.subscriberDOB,
        relation: values.relationshipOfPatient,
        ssn: values.insuredSSN,
        groupNumber: String(values.insuredGroup),
        groupName: values.insuredGroupName,
        files: filesToServ,
      });
    values.secPrimaryInsurance &&
      data.insurances.push({
        insuranceName: values.secPrimaryInsurance,
        primaryInsuranceCompany: values.secPrimaryInsurance,
        companyPhone: values.secCompanyPhone,
        subscriberName: values.secSubscriberName,
        dateOfBirth: values.secSubscriberDOB,
        isPrimary: false,
        relation: values.secRelationshipOfPatient,
        ssn: values.secInsuredSSN,
        groupNumber: String(values.secInsuredGroup),
        groupName: values.secInsuredGroupName,
      });

    await profile(data)
      .unwrap()
      .then(async res => {
        values.documents?.length &&
          (await uploadInitialFiles({
            files: values.documents,
          })
            .unwrap()
            .then(res => {})
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            }));
        values.avatar &&
          (await uploadPhoto({ id: userInfo.id, avatar: values.avatar })
            .unwrap()
            .then(res => {})
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            }));
        window.location.reload();
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const [checkBoxDialog, setCheckBoxDialog] = useState(false);

  const steps = [
    'Profile info ',
    'Contact info',
    'Demographic info',
    'Health info',
    'Primary insurance',
    'Secondary insurance',
    'Documents',
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    if (activeStep === 6 && values.primaryInsurance === '') {
      setActiveStep(prevActiveStep => prevActiveStep - 2);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const validateSteps = () => {
    switch (activeStep) {
      case 0:
        return ProfileSchema;
      case 1:
        return ContactSchema;
      case 2:
        return DemographicSchema;
      case 3:
        return HealthSchema;
      case 4:
        return InsuranceSchema;
      case 5:
        return SecondaryInsuranceSchema;
      case 6:
        return DocumentsSchema;
    }
  };

  const ProfileSchema = yup.object().shape({
    firstName: yup.string().required('Is required'),
    lastName: yup.string().required('Is required'),
    sex: yup.string().required('Is required'),
    mi: yup.string(),
    dateOfBirth: yup.date().required('Is required'),
    height: yup.string().min(1, 'Min value is 1').max(10, 'Max value is 10'),

    weight: yup.number().min(10, 'Min value is 10').max(1000, 'Max value is 1000'),
    avatar: yup.mixed().nullable(),
  });

  const ContactSchema = yup.object().shape({
    address: yup.string().required('Is required'),
    city: yup.string().required('Is required'),
    state: yup.string().required('Is required'),
    zipcode: yup.string().required('Is required'),
    cellPhone: phoneValidator,
    homePhone: phoneValidator,
    email: emailValidator.required('Is required'),
    emergencyContact: yup.string(),
    emergencyPhone: phoneValidator,
    employerName: yup.string(),
    employerPosition: yup.string(),
    employerAddress: yup.string(),
  });

  const HealthSchema = yup.object().shape({
    isContagiousDisease: yup.boolean(),
    isCoagulation: yup.boolean(),
    isPacemaker: yup.boolean(),
    isMetalSupport: yup.boolean(),
    isPregnant: yup.boolean(),
  });

  const InsuranceSchema = yup.object().shape({
    isNoFault: yup.boolean(),
    isWorkersCompensation: yup.boolean(),
    primaryInsurance: yup.string().required('Is required'),
    companyPhone: phoneValidator,
    subscriberName: yup.string().required('Is required'),
    subscriberDOB: yup.date().required('Is required'),
    relationshipOfPatient: yup.string().required('Is required'),
    insuredSSN: yup.string(),
    insuredID: yup.string().required('Is required'),
    insuredGroup: yup.string().required('Is required'),
    insuredGroupName: yup.string(),
    files: yup.array().min(1),
  });

  const SecondaryInsuranceSchema = yup.object().shape({
    secPrimaryInsurance: yup.string().required('Is required'),
    secCompanyPhone: phoneValidator,
    secSubscriberName: yup.string().required('Is required'),
    secSubscriberDOB: yup.date().required('Is required'),
    secRelationshipOfPatient: yup.string().required('Is required'),
    secInsuredSSN: yup.string(),
    secInsuredID: yup.string().required('Is required'),
    secInsuredGroup: yup.string().required('Is required'),
    secInsuredGroupName: yup.string(),
    secFiles: yup.array().min(1),
  });

  const DocumentsSchema = yup.object().shape({
    documents: yup.mixed(),
  });

  const DemographicSchema = yup.object().shape({
    ethnicity: yup.string(),
    race: yup.string(),
    nationality: yup.string(),
    gender: yup.string(),
    religion: yup.string(),
    employerPosition: doNotDisturb ? yup.string() : yup.string().required('Is required'),
    employerName: doNotDisturb ? yup.string() : yup.string().required('Is required'),
    employerAddress: doNotDisturb ? yup.string() : yup.string().required('Is required'),
  });

  const formik = useFormik<FormValuesAuth>({
    initialValues: {
      documents: [],
      avatar: null,
      firstName: '',
      lastName: '',
      address2: '',
      ethnicity: '',
      sex: '',
      files: [],
      secFiles: [],
      mi: '',
      gender: '',
      insurancefileId: '',
      dateOfBirth: null,
      height: '',
      weight: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      nationality: '',
      race: '',
      religion: '',
      isDoNotAnswer: false,
      cellPhone: userInfo?.phone || '',
      homePhone: '',
      email: '',
      emergencyContact: '',
      emergencyPhone: '',
      employerName: '',
      employerPosition: '',
      employerAddress: '',
      isContagiousDisease: false,
      isCoagulation: false,
      isPacemaker: false,
      isMetalSupport: false,
      isPregnant: false,
      isNoFault: false,
      isWorkersCompensation: false,
      primaryInsurance: '',
      companyPhone: '',
      subscriberName: '',
      subscriberDOB: null,
      relationshipOfPatient: '',
      insuredSSN: '',
      insuredID: '',
      insuredGroup: '',
      insuredGroupName: '',
      secPrimaryInsurance: '',
      secCompanyPhone: '',
      secSubscriberName: '',
      secSubscriberDOB: null,
      secRelationshipOfPatient: '',
      secInsuredSSN: '',
      secInsuredID: '',
      secInsuredGroup: '',
      secInsuredGroupName: '',
    },
    validateOnChange: false,
    validationSchema: validateSteps(),
    onSubmit: () => {
      handleNext();
    },
  });

  const { values, handleSubmit } = formik;

  useEffect(() => {
    setDoNotDisturb(formik.values.isDoNotAnswer);
  }, [formik.values.isDoNotAnswer]);

  const handleSkip = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    formik.setValues({
      ...values,
      secPrimaryInsurance: '',
      secCompanyPhone: '',
      secSubscriberName: '',
      secRelationshipOfPatient: '',
      secSubscriberDOB: null,
      secInsuredID: '',
      secInsuredGroup: '',
      secInsuredSSN: '',
      secInsuredGroupName: '',
    });
  };

  const handleSkipPrimary = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 2);
    formik.setValues({
      ...values,
      primaryInsurance: '',
      companyPhone: '',
      subscriberName: '',
      relationshipOfPatient: '',
      subscriberDOB: null,
      insuredID: '',
      insuredGroup: '',
      insuredSSN: '',
      files: [],
      insuredGroupName: '',
    });
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.SignUpDetailsWrapper>
          {/* <S.ContentWrapper> */}
          <S.Header>
            <img className="logo" src={logo} alt="logotype"></img>
          </S.Header>
          <S.StepperWrapper>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </S.StepperWrapper>

          <S.Content>
            {activeStep === 0 && (
              <>
                <ProfileSignUp formik={formik} />
              </>
            )}
            {activeStep === 1 && (
              <>
                <ContactSignUp formik={formik} />
              </>
            )}

            {activeStep === 2 && (
              <>
                <DemographicInfo formik={formik} />
              </>
            )}

            {activeStep === 3 && (
              <>
                <HealthSignUp formik={formik} />
              </>
            )}
            {activeStep === 4 && (
              <>
                <InsuranceSignUp formik={formik} step={activeStep} />
              </>
            )}
            {activeStep === 5 && (
              <>
                <SecondarySignUp formik={formik} step={activeStep} />
              </>
            )}

            {activeStep === 6 && (
              <>
                <DocumentsSignUp formik={formik} />
              </>
            )}
          </S.Content>
          <S.Footer>
            <S.FooterButtonWrapper>
              <S.ButtonWrapperNext>
                <Button
                  text="Logout"
                  onClick={() => {
                    logout({}).then(() =>
                      dispatch(
                        setUser({
                          role: Roles.UNAUTH,
                          status: UserStatus.UNVERIFIED,
                        }),
                      ),
                    );
                  }}
                  backgroundColor="#C66060"
                ></Button>
              </S.ButtonWrapperNext>
              <S.HandleButtons>
                {activeStep === 5 && (
                  <S.ButtonWrapperNext>
                    <Button
                      text="Skip"
                      onClick={handleSkip}
                      backgroundColor="#848A9B"
                    ></Button>
                  </S.ButtonWrapperNext>
                )}
                {activeStep === 4 && (
                  <S.ButtonWrapperNext>
                    <Button
                      text="Skip"
                      onClick={handleSkipPrimary}
                      backgroundColor="#848A9B"
                    ></Button>
                  </S.ButtonWrapperNext>
                )}
                {activeStep !== 0 && (
                  <S.ButtonWrapperNext>
                    <Button text="Back" onClick={handleBack}></Button>
                  </S.ButtonWrapperNext>
                )}
                {activeStep !== steps.length - 1 && (
                  <S.ButtonWrapperNext>
                    <Button text="Next" type="submit"></Button>
                  </S.ButtonWrapperNext>
                )}
                {activeStep === steps.length - 1 && (
                  <S.ButtonWrapperNext>
                    <Button text="Submit" onClick={handleShowDialog}></Button>
                  </S.ButtonWrapperNext>
                )}
              </S.HandleButtons>
            </S.FooterButtonWrapper>
          </S.Footer>
        </S.SignUpDetailsWrapper>
      </form>

      <Dialog open={openDialog} onClose={handleShowDialog}>
        <S.DialogContent>
          <S.DialogArticle>Account verification</S.DialogArticle>
          <S.DialogSubArticle>
            Your Account need to be verified by our specialist. We will contact you
            shortly
          </S.DialogSubArticle>
          <S.DialogCheckboxWrapper>
            <CheckBox
              checked={checkBoxDialog}
              onChange={() => setCheckBoxDialog(!checkBoxDialog)}
            ></CheckBox>
            <S.DialogTextCheckbox>
              Sign the Registration Form. By this action you confirm that provided
              information is true{' '}
            </S.DialogTextCheckbox>
          </S.DialogCheckboxWrapper>
          <S.ButtonWrapper>
            <Button
              text="Submit"
              onClick={handleSubmitButton}
              disabled={
                !checkBoxDialog ||
                uploadInitialFilesStatus.isLoading ||
                statusProfileCreate.isLoading ||
                statusUploadPhoto.isLoading
              }
            />
          </S.ButtonWrapper>
        </S.DialogContent>
      </Dialog>
    </>
  );
};

export default SignUpDetails;
