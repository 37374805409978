import { ClosePinSVG } from '../../../../assets/icons';
import * as S from './styles';

type Props = {
  text: string;
  isCantUnpin?: boolean;
  onDelete?: () => void;
};

const SelectedCard = ({ text, isCantUnpin, onDelete }: Props) => {
  return (
    <S.Wrapper>
      <S.Text>{text}</S.Text>
      {!isCantUnpin && <ClosePinSVG onClick={() => onDelete && onDelete()} />}
    </S.Wrapper>
  );
};

export default SelectedCard;
