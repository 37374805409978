import styled from 'styled-components';
import { devices } from 'constants/device';
import { Slider } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 538px;
  overflow-y: auto;
  overflow-x: hidden;

  @media ${devices.xs} {
    width: 375px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 435px;
  gap: 18px;

  &.mt {
    margin: 9px 0 21px 6px;
  }

  @media ${devices.xs} {
    width: 330px;
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 10px;
  left: 48%;
`;

export const CheckBoxTitle = styled.div`
  display: flex;
  align-items: center;
  //width: 435px;
  gap: 18px;

  &.mt {
    margin: 9px 0 21px 6px;
  }

  &.ml {
    margin: 0 0 0 15px;
  }

  @media ${devices.xs} {
    width: 330px;
  }
`;

export const CheckBoxLabel = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.xl {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const StyledSlider = styled(Slider)`
  background-image: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(0, 100%, 50%)
  );
`;

export const AlphaStyledSlider = styled(Slider)`
  background-image: linear-gradient(
    to right,
    hsl(0, 0%, 0%),
    hsl(0, 0%, 20%),
    hsl(0, 0%, 40%),
    hsl(0, 0%, 60%),
    hsl(0, 0%, 80%),
    hsl(0, 0%, 100%)
  );
`;

export const ArticleWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;
  gap: 15px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  &.withoutMargin {
    margin-top: 0;
  }

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const TableBody = styled.div`
  margin-top: 10px;
`;

export const Empty = styled.div<{ height?: string }>`
  margin-top: ${props => props.height || `5px`};
  margin-bottom: ${props => props.height || `5px`};
`;

export const Services = styled.div`
  border-radius: 5px;
  background: #f9f9fc;
  padding: 20px 25px 20px 45px;
  width: 430px;
  display: flex;
  justify-content: flex-start;
  gap: 30px 40px;
  flex-wrap: wrap;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;

  @media ${devices.xs} {
    width: 100%;
    background: none;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 18px;
  }
`;

export const BtnWrapper = styled.div`
  width: 174px;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;
