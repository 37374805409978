import { ChangeEvent, useMemo, useState } from 'react';
import { Button, Input } from '../../../../../components';
import * as S from './styles';
import {
  useHoldAppointmentMutation,
  useHoldBillingMutation,
} from '../../../../../store/api/user/profileApi';
import { setMessage } from '../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { notesApi } from '../../../../../store/api/notes/noteApi';

type Props = {
  onClose: () => void;
  type: string;
  id?: string;
};

const ReasonAddDialog: React.FC<Props> = ({ onClose, type, id }) => {
  const [note, setNote] = useState('');
  const dispatch = useAppDispatch();

  const [holdAppointment] = useHoldAppointmentMutation({});
  const [holdBilling] = useHoldBillingMutation({});

  const handleConfirmClick = () => {
    onClose();
    type === 'Billing'
      ? holdBilling({
          id,
          text: note,
        })
          .unwrap()
          .then(res => dispatch(setMessage({ message: res.message, type: 'success' })))
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          })
      : holdAppointment({
          id,
          text: note,
        })
          .unwrap()
          .then(res => dispatch(setMessage({ message: res.message, type: 'success' })))
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
  };

  const disableButton = useMemo(() => !note, [note]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };
  return (
    <S.Content>
      <S.Text>
        <S.Article>{type === 'Billing' ? 'Hold Billing' : 'Hold Appointments'}</S.Article>
        <S.SubArticle>Enter the reason for the hold</S.SubArticle>
      </S.Text>
      <S.InputWrapper>
        <Input
          multiline
          rows={9}
          label="Reason body"
          value={note}
          onChange={handleChangeInput}
        />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button text="Save" onClick={handleConfirmClick} disabled={disableButton} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default ReasonAddDialog;
