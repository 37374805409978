export enum GroupHealthPlanEnum {
  MEDICARE = 'MEDICARE',
  MEDICAID = 'MEDICAID',
  TRICARE = 'TRICARE',
  CHAMPVA = 'CHAMPVA',
  GROUPHEALTHPLAN = 'GROUP HEALTH PLAN',
  FECABLKLUNG = 'FECA BLKL UNG',
  OTHER = 'Other',
}

export type ClaimErrorHistoryType = {
  id: string;
  field: string;
  type: string;
  description: string;
  claimId: number;
  createdAt: string;
  updatedAt: string;
};

export type ClaimEventHistoryType = {
  id: string;
  modifierId?: string;
  claimId: number;
  status: string;
  claimType: string;
  changeList?: Array<{
    to: string;
    key: string;
    from: string;
  }>;
  createdAt: string;
  updatedAt: string;
  modifier?: {
    id: string;
    isHoldAppointment: any;
    isHoldBilling: any;
    phone: string;
    profile: {
      id: string;
      firstName: string;
      lastName: string;
      cellPhone: any;
    };
    avatar: any;
  };
};
