import styled from 'styled-components';

export const Text = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AppointmentLink = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 18px;
  }
`;
