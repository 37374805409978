import { FormikProps } from 'formik';
import { Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import ReactInputMask from 'react-input-mask';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  setIsDirty?: () => void;
};
const CRContacts = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Article>Contacts</S.Article>
      <S.Line>
        <S.InputWrapper className="px240">
          <ReactInputMask
            mask="+19999999999"
            defaultValue={formik.values?.contacts?.homePhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'contacts.homePhone', ref, setIsDirty)
            }
          >
            <Input label={'Home Phone'} />
          </ReactInputMask>
        </S.InputWrapper>

        <S.InputWrapper className="px240">
          <ReactInputMask
            mask="+19999999999"
            defaultValue={formik.values?.contacts?.workPhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'contacts.workPhone', ref, setIsDirty)
            }
          >
            <Input label={'Work Phone'} />
          </ReactInputMask>
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <ReactInputMask
            mask="+19999999999"
            defaultValue={formik.values?.contacts?.cellPhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'contacts.cellPhone', ref, setIsDirty)
            }
          >
            <Input label={'Cell Phone'} />
          </ReactInputMask>
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.Caption>How do you prefer to receive your statements:</S.Caption>
        <CheckBoxWithLabel label="E-mail" />
        <CheckBoxWithLabel label="Fax" />
        <CheckBoxWithLabel label="Mail" />
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'E-mail'}
            defaultValue={formik.values?.contacts?.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'contacts.email', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Fax'}
            defaultValue={formik.values.contacts.fax}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'contacts.fax', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
    </>
  ),
);

export default CRContacts;
