import { useEffect, useState } from 'react';
import { LinearLoader, TicketRecord } from '../../../../../../components';
import {
  useLazyTicketsOutgoingGetQuery,
  useTicketsIncomingGetQuery,
  useTicketsOutgoingGetQuery,
} from '../../../../../../store/api/tickets/ticketApi';
import { Roles } from '../../../../../../types/Roles';
import * as S from '../../styles';
import TicketsPlaceholder from '../TicketsPlaceholder';

type Props = {
  clinicId: string;
  debouncedSearch: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
};

const Outgoing = ({ clinicId, debouncedSearch, page, setPage }: Props) => {
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  const [isRefetch, setIsRefetch] = useState(false);

  // useEffect(() => {
  //   setPage(1);
  // }, [debouncedSearch, setPage]);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
      }
      return;
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  const [getOutgoingTickets, status] = useLazyTicketsOutgoingGetQuery({});

  useEffect(() => {
    getOutgoingTickets({
      'clinicId[]': [clinicId],
      page: page,
      take: 40,
      userName: debouncedSearch,
    });
  }, [page]);

  useEffect(() => {
    getOutgoingTickets({
      'clinicId[]': [clinicId],
      page: 1,
      take: 40,
      userName: debouncedSearch,
    });
  }, [debouncedSearch, clinicId]);

  return (
    <S.RecordsWrapper>
      {status.currentData?.rows && status.currentData?.rows.length
        ? status.currentData?.rows.map((item, index) => {
            const isLast =
              status.currentData?.rows &&
              index === status.currentData?.rows?.length - 1 &&
              status.currentData &&
              page < status.currentData.lastPage;
            return (
              <div key={item.id + index} ref={isLast ? setObserverTarget : undefined}>
                <TicketRecord
                  setIsRefetch={setIsRefetch}
                  key={item.id}
                  ticket={item}
                  isCanDelete
                  setPage={setPage}
                  isFromOutgoing
                />
              </div>
            );
          })
        : !(status.isLoading || status.isFetching) && (
            <TicketsPlaceholder type="incoming" />
          )}
      {(status.isLoading || status.isFetching) && (
        <S.Loader>
          <LinearLoader />
        </S.Loader>
      )}
    </S.RecordsWrapper>
  );
};

export default Outgoing;
