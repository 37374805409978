import React, { useEffect, useState } from 'react';
import * as S from './styles';
import Button from 'components/atoms/Button';
import SearchInput from 'components/atoms/SearchInput';
import { useDebounce } from 'use-debounce';
import { CustomTable, RadioButton } from 'components';
import { useLazyGetPlaceOfServiceQuery } from 'store/api/claims/claimsApi';
import { TableCell, TableRow } from '@mui/material';

export type PlaceOfServiceType = {
  description: string;
  id: string;
  row_num: number;
  value: string;
};

type Props = {
  onApplyClick: (code: string) => void;
  currentValue?: string;
};

const headers = [
  { name: '', key: 'checkbox' },
  { name: 'Value', key: 'value' },
  { name: 'Description', key: 'description' },
];

export const FieldLookup = ({ onApplyClick, currentValue }: Props) => {
  const [search, setSearch] = useState('');
  const [getData, statusPlaceOfService] = useLazyGetPlaceOfServiceQuery({});
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedItem, setSelectedItem] = useState<PlaceOfServiceType | null>(null);

  useEffect(() => {
    getData({ search: debouncedSearch || undefined });
  }, [debouncedSearch, getData]);

  useEffect(() => {
    if (currentValue) {
      setSelectedItem(
        statusPlaceOfService.data?.find(i => i.value === currentValue) ?? null,
      );
    }
  }, [statusPlaceOfService]);

  return (
    <S.Content>
      <S.Article>Field Lookup</S.Article>
      <S.SearchWrapper>
        <SearchInput
          isSearch={!!search}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </S.SearchWrapper>
      <S.Table>
        <CustomTable headers={headers}>
          {!!statusPlaceOfService.data?.length &&
            statusPlaceOfService.data?.map(row => (
              <TableRow
                key={row.id}
                sx={{
                  '& .MuiTableCell-root': {
                    background: selectedItem?.id === row.id ? '#e7e8ec' : '',
                  },
                }}
              >
                <TableCell>
                  <RadioButton
                    onClick={() =>
                      selectedItem?.id === row.id
                        ? setSelectedItem(null)
                        : setSelectedItem(row)
                    }
                    checked={selectedItem?.id === row.id}
                  />
                </TableCell>
                <TableCell>
                  <S.Text>{row.value}</S.Text>
                </TableCell>
                <TableCell>
                  <S.Text>{row.description}</S.Text>
                </TableCell>
              </TableRow>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem?.value ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
