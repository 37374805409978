import { useDispatch } from 'react-redux';
import { Button } from '../../../../../../components';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import * as S from './styles';
import { DrawerMode } from '../../../../../../types/DrawerMode';

type Props = {
  onClose: () => void;
};

const Dialog_AppointmentVerification: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    onClose();
    dispatch(
      showDrawer({
        show: false,
        mode: DrawerMode.DEFAULT,
        props: null,
      }),
    );
  };

  return (
    <S.Content>
      <S.Article>Appointment verification</S.Article>
      <S.SubArticle>
        Your Appointment need to be verified by our specialist. We will contact you
        shortly
      </S.SubArticle>
      <S.ButtonWrapper>
        <Button text="Ok" onClick={handleConfirmClick} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default Dialog_AppointmentVerification;
