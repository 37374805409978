import dayjs from 'dayjs';
import { ucFirst } from '../../../../../../helpers/functions/toUpperCase';
import * as S from './styles';
import {
  DeleteBtnSVG,
  DeleteRedBtnSVG,
  EditBtnMinimalSVG,
  EditBtnSVG,
} from '../../../../../../assets/icons';
import { useAppointmentGetOneMutation } from '../../../../../../store/api/appointment/appointmentApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../types/DrawerMode';
import { Dialog } from '../../../../../../components';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';
import { useState } from 'react';
import { useCashboxDeleteRowMutation } from '../../../../../../store/api/cash/cashboxApi';
import { setMessage } from '../../../../../../store/slices/message';

type Props = {
  row: any;
  header: string;
  showCashBox: () => void;
};

const CashTableData: React.FC<Props> = ({ row, header, showCashBox }) => {
  const [getAppointmentOne] = useAppointmentGetOneMutation({});

  const [deleteCashBoxRow] = useCashboxDeleteRowMutation({});

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const dispatch = useAppDispatch();

  switch (header) {
    case 'PatientName': {
      return (
        row.patient?.profile?.firstName + ' ' + row.patient?.profile?.lastName || '-'
      );
    }
    case 'Date': {
      return dayjs(row.date).format('MM/DD/YYYY') || '-';
    }
    case 'Appointment': {
      return row.appointmentId ? (
        <S.Link
          onClick={() =>
            getAppointmentOne({
              id: row.appointmentId,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.INFO_APPOINTMENT,
                    props: res,
                  }),
                );
              })
          }
        >
          {'#' + row.appointmentId.slice(0, 8)}
        </S.Link>
      ) : (
        '-'
      );
    }
    case 'In': {
      return row.in ? row.in + ' USD' : '0 USD';
    }
    case 'Out': {
      return row.out ? row.out + ' USD' : '0 USD';
    }
    case 'Total': {
      return row.total ? row.total + ' USD' : '0 USD';
    }
    case 'CreditCard': {
      return row.creditCardCash ? row.creditCardCash + ' USD' : '0 USD';
    }
    case 'Note': {
      return row.note || '-';
    }

    case 'Edit': {
      return (
        <S.CellWrapper>
          <Dialog open={openDialog} onClose={handleChangeDialog}>
            <ConfirmDeleteDialog
              onClose={handleChangeDialog}
              titleText={'cash row'}
              onSuccess={() =>
                deleteCashBoxRow({ id: row.id })
                  .unwrap()
                  .then(res => {
                    dispatch(
                      setMessage({
                        message: 'Cash row was successfully deleted',
                        type: 'success',
                      }),
                    );
                  })
                  .catch(error => {
                    dispatch(setMessage({ message: error.data.message, type: 'error' }));
                  })
              }
            />
          </Dialog>
          <EditBtnMinimalSVG onClick={showCashBox} />
          {!row.appointmentId ? (
            <DeleteRedBtnSVG onClick={handleChangeDialog} />
          ) : (
            <S.Empty></S.Empty>
          )}
        </S.CellWrapper>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default CashTableData;
