import * as S from './styles';
import { useFormik } from 'formik';
import { ChangeEvent } from 'react';
import * as yup from 'yup';

type FormValues = {
  note: string;
};

const AddHealthRecords = () => {
  const Shape = yup.object().shape({});

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      note: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: () => {},
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };
  return <S.Wrapper></S.Wrapper>;
};

export default AddHealthRecords;
