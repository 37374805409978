import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import { generateStylesConfig } from './generateStylesConfig';

type Props = {
  isPadding?: boolean;
  placeholder?: string;
  children: React.ReactNode;
};

const FieldSection = ({ isPadding, placeholder, children }: Props) => {
  const styles = generateStylesConfig();
  return <View style={styles.section}>{children}</View>;
};

export default FieldSection;
