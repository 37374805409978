import { useEffect, useState } from 'react';
import { FilterSVG } from '../../../../../../assets/icons';
import { NotificationPopover } from '../../../../../../components';
import * as S from './styles';
import FilterContent from '../FilterContent';
import { SelectChangeEvent } from '@mui/material';
import {
  FilterCounterValuesType,
  FilterValuesType,
} from '../../../../../../helpers/FilterSearch/filterSearch';

type Props = {
  isLoading: boolean;
  filterValues: FilterValuesType;
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValuesType>>;
  setDOBRange: React.Dispatch<React.SetStateAction<string>>;
  DOBRange: string;
  setCounterFilterValues: React.Dispatch<React.SetStateAction<FilterCounterValuesType>>;
  counterFilterValues: FilterCounterValuesType;
};

const FilterPopover = ({
  isLoading,
  filterValues,
  setFilterValues,
  DOBRange,
  setDOBRange,
  counterFilterValues,
  setCounterFilterValues,
}: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleAnchor = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchor ? setAnchor(null) : setAnchor(e.currentTarget);
  };

  const isHasCount = () => {
    return Object.values(counterFilterValues).filter(value => value === true).length;
  };

  return (
    <>
      <NotificationPopover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => {
          setAnchor(null);
        }}
      >
        <FilterContent
          counterFilterValues={counterFilterValues}
          setCounterFilterValues={setCounterFilterValues}
          DOBRange={DOBRange}
          setDOBRange={setDOBRange}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          onClose={() => setAnchor(null)}
          isLoading={isLoading}
        />
      </NotificationPopover>

      <S.Wrapper $isActive={isHasCount() > 0 ? true : false} onClick={handleAnchor}>
        <FilterSVG color="#0084B1" />
        <S.Text>Filter</S.Text>
        {isHasCount() > 0 && <S.CountCircle>{isHasCount()}</S.CountCircle>}
      </S.Wrapper>
    </>
  );
};

export default FilterPopover;
