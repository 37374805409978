import { AppointmentProcessInterface, Step } from '../../../../types/AppointmentNewTypes';
import { DigitalTemplateType } from '../../../../types/TemplateType';

export const ptInitialFormik = (
  name?: string,
  data?: AppointmentProcessInterface,
  currentStep?: Step,
) => {
  return {
    mdType: DigitalTemplateType.PT_INITIAL,
    patientInfo: {
      diagnostics: currentStep?.digitalForm?.payload?.diagnostics ?? '',
      patientName: currentStep?.digitalForm?.payload?.patientName ?? '',
      date: currentStep?.digitalForm?.payload?.date ?? '',
      birthDate: currentStep?.digitalForm?.payload?.birthDate ?? '',
      cpt: currentStep?.digitalForm?.payload?.cpt ?? '',
    },
    history: {
      dateOfOnset: currentStep?.digitalForm?.payload?.history?.dateOfOnset ?? '',
      hpi: currentStep?.digitalForm?.payload?.history?.hpi ?? '',
      pastMedicalHistory:
        currentStep?.digitalForm?.payload?.history?.pastMedicalHistory ?? '',
    },
    pain: {
      location: {
        lumbar: currentStep?.digitalForm?.payload?.pain?.location?.lumbar ?? '',
      },
      intensity: currentStep?.digitalForm?.payload?.pain?.intensity ?? '',
      priorFrequency: currentStep?.digitalForm?.payload?.pain?.priorFrequency ?? '',
      worseWith: currentStep?.digitalForm?.payload?.pain?.worseWith ?? '',
      betterWith: currentStep?.digitalForm?.payload?.pain?.betterWith ?? '',
      priorHistory: currentStep?.digitalForm?.payload?.pain?.priorHistory ?? '',
      frequency: currentStep?.digitalForm?.payload?.pain?.frequency ?? '',
      exetciseHistory: currentStep?.digitalForm?.payload?.pain?.exetciseHistory ?? '',
      postune: currentStep?.digitalForm?.payload?.pain?.postune ?? '',
      rom: currentStep?.digitalForm?.payload?.pain?.rom ?? '',
      strength: currentStep?.digitalForm?.payload?.pain?.strength ?? '',
    },
    reasonType: {
      reason: {
        physicalTherary:
          currentStep?.digitalForm?.payload?.reasonType?.reason?.physicalTherary ?? '',
      },
      specialTests: currentStep?.digitalForm?.payload?.reasonType?.specialTests ?? '',
      gait: currentStep?.digitalForm?.payload?.reasonType?.gait ?? '',
      palpation: currentStep?.digitalForm?.payload?.reasonType?.palpation ?? '',
      sensation: currentStep?.digitalForm?.payload?.reasonType?.sensation ?? '',
    },
    firstTreatment: {
      manualTx: currentStep?.digitalForm?.payload?.firstTreatment?.manualTx ?? '',
      arom: {
        centralizng:
          currentStep?.digitalForm?.payload?.firstTreatment?.arom?.centralizng ?? '',
      },
      hep: {
        sit: currentStep?.digitalForm?.payload?.firstTreatment?.hep?.sit ?? '',
      },
      strength: currentStep?.digitalForm?.payload?.firstTreatment?.strength ?? '',
    },
    assessment: {
      pain: currentStep?.digitalForm?.payload?.assessment?.pain ?? '',
      treatment: {
        isTolerated:
          currentStep?.digitalForm?.payload?.assessment?.treatment.isTolerated ?? false,
        isDeficits:
          currentStep?.digitalForm?.payload?.assessment?.treatment.isDeficits ?? false,
      },
      pom: currentStep?.digitalForm?.payload?.assessment?.pom ?? '',
      flexibility: currentStep?.digitalForm?.payload?.assessment?.flexibility ?? '',
      strength: currentStep?.digitalForm?.payload?.assessment?.strength ?? '',
      funcDificits: currentStep?.digitalForm?.payload?.assessment?.funcDificits ?? '',
      plan: currentStep?.digitalForm?.payload?.assessment?.plan ?? '',
    },
    shortTermGoals: {
      pain: currentStep?.digitalForm?.payload?.shortTermGoals?.pain ?? '',
      pom: currentStep?.digitalForm?.payload?.shortTermGoals?.pom ?? '',
      flexibility: currentStep?.digitalForm?.payload?.shortTermGoals?.flexibility ?? '',
      strength: currentStep?.digitalForm?.payload?.shortTermGoals?.strength ?? '',
      funcDificits: currentStep?.digitalForm?.payload?.shortTermGoals?.funcDificits ?? '',
    },
    longTermGoals: {
      pain: currentStep?.digitalForm?.payload?.longTermGoals?.pain ?? '',
      pom: currentStep?.digitalForm?.payload?.longTermGoals?.pom ?? '',
      flexibility: currentStep?.digitalForm?.payload?.longTermGoals?.flexibility ?? '',
      strength: currentStep?.digitalForm?.payload?.longTermGoals?.strength ?? '',
      funcDificits: currentStep?.digitalForm?.payload?.longTermGoals?.funcDificits ?? '',
      physician: currentStep?.digitalForm?.payload?.longTermGoals?.physician ?? '',
      licence: currentStep?.digitalForm?.payload?.longTermGoals?.licence ?? '',
    },
  };
};
