import styled from 'styled-components';
import { devices } from '../../../../../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  @media ${devices.xs} {
    min-height: 100vh;
  }
  background: #fff;
  overflow: auto;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  height: 150px;
  width: 150px;
  padding: 20;
  justify-content: center;
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;

  @media ${devices.xs} {
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 7px 24px;
  background-color: #fff;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

export const Footer = styled.div`
  height: 65px;
  border-top: 1px solid var(--line, #d7d7d7);
  background: #f4f4f4;
`;

export const FooterItems = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 15px;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 170px;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
`;
