import * as S from './styles';
import * as AuthS from '../../styles';
import logo from '../../../../assets/images/logo.png';
import { Button, CircularLoader } from '../../../../components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppointmentConfirmMutation } from '../../../../store/api/appointment/appointmentApi';
import dayjs from 'dayjs';
import { convertAMtoPM } from '../../../../helpers/functions/convertAMtoPM';

const ConfirmMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const [appointmentConfirm, statusAppointmentConfirm] = useAppointmentConfirmMutation(
    {},
  );

  useEffect(() => {
    appointmentConfirm({
      confirmToken: searchParams.get('confirmToken'),
    })
      .unwrap()
      .then(res => {
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        navigate('/*');
      });
  }, [location]);

  return (
    <AuthS.AuthWrapper>
      {isLoading || statusAppointmentConfirm.isLoading ? (
        <CircularLoader color="#0084B1" />
      ) : (
        <AuthS.LoginBlock>
          <img className="logo" src={logo} alt="logotype"></img>
          <S.WindowWrapper>
            <S.Article>Congratulations!</S.Article>
            <S.SubArticle>
              You have successfully confirmed your appointments at the Clinic:{' '}
              <S.StyledSpan>
                {statusAppointmentConfirm.data[0]?.appointment?.clinic?.name}
              </S.StyledSpan>{' '}
            </S.SubArticle>
            <S.SubArticle>
              {statusAppointmentConfirm.data?.map((item: any, index: number) => {
                return (
                  <S.Text key={index}>
                    Appointment:{' '}
                    <S.StyledSpan>
                      {convertAMtoPM(item?.startTime)}{' '}
                      {dayjs(item?.date).format('MM/DD/YYYY')}
                    </S.StyledSpan>
                  </S.Text>
                );
              })}
            </S.SubArticle>
            <S.ButtonWrapper>
              <Button text="Back to App" onClick={() => navigate('/*')} />
            </S.ButtonWrapper>
          </S.WindowWrapper>
        </AuthS.LoginBlock>
      )}
    </AuthS.AuthWrapper>
  );
};

export default ConfirmMessage;
