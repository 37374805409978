import { TableCell, TableRow } from '@mui/material';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import CashTableData from './CashTableData';
import { Dialog } from '../../../../../../components';
import { useState } from 'react';
import CashBoxDialog from '../CashBoxDialog';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: Array<Object> | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
  handleShowDialogCashBox: () => void;
  handleCurrentRow: (value: string) => void;
};

const CashTableRow: React.FC<Props> = ({
  headers,
  data,
  clickable,
  handleShowDialogCashBox,
  handleCurrentRow,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {data &&
        data.map((row: any, index) => (
          <TableRow
            key={row.id}
            className={row.appointmentId ? 'notEdit' : 'colored notEdit'}
            onClick={() => {
              clickable && navigate('' + row.userId);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <CashTableData
                  key={header.key}
                  header={header.key}
                  row={row}
                  showCashBox={() => {
                    handleCurrentRow(row);
                    // setCurrentRow(row.id);
                    handleShowDialogCashBox();
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default CashTableRow;
