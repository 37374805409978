import styled from 'styled-components';

export const ChatItemWrapper = styled.div<{ $active: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--line, #d7d7d7);
  background: ${props => (props.$active ? '#E1EBEE' : '#f9f9fc')};
  cursor: pointer;

  &:hover {
    background: #f2f6f7;
  }
`;

export const Content = styled.div`
  margin: 18px 0px 14px 15px;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  background-color: #0084b1;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
`;

export const AvatarDiv = styled.div`
  & img {
    width: 42px;
    height: 42px;
    border-radius: 59px;
    object-fit: cover;
  }

  svg {
    width: 42px;
    height: 42px;
    border-radius: 59px;
  }
`;

export const InformationDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Text = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;

  svg {
    width: 10px;
    height: 10px;
    path {
      stroke: #848a9b;
    }
  }
`;

export const DateDiv = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;
