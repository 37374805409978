import * as S from './styles';
import { useAppSelector } from 'hooks/useAppSelector';
import StatisticHeaderBar from 'pages/Receptionist/components/Dashboard/components/StatisticHeaderBar';
import AveragePatientVisits from 'pages/Receptionist/components/Dashboard/components/AveragePatientVisits';
import TicketsCount from 'pages/Receptionist/components/Dashboard/components/TicketsCount';
import Masseur from 'pages/Receptionist/components/Dashboard/components/Masseur';
import DashVisitReasons from 'pages/Receptionist/components/Dashboard/components/DashVisitReasons';
import { Roles } from 'types/Roles';
import DashCash from 'pages/Receptionist/components/Dashboard/components/DashCash';
import InvoicesCount from 'pages/Receptionist/components/Dashboard/components/InvoicesCount';
import { PatientStatuses } from 'pages/Receptionist/components/Dashboard/components/PatientStatuses';
import { DashMarketing } from 'pages/Receptionist/components/Dashboard/components/DashMarketing';

const Dashboard = () => {
  const userInfo = useAppSelector(state => state.auth);

  return (
    <S.DashboardWrapper>
      <S.SubtitleBar>
        <S.Article>Dashboard</S.Article>
        <S.SubtitleItems />
      </S.SubtitleBar>
      <S.ScrollWrapper>
        <S.Content>
          <StatisticHeaderBar />
          <AveragePatientVisits />

          <S.RowCards>
            <InvoicesCount />
            <TicketsCount />
            <Masseur />
            <PatientStatuses />
          </S.RowCards>

          <DashVisitReasons />

          {userInfo.role !== Roles.SUPER_ADMIN && <DashCash />}

          <DashMarketing />
        </S.Content>
      </S.ScrollWrapper>
    </S.DashboardWrapper>
  );
};

export default Dashboard;
