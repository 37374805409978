import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import CashTableData from "./CashTableData";

import { useState } from 'react';
import TemplateData from './TemplateData';
import { TemplateType } from '../../../../../../types/TemplateType';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: TemplateType[] | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
};

const TemplateRow: React.FC<Props> = ({ headers, data, clickable }) => {
  const navigate = useNavigate();

  return (
    <>
      {data &&
        data.map((row, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && navigate('' + row.id);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <TemplateData header={header.key} row={row} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default TemplateRow;
