import * as S from './styles';
import withDragAndDrop, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Components,
  View,
  Views,
  momentLocalizer,
  Calendar as CalendarBig,
  Event,
} from 'react-big-calendar';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ModeEnum } from 'types/enums/AppointmentEnum';
import { useClinicScheduleCheckWorkingTimeDiapasonQuery } from 'store/api/clinic-schedule/clinicScheduleApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { DateObject } from 'react-multi-date-picker';
import { getDay } from 'helpers/CalendarHelpers/getDay';
import CalendarEvent from 'components/molecules/CalendarEvent';
import { Response } from 'types/Response';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import dayjs from 'dayjs';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
  useUpdateVisitServiceMutation,
} from 'store/api/appointment/appointmentApi';
import { setMessage } from 'store/slices/message';
import getDaysWeekendsForCalendar from './getDaysWeekendsForCalendar';
import { addXmin } from 'helpers/functions/addXMin';
import { getHoursOfWorkDay } from 'helpers/functions/getHoursOfWorkDay';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { Dialog } from 'components/index';

type Props = {
  mode: ModeEnum;
  selectedDate: DateObject[];
  allAppointments?: Response<AppointmentsNew>;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateObject[]>>;
};

const BigCalendar: React.FC<Props> = ({
  mode,
  selectedDate,
  allAppointments,
  setSelectedDate,
}) => {
  const DnDCalendar = withDragAndDrop(CalendarBig);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const localizer = momentLocalizer(moment);
  const [view, setView] = useState(Views.WEEK as View);
  const onView = useCallback((newView: any) => setView(newView), [setView]);
  const [openDialog, setOpenDialog] = useState<Event | null>(null);

  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [deleteVisit] = useDeleteVisitServiceMutation({});

  const handleDelete = () => {
    if (openDialog) {
      openDialog.resource?.selectedEvent?.visits.length > 1
        ? deleteVisit({ id: openDialog.resource?.visit?.id })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Service was successfully deleted from appointment',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(
                setMessage({
                  message: error.data.message,
                  type: 'error',
                }),
              );
            })
        : deleteAppointment({ id: openDialog.resource?.selectedEvent.id })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Appointment was successfully deleted',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(
                setMessage({
                  message: error.data.message,
                  type: 'error',
                }),
              );
            });
    }
  };

  useEffect(() => {
    setView(mode.toLowerCase() as View);
  }, [mode]);

  const checkworkingTimeDiapason = useClinicScheduleCheckWorkingTimeDiapasonQuery({
    startDate: getDay(selectedDate[0])[0].format('YYYY-MM-DD'),
    endDate: getDay(selectedDate[1])[1].format('YYYY-MM-DD'),
    clinicId: selectedClinic.id,
  });

  const onNavigate = useCallback(
    (newDate: any) => setSelectedDate(getDay(newDate)),
    [setSelectedDate],
  );

  const [myEvents, setMyEvents] = useState<Array<Event>>([]);

  useEffect(() => {
    setMyEvents([]);
    allAppointments?.rows?.forEach(appointment => {
      appointment.visits.forEach(visit => {
        const data: Event = {
          title: visit.reason,
          start: new Date((appointment.date + ' ' + visit.startTime).toString()),

          end: new Date(
            (
              appointment.date +
              ' ' +
              addXmin(visit.startTime, visit.visitLengthMinutes)
            ).toString(),
          ),
          resource: {
            id: visit.id,
            type: visit.reason,
            status: appointment.status,
            isFirstTimeVisit: appointment.isFirstTimeVisit,
            selectedEvent: appointment,
            visit: visit,
          },
        };
        setMyEvents(oldEvent => [...oldEvent, data]);
      });
    });
  }, [allAppointments]);

  const components: Components<Event> = {
    event: props => {
      const eventType = props?.event?.resource?.type;
      const startTime = props?.event?.start?.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const status = props?.event?.resource?.status;
      const endTime = props?.event?.end?.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const fullTime = `${startTime}-${endTime}`;
      const isFirstTimeVisit = props?.event?.resource?.isFirstTimeVisit;
      return (
        <CalendarEvent
          type={eventType}
          time={fullTime}
          status={status}
          fullEvent={props.event}
          isFirstTimeVisit={isFirstTimeVisit}
          setOpenDialog={setOpenDialog}
        />
      );
    },
  };

  const customSlotPropGetter = useCallback(
    (date: Date) => {
      if (!checkworkingTimeDiapason.isLoading && !checkworkingTimeDiapason.isFetching) {
        return {
          className: 'slotDefault',
          ...(getHoursOfWorkDay(checkworkingTimeDiapason.currentData, date) && {
            style: {
              backgroundColor: '#fff',
            },
          }),
        };
      } else return { className: 'slotDefault' };
    },
    [checkworkingTimeDiapason],
  );

  const calendarStyle = useCallback(
    (date: Date) => {
      if (!checkworkingTimeDiapason.isLoading && !checkworkingTimeDiapason.isFetching) {
        return {
          className: 'slotDefault',
          ...(getDaysWeekendsForCalendar(checkworkingTimeDiapason.currentData, date) && {
            style: {
              backgroundColor: '#fff',
            },
          }),
        };
      } else return { className: 'slotDefault' };
    },
    [checkworkingTimeDiapason],
  );

  const [onSelectUpd, setOnSelectUpd] = useState<Event | null>(null);

  const [updateTime, statusLoading] = useUpdateVisitServiceMutation({});

  const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
    const { event, start, end } = data;
    const dayJSNewDate = dayjs(start);
    const dayJSOldDate = dayjs(event.start);

    dayJSNewDate.isSame(dayJSOldDate, 'day')
      ? setMyEvents(currentEvents => {
          const existing =
            currentEvents.find(ev => ev.resource.id === event.resource.id) ?? {};
          const filtered = currentEvents.filter(
            ev => ev.resource.id !== event.resource.id,
          );
          setOnSelectUpd({
            ...existing,
            start: start as Date,
            end: end as Date,
          });
          return existing
            ? [...filtered, { ...existing, start: start as Date, end: end as Date }]
            : [...filtered];
        })
      : dispatch(
          setMessage({
            message: 'You can`t move service to another day',
            type: 'error',
          }),
        );
  };

  useEffect(() => {
    if (onSelectUpd) {
      updateTime({
        body: [
          {
            id: onSelectUpd?.resource.id,
            startTime: dayjs(onSelectUpd?.start).format('HH:mm'),
            visitLengthMinutes: onSelectUpd.resource.visit.visitLengthMinutes,
            // endTime: dayjs(onSelectUpd?.end).format("HH:mm"),
            // date: dayjs(onSelectUpd?.start).format("YYYY-MM-DD"),
          },
        ],
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    }
  }, [onSelectUpd]);

  const dispatch = useAppDispatch();

  return (
    <S.Wrapper>
      <S.Information>
        <S.Container>
          <S.TimelineWrapper>
            <DnDCalendar
              components={components}
              toolbar={false}
              slotPropGetter={customSlotPropGetter}
              tooltipAccessor={null}
              dayPropGetter={calendarStyle}
              popup={false}
              showAllEvents={true}
              min={moment('7:00am', 'h:mma').toDate()}
              max={moment('10:00pm', 'h:mma').toDate()}
              events={myEvents}
              localizer={localizer}
              resizable={false}
              step={15}
              timeslots={1}
              dayLayoutAlgorithm="overlap"
              scrollToTime={new Date()}
              // scrollToTime={moment()
              //   .set({
              //     h: moment().subtract(2, "hours").hours(),
              //     m: moment().minutes(),
              //   })
              //   .toDate()}
              onEventDrop={onEventDrop}
              date={selectedDate[0].format('MM/DD/YYYY')}
              view={view}
              onNavigate={onNavigate}
              onView={onView}
              onSelectEvent={(event: Event) => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.INFO_APPOINTMENT,
                    props: event?.resource?.selectedEvent,
                  }),
                );
              }}
            />
          </S.TimelineWrapper>
        </S.Container>
      </S.Information>

      <Dialog
        open={!!openDialog}
        onClose={() => {
          dispatch(
            showDrawer({
              show: false,
              mode: DrawerMode.DEFAULT,
              props: null,
            }),
          );
          setOpenDialog(null);
        }}
      >
        <ConfirmDeleteDialog
          onClose={() => setOpenDialog(null)}
          fullTitle={
            openDialog?.resource?.selectedEvent?.visits.length > 1
              ? `Are you sure want to delete current service from appointment #${openDialog?.resource?.selectedEvent?.id.slice(
                  0,
                  8,
                )}?`
              : 'Are you sure you want to delete the entire appointment?'
          }
          helperText={
            openDialog?.resource?.selectedEvent?.visits.length > 1
              ? 'You will delete only one current service from appointment'
              : 'You will delete the entire appointment and lose progress'
          }
          onSuccess={handleDelete}
        />
      </Dialog>
    </S.Wrapper>
  );
};

export default BigCalendar;
