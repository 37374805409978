import styled from 'styled-components';
import { devices } from 'constants/device';

export const Wrapper = styled.div`
  padding: 14px 23px 57px;
  background: #fff;
  margin: 9px 13px;
  border-radius: 10px;
  height: calc(100vh - 250px);
`;

export const Content = styled.div`
  max-height: calc(100vh - 310px);
  overflow: auto;
`;

export const GroupNumberCard = styled.div``;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-left: 0;
    margin-right: 0;
    height: auto;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ArticleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.normal {
    font-weight: 400;
  }

  &.bold {
    flex: 1;
    font-weight: 700;
  }

  &.fs13 {
    font-size: 13px;
    font-weight: 400;
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px;
  height: 60px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  &.biggap {
    gap: 40px;
  }

  &.withoutLabel {
    margin-top: 18px;
  }

  &.mt13 {
    margin: 13px 0 6px;
  }

  &.mt {
    margin: 20px 20px 6px 0;
  }

  &.m13 {
    margin: 13px 20px 8px;
  }

  &.m7 {
    margin: 7px 20px;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.end {
    justify-content: flex-end;
  }

  &.padding {
    justify-content: space-between;
    margin: 19px 10px 11px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;

  &.px480 {
    width: 480px;
  }

  &.px240 {
    width: 240px;
  }
  &.px180 {
    width: 180px;
  }
  &.px90 {
    width: 90px;
  }
  &.all {
    width: 100%;
  }

  &.percent33 {
    width: calc(33% - 12px);
  }
  &.percent50 {
    width: calc(50% - 10px);
  }
  &.flex {
    min-width: 150px;
    flex: 1;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SearchBtn = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
  line-height: normal;
  justify-content: center;
  margin-top: 15px;

  svg {
    path {
      stroke: #0084b1;
    }
    ellipse {
      stroke: #0084b1;
    }
  }

  &.withoutMargin {
    margin-top: 0;
  }
`;

export const EmptyDiv = styled.div<{ width?: string }>`
  width: ${props => props.width};
`;

export const RadioItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 5px;

  &.error {
    border: 1px solid red;
    border-radius: 5px;
  }
`;

export const DeleteBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
`;

export const CoveredCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 23px 30px;
  background: #e7e8ec;
  margin: 10px 0;
  border-radius: 8px;
  min-height: 100px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  width: calc(100% - 30px);
  gap: 12px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const Button = styled.div`
  width: 200px;
`;

export const Loader = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
`;
