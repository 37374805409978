import * as S from './styles';
import * as AuthS from '../../styles';
import { Button, Input } from '../../../../components';
import { ChangeEvent, useEffect, useState } from 'react';
import logo from '../../../../assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useResetPasswordMutation } from '../../../../store/api/auth/authApi';
import { setMessage } from '../../../../store/slices/message';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [resetPassword] = useResetPasswordMutation();

  const ChangePasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .required('Is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
  });

  const { values, handleChange, handleSubmit, errors, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      validateOnChange: false,
      validationSchema: ChangePasswordSchema,
      onSubmit: async ({ password, confirmPassword }) => {
        await resetPassword({ password, confirmPassword })
          .unwrap()
          .then(res => {
            navigate('/auth');
            dispatch(setMessage({ message: res.message, type: 'success' }));
          })
          .catch(error => {
            dispatch(setMessage({ message: error.data.message, type: 'error' }));
          });
      },
    });

  useEffect(() => {
    if (!state) {
      navigate('/auth');
    }
  }, []);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <AuthS.AuthWrapper>
        <AuthS.LoginBlock>
          <img className="logo" src={logo} alt="logotype"></img>
          <S.ChangePasswordWrapper>
            <S.Article>Change Password</S.Article>
            <S.SubArticle>
              To change the current password, enter the new password in both fields
            </S.SubArticle>
            <S.Items>
              <Input
                isPassword
                id="password"
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChangeInput}
                error={!!errors.password}
                helperText={errors.password}
              />
              <Input
                isPassword
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChangeInput}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
              <S.ButtonWrapper>
                <Button text="Submit" type="submit" />
              </S.ButtonWrapper>
            </S.Items>
          </S.ChangePasswordWrapper>
        </AuthS.LoginBlock>
      </AuthS.AuthWrapper>
    </form>
  );
};

export default ChangePassword;
