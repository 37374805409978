import { FormikProps, useFormik } from 'formik';
import * as S from './styles';
import * as yup from 'yup';
import { ForwardedRef, forwardRef } from 'react';
import History from './components/History';
import Pain from './components/Pain';
import ReasonType from './components/ReasonType';
import Assesment from './components/Assesment';
import PTUserBlock from './components/PTUserBlock';
import { PagePTInitialType } from '../pages/PagePTInitialConsultation/PagePTInitialType';
import { AppointmentsNew, Step } from '../../../../../../types/AppointmentNewTypes';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  isFromStepper?: boolean;
  stepperValues?: Step;
  digitalFormId?: number;
  appointmentInformation?: AppointmentsNew;
};
const PTInitialEvaluation = forwardRef<Record<string, unknown>, Props>(
  (
    { formik, isFromStepper, stepperValues, appointmentInformation, digitalFormId },
    ref,
  ) => (
    <S.Wrapper>
      <S.Inputs>
        <PTUserBlock
          formik={formik}
          ref={ref}
          appointmentInformation={appointmentInformation}
        />
        <History ref={ref} formik={formik} />
        <Pain ref={ref} formik={formik} />
        <ReasonType ref={ref} formik={formik} />
        <Assesment ref={ref} formik={formik} />
      </S.Inputs>
    </S.Wrapper>
  ),
);

PTInitialEvaluation.displayName = 'PTInitialEvaluation';

export default PTInitialEvaluation;
