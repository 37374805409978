import { TextField, TextFieldProps } from '@mui/material';
import * as S from './styles';

type Props = { label?: string } & TextFieldProps;

const InputCell: React.FC<Props> = ({ ...props }) => {
  return (
    <S.Wrapper>
      <TextField variant="standard" {...props} sx={{ width: '100%' }} />
    </S.Wrapper>
  );
};

export default InputCell;
