import styled, { css } from 'styled-components';
import { devices } from '../../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 45px);

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;

    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  overflow: auto;
`;

export const SelectInputWrapper = styled.div`
  width: 150px;
  min-width: 150px;
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitleBar = styled.div`
  background-color: #f9f9fc;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1000;
  height: 82px;
  display: flex;
  border-left: 1px solid #ddd;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const NoPatients = styled.div``;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 20px;

  @media ${devices.xs} {
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 100%;
  margin: 7px 10px;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;
export const TableWrapper = styled.div`
  display: flex;

  & .MuiTable-root {
    height: 100%;
    width: auto;
    border-collapse: separate;
  }
`;

export const SpanSince = styled.span`
  color: rgba(32, 46, 95, 0.5);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
`;

export const ColumnWrapper = styled.div<{ $backgrColor?: string }>`
  & .MuiTableHead-root {
    ${props =>
      props.$backgrColor &&
      css`
        background: ${props.$backgrColor};
      `}
    height: 100%;
    position: sticky;
    top: 77px;
  }
  & .MuiTableCell-root {
    ${props =>
      props.$backgrColor &&
      css`
        background: ${props.$backgrColor};
      `}
    white-space: nowrap;
    max-width: 100px;
    width: 100px;
    min-width: 50px;
    max-height: 20px;
    height: 20px;
    color: #202e5f;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    border: 1px solid #d7d7d7;
    text-align: center;
    /* 
    &:hover {
      cursor: pointer;
      border: 1px solid #0084b1;
      background: var(--hover-1, rgba(0, 132, 177, 0.08));
    } */

    &.clinics {
      font-size: 12px;

      &.since {
        max-height: 30px;
        height: 30px;
        white-space: normal;
        color: var(--text, #202e5f);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    &.dataCell {
      border: 0px solid;
      padding: 0px;
      overflow: auto;

      ${props =>
        props.$backgrColor &&
        css`
          background: ${props.$backgrColor};
        `}
      &.whiteColor {
        & :hover {
          cursor: auto;
          border: 1px solid #d7d7d7;
          background: #fff;
        }

        color: var(--icon, #848a9b);
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        background: #fff;
      }
    }

    &.white-header-top {
      border-top: 0px solid;
    }

    &.white-header {
      border-bottom: 0px solid;
      border-right: 0px solid;
    }

    &.white {
      //z-index: 10;
      width: 159px;
      min-width: 159px;
      background: #fff;
      color: var(--icon, #848a9b);
      font-family: Inter;
      font-size: 9.15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.insurance {
      height: 30px;
      max-height: 30px;
      text-overflow: ellipsis;
      /* writing-mode: vertical-rl; */
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0em;
      border-bottom: 2px solid #b0aeae;
      /* transform: rotate(-180deg);
      transform-origin: center; */
    }
  }
`;

export const WhiteHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 159px;
  min-width: 159px;
  height: calc(100%);
  border-right: 1px solid var(--line, #d7d7d7);
  background: #fff;
`;

export const ClaimCount = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #d7d7d7;
  &:hover {
    cursor: pointer;
    border: 1px solid #0084b1;
    background: var(--hover-1, rgba(0, 132, 177, 0.08));
  }

  &.lastday {
    border-bottom: 2px solid #b0aeae;

    &:hover {
      border-bottom: 2px solid #b0aeae !important;
    }
  }
`;

export const WrapperClaimCount = styled.div``;

export const White = styled.div`
  height: 295px;
`;
export const Dates = styled.div`
  border-top: 1px solid red;
`;
