import { useState } from 'react';
import { Dialog } from 'components';
import * as S from '../../styles';
import DialogModifiedInformation from '../DialogModifiedInformation';
import { ClaimErrorHistoryType, ClaimEventHistoryType } from 'types/ClaimTypes';
import dayjs from 'dayjs';
import { DialogErrorInformation } from '../DialogErrorInformation';

type Props = {
  color: string;
  title: string;
  description?: string;
  clickable?: boolean;
  claimEvent?: ClaimEventHistoryType;
  claimError?: ClaimErrorHistoryType;
};

const ClaimMessageCard = ({
  color,
  title,
  description,
  clickable,
  claimEvent,
  claimError,
}: Props) => {
  const [isOpenModifiedDialog, setIsOpenModifiedDialog] = useState(false);
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);

  return (
    <>
      <Dialog open={isOpenModifiedDialog} onClose={() => setIsOpenModifiedDialog(false)}>
        <DialogModifiedInformation claimEvent={claimEvent} />
      </Dialog>
      <Dialog open={isOpenErrorDialog} onClose={() => setIsOpenErrorDialog(false)}>
        <DialogErrorInformation claimError={claimError} />
      </Dialog>
      <S.CardWrapper
        $color={color}
        $clickable={clickable}
        onClick={() => {
          clickable &&
            (claimEvent ? setIsOpenModifiedDialog(true) : setIsOpenErrorDialog(true));
        }}
      >
        <S.Title $clickable={clickable}>{title}</S.Title>
        {description && <S.CardText>{description}</S.CardText>}
        {claimEvent && (
          <S.CardText>
            {claimEvent.modifier
              ? claimEvent.modifier.profile.firstName +
                ' ' +
                claimEvent.modifier.profile.lastName
              : 'System'}
            , {dayjs(claimEvent.createdAt).format('MM/DD/YYYY h:mm A')}
          </S.CardText>
        )}
      </S.CardWrapper>
    </>
  );
};

export default ClaimMessageCard;
