import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ChatResponse,
  MessageResponse,
  NewMessagesResponse,
  NotificationCount,
  PendingAppointmentsCount,
} from 'types/ChatTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';

export type StartAppointment = {
  startedAppointments: AppointmentsNew[];
};

type Initial = {
  messageResponse: MessageResponse | null;
  chats: ChatResponse | null;
  connected: boolean;
  currentChat: string | null;
  newMessage: NewMessagesResponse | null;
  newNotifications: any;
  notificationsCount: NotificationCount | null;
  pendingAppointmentCount: PendingAppointmentsCount | null;
  startedAppointmentCount: StartAppointment | null;
};

const initialState: Initial = {
  messageResponse: null,
  chats: null,
  connected: false,
  currentChat: null,
  newMessage: null,
  newNotifications: null,
  notificationsCount: null,
  pendingAppointmentCount: null,
  startedAppointmentCount: null,
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessageResponse | null>) => {
      state.messageResponse = action.payload;
    },
    setChats: (state, action: PayloadAction<ChatResponse>) => {
      state.chats = action.payload;
    },
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    setCurrentChat: (state, action: PayloadAction<string | null>) => {
      state.currentChat = action.payload;
    },
    setNewNotifications: (state, action: PayloadAction<any>) => {
      state.newNotifications = action.payload;
    },
    setNotificationCount: (state, action: PayloadAction<NotificationCount | null>) => {
      state.notificationsCount = action.payload;
    },
    setNewMessage: (state, action: PayloadAction<NewMessagesResponse | null>) => {
      state.newMessage = action.payload;
    },
    setPendingAppointmentsCount: (
      state,
      action: PayloadAction<PendingAppointmentsCount | null>,
    ) => {
      state.pendingAppointmentCount = action.payload;
    },
    setStartedAppointmentCount: (
      state,
      action: PayloadAction<StartAppointment | null>,
    ) => {
      state.startedAppointmentCount = action.payload;
    },
  },
});

const { reducer, actions } = chatsSlice;

export const {
  setMessages,
  setChats,
  setConnected,
  setCurrentChat,
  setNewNotifications,
  setNotificationCount,
  setPendingAppointmentsCount,
  setStartedAppointmentCount,
  setNewMessage,
} = actions;
export default reducer;
