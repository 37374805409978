import { FormikProps } from 'formik';
import { Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  setIsDirty?: () => void;
};
const CRRelative = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Article>
        Name and address of closest relative (other than spouse) in case of emergency:
      </S.Article>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Emergency Contact'}
            defaultValue={formik.values.relativeContacts.emergencyContact}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(
                e,
                'relativeContacts.emergencyContact',
                ref,
                setIsDirty,
              )
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Relationship'}
            defaultValue={formik.values.relativeContacts.relationship}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.relationship', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Phone'}
            defaultValue={formik.values.relativeContacts.phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.phone', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Address'}
            defaultValue={formik.values.relativeContacts.address}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.address', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'City'}
            defaultValue={formik.values.relativeContacts.city}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.city', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'State'}
            defaultValue={formik.values.relativeContacts.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.state', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Zip Code'}
            defaultValue={formik.values.relativeContacts.zipCode}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'relativeContacts.zipCode', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
    </>
  ),
);

export default CRRelative;
