import styled from 'styled-components';
import { devices } from '../../../../../../../../constants/device';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

export const HelperText = styled.div`
  color: var(--text, #202e5f);
  margin-top: 10px;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 355px;
  opacity: 0.7;
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
