import { isEqual } from 'lodash';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { TicketType } from '../../../types/TicketType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const clinicApi = api.injectEndpoints({
  endpoints: builder => ({
    ticketsIncomingGet: builder.query<Response<TicketType>, any>({
      query: ({ ...params }) => ({
        url: '/task/incoming',
        params: { ...params },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },

      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['Tickets'],
    }),
    ticketsOutgoingGet: builder.query<Response<TicketType>, any>({
      query: ({ ...params }) => ({
        url: '/task/outgoing',
        params: { ...params },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Tickets'],
    }),
    ticketsMarkDone: builder.mutation({
      query: ({ id }) => ({
        url: `/task/${id}/mark-done`,
        method: 'PATCH',
      }),
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    ticketsDelete: builder.mutation({
      query: ({ id }) => ({
        url: `/task/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tickets'],
    }),
    ticketsCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/task`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Tickets'],
    }),
  }),
});

export const {
  useTicketsIncomingGetQuery,
  useTicketsOutgoingGetQuery,
  useTicketsMarkDoneMutation,
  useTicketsCreateMutation,
  useTicketsDeleteMutation,
  useLazyTicketsIncomingGetQuery,
  useLazyTicketsOutgoingGetQuery,
} = clinicApi;
