import {
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import * as S from './styles';
import { Link, useNavigate } from 'react-router-dom';
import PatientTableData from './PatientTableData/PatientTableData';
import PatientTableRow from './PatientTableData/PatientTableRow';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  headers: Array<HeaderValue>;
  children?: React.ReactNode;
  isFetching?: boolean;
};

const CustomTable: React.FC<Props> = ({ headers, isFetching, children }) => {
  // const headers = Object.keys(data[0]);

  const navigate = useNavigate();
  return (
    <S.CustomTableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header.key}>{header.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isFetching ? (
          <TableBody>
            <tr>
              <td>
                <S.Loader>
                  <LinearProgress />
                </S.Loader>
              </td>
            </tr>
          </TableBody>
        ) : (
          <TableBody>{children}</TableBody>
        )}
      </Table>
    </S.CustomTableWrapper>
  );
};

export default CustomTable;
