import { TimePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const TimePickerWrapper = styled.div``;

export const TimePickerItem = styled(TimePicker)`
  border-radius: 5px;

  &.timePicker {
    width: 240px;
  }

  & .Mui-focused {
    border: 0.5px solid var(--line, #0084b1) !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    height: 38px;
    border-radius: 5px;
    padding: 11px 14px;
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  &.MuiOutlinedInput {
  }
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
