import { useMemo, useState } from 'react';
import { Button } from '../../../../../../components';
import * as S from './styles';
import { ClaimTypeEnum } from '../../../../../../types/InvoiceType';

type Props = {
  onClose: () => void;
  selectedType: ClaimTypeEnum | null;
  setSelectedType: React.Dispatch<React.SetStateAction<ClaimTypeEnum | null>>;
};

const CreateClaimDialog = ({ onClose, selectedType, setSelectedType }: Props) => {
  const [selectedClaimType, setSelectedClaimType] = useState<ClaimTypeEnum | null>(null);

  const isButtonDisabled = useMemo(() => !selectedClaimType, [selectedClaimType]);

  const handleCreateClick = () => {
    setSelectedType(selectedClaimType);
    onClose();
  };

  const claimTypes = [
    {
      type: ClaimTypeEnum.ON_THE_PREVIOUS_CLAIM,
      label: 'Create Form Copy of Previous Claim',
    },
    {
      type: ClaimTypeEnum.ON_THE_PATIENT_RECORDS,
      label: 'Create Form Patient Record',
    },
    {
      type: ClaimTypeEnum.BLANK,
      label: 'Create Blank Claim',
    },
  ];

  return (
    <S.Content>
      <S.Article>Create New Claim</S.Article>
      <S.MainContent>
        {claimTypes.map(claimType => (
          <S.ButtonCard
            key={claimType.type}
            $isSelected={selectedClaimType === claimType.type}
            onClick={() => setSelectedClaimType(claimType.type)}
          >
            {claimType.label}
          </S.ButtonCard>
        ))}
        <S.ButtonWrapper>
          <Button text="Create" disabled={isButtonDisabled} onClick={handleCreateClick} />
        </S.ButtonWrapper>
      </S.MainContent>
    </S.Content>
  );
};

export default CreateClaimDialog;
