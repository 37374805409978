export enum PaymentStatus {
  UNPAID = 'unpaid',
  PAID = 'paid',
}

export enum PaymentMethods {
  CHECK = 'check',
  CLIENT_BY_CARD = 'client_by_card',
  CO_PAYMENT = 'co_payment',
  INSURANCE_COMPANY = 'insurance_company',
  CLIENT_BY_CASH = 'client_by_cash',
}
