import { CheckBox } from '../../../../../../../../components';
import * as S from '../../styles';
type Props = {
  article: string;
  value?: string | number | boolean | null;
  withCheckbox?: boolean;
  checked?: boolean;
};

const ItemValue = ({ article, value, withCheckbox, checked }: Props) => {
  return (
    <S.Item>
      <S.ItemArticle>{article}</S.ItemArticle>

      <S.ItemValue>
        {withCheckbox ? (
          <CheckBox style={{ pointerEvents: 'none' }} checked={checked ?? false} />
        ) : (
          (value ?? '-')
        )}
      </S.ItemValue>
    </S.Item>
  );
};

export default ItemValue;
