import { AppointmentsNew } from '../../../types/AppointmentNewTypes';
import { Appointments } from '../../../types/AppointmentsTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const appointmentApi = api.injectEndpoints({
  endpoints: builder => ({
    appointmentCreate: builder.mutation({
      query: data => ({
        url: '/appointment',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Appointments', 'Me'],
    }),
    appointmentUpdateTime: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/appointment/${id}/date-time`,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: ['Appointments'],
    }),
    appointmentGetForTicket: builder.query<Response<AppointmentsNew>, any>({
      query: ({ page, take, ...arg }) => ({
        url: `/appointment/search-by-id?take=${take || 15}&page=${page}`,
        params: { ...arg },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          return;
        }
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['User'],
    }),
    appointmentGet: builder.query<Response<AppointmentsNew>, any>({
      query: ({ page, cache, take, ...arg }) => {
        return {
          url: `/appointment?take=${take || 15}&page=${page}`,
          params: { ...arg },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },

      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }

        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },

      providesTags: ['Appointments'],
    }),

    appointmentUpdateStatus: builder.mutation({
      query: body => {
        return {
          url: `/appointment/status/${body.id}`,
          method: 'PATCH',
          body: { status: body.status },
        };
      },
      invalidatesTags: ['Appointments', 'CashBox'],
    }),
    appointmentGetTicket: builder.mutation({
      query: ({ ...arg }) => {
        return {
          url: `/appointment/search-by-id`,
          method: 'GET',
          params: { ...arg },
        };
      },
    }),
    appointmentConfirm: builder.mutation({
      query: ({ ...arg }) => {
        return {
          url: `/event/confirm`,
          method: 'GET',
          params: { ...arg },
        };
      },
    }),
    appointmentGetOne: builder.mutation({
      query: ({ id, ...arg }) => {
        return {
          url: `/appointment/${id}`,
          method: 'GET',
          params: { ...arg },
        };
      },
      invalidatesTags: ['Appointments'],
    }),
    appointmentDelete: builder.mutation({
      query: ({ id }) => ({
        url: `/appointment/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments'],
    }),
    appointmentUpdate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/appointment/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Appointments', 'CashBox', 'User', 'Dashboard', 'Coupons'],
    }),
    unLockPendingAppointment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/appointment/clear-data/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Appointments', 'User', 'Coupons'],
    }),
    updateVisitService: builder.mutation({
      query: ({ id, body }) => ({
        url: `/appointment-visit`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Appointments', 'User', 'Coupons'],
    }),
    deleteVisitService: builder.mutation({
      query: ({ id, body }) => ({
        url: `/appointment-visit/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments', 'User', 'Coupons'],
    }),
    appointmentCheckBilling: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/appointment/checked-billing/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Appointments', 'User', 'Coupons'],
    }),
  }),
});

export const {
  useAppointmentCreateMutation,
  useAppointmentGetQuery,
  useLazyAppointmentGetQuery,
  useAppointmentUpdateStatusMutation,
  useAppointmentGetTicketMutation,
  useAppointmentConfirmMutation,
  useAppointmentUpdateTimeMutation,
  useAppointmentGetOneMutation,
  useAppointmentDeleteMutation,
  useAppointmentUpdateMutation,
  useUnLockPendingAppointmentMutation,
  useUpdateVisitServiceMutation,
  useDeleteVisitServiceMutation,
  useAppointmentCheckBillingMutation,
  useAppointmentGetForTicketQuery,
} = appointmentApi;
