import styled from 'styled-components';

export const Wrapper = styled.div<{ $isChecked: boolean; $isSend: boolean }>`
  display: flex;
  border-radius: 11.082px;
  height: 78px;
  width: 24%;
  min-width: 250px;
  background: ${props => (props.$isChecked ? '#E5F0F6' : '#E9E9EC')};
  border: 1px solid ${props => (props.$isChecked ? '#0084B1' : '#E5F0F6')};
  opacity: ${props => (props.$isSend ? '0.5' : '1')};
  pointer-events: ${props => (props.$isSend ? 'none' : 'all')};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const RightBlock = styled.div`
  display: flex;
`;

export const UsernameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Avatar = styled.div`
  svg {
    width: 22px;
    height: 22px;
  }

  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const Username = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 200px;
`;

export const SentThrough = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-left: 3px;
`;
