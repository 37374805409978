import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div`
  padding: 11px 30px 11px 13px;
  width: 100%;
  cursor: pointer;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: #e5f0f6;
  }
`;
