import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 293px;
  max-width: 350px;
  width: auto;
  height: auto;
`;

export const FirstLineWrapper = styled.div`
  padding: 15px;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const FirstTimeVisit = styled.div`
  color: #d17e00;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  gap: 4px;
`;

export const FTVText = styled.div`
  color: #d17e00;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TimeDiv = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SecondLine = styled.div`
  border-top: 0.5px solid var(--line, #d7d7d7);
  border-bottom: 0.5px solid var(--line, #d7d7d7);
  background: var(--hover, #f5f5f5);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const UserName = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
`;

export const Phone = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Note = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 350px;
`;
