import { CloseBtnSVG, EyeVisibleSVG } from '../../../assets/icons';
import { useReadNotificationByIdMutation } from '../../../store/api/notifications/notificationsApi';
import { NotificationItemType } from '../../../types/NotificationsTypes';
import * as S from './styles';

type Props = {
  notification: NotificationItemType;
};

const CloseButtonNotify: React.FC<Props> = ({ notification }) => {
  const [readById] = useReadNotificationByIdMutation();

  return (
    <S.Wrapper>
      <S.MarkRead
        onClick={() => {
          readById({ id: notification.id });
        }}
      >
        <EyeVisibleSVG />
        Mark as read
      </S.MarkRead>
      <S.CloseBtn>
        <CloseBtnSVG />
      </S.CloseBtn>
    </S.Wrapper>
  );
};

export default CloseButtonNotify;
