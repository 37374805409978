import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-root {
    padding: 0;

    & .Mui-focused {
      border: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 5px;
    }
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: 38px;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }
`;

export const LiWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Avatar = styled.div`
  img {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    object-fit: cover;
  }
`;

export const DateOfBirth = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const OptionWrap = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
