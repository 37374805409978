import styled from 'styled-components';

export const MessageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & ::-webkit-scrollbar {
    display: none;
  }

  & .MuiInputAdornment-root {
    display: flex;
    gap: 5px;

    & .disabled {
      opacity: 0.5;
    }

    & .attachFile {
      svg {
        color: #0084b1;
        width: 24px;
        height: 24px;
      }
    }
    /* & .MuiButtonBase-root {
      &.attachFile {
        height: 100px;
        width: 100px;
      }
    }
    &.attachFile {
      height: 100px;
      width: 100px;
    } */
  }

  /* & .Mui-focused {
    border-top: 0.5px solid var(--line, #0084b1) !important;

    &.MuiFormHelperText-root {
      border: none !important;
    }
  } */

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    border-top: 0.5px solid var(--line, #ffffff);
    background: #ffffff;
    padding: 2px 90px 2px 12px;
    line-height: normal;
    height: 70px;
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
    overflow-y: scroll;
  }
`;
