import * as yup from 'yup';
import {
  commonMoneyValidator,
  MORE_ZERO,
  REQUIRED_FIELD,
  TOO_LONG,
} from 'constants/constants';
import { ClinicInsuranceType } from 'types/enums/AppointmentEnum';

export const emptyCoveredService: SmallCoveredServiceType = {
  typeServiceId: '',
  type: '',
  visitAuthorizet: 0,
  visitUsed: 0,
  isUnlimited: false,
};

export const emptyGroupNumber = (parentInsuranceName: string) => {
  return {
    groupNumber: '',
    parentInsuranceName,
    inNetMet: 0,
    inNetTotal: 0,
    inOutMet: 0,
    inOutTotal: 0,
    pocketLimitInFrom: 0,
    pocketLimitInTo: 0,
    pocketLimitOutFrom: 0,
    pocketLimitOutTo: 0,
    insuranceOnPolicyIn: '',
    insuranceOnPolicyOut: '',
    copayIn: 0,
    copayOut: 0,
    isReferallRequired: false,
    isAuthorizationRequired: false,
    note: '',
    //patientPhoneNumber: '',
    coveredServices: [],
  };
};

export type NewGroupType = Partial<{
  oldGroupNumber: string;
  id: string;
  groupNumber: string; // required
  parentInsuranceName: string; //required
  inNetMet: number;
  inNetTotal: number;
  inOutMet: number;
  inOutTotal: number;
  pocketLimitInFrom: number | null;
  pocketLimitInTo: number | null;
  pocketLimitOutFrom: number | null;
  pocketLimitOutTo: number | null;
  insuranceOnPolicyIn: string; //'40/60'
  insuranceOnPolicyOut: string; //'40/60'
  copayIn: number;
  copayOut: number;
  isReferallRequired: boolean;
  isAuthorizationRequired: boolean;
  note: string;
  patientPhoneNumber: string;
  coveredServices: SmallCoveredServiceType[];
}>;

type SmallCoveredServiceType = {
  id?: string;
  type: ClinicInsuranceType | string;
  typeServiceId: string | null;
  visitAuthorizet: number;
  visitUsed?: number;
  isUnlimited: boolean;
};

export type NewInsuranceGroupsType = {
  insuranceId: string;
  insuranceName: string;
  payerId: string;
  groups: NewGroupType[];
};

export const yupServicesSchema = yup.object().shape({
  type: yup.string().required(REQUIRED_FIELD),
  typeServiceId: yup.string().required(REQUIRED_FIELD),
  isUnlimited: yup.boolean(),
  visitAuthorizet: yup
    .number()
    .when(['isUnlimited', 'visitUsed'], ([isUnlimited, visitUsed]) => {
      return isUnlimited
        ? yup.string().optional()
        : yup
            .number()
            .min(visitUsed ?? 0, `Field must be greater than or equal to ${visitUsed}`)
            .required(REQUIRED_FIELD);
    }),
  visitUsed: yup.number().when('isUnlimited', ([isUnlimited]) => {
    return isUnlimited
      ? yup.string().optional()
      : yup.number().min(0, MORE_ZERO).optional();
  }),
});

export const yupGroupSchema = yup.object().shape({
  groupNumber: yup.string().trim().max(6, TOO_LONG).required(REQUIRED_FIELD),
  parentInsuranceName: yup.string().trim().required(REQUIRED_FIELD),

  inNetMet: commonMoneyValidator,
  inNetTotal: commonMoneyValidator,
  inOutMet: commonMoneyValidator,
  inOutTotal: commonMoneyValidator,
  pocketLimitInFrom: commonMoneyValidator,
  pocketLimitInTo: commonMoneyValidator,
  pocketLimitOutFrom: commonMoneyValidator,
  pocketLimitOutTo: commonMoneyValidator,
  insuranceOnPolicyIn: yup.string().trim().max(5, TOO_LONG).nullable(),
  insuranceOnPolicyOut: yup.string().trim().max(5, TOO_LONG).nullable(),
  copayIn: commonMoneyValidator,
  copayOut: commonMoneyValidator,
  isReferallRequired: yup.boolean(),
  isAuthorizationRequired: yup.boolean(),
  note: yup.string().trim().max(100, TOO_LONG).nullable(),
  //patientPhoneNumber: yup.string().trim().nullable(),

  coveredServices: yup.array().of(yupServicesSchema).nullable(),
});

export const Schema = yup.object().shape({
  insuranceName: yup.string().trim().required(REQUIRED_FIELD),

  groups: yup.array().of(yupGroupSchema).nullable(),
});

export const Shares = [
  {
    value: '0/100',
    name: '0/100%',
  },
  {
    value: '10/90',
    name: '10/90%',
  },
  {
    value: '20/80',
    name: '20/80%',
  },
  {
    value: '30/70',
    name: '30/70%',
  },
  {
    value: '40/60',
    name: '40/60%',
  },
  {
    value: '50/50',
    name: '50/50%',
  },
  {
    value: '60/40',
    name: '60/40%',
  },
  {
    value: '70/30',
    name: '70/30%',
  },
  {
    value: '80/20',
    name: '80/20%',
  },
  {
    value: '90/10',
    name: '90/10%',
  },
  {
    value: '100/0',
    name: '100/0%',
  },
];
