import { Dialog, Tab } from '@mui/material';
import { Button, TabsItem } from 'components';
import * as S from './styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ClaimHistoryMessages from './components/ClaimHistoryMessages';
import ClaimHistoryEvents from './components/ClaimHistoryEvents';
import { DeleteGrayBtnSVG, DownloadFileSVG, JPGSVG, PDFSVG } from 'assets/icons';
import { useDeleteFileMutation } from 'store/api/files/files';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import {
  useLazyAttachmentsGetQuery,
  useUploadAttachmentsMutation,
} from 'store/api/claims/claimsApi';
import { ClaimStatusType } from 'types/InvoiceType';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CircularLoader from 'components/atoms/CircuralLoader';

type Props = {
  isShow: boolean;
  claimId: string;
  claimStatus?: string;
};

const ClaimHistory = ({ isShow, claimId, claimStatus }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadAttachmentsMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});
  const [getAttachments, attachmentsStatus] = useLazyAttachmentsGetQuery({});

  const [tabIndex, setTabIndex] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    inputRef?.current?.click();
  };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const { target = {} } = event || {};

    // @ts-ignore
    target.value = '';
  };

  const handleClearImg = (id: string) => {
    deleteFile({ id });
  };

  const handleSuccessDelete = () => {
    showConfirmDialog && attachmentsStatus.data?.forEach(i => deleteFile({ id: i.id }));
  };

  const handleChangeFile = (e: any) => {
    const fileList = e.target.files;
    if (fileList) {
      if (fileList.length > 10) {
        return dispatch(
          setMessage({
            message: 'You can send only 10 files',
            type: 'warning',
          }),
        );
      }

      const files = [...fileList];
      const filteredFileList = files.filter(i => i.size > 50000000);

      if (filteredFileList.length) {
        return dispatch(
          setMessage({
            message: 'Each file must be less than 50 MB',
            type: 'warning',
          }),
        );
      }

      uploadInitialFiles({ files, claimId });
    }
  };

  const isShowAttachments = useMemo(() => {
    return claimStatus === ClaimStatusType.RELEASED;
  }, [claimStatus]);

  const disabled = useMemo(() => {
    return (
      uploadInitialFilesStatus.isLoading ||
      statusDelete.isLoading ||
      attachmentsStatus.isFetching
    );
  }, [
    uploadInitialFilesStatus.isLoading,
    statusDelete.isLoading,
    attachmentsStatus.isFetching,
  ]);

  useEffect(() => {
    if (isShow) {
      getAttachments({ claimId });
    }
  }, [isShow]);

  return (
    <S.Wrapper $isShow={isShow}>
      {isShow && (
        <S.Content>
          <S.Box>
            <S.Header>
              <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
                <Tab disableRipple label="Messages"></Tab>
                <Tab disableRipple label="Events"></Tab>
                {/*<Tab disableRipple label="Related"></Tab>*/}
              </TabsItem>
            </S.Header>
            <S.Body className={isShowAttachments ? 'small' : ''}>
              {tabIndex === 0 && <ClaimHistoryMessages claimId={claimId} />}
              {tabIndex === 1 && <ClaimHistoryEvents claimId={claimId} />}
            </S.Body>
          </S.Box>

          {isShowAttachments && (
            <S.Footer>
              <S.TitleBox>
                <S.Title>Attachments</S.Title>
                {disabled && (
                  <S.Loader>
                    <CircularLoader color="#0084B1" size={16} />
                  </S.Loader>
                )}
                {!!attachmentsStatus.data?.length && (
                  <DeleteGrayBtnSVG
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowConfirmDialog(true)}
                  />
                )}
              </S.TitleBox>
              <label htmlFor="file">
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  onChange={handleChangeFile}
                  accept="image/png, image/jpeg, application/pdf"
                  hidden
                  multiple={true}
                  onClick={handleInputClick}
                />
              </label>
              <S.UploadButtonWrapper>
                <Button
                  text=""
                  onClick={handleClick}
                  customStyle={{
                    width: '210px',
                    border: '1px solid #0084b1',
                    backgroundColor: 'transparent',
                  }}
                >
                  <S.ButtonArticle>
                    <DownloadFileSVG /> Upload Attachments
                  </S.ButtonArticle>
                </Button>
                {attachmentsStatus?.data?.map(item => (
                  <S.FileRow
                    key={item?.id}
                    onClick={() => {
                      window.open(item?.url);
                    }}
                  >
                    <S.InfoRow>
                      {item?.name.includes('.pdf') ? (
                        <PDFSVG className="icon" />
                      ) : (
                        <JPGSVG className="icon" />
                      )}
                      {item?.name}
                    </S.InfoRow>
                    <DeleteGrayBtnSVG
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        if (disabled) {
                          return;
                        }

                        e.stopPropagation();
                        handleClearImg(item?.id);
                      }}
                    />
                  </S.FileRow>
                ))}
              </S.UploadButtonWrapper>
            </S.Footer>
          )}
        </S.Content>
      )}

      <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
        <ConfirmDeleteDialog
          onClose={() => setShowConfirmDialog(false)}
          titleText="files"
          helperText="This process is irreversible"
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
    </S.Wrapper>
  );
};

export default ClaimHistory;
