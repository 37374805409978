import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '../../../atoms/Button';
import Input from '../../../atoms/Input';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import DatePicker from '../../../atoms/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import SelectInput from '../../../atoms/SelectInput';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  coupounsApi,
  useCouponCreateMutation,
  useCouponSourceGetQuery,
  useCouponUpdateMutation,
} from '../../../../store/api/coupons/couponsApi';
import { ucFirst } from '../../../../helpers/functions/toUpperCase';
import { useClinicsGetQuery } from '../../../../store/api/clinics/clinicApi';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Clinics } from '../../../../types/ClinicTypes';
import { AddRectSVG, CouponNoteSVG, DeleteBtnSVG } from '../../../../assets/icons';
import { getFileIcon } from '../../../../helpers/getFileIcon';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../types/DrawerMode';
import { setMessage } from '../../../../store/slices/message';
import { Coupon } from '../../../../types/CouponTypes';
import { useSurveyCreateMutation } from '../../../../store/api/survey/surveyApi';

type FormValues = {
  title: string;
  description: string;
  link: string;
};

const AddSurvey = () => {
  const Shape = yup.object().shape({});

  const [createSurvey] = useSurveyCreateMutation({});
  const dispatch = useAppDispatch();
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      title: '',
      description: '',
      link: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {
      dispatch(showDrawer({ show: false, props: null, mode: DrawerMode.DEFAULT }));
      createSurvey({
        title: values.title,
        link: values.link,
        description: values.description,
        clinicId: selectedClinic.id,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Survey was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(
            setMessage({
              message: err.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>{'Create Survey'}</S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="title"
                name="title"
                label="Title"
                isRequired
                value={values.title}
                onChange={handleChangeInput}
                error={!!errors.title}
                helperText={errors.title}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="link"
                name="link"
                label="Link"
                isRequired
                value={values.link}
                onChange={handleChangeInput}
                error={!!errors.link}
                helperText={errors.link}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="description"
                name="description"
                label="Description"
                isRequired
                rows={5}
                multiline
                value={values.description}
                onChange={handleChangeInput}
                error={!!errors.description}
                helperText={errors.description}
              />
            </S.InputRow>
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text={'Create'} type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddSurvey;
