import styled from 'styled-components';
import { devices } from 'constants/device';

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-left: 0px;
    margin-right: 0px;
    height: none;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  min-width: 300px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-left: 25px;
  margin-top: 23px;
`;

export const SubtitleItems = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 25px;
`;

export const ButtonWrapper = styled.div`
  width: 210px;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 8px;
  padding-left: 27px;
  padding-right: 27px;

  @media ${devices.xs} {
    margin-left: 0px;
    flex-direction: column;
  }
`;

export const SearchWrapper = styled.div`
  width: 394px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const SelectWrapper = styled.div`
  width: 160px;
  margin-top: 6px;
  margin-right: 25px;

  & .MuiInputBase-root {
    background-color: #fff;
  }
`;

export const MenuItemRow = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
`;

export const SubHeader = styled.div`
  width: 100%;
  //border-top: 0.5px solid var(--line, #d7d7d7);
  display: flex;
  justify-content: end;
`;

export const DashboardFilterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const DashboardCardWrapper = styled.div`
  max-height: 150px;
  width: 100%;
`;

export const DashboardSmallWrapper = styled.div`
  // height: 111px;
  max-height: 150px;
  width: 100%;
`;

export const DashboardItems = styled.div`
  display: flex;
  width: 100%;
  gap: 27px;
  margin-top: 18px;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const MainInfoCard = styled.div`
  margin-top: 21px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 84, 113, 0.1);
  width: calc(100% / 2 - 10px);
  height: auto;
`;

export const MainInformation = styled.div`
  margin-top: 21px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 84, 113, 0.1);
  width: 100%;
  height: auto;
`;

export const MainContent = styled.div`
  margin-top: 23px;
  margin-left: 28px;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Chart = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 400px;
  height: 400px;
  position: relative;
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
  margin-bottom: 20px;
  margin-right: 30px;
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 27px;
`;

export const RowCards = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 120px);

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;
