import { ReactNode, useState } from 'react';
import * as S from './styles';
import { IconButton, InputAdornment, TextFieldProps, Tooltip } from '@mui/material';
import { EyeUnVisibleSVG, EyeVisibleSVG } from 'assets/icons';

type Props = {
  width?: string;
  height?: string;
  isPassword?: boolean;
  label?: string;
  background?: string;
  isRequired?: boolean;
  isSum?: string | ReactNode;
  tooltipHelperText?: string;
} & TextFieldProps;

export const TooltipInput = ({
  label,
  value,
  onChange,
  background,
  isPassword,
  width,
  height,
  isRequired,
  rows,
  multiline,
  children,
  select,
  error,
  helperText,
  id,
  name,
  isSum,
  tooltipHelperText,
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <S.InputWrapper>
      <S.Label htmlFor="input">
        {label} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <Tooltip title={tooltipHelperText ? tooltipHelperText : ''} placement={'right'}>
        <S.InputItem
          name={name}
          id={id}
          value={value}
          error={error}
          helperText={helperText}
          select={select}
          onChange={onChange}
          rows={rows}
          // required={isRequired}
          multiline={multiline}
          type={!showPassword && isPassword ? 'password' : 'text'}
          {...props}
          InputProps={{
            style: {
              backgroundColor: background,
              borderColor: error ? 'red' : '',
            },
            endAdornment: (isPassword || isSum) && (
              <InputAdornment position="end">
                {isPassword && (
                  <IconButton
                    disableRipple={true}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <EyeVisibleSVG style={{ cursor: 'pointer' }} />
                    ) : (
                      <EyeUnVisibleSVG style={{ cursor: 'pointer' }} />
                    )}
                  </IconButton>
                )}
                {!!isSum && (
                  <IconButton
                    disableRipple={true}
                    aria-label="toggle password visibility"
                  >
                    {isSum}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        >
          {children}
        </S.InputItem>
      </Tooltip>
    </S.InputWrapper>
  );
};
