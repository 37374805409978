import Button from '../../atoms/Button';
import * as S from './styles';
import { ReactNode } from 'react';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  titleText?: string;
  fullTitle?: string;
  helperText?: string | ReactNode;
  closeAfterSuccess?: boolean;
  cancelText?: string;
  confirmText?: string;
  href?: string;
  helperTextWithNote?: boolean;
  smallHelperTextLineHeight?: boolean;
};

const ConfirmDeleteDialog: React.FC<Props> = ({
  onClose,
  titleText,
  onSuccess,
  fullTitle,
  helperText,
  closeAfterSuccess = true,
  cancelText = 'No',
  confirmText = 'Yes',
  href,
  helperTextWithNote = true,
  smallHelperTextLineHeight = false,
}) => {
  const handleConfirmClick = () => {
    onSuccess();
    closeAfterSuccess && onClose();
  };
  return (
    <S.Content>
      <S.Article>
        {fullTitle ? fullTitle : `Are you sure want to delete this ${titleText} ?`}
      </S.Article>
      {helperText && (
        <S.HelperText smallHelperTextLineHeight={smallHelperTextLineHeight}>
          {helperTextWithNote && <S.Span>NOTE: </S.Span>}
          {helperText}
        </S.HelperText>
      )}
      <S.Buttons>
        <S.ButtonWrapper>
          <Button text={cancelText} onClick={onClose} backgroundColor="#C66060" />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button text={confirmText} onClick={handleConfirmClick} href={href} />
        </S.ButtonWrapper>
      </S.Buttons>
    </S.Content>
  );
};

export default ConfirmDeleteDialog;
