import { IconButton, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import * as S from './styles';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'components/index';
import SelectInput from 'components/atoms/SelectInput';
import OptionContent from 'components/organismus/AppointmentDrawer/components/PatientInformation/OptionContent';
import { OptionType } from 'components/organismus/AppointmentDrawer/components/PatientInformation/OptionContent/OptionContent';
import { CloseBtnSVG } from 'assets/icons';
import CircularLoader from 'components/atoms/CircuralLoader';

type Props = {
  mainLabel?: string;
  isRequired?: boolean;
  data: Array<any>;
  loading: boolean;
  searchValue: string;
  disabled?: boolean;
  selectedValue: any;
  setSearchValue: any;
  setSelectedValue: any;
  onDoubleClick?: () => void;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  setLastElement?: Dispatch<SetStateAction<HTMLDivElement | HTMLTableRowElement | null>>;
  setPage?: (page: number) => void;
};

export const AutoCompleteDatePicker: React.FC<Props> = ({
  mainLabel,
  isRequired,
  data,
  loading,
  searchValue,
  disabled,
  selectedValue,
  setSearchValue,
  setSelectedValue,
  onDoubleClick,
  error,
  helperText,
  defaultValue,
  setLastElement,
  setPage,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(true);

  useEffect(() => {
    if (data && defaultValue) {
      const currentValue = data?.find(
        i =>
          i?.user?.profile?.firstName + ' ' + i?.user?.profile?.lastName === defaultValue,
      );
      if (currentValue) {
        setSelectedValue(currentValue);
      }
    }
  }, [defaultValue]);

  const selectRenderValue = useMemo(() => {
    if (!selectedValue && searchValue) {
      return () => (
        <div style={{ opacity: 0.4 }}>{dayjs(searchValue).format('MM/DD/YYYY')}</div>
      );
    }
    return undefined;
  }, [selectedValue, searchValue]);

  return (
    <S.Wrapper onDoubleClick={onDoubleClick}>
      <S.Label htmlFor="input">
        {mainLabel} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      {showDatePicker ? (
        <DatePicker
          label={''}
          value={dayjs(searchValue)}
          onChange={value => {
            setPage && setPage(1);
            setSearchValue(value);
            !!value && setShowDatePicker(false);
          }}
          error={error}
          helperText={helperText}
          slotProps={{ field: { clearable: !!searchValue } }}
          disabled={disabled}
        />
      ) : (
        <SelectInput
          label=""
          placeholder={searchValue}
          value={selectedValue}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setSelectedValue(e.target.value);
          }}
          open={!showDatePicker}
          renderValue={selectRenderValue}
          onClose={() => {
            setSearchValue('');
            setShowDatePicker(true);
          }}
          endAdornment={
            !!searchValue && (
              <InputAdornment sx={{ marginRight: '12px' }} position="end">
                <IconButton>
                  <CloseBtnSVG />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {loading ? (
            <CircularLoader color="#0084B1" size={24} />
          ) : (
            data?.map((option, index, { length }) => {
              const refLast = index === length - 1 ? setLastElement : null;

              return (
                <MenuItem key={option.id} value={option}>
                  <OptionContent
                    searchValue={searchValue}
                    textToHighlight={
                      option.profile?.dateOfBirth
                        ? dayjs(option.profile?.dateOfBirth).format('MM/DD/YYYY')
                        : ''
                    }
                    otherText={option.profile?.lastName || ''}
                    type={OptionType.DOB}
                    option={option}
                    loading={false}
                    // @ts-ignore
                    ref={refLast}
                  />
                </MenuItem>
              );
            })
          )}
        </SelectInput>
      )}
    </S.Wrapper>
  );
};
