import { useNavigate } from 'react-router-dom';
import { AvatarSVG, DeleteRedBtnSVG } from 'assets/icons';
import { Button, CircularLoader, Dialog, HoldCard } from 'components';
import { ClaimCardType, ClaimStatusType, ClaimTypeEnum } from 'types/InvoiceType';
import * as S from './styles';
import { getClaimTypeColor } from 'helpers/UserStatusPinColors/getClaimTypeColor';
import { useMemo, useState } from 'react';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import {
  useCheckClaimStatusMutation,
  useDeleteClaimMutation,
  useFullyDeleteClaimMutation,
} from 'store/api/claims/claimsApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import dayjs from 'dayjs';

type Props = {
  claimInformation: ClaimCardType;
  refetch: () => void;
};

const ClaimItem = ({ claimInformation, refetch }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenFullyDeleteDialog, setIsOpenFullyDeleteDialog] = useState(false);
  const [isBlockNavigate, setIsBlockNavigate] = useState(false);
  const [deleteClaim] = useDeleteClaimMutation({});
  const [fullyDeleteClaim] = useFullyDeleteClaimMutation({});
  const [checkClaimStatus, statusCheckClaimStatus] = useCheckClaimStatusMutation({});

  const checkStatusCondition = useMemo(() => {
    return [
      ClaimStatusType.HOLD,
      ClaimStatusType.QUEUE,
      ClaimStatusType.RELEASED,
      ClaimStatusType.ACKNOWLEDGED,
      ClaimStatusType.REJECTED,
    ].includes(claimInformation.status as ClaimStatusType);
  }, [claimInformation.status]);

  return (
    <S.Wrapper
      onClick={() => {
        if (!isBlockNavigate) {
          navigate(`/claim-form/${claimInformation.id}`);
        }
      }}
    >
      <S.Content>
        <Dialog
          open={isOpenDeleteDialog}
          onClose={() => {
            setIsBlockNavigate(false);
            setIsOpenDeleteDialog(!isOpenDeleteDialog);
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setIsBlockNavigate(false);
              setIsOpenDeleteDialog(!isOpenDeleteDialog);
            }}
            onSuccess={() =>
              deleteClaim({ id: claimInformation.id })
                .unwrap()
                .then(res => {
                  refetch();
                  dispatch(
                    setMessage({
                      message: 'Claim was successfully deleted',
                      type: 'success',
                    }),
                  );
                })
                .catch(error => {
                  dispatch(setMessage({ message: error.data.message, type: 'error' }));
                })
            }
            helperText="Once deleted, the Claim will be placed in the Deleted tab"
            fullTitle="Are you sure want to delete this claim?"
          />
        </Dialog>

        <Dialog
          open={isOpenFullyDeleteDialog}
          onClose={() => {
            setIsBlockNavigate(false);
            setIsOpenFullyDeleteDialog(false);
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setIsBlockNavigate(false);
              setIsOpenFullyDeleteDialog(false);
            }}
            onSuccess={() =>
              fullyDeleteClaim({ id: claimInformation.id })
                .unwrap()
                .then(res => {
                  refetch();
                  dispatch(
                    setMessage({
                      message: 'Claim was successfully deleted',
                      type: 'success',
                    }),
                  );
                })
                .catch(error => {
                  dispatch(setMessage({ message: error.data.message, type: 'error' }));
                })
            }
            helperText="This is an irreversible process!"
            fullTitle="Are you sure want to delete this claim?"
          />
        </Dialog>

        <S.HeaderItem>
          <S.Name>
            <S.Span>#{claimInformation.id}</S.Span>
          </S.Name>
          <S.Avatar>
            {claimInformation.patient?.avatar ? (
              <img src={claimInformation.patient.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          <S.Name>
            {claimInformation.patientName ?? '-'}
            {claimInformation.patient?.isHoldAppointment && (
              <HoldCard text="HA" reason={claimInformation.patient?.isHoldAppointment} />
            )}
            {claimInformation.patient?.isHoldBilling && (
              <HoldCard text="HB" reason={claimInformation.patient?.isHoldBilling} />
            )}
          </S.Name>
          <S.AccNumber>
            {/* <S.PayerTitle>Acc. N:</S.PayerTitle>{" "} */}
            {claimInformation.patientAccountNumber && (
              <S.ACCNumber>( {claimInformation.patientAccountNumber} )</S.ACCNumber>
            )}
          </S.AccNumber>
        </S.HeaderItem>
        <S.Line>
          <S.PayerInfo>
            <S.PayerTitle>Insurance name: </S.PayerTitle>
            <S.Data>{claimInformation?.insurancePlanNameOrProgramName || '-'}</S.Data>
          </S.PayerInfo>
          <S.PayerInfo>
            <S.PayerTitle>Sum: </S.PayerTitle>
            <S.Data>${claimInformation.totalCharge ?? 0}</S.Data>
          </S.PayerInfo>
          <S.PayerInfo>
            <S.PayerTitle>Created date: </S.PayerTitle>
            <S.Data>{dayjs(claimInformation.createdAt).format('MM/DD/YYYY') ?? 0}</S.Data>
          </S.PayerInfo>
          <S.PayerInfo>
            <S.PayerTitle>From date: </S.PayerTitle>
            <S.Data>-</S.Data>
          </S.PayerInfo>
          {claimInformation.paymentDate && (
            <S.PayerInfo>
              <S.PayerTitle>Payment date: </S.PayerTitle>
              <S.Data>-</S.Data>
            </S.PayerInfo>
          )}
        </S.Line>
      </S.Content>
      {checkStatusCondition && (
        <S.ButtonWrapper
          onClick={e => {
            e.stopPropagation();
            checkClaimStatus({ id: claimInformation.id });
          }}
        >
          <Button text="Check Status" />
          <S.EmptyDiv />
          {statusCheckClaimStatus.isLoading && (
            <CircularLoader size={20} color="#0084B1" />
          )}
        </S.ButtonWrapper>
      )}
      <S.RightSide>
        <S.CardType
          color={getClaimTypeColor(claimInformation.claimType as ClaimTypeEnum)}
        >
          {claimInformation.claimType}
        </S.CardType>
        <S.Down>
          <S.DeleteWrapper
            onClick={e => {
              e.stopPropagation();
              setIsBlockNavigate(true);
              claimInformation.status === ClaimStatusType.DELETED
                ? setIsOpenFullyDeleteDialog(true)
                : setIsOpenDeleteDialog(true);
            }}
          >
            <DeleteRedBtnSVG />
          </S.DeleteWrapper>
        </S.Down>
      </S.RightSide>
    </S.Wrapper>
  );
};

export default ClaimItem;
