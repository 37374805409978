import { PlaceHolderTreatmentsSVG } from '../../../../../assets/icons';
import * as S from '../styles';

const NoTreatmentPlaceholder = () => {
  return (
    <S.Wrapper>
      <S.PlaceholderWrapper>
        <S.ArticleWrapper>
          <S.Article>Initial Treatment Form</S.Article>
        </S.ArticleWrapper>
        <S.PlaceholderContent>
          <PlaceHolderTreatmentsSVG />
          <S.HelperText>No Treatment form</S.HelperText>
        </S.PlaceholderContent>
      </S.PlaceholderWrapper>
    </S.Wrapper>
  );
};

export default NoTreatmentPlaceholder;
