import { Fade, Tooltip } from '@mui/material';
import * as S from './styles';

type Props = {
  text: string;
  reason: string;
};

const HoldCard: React.FC<Props> = ({ text, reason }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#C66060',
            fontFamily: 'Inter',
          },
        },
      }}
      title={reason}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
    >
      <S.Wrapper>{text}</S.Wrapper>
    </Tooltip>
  );
};

export default HoldCard;
