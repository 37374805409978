import { MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { Profile } from '../../../../../../../../types/ProfileTypes';
import { Roles } from '../../../../../../../../types/Roles';
import SearchContentRow from '../SeachContentRow';
import * as S from './styles';
import { CircularLoader } from '../../../../../../../../components';
import { Response } from '../../../../../../../../types/Response';

type Row = {
  id: string;
  profile: Profile;
  role: Roles;
  phone: string;
};

type Props = {
  page: number;
  count?: number;
  setPage: SetStateAction<any>;
  isFetching: boolean;
  data?: Response<any>;
  selectedRow: Row | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<Row | null>>;
  messageStartRef: MutableRefObject<null | HTMLDivElement>;
  scroll: () => void;
};

const SearchContentList: React.FC<Props> = ({
  page,
  setPage,
  count,
  data,
  isFetching,
  selectedRow,
  setSelectedRow,
  messageStartRef,
  scroll,
}) => {
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && page !== data?.lastPage) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Text>Search result {count}</S.Text>
      </S.Header>
      <S.Content>
        {count ? (
          <>
            <div ref={messageStartRef}></div>
            {data?.rows?.map((row, index, { length }) => {
              const isLast = index === length - 1 && length < data?.count;
              return (
                <div key={row.id} ref={isLast ? setObserverTarget : undefined}>
                  <SearchContentRow
                    isTable={true}
                    row={row}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <S.Placeholder>No any results</S.Placeholder>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default SearchContentList;
