import dayjs, { Dayjs } from 'dayjs';
import * as S from '../styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { ChangeEvent, useState } from 'react';
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import InputCell from 'components/atoms/InputCell';
import { usePrepaymentInvoiceItemUpdateMutation } from 'store/api/invoices/invoiceApi';
import { setMessage } from 'store/slices/message';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, Dialog, HoldCard, SelectInput } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { AccessLevel, ClinicInsuranceType } from 'types/enums/AppointmentEnum';
import { getNetworkTypeName } from 'helpers/functions/getInOutNetworkName';
import { ChooseDoctorOrClinic } from 'pages/Billing/components/InvoiceDetail/components/InvInformationTable/TableData/ChooseDoctorOrClinic';
import { DotsSVG, EditBtnMinimalSVG } from 'assets/icons';
import NotificationPopover from 'components/organismus/NotificationPopover';
import Button from 'components/atoms/Button';

type Props = {
  row: any;
  header: string;
};

const InvInfPrepData: React.FC<Props> = ({ row, header }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const me = useAppSelector(state => state.auth);
  const { invoicePrePaymentAccessLevel } = useAppSelector(state => state.auth);

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);
  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [editDate, setEditDate] = useState(false);
  const [date, setDate] = useState<Dayjs | string>(dayjs());

  const [editDOS, setEditDOS] = useState(false);
  const [DOS, setDOS] = useState<Dayjs | string>(dayjs());

  const [editDoctor, setEditDoctor] = useState(false);

  const [editAmount, setEditAmount] = useState(false);
  const [amount, setAmount] = useState(0);

  const [editNetwork, setEditNetwork] = useState(false);
  const [network, setNetwork] = useState('');

  const [changeItem] = usePrepaymentInvoiceItemUpdateMutation({});

  const handleSave = ({ ...params }) => {
    changeItem({
      ...params,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Invoice item was updated successfully',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  switch (header) {
    case 'ID': {
      return row.id || '-';
    }
    case 'PatientName': {
      return (
        <S.Link onClick={() => navigate('/patients/' + row.claim?.patient?.id)}>
          {row.claim?.patient?.profile?.firstName
            ? row.claim?.patient?.profile?.firstName +
              ' ' +
              row.claim?.patient?.profile?.lastName
            : '-'}
          {row.claim?.patient?.isHoldBilling && (
            <HoldCard text="HB" reason={row.claim.patient.isHoldBilling} />
          )}
          {row.claim?.patient?.isHoldAppointment && (
            <HoldCard text="HA" reason={row.claim.patient.isHoldAppointment} />
          )}
        </S.Link>
      );
    }
    case 'Account': {
      return <>{row.accountNumber ? row.accountNumber : '-'}</>;
    }
    case 'Claim': {
      return (
        <S.Link onClick={() => !!row.claimId && navigate(`/claim-form/${row.claimId}`)}>
          {row.claimId ? row.claimId : '-'}
        </S.Link>
      );
    }
    case 'SubmittedOn': {
      return (
        <S.CellWrapper
          className="min"
          editable={invoicePrePaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePrePaymentAccessLevel === AccessLevel.WRITE) {
              setDate(dayjs(row.submittedOnDate) || '');
              setEditDate(true);
            }
          }}
        >
          {editDate ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditDate(false);
                editDate &&
                  dayjs(date).format('MM/DD/YYYY') !==
                    dayjs(row.submittedOnDate).format('MM/DD/YYYY') &&
                  handleSave({
                    id: row.id,
                    submittedOnDate: dayjs(date).format('YYYY-MM-DD'),
                    employeeId: row?.employeeId ? me?.id : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <DatePicker
                  background="none"
                  isCell
                  label=""
                  value={date}
                  onChange={value => setDate(dayjs(value).format('MM/DD/YYYY'))}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>
              {row.submittedOnDate
                ? dayjs(row.submittedOnDate).format('MM/DD/YYYY')
                : '-'}
            </>
          )}
        </S.CellWrapper>
      );
    }
    case 'DOS': {
      return (
        <S.CellWrapper
          className="min"
          editable={invoicePrePaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePrePaymentAccessLevel === AccessLevel.WRITE) {
              setDOS(dayjs(row.dateOfService) || '');
              setEditDOS(true);
            }
          }}
        >
          {editDOS ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditDOS(false);
                editDOS &&
                  dayjs(DOS).format('MM/DD/YYYY') !==
                    dayjs(row.dateOfService).format('MM/DD/YYYY') &&
                  handleSave({
                    id: row.id,
                    dateOfService: dayjs(DOS).format('YYYY-MM-DD'),
                    employeeId: row?.employeeId ? me?.id : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <DatePicker
                  background="none"
                  isCell
                  label=""
                  value={DOS}
                  onChange={value => setDOS(dayjs(value).format('MM/DD/YYYY'))}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row.dateOfService ? dayjs(row.dateOfService).format('MM/DD/YYYY') : '-'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Charged': {
      return (
        <S.CellWrapper
          editable={invoicePrePaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePrePaymentAccessLevel === AccessLevel.WRITE) {
              setAmount(row.charged || 0);
              setEditAmount(true);
            }
          }}
        >
          {editAmount ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditAmount(false);
                editAmount &&
                  amount !== row.charged &&
                  !(amount === 0 && row.charged === null) &&
                  handleSave({
                    id: row.id,
                    charged: amount,
                    employeeId: row?.employeeId ? me?.id : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disableRipple={true}
                          aria-label="toggle password visibility"
                        >
                          USD
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={amount}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAmount(+e.target.value)
                  }
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{(row.charged ?? 0) + ' USD'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Dr': {
      return (
        <>
          <S.CellWrapper
            editable={invoicePrePaymentAccessLevel}
            onDoubleClick={() => {
              invoicePrePaymentAccessLevel === AccessLevel.WRITE && setEditDoctor(true);
            }}
          >
            {row?.doctor?.profile
              ? row?.doctor?.profile?.firstName + ' ' + row?.doctor?.profile?.lastName
              : '-'}
          </S.CellWrapper>

          <Dialog open={editDoctor} onClose={() => setEditDoctor(false)}>
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    doctorInPrePaymentId: id,
                    employeeId: row?.employeeId ? me?.id : undefined,
                    invoiceId: Number(params.invoiceId),
                  });
                setEditDoctor(false);
              }}
              title={'Rendering Provider'}
              onlyDoctor
              currentValue={row?.doctor?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'Employee': {
      return (
        <>
          {row?.employeeId ? (
            <S.CellWrapper>
              {row.employee?.profile
                ? row.employee?.profile?.firstName + ' ' + row.employee?.profile?.lastName
                : '-'}
            </S.CellWrapper>
          ) : (
            <S.DotsWrapper>
              <S.CellWrapper>-</S.CellWrapper>
              {invoicePrePaymentAccessLevel === AccessLevel.WRITE && (
                <S.Dots onClick={handleAnchorReport}>
                  <DotsSVG />
                </S.Dots>
              )}
              <NotificationPopover
                open={Boolean(anchorElReport)}
                anchorEl={anchorElReport}
                onClose={() => setAnchorElReport(null)}
              >
                <S.ContentWrapper>
                  <S.ContentRow onClick={() => setOpenConfirmDialog(true)}>
                    <EditBtnMinimalSVG />
                    Mark as Checked
                  </S.ContentRow>
                </S.ContentWrapper>
              </NotificationPopover>
            </S.DotsWrapper>
          )}
          <Dialog
            open={openConfirmDialog}
            onClose={() => {
              setAnchorElReport(null);
              setOpenConfirmDialog(false);
            }}
          >
            <S.Content>
              <S.Article>Mark as checked</S.Article>
              <S.HelperText>
                Do you really want to mark this item as checked?
              </S.HelperText>

              <S.ButtonWrapper>
                <Button
                  text="Submit"
                  onClick={() => {
                    handleSave({
                      id: row.id,
                      employeeId: me?.id,
                      invoiceId: Number(params.id),
                    });
                    setAnchorElReport(null);
                    setOpenConfirmDialog(false);
                  }}
                />
              </S.ButtonWrapper>
            </S.Content>
          </Dialog>
        </>
      );
    }
    case 'Network': {
      return (
        <S.CellWrapper
          editable={invoicePrePaymentAccessLevel}
          onDoubleClick={() => {
            if (invoicePrePaymentAccessLevel === AccessLevel.WRITE) {
              setEditNetwork(true);
              setNetwork(row.network || '');
            }
          }}
        >
          {editNetwork ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditNetwork(false);
                editNetwork &&
                  network !== row.network &&
                  !(network === '' && row.network === null) &&
                  handleSave({
                    id: row.id,
                    network,
                    employeeId: row?.employeeId ? me?.id : undefined,
                    invoiceId: Number(params.id),
                  });
              }}
            >
              <S.InputWrapper>
                <SelectInput
                  isCell
                  label=""
                  value={network}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    setNetwork(e.target.value as string)
                  }
                >
                  <MenuItem value={ClinicInsuranceType.IN_NETWORK}>
                    <S.MenuItemContent>
                      {getNetworkTypeName(ClinicInsuranceType.IN_NETWORK)}
                    </S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={ClinicInsuranceType.OUT_OF_NETWORK}>
                    <S.MenuItemContent>
                      {getNetworkTypeName(ClinicInsuranceType.OUT_OF_NETWORK)}
                    </S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row.network ? getNetworkTypeName(row.network) : '-'}</>
          )}
        </S.CellWrapper>
      );
    }

    default:
      return row[header] || '-';
  }
};

export default InvInfPrepData;
