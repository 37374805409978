import { Tabs } from '@mui/material';
import * as S from './styles';
import { ReactNode } from 'react';

type TabsProps = {
  children?: ReactNode;
  tabIndex: number | string;
  setTabIndex: (index: number | string) => void;
};

export const CustomTabsItem = ({ children, tabIndex, setTabIndex }: TabsProps) => {
  return (
    <S.TabsWrapper>
      <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
        {children}
      </Tabs>
    </S.TabsWrapper>
  );
};
