import { Clinics, Patients } from '../../../types/ClinicTypes';
import { TicketType } from '../../../types/TicketType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export type StaffSchedule = {
  id: string;
  weekDay: number;
  workingHoursStart: string;
  workingHoursEnd: string;
  isWorkDay?: boolean;
  clinicId: string;
  clinic: {
    name: string;
  };
};

export const staffScheduleApi = api.injectEndpoints({
  endpoints: builder => ({
    StaffScheduleOneGet: builder.query<Response<StaffSchedule>, any>({
      query: ({ doctorId, weekDay }) => ({
        url: `/staff-schedule?take=200`,
        params: { doctorId, weekDay },
      }),
      providesTags: ['Schedule'],
    }),
    StaffScheduleCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/staff-schedule`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'Schedule' }],
    }),
    StaffScheduleDelete: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/staff-schedule/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'Schedule' }],
    }),
    StaffScheduleMarkWorkDay: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `/staff-schedule/change-work-day`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: () => [{ type: 'Schedule' }],
    }),
    StaffScheduleUpdate: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `/staff-schedule/${id}`,
          method: 'PUT',
          body: { ...body },
        };
      },
      invalidatesTags: () => [{ type: 'Schedule' }],
    }),
  }),
});

export const {
  useStaffScheduleCreateMutation,
  useStaffScheduleOneGetQuery,
  useStaffScheduleDeleteMutation,
  useStaffScheduleMarkWorkDayMutation,
  useStaffScheduleUpdateMutation,
} = staffScheduleApi;
