import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDashVisitReasonsGetQuery } from 'store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import { CircularLoader, DashboardCard, VerticalChart } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { getFirstAndLastDate } from 'helpers/functions/getFirstAndLastDate';
import { useDebounce } from 'use-debounce';
import { DatesFilter } from 'pages/Receptionist/components/Dashboard/components/DatesFilter';

const DashVisitReasons = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).firstDate,
  );
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).lastDate,
  );

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  const getStat = useDashVisitReasonsGetQuery({
    clinicId: selectedClinic.id,
    startDate: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
    endDate: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
  });

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const data = {
    labels: getStat.currentData?.graph?.map((item: any) => item.reason),
    datasets: [
      {
        label: 'Canceled',
        backgroundColor: '#E17777',
        data: getStat.currentData?.graph?.map((item: any) => item.canceledCount),
        borderRadius: 7,
      },
      {
        label: 'Completed',
        backgroundColor: '#0084B1',
        borderRadius: 10,
        data: getStat.currentData?.graph?.map((item: any) => item.completedCount),
      },
    ],
  };

  return (
    <S.MainInformation>
      <S.MainContent>
        <S.TitleBox>
          <S.SubtitleText>Services (Completed Appointments)</S.SubtitleText>
          <DatesFilter
            firstDate={firstDate}
            secondDate={secondDate}
            setFirstDate={setFirstDate}
            setSecondDate={setSecondDate}
            isLoading={getStat.isFetching || getStat.isLoading}
          />
        </S.TitleBox>

        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <VerticalChart stackedData={data} propsOptions={options} />
            )}
          </S.Chart>
          <S.Items>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Completed"
                number={getStat.currentData?.totalCompleted}
                $isGray
              />
            </S.DashboardSmallWrapper>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Canceled"
                number={getStat.currentData?.totalCanceled}
                $isGray
              />
            </S.DashboardSmallWrapper>
          </S.Items>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInformation>
  );
};

export default DashVisitReasons;
