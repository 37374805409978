import {
  InvoiceHistoryOperation,
  InvoiceShowHistory,
} from '../../../../../../types/InvoiceType';

import * as S from './styles';

export const getTextHistory = (item: InvoiceShowHistory) => {
  switch (item.operation) {
    case InvoiceHistoryOperation.ADD_ITEM:
      return (
        <S.RowArticle>
          <S.SpanWeight>{`Add ITEM ID : ${item.invoiceItemId} `}</S.SpanWeight>
        </S.RowArticle>
      );
    case InvoiceHistoryOperation.CREATE:
      return (
        <S.RowArticle>
          <S.SpanWeight>{`Created  `}</S.SpanWeight>
        </S.RowArticle>
      );
    case InvoiceHistoryOperation.UPDATE_ITEM:
      return (
        <S.RowArticle>
          <S.SpanWeight>Updated: </S.SpanWeight>
          <S.SpanWeight>"{item.changeList[0]?.key}" </S.SpanWeight>
          from <S.SpanWeight>{item.changeList[0]?.from || '" - "'} </S.SpanWeight>
          to <S.SpanWeight>{item.changeList[0]?.to || '" - "'} </S.SpanWeight>
          by{' '}
          <S.SpanBy>
            {item.editor?.profile?.firstName + ' ' + item.editor?.profile?.lastName}
          </S.SpanBy>
        </S.RowArticle>
      );
    default:
      return '-';
  }
};
