import { useEffect, useState } from 'react';
import { useGetClinicWorkingTimeMutation } from '../../../../../store/api/clinic-schedule/clinicScheduleApi';
import HeaderLine from './components/HeaderLine';
import PlannerRecord from './components/PlannerRecord';
import * as S from './styles';
import { DateObject } from 'react-multi-date-picker';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { Time } from '../../../../../pages/Billing/components/InvoiceDetail/components/ShowInvoiceHistory/styles';
import CircularLoader from '../../../../atoms/CircuralLoader';
import { constantTimePickerOUT } from '../../../../../constants/constants';
import { Response } from '../../../../../types/Response';
import { AppointmentsNew } from '../../../../../types/AppointmentNewTypes';

import TimeLine from './components/TimeLine';

type Props = {
  selectedDate: DateObject[];
  isFetching: boolean;
  allAppointments?: Response<AppointmentsNew>;
};

const PlannerView: React.FC<Props> = ({ selectedDate, isFetching, allAppointments }) => {
  const [getTime, getTimeStatus] = useGetClinicWorkingTimeMutation({});
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [arrTime, setArrTime] = useState<string[]>([]);

  const [isWorkDay, setIsWorkDay] = useState(true);

  useEffect(() => {
    if (selectedClinic) {
      getTime({
        clinicId: selectedClinic.id,
        date: selectedDate[0].format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          setIsWorkDay(res.isWorkDay);
          res.time.length > 0
            ? setArrTime(res.time)
            : setArrTime(constantTimePickerOUT.map(item => item.value));
        });
    }
  }, [selectedDate, selectedClinic]);

  return (
    <S.Wrapper>
      <HeaderLine />
      <S.PlannerItems>
        {getTimeStatus.isLoading ? (
          <CircularLoader color="#0084B1" />
        ) : (
          <>
            {arrTime &&
              arrTime.map(time => {
                return (
                  <TimeLine
                    allAppointments={allAppointments}
                    time={time}
                    key={time}
                    isWorkDay={isWorkDay}
                    selectedDate={selectedDate[0].format('YYYY-MM-DD')}
                  />
                );
              })}
          </>
        )}
      </S.PlannerItems>
    </S.Wrapper>
  );
};

export default PlannerView;
