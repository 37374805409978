import { ClaimStatusType } from 'types/InvoiceType';

export const getClaimConfig = (status: ClaimStatusType) => {
  switch (status) {
    case ClaimStatusType.CREATED:
      return {
        title: ClaimStatusType.CREATED,
        headercolor: '#79A030',
      };
    case ClaimStatusType.ERROR:
      return {
        title: ClaimStatusType.ERROR,
        headercolor: '#C66060',
      };
    case ClaimStatusType.DELETED:
      return {
        title: ClaimStatusType.DELETED,
        headercolor: '#E24733',
      };
    case ClaimStatusType.ACKNOWLEDGED:
      return {
        title: ClaimStatusType.ACKNOWLEDGED,
        headercolor: '#01796F',
      };
    case ClaimStatusType.REJECTED:
      return {
        title: ClaimStatusType.REJECTED,
        headercolor: '#911E42',
      };
    case ClaimStatusType.RELEASED:
      return {
        title: ClaimStatusType.RELEASED,
        headercolor: '#9663BE',
      };
    case ClaimStatusType.HOLD:
      return {
        title: ClaimStatusType.HOLD,
        headercolor: '#E1944D',
      };
    case ClaimStatusType.MODIFIED:
      return {
        title: ClaimStatusType.MODIFIED,
        headercolor: '#0084B1',
      };
    case ClaimStatusType.VALIDATED:
      return {
        title: ClaimStatusType.VALIDATED,
        headercolor: '#62639B',
      };
    case ClaimStatusType.QUEUE:
      return {
        title: ClaimStatusType.QUEUE,
        headercolor: '#FFB841',
      };
    case ClaimStatusType.PAID:
      return {
        title: ClaimStatusType.PAID,
        headercolor: '#01796F',
      };
    default:
      return {
        title: 'default',
        headercolor: '#E1944D',
      };
  }
};

export const claimStatusArray: string[] = Object.values(ClaimStatusType);
