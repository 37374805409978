import { Clinics } from './ClinicTypes';
import { UserProfile } from './UserProfileTypes';

export type TemplateType = {
  id: string;
  name: string;
  clinics: Array<Clinics>;
  file: File;
  note: string;
  type: TemplateNameType;
  createdBy: UserProfile;
  createdAt: string;
  key: string;
};

export enum TemplateNameType {
  DIGITAL = 'digital',
  PDF = 'pdf',
  CLIENT = 'client',
}

export enum TemplateFieldsForFill {
  PATIENT = 'patient',
  GUARDIAN = 'guardian',
  PATIENT_SIGNATURE = 'patientSignature',
  DOCTOR = 'doctor',
  CLINIC = 'clinic',
}

export type AppointmentsForms = {
  appointmentId: string;
  appointmentDate: string;
  formsCount: number;
};

export type TemplateFolders = {
  clinicName: string;
  appointmentsForms: AppointmentsForms[];
  initialDocumentsCount: number;
  otherDocumentsCount: number;
};

export enum DigitalTemplateType {
  INITIAL = 'initial',
  PT_INITIAL = 'pt_initial',
  ACUPUNCTURE_INSURANCE = 'acupuncture-insurance',
  PT_THERAPY = 'pt-therapy',
  CLIENT_REGISTRATION = 'client_registration',
}

export enum DigitalTemplateTypeByName {
  INITIAL = 'Initial visit form',
  PT_INITIAL = 'PT Initial Evaluation',
  ACUPUNCTURE_INSURANCE = 'Acupuncture Insurance Inquiry Questionnaire',
  PDF_ACU_INFORMED = 'Acupuncture informed consent to treat',
  PT_THERAPY = 'Physical Therapy Insurance Inquiry Questionnaire',
  CLIENT_REGISTRATION = 'Client Registration Form',
  PDF_OFFICE_POL = 'Office policies and procedures',
  PDF_INFORMED = 'Informed consent to physical therapy evaluation and treatment',
}

export interface InitialDocumentsFolders {
  id: string;
  name: string;
  key: any;
  url: string;
  mimetype: string;
  size: number;
  isSinature: boolean;
  isDoc: boolean;
  docType: string;
  isAvatar: boolean;
  createdById: string;
  insuranceId: any;
  clinicId: any;
  createdAt: string;
  createdByDoctor: {
    id: string;
    profile: {
      firstName: string;
      lastName: string;
    };
  };
  updatedAt: string;
}

export enum AppointmentTemplateType {
  INITIAL = 'Initial consultation',
  REEVAL = 'Re-evaluation',
  FOLLOWUP = 'Follow-up',
  HIDDENCLINIC = 'HiddenClinic',
}
