import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & .warning {
    svg {
      width: 16px;
      height: 16px;
    }

    &.MuiButtonBase-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
    }
  }

  & .MuiTab-iconWrapper {
    margin-bottom: 0 !important;
    margin-right: 4px;
  }

  & svg {
    width: 24px;
    height: 24px;
  }

  & .MuiTabs-root {
    border-radius: 5px;
    border: 0.5px solid var(--line, #d7d7d7);
    background: #fff;
    min-height: 0;
    padding: 5px;
    margin: 0;
    display: flex;
  }

  & .MuiTabs-scroller {
    padding: 0;
    margin: 0;
  }

  & .MuiTabs-flexContainer {
    gap: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
  }

  & .MuiButtonBase-root {
    color: var(--text, #202e5f);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
    padding: 13px 10px;
    min-width: 0;
    min-height: 0;
    text-transform: none;
    white-space: nowrap;
    z-index: 1000;
    height: 24px;

    &.Mui-selected {
      opacity: 1;
      /* background: #e1ebee; */
      color: #202e5f;
    }
  }

  & .MuiTabs-indicator {
    top: 0;
    left: 0;
    height: 28px;
    z-index: 999;
    border-radius: 5px;
    background: #e1ebee;
  }
`;
