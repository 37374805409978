import dayjs from 'dayjs';

export const getAge = (dateOfBirth?: string): number => {
  if (dateOfBirth) {
    const birthDate = dayjs(dateOfBirth);
    const today = dayjs();
    const age = today.diff(birthDate, 'year');
    return age;
  } else return 0;
};
