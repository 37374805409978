import styled from 'styled-components';
import { devices } from '../../../../../constants/device';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  gap: 7px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const Buttons = styled.div`
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const ResetLink = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const Text = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AppointmentLink = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 174px;

  @media ${devices.xs} {
    width: 300px;
  }
`;
