import { FormikProps } from 'formik';
import { Dialog, Input, SuccessDialog } from '../../../../../../components';
import CheckBoxWithLabel from '../../../../../../components/atoms/CheckboxWithLabel';
import CoInsurancePolicy from './components/CoInsurancePolicy';
import Deductible from './components/Deductible';
import PreviousPT from './components/PreviousPT';
import * as S from './styles';
import { PagePTInsuranceType } from '../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { forwardRef, useEffect, useState } from 'react';
import PTInsuranceUserBlock from './components/PTInsuranceUserBlock';
import { AppointmentsNew, Step } from '../../../../../../types/AppointmentNewTypes';
import { debounce } from 'lodash';
import {
  updateFormikValues,
  updateFormikValuesWithKey,
} from '../../../../../../helpers/FormikFuncs/updateFormikValues';
import {
  useDigitalFormCreateMutation,
  useDigitalFormUpdateMutation,
} from '../../../../../../store/api/templates/templateApi';
import useUnsavedChangesWarning from '../../../../../../hooks/useShowDiscardChanges';
import useDirtyFormDialog from '../../../../../../hooks/useDirtyFormDialog';
import { useBlocker } from 'react-router-dom';

type Props = {
  formik: FormikProps<PagePTInsuranceType>;
  isFromStepper?: boolean;
  stepperValues?: Step;
  digitalFormId?: number;
  appointmentInformation?: AppointmentsNew;
  // ref: React.MutableRefObject<{}>;
};
const PhysicalTherapyInsurance = forwardRef<Record<string, unknown>, Props>(
  (
    { formik, isFromStepper, stepperValues, appointmentInformation, digitalFormId },
    ref,
  ) => {
    const [updateDigForm, updateDigFormStatus] = useDigitalFormUpdateMutation({});
    const deleteNames = (obj: any) => {
      delete obj?.firstName;
      delete obj?.lastName;
      delete obj?.userId;
      delete obj?.dateOfBirth;
      return obj;
    };
    const updateValues = () => {
      if (typeof ref !== 'function' && ref && ref?.current !== null) {
        const updatedFormikValues = updateFormikValues(
          { ...deleteNames(formik.values) },
          ref.current,
        );
        updateDigForm({
          id: digitalFormId,
          body: {
            ...updatedFormikValues,
          },
        });
      }
    };

    const [isDirty, setIsDirty] = useState(false);
    const markAsDirty = () => {
      setIsDirty(true);
    };
    const handleSubmit = () => {};

    const [DialogComponent, handleLeavePage] = useUnsavedChangesWarning({
      handleSubmit,
      isDirty,
      setIsDirty,
    });

    useEffect(() => {
      window.addEventListener('beforeunload', handleLeavePage);
      return () => {
        window.removeEventListener('beforeunload', handleLeavePage);
      };
    }, [handleLeavePage]);

    return (
      <>
        {DialogComponent()}
        <S.Wrapper>
          <S.Inputs>
            <PTInsuranceUserBlock
              setIsDirty={markAsDirty}
              formik={formik}
              ref={ref}
              appointmentInformation={appointmentInformation}
            />
            <PreviousPT ref={ref} formik={formik} setIsDirty={markAsDirty} />
            <Deductible ref={ref} formik={formik} setIsDirty={markAsDirty} />
            <CoInsurancePolicy ref={ref} formik={formik} setIsDirty={markAsDirty} />
          </S.Inputs>
        </S.Wrapper>
      </>
    );
  },
);
PhysicalTherapyInsurance.displayName = 'PhysicalTherapyInsurance';

export default PhysicalTherapyInsurance;
