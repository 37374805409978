import { Input, SelectInput } from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from './styles';

const Assesment = () => {
  return (
    <>
      <S.Article>Assesment</S.Article>
      <S.Article className="px13">Traditional Chinese Medicine Diagnosis</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Qi and Blood Stagnation" />
        <CheckBoxWithLabel label="Dampness Stagnation" />
        <CheckBoxWithLabel label="Post Traumatic “Pain” syndrome" />
        <CheckBoxWithLabel label="Qi and Blood Deficiency" />
        <CheckBoxWithLabel label="Dampness and Heat" />
        <S.Text>Other:</S.Text>
        <S.InputWrapper>
          <Input label=""></Input>
        </S.InputWrapper>
      </S.Line>
      <S.Article className="px13">Western Medical Diagnosis (ICD 10)</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="M54.2 Cervicalgia (Neck Pain)" />
        <CheckBoxWithLabel label="M54.30 Sciatica" />
        <CheckBoxWithLabel label="M54.5Lumbago (Lower back pain)" />
        <CheckBoxWithLabel label="M25.539 Wrist Pain" />
        <CheckBoxWithLabel label="M25.559 Hip Pain" />
        <CheckBoxWithLabel label="M25.569 Gonalgia (Knee Pain)" />
        <CheckBoxWithLabel label="M25.579 Ankle/Foot Pain" />
        <CheckBoxWithLabel label="G44.209 Tension Headache" />
        <CheckBoxWithLabel label="G43.909 Migraine" />
        <CheckBoxWithLabel label="R51 Headache" />
        <CheckBoxWithLabel label="G89.21 Chronic pain due to trauma" />
      </S.Line>
      <S.Line>
        <S.InputWrapper>
          <SelectInput label="Pain intensity"></SelectInput>
        </S.InputWrapper>
        <S.InputWrapper>
          <SelectInput label="ROM"></SelectInput>
        </S.InputWrapper>
        <S.InputWrapper>
          <SelectInput label="Frequency of pain"></SelectInput>
        </S.InputWrapper>
      </S.Line>
    </>
  );
};

export default Assesment;
