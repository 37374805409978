import React, { ReactNode } from 'react';
import * as S from './styles';

type Props = {
  children?: React.ReactNode;
};

const ScrollContainer = React.forwardRef<HTMLDivElement, Props>(({ children }, ref) => (
  <S.ScrollWrapper ref={ref}>{children}</S.ScrollWrapper>
));

export default ScrollContainer;
