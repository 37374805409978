import { Tab } from '@mui/material';
import * as S from './styles';
import { TabsItem } from '../../../../components';
import { useEffect, useState } from 'react';
import InvoiceSheet from '../InvoiceSheet';
import InvoicePrepayment from '../InvoicePrepayment';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import InvoicePostPayment from '../InvoicePostPayment/InvoicePostPayment';

const Invoices = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('sheet')) {
      setTabIndex(0);
    }
    if (location.pathname.includes('pre-payment')) {
      setTabIndex(1);
    }
    if (location.pathname.includes('post-payment')) {
      setTabIndex(2);
    }
  }, [location]);
  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Invoices</S.SubtitleText>
      </S.SubtitleBar>
      <S.TabWrapper>
        <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
          <Tab
            disableRipple
            label="Invoice sheet"
            onClick={() => {
              navigate('/invoices/sheet');
            }}
          ></Tab>
          <Tab
            disableRipple
            label="Pre-Payment Invoices"
            onClick={() => {
              navigate('/invoices/pre-payment');
            }}
          ></Tab>
          <Tab
            disableRipple
            label="Post-Payment Invoices"
            onClick={() => {
              navigate('/invoices/post-payment');
            }}
          ></Tab>
        </TabsItem>
      </S.TabWrapper>
      <S.Content>
        <Routes>
          <Route path="*" element={<Navigate to="sheet" />} />
          <Route path="sheet" element={<InvoiceSheet />}></Route>
          <Route path="pre-payment" element={<InvoicePrepayment />}></Route>
          <Route path="post-payment" element={<InvoicePostPayment />}></Route>
        </Routes>
      </S.Content>
    </S.Wrapper>
  );
};

export default Invoices;
