import styled from 'styled-components';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div`
  width: 502px;
  border-radius: 8px;

  background: #e7e8ec;

  @media ${devices.xs} {
    width: 320px;
  }
`;

export const Title = styled.div`
  width: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding: 10px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  gap: 5px;
  min-width: 48%;
  max-width: 48%;
`;

export const SVG = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const HelperText = styled.div`
  color: var(--icon, #dd8500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;
