import { PlaceHolderSuccessSVG } from '../../../../../../assets/icons';
import * as S from './styles';

type Props = {
  onClose: () => void;
  navigateHere: () => void;
};

const SuccessSavedTemplateDialog: React.FC<Props> = ({ onClose, navigateHere }) => {
  return (
    <S.Content>
      <PlaceHolderSuccessSVG />
      <S.HelperText>The Document was saved successfully!</S.HelperText>
      <S.HelperText>
        You can find it <S.Span onClick={navigateHere}>here</S.Span>
      </S.HelperText>
    </S.Content>
  );
};

export default SuccessSavedTemplateDialog;
