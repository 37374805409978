import {
  DiagnosisCodes,
  InsuranceInfoFormType,
  PatientInfoFormType,
} from 'types/AppointmentNewTypes';

export const InjuryQual = [
  {
    value: '431',
    name: '431 Onset of Current Symptoms or Illness',
  },
  {
    value: '484',
    name: '484 Last Menstrual Period',
  },
];

export const OtherQual = [
  {
    value: '454',
    name: '454 Initial Treatment',
  },
  {
    value: '304',
    name: '304 Latest Visit or Consultation',
  },
  {
    value: '453',
    name: '453 Acute Manifestation of a Chronic Condition',
  },
  {
    value: '439',
    name: '439 Accident',
  },
  {
    value: '455',
    name: '455 Last X-ray',
  },
  {
    value: '471',
    name: '471 Prescription',
  },
  {
    value: '090',
    name: '090 Report Start (Assumed Care Date)',
  },
  {
    value: '091',
    name: '091 Report End (Relinquished Care Date)',
  },
  {
    value: '444',
    name: '444 First Visit or Consultation',
  },
];

export const getAlphabetLetter = (index: number) => {
  return String.fromCharCode(65 + index);
};

export type ClaimFormValues = PatientInfoFormType &
  InsuranceInfoFormType & {
    typeICD: string;

    dateOfIllness: string;
    dateOfIllnessQual: string;
    otherDateQual: string;
    otherDate: string;
    unableToWorkStartDate: string;
    unableToWorkEndDate: string;
    reservedForNUCC: string;
    otherInsuredName: string;
    nameOfReferringProvider: string;
    hospitalizationStartDate: string;
    hospitalizationEndDate: string;
    diagnosisCodes: Array<DiagnosisCodes>;
  };
