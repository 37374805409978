import * as S from './styles';
import { MouseEvent, MouseEventHandler } from 'react';
import dayjs from 'dayjs';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import AppointmentCard from '../AppointmentsView/components/PlannerView/components/AppointmentCard';

type Props = {
  row: AppointmentsNew;
  onClick: MouseEventHandler<HTMLDivElement>;
  onTreatmentClick: (e: MouseEvent<HTMLDivElement>) => void;
  isUserView?: boolean;
};

const RecordItem: React.FC<Props> = ({ onClick, row, onTreatmentClick, isUserView }) => {
  return (
    <S.RecordItemWrapper background={'#fff'} border={'#848A9B'} onClick={e => onClick(e)}>
      <S.LeftBlock>
        <S.DateDiv>
          <S.NumberDay>{dayjs(row.date).format('MMM DD, YYYY')}</S.NumberDay>
          <S.WeekDay>{dayjs(row.date).format('dddd')}</S.WeekDay>
        </S.DateDiv>
        <S.Line />
        <S.RecordInformation>
          {row.visits.map(visit => {
            return (
              <AppointmentCard
                key={visit.id}
                appointment={row}
                visit={visit}
                isOnlyView
                isUserView
              />
            );
          })}
        </S.RecordInformation>
      </S.LeftBlock>
    </S.RecordItemWrapper>
  );
};

export default RecordItem;
