import React, { useEffect } from 'react';
import './App.css';
import { useAppSelector } from './hooks/useAppSelector';

import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer, toast } from 'react-toastify';
import StatusWrapper from './template/StatusWrapper';
import { useChat } from './hooks/useChat';
import { useNotificationsAllGetQuery } from './store/api/notifications/notificationsApi';
import { AvatarSVG } from './assets/icons';
import * as S from './AppStyles';

import logo from './assets/images/avatarmock.jpg';
import { NotificationRow } from './components';
import CloseButtonNotify from './components/atoms/CloseButtonNotify';
import DialogUnsavedChanges from './services/DialogUnsavedChanges';
function App() {
  useChat();

  const userInfo = useAppSelector(state => state.auth);
  const message = useAppSelector(state => state.message);
  const notification = useAppSelector(state => state.chats.newNotifications);

  useEffect(() => {
    if (!message) {
      return;
    } else {
      toast(message?.message, {
        type: message?.type,
      });
    }
  }, [message]);

  useEffect(() => {
    if (!notification) {
      return;
    } else {
      if (userInfo.isNotificationsEnabled) {
        toast(
          <NotificationRow
            src={logo}
            isApproved={false}
            isHideRead
            notificationItem={notification}
          />,
          {
            position: 'top-left',
            autoClose: 3000,
            closeButton: <CloseButtonNotify notification={notification} />,
          },
        );
      }
    }
  }, [notification]);

  return (
    <S.AppWrapper>
      <DialogUnsavedChanges />
      <StatusWrapper />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        pauseOnHover={false}
        theme="light"
      />
    </S.AppWrapper>
  );
}

export default App;
