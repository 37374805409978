import React, { useState } from 'react';
import { AddCircleBlueSVG, EditBlueBtnSVG } from 'assets/icons';
import { Dialog } from 'components';
import { UserProfile } from 'types/UserProfileTypes';
import AddInsuranceDialog from './components/AddInsuranceDialog';
import CommonInformationBlock from './components/CommonInformationBlock';
import InsurancesBlock from './components/InsurancesBlock';
import * as S from './styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { InsuranceInUserProfileType } from 'types/InsuranceType';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';

type Props = {
  rows: UserProfile | undefined;
};

const InsuranceInformationV2 = ({ rows }: Props) => {
  const [isOpenAddInsuranceDialog, setIsOpenAddInsuranceDialog] = useState(false);

  const [showExitModal, setShowExitModal] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const [editedRow, setEditedRow] = useState<InsuranceInUserProfileType | null>(null);

  const dispatch = useAppDispatch();
  return (
    <S.Wrapper>
      <Dialog
        open={isOpenAddInsuranceDialog}
        onClose={() => {
          if (isDirtyForm) {
            setShowExitModal(true);
          } else {
            setIsOpenAddInsuranceDialog(false);
            setEditedRow(null);
          }
        }}
      >
        <AddInsuranceDialog
          row={editedRow}
          userId={rows?.profile?.id}
          onClose={() => {
            setIsOpenAddInsuranceDialog(false);
            setEditedRow(null);
          }}
          setIsDirtyForm={setIsDirtyForm}
        />
      </Dialog>
      <S.Content>
        <S.Article>
          Insurance information
          <S.ShowBtnsLogs
            onClick={() =>
              dispatch(
                showDrawer({
                  show: true,
                  mode: DrawerMode.INSURANCE_LOGS,
                  props: rows?.id,
                }),
              )
            }
          >
            <EditBlueBtnSVG />
            Show Edit History
          </S.ShowBtnsLogs>
        </S.Article>
        <S.SubArticle>
          Please fill out this form in its entirety, we can not verify or accept insurance
          with any missing information.
        </S.SubArticle>
        <CommonInformationBlock rows={rows} />
        <InsurancesBlock
          rows={rows}
          setEditedRow={setEditedRow}
          setIsOpenAddInsuranceDialog={setIsOpenAddInsuranceDialog}
        />
        <S.ButtonAddInsurance
          onClick={() => setIsOpenAddInsuranceDialog(!isOpenAddInsuranceDialog)}
        >
          <AddCircleBlueSVG />
          Add Insurance
        </S.ButtonAddInsurance>
      </S.Content>

      <Dialog open={showExitModal} onClose={() => setShowExitModal(false)}>
        <ConfirmDeleteDialog
          onClose={() => {
            setShowExitModal(false);
          }}
          helperText={'All unsaved data will be lost'}
          fullTitle={'Are you sure you want to close this window? '}
          closeAfterSuccess={false}
          onSuccess={() => {
            setShowExitModal(false);
            setIsOpenAddInsuranceDialog(false);
            setEditedRow(null);
          }}
        />
      </Dialog>
    </S.Wrapper>
  );
};

export default InsuranceInformationV2;
