import { createSlice } from '@reduxjs/toolkit';
import { DrawerMode } from '../../types/DrawerMode';

type Drawer = {
  show: boolean;
  mode: DrawerMode;
  props: any;
};

const initialState: Drawer = {
  show: false,
  mode: DrawerMode.DEFAULT,
  props: null,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    showDrawer: (state, action) => {
      return {
        ...state,
        show: action.payload.show,
        mode: action.payload.mode,
        props: action.payload.props,
      };
    },
  },
});

const { reducer, actions } = drawerSlice;

export const { showDrawer } = actions;
export default reducer;
