import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type BadResponse<T> = {
  count: number;
  page: number;
  lastPage: number;
  searchResult: number;
  row: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const staffApi = api.injectEndpoints({
  endpoints: builder => ({
    doctorInsurances: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/staff/${id}/insurances`,
        params: { ...params },
      }),
      providesTags: ['StaffInsurances'],
    }),
    findAllDoctors: builder.mutation({
      query({ ...params }) {
        return {
          url: `/clinic/doctors`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    getAllDoctors: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/clinic/doctors`,
        params: { ...params },
      }),
    }),
    getAllReceptionists: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/clinic/receptionists`,
        params: { ...params },
      }),
    }),
    getAllLocalAdmins: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/clinic/local-admins`,
        params: { ...params },
      }),
    }),
    getAllRefProviders: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/referring-provider?take=20`,
        params: { ...params },
      }),
      providesTags: ['Staff'],
    }),
    getMutAllRefProviders: builder.mutation({
      query: ({ id, ...params }) => ({
        url: `/referring-provider`,
        params: { ...params },
      }),
      invalidatesTags: ['Staff'],
    }),
    createRefProvider: builder.mutation({
      query({ ...body }) {
        return {
          url: `/referring-provider`,
          method: 'POST',
          body: { ...body },
        };
      },
      invalidatesTags: ['Staff'],
    }),
    updateRefProvider: builder.mutation({
      query({ id, ...body }) {
        return {
          url: `/referring-provider/${id}`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: ['Staff'],
    }),
    updateDoctorSpeciality: builder.mutation({
      query({ ...body }) {
        return {
          url: `/clinic/update-doctor-speciality`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: ['Staff'],
    }),
    changeHealthCare: builder.query<BadResponse<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/changehealthcare/payers`,
        params: { ...params },
      }),
      providesTags: ['Payer'],
    }),
    changeHealthCareMutation: builder.mutation<BadResponse<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/changehealthcare/payers`,
        params: { ...params },
        method: 'GET',
      }),
      invalidatesTags: ['Payer'],
    }),
    assignInsuranceToDoctor: builder.mutation<BadResponse<any>, any>({
      query: ({ doctorId, ...body }) => ({
        url: `/staff/${doctorId}/assign-insurance`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['StaffInsurances'],
    }),
    removeInsuranceFromDoctor: builder.mutation<BadResponse<any>, any>({
      query: ({ id, ...body }) => ({
        url: `/staff/remove-insurance/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StaffInsurances'],
    }),
    updateInsuranceDoctor: builder.mutation<BadResponse<any>, any>({
      query: ({ id, doctorId, ...body }) => ({
        url: `/staff/${doctorId}/update-insurance/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['StaffInsurances'],
    }),
  }),
});

export const {
  useDoctorInsurancesQuery,
  useFindAllDoctorsMutation,
  useGetAllDoctorsQuery,
  useLazyGetAllDoctorsQuery,
  useGetAllLocalAdminsQuery,
  useGetAllReceptionistsQuery,
  useGetAllRefProvidersQuery,
  useCreateRefProviderMutation,
  useUpdateRefProviderMutation,
  useGetMutAllRefProvidersMutation,
  useChangeHealthCareQuery,
  useChangeHealthCareMutationMutation,
  useAssignInsuranceToDoctorMutation,
  useRemoveInsuranceFromDoctorMutation,
  useUpdateInsuranceDoctorMutation,
  useUpdateDoctorSpecialityMutation,
} = staffApi;
