import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import * as S from './styles';
import { SearchIconSVG } from '../../../assets/icons';

type Props = {
  placeholder?: string;
  isSearch?: boolean;
} & TextFieldProps;

const SearchInput: React.FC<Props> = ({ placeholder = 'Search', isSearch, ...props }) => {
  return (
    <S.SearchInputWrapper>
      <S.SearchInputItem
        $isSearch={isSearch}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton disableRipple={true} aria-label="toggle password visibility">
                <SearchIconSVG />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      ></S.SearchInputItem>
    </S.SearchInputWrapper>
  );
};

export default SearchInput;
