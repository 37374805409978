import { useParams } from 'react-router-dom';
import { EyeVisibleSVG } from 'assets/icons';
import { CustomPagination, CustomTable } from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useInvoiceSheetGetItemsByIdQuery } from 'store/api/invoices/invoiceApi';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { InvoicesMainType } from 'types/InvoiceType';
import InvInfRow from './TableData/InvInfRow';
import { useState } from 'react';

import * as S from './styles';

const ITEMS_LIMIT = 20;

const headers = [
  { name: 'Appointment (SIGS)', key: 'SIGS' },
  // { name: 'Date', key: 'Date' },
  { name: 'ICD', key: 'ICD' },
  { name: 'CPT', key: 'CPT' },
  { name: 'Rendering Provider', key: 'RenderingProvider' },
  { name: 'Service Loc', key: 'ServiceLoc' },
  { name: 'Billing Provider', key: 'BillingProvider' },
  { name: 'Billing Loc', key: 'BillingLoc' },
  { name: 'Charge amount', key: 'ChargeAmount' },
  { name: 'Notes', key: 'Notes' },
  { name: 'Status', key: 'Status' },
  { name: 'Claim', key: 'Claim' },
];

const InvInformationTable = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [page, setPage] = useState(1);

  const getList = useInvoiceSheetGetItemsByIdQuery({
    id: params.invoiceId,
    page,
    take: ITEMS_LIMIT,
  });

  return (
    <S.Wrapper>
      <S.Header>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'invoices'}
          limit={ITEMS_LIMIT}
        />
        <S.ButtonShow
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.SHOW_INVOICE_HISTORY,
                props: { id: params.invoiceId, type: InvoicesMainType.SHEET },
              }),
            )
          }
        >
          <EyeVisibleSVG />
          <S.Text>Show Edit History</S.Text>
        </S.ButtonShow>
      </S.Header>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <InvInfRow data={getList?.currentData?.rows} headers={headers} />
        </CustomTable>
      </S.TableWrapper>
    </S.Wrapper>
  );
};

export default InvInformationTable;
