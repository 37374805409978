import { ChangeEvent, useRef, useState } from 'react';
import { AvatarSVG, CloseSVG } from '../../../../../../assets/icons';
import { Button, DatePicker, Input, SelectInput } from '../../../../../../components';
import * as S from '../../styles';
import { InputAdornment, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { FormikErrors, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import AvatarZone from './AvatarZone';
import ReactInputMask from 'react-input-mask';

type Props = {
  formik: any;
};

const ProfileSignUp: React.FC<Props> = ({ formik }) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  return (
    <>
      <AvatarZone formik={formik} />
      <S.InputsDiv>
        <S.Article>Profile info</S.Article>
        <S.SubArticle>Please, fill out all mandatory fields to continue</S.SubArticle>
        <S.Inputs>
          <S.InputRow>
            <Input
              label="First name"
              isRequired
              background="#fff"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              error={!!formik.errors.firstName}
              helperText={formik.errors.firstName}
              onChange={handleChangeInput}
            />
            <S.InputSmall>
              <Input
                label="MI"
                background="#fff"
                id="mi"
                name="mi"
                value={formik.values.mi}
                error={!!formik.errors.mi}
                helperText={formik.errors.mi}
                onChange={handleChangeInput}
              />
            </S.InputSmall>
            <Input
              label="Last name"
              isRequired
              background="#fff"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              error={!!formik.errors.lastName}
              helperText={formik.errors.lastName}
              onChange={handleChangeInput}
            />
          </S.InputRow>
          <S.InputRow className="devide">
            <SelectInput
              label="Sex"
              background="#fff"
              isRequired
              id="sex"
              name="sex"
              value={formik.values.sex}
              error={!!formik.errors.sex}
              helperText={formik.errors.sex}
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.setFieldError('sex', '');
                formik.handleChange(e);
              }}
            >
              <MenuItem value={'male'}>
                <S.MenuItemContent>{'Male'}</S.MenuItemContent>
              </MenuItem>
              <MenuItem value={'female'}>
                <S.MenuItemContent> {'Female'}</S.MenuItemContent>
              </MenuItem>
              <MenuItem value={'other'}>
                <S.MenuItemContent> {'Other'}</S.MenuItemContent>
              </MenuItem>
            </SelectInput>
            <DatePicker
              label="DOB"
              isRequired
              disableFuture
              background="#ffff"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formik.values.dateOfBirth}
              error={!!formik.errors.dateOfBirth}
              helperText={formik.errors.dateOfBirth}
              onChange={value => {
                formik.setFieldError('dateOfBirth', '');
                formik.setFieldValue('dateOfBirth', value);
              }}
            />

            <ReactInputMask
              mask="9'99''"
              value={formik.values.height}
              onChange={handleChangeInput}
              disabled={false}
            >
              <Input
                label="Height"
                background="#fff"
                id="height"
                name="height"
                error={!!formik.errors.height}
                helperText={formik.errors.height}
              />
            </ReactInputMask>

            <Input
              label="Weight"
              type="number"
              background="#fff"
              id="weight"
              inputProps={{
                step: 0.01,
                min: 10,
                max: 1000,
              }}
              isSum={'lbs'}
              name="weight"
              value={formik.values.weight}
              error={!!formik.errors.weight}
              helperText={formik.errors.weight}
              onChange={handleChangeInput}
            />
          </S.InputRow>
        </S.Inputs>
      </S.InputsDiv>
    </>
  );
};

export default ProfileSignUp;
