import { useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDashMarketingGetQuery } from 'store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import { CircularLoader, DashboardCard, SelectInput, VerticalChart } from 'components';
import { getLastYears } from 'helpers/functions/getLastYears';
import { MenuItem, SelectChangeEvent } from '@mui/material';

export const DashMarketing = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const yearArr = getLastYears();

  const [filterYear, setFilterYear] = useState<number>(yearArr[0]);

  const getStat = useDashMarketingGetQuery({
    clinicId: selectedClinic.id,
    year: filterYear,
  });

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const data = {
    labels: getStat.currentData?.graph?.map((item: any) => item.month),
    datasets: [
      {
        label: 'Survey',
        backgroundColor: '#E17777',
        data: getStat.currentData?.graph?.map((item: any) => item.count?.totalServey),
        borderRadius: 7,
      },
      {
        label: 'Coupon',
        backgroundColor: '#0084B1',
        borderRadius: 10,
        data: getStat.currentData?.graph?.map((item: any) => item.count?.totalCoupon),
      },
    ],
  };

  return (
    <S.MainInformation>
      <S.MainContent>
        <S.TitleBox>
          <S.SubtitleText>Marketing</S.SubtitleText>
          <S.SubtitleItems>
            <SelectInput
              label="Year"
              value={filterYear}
              onChange={(e: SelectChangeEvent<unknown>) =>
                setFilterYear(e.target.value as number)
              }
            >
              {yearArr.map(year => (
                <MenuItem
                  key={year}
                  value={year}
                  disabled={getStat.isFetching || getStat.isLoading}
                >
                  <S.MenuItemContent>{year}</S.MenuItemContent>
                </MenuItem>
              ))}
            </SelectInput>
          </S.SubtitleItems>
        </S.TitleBox>

        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <VerticalChart stackedData={data} propsOptions={options} />
            )}
          </S.Chart>
          <S.Items>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Coupon"
                number={getStat.currentData?.totalCoupons}
                $isGray
              />
            </S.DashboardSmallWrapper>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Survey"
                number={getStat.currentData?.totalServeys}
                $isGray
              />
            </S.DashboardSmallWrapper>
          </S.Items>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInformation>
  );
};
