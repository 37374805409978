import { CircularProgress, CircularProgressProps } from '@mui/material';
import * as S from './styles';

type Props = {
  color?: string;
  size?: number;
};

const CircularLoader: React.FC<Props> = ({ color, size }) => {
  return (
    <S.Loader color={color}>
      <CircularProgress size={size} />
    </S.Loader>
  );
};

export default CircularLoader;
