import { Step } from '../../../types/AppointmentNewTypes';
import { DigitalTemplateTypeByName } from '../../../types/TemplateType';

export const configCompleteStep = (data?: Step) => {
  switch (data?.clinicTemplate?.template?.name) {
    case DigitalTemplateTypeByName.PDF_OFFICE_POL:
    case DigitalTemplateTypeByName.PDF_ACU_INFORMED:
    case DigitalTemplateTypeByName.PDF_INFORMED:
      // case DigitalTemplateTypeByName.CLIENT_REGISTRATION:
      return true;
    default:
      return false;
  }
};
