import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import { generateStylesConfig } from './generateStylesConfig';

type Props = {
  isPadding?: boolean;
  placeholder?: string;
  text: string;
};

const FieldArticle = ({ isPadding, placeholder, text }: Props) => {
  const styles = generateStylesConfig();
  return <Text style={styles.article}>{text}</Text>;
};

export default FieldArticle;
