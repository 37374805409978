import { Tab, Tabs } from '@mui/material';
import * as S from './styles';
import { useState } from 'react';

type TabsProps = {
  children?: React.ReactNode;
  tabIndex: number;
  setTabIndex: (index: number) => void;
};

const TabsItem: React.FC<TabsProps> = ({ children, tabIndex, setTabIndex }) => {
  return (
    <S.TabsWrapper>
      <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
        {children}
      </Tabs>
    </S.TabsWrapper>
  );
};

export default TabsItem;
