import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isActive: boolean }>`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  max-width: 100px;
  gap: 5px;
  height: 38px;
  cursor: pointer;
  ${props =>
    props.$isActive &&
    css`
      border-radius: 5px;
      background: var(--hover-1, rgba(0, 132, 177, 0.08));
    `}

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Text = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CountCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #c66060;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
