import { useFormik } from 'formik';
import * as S from './styles';
import * as yup from 'yup';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import {
  useDigitalFormCreateMutation,
  useDigitalFormSavePDFMutation,
} from '../../../../../../../store/api/templates/templateApi';
import { ClientRegistrationType } from './ClientRegistrationType';
import { chooseAppointmentProcessFormik } from '../../../../../../../components/organismus/AppointmentProcess/configFormik';
import {
  DigitalTemplateType,
  DigitalTemplateTypeByName,
} from '../../../../../../../types/TemplateType';
import { generateFileName } from '../../../../../../../helpers/generateFileName';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularLoader, Dialog } from '../../../../../../../components';
import SuccessSavedTemplateDialog from '../../SuccessSavedTemplateDialog';
import {
  removeEmptyFields,
  updateFormikValues,
} from '../../../../../../../helpers/FormikFuncs/updateFormikValues';
import GeneratePTInitial from '../../GenerateEmptyDigital/GeneratePTInitial';
import { pdf } from '@react-pdf/renderer';
import GenerateClientRegistrationForm from '../../GenerateEmptyDigital/GenerateClientRegistrationForm';
import ClientRegistration from '../../ClientRegistration';
import { ArrowLeftSVG } from '../../../../../../../assets/icons';

const PageClientRegistration = () => {
  const Schema = yup.object().shape({
    patientInfo: yup.object().shape({
      // patientName: yup.string().required('Is required'),
    }),
  });

  const [createDigitalForm, createDigFormStatus] = useDigitalFormCreateMutation({});
  const signature = useAppSelector(state => state.auth.signature);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [savePDF, savePDFStatus] = useDigitalFormSavePDFMutation({});

  const formik = useFormik<ClientRegistrationType>({
    initialValues: chooseAppointmentProcessFormik(
      DigitalTemplateTypeByName.CLIENT_REGISTRATION,
    ) as ClientRegistrationType,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Schema,
    onSubmit: () => {
      setIsOpenDialogSign(true);
      createDigitalForm({
        params: {
          clinicId: selectedClinic.id,
          patientId: userId,
          type: DigitalTemplateType.CLIENT_REGISTRATION,
        },
        ...formik.values,
      })
        .unwrap()
        .then(async res => {
          if (res) {
            const blob = await pdf(
              <GenerateClientRegistrationForm data={res} signature={signature} />,
            ).toBlob();
            const file = new File(
              [blob],
              `${generateFileName(
                DigitalTemplateTypeByName.CLIENT_REGISTRATION as string,
              )}.pdf`,
            );
            savePDF({
              id: res.id,
              pdf: file,
              signature: null,
            });
          }
        });
    },
  });

  const [isOpenDialogSign, setIsOpenDialogSign] = useState(false);
  const ref = useRef<Record<string, unknown>>({});

  const navigate = useNavigate();

  const deleteNames = (obj: any) => {
    delete obj?.firstName;
    delete obj?.lastName;
    delete obj?.userId;
    delete obj?.dateOfBirth;
    return obj;
  };

  const [userId, setUserId] = useState<string | undefined>('');

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <S.Wrapper>
          <Dialog
            open={isOpenDialogSign}
            onClose={() => {
              navigate(`/templates`);
              setIsOpenDialogSign(false);
            }}
          >
            {(createDigFormStatus.isLoading || savePDFStatus.isLoading) &&
            !createDigFormStatus.data &&
            !savePDFStatus.data &&
            !savePDFStatus.isSuccess &&
            !createDigFormStatus.isSuccess ? (
              <S.Loader>
                <CircularLoader size={24} color="#0084B1" />
              </S.Loader>
            ) : (
              <>
                <SuccessSavedTemplateDialog
                  onClose={() => {
                    setIsOpenDialogSign(false);
                  }}
                  navigateHere={() =>
                    navigate(`/patients/${userId}`, {
                      state: { templateDocuments: true },
                    })
                  }
                />
              </>
            )}
          </Dialog>
          <S.SubtitleBar>
            <S.SubtitleText>
              <ArrowLeftSVG onClick={() => navigate('/templates')} />
              Client registration form
            </S.SubtitleText>
            <S.SubtitleItems></S.SubtitleItems>
          </S.SubtitleBar>
          <S.Content>
            <ClientRegistration formik={formik} ref={ref} />
          </S.Content>
        </S.Wrapper>
        <S.Footer>
          <S.FooterItems>
            <S.ButtonWrapper>
              <Button
                text="Signature"
                onClick={() => {
                  setUserId(formik.values.userId);
                  const updatedFormikValues = updateFormikValues(
                    { ...deleteNames(formik.values) },
                    ref.current,
                  );
                  formik
                    .setValues({
                      ...updatedFormikValues,
                    })
                    .then(() => {
                      formik.handleSubmit();
                    });
                }}
              />
            </S.ButtonWrapper>
          </S.FooterItems>
        </S.Footer>
      </form>
    </>
  );
};

export default PageClientRegistration;
