import { useEffect, useState } from 'react';
import { DeleteSVG, DocumentSVG, JPGSVG, PDFSVG } from '../../../../../../assets/icons';
import * as S from '../../styles';
import { useDropzone } from 'react-dropzone';

type Props = {
  formik: any;
};

const DocumentsSignUp: React.FC<Props> = ({ formik }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/JPEG': [`.jpeg`, `.png`, `.jpg`],
    },
  });

  useEffect(() => {
    formik.setFieldValue('documents', [...formik.values.documents, ...acceptedFiles]);
  }, [acceptedFiles]);

  const handleDeleteFile = (index: number) => {
    const newArr = [...formik.values.documents];
    newArr.splice(index, 1);
    formik.setFieldValue('documents', newArr);
  };

  return (
    <>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.DropzoneWrapper>
            <S.DropzoneContainer {...getRootProps({})}>
              <input {...getInputProps()} />
              <S.TextDropzone>
                <DocumentSVG />
                <S.UpperText>Drop your document here, or select</S.UpperText>
                <S.UpperText>
                  Available formats JPG, JPEG, PNG, PDF, max size 50MB
                </S.UpperText>
                <S.DownText> click to brows</S.DownText>
              </S.TextDropzone>
            </S.DropzoneContainer>
            <S.AsideFiles>
              {formik.values.documents &&
                formik.values.documents.map((file: File, index: number) => {
                  return (
                    <S.FileRow key={file.name + index}>
                      <S.InfoRow>
                        {file.name.includes('.pdf') ? (
                          <PDFSVG className="icon" />
                        ) : (
                          <JPGSVG className="icon" />
                        )}
                        {file.name}
                      </S.InfoRow>
                      <DeleteSVG
                        className="delete"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </S.FileRow>
                  );
                })}
            </S.AsideFiles>
          </S.DropzoneWrapper>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default DocumentsSignUp;
