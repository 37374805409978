import styled from 'styled-components';

export const WrapperIcon = styled.div`
  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const AppWrapper = styled.div`
  & .PrivateSwipeArea-root {
    display: none;
  }

  & .Toastify__toast-theme--light {
    display: flex;
    flex-direction: column-reverse;
  }

  & .Toastify__close-button--light {
    align-self: end;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast-icon {
    margin-left: 10px;
  }
`;
