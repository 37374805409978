import * as S from './styles';
import logo from '../../../assets/images/logo.png';
import newyear from '../../../assets/images/newyear.gif';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setIsHover } from '../../../store/slices/hoverMenuSlice';

type Props = {
  children?: React.ReactNode;
};

const VerticalMenu: React.FC<Props> = ({ children }) => {
  const isFebr = dayjs().month();

  const dispatch = useAppDispatch();

  const { isHover } = useAppSelector(state => state.isHover);

  return (
    <S.VerticalMenuRoot
      $isHover={isHover}
      onMouseOver={() =>
        !isHover &&
        dispatch(
          setIsHover({
            isHover: true,
          }),
        )
      }
      onMouseLeave={() =>
        isHover &&
        dispatch(
          setIsHover({
            isHover: false,
          }),
        )
      }
    >
      <S.VerticalMenuContent $isNewYear={false}>
        {/* {isHover && ( */}
        <S.ImgWrapper $isHover={isHover}>
          {/* {isFebr === 3 && (
            <img src={newyear} alt="newyear" className="newyear" />
          )} */}
          <img src={logo} alt="logotype" className="logotype"></img>
        </S.ImgWrapper>
        {/* )} */}
        <S.VerticalMenuItems $isNewYear={false}>{children}</S.VerticalMenuItems>
      </S.VerticalMenuContent>
    </S.VerticalMenuRoot>
  );
};

export default VerticalMenu;
