import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: end;
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CloseBtn = styled.div``;

export const MarkRead = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 5px;

  &:hover {
    color: #0084b1;

    svg {
      path {
        stroke: #0084b1;
      }
      circle {
        stroke: #0084b1;
      }
    }
  }
`;
