import { InsuranceWithGroupsType } from 'types/InsuranceType';
import * as S from './styles';
import { DeleteRedBtnSVG } from 'assets/icons';
import { ClinicInsuranceType } from 'types/enums/AppointmentEnum';
import { transformNoteTextMore } from 'helpers/functions/transformNoteText';

type Props = {
  row: InsuranceWithGroupsType;
  header: string;
  setShowConfirmDialog: React.Dispatch<React.SetStateAction<string | null>>;
};

export const InsuranceTableCell = ({ row, header, setShowConfirmDialog }: Props) => {
  switch (header) {
    case 'insuranceName':
      return <S.CellRowWrapper>{row?.insuranceName}</S.CellRowWrapper>;
    case 'groupNumber':
      return (
        <S.CellRowWrapper>
          {row?.insuranceGroupNumbers?.length
            ? row?.insuranceGroupNumbers?.map(group => group?.groupNumber)?.join(', ')
            : '-'}
        </S.CellRowWrapper>
      );
    case 'coveredServices':
      return (
        <S.CellRowWrapper>
          {row?.insuranceGroupNumbers?.length
            ? row?.insuranceGroupNumbers
                ?.filter(i => i.coveredServices?.length)
                ?.map(group =>
                  group?.coveredServices
                    ?.map(
                      i =>
                        `${i.typeServiceName ?? 'Unknown'}(${i.type ? (i.type === ClinicInsuranceType.IN_NETWORK ? 'In network' : 'Out of network') : '-'})`,
                    )
                    .join(', '),
                )
                .join(', ')
            : '-'}
        </S.CellRowWrapper>
      );
    case 'note':
      return (
        <S.CellRowWrapper>
          {row?.insuranceGroupNumbers?.length
            ? row?.insuranceGroupNumbers
                ?.filter(i => !!i.note)
                ?.map(
                  group =>
                    `${group?.groupNumber} - ${transformNoteTextMore(group?.note)}`,
                )
                .join(', ')
            : '-'}
        </S.CellRowWrapper>
      );
    case 'buttons': {
      return (
        <S.Btns>
          {/*<Edit24pxSVG onClick={() => setIsShowEditDialog(true)} />*/}

          <DeleteRedBtnSVG
            onClick={e => {
              e.stopPropagation();
              setShowConfirmDialog(row.id);
            }}
          />

          {/*<Dialog open={isShowEditDialog} onClose={() => setIsShowEditDialog(false)}>
            edit insurance
            <CreateTemplateDialog onClose={() => setIsShowEditDialog(false)} data={row} />
          </Dialog>*/}
        </S.Btns>
      );
    }
    default:
      return null;
  }
};
