import { useDispatch } from 'react-redux';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';
import { AppointmentsForms, InitialDocumentsFolders } from '../../../types/TemplateType';
import { InsuranceLogs } from '../../../types/insuranceTypes';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type BadResponse<T> = {
  count: number;
  page: number;
  lastPage: number;
  row: Array<T>;
};

export const healthrecordApi = api.injectEndpoints({
  endpoints: builder => ({
    healthrecordAdd: builder.mutation({
      query(body) {
        return {
          url: `/health-record`,
          method: 'POST',
          body: { ...body },
        };
      },
      invalidatesTags: ['HealthRecords', 'Events'],
    }),
    healthrecordUpdate: builder.mutation({
      query({ id, ...body }) {
        return {
          url: `/health-record/${id}`,
          method: 'PUT',
          body: { ...body },
        };
      },
      invalidatesTags: ['HealthRecords', 'Events'],
    }),
    healthrecordGetAllDoctor: builder.query<BadResponse<AppointmentsForms>, any>({
      query: params => ({
        url: `/appointment-user`,
        params: { ...params },
      }),
      providesTags: ['HealthRecords', 'Templates', 'User'],
    }),
    healthrecordGetOneDoctor: builder.mutation<InitialDocumentsFolders[], any>({
      query: ({ doctorId, appointmentId, ...data }) => ({
        url: `/appointment-user/${appointmentId}/get-appointment/${doctorId}`,
        method: 'GET',
        params: { ...data },
      }),
    }),
    getInsuranceEditHistory: builder.query<Response<InsuranceLogs>, any>({
      query: ({ id, ...params }) => ({
        url: `/insurance-edit-history`,
        params: { ...params },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }

        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['HealthRecords', 'Templates', 'User'],
    }),
  }),
});

export const {
  useHealthrecordAddMutation,
  useHealthrecordGetOneDoctorMutation,
  useHealthrecordUpdateMutation,
  useHealthrecordGetAllDoctorQuery,
  useGetInsuranceEditHistoryQuery,
} = healthrecordApi;
