import { useState } from 'react';
import { AddClinicIconSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, SearchInput } from 'components';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAllClinicsGetQuery } from 'store/api/clinics/clinicApi';
import ClinicsRow from './TableData/ClinicsRow';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { useDispatch } from 'react-redux';

const headers = [
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Status', key: 'Status' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Address', key: 'Address' },
  { name: 'Local Admin', key: 'LocalAdmin' },
  { name: 'Chat link', key: 'Chatlink' },
];

const CLINICS_LIMIT = 20;

const Clinics = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const listResult = useAllClinicsGetQuery({
    q: debouncedSearch,
  });
  const dispatch = useDispatch();

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>All Clinics</S.SubtitleText>
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              text="Add Clinic"
              onClick={() => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.ADD_CLINIC,
                    props: null,
                  }),
                );
              }}
            >
              <AddClinicIconSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <S.Content>
          <S.HeaderTable>
            <CustomPagination
              isFetching={listResult.isFetching}
              setPage={setPage}
              currentPage={listResult.currentData?.page}
              lastPage={listResult.currentData?.lastPage}
              count={listResult?.currentData?.count}
              text={'clinics'}
              limit={CLINICS_LIMIT}
            />
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.HeaderTable>
          <S.TableBody>
            <CustomTable headers={headers} isFetching={listResult.isFetching}>
              <ClinicsRow
                clickable
                data={listResult?.currentData?.rows}
                headers={headers}
              />
            </CustomTable>
          </S.TableBody>
        </S.Content>
      </S.Content>
    </S.Wrapper>
  );
};

export default Clinics;
