import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  PDFViewer,
  pdf,
  Image,
} from '@react-pdf/renderer';
import FieldValue from '../DocElements/FieldValue';
import FieldWithCheckbox from '../DocElements/FieldWithCheckbox';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { PagePTInsuranceType } from '../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { generateStylesConfig } from '../DocElements/generateStylesConfig';
import { Signature } from '../../../../../../../store/api/auth/authSlice';
import { PageACAInsuranceType } from '../../pages/PageACAInsurance/PageACAInsuranceType';

type Props = {
  data?: any;
  signature?: Signature | null;
  setDocument?: (doc: any) => void;
};

const GenerateACAInsurance: React.FC<Props> = ({ data, setDocument, signature }) => {
  const styles = generateStylesConfig();
  const GenerateACAInsuranceContent = () => {
    const payload: PageACAInsuranceType | undefined = data?.payload;

    return (
      <Document style={styles.viewer}>
        <Page size="A4" style={styles.page}>
          <FieldValue
            article="Date"
            text={payload ? dayjs().format('MM/DD/YYYY') : ''}
            styles={styles}
          />
          <View style={styles.section}>
            <FieldValue
              article="Patient name"
              text={payload?.patientName ?? ''}
              styles={styles}
              isPadding
            />
          </View>
          <View style={styles.section}>
            <FieldValue
              article="Patient birth date"
              text={payload?.birthDate ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue article="Age" text={payload?.age ?? ''} styles={styles} />
            <FieldValue article="Sex" text={payload?.gender ?? ''} styles={styles} />
          </View>
          <View style={styles.section}>
            <FieldValue
              article="Insurance Co"
              text={payload?.insuranceCo ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Plan/Group#"
              text={payload?.planGroup ?? ''}
              styles={styles}
              isPadding
            />
          </View>
          <View style={styles.section}>
            <FieldValue
              article="Member I.D. #"
              text={payload?.memberId ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Patient Phone number"
              text={payload?.patientPhone ?? ''}
              styles={styles}
              isPadding
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>1. Do you cover Acupuncture</Text>
            <FieldWithCheckbox
              article="Yes"
              styles={styles}
              isChecked={payload && payload.coverAcupunture}
            />
            <FieldWithCheckbox
              article="No (End call)"
              styles={styles}
              isChecked={payload && !payload.coverAcupunture}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              Does the patient have out of network coverage?
            </Text>
            <FieldWithCheckbox
              article="Yes"
              styles={styles}
              isChecked={payload && payload.coverNetworkAcupunture}
            />
            <FieldWithCheckbox
              article="No"
              styles={styles}
              isChecked={payload && !payload.coverNetworkAcupunture}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              2. How many treatments/visits does it cover per calendar year?
            </Text>
            <FieldValue
              article=""
              text={payload?.countTreatmensPerCalendarYear ?? ''}
              styles={styles}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              How many treatments/visits have been used this year?
            </Text>
            <FieldValue
              article=""
              text={payload?.countTreatmensUsedThisYear ?? ''}
              styles={styles}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>3. What is the deductible?</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.article}>In-Net</Text>
            <FieldValue
              article="Met"
              text={payload?.deductible.inNet.met ?? ''}
              placeholder="$"
              styles={styles}
            />
            <FieldValue
              article="Total"
              text={payload?.deductible.inNet.total ?? ''}
              placeholder="$"
              styles={styles}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Out-Net</Text>
            <FieldValue
              article="Met"
              text={payload?.deductible.outNet.met ?? ''}
              placeholder="$"
              styles={styles}
            />
            <FieldValue
              article="Total"
              text={payload?.deductible.outNet.total ?? ''}
              placeholder="$"
              styles={styles}
            />
          </View>
          <Text style={styles.text}>What is out of pocket limit?</Text>
          <View style={styles.section}>
            <FieldValue
              article="In"
              text={payload?.pocketLimit.inStart ?? ''}
              placeholder="$"
              styles={styles}
            />
            <Text style={styles.text}>-</Text>
            <FieldValue
              article=""
              text={payload?.pocketLimit.inStart ?? ''}
              placeholder="$"
              styles={styles}
            />
            <FieldValue
              article="Out"
              text={payload?.pocketLimit.outStart ?? ''}
              placeholder="$"
              styles={styles}
            />
            <Text style={styles.text}>-</Text>
            <FieldValue
              article=""
              text={payload?.pocketLimit.outEnd ?? ''}
              placeholder="$"
              styles={styles}
            />
          </View>
          <Text style={styles.text}>4. What is the co/insurance on policy?</Text>
          <View style={styles.section}>
            <FieldValue
              article="In-Network"
              text={payload?.insuranceCoOnPolicy.inNetwork ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Out-Network"
              text={payload?.insuranceCoOnPolicy.outNetwork ?? ''}
              styles={styles}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Does the patient have a copay?</Text>
            <FieldValue
              article="In"
              text={payload?.copay.in ?? ''}
              placeholder="$"
              styles={styles}
            />
            <FieldValue
              article="Out"
              text={payload?.copay.out ?? ''}
              styles={styles}
              placeholder="$"
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              5. Is a primary physician’s referral required?
            </Text>
            <FieldWithCheckbox
              article="Yes"
              styles={styles}
              isChecked={payload && payload.isReferralRequired}
            />
            <FieldWithCheckbox
              article="No"
              styles={styles}
              isChecked={payload && !payload.isReferralRequired}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>6. Is prior authorization required?</Text>
            <FieldWithCheckbox
              article="Yes"
              styles={styles}
              isChecked={payload && payload.isPriorAuthRequired}
            />
            <FieldWithCheckbox
              article="No"
              styles={styles}
              isChecked={payload && !payload.isPriorAuthRequired}
            />
          </View>
          <Text style={styles.text}>7. Are these procedure codes covered?</Text>
          <View style={styles.section}>
            <FieldWithCheckbox
              article="97813"
              styles={styles}
              isChecked={payload && payload.procCodeCovered[97813]}
            />
            <FieldWithCheckbox
              article="97814"
              isChecked={payload && payload.procCodeCovered[97814]}
              styles={styles}
            />
            <FieldWithCheckbox
              article="97810"
              styles={styles}
              isChecked={payload && payload.procCodeCovered[97810]}
            />
            <FieldWithCheckbox
              article="97811"
              styles={styles}
              isChecked={payload && payload.procCodeCovered[97811]}
            />
          </View>
          <View style={styles.section}>
            <FieldValue
              article="Notes"
              text={payload?.notes ?? ''}
              styles={styles}
              isPadding
            />
          </View>
          <View style={styles.section}>
            <FieldValue
              article="Associate’s Name"
              text={payload?.associatesName ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              text={payload?.reference ?? ''}
              article="Reference #"
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Time"
              text={payload ? dayjs().format('hh:mm A') : ''}
              styles={styles}
            />
          </View>
          {/* {signature && payload && (
            <View
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Image
                style={{
                  marginRight: 50,
                  marginTop: 10,
                  height: 25,
                  width: 100,
                }}
                // src={userInfo.signature.url}
                src={
                  "https://corsproxy.io/?" + encodeURIComponent(signature.url)
                }
              />
            </View>
          )} */}
        </Page>
      </Document>
    );
  };

  return data ? (
    <GenerateACAInsuranceContent />
  ) : (
    <PDFViewer style={styles.viewer}>
      <GenerateACAInsuranceContent />
    </PDFViewer>
  );
};
export default GenerateACAInsurance;
