import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Font,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import FieldValue from '../DocElements/FieldValue';
import FieldWithCheckbox from '../DocElements/FieldWithCheckbox';
import { generateStylesConfig } from '../DocElements/generateStylesConfig';
import FieldSection from '../DocElements/FieldSection';
import FieldArticle from '../DocElements/Article';
import { Signature } from '../../../../../../../store/api/auth/authSlice';
import { InitialVisitFormType } from '../../pages/InitialVisitFormPage/InitialVisitiFormType';

type Props = {
  data?: any;
  signature?: Signature | null;
  setDocument?: (doc: any) => void;
};

const GenerateInitialVisitForm: React.FC<Props> = ({ data, setDocument, signature }) => {
  const styles = generateStylesConfig();

  const GenerateInitialVisitFormContent = () => {
    const payload: InitialVisitFormType | undefined = data?.payload;

    return (
      <Document style={styles.viewer}>
        <Page size="A4" style={styles.page}>
          <FieldSection>
            <FieldValue
              article="Account #"
              text={payload?.accountNumber ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Patient name"
              text={payload?.patientName ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Patient's Birth Date"
              text={payload?.birthDate ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Sex"
              text={payload?.gender ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Symptoms" />
          <FieldSection>
            <FieldValue
              article="Major complaint"
              text={payload?.symptoms.majorComplaint ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>When did fell worse?</Text>
          <FieldSection>
            <FieldWithCheckbox
              article="Morning"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isMorning}
            />
            <FieldWithCheckbox
              article="Daytime"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isDaytime}
            />
            <FieldWithCheckbox
              article="Evening"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isEvening}
            />
            <FieldWithCheckbox
              article="Night"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isNight}
            />
            <FieldWithCheckbox
              article="Rain"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isRain}
            />
            <FieldWithCheckbox
              article="Cold or warm weather"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isColdOrWarmWeather}
            />
            <FieldWithCheckbox
              article="Up stair"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isUpStair}
            />
            <FieldWithCheckbox
              article="Down stair"
              styles={styles}
              isChecked={payload && payload.symptoms.feelWorse.isDownStair}
            />
          </FieldSection>
          <Text style={styles.articleText}>Have you even try:</Text>
          <FieldSection>
            <FieldWithCheckbox
              article="Acupuncture"
              styles={styles}
              isChecked={payload && payload.symptoms.haveYouEvenTry.isAcupuncture}
            />
            <Text style={styles.text}>when</Text>
            <FieldValue
              article=""
              text={payload?.symptoms.haveYouEvenTry.whenAcupuncture ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldWithCheckbox
              article="Chinese Medicine Herb"
              styles={styles}
              isChecked={payload && payload.symptoms.haveYouEvenTry.isChineseMedicineHerb}
            />
            <Text style={styles.text}>when</Text>
            <FieldValue
              article=""
              text={payload?.symptoms.haveYouEvenTry.whenChineseMedicineHerb ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>List all yout conditions</Text>
          <FieldValue
            article="Condition 1"
            text={payload?.symptoms.listOfConditions.conditions[0]?.name ?? ''}
            styles={styles}
            isPadding
          />
          <FieldValue
            article="Condition 2"
            text={payload?.symptoms.listOfConditions.conditions[1]?.name ?? ''}
            styles={styles}
            isPadding
          />
          <FieldValue
            article="Condition 3"
            text={payload?.symptoms.listOfConditions.conditions[2]?.name ?? ''}
            styles={styles}
            isPadding
          />
          <FieldSection>
            <FieldWithCheckbox
              article="Hot or Cold"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isHotOrCold
              }
            />
            <FieldWithCheckbox
              article="Spontaneously"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isSpontaneously
              }
            />
            <FieldWithCheckbox
              article="Night Sweating"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isNightSweating
              }
            />
            <FieldWithCheckbox article="Headeche" styles={styles} isChecked={false} />
            <FieldWithCheckbox
              article="Dizziness"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isDizziness
              }
            />
            <FieldWithCheckbox
              article="Cold or warm weather"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isColdOrWarmWeather
              }
            />
            <FieldWithCheckbox
              article="Up stair"
              styles={styles}
              isChecked={
                payload && payload.symptoms.listOfConditions.whenFeelConditions.isUpStair
              }
            />
            <FieldWithCheckbox
              article="Down stair"
              styles={styles}
              isChecked={
                payload &&
                payload.symptoms.listOfConditions.whenFeelConditions.isDownStair
              }
            />
          </FieldSection>
          <Text style={styles.articleText}>Body</Text>
          <FieldSection>
            <Text style={styles.text}>Common information:</Text>
            <FieldWithCheckbox article="Weak" styles={styles} isChecked={false} />
            <FieldWithCheckbox article="Numbness" styles={styles} isChecked={false} />
            <FieldWithCheckbox article="Drink More" styles={styles} isChecked={false} />
            <FieldWithCheckbox article="Drink Less" styles={styles} isChecked={false} />
            <FieldWithCheckbox
              article="Prefer Hot Drink"
              styles={styles}
              isChecked={false}
            />
            <FieldWithCheckbox
              article="Prefer Cold Drink"
              styles={styles}
              isChecked={false}
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Appetite"
              text={payload?.symptoms.body.appetite ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Food"
              text={payload?.symptoms.body.food ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Do you have:</Text>
            <FieldWithCheckbox
              article="Chest Pain"
              styles={styles}
              isChecked={payload && payload.symptoms.body.doYouHave.isChestPain}
            />
            <FieldWithCheckbox
              article="Press"
              styles={styles}
              isChecked={payload && payload.symptoms.body.doYouHave.isPress}
            />
            <FieldWithCheckbox
              article="Palpitation"
              styles={styles}
              isChecked={payload && payload.symptoms.body.doYouHave.isPalpitation}
            />
          </FieldSection>
          <Text style={styles.articleText}>Sleep</Text>
          <FieldSection>
            <FieldValue
              article="How many hours per day"
              text={payload?.symptoms.body.sleep.hours ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Sleep quality"
              text={payload?.symptoms.body.sleep.quality ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>Urine</Text>
          <FieldSection>
            <FieldValue
              article="How many times per day"
              text={payload?.symptoms.body.urine.times ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Urine"
              text={payload?.symptoms.body.urine.quality ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>Stool</Text>
          <FieldSection>
            <FieldValue
              article="Once in?"
              text={payload?.symptoms.body.stool.days ?? ''}
              styles={styles}
              isPadding
            />
            <Text style={styles.grayText}>days</Text>
          </FieldSection>
          <FieldSection>
            <FieldWithCheckbox
              article="Do you have constipation?"
              styles={styles}
              isChecked={payload && payload.symptoms.body.stool.isHaveConstipation}
            />
            <FieldWithCheckbox
              article="Do you have loose?"
              styles={styles}
              isChecked={payload && payload.symptoms.body.stool.isHaveLoose}
            />
          </FieldSection>
          <Text style={styles.articleText}>Menstruation</Text>
          <FieldSection>
            <FieldValue
              article="Start age"
              text={payload?.symptoms.body.menstruation.startAge ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Cycle days"
              text={payload?.symptoms.body.menstruation.cycleDays ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Last days"
              text={payload?.symptoms.body.menstruation.lastDays ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Amount"
              text={payload?.symptoms.body.menstruation.amount ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Color"
              text={payload?.symptoms.body.menstruation.color ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Clots"
              text={payload?.symptoms.body.menstruation.clots ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldWithCheckbox
              article="Do you have cramp pain?"
              styles={styles}
              isChecked={payload && payload.symptoms.body.menstruation.isCrampPain}
            />
            <FieldWithCheckbox
              article="Are you pregnant?"
              styles={styles}
              isChecked={payload && payload.symptoms.body.menstruation.isPregnant}
            />
            <FieldValue
              article="Date of last period"
              text={payload?.symptoms.body.menstruation.dateOfLastPeriod ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Personal Life style" />
          <FieldSection>
            <FieldWithCheckbox
              article="Do you smoke?"
              styles={styles}
              isChecked={payload && payload.personal.isSmoke}
            />
            <FieldWithCheckbox
              article="Do you drink alcohol?"
              styles={styles}
              isChecked={payload && payload.personal.isDrinkAlcohol}
            />
          </FieldSection>
          <FieldArticle text="Medical history" />
          <FieldSection>
            <FieldWithCheckbox
              article="Hypertension"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isHypertension}
            />
            <FieldWithCheckbox
              article="Diabetes"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isDiabetes}
            />
            <FieldWithCheckbox
              article="Heart disease"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isHeartDisease}
            />
            <FieldWithCheckbox
              article="Pulmponary disease"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isPulmonaryDisease}
            />
            <FieldWithCheckbox
              article="Cancer"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isCancer}
            />
            <FieldWithCheckbox
              article="Allergies"
              styles={styles}
              isChecked={payload && payload.medicalHistory.isAllergies}
            />
            <FieldValue
              article="Other"
              text={payload?.medicalHistory.other ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>Medicine and dosage</Text>
          <FieldSection>
            <FieldValue
              article="Medicine 1"
              text={payload?.medicalHistory.medicineAndDosage.medicine[0]?.name ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Dosage"
              text={payload?.medicalHistory.medicineAndDosage.medicine[0]?.dosage ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Medicine 2"
              text={payload?.medicalHistory.medicineAndDosage.medicine[1]?.name ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Dosage"
              text={payload?.medicalHistory.medicineAndDosage.medicine[1]?.dosage ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Medicine 3"
              text={payload?.medicalHistory.medicineAndDosage.medicine[2]?.name ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Dosage"
              text={payload?.medicalHistory.medicineAndDosage.medicine[2]?.dosage ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldWithCheckbox
              article="Have you had any surgeries?"
              styles={styles}
              isChecked={
                payload && payload.medicalHistory.medicineAndDosage.isAnySurgeries
              }
            />
            <FieldValue
              article="What kind?"
              text={payload?.medicalHistory.medicineAndDosage.kindOfSurgeries ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldWithCheckbox
              article="Have you had any accidents?"
              styles={styles}
              isChecked={
                payload && payload.medicalHistory.medicineAndDosage.isAnyAccidents
              }
            />
            <FieldValue
              article="What kind?"
              text={payload?.medicalHistory.medicineAndDosage.kindOfAccidents ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Family history"></FieldArticle>
          <FieldSection>
            <FieldWithCheckbox
              article="Diabetes"
              styles={styles}
              isChecked={payload && payload.familyHistory.isDiabetes}
            />
            <FieldWithCheckbox
              article="Heart Disease"
              styles={styles}
              isChecked={payload && payload.familyHistory.isHeartDisease}
            />
            <FieldWithCheckbox
              article="High blood Pressure"
              styles={styles}
              isChecked={payload && payload.familyHistory.isHighBloodPressure}
            />
            <FieldWithCheckbox
              article="Cancer"
              styles={styles}
              isChecked={payload && payload.familyHistory.isCancer}
            />
            <FieldValue
              article="Other"
              text={payload?.familyHistory.other ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Observation"></FieldArticle>
          <Text style={styles.articleText}>Pulse</Text>
          <FieldSection>
            <Text style={styles.text}>Rhythm:</Text>
            <FieldWithCheckbox
              article="Regular"
              styles={styles}
              isChecked={payload && payload.observation.pulse.rhythm.isRegular}
            />
            <FieldWithCheckbox
              article="Irregular"
              styles={styles}
              isChecked={payload && payload.observation.pulse.rhythm.isIrregular}
            />
            <FieldWithCheckbox
              article="Rapid"
              styles={styles}
              isChecked={payload && payload.observation.pulse.rhythm.isRapid}
            />
            <FieldWithCheckbox
              article="Slow"
              styles={styles}
              isChecked={payload && payload.observation.pulse.rhythm.isSlow}
            />
            <FieldWithCheckbox
              article="Intermittent"
              styles={styles}
              isChecked={payload && payload.observation.pulse.rhythm.isIntermittent}
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Quality:</Text>
            <FieldWithCheckbox
              article="Floating"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isFloating}
            />
            <FieldWithCheckbox
              article="Weak"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isWeak}
            />
            <FieldWithCheckbox
              article="Deep"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isDeep}
            />
            <FieldWithCheckbox
              article="Tight Slipper"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isTightSlipper}
            />
            <FieldWithCheckbox
              article="Empty"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isEmpty}
            />
            <FieldWithCheckbox
              article="Thin"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isThin}
            />
            <FieldWithCheckbox
              article="Wiry"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isWiry}
            />
            <FieldWithCheckbox
              article="Others"
              styles={styles}
              isChecked={payload && payload.observation.pulse.quality.isOther}
            />
          </FieldSection>
          <Text style={styles.articleText}>Trigger point. Second metacarpal bone</Text>
          <FieldSection>
            <Text style={styles.text}>Right:</Text>
            <FieldWithCheckbox
              article="Normal"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneRight.isNormal}
            />
            <FieldWithCheckbox
              article="Upper"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneRight.isUpper}
            />
            <FieldWithCheckbox
              article="Mid"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneRight.isMid}
            />
            <FieldWithCheckbox
              article="Lower"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneRight.isLower}
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Left:</Text>
            <FieldWithCheckbox
              article="Normal"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneLeft.isNormal}
            />
            <FieldWithCheckbox
              article="Upper"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneLeft.isUpper}
            />
            <FieldWithCheckbox
              article="Mid"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneLeft.isMid}
            />
            <FieldWithCheckbox
              article="Lower"
              styles={styles}
              isChecked={payload && payload.observation.triggerPoint.boneLeft.isLower}
            />
          </FieldSection>
          <Text style={styles.articleText}>Tongue</Text>
          <FieldSection>
            <Text style={styles.text}>Body:</Text>
            <FieldWithCheckbox
              article="Normal"
              styles={styles}
              isChecked={payload && payload.observation.tongue.body.isNormal}
            />
            <FieldWithCheckbox
              article="Small"
              styles={styles}
              isChecked={payload && payload.observation.tongue.body.isSmall}
            />
            <FieldWithCheckbox
              article="Big"
              styles={styles}
              isChecked={payload && payload.observation.tongue.body.isBig}
            />
            <FieldWithCheckbox
              article="Teeth print"
              styles={styles}
              isChecked={payload && payload.observation.tongue.body.isTeethPrint}
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Color:</Text>
            <FieldWithCheckbox
              article="Normal"
              styles={styles}
              isChecked={payload && payload.observation.tongue.color.isNormal}
            />
            <FieldWithCheckbox
              article="Purple"
              styles={styles}
              isChecked={payload && payload.observation.tongue.color.isPurple}
            />
            <FieldWithCheckbox
              article="Purple Spots"
              styles={styles}
              isChecked={payload && payload.observation.tongue.color.isPurpleSpots}
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Coating:</Text>
            <FieldWithCheckbox
              article="None"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isNone}
            />
            <FieldWithCheckbox
              article="White"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isWhite}
            />
            <FieldWithCheckbox
              article="Yellow"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isYellow}
            />
            <FieldWithCheckbox
              article="Greasy"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isGreasy}
            />
            <FieldWithCheckbox
              article="Thin"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isThin}
            />
            <FieldWithCheckbox article="Thick" styles={styles} isChecked={false} />
            <FieldWithCheckbox
              article="Wet"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isWet}
            />
            <FieldWithCheckbox
              article="Sticky"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isSticky}
            />
            <FieldWithCheckbox
              article="Cracked"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isCracked}
            />
            <FieldWithCheckbox
              article="Dry"
              styles={styles}
              isChecked={payload && payload.observation.tongue.coating.isDry}
            />
          </FieldSection>
          <FieldSection>
            <Text style={styles.text}>Local Change:</Text>
            <FieldWithCheckbox
              article="Warm"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isWarm}
            />
            <FieldWithCheckbox
              article="Cold"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isCold}
            />
            <FieldWithCheckbox
              article="Swelling"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isSwelling}
            />
            <FieldWithCheckbox
              article="Atrophy"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isAtrophy}
            />
            <FieldWithCheckbox
              article="Deformed"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isDeformed}
            />
            <FieldWithCheckbox
              article="Tenderness"
              styles={styles}
              isChecked={payload && payload.observation.tongue.localChange.isTenderness}
            />
            <FieldWithCheckbox
              article="Trigger points"
              styles={styles}
              isChecked={
                payload && payload.observation.tongue.localChange.isTriggerPoints
              }
            />
          </FieldSection>
          <FieldArticle text="Assesment" />
          <Text style={styles.articleText}>Traditional Chinese Medicine Diagnosis</Text>
          <FieldSection>
            <FieldWithCheckbox
              article="Qi and Blood Stagnation"
              styles={styles}
              isChecked={
                payload && payload.assessment.chineseDiagnostic.isQiAndBloodStagnation
              }
            />
            <FieldWithCheckbox
              article="Dampness Stagnation"
              styles={styles}
              isChecked={
                payload && payload.assessment.chineseDiagnostic.isDampnessStagnation
              }
            />
            <FieldWithCheckbox
              article="Post Traumatic “Pain” syndrome"
              styles={styles}
              isChecked={
                payload &&
                payload.assessment.chineseDiagnostic.isPostTraumaticPainSyndrome
              }
            />
            <FieldWithCheckbox
              article="Qi and Blood Deficiency"
              styles={styles}
              isChecked={
                payload && payload.assessment.chineseDiagnostic.isQiAndBloodDeficiency
              }
            />
            <FieldWithCheckbox
              article="Dampness and Heat"
              styles={styles}
              isChecked={
                payload && payload.assessment.chineseDiagnostic.isDampnessAndHeat
              }
            />
            <FieldWithCheckbox
              article="Other"
              styles={styles}
              isChecked={payload && payload.assessment.chineseDiagnostic.isOther}
            />
            <FieldValue
              article=""
              text={payload?.assessment.chineseDiagnostic.other ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <Text style={styles.articleText}>Western Medical Diagnosis (ICD 10)</Text>
          <FieldSection>
            <FieldWithCheckbox
              article="M54.2 Cervicalgia (Neck Pain)"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isCervicalgia
              }
            />
            <FieldWithCheckbox
              article="M54.30 Sciatica"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isSciatica
              }
            />
            <FieldWithCheckbox
              article="M54.5Lumbago (Lower back pain)"
              styles={styles}
              isChecked={payload && payload.assessment.westernMedicalDiagnostic.isLumbago}
            />
            <FieldWithCheckbox
              article="M25.539 Wrist Pain"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isWristPain
              }
            />
            <FieldWithCheckbox
              article="M25.559 Hip Pain"
              styles={styles}
              isChecked={payload && payload.assessment.westernMedicalDiagnostic.isHipPain}
            />
            <FieldWithCheckbox
              article="M25.569 Gonalgia (Knee Pain)"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isGonalgia
              }
            />
            <FieldWithCheckbox
              article="M25.519 Shoulder Pain"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isShoulderPain
              }
            />
            <FieldWithCheckbox
              article="M25.579 Ankle/Foot Pain"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isAnklePain
              }
            />
            <FieldWithCheckbox
              article="G44.209 Tension Headache"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isTensionHeadache
              }
            />
            <FieldWithCheckbox
              article="G43.909 Migraine"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isMigraine
              }
            />
            <FieldWithCheckbox
              article="R51 Headache"
              styles={styles}
              isChecked={
                payload && payload.assessment.westernMedicalDiagnostic.isHeadache
              }
            />
            <FieldWithCheckbox
              article="G89.21 Chronic pain due to trauma"
              styles={styles}
              isChecked={
                payload &&
                payload.assessment.westernMedicalDiagnostic.isChronicPainDueToTrauma
              }
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Pain Intensity"
              text={payload?.assessment.westernMedicalDiagnostic.painIntensity ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="ROM"
              text={payload?.assessment.westernMedicalDiagnostic.rom ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Frequency of pain"
              text={payload?.assessment.westernMedicalDiagnostic.frequencyOfPain ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldArticle text="Treatment Plan" />
          <FieldSection>
            <FieldWithCheckbox
              article="Promote flow of Qi and Blood in affected Meridians"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.isPromoteFlowOfQiAndBlood}
            />
            <FieldWithCheckbox
              article="Relieve muscle spasm/pain"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.isRelieveMusclePain}
            />
            <FieldWithCheckbox
              article="Inactivated local trigger points"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.isInactivatedLocalTriggerPoints}
            />
            <FieldWithCheckbox
              article="Clear Mind, Calm Heart"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.isClearMind}
            />
            <FieldWithCheckbox
              article="Boost Tendon Strength"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.isBoostTendonStrength}
            />
          </FieldSection>
          <Text style={styles.articleText}>Treatment Provided:</Text>
          <FieldSection>
            <FieldWithCheckbox
              article="Acupuncture"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isAcupuncture}
            />
            <FieldWithCheckbox
              article="Acupuncture with electrical stimulation"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.treatmentProvided
                  .isAcupunctureWithElectricalStimulation
              }
            />
            <FieldWithCheckbox
              article="Cupping"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isCupping}
            />
            <FieldWithCheckbox
              article="Manuel therapy"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.treatmentProvided.isManuelTherapy
              }
            />
            <FieldWithCheckbox
              article="Tui Na"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isTuiNa}
            />
            <FieldWithCheckbox
              article="Electromagnetic Health Lamp"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.treatmentProvided.isElectromagneticHealthLamp
              }
            />
            <FieldWithCheckbox
              article="Ear Seed"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isEarSeed}
            />
            <FieldWithCheckbox
              article="Moxa"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isMoxa}
            />
            <FieldWithCheckbox
              article="Hot Pack"
              styles={styles}
              isChecked={payload && payload.treatmentPlan.treatmentProvided.isHotPack}
            />
            <FieldWithCheckbox
              article="Therapeutic Exercise"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.treatmentProvided.isTherapeuticExercise
              }
            />
          </FieldSection>
          <Text style={styles.articleText}>
            Insertion points are chosen according to the Traditional Chinese Medical
            Theory from the affected channels:
          </Text>
          <FieldSection>
            <FieldWithCheckbox
              article="LU-Hand Tai Yin"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isLuHandTaiYin
              }
            />
            <FieldWithCheckbox
              article="LI-Hand Yang Ming"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.insertionPointsAreChinese.isLiHandYangMind
              }
            />
            <FieldWithCheckbox
              article="ST-Foot Yang Ming"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.insertionPointsAreChinese.isStFootYangMing
              }
            />
            <FieldWithCheckbox
              article="SP-Foot Tai Yin"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isSpFootTaiYin
              }
            />
            <FieldWithCheckbox
              article="HT-Hand Shao Yin"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isHtHandShaoYin
              }
            />
            <FieldWithCheckbox
              article="SI-Hand Tai Yang"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isSiHandYaiyang
              }
            />
            <FieldWithCheckbox
              article="UB-Foot Tai Yang"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isUbFootTaiYang
              }
            />
            <FieldWithCheckbox
              article="KI-Foot Shao Yin"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isKiFootShaoYin
              }
            />
            <FieldWithCheckbox
              article="SJ-Hand Shao Yang"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.insertionPointsAreChinese.isSjHandShaoYang
              }
            />
            <FieldWithCheckbox
              article="GB-Foot Shao Yang"
              styles={styles}
              isChecked={
                payload &&
                payload.treatmentPlan.insertionPointsAreChinese.isGbFootShaoYang
              }
            />
            <FieldWithCheckbox
              article="LIV-Foot Jue Yin"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isLivFootJueYin
              }
            />
            <FieldWithCheckbox
              article="Du Meridian"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isDuMeridian
              }
            />
            <FieldWithCheckbox
              article="Ren Meridian"
              styles={styles}
              isChecked={
                payload && payload.treatmentPlan.insertionPointsAreChinese.isRenMeridian
              }
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Acupuncture Points"
              text={payload?.acupuncturePoints ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
          <FieldSection>
            <FieldValue
              article="Provider"
              text={payload?.provider ?? ''}
              styles={styles}
              isPadding
            />
            <FieldValue
              article="Signed by"
              text={payload?.signedBy ?? ''}
              styles={styles}
              isPadding
            />
          </FieldSection>
        </Page>
      </Document>
    );
  };

  return data ? (
    <GenerateInitialVisitFormContent />
  ) : (
    <PDFViewer style={styles.viewer}>
      <GenerateInitialVisitFormContent />
    </PDFViewer>
  );
};
export default GenerateInitialVisitForm;
