import { Font, StyleSheet } from '@react-pdf/renderer';
import InterRegular from 'fonts/Inter-Regular.ttf';
import InterBold from 'fonts/Inter-Bold.ttf';
import InterMedium from 'fonts/Inter-Medium.ttf';

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontWeight: 400,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
  ],
});

export const generateStylesConfig = () => {
  return StyleSheet.create({
    // common
    viewer: {
      fontFamily: 'Inter',
      width: window.innerWidth / 1.5,
      height: window.innerHeight / 1.2,
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: '15 20 15 15',
    },
    table: {
      width: 562,
      border: 1,
      borderBottom: 1,
      borderColor: '#EC2027',
      height: 300,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    redRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: 560,
      borderBottom: 1,
      borderColor: '#EC2027',
      height: 25,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cell: {
      borderLeft: 1,
      borderColor: '#EC2027',
      height: 25,
    },

    // text
    header: {
      fontSize: 11,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.22,
      marginTop: 60,
      marginBottom: 3,
    },
    subHeader: {
      fontSize: 8,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.152,
      marginBottom: 6,
    },
    title: {
      fontSize: 6,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.12,
      padding: 2.5,
    },
    subTitle: {
      fontSize: 5,
      fontWeight: 400,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.1,
      padding: 2.5,
    },
    text: {
      fontSize: 10,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#202E5F',
      letterSpacing: 0.152,
    },
    textUnderlie: {
      fontSize: 10,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#202E5F',
      letterSpacing: 0.152,
      textDecoration: 'underline',
      textDecorationColor: '#EC2027',
    },
    check: {
      fontSize: 6.5,
      fontWeight: 700,
      fontFamily: 'Inter',
      color: '#202E5F',
      letterSpacing: 0.152,
      marginLeft: 1.5,
    },

    // arrow
    columnRow: {
      marginRight: -30,
      marginTop: 30,
      marginBottom: 30,
      fontSize: 8,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.22,
      transform: 'rotate(270deg)',
    },
    columnRowSec: {
      marginRight: -85,
      marginTop: 130,
      marginBottom: 130,
      fontSize: 8,
      fontWeight: 500,
      fontFamily: 'Inter',
      color: '#EC2027',
      letterSpacing: 0.22,
      transform: 'rotate(270deg)',
    },
    arrow: {
      marginRight: -30,
      fontFamily: 'Inter',
      fontSize: 11,
      color: '#EC2027',
      transform: 'rotate(90deg)',
    },
    arrowSec: {
      marginRight: -85,
      fontFamily: 'Inter',
      fontSize: 11,
      color: '#EC2027',
      transform: 'rotate(90deg)',
    },

    square: {
      width: 9,
      height: 9,
      border: 0.5,
      borderColor: '#EC2027',
      backgroundColor: 'transparent',
    },
    underline: {
      width: 100,
      height: 10,
      borderBottom: 0.5,
      borderColor: '#EC2027',
      backgroundColor: 'transparent',
    },
    verticalBorder: {
      width: 5,
      height: 14,
      borderRight: 1,
      borderColor: '#EC2027',
      borderStyle: 'dashed',
    },
    leftBorder: {
      width: 50,
      height: 10,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: '#EC2027',
      borderStyle: 'solid',
    },
  });
};
