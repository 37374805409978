import { useEffect, useState } from 'react';
import { Button, Input } from '../../../../../../components';
import {
  AppointmentTemplateType,
  TemplateType,
} from '../../../../../../types/TemplateType';
import { getIconTemplate } from '../../getIconTemplate';
import * as S from './styles';
import { Clinics } from '../../../../../../types/ClinicTypes';
import SettingsCard from './SettingsCard';
import { useTemplatesUpdateSelectedTypesMutation } from '../../../../../../store/api/templates/templateApi';
import { setMessage } from '../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';

type Props = {
  onClose: () => void;
  data?: TemplateType;
};

const SettingsTemplate: React.FC<Props> = ({ onClose, data }) => {
  const [defaultState, setDefaultState] = useState<Array<Clinics>>([]);
  const [stateToServer, setStateToServer] = useState<Array<Clinics>>([]);
  const [note, setNote] = useState('');

  const [updateSettings] = useTemplatesUpdateSelectedTypesMutation({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.clinics) {
      setDefaultState(data?.clinics);
      setNote(data.note || '');
    }
  }, [data]);

  const handleSave = () => {
    const newArr = defaultState.map(item => {
      return {
        clinicTemplateId: item.TemplateClinic?.id,
        isInitialConsultation: item.TemplateClinic?.isInitialConsultation || false,
        isFollowUp: item.TemplateClinic?.isFollowUp || false,
        isReEvaluation: item.TemplateClinic?.isReEvaluation || false,
        isHidden: item.TemplateClinic?.isHidden || false,
      };
    });
    onClose();
    updateSettings({
      note: note,
      selectedTypes: newArr,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Settings was successfully saved',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleChangeSettings = (id: string, type: AppointmentTemplateType) => {
    const newArr = defaultState.map(item => {
      if (item.id === id && item.TemplateClinic) {
        switch (type) {
          case AppointmentTemplateType.HIDDENCLINIC:
            return {
              ...item,
              TemplateClinic: {
                ...item.TemplateClinic,
                isHidden: !item.TemplateClinic?.isHidden,
              },
            };
          case AppointmentTemplateType.FOLLOWUP:
            return {
              ...item,
              TemplateClinic: {
                ...item.TemplateClinic,
                isFollowUp: !item.TemplateClinic?.isFollowUp,
              },
            };
          case AppointmentTemplateType.REEVAL:
            return {
              ...item,
              TemplateClinic: {
                ...item.TemplateClinic,
                isReEvaluation: !item.TemplateClinic?.isReEvaluation,
              },
            };
          case AppointmentTemplateType.INITIAL:
            return {
              ...item,
              TemplateClinic: {
                ...item.TemplateClinic,
                isInitialConsultation: !item.TemplateClinic?.isInitialConsultation,
              },
            };
          default:
            return item;
        }
      } else {
        return item;
      }
    });
    setDefaultState(newArr);
  };

  return (
    <S.Content>
      <S.Article>Assign Template</S.Article>
      <S.Block>
        <S.Caption>Template name</S.Caption>
        <S.HelperText>
          {data?.type && getIconTemplate(data?.type, data?.name.slice(-3))}
          {data?.name}
        </S.HelperText>
        <S.InputWrapper>
          <Input label="Note" value={note} onChange={e => setNote(e.target.value)} />
        </S.InputWrapper>
      </S.Block>

      <S.Cards>
        {defaultState.map(item => {
          return (
            <SettingsCard
              key={item.id}
              card={item}
              onChangeSettings={(type: AppointmentTemplateType) =>
                handleChangeSettings(item.id, type)
              }
            />
          );
        })}
      </S.Cards>
      <S.ButtonWrapper>
        <Button text="Save" onClick={handleSave} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default SettingsTemplate;
