import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 48px;
  min-height: 48px;
  width: 100%;
  border-radius: 5px;
  background: var(--hover-1, rgba(0, 132, 177, 0.08));
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.bold {
    font-weight: 600;
  }

  &.link {
    font-weight: 600;
    color: #0084b1;
    cursor: pointer;
  }
`;

export const Information = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 25px;
`;

export const DeleteBtnWrapper = styled.div`
  display: flex;
  width: fit-content;
  cursor: pointer;
`;
