import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  & .Mui-focused {
    border-bottom: 0.5px solid var(--line, #0084b1) !important;

    &.MuiFormHelperText-root {
      border: none !important;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    border-radius: 0px;
    padding: 9px 9px;
    line-height: normal;
    color: var(--text, #202e5f);
    font-family: Inter;
    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
    }
  }

  & .MuiInputBase-input {
    border: none;
    padding: 0;
  }
  & .MuiButtonBase-root {
    font-size: 1rem;
    cursor: auto;
  }
`;
