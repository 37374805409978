import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  box-shadow: 0px -2px 27px 0px rgba(0, 84, 113, 0.2);

  @media ${devices.xs} {
    flex-direction: column;
    gap: 15px;
    height: 172px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 97px;

  @media ${devices.xs} {
    flex-direction: column;
    gap: 15px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const Article = styled.div`
  width: 228px;
  height: 16px;
  flex-shrink: 0;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 14px;
  }
`;

export const Text = styled.div`
  width: 901px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 346px;
    font-size: 12px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 174px;

  @media ${devices.xs} {
    width: 300px;
  }
`;
