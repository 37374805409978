import { DoctorClaimResponse } from 'types/AccTableTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const billingApi = api.injectEndpoints({
  endpoints: builder => ({
    billingAccTableGet: builder.query<any, any>({
      query: ({ ...params }) => ({
        url: `billing/accounting-table`,
        params: { ...params },
      }),
      providesTags: ['Billing'],
    }),
    doctorClaimList: builder.query<Response<DoctorClaimResponse>, any>({
      query: ({ ...params }) => ({
        url: `/billing/doctor-claims`,
        params: { ...params },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return !!currentArg?.clinicId && currentArg !== previousArg;
      },
      // invalidatesTags: ['Clinics'],
    }),
  }),
});

export const { useBillingAccTableGetQuery, useDoctorClaimListQuery } = billingApi;
