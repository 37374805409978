import { FormikProps } from 'formik';
import { handleChangeInputRef } from 'helpers/FormikFuncs/formikFuncs';
import { Input } from 'components';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FirstName from 'components/organismus/AppointmentDrawer/components/PatientInformation/FirstName';
import { useAppSelector } from 'hooks/useAppSelector';
import LastName from 'components/organismus/AppointmentDrawer/components/PatientInformation/LastName';
import { ClearFiltersSVG } from 'assets/icons';
import DOB from 'components/organismus/AppointmentDrawer/components/PatientInformation/DOB';
import { getAge } from 'helpers/functions/getAge';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { AppointmentsNew, Step } from 'types/AppointmentNewTypes';
import * as S from '../../styles';
import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  stepperValues?: Step;
  appointmentInformation?: AppointmentsNew;

  setIsDirty?: () => void;
};
const ClientRegistrationUserBlock = forwardRef<Record<string, unknown>, Props>(
  ({ formik, stepperValues, appointmentInformation, setIsDirty }, ref) => {
    const [selectedValue, setSelectedValue] = useState<any>('');
    const { id } = useAppSelector(state => state.selectedClinic);
    const [emptyDatePicker, setEmptyDatePicker] = useState(false);

    useEffect(() => {
      if (appointmentInformation) {
        setSelectedValue(appointmentInformation.patient);
      }
    }, [appointmentInformation]);

    useEffect(() => {
      if (selectedValue) {
        formik.setErrors({
          patientName: '',
        });
        formik.setValues({
          ...formik.values,
          userId: selectedValue?.id,
          age: getAge(selectedValue.profile?.dateOfBirth),
          gender: selectedValue.profile?.sex,
          birthDate: dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY'),
          patientName:
            selectedValue.profile?.firstName + ' ' + selectedValue.profile?.lastName,
        });
      } else
        formik.setValues({
          ...formik.values,
          userId: '',
          age: 0,
          gender: '',
          patientName: '',
          birthDate: '',
        });
    }, [selectedValue]);

    return (
      <>
        {selectedValue && !appointmentInformation && (
          <S.ResetButton
            onClick={() => {
              setEmptyDatePicker(true);
              setSelectedValue('');
            }}
          >
            <ClearFiltersSVG />
            Change user
          </S.ResetButton>
        )}

        {formik.errors.patientName && (
          <S.Line>
            <S.ErrorText>{'USER INFORMATION IS REQUIRED!'}</S.ErrorText>
          </S.Line>
        )}

        <S.Line>
          <S.InputWrapper className="px240">
            <FirstName
              error={formik.errors.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <LastName
              error={formik.errors.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <DOB
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
              emptyDatePicker={emptyDatePicker}
              setEmptyDatePicker={setEmptyDatePicker}
            />
          </S.InputWrapper>
          {selectedValue && (
            <>
              <S.BoldText>
                Age: <S.Span>{formik.values.age}</S.Span>
              </S.BoldText>
              <S.BoldText>
                Sex: <S.Span>{ucFirst(formik.values.gender)}</S.Span>
              </S.BoldText>
            </>
          )}
        </S.Line>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="Billing Address"
              defaultValue={formik.values.billingAddress}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'billingAddress', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="Physical Address"
              defaultValue={formik.values.physicalAddress}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'physicalAddress', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="City"
              defaultValue={formik.values.city}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'city', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="State"
              defaultValue={formik.values.state}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'state', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="Zip Code"
              defaultValue={formik.values.zipCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'zipCode', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="Drivers Lic #"
              defaultValue={formik.values.driversLic}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'driversLic', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="Last 4 Digits of SSN #"
              defaultValue={formik.values.last4DigitsOfSSN}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'last4DigitsOfSSN', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
        </S.Line>
      </>
    );
  },
);

export default ClientRegistrationUserBlock;
