import React, { useMemo } from 'react';
import { ServiceType } from 'types/ServicesType';
import * as S from './styles';
import { DeleteRedBtnSVG } from 'assets/icons';
import { ColoredServiceBox } from 'components/atoms/ColoredServiceBox';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import { ucFirst } from 'helpers/functions/toUpperCase';

type Props = {
  row: ServiceType;
  header: string;
  setShowConfirmDialog: React.Dispatch<React.SetStateAction<string | null>>;
};

export const ServiceTableCell = ({ row, header, setShowConfirmDialog }: Props) => {
  const userInfo = useAppSelector(state => state.auth);

  const assignClinics = useMemo(() => {
    return userInfo.role === Roles.LOCATION_MANAGER
      ? row?.clinics?.filter(clinic => userInfo.clinics.some(i => i.id === clinic.id))
      : row?.clinics;
  }, [row?.clinics, userInfo.clinics, userInfo.role]);

  switch (header) {
    case 'color':
      return (
        <S.CellRowWrapper>
          <ColoredServiceBox color={row.color} size={'xs'} />
        </S.CellRowWrapper>
      );
    case 'name':
      return <S.CellRowWrapper>{row?.serviceName}</S.CellRowWrapper>;
    case 'assignTo':
      return <S.CellRowWrapper>{ucFirst(row?.serviceCategory) ?? '-'}</S.CellRowWrapper>;
    case 'clinics':
      return (
        <S.CellRowWrapper>
          {assignClinics?.length
            ? assignClinics?.map(clinic => clinic?.name)?.join(', ')
            : '-'}
        </S.CellRowWrapper>
      );
    case 'buttons': {
      return (
        <S.Btns>
          {!row?.clinics?.length && userInfo.role === Roles.SUPER_ADMIN && (
            <DeleteRedBtnSVG
              onClick={e => {
                e.stopPropagation();
                setShowConfirmDialog(row.id);
              }}
            />
          )}
        </S.Btns>
      );
    }
    default:
      return null;
  }
};
