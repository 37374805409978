import styled from '@emotion/styled';
import { devices } from '../../../../../../../../constants/device';
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 538px;
  padding-bottom: 70px;
  @media ${devices.xs} {
    width: 375px;
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 538px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const GraySpan = styled.span`
  color: #a25fc1;
`;

export const GreenArticle = styled.span`
  color: #9bc15f;
  font-weight: 600;
`;

export const RedArticle = styled.span`
  color: #c66060;
  font-weight: 600;
`;

export const BlueArticle = styled.span`
  color: #0084b1;
  font-weight: 600;
`;

export const ArticleWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 502px;
`;

export const RowItem = styled.div`
  display: inline-flex;
  padding: 12px 10px 12px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  border-radius: 10px;
  background: #f9f9fc;
`;

export const RowArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SpanWeight = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SpanBy = styled.span`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Time = styled.div`
  color: var(--icon, #737e8f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
