import styled from 'styled-components';
import { devices } from '../../../../../constants/device';

export const CalendarBarWrapper = styled.div`
  width: 255px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  @media ${devices.xs} {
    width: auto;
    gap: none;
    justify-content: center;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const FilterWrapper = styled.div`
  width: 238px;
  & .MuiInputBase-root {
    background-color: #fff;
  }

  @media ${devices.xs} {
    display: none;
  }
`;

export const CheckboxWrapper = styled.div`
  width: 225px;
  margin-top: 10px;
  & .MuiInputBase-root {
    background-color: #fff;
  }

  @media ${devices.xs} {
    display: none;
  }
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;

  & svg {
    position: absolute;
  }
`;

export const SelectInputOutputText = styled.div`
  margin-left: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
