import { SearchOperatorsForKey } from 'helpers/FilterSearch/filterSearch';

export enum InsuranceSearchKey {
  NAME = 'insuranceName',
  PAYER_ID = 'payerId',
  GROUP_NUMBER = 'groupNumber',
  COVERED_SERVICE = 'coveredService',
}

export const searchKeys: SearchOperatorsForKey[] = [
  {
    name: 'Insurance Name',
    key: InsuranceSearchKey.NAME,
  },
  {
    name: 'Group #',
    key: InsuranceSearchKey.GROUP_NUMBER,
  },
  {
    name: 'Covered service',
    key: InsuranceSearchKey.COVERED_SERVICE,
  },
];

export const headers = [
  { name: 'Insurance Name', key: 'insuranceName' },
  { name: 'Group #', key: 'groupNumber' },
  { name: 'Covered services', key: 'coveredServices' },
  { name: 'Note', key: 'note' },
  { name: '', key: 'buttons' },
];
