import { PhotoProvider, PhotoView } from 'react-photo-view';
import * as S from '../../styles';
import { InsuranceInUserProfileType } from 'types/InsuranceType';
import { AddRectSVG, CloseSVG } from 'assets/icons';
import { useFilesCreateInProfileMutation } from 'store/api/files/files';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { useState } from 'react';
import { ResponseCommonFile } from 'types/FileType';
import { useUploadPhotoInsuranceMutation } from 'store/api/user/profileApi';
import { useParams } from 'react-router-dom';
import { CircularLoader, Dialog } from 'components';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { useInsuranceDeleteFilesMutation } from 'store/api/insuranceRelative/insuranceRelativeApi';

type Props = {
  row?: InsuranceInUserProfileType;
};

const PhotoInsurancesBlock = ({ row }: Props) => {
  const { patientId } = useParams();

  const [deleteFile, statusDelete] = useInsuranceDeleteFilesMutation({});
  const dispatch = useAppDispatch();

  // const [filesValues, setFilesValues] = useState<Array<File>>([]);

  const [uploadFiles, statusUploadFiles] = useFilesCreateInProfileMutation({});

  const [uploadPhotoInsurance, statusUploadPhoto] = useUploadPhotoInsuranceMutation({});

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      uploadFiles({ files: [...file] })
        .unwrap()
        .then(res => {
          const newItemsArr: string[] = [];
          row?.files.map(file => newItemsArr.push(file.id));
          res.map((item: ResponseCommonFile, index: number) => {
            newItemsArr.push(item.value.id);
          });
          uploadPhotoInsurance({
            files: newItemsArr,
            insuranceId: row?.id,
          })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Files was successfully added',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
        })
        .catch(error => console.log(error));
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const [deletedId, setDeletedId] = useState<string | null>(null);

  const handleSuccessDelete = () => {
    if (deletedId) {
      deleteFile({ id: deletedId, insuranceId: row?.id })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'File was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    }
  };

  return (
    <>
      <S.UploadPhotoWrapper>
        <Dialog
          open={openDialog}
          onClose={() => {
            setDeletedId(null);
            setOpenDialog(!openDialog);
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setDeletedId(null);
              setOpenDialog(!openDialog);
            }}
            titleText={'document photo'}
            helperText="This process is irreversible"
            onSuccess={handleSuccessDelete}
          />
        </Dialog>
        <S.PhotoWrapper>
          <label htmlFor={`file${row?.id}`}>
            <S.AddPhotoBtn>
              <AddRectSVG />
              Upload photo
              {(statusUploadFiles.isLoading || statusUploadPhoto.isLoading) && (
                <S.Loader>
                  <CircularLoader color="#0084B1" size={20} />
                </S.Loader>
              )}
            </S.AddPhotoBtn>
            <input
              type="file"
              id={`file${row?.id}`}
              onChange={(e: any) => {
                handleChangeFile(e);
              }}
              accept="image/png, image/jpeg"
              hidden
              multiple={true}
            />
          </label>

          <S.SubArticle>Available formats JPG, JPEG, PNG, max size 50MB</S.SubArticle>
        </S.PhotoWrapper>
      </S.UploadPhotoWrapper>
      <S.UploadedPhotos>
        <PhotoProvider>
          {row &&
            row.files.map(item => {
              return (
                <PhotoView key={item?.id} src={item?.url}>
                  <S.ImgWrapper>
                    <S.DeleteCircleSmall
                      onClick={e => {
                        e.stopPropagation();
                        setDeletedId(item?.id);
                        setOpenDialog(true);
                      }}
                    >
                      <CloseSVG />
                    </S.DeleteCircleSmall>
                    <img src={item?.url} alt=""></img>
                  </S.ImgWrapper>
                </PhotoView>
              );
            })}
        </PhotoProvider>
      </S.UploadedPhotos>
    </>
  );
};

export default PhotoInsurancesBlock;
