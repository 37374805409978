import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Content = styled.div`
  height: auto;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px 20px 30px;

  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 20px;
  }
`;

export const InputItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const Article = styled.div`
  margin-top: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const ButtonCard = styled.div<{ $isSelected: boolean }>`
  border-radius: 5px;
  background: #ecf3f5;
  width: 324px;
  padding: 13px 10px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    cursor: pointer;
    background: #dfecf0;
  }

  border: 1px solid ${props => (props.$isSelected ? '#0084B1' : '#ECF3F5')};
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;
