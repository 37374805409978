import styled from '@emotion/styled';

export const Wrapper = styled.div``;

export const ProfileContent = styled.div`
  /* width: 356px;
  height: 201px; */
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`;

export const AvatarBlock = styled.div`
  & img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
  }
  svg {
    width: 100px;
    height: 100px;
  }
`;

export const Name = styled.div`
  display: flex;
  gap: 5px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Content = styled.div`
  padding: 20px 30px;
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BtnWrapper = styled.div`
  padding: 0px 30px 10px 30px;
`;
