import { useState } from 'react';
import { AddPatientsSVG, ArrowSVG, DownloadReportSVG, InfoCircleSVG } from 'assets/icons';
import { Button, Dialog, NotificationPopover } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useCsvDownloadMutation } from 'store/api/clinics/clinicApi';
import * as S from '../../styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { useUploadCSVMutation } from 'store/api/files/files';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { IconButton, Tooltip } from '@mui/material';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Roles } from 'types/Roles';
import { ImportErrorType } from 'pages/Receptionist/components/Patients/Patients';

type Props = {
  setErrors?: (errors: ImportErrorType[]) => void;
};

const ExportBtns = ({ setErrors }: Props) => {
  const [downloadCSV] = useCsvDownloadMutation();
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const { role } = useAppSelector(state => state.auth);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const handleAnchorExport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElExport ? setAnchorElExport(null) : setAnchorElExport(e.currentTarget);
  };

  const dispatch = useAppDispatch();
  const [uploadCSV, status] = useUploadCSVMutation();

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    await uploadCSV({
      id: selectedClinic.id,
      file: file,
    })
      .unwrap()
      .then(res => {
        setAnchorElExport(null);
        dispatch(
          setMessage({
            message: 'Patients have been successfully imported',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        setAnchorElExport(null);
        const errors: any[] = [];

        error?.data?.response?.validationMessage?.forEach((validation: any) =>
          errors.push(
            ...validation?.message.map((message: any) => ({
              ...message,
              index: message.index + 1,
            })),
          ),
        );

        !!setErrors && setErrors(errors);
      });
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <>
      <IconButton disableRipple={true} onClick={() => setOpenDownloadDialog(true)}>
        <DownloadReportSVG />
        <S.TextBtn>Download Template</S.TextBtn>
      </IconButton>

      <NoMaxWidthTooltip
        title={
          <S.TooltipText>
            <div>Description of some fields:</div>
            <div>
              &nbsp;*Auth Field- method of Patient Registration, enter the word “phone” or
              “email”
            </div>
            <div>
              &nbsp;*Relationship to patient- select one option: self/ cild/ spouse/ other
            </div>
            <div>&nbsp;*Gender- enter one option: female/male</div>
            <div>&nbsp;*State- Enter the abbreviated name of the state.</div>
          </S.TooltipText>
        }
        placement={'bottom-end'}
      >
        <S.SVG>
          <InfoCircleSVG />
        </S.SVG>
      </NoMaxWidthTooltip>
      <S.PopoverWrapper onClick={handleAnchorExport}>
        Export <ArrowSVG />
        <NotificationPopover
          open={Boolean(anchorElExport)}
          anchorEl={anchorElExport}
          onClose={() => setAnchorElExport(null)}
        >
          <S.ContentWrapper>
            <span className="icon-download download-csv-1" />
            <S.ContentRow
              onClick={() => {
                downloadCSV({ id: selectedClinic.id });
                setAnchorElExport(null);
              }}
            >
              Export Patient Data
            </S.ContentRow>
            <label htmlFor="fileclick">
              <S.ContentRow>Import Patient Data</S.ContentRow>
              <input
                id="fileclick"
                type="file"
                hidden
                multiple={false}
                accept=".csv"
                onChange={handleChangeFile}
              />
            </label>
          </S.ContentWrapper>
        </NotificationPopover>
      </S.PopoverWrapper>
      {role !== Roles.BILLING && (
        <S.ButtonWrapper>
          <Button
            text="Add Patients"
            onClick={() =>
              dispatch(
                showDrawer({
                  show: true,
                  mode: DrawerMode.ADD_PATIENTS,
                  props: selectedClinic.id,
                }),
              )
            }
          >
            <AddPatientsSVG />
          </Button>
        </S.ButtonWrapper>
      )}

      <Dialog open={openDownloadDialog} onClose={() => setOpenDownloadDialog(false)}>
        <ConfirmDeleteDialog
          onClose={() => setOpenDownloadDialog(false)}
          fullTitle={'Warning'}
          helperText={
            <>
              <span>
                In the downloaded file you will see the first line - a sample of filling
                out the template. Please stick to its data format in it, so that you can
                upload the app after filling it in.
              </span>
              <S.BoldText>
                {' '}
                Before saving the file, please make sure that the sample line is deleted.{' '}
              </S.BoldText>
              <span>Download the file for filling out?</span>
            </>
          }
          onSuccess={() => undefined}
          href={`/assets/importTemplates/Patient Template.csv`}
          cancelText={'Cancel'}
          confirmText={'Download'}
          helperTextWithNote={false}
          smallHelperTextLineHeight={true}
        />
      </Dialog>
    </>
  );
};

export default ExportBtns;
