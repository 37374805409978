import styled, { css } from 'styled-components';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div``;

export const HeaderInformation = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 18px;
  margin-top: 10px;
  margin-right: 16px;

  & .btn {
    cursor: pointer;
  }

  & .btn.disabled {
    pointer-events: none;
  }

  @media ${devices.xs} {
    display: none;
  }
`;

export const ChoosedDateWrapper = styled.div<{ $isModePending?: boolean }>`
  user-select: none;
  display: flex;
  align-items: center;
  gap: 11px;

  ${props =>
    props.$isModePending &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  @media ${devices.pl} {
    gap: 3px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ChoosedDateText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 250px;

  @media ${devices.pl} {
    width: 100px;
    font-size: 10px;
  }
`;

export const ModeButtons = styled.div<{ $isModePending?: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  ${props =>
    props.$isModePending &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const SelectWrapper = styled.div`
  width: 160px;

  & .MuiInputBase-root {
    background-color: #fff;
  }
`;

export const MenuItemRow = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
`;
