import { useNavigate, useParams } from 'react-router-dom';
import { AlertItem, Button, TabsItem } from 'components';
import * as S from './styles';
import { ArrowLeftSVG, CloseBtnSVG, ConfirmBtnSVG } from 'assets/icons';
import ClinicProfileInformation from '../Clinics/components/ClinicProfile';
import { Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import StaffDoctors from '../Staff/components/StaffDoctors';
import StaffReceptionists from '../Staff/components/StaffReceptionists';
import StaffLocal from '../Staff/components/StaffLocal';
import { useDispatch } from 'react-redux';
import ClinicWorkSchedule from '../Clinics/components/ClinicWorkSchedule';
import {
  useClinicUpdateMutation,
  useClinicsGetOneQuery,
} from 'store/api/clinics/clinicApi';
import { AppointmentStatus } from 'types/StatusTypes';
import { setMessage } from 'store/slices/message';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';

const ProfileClinic = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { role } = useAppSelector(state => state.auth);

  const clinicInfo = useClinicsGetOneQuery({
    id: params.clinicId,
  });

  const [updateStatus, statusUpdate] = useClinicUpdateMutation();

  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const [condition, setCondition] = useState<Array<string>>([]);

  useEffect(() => {
    setCondition([]);
    if (!clinicInfo.currentData?.hasSchedule) {
      setCondition(prev => [...prev, 'Add clinic schedule in tab "Clinic info"']);
    }
    if (!clinicInfo.currentData?.hasReceptionists) {
      setCondition(prev => [...prev, 'Add receptionist in tab "Receptionists"']);
    }
    if (clinicInfo.currentData?.status === AppointmentStatus.PENDING) {
      setCondition(prev => [...prev, 'Press button "Activate clinic"']);
    }
  }, [clinicInfo]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate('/clinics')}>
          <ArrowLeftSVG /> Back
        </S.SubtitleText>
        <S.SubtitleItems></S.SubtitleItems>
      </S.SubtitleBar>
      <S.SubtitleItems>
        <S.TabWrapper>
          <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
            <Tab disableRipple label="Clinic info" />
            <Tab disableRipple label="Doctors" />
            <Tab disableRipple label="Specialists" />
            <Tab disableRipple label="Receptionists" />
            {role !== Roles.LOCATION_MANAGER && (
              <Tab disableRipple label="Local admins" />
            )}
          </TabsItem>
        </S.TabWrapper>

        {clinicInfo.currentData?.status === AppointmentStatus.PENDING &&
          role === Roles.SUPER_ADMIN && (
            <>
              <AlertItem
                text="To complete the clinic registration process you need:"
                condition={condition}
                type="warning"
                height="68px"
                $isHideButton={true}
                $isDefault={true}
              />
              <S.ButtonWrapper>
                <Button
                  onClick={() =>
                    updateStatus({
                      id: params.clinicId,
                      status: AppointmentStatus.ACTIVE,
                    })
                      .unwrap()
                      .then(res =>
                        dispatch(
                          setMessage({
                            message: 'Clinic was successfully activated',
                            type: 'success',
                          }),
                        ),
                      )
                      .catch(err =>
                        dispatch(
                          setMessage({
                            message: 'Something went wrong',
                            type: 'error',
                          }),
                        ),
                      )
                  }
                  text="Activate clinic"
                  backgroundColor="#79A030"
                  disabled={
                    clinicInfo.currentData?.hasReceptionists === false ||
                    clinicInfo.currentData?.hasSchedule === false ||
                    clinicInfo.isLoading ||
                    clinicInfo.isFetching
                  }
                >
                  <ConfirmBtnSVG
                    className={
                      clinicInfo.currentData?.hasReceptionists === false ||
                      clinicInfo.currentData?.hasSchedule === false ||
                      clinicInfo.isLoading ||
                      clinicInfo.isFetching
                        ? 'disabled'
                        : ''
                    }
                  />
                </Button>
              </S.ButtonWrapper>
            </>
          )}
        {clinicInfo.currentData?.status === AppointmentStatus.ACTIVE &&
          role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button
                onClick={() =>
                  updateStatus({
                    id: params.clinicId,
                    status: AppointmentStatus.PENDING,
                  })
                    .unwrap()
                    .then(res =>
                      dispatch(
                        setMessage({
                          message: 'Clinic was successfully deactivated',
                          type: 'success',
                        }),
                      ),
                    )
                    .catch(err =>
                      dispatch(
                        setMessage({
                          message: 'Something went wrong',
                          type: 'error',
                        }),
                      ),
                    )
                }
                text="Deactivate clinic"
                backgroundColor="#C66060"
                disabled={
                  clinicInfo.currentData?.hasReceptionists === false ||
                  clinicInfo.currentData?.hasSchedule === false ||
                  clinicInfo.isLoading ||
                  clinicInfo.isFetching
                }
              >
                <CloseBtnSVG className="white" />
              </Button>
            </S.ButtonWrapper>
          )}
      </S.SubtitleItems>
      <S.Content>
        <S.ProfileInformation>
          <ClinicProfileInformation />
        </S.ProfileInformation>

        <S.TabInformation>
          <S.TabContent>
            {tabIndex === 0 && <ClinicWorkSchedule />}
            {tabIndex === 1 && <StaffDoctors deletable fromClinic />}
            {tabIndex === 2 && <StaffDoctors deletable fromClinic isSpecialists />}
            {tabIndex === 3 && <StaffReceptionists fromClinic />}
            {tabIndex === 4 && <StaffLocal fromClinic />}
          </S.TabContent>
        </S.TabInformation>
      </S.Content>
    </S.Wrapper>
  );
};

export default ProfileClinic;
