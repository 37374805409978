import { AddRectSVG } from 'assets/icons';
import { CustomTable, TooltipInput, RadioButton } from 'components';
import * as S from '../../styles';
import { useFormik } from 'formik';
import {
  ClaimValues,
  PaymentInfoFormType,
  ServiceInfoFormType,
} from 'types/AppointmentNewTypes';
import { RadioGroup, TableCell, TableRow } from '@mui/material';
import { ServicesRow } from './ServicesRow';
import React, { memo, useEffect } from 'react';
import {
  paymentClaimForm,
  paymentClaimSchema,
} from 'pages/Billing/components/Claims/formikClaim';
import { ClaimActionEnum } from '../../ClaimForm';

const headers: { name: string; key: keyof ServiceInfoFormType | 'trash' }[] = [
  { name: 'Date From', key: 'dateFrom' },
  { name: 'Date To', key: 'dateTo' },
  { name: 'Place of Service', key: 'placeOfService' },
  { name: 'EMG', key: 'emg' },
  { name: 'CPT/HCPCS', key: 'cpt' },
  { name: 'Modifier', key: 'modifier' },
  { name: 'Diagnosis Pointer', key: 'pointer' },
  { name: 'Charges', key: 'charges' },
  { name: 'Days of Units', key: 'units' },
  { name: 'EPSDT', key: 'epsdt' },
  { name: 'ID Qual', key: 'idQual' },
  { name: 'Rendering Provider ID', key: 'renderingProviderId' },
  { name: '', key: 'trash' },
];

const emptyRow = (
  dateFrom?: string,
  dateTo?: string,
  placeOfService?: string,
  renderingProviderId?: string | null,
) => {
  return {
    dateFrom: dateFrom ?? '',
    dateTo: dateTo ?? '',
    placeOfService: placeOfService ?? '',
    emg: '',
    cpt: '',
    modifier: ['', '', '', ''],
    pointer: '',
    charges: '',
    units: '',
    epsdt: '',
    idQual: '',
    renderingProviderId: renderingProviderId ?? '',

    trash: '',
  };
};

type Props = {
  data?: ClaimValues;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  setIsDirty: (isDirty: boolean) => void;
  renderingProviderId?: string | null;
  billingEin?: string | null;
};

export const ServicesTable = memo(
  ({
    data,
    getData,
    isSave,
    setIsSave,
    setIsDirty,
    renderingProviderId,
    billingEin,
  }: Props) => {
    const paymentFormik = useFormik<Partial<PaymentInfoFormType>>({
      initialValues: paymentClaimForm(data),
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: paymentClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await paymentFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(paymentFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (paymentFormik.dirty) {
        setIsDirty(true);
      }
    }, [paymentFormik.dirty]);

    useEffect(() => {
      if (paymentFormik.values.services?.length) {
        paymentFormik.setFieldValue(
          `totalCharge`,
          paymentFormik.values.services
            ?.reduce((acc, i) => acc + +i.charges, 0)
            .toFixed(2),
        );
      }
    }, [JSON.stringify(paymentFormik.values.services?.map(i => i.charges))]);

    useEffect(() => {
      paymentFormik.setFieldValue(
        'services',
        paymentFormik.values.services?.map(service => ({
          ...service,
          renderingProviderId: renderingProviderId ?? '',
        })),
      );
    }, [renderingProviderId]);

    useEffect(() => {
      if (paymentFormik.values.isFederalEIN === 'EIN') {
        paymentFormik.setFieldValue(
          'federalTaxId',
          billingEin ?? data?.federalTaxId ?? '',
        );
      }
    }, [billingEin]);

    return (
      <>
        <S.BlockWrapper>
          <S.Line className="space-between">
            <S.Article>Services</S.Article>
            <S.Btn
              onClick={() =>
                paymentFormik.setFieldValue('services', [
                  ...(paymentFormik.values?.services ?? []),
                  emptyRow(
                    paymentFormik.values?.services?.at(-1)?.dateFrom,
                    paymentFormik.values?.services?.at(-1)?.dateTo,
                    paymentFormik.values?.services?.at(-1)?.placeOfService,
                    renderingProviderId,
                  ),
                ])
              }
            >
              <AddRectSVG className="svg" />
              Add Line
            </S.Btn>
          </S.Line>
          <CustomTable headers={headers}>
            {!!paymentFormik.values?.services?.length &&
              paymentFormik.values?.services?.map((row: any, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '& .MuiTableCell-root': { padding: '0 1px' } }}
                >
                  {headers.map((header, i) => (
                    <TableCell key={header.key + i}>
                      <ServicesRow
                        row={row}
                        header={header.key}
                        paymentFormik={paymentFormik}
                        index={index}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </CustomTable>

          <S.Line>
            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="25.Federal Tax ID No"
                value={paymentFormik.values.federalTaxId}
                onChange={e =>
                  paymentFormik.setFieldValue('federalTaxId', e.target.value)
                }
                error={!!paymentFormik.errors.federalTaxId}
                tooltipHelperText={paymentFormik.errors.federalTaxId}
              />
            </S.InputWrapper>

            <RadioGroup
              value={paymentFormik.values.isFederalEIN}
              onChange={e => {
                paymentFormik.setFieldValue('isFederalEIN', e.target.value);
              }}
            >
              <S.Label>
                <S.LabelArticle></S.LabelArticle>
              </S.Label>
              <S.RadioItem>
                <S.RadioButtons>
                  <RadioButton label="SSN" value={'SSN'} />
                  <RadioButton label="EIN" value={'EIN'} />
                </S.RadioButtons>
              </S.RadioItem>
            </RadioGroup>

            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="26.Patient’s Account No "
                value={paymentFormik.values.patientAccountNumber}
                onChange={e =>
                  paymentFormik.setFieldValue('patientAccountNumber', e.target.value)
                }
                error={!!paymentFormik.errors.patientAccountNumber}
                tooltipHelperText={paymentFormik.errors.patientAccountNumber}
              />
            </S.InputWrapper>
            <RadioGroup
              value={paymentFormik.values.isAcceptAssignment}
              onChange={e =>
                paymentFormik.setFieldValue(
                  'isAcceptAssignment',
                  e.target.value === 'true',
                )
              }
            >
              <S.Label>
                <S.LabelArticle>27. Accept Assignment? *</S.LabelArticle>
              </S.Label>
              <S.RadioItem>
                <S.RadioButtons>
                  <RadioButton label="Yes" value={true} />
                  <RadioButton label="No" value={false} />
                </S.RadioButtons>
              </S.RadioItem>
            </RadioGroup>
            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="28.TOTAL CHARGE"
                type="number"
                isSum="$"
                value={paymentFormik.values.totalCharge}
                onChange={e => paymentFormik.setFieldValue('totalCharge', e.target.value)}
                error={!!paymentFormik.errors.totalCharge}
                tooltipHelperText={paymentFormik.errors.totalCharge}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipInput
                isRequired
                label="29.AMOUNT PAID"
                type="number"
                isSum="$"
                value={paymentFormik.values.totalPaid}
                onChange={e => paymentFormik.setFieldValue('totalPaid', e.target.value)}
                error={!!paymentFormik.errors.totalPaid}
                tooltipHelperText={paymentFormik.errors.totalPaid}
              />
            </S.InputWrapper>
          </S.Line>
        </S.BlockWrapper>
      </>
    );
  },
);
