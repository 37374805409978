import styled from 'styled-components';
import { devices } from '../../../../../../../../constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const Article14 = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubArticle = styled.div`
  width: 100%;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 300px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const InputWrapper = styled.div`
  width: 350px;

  @media ${devices.xs} {
    width: 250px;
  }
`;

export const Patient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PatientTitle = styled.div`
  padding-left: 6px;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 5px;

  svg,
  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
`;

export const HelperText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SelectInputWrapper = styled.div`
  width: 300px;
`;

export const WorkTime = styled.div`
  display: flex;
  gap: 20px;
  width: 300px;
  align-items: center;

  .line {
    margin-top: 20px;
  }
`;

export const DownloadBtnWrapper = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Btn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const RowText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 200px;
  word-wrap: break-word;
`;

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 300px;

  svg {
    cursor: pointer;
  }
`;

export const SchedLine = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 15px;
`;

export const SchedBtnWrapper = styled.div<{ disabled?: boolean }>`
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`;

export const WorkingHours = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  gap: 5px;
  .line {
    margin-top: 15px;
  }
  justify-content: space-between;
`;

export const ArticleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 50px;
  margin-top: 15px;
`;
