import { useDispatch } from 'react-redux';
import { PencilSVG } from '../../../assets/icons';
import { showDrawer } from '../../../store/slices/drawerSlice';
import * as S from './styles';
import { DrawerMode } from '../../../types/DrawerMode';

const AppointmentWithoutRegistration = () => {
  const dispatch = useDispatch();
  return (
    <>
      <S.AppointmentLink
        onClick={() =>
          dispatch(
            showDrawer({
              show: true,
              mode: DrawerMode.APPOINMENT_WITHOUT_REGISTRATION,
              props: null,
            }),
          )
        }
      >
        <PencilSVG />
        <S.Text>Appointment without registration</S.Text>
      </S.AppointmentLink>
    </>
  );
};

export default AppointmentWithoutRegistration;
