import { LinearProgress } from '@mui/material';
import * as S from './styles';

const LinearLoader = () => {
  return (
    <S.Loader>
      <LinearProgress />
    </S.Loader>
  );
};

export default LinearLoader;
