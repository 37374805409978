import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import CashTableData from "./CashTableData";

import { useState } from 'react';
import StaffRecepData from './StaffDoctorData';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: Array<Object> | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
  deletable?: boolean;
};

const StaffDoctorRow: React.FC<Props> = ({ headers, data, clickable, deletable }) => {
  const navigate = useNavigate();

  const [isClickedDelete, setIsClickedDelete] = useState(false);

  return (
    <>
      {data &&
        data.map((row: any, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && !isClickedDelete && navigate('/staff/' + row.userId);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <StaffRecepData
                  key={header.key}
                  header={header.key}
                  row={row}
                  setIsClickedDelete={setIsClickedDelete}
                  deletable={
                    !!(row.user.clinics && deletable && row.user.clinics.length > 1)
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default StaffDoctorRow;
