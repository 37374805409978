import styled from 'styled-components';

export const ChatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SubtitleBar = styled.div`
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--line, #d7d7d7);
`;

export const SubtitleText = styled.div`
  margin-left: 22px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

export const ChatsItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`;

export const FilterLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Messages = styled.div`
  min-width: 50%;
  max-width: 50%;
  background: #fff;
  height: calc(100vh - 110px);
`;

export const FilterWrapper = styled.div`
  width: 202px;
  & .MuiInputBase-root {
    background-color: #fff;
  }
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const MenuItemRow = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
`;

export const SearchLine = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 14px;
`;

export const SearchWrapper = styled.div`
  margin-left: 11px;
  margin-right: 11px;
`;

export const ChatItemsWrapper = styled.div`
  height: calc(100vh - 220px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.messages {
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
  }
`;

export const ChatWrapper = styled.div``;

export const MessageInputWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-top: 0.5px solid var(--line, #0084b1) !important;
`;

export const NoMessageWrapper = styled.div`
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MessagePlaceWrapper = styled.div<{ $hasFiles: boolean }>`
  height: ${props => (props.$hasFiles ? `calc(100vh - 268px)` : `calc(100vh - 168px);`)};

  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 20px;
  position: relative;
`;

export const PlaceText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 8px;
  background: #4f5a81;
  position: absolute;
  top: 10px;
  left: -32px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 17px;
  }
`;

export const DropzoneContainer = styled.div`
  cursor: pointer;
  background-color: red;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
`;

export const FileZone = styled.div`
  min-height: 100px;
  max-height: 100px;
  border-top: 0.5px solid var(--line, #d7d7d7);
  background: #fff;
  display: flex;
  align-items: center;
`;

export const FileItems = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 10px;
`;

export const FileItem = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

export const Icons = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 10px;
  background: #cad1d8;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;

  & .document {
    width: 30px;
    height: 30px;
  }

  & .close {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
`;

export const AddMsg = styled.div`
  width: 56px;
  height: 56px;
  min-width: 56px;
  border-radius: 10px;
  background: #848a9b;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.div`
  width: 63px;
  text-align: center;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
