import ReactSignatureCanvas from 'react-signature-canvas';
import * as S from './styles';
import { Button, CircularLoader } from '../../../../../../components';
import { useEffect, useState } from 'react';
import { useDigitalFormSavePDFMutation } from '../../../../../../store/api/templates/templateApi';

type Props = {
  res?: any;
  loading?: boolean;
  actionAfterSignature?: any;
  actionWhenDoctor?: any;
};

const PutSignature: React.FC<Props> = ({
  res,
  loading,
  actionAfterSignature,
  actionWhenDoctor,
}) => {
  const [sig, setSig] = useState<any>(null);

  const dataURItoBlob = (dataURI: string) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      // mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: 'image/png' });
  }

  const [blob, setBlob] = useState<any>(null);

  const [savePDF] = useDigitalFormSavePDFMutation({});

  // useEffect(() => {
  //   sig && setBlob(dataURItoBlob(sig.getCanvas().toDataURL("image/png")));
  // }, [sig]);
  return (
    <S.Content>
      {loading ? (
        <S.Loader>
          <CircularLoader />
        </S.Loader>
      ) : (
        <>
          <S.Article>Please, put your Signature</S.Article>
          <ReactSignatureCanvas
            backgroundColor="rgba(0,0,0,0)"
            penColor="black"
            canvasProps={{
              width: '400px',
              height: '100px',
              className: 'sigCanvas',
            }}
            ref={ref => setSig(ref)}
          />
          <S.Btns>
            {actionWhenDoctor && (
              <S.ButtonWrapper>
                <Button
                  text="Save without signature"
                  onClick={() =>
                    actionAfterSignature(
                      dataURLtoFile(
                        sig.getCanvas().toDataURL('image/png'),
                        'signatureDoctor',
                      ),
                    )
                  }
                />
              </S.ButtonWrapper>
            )}
            <S.ButtonWrapper>
              <Button
                text="Signature"
                onClick={() => {
                  actionAfterSignature(
                    dataURLtoFile(
                      sig.getCanvas().toDataURL('image/png'),
                      'signatureDoctor',
                    ),
                  );
                }}
              />
            </S.ButtonWrapper>
          </S.Btns>
        </>
      )}
    </S.Content>
  );
};

export default PutSignature;
