import dayjs from 'dayjs';
import { AvatarSVG } from '../../../../../../assets/icons';
import { useSurveyParticipantsGetQuery } from '../../../../../../store/api/survey/surveyApi';
import { SurveyType } from '../../../../../../types/SurveyType';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

type Props = {
  onClose: () => void;
  row: SurveyType;
};

const ShowParticipants: React.FC<Props> = ({ onClose, row }) => {
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
      }
      return;
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  const listParticipants = useSurveyParticipantsGetQuery({
    id: row.id,
    page: page,
  });

  const navigate = useNavigate();

  return (
    <S.Content>
      <S.Article>Survey recipients</S.Article>
      {listParticipants.currentData?.rows.map((patient, index) => {
        const isLast =
          listParticipants.currentData?.rows?.length &&
          index === listParticipants.currentData.rows.length - 1 &&
          page < listParticipants.currentData.lastPage;

        return (
          <S.UserInfo
            key={patient?.userId + index}
            onClick={() => navigate(`/patients/${patient?.userId}`)}
            ref={isLast ? setObserverTarget : undefined}
          >
            <S.User>
              <S.Avatar>
                {patient?.user?.avatar ? (
                  <img src={patient.user.avatar.url} alt="avatar" />
                ) : (
                  <AvatarSVG />
                )}
              </S.Avatar>
              <S.Name>
                {patient?.user?.profile?.firstName} {patient?.user?.profile?.lastName}
              </S.Name>
            </S.User>
            <S.SendVia>
              • Send via {patient?.viaEmail && 'Email'}
              {patient?.viaEmail && patient?.viaPhone && ','}{' '}
              {patient?.viaPhone && 'Phone'}
            </S.SendVia>
            <S.Date>{dayjs(patient.createdAt).format('MM/DD/YYYY h:mm A')}</S.Date>
          </S.UserInfo>
        );
      })}
    </S.Content>
  );
};

export default ShowParticipants;
