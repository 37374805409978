import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from './styles';

const Observation = () => {
  return (
    <>
      <S.Article>Observation</S.Article>
      <S.Article className="px13">Pulse</S.Article>
      <S.Line>
        <S.Caption>Rhythm:</S.Caption>
        <CheckBoxWithLabel label="Regular" />
        <CheckBoxWithLabel label="Irregular" />
        <CheckBoxWithLabel label="Rapid" />
        <CheckBoxWithLabel label="Slow" />
        <CheckBoxWithLabel label="Intermittent" />
      </S.Line>
      <S.Line>
        <S.Caption>Quality:</S.Caption>
        <CheckBoxWithLabel label="Floating" />
        <CheckBoxWithLabel label="Weak" />
        <CheckBoxWithLabel label="Deep" />
        <CheckBoxWithLabel label="Tight Slipper" />
        <CheckBoxWithLabel label="Empty" />
        <CheckBoxWithLabel label="Thin" />
        <CheckBoxWithLabel label="Wiry" />
        <CheckBoxWithLabel label="Others" />
      </S.Line>
      <S.Article className="px13">Trigger point</S.Article>
      <S.Line className="noAlign">
        <S.Caption>Second metacarpal bone</S.Caption>
        <S.BlockColumn>
          <S.Line>
            <S.Caption>Right:</S.Caption>
            <CheckBoxWithLabel label="Normal" />
            <CheckBoxWithLabel label="Upper" />
            <CheckBoxWithLabel label="Mid" />
            <CheckBoxWithLabel label="Lower" />
          </S.Line>
          <S.Line>
            <S.Caption>Left:</S.Caption>
            <CheckBoxWithLabel label="Normal" />
            <CheckBoxWithLabel label="Upper" />
            <CheckBoxWithLabel label="Mid" />
            <CheckBoxWithLabel label="Lower" />
          </S.Line>
        </S.BlockColumn>
      </S.Line>
      <S.Article className="px13">Tongue</S.Article>
      <S.Line>
        <S.Caption>Body:</S.Caption>
        <CheckBoxWithLabel label="Normal" />
        <CheckBoxWithLabel label="Small" />
        <CheckBoxWithLabel label="Big" />
        <CheckBoxWithLabel label="Teeth print" />
      </S.Line>
      <S.Line>
        <S.Caption>Color:</S.Caption>
        <CheckBoxWithLabel label="Normal" />
        <CheckBoxWithLabel label="Purple" />
        <CheckBoxWithLabel label="Purple Spots" />
      </S.Line>
      <S.Line>
        <S.Caption>Coating:</S.Caption>
        <CheckBoxWithLabel label="None" />
        <CheckBoxWithLabel label="White" />
        <CheckBoxWithLabel label="Yellow" />
        <CheckBoxWithLabel label="Greasy" />
        <CheckBoxWithLabel label="Thin" />
        <CheckBoxWithLabel label="Thick" />
        <CheckBoxWithLabel label="Wet" />
        <CheckBoxWithLabel label="Sticky" />
        <CheckBoxWithLabel label="Cracked" />
        <CheckBoxWithLabel label="Dry" />
      </S.Line>
      <S.Line>
        <S.Caption>Local change:</S.Caption>
        <CheckBoxWithLabel label="Warm" />
        <CheckBoxWithLabel label="Cold" />
        <CheckBoxWithLabel label="Swelling" />
        <CheckBoxWithLabel label="Atrophy" />
        <CheckBoxWithLabel label="Deformed" />
        <CheckBoxWithLabel label="Tenderness" />
        <CheckBoxWithLabel label="Trigger points" />
      </S.Line>
    </>
  );
};

export default Observation;
