import { AppointmentWithoutRegistration, Button, Input } from '../../../../../components';
import * as S from './styles';
import { useLoginMutation } from '../../../../../store/api/auth/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../../store/api/auth/authSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { setMessage } from '../../../../../store/slices/message';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { isTypeAuthField } from '../../../../../helpers/isTypeAuthField';
import { AuthFieldType } from '../../../../../types/enums/AppointmentEnum';
import Auth from '../../../Auth';
import ReactInputMask from 'react-input-mask';
import { TextFieldProps } from '@mui/material';

type FormValues = {
  authField: string;
  password: string;
};

const Login = () => {
  const [authFieldType, setAuthFieldType] = useState<AuthFieldType>(AuthFieldType.PHONE);

  const LoginSchema = yup.object().shape({
    authField:
      authFieldType === AuthFieldType.PHONE
        ? yup
            .string()
            .matches(/^\+[1-9]\d{1,14}$/, 'International format required: +1xxxxxxxxxx')
            .min(12, 'International format required: +1xxxxxxxxxx')
            .max(12, 'International format required: +1xxxxxxxxxx')
            .required('Is required')
        : yup
            .string()
            .matches(
              /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
              'Set email in valid format',
            )
            .required('Is required'),
    password: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .required('Is required'),
  });
  const { values, handleChange, handleSubmit, errors, setFieldValue, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        authField: '',
        password: '',
      },
      validateOnChange: false,
      validationSchema: LoginSchema,
      onSubmit: async ({ password, authField }) => {
        await login({ authField, password, authFieldType })
          .unwrap()
          .then(res => {
            dispatch(setUser(res.payload));
          })
          .catch(error => {
            dispatch(setMessage({ message: error.data.message, type: 'error' }));
          });
      },
    });

  useEffect(() => {
    values.authField && setAuthFieldType(isTypeAuthField(values.authField));
  }, [values.authField]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login] = useLoginMutation();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <S.LoginWrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.Inputs>
          <Input
            id="authField"
            name="authField"
            onChange={handleChangeInput}
            value={values.authField}
            error={!!errors.authField}
            helperText={errors.authField}
            label="Phone or Email"
            placeholder="+1xxxxxxxxxx or xxx@xxx.com"
          />
          <Input
            id="password"
            name="password"
            value={values.password}
            onChange={handleChangeInput}
            error={!!errors.password}
            helperText={errors.password}
            label="Password"
            isPassword
          />
        </S.Inputs>
        <S.Buttons>
          <S.ButtonWrapper>
            <Button
              text="Submit"
              type="submit"
              onClick={() => {
                values.authField.length === 11 &&
                  values.authField[0] === '1' &&
                  authFieldType === AuthFieldType.PHONE &&
                  setFieldValue('authField', '+' + values.authField);

                values.authField.length === 10 &&
                  authFieldType === AuthFieldType.PHONE &&
                  values.authField[0] !== '+' &&
                  setFieldValue('authField', '+1' + values.authField);
              }}
            />
          </S.ButtonWrapper>
          <AppointmentWithoutRegistration />
          <S.ResetLink onClick={() => navigate('/resetpassword')}>
            Reset password?
          </S.ResetLink>
        </S.Buttons>
      </form>
    </S.LoginWrapper>
  );
};

export default Login;
