import styled, { css } from 'styled-components';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div<{ $active: boolean }>`
  background: #ffffff;
  cursor: pointer;
  width: 100%;
  min-height: 74px;
  border: 0.5px solid var(--line, #d7d7d7);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  pointer-events: ${props => (props.$active ? 'all' : 'none')};
  opacity: ${props => (props.$active ? '1' : '0.5')};

  overflow: hidden;

  @media ${devices.xs} {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const Content = styled.div`
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

export const ArticleWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 20px; */
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const RightBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectInputWrapper = styled.div`
  width: 300px;
`;

export const WorkTime = styled.div`
  display: flex;
  gap: 20px;
  width: 300px;
  align-items: center;

  .line {
    margin-top: 20px;
  }
`;

export const ManageBtn = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const EditBtns = styled.div`
  margin-right: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 3px;

  & svg {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.bigger {
      width: 28px;
      height: 28px;
    }
  }
`;

export const ApproveBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`;
