import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const SubtitleItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 10px;
  height: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 180px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background: #fff;
  height: 100%;
`;

export const FormContent = styled.div`
  background: #fff;
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const Form = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 15px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  height: 40px;
  min-height: 40px;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%);
`;
