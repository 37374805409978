import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Content = styled.div`
  height: auto;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px 20px 30px;

  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 20px;
  }
`;

export const InputItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 20px;
`;

export const SearchContent = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  width: 560px;
  overflow: hidden;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 5px;
`;

export const SearchBtn = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
  line-height: normal;
  justify-content: center;

  svg {
    path {
      stroke: #0084b1;
    }
    ellipse {
      stroke: #0084b1;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Placeholder = styled.div`
  color: var(--text, #8d93ae);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: -20px;
`;

export const ResetFilter = styled.div`
  display: flex;
  align-self: flex-end;

  cursor: pointer;
  gap: 5px;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
  line-height: normal;
  justify-content: center;
  padding-right: 15px;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 400px;
`;

export const Article = styled.div`
  margin-top: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const SubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 450px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const SearchBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  align-self: end;
  margin-top: 6px;
  margin-right: 10px;
  cursor: pointer;
`;
