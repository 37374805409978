import dayjs from 'dayjs';
import * as S from '../../../styles';
import { HoldCard, UserStatusPin } from '../../../../../../../components';
import { AvatarSVG } from '../../../../../../../assets/icons';
import { Clinics } from '../../../../../../../types/ClinicTypes';

type Props = {
  row: any;
  header: string;
};

const RefProvidersData: React.FC<Props> = ({ row, header }) => {
  switch (header) {
    case 'Name': {
      return <S.CellWrapper>{row?.firstName ? row?.firstName : '-'}</S.CellWrapper>;
    }
    case 'Last Name': {
      return <S.CellWrapper>{row?.lastName ? row?.lastName : '-'}</S.CellWrapper>;
    }
    default:
      return row[header] || '-';
  }
};

export default RefProvidersData;
