import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const DocumentsWrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  height: 100%;
  margin-right: 15px;

  @media ${devices.xs} {
    background: none;
    min-height: 100vh;
  }
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  user-select: none;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 7px;

  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 30px;
  margin-top: 30px;

  .svg {
    width: 18px;
    height: 18px;
  }
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const BlockContent = styled.div`
  margin-top: 24px;
  margin-right: 12px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 22px;
`;

export const EmptyBtn = styled.div`
  width: 20px;
`;

export const Content = styled.div`
  margin-left: 23px;
  display: flex;
  flex-direction: column;
`;

export const Files = styled.div`
  margin-top: 27px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Row = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0px 19px 14px;
  cursor: pointer;
`;

export const RowText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const DateDiv = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 30px;
  align-items: center;
  margin-right: 16px;
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  svg {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
`;

export const TestClick = styled.div`
  cursor: pointer;

  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 30px;
  margin-top: 30px;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const AccordeonContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const AccordeonItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
`;

export const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  cursor: pointer;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & .icon {
    width: 14px;
    height: 14px;
    margin-left: 9px;
  }

  & .delete {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-right: 9px;
  }
`;

export const CreatedBy = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Text = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 200px;
  flex-wrap: wrap;
  line-height: normal;

  &.small {
    width: 130px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  max-width: 500px;
  word-wrap: break-word;

  svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  @media ${devices.xs} {
    width: 300px;
  }
`;
