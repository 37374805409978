import { useEffect, useMemo, useRef, useState } from 'react';
import { AddIconSVG } from '../../../assets/icons';
import CircularLoader from '../../atoms/CircuralLoader';
import LinearLoader from '../../atoms/LinearLoader';
import * as S from './styles';
import { Document, Page, pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
//@ts-ignore
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import {
  BlobProvider,
  PDFDownloadLink,
  StyleSheet,
  Text,
  usePDF,
  View,
} from '@react-pdf/renderer';
import Button from '../../atoms/Button';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { Document as DocumentRender, Page as PageRender } from '@react-pdf/renderer';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

type Props = {
  file: string;
  loading?: boolean;
};

const PDFDrawer: React.FC<Props> = ({ file, loading }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const ref = useRef<any>();

  const [blob, setBlob] = useState<Blob | null>(null);

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const styles = StyleSheet.create({
    page: {
      width: '100%', // set the width to 100%
    },
  });

  return (
    <S.Wrapper>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={index + 1}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </S.Wrapper>
  );
};

export default PDFDrawer;
