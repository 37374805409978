import { FormikProps } from 'formik';
import { Input, SelectInput } from '../../../../../../../../components';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';
import * as S from '../../styles';
import { ChangeEvent, forwardRef } from 'react';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { SelectChangeEvent } from '@mui/material';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  setIsDirty?: () => void;
};
const Pain = forwardRef<Record<string, unknown>, Props>(({ formik, setIsDirty }, ref) => {
  return (
    <>
      <S.Article>Pain</S.Article>
      <S.Line>
        <S.InputWrapper>
          <SelectInput
            label="Location"
            defaultValue={formik.values?.pain.location.lumbar}
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleChangeInputRef(
                e as ChangeEvent<HTMLInputElement>,
                'pain.location.lumbar',
                ref,
                setIsDirty,
              )
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="Intensity"
            isSum="/10"
            defaultValue={formik.values?.pain.intensity}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.intensity', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="Frequency"
            defaultValue={formik.values?.pain.frequency}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.frequency', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper>
          <Input
            label="Worse with"
            defaultValue={formik.values?.pain.worseWith}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.worseWith', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="Better with"
            defaultValue={formik.values?.pain.betterWith}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.betterWith', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <Input
          label={'Prior History'}
          defaultValue={formik.values?.pain.priorHistory}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'pain.priorHistory', ref, setIsDirty)
          }
        />
      </S.Line>
      <S.Line>
        <S.InputWrapper>
          <Input
            label="Frequency"
            defaultValue={formik.values?.pain.frequency}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.frequency', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <Input
          label={'Exercise History'}
          defaultValue={formik.values?.pain.exetciseHistory}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'pain.exetciseHistory', ref, setIsDirty)
          }
        />
      </S.Line>
      <S.Line>
        <S.InputWrapper>
          <Input
            label="Posture"
            defaultValue={formik.values?.pain.postune}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.postune', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="ROM"
            defaultValue={formik.values?.pain.rom}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.rom', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="STRENGTH"
            defaultValue={formik.values?.pain.strength}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'pain.strength', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
    </>
  );
});

export default Pain;
