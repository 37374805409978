export enum AccessLevel {
  READ = 'read',
  WRITE = 'write',
}

export enum ClinicInsuranceType {
  IN_NETWORK = 'IN_NETWORK',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
}

export enum InvoiceType {
  SHEET = 'sheet',
  PRE_PAYMENT = 'prePayment',
  POST_PAYMENT = 'postPayment',
}

export enum Relation {
  SELF = 'self',
  SPOUSE = 'spouse',
  CHILD = 'child',
  OTHER = 'other',
}

export enum AuthFieldType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum ModeEnum {
  WEEK = 'Week',
  DAY = 'Day',
  MONTH = 'Month',
  YEAR = 'Year',
}
export type CoveredServicesInformation = {
  count: number;
  insuranceInfo: {
    id: string;
    startDate: string | null;
    endDate: string | null;
    insuranceGroupNumberIds: string[];
    isPrimary?: boolean;
  };
};

export type CoveredServicesVR = {
  service: string;
  typeServiceId: string;
  coveredService: Array<CoveredServicesInformation>;
};
