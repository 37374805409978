import { useState } from 'react';
import { DashboardCard } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppointmentsGetQuery } from 'store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from 'use-debounce';
import { getFirstAndLastDate } from 'helpers/functions/getFirstAndLastDate';
import { DatesFilter } from 'pages/Receptionist/components/Dashboard/components/DatesFilter';

const StatisticHeaderBar = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).firstDate,
  );
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).lastDate,
  );

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  const visits = useAppointmentsGetQuery({
    clinicId: selectedClinic.id,
    startDate: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
    endDate: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
  });

  return (
    <S.ContainerWrapper>
      <S.DashboardFilterWrapper>
        <S.Article>Appointments</S.Article>
        <DatesFilter
          firstDate={firstDate}
          secondDate={secondDate}
          setFirstDate={setFirstDate}
          setSecondDate={setSecondDate}
          isLoading={visits.isFetching || visits.isLoading}
          firstLabel={'Date from'}
          secondLabel={'Date to'}
          withLabel
        />
      </S.DashboardFilterWrapper>
      <S.DashboardItems>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={!!(visits.isFetching || visits.isLoading)}
            article="Total Appointments"
            number={visits.currentData?.total || 0}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={!!(visits.isFetching || visits.isLoading)}
            article="Active Appointments"
            number={visits.currentData?.totalActive || 0}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={!!(visits.isFetching || visits.isLoading)}
            article="Completed Appointments"
            number={visits.currentData?.totalCompleted || 0}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={!!(visits.isFetching || visits.isLoading)}
            article="Canceled Appointments"
            number={visits.currentData?.totalCanceled || 0}
          />
        </S.DashboardCardWrapper>
      </S.DashboardItems>
    </S.ContainerWrapper>
  );
};

export default StatisticHeaderBar;
