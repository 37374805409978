import React from 'react';
import { ClaimErrorHistoryType } from 'types/ClaimTypes';
import * as S from '../DialogModifiedInformation/styles';
import dayjs from 'dayjs';
import { CustomTable } from 'components';
import { TableCell, TableRow } from '@mui/material';
import { ucFirst } from 'helpers/functions/toUpperCase';

type Props = {
  claimError?: ClaimErrorHistoryType;
};

const commonHeaders = [
  { name: 'Rule Information', key: 'info' },
  { name: '', key: '' },
];

const messageHeaders = [{ name: 'Message', key: 'message' }];

export const DialogErrorInformation = ({ claimError }: Props) => {
  return (
    <S.Content>
      <S.Article>{ucFirst(claimError?.field?.split('.')?.at(-1) ?? '')}</S.Article>
      <S.TableWrapper>
        <CustomTable headers={commonHeaders}>
          <TableRow className={'notEdit'}>
            <TableCell>Name</TableCell>
            <TableCell>{claimError?.field}</TableCell>
          </TableRow>
          <TableRow className={'notEdit'}>
            <TableCell>Date Applied</TableCell>
            <TableCell>
              {dayjs(claimError?.createdAt).format('MM/DD/YY h:mm:ss A')}
            </TableCell>
          </TableRow>
        </CustomTable>
      </S.TableWrapper>
      <S.TableWrapper>
        <CustomTable headers={messageHeaders}>
          <TableRow className={'notEdit'}>
            <TableCell>{claimError?.description}</TableCell>
          </TableRow>
        </CustomTable>
      </S.TableWrapper>
    </S.Content>
  );
};
