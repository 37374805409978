import { api } from 'store/api/api';
import { ServiceType } from 'types/ServicesType';
import { setServicesByClinic } from 'store/slices/selectedClinic';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const servicesApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllServices: builder.query<Response<ServiceType>, any>({
      query: ({ ...params }) => ({
        url: `/services`,
        params: { ...params },
        method: 'GET',
      }),
      providesTags: ['Services'],
    }),
    getServicesByClinic: builder.query<Response<ServiceType>, any>({
      query: ({ ...params }) => ({
        url: `/services`,
        params: { ...params },
        method: 'GET',
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setServicesByClinic({ services: data?.rows }));
      },
      providesTags: ['ServicesByClinic'],
    }),
    getServicesByClinicWithoutStore: builder.query<Response<ServiceType>, any>({
      query: ({ ...params }) => ({
        url: `/services`,
        params: { ...params },
        method: 'GET',
      }),
    }),
    createService: builder.mutation({
      query: ({ ...body }) => ({
        url: `/services`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Services', 'ServicesByClinic'],
    }),
    updateService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/services/${id}`,
        body: { ...body },
        method: 'PATCH',
      }),
      invalidatesTags: ['Services', 'ServicesByClinic'],
    }),
    assignService: builder.mutation({
      query: ({ ...body }) => ({
        url: `/services/assign-to-clinic`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Services', 'ServicesByClinic'],
    }),
    holdService: builder.mutation({
      query: ({ ...body }) => ({
        url: `/services/hold`,
        body: { ...body },
        method: 'PATCH',
      }),
      invalidatesTags: ['Services', 'ServicesByClinic'],
    }),
    deleteService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/services/${id}`,
        body: { ...body },
        method: 'DELETE',
      }),
      invalidatesTags: ['Services'],
    }),
    getServiceById: builder.mutation<ServiceType, any>({
      query: ({ id, ...params }) => ({
        url: `/services/${id}`,
        params: { ...params },
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllServicesQuery,
  useLazyGetServicesByClinicQuery,
  useLazyGetServicesByClinicWithoutStoreQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useAssignServiceMutation,
  useHoldServiceMutation,
  useDeleteServiceMutation,
  useGetServiceByIdMutation,
} = servicesApi;
