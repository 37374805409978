import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  CheckBox,
  LinearLoader,
  SelectInput,
  WorkSchedule,
} from '../../../../../../../../components';
import SchedBtn from '../../../../../../../../components/organismus/WorkSchedule/components/SchedBtn';
import * as S from './styles';
import { HorizontalLineSVG } from '../../../../../../../../assets/icons';
import {
  useClinicScheduleCreateMutation,
  useClinicScheduleGetQuery,
  useClinicScheduleUpdateMutation,
} from '../../../../../../../../store/api/clinic-schedule/clinicScheduleApi';
import { useParams } from 'react-router-dom';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { constantTimePickerOUT } from '../../../../../../../../constants/constants';
import { setMessage } from '../../../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';

type Props = {
  onClose: () => void;
};

const ClinicScheduleDialog: React.FC<Props> = ({ onClose }) => {
  const textWeekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [choosedWeekDay, setChoosedWeekDay] = useState(0);

  const params = useParams();

  const getSchedule = useClinicScheduleGetQuery({
    id: params.clinicId,
    sort: 'weekDay',
    asc: 'ASC',
  });

  const [currentSched, setCurrentSched] = useState<Array<any>>([]);
  const [schedToServer, setSchedToServer] = useState<Array<any>>([]);

  useEffect(() => {
    setCurrentSched([]);
    if (getSchedule.currentData?.count) {
      getSchedule.currentData.rows.map(item => {
        setCurrentSched((prevState: Array<any>) => [
          ...prevState,
          {
            id: item.id,
            weekDay: item.weekDay,
            isWorkDay: item.isWorkDay,
            workingHoursStart: item.workingHoursStart
              ? item.workingHoursStart.slice(0, 5)
              : constantTimePickerOUT[0].value,
            workingHoursEnd: item.workingHoursEnd
              ? item.workingHoursEnd.slice(0, 5)
              : constantTimePickerOUT[constantTimePickerOUT.length - 1].value,
          },
        ]);
      });
    } else {
      textWeekDays.map((item, index) => {
        setCurrentSched((prevState: Array<any>) => [
          ...prevState,
          {
            weekDay: index,
            isWorkDay: false,
            workingHoursStart: constantTimePickerOUT[0].value,
            workingHoursEnd:
              constantTimePickerOUT[constantTimePickerOUT.length - 1].value,
          },
        ]);
      });
    }
  }, [getSchedule]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSchedToServer([]);
    currentSched.map(item => {
      if (item.isWorkDay) {
        setSchedToServer(prevState => [
          ...prevState,
          { ...item, clinicId: params.clinicId },
        ]);
      } else {
        setSchedToServer(prevState => [
          ...prevState,
          {
            id: item.id,
            workingHoursStart: null,
            workingHoursEnd: null,
            isWorkDay: false,
            clinicId: params.clinicId,
            weekDay: item.weekDay,
          },
        ]);
      }
    });
  }, [currentSched]);

  const [createSched] = useClinicScheduleCreateMutation({});
  const [updateSched] = useClinicScheduleUpdateMutation({});

  const handleSaveSched = () => {
    createSched({ schedule: schedToServer })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Schedule was successfully created',
            type: 'success',
          }),
        );
        onClose();
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleUpdateSched = () => {
    onClose();
    updateSched({ schedule: schedToServer })
      .unwrap()
      .then(res => {
        onClose();
        dispatch(
          setMessage({
            message: 'Schedule was successfully updated',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <S.Content>
      <S.Article>Change Schedule settings</S.Article>
      <S.SubArticle>Here you can edit current clinic default schedule </S.SubArticle>
      {getSchedule.isLoading || getSchedule.isFetching ? (
        <LinearLoader />
      ) : (
        <>
          <S.SchedLine>
            {textWeekDays.map((item, index) => {
              const res = currentSched.find((row: any) => {
                return index === row.weekDay;
              });
              return (
                <S.SchedBtnWrapper
                  key={item + index}
                  onClick={() => setChoosedWeekDay(index)}
                >
                  <SchedBtn
                    // disabled={
                    //   getSchedOneDay.isFetching || getSchedOneDay.isLoading
                    //     ? true
                    //     : false
                    // }
                    text={item}
                    active={choosedWeekDay === index}
                    isWorkDay={res && res.isWorkDay ? true : false}
                  />
                </S.SchedBtnWrapper>
              );
            })}
          </S.SchedLine>
          <S.ArticleWrap>
            <S.Article14>Is it day off day?</S.Article14>
            <CheckBox
              id="isWorkDay"
              name="isWorkDay"
              checked={!currentSched[choosedWeekDay]?.isWorkDay || false}
              value={!currentSched[choosedWeekDay]?.isWorkDay || false}
              onChange={() => {
                const newPosts = currentSched.map((sched, index) => {
                  if (index === choosedWeekDay) {
                    return { ...sched, isWorkDay: !sched.isWorkDay };
                  } else return sched;
                });
                setCurrentSched(newPosts);
              }}
            />
          </S.ArticleWrap>
          {currentSched[choosedWeekDay]?.isWorkDay && (
            <S.WorkingHours>
              <S.Article14>Working Hours:</S.Article14>
              <S.InputRow>
                <SelectInput
                  label="Start"
                  value={currentSched[choosedWeekDay]?.workingHoursStart || ''}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const newTime = currentSched.map((time, index) => {
                      if (index === choosedWeekDay) {
                        return {
                          ...time,
                          workingHoursStart: e.target.value as string,
                        };
                      } else return time;
                    });
                    setCurrentSched(newTime);
                  }}
                >
                  {constantTimePickerOUT.map(time => (
                    <MenuItem key={time.name} value={time.value}>
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>

                <HorizontalLineSVG className="line" />
                <SelectInput
                  label="End"
                  value={currentSched[choosedWeekDay]?.workingHoursEnd || ''}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    const newTime = currentSched.map((time, index) => {
                      if (index === choosedWeekDay) {
                        return {
                          ...time,
                          workingHoursEnd: e.target.value as string,
                        };
                      } else return time;
                    });
                    setCurrentSched(newTime);
                  }}
                >
                  {constantTimePickerOUT.map(time => (
                    <MenuItem key={time.name} value={time.value}>
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.InputRow>
            </S.WorkingHours>
          )}
          <S.ButtonWrapper>
            <Button
              text={
                getSchedule.currentData?.count
                  ? 'Save updates'
                  : 'Create default schedule'
              }
              onClick={
                getSchedule.currentData?.count ? handleUpdateSched : handleSaveSched
              }
            />
          </S.ButtonWrapper>
        </>
      )}
    </S.Content>
  );
};

export default ClinicScheduleDialog;
