import { MenuItem, SelectChangeEvent } from '@mui/material';
import { CloseBtnSVG, CloseSVG, FilterSVG } from '../../../../../../assets/icons';
import { Button, CheckBox, DatePicker, SelectInput } from '../../../../../../components';
import * as S from './styles';
import { ucFirst } from '../../../../../../helpers/functions/toUpperCase';
import { userStatuses } from '../../../../../../constants/constants';
import CheckBoxWithLabel from '../../../../../../components/atoms/CheckboxWithLabel';
import {
  FilterCounterValuesType,
  FilterValuesType,
} from '../../../../../../helpers/FilterSearch/filterSearch';
import { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  isLoading: boolean;
  onClose: () => void;
  setDOBRange: React.Dispatch<React.SetStateAction<string>>;
  DOBRange: string;
  filterValues: FilterValuesType;
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValuesType>>;
  setCounterFilterValues: React.Dispatch<React.SetStateAction<FilterCounterValuesType>>;
  counterFilterValues: FilterCounterValuesType;
};

const FilterContent = ({
  isLoading,
  onClose,
  DOBRange,
  setDOBRange,
  filterValues,
  setFilterValues,
  setCounterFilterValues,
  counterFilterValues,
}: Props) => {
  const [filterStatus, setFilterStatus] = useState<any>(userStatuses);

  const [startDOB, setStartDOB] = useState('');
  const [endDOB, setEndDOB] = useState('');
  const [lastVisitStart, setLastVisitStart] = useState('');
  const [lastVisitEnd, setLastVisitEnd] = useState('');
  const [isShowAllPatients, setIsShowAllPatients] = useState(false);
  const [sortLastVisitDate, setSortLastVisitDate] = useState('');

  const handleChangeSelect = (event: SelectChangeEvent<typeof filterStatus>) => {
    const {
      target: { value },
    } = event;
    setFilterStatus(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSetFilters = () => {
    setFilterValues({
      ...filterValues,
      serverIsShowAllPatients: isShowAllPatients,
      serverStatus: filterStatus,
      serverExactDOB: DOBRange === 'ExactDate' ? startDOB : '',
      serverStartDOB: DOBRange === 'Range' ? startDOB : '',
      serverEndDOB: DOBRange === 'Range' ? endDOB : '',
      serverSortLastVisit: sortLastVisitDate,
      serverEndLastVisit: lastVisitEnd,
      serverStartLastVisit: lastVisitStart,
    });

    onClose();
  };

  const handleResetFilters = () => {
    setFilterValues({
      ...filterValues,
      serverIsShowAllPatients: false,
      serverStatus: [],
      serverExactDOB: '',
      serverStartDOB: '',
      serverEndDOB: '',
      serverSortLastVisit: '',
      serverEndLastVisit: '',
      serverStartLastVisit: '',
    });

    onClose();
  };

  useEffect(() => {
    setIsShowAllPatients(filterValues.serverIsShowAllPatients);
    setFilterStatus(filterValues.serverStatus);
    setStartDOB(
      DOBRange === 'ExactDate'
        ? filterValues.serverExactDOB
        : filterValues.serverStartDOB,
    );
    setEndDOB(filterValues.serverEndDOB);
    setSortLastVisitDate(filterValues.serverSortLastVisit);
    setLastVisitEnd(filterValues.serverEndLastVisit);
    setLastVisitStart(filterValues.serverStartLastVisit);
  }, [filterValues]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.ArticleWrap>
          Filter
          <CloseBtnSVG onClick={onClose} />
        </S.ArticleWrap>
        <S.Line>
          <CheckBoxWithLabel
            label="Show all patients in clinics"
            value={isShowAllPatients}
            checked={isShowAllPatients}
            onChange={() => setIsShowAllPatients(!isShowAllPatients)}
          />
        </S.Line>
        <S.SelectInputWrapper>
          <SelectInput
            label="Status"
            multiple
            disabled={isLoading}
            value={filterStatus}
            renderValue={(selected: any) => (
              <S.SelectInputOutput>
                <FilterSVG color="#848A9B" />
                <S.SelectInputOutputText>
                  {selected.length === 0 || selected.length === userStatuses.length
                    ? 'All'
                    : selected.join(', ').toUpperCase()}
                </S.SelectInputOutputText>
              </S.SelectInputOutput>
            )}
            onChange={handleChangeSelect}
          >
            {userStatuses.map((status: any) => (
              <MenuItem key={status} value={status} disabled={isLoading}>
                <S.MenuItemContent>
                  <CheckBox checked={filterStatus.indexOf(status) > -1} />
                  {ucFirst(status)}
                </S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.SelectInputWrapper>
        <S.SelectInputWrapper>
          <SelectInput
            label="DOB"
            value={DOBRange}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setEndDOB('');
              setDOBRange(e.target.value as string);
            }}
          >
            <MenuItem value={'ExactDate'} disabled={isLoading}>
              <S.MenuItemContent>Exact Date</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'Range'} disabled={isLoading}>
              <S.MenuItemContent>Range</S.MenuItemContent>
            </MenuItem>
          </SelectInput>
          <S.Line>
            <DatePicker
              label={''}
              shouldDisableDate={date => {
                if (endDOB && date > endDOB) {
                  return true;
                } else {
                  return false;
                }
              }}
              disabled={DOBRange === ''}
              value={startDOB}
              onChange={value => setStartDOB(value)}
            />
            <DatePicker
              shouldDisableDate={date => {
                if (startDOB && date < startDOB) {
                  return true;
                } else {
                  return false;
                }
              }}
              label={''}
              disabled={DOBRange === '' || DOBRange === 'ExactDate'}
              value={endDOB}
              onChange={value => setEndDOB(value)}
            />
          </S.Line>
        </S.SelectInputWrapper>
        <SelectInput
          label="Sort Last visit"
          value={sortLastVisitDate}
          onChange={(e: SelectChangeEvent<unknown>) =>
            setSortLastVisitDate(e.target.value as string)
          }
        >
          <MenuItem value={'ASC'} disabled={isLoading}>
            <S.MenuItemContent>Ascending</S.MenuItemContent>
          </MenuItem>
          <MenuItem value={'DESC'} disabled={isLoading}>
            <S.MenuItemContent>Descending</S.MenuItemContent>
          </MenuItem>
        </SelectInput>
        <S.SelectInputWrapper>
          <S.Text>Show patients with last visit in:</S.Text>
          <S.Line>
            <DatePicker
              label={''}
              value={lastVisitStart}
              onChange={value => setLastVisitStart(value)}
            />
            <DatePicker
              label={''}
              value={lastVisitEnd}
              onChange={value => setLastVisitEnd(value)}
            />
          </S.Line>
        </S.SelectInputWrapper>
      </S.Content>
      <S.Footer>
        <S.Btns>
          <S.ButtonWrapper>
            <Button text="Reset" backgroundColor="#848A9B" onClick={handleResetFilters} />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button text="Apply" onClick={handleSetFilters} />
          </S.ButtonWrapper>
        </S.Btns>
      </S.Footer>
    </S.Wrapper>
  );
};

export default FilterContent;
