import * as S from './styles';
import { AddHealthRecordSVG } from 'assets/icons';
import { useState } from 'react';
import { getStatusColor } from 'helpers/getStatusColor';
import { Event } from 'react-big-calendar';
import { EventView } from 'types/AppointmentsTypes';
import NotificationPopover from 'components/organismus/NotificationPopover';
import Fade from '@mui/material/Fade';
import { bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverInformation from './HoverInformation';
import { useAppSelector } from 'hooks/useAppSelector';
import ClickEventMenu from './ClickEventMenu';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Roles } from 'types/Roles';

type Props = {
  type: string;
  time: string;
  status: string;
  isFirstTimeVisit: boolean;
  fullEvent: Event;
  setOpenDialog: React.Dispatch<React.SetStateAction<Event | null>>;
};

const CalendarEvent: React.FC<Props> = ({
  type,
  time,
  status,
  isFirstTimeVisit,
  fullEvent,
  setOpenDialog,
}) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const currentService = selectedClinic.services?.find(
    i => i.id === fullEvent?.resource?.visit?.typeServiceId,
  );
  const me = useAppSelector(state => state.auth);

  const statusColor = getStatusColor(status).status;

  const [isHover, setIsHover] = useState(false);

  const currentEvent = fullEvent as EventView;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);

  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  return (
    <>
      <S.MainWrapper {...bindHover(popupState)}>
        <HoverPopover
          className="rr"
          TransitionProps={{ timeout: 200 }}
          TransitionComponent={Fade}
          transitionDuration={1500}
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: -10,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <HoverInformation event={currentEvent} />
        </HoverPopover>
        <S.EventWrapper
          background={
            fullEvent?.resource?.isFirstTimeVisit &&
            currentService?.isUseForFirstTimeVisit &&
            currentService?.firstVisitColor
              ? currentService?.firstVisitColor
              : (currentService?.color ?? 'transparent')
          }
          border={isHover ? '#0084B1' : '#848A9B'}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover && me.role !== Roles.BILLING && (
            <>
              <S.ButtonAdd onClick={handleAnchorReport}>
                <AddHealthRecordSVG />
                <NotificationPopover
                  open={Boolean(anchorElReport)}
                  anchorEl={anchorElReport}
                  onClose={() => {
                    setAnchorElReport(null);
                  }}
                >
                  <ClickEventMenu
                    setOpenDialog={setOpenDialog}
                    visit={fullEvent.resource?.visit}
                    appointment={fullEvent.resource?.selectedEvent}
                    fullEvent={fullEvent}
                  />
                </NotificationPopover>
              </S.ButtonAdd>
            </>
          )}
          <S.SmallContent>
            <S.StatusBar className="small" status={statusColor} />
            <S.ContentWrap>
              <S.Type>{fullEvent?.resource?.type ?? '-'}</S.Type>
            </S.ContentWrap>
          </S.SmallContent>
        </S.EventWrapper>
      </S.MainWrapper>
    </>
  );
};

export default CalendarEvent;
