import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

interface DownloadPdfResponse {
  data: Blob;
}

export const debugApi = api.injectEndpoints({
  endpoints: builder => ({
    getTestPDF: builder.mutation({
      query: ({}) => ({
        url: `/invoice/3/pdf?type=sheet`,
        responseHandler: async (response: any) => {
          const blobItem = await response.blob();
          const blobURL = URL.createObjectURL(blobItem);
          return blobURL;
        },
      }),
    }),
    downloadTestPDF: builder.query<DownloadPdfResponse, void>({
      query: () => ({
        url: `/invoice/3/pdf?type=sheet`,
        method: 'GET',
        responseType: 'blob',
      }),
    }),
  }),
});

export const { useGetTestPDFMutation } = debugApi;
