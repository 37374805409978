import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  width: 100%;
`;
export const EmptyDiv = styled.div``;

export const DatePickerItem = styled(DatePicker)`
  border-radius: 5px;
  width: 100%;

  &.isCell {
    & .Mui-focused {
      border-bottom: 0.5px solid var(--line, #0084b1) !important;

      &.MuiFormHelperText-root {
        border: none !important;
      }
    }

    & .MuiButtonBase-root {
      &.Mui-selected {
        //background-color: #0084b1;
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiInputBase-root {
      border: none;
      text-overflow: ellipsis;
      background: none !important;
      border-bottom: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 0px;
      padding: 9px 14px;
      line-height: normal;
      height: none;
      color: var(--text, #202e5f);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::before {
        border: none !important;
      }
      &::after {
        border: none !important;
      }

      & :focus {
        background: none !important;
      }
    }

    & .MuiSelect-select {
      padding: 0;

      & :focus {
        background: none;
      }
    }
  }

  & .Mui-focused {
    border: 0.5px solid var(--line, #0084b1);

    &.MuiFormHelperText-root {
      border: none !important;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    height: none;
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  &.MuiOutlinedInput {
  }
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FormHelperText = styled.div`
  & .MuiFormHelperText-root {
    color: #d32f2f;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
`;
