import styled, { css } from 'styled-components';

export const StatusPinWrapper = styled.div<{
  background: string;
  border: string;
  font: string;
}>`
  border-radius: 5px;
  height: 20px;
  padding: 2px 10px;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${props =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `}
  ${props =>
    props.font &&
    css`
      color: ${props.font};
    `}
`;
