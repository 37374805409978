import { useDispatch } from 'react-redux';
import { NoPatientPlaceHolderSVG } from '../../../../../../assets/icons';
import { Button } from '../../../../../../components';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import * as S from './styles';
import { DrawerMode } from '../../../../../../types/DrawerMode';

const NoPatients = () => {
  const dispatch = useDispatch();
  return (
    <S.NoPatientsWrapper>
      <S.Article>Patient has not been found</S.Article>
      <NoPatientPlaceHolderSVG />
      <S.ButtonWrapper>
        <Button
          text="Add Patient"
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.ADD_PATIENTS,
                props: null,
              }),
            )
          }
        />
      </S.ButtonWrapper>
    </S.NoPatientsWrapper>
  );
};

export default NoPatients;
