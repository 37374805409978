import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #f9f9fc;
  border-radius: 10px;
  width: 502px;
  border: 0.5px solid var(--line, #d7d7d7);
`;

export const ArticleWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 10px 10px 15px 15px;
`;

export const Receptionist = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;

export const Avatar = styled.div`
  img {
    width: 18px;
    border-radius: 18px;
    height: 18px;
    object-fit: cover;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Pin = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ReceptionistName = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Dots = styled.div`
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const SecondBlock = styled.div`
  color: var(--text, #202e5f);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 33px;
  width: 100%;
`;

export const ClinicName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DateCreate = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ThirdBlock = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  width: 100%;
  gap: 5px;
`;

export const Text = styled.div`
  width: 440px;
  max-width: 440px;
  word-wrap: break-word;
  margin-top: 1px;
`;

export const PopoverWrapper = styled.div`
  border-radius: 5px;
  background: var(--icon, #848a9b);
  width: 98px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div`
  padding: 11px 30px 11px 13px;
  width: 100%;
  cursor: pointer;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: #e5f0f6;
  }
`;
