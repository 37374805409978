import { useEffect } from 'react';
import { useAppSelector } from '../hooks/useAppSelector';
import Auth from '../pages/Auth';
import { setUser } from '../store/api/auth/authSlice';
import { UserStatus } from '../types/UserStatuses';
import RoleWrapper from './RoleWrapper';
import { useDispatch } from 'react-redux';
import { useAuthMeMutation } from '../store/api/auth/authApi';
import { CircularProgress, LinearProgress } from '@mui/material';

const StatusWrapper = () => {
  const status = useAppSelector(state => state.auth.status);

  const [authMe] = useAuthMeMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    authMe({})
      .unwrap()
      .then(res => {
        dispatch(setUser(res));
      })
      .catch(error => {
        dispatch(setUser({ status: UserStatus.BUFFER }));
      });
  }, []);

  switch (status) {
    case UserStatus.UNVERIFIED:
      return <Auth />;
    case UserStatus.BUFFER:
      return <Auth />;
    case UserStatus.VERIFIED_AUTH_FIELD:
      return <Auth />;
    case UserStatus.VERIFIED_FIELD_DATA:
      return <RoleWrapper />;
    case UserStatus.VERIFIED_FULL:
      return <RoleWrapper />;
    default:
      return <LinearProgress />;
  }
};

export default StatusWrapper;
