import React from 'react';
import { Dialog, SuccessDialog } from '../components';
import { setIsDirty } from '../store/slices/isDirtyFormSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useDigitalFormUpdateMutation } from '../store/api/templates/templateApi';

const DialogUnsavedChanges = () => {
  const dispatch = useAppDispatch();
  const isShowDialog = useAppSelector(state => state.isDirty.isShowDialog);
  const action = useAppSelector(state => state.isDirty.action);
  const objectForUpd = useAppSelector(state => state.isDirty.objectForUpd);
  // const successAction = useAppSelector((state) => state.isDirty.successAction);

  const [updateDigitalForm] = useDigitalFormUpdateMutation({});

  const handleSetToInitial = () => {
    dispatch(
      setIsDirty({
        isDirty: false,
        isShowDialog: false,
        action: () => {},
      }),
    );
  };

  const handleReject = () => {
    action();
    handleSetToInitial();
  };

  const handleSuccess = () => {
    handleSetToInitial();
  };

  if (!isShowDialog) {
    return null;
  }

  return (
    <Dialog
      open={isShowDialog}
      onClose={() => {
        handleSetToInitial();
      }}
    >
      <SuccessDialog
        noteText="All changes will be lost"
        rejectText="Discard changes and leave"
        successText="Save and leave"
        onReject={handleReject}
        onSuccess={handleSuccess}
        titleText="You have unsaved changes"
        onClose={() => {
          handleSetToInitial();
        }}
      />
    </Dialog>
  );
};

export default DialogUnsavedChanges;
