import { Select } from '@mui/material';
import styled from 'styled-components';

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectInput = styled(Select)`
  width: 100%;
  border-radius: 5px;

  &.isCell {
    & .Mui-focused {
      border-bottom: 0.5px solid var(--line, #0084b1) !important;

      &.MuiFormHelperText-root {
        border: none !important;
      }
    }

    &.MuiButtonBase-root {
      &.Mui-selected {
        //background-color: #0084b1;
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    &.MuiInputBase-root {
      border: none;
      text-overflow: ellipsis;
      background: none !important;
      border-bottom: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 0px;
      padding: 9px 14px;
      line-height: normal;
      //height: none;
      color: var(--text, #202e5f);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::before {
        border: none !important;
      }
      &::after {
        border: none !important;
      }

      & :focus {
        background: none !important;
      }
    }

    & .MuiSelect-select {
      padding: 0;

      & :focus {
        background: none;
      }
    }
  }

  &.Mui-focused {
    border: 0.5px solid var(--line, #0084b1) !important;

    &.MuiFormHelperText-root {
      border: none !important;
    }
  }

  &.MuiButtonBase-root {
    &.Mui-selected {
      background-color: #0084b1;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &.MuiInputBase-root {
    text-overflow: ellipsis;
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: none;
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiSelect-select {
    padding: 0;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Content = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FormHelperText = styled.div`
  & .MuiFormHelperText-root {
    color: #d32f2f;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
`;
