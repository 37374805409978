import * as S from './styles';
import * as AuthS from '../../styles';
import logo from '../../../../assets/images/logo.png';
import { Button, Input } from '../../../../components';
import { ChangeEvent, useEffect, useState } from 'react';
import { ArrowLSVG, ArrowLeftSVG } from '../../../../assets/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../../../store/slices/message';
import { useForgotPasswordMutation } from '../../../../store/api/auth/authApi';
import { AuthFieldType } from '../../../../types/enums/AppointmentEnum';
import { isTypeAuthField } from '../../../../helpers/isTypeAuthField';

type FormValues = {
  authField: string;
};

const ResetPassword = () => {
  const [authFieldType, setAuthFieldType] = useState<AuthFieldType>(AuthFieldType.PHONE);

  const ResetPasswordSchema = yup.object().shape({
    authField:
      authFieldType === AuthFieldType.PHONE
        ? yup
            .string()
            .matches(/^\+[1-9]\d{1,14}$/, 'International format required: +1xxxxxxxxxx')
            .min(12, 'International format required: +1xxxxxxxxxx')
            .max(12, 'International format required: +1xxxxxxxxxx')
            .required('Is required')
        : yup
            .string()
            .matches(
              /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
              'Set email in valid format',
            )
            .required('Is required'),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();

  const { values, handleChange, handleSubmit, errors, setFieldValue, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        authField: '',
      },
      validateOnChange: false,
      validationSchema: ResetPasswordSchema,
      onSubmit: async ({ authField }) => {
        await forgotPassword({ authField, authFieldType })
          .unwrap()
          .then(res => {
            navigate('/resetpasswordverify', {
              state: { authField, authFieldType },
            });
          })
          .catch(error => {
            dispatch(setMessage({ message: error.data.message, type: 'error' }));
          });
      },
    });

  useEffect(() => {
    values.authField && setAuthFieldType(isTypeAuthField(values.authField));
  }, [values.authField]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <AuthS.AuthWrapper>
          <AuthS.LoginBlock>
            <img className="logo" src={logo} alt="logotype"></img>
            <S.ResetPasswordWrapper>
              <S.Article>
                <ArrowLeftSVG onClick={() => navigate('/auth')} /> Reset Password
              </S.Article>
              <S.SubArticle>
                Please enter the phone number or email you used for Registration
              </S.SubArticle>
              <S.Items>
                <Input
                  id="authField"
                  name="authField"
                  onChange={handleChangeInput}
                  value={values.authField}
                  error={!!errors.authField}
                  helperText={errors.authField}
                  label="Phone or Email"
                  placeholder="+1xxxxxxxxxx or xxx@xxx.com"
                />
                <S.ButtonWrapper>
                  <Button text="Submit" type="submit" />
                </S.ButtonWrapper>
              </S.Items>
            </S.ResetPasswordWrapper>
          </AuthS.LoginBlock>
        </AuthS.AuthWrapper>
      </form>
    </>
  );
};

export default ResetPassword;
