import { FormikProps } from 'formik';
import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { ClientRegistrationType } from '../pages/ClientRegistrationPage/ClientRegistrationType';
import { AppointmentsNew, Step } from '../../../../../../types/AppointmentNewTypes';
import * as S from './styles';
import CheckBoxWithLabel from '../../../../../../components/atoms/CheckboxWithLabel';
import ClientRegistrationUserBlock from './components/ClientRegistrationUserBlock';
import CRContacts from './components/CRContacts';
import CROccupation from './components/CROccupation';
import CROtherContacts from './components/CROtherContacts';
import CRRelative from './components/CRRelative';
import CRInsurance from './components/CRInsurance';
import { Dialog, SuccessDialog } from '../../../../../../components';
import { Location, useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { handleChangeInputRef } from '../../../../../../helpers/FormikFuncs/formikFuncs';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  isFromStepper?: boolean;
  stepperValues?: Step;
  digitalFormId?: number;
  appointmentInformation?: AppointmentsNew;

  // ref: React.MutableRefObject<{}>;
};

const ClientRegistration = forwardRef<Record<string, unknown>, Props>(
  (
    { formik, isFromStepper, stepperValues, appointmentInformation, digitalFormId },
    ref,
  ) => {
    const [isDirty, setIsDirty] = useState(false);

    const markAsDirty = () => {
      setIsDirty(true);
    };
    /*const [showDialog, setShowDialog] = useState(false);
    const handleDialogClose = () => {
      setShowDialog(false);
    };

    const handleDialogConfirm = () => {
      setIsDirty(false);
      setShowDialog(false);
      formik.handleSubmit();
    };

    const [nextLocation, setNextLocation] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();*/

    return (
      <>
        <S.Wrapper>
          <S.Inputs>
            <CheckBoxWithLabel
              label="Did you have any Physical Therapy this year?"
              defaultChecked={formik.values.isHaveTherapy}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'isHaveTherapy', ref, markAsDirty)
              }
            />
            <ClientRegistrationUserBlock
              formik={formik}
              ref={ref}
              setIsDirty={markAsDirty}
              appointmentInformation={appointmentInformation}
            />
            <CRContacts formik={formik} ref={ref} setIsDirty={markAsDirty} />
            <CROccupation formik={formik} ref={ref} setIsDirty={markAsDirty} />
            <CROtherContacts formik={formik} ref={ref} setIsDirty={markAsDirty} />
            <CRRelative formik={formik} ref={ref} setIsDirty={markAsDirty} />
            <CRInsurance formik={formik} ref={ref} setIsDirty={markAsDirty} />
            <S.Article>
              All professional services rendered are the ultimate responsibility of the
              patient
            </S.Article>
          </S.Inputs>
        </S.Wrapper>
      </>
    );
  },
);

export default ClientRegistration;
