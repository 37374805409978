import styled from 'styled-components';

export const BtnWrapper = styled.div`
  width: 130px;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  height: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;

  background: #f4f4f4;
  justify-content: space-between;
`;

export const SelectWrapper = styled.div`
  width: 240px;
  min-width: 240px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  width: 100%;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
`;
