import moment from 'moment';
import dayjs from 'dayjs';
import { CheckWorkingTimeDiapason } from '../../../../../types/AppointmentsTypes';
import { Item } from '../../../LogoutDiv/styles';

const getDaysWeekendsForCalendar = (
  currentData: Array<CheckWorkingTimeDiapason>,
  date: Date,
) => {
  if (
    currentData &&
    currentData.find(
      item =>
        dayjs(item.date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD') &&
        item.isWorkDay === true,
    )
  ) {
    return true;
  } else return false;
};

export default getDaysWeekendsForCalendar;
