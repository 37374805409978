import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Content = styled.div`
  width: 520px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 20px;
  }
`;

export const Article = styled.div`
  margin-top: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const SubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 450px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  align-self: end;
  margin-top: 6px;
  margin-right: 10px;
  cursor: pointer;
`;
