import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 210px);
`;

export const Header = styled.div<{ color: string }>`
  background: ${props => props.color || `#fff`};
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Text = styled.div`
  padding: 10px 0px 10px 13px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  /* scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  } */

  /* height: calc(100vh - 40px); */
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 84, 113, 0.1);
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
