import Credentials from './components/Credentials';
import { Navigate, Route, Routes } from 'react-router-dom';
import VerificationCode from './components/VerificationCode';
import SignUpDetails from './components/SignUpDetails';
import ChangePassword from './components/ChangePassword';
import ResetPasswordVerify from './components/ResetPasswordVerify';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { UserStatus } from '../../types/UserStatuses';
import ResetPassword from './components/ResetPassword';

const Auth = () => {
  const status = useAppSelector(state => state.auth.status);
  return (
    <>
      {status === UserStatus.VERIFIED_AUTH_FIELD ? (
        <Routes>
          <Route path="*" element={<Navigate to="/signup" />} />
          <Route path="signup" element={<SignUpDetails />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="auth" element={<Credentials />} />
          <Route path="verificationcode" element={<VerificationCode />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="resetpasswordverify" element={<ResetPasswordVerify />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      )}
    </>
  );
};

export default Auth;
