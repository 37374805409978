import styled, { css } from 'styled-components';
import { devices } from '../../../../../../../constants/device';

export const RecordItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const AppointmentWrapper = styled.div<{
  background: string;
  border: string;
}>`
  border-radius: 5px;
  gap: 4px;
  width: 100%;
  min-height: 46px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${props =>
    props.border &&
    css`
      border: 0.5px solid ${props.border};
    `}
`;

export const Wrapper = styled.div<{ background: string; border: string }>`
  border-radius: 5px;
  gap: 4px;
  width: 100%;
  min-height: 46px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${props =>
    props.border &&
    css`
      border: 0.5px solid ${props.border};
    `}
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const StatusBlock = styled.div<{ status: string }>`
  width: 7px;
  height: 74px;

  ${props =>
    props.status &&
    css`
      background: ${props.status};
    `}
`;

export const DateDiv = styled.div`
  display: flex;
  width: 120px;
  min-width: 120px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const Line = styled.div`
  width: 0.5px;
  height: 50px;
  background: #d7d7d7;

  @media ${devices.xs} {
    display: none;
  }
`;

export const WeekDay = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
`;

export const NumberDay = styled.div`
  color: #0084b1;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const RecordInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media ${devices.xs} {
    gap: 8px;
    margin-left: 14px;
  }
`;

export const ArticleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StatusPinWrapper = styled.div``;

export const MiddleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${devices.xs} {
    gap: 10px;
    flex-wrap: wrap;
  }
`;

export const RecordTime = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ClinicName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BtnColumn = styled.div`
  min-width: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;

  &.signatures {
    color: var(--icon, #848a9b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  svg {
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
`;

export const DoctorName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const DownDiv = styled.div`
  display: flex;
  align-items: start;
  gap: 5px;
  margin-left: -2px;

  svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }
`;

export const NoteText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: normal;

  @media ${devices.xs} {
    width: 100%;
    margin-right: 10px;
  }
`;

export const TreatmentBtn = styled.div`
  display: flex;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;

  & .red {
    fill: red;
  }
`;

export const ProfileContent = styled.div`
  /* width: 356px;
  height: 201px; */
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`;

export const Content = styled.div`
  padding: 20px 30px;
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const AvatarBlock = styled.div`
  & img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
  }
  svg {
    width: 100px;
    height: 100px;
  }
`;

export const SelectBlock = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;
`;

export const Name = styled.div`
  display: flex;
  gap: 5px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Span = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const BlackSpan = styled.span`
  color: var(--icon, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BtnWrapper = styled.div`
  padding: 0px 30px 10px 30px;
`;
