import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { DeleteRedBtnSVG, EditBtnCurrentColorSVG } from 'assets/icons';
import { CheckBox, Dialog, SuccessDialog } from 'components';
import { UserProfile } from 'types/UserProfileTypes';
import * as S from '../../styles';
import { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InsuranceItemBlock from '../InsuranceItemBlock';
import { InsuranceInUserProfileType } from 'types/InsuranceType';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { useInsuranceDeleteMutation } from 'store/api/insuranceRelative/insuranceRelativeApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { useMarkAsPrimaryInsuranceMutation } from 'store/api/profile/profileApi';

type Props = {
  rows: UserProfile | undefined;
  setEditedRow: React.Dispatch<React.SetStateAction<InsuranceInUserProfileType | null>>;
  setIsOpenAddInsuranceDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const InsurancesBlock = ({ rows, setEditedRow, setIsOpenAddInsuranceDialog }: Props) => {
  const [expandedIdArr, setExpandedIdArr] = useState<Array<string>>([]);

  useEffect(() => {
    if (rows) {
      const newArr = rows?.profile?.insurances?.map((item, index) => {
        return item.id;
      });
      setExpandedIdArr(newArr);
    }
  }, [rows]);

  const handleExpanded = (id: string) => {
    setExpandedIdArr(
      expandedIdArr?.includes(id)
        ? expandedIdArr.filter(filteredId => filteredId !== id)
        : expandedIdArr?.concat([id]),
    );
  };

  const [deleteInsurance] = useInsuranceDeleteMutation({});

  const [deletedRow, setDeletedRow] = useState<string | null>(null);
  const [markedRow, setMarkedRow] = useState<InsuranceInUserProfileType | null>(null);

  const dispatch = useAppDispatch();

  const handleSuccessDelete = () => {
    if (deletedRow) {
      deleteInsurance({
        id: deletedRow,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Insurance was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  const [markAsPrimary] = useMarkAsPrimaryInsuranceMutation({});

  const handleSuccessMarkPrimary = () => {
    if (markedRow) {
      markAsPrimary({
        id: markedRow.id,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Insurance was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const [isShowMarkPrimaryDialog, setIsShowMarkPrimaryDialog] = useState(false);

  return (
    <S.InfoWrapper>
      <Dialog
        open={openDialog}
        onClose={() => {
          setDeletedRow(null);
          setOpenDialog(!openDialog);
        }}
      >
        <ConfirmDeleteDialog
          onClose={() => {
            setDeletedRow(null);
            setOpenDialog(!openDialog);
          }}
          titleText={'insurance'}
          helperText="This process is irreversible"
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
      <Dialog
        open={isShowMarkPrimaryDialog}
        onClose={() => {
          setMarkedRow(null);
          setIsShowMarkPrimaryDialog(!isShowMarkPrimaryDialog);
        }}
      >
        <SuccessDialog
          onClose={() => {
            setMarkedRow(null);
            setIsShowMarkPrimaryDialog(!isShowMarkPrimaryDialog);
          }}
          titleText={`Mark ${markedRow?.primaryInsuranceCompany} as primary insurance?`}
          helperText="This process will unmark any primary insurance"
          onSuccess={handleSuccessMarkPrimary}
        />
      </Dialog>

      {rows?.profile?.insurances?.map((item, index) => {
        return (
          <S.Block key={item.id}>
            <S.Row>
              <Accordion expanded={expandedIdArr?.includes(item.id)}>
                <AccordionSummary
                  onClick={() => {
                    handleExpanded(item.id);
                  }}
                >
                  <S.ArticleSmallWrapper>
                    <S.LeftSide>
                      <S.ButtonMark
                        onClick={e => {
                          e.stopPropagation();
                          if (!item.isPrimary) {
                            setMarkedRow(item);
                            setIsShowMarkPrimaryDialog(!isShowMarkPrimaryDialog);
                          }
                        }}
                      >
                        <CheckBox checked={item.isPrimary} />
                        Mark as primary
                      </S.ButtonMark>

                      {item.primaryInsuranceCompany}
                    </S.LeftSide>

                    <S.Btns>
                      {expandedIdArr?.includes(item.id) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                      <EditBtnCurrentColorSVG
                        color="#848A9B"
                        onClick={e => {
                          e.stopPropagation();
                          setEditedRow(item);
                          setIsOpenAddInsuranceDialog(true);
                        }}
                      />
                      <DeleteRedBtnSVG
                        style={{ width: '19px', height: '19px' }}
                        onClick={e => {
                          e.stopPropagation();
                          setDeletedRow(item.id);
                          setOpenDialog(!openDialog);
                        }}
                      />
                    </S.Btns>
                  </S.ArticleSmallWrapper>
                </AccordionSummary>
                <AccordionDetails>
                  <InsuranceItemBlock row={item} />
                </AccordionDetails>
              </Accordion>
            </S.Row>
            <S.BottomLine />
          </S.Block>
        );
      })}
    </S.InfoWrapper>
  );
};

export default InsurancesBlock;
