import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  border-radius: 5px;
  background: rgba(255, 231, 104, 0.2);
  padding: 4px 6px;
  width: fit-content;
  min-width: fit-content;
  align-items: center;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const Text = styled.div`
  color: #d17e00;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
