import React, { useMemo, useState } from 'react';
import * as S from './styles';
import { ArrowLSVG, ArrowRSVG } from 'assets/icons';

type Props = {
  limit: number;
  text: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isFetching: boolean;
  currentPage?: number;
  lastPage?: number;
  count?: number;
};

export const CustomPagination = ({
  count,
  limit,
  text,
  setPage,
  currentPage,
  lastPage,
  isFetching,
}: Props) => {
  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(limit);

  const handleForward = () => {
    setPage(prev => prev + 1);
    setFirstCount(firstCount + limit);
    setSecondCount(secondCount + limit);
  };
  const handleBack = () => {
    setPage(prev => prev - 1);
    setFirstCount(firstCount - limit);
    setSecondCount(secondCount - limit);
  };

  const handleShowCount = useMemo(() => {
    if (count && count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-{count && count < secondCount ? count : secondCount} of{' '}
          {count} {text}{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {count} {text}{' '}
        </S.PaginationText>
      );
    }
  }, [firstCount, count, text, secondCount]);

  return (
    <S.PaginationWrapper>
      <S.Arrows>
        <ArrowLSVG onClick={handleBack} className={currentPage === 1 ? 'disabled' : ''} />
        <ArrowRSVG
          onClick={handleForward}
          className={currentPage === (lastPage || 1) ? 'disabled' : ''}
        />
      </S.Arrows>
      {isFetching ? <div>...</div> : handleShowCount}
    </S.PaginationWrapper>
  );
};
