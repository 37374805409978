import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  display: flex;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 430px;
`;

export const ButtonWrapper = styled.div`
  width: 160px;
  margin-top: 20px;
`;
