import styled from 'styled-components';

export const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

export const AvatarCellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const Wrapper = styled.div``;

export const Empty = styled.div`
  width: 20px;
  height: 20px;
`;

export const Link = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #4570de;
  }
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
