import { Input } from '../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../components/atoms/CheckboxWithLabel';
import MedicineAndDosage from './components/MedicineAndDosage';
import * as S from './styles';

const MedicalHistory = () => {
  return (
    <>
      <S.Article>Medical History</S.Article>
      <S.Line>
        <CheckBoxWithLabel label="Hypertension" />
        <CheckBoxWithLabel label="Diabetes" />
        <CheckBoxWithLabel label="Heart Disease" />
        <CheckBoxWithLabel label="Pulmonary disease" />
        <CheckBoxWithLabel label="Cancer" />
        <CheckBoxWithLabel label="Allergies" />
        <S.Text>Other: </S.Text>
        <S.InputWrapper>
          <Input label="" />
        </S.InputWrapper>
      </S.Line>
      <MedicineAndDosage />
      <S.Line>
        <CheckBoxWithLabel label="Have you had any surgeries?" />
        <S.Text>What kind?</S.Text>
        <S.InputWrapper className="px360">
          <Input />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <CheckBoxWithLabel label="Have you had any accidents?" />
        <S.Text>What kind?</S.Text>
        <S.InputWrapper className="px360">
          <Input />
        </S.InputWrapper>
      </S.Line>
    </>
  );
};

export default MedicalHistory;
