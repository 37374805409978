import dayjs from 'dayjs';
import * as S from '../styles';
import { MouseEvent } from 'react';
import { getFileName } from '../../../../../../helpers/functions/fileName';
import { DownloadReportSVG, EditBtnMinimalSVG } from '../../../../../../assets/icons';
import { useCouponReportMutation } from '../../../../../../store/api/coupons/couponsApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../types/DrawerMode';

type Props = {
  row: any;
  header: string;
};

const CouponData: React.FC<Props> = ({ row, header }) => {
  const [downloadReport] = useCouponReportMutation({});

  const dispatch = useAppDispatch();

  switch (header) {
    case 'Title': {
      return row.title || '';
    }
    case 'Validity': {
      return (
        dayjs(row.validFrom).format('MM/DD/YYYY') +
          ' - ' +
          dayjs(row.validTo).format('MM/DD/YYYY') || ''
      );
    }
    case 'NumberAct': {
      return row.activationCount || '0';
    }
    case 'File': {
      return row.file?.name ? (
        <S.LinkWrapper
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            window.open(row.file.url);
          }}
        >
          {getFileName(row.file.name)}
        </S.LinkWrapper>
      ) : (
        '-'
      );
    }
    case 'ClinicName': {
      return row.clinic?.name || '-';
    }
    case 'Benefits': {
      return row.benefit || '-';
    }
    case 'Value': {
      return <S.Value>{row.value || '-'}</S.Value>;
    }
    case 'Reports': {
      return (
        <S.CellWrapper>
          <DownloadReportSVG onClick={() => downloadReport({ id: row.id })} />
        </S.CellWrapper>
      );
    }
    case 'Edit': {
      return (
        <S.CellWrapper>
          <EditBtnMinimalSVG
            onClick={() =>
              dispatch(
                showDrawer({
                  mode: DrawerMode.ADD_COUPON,
                  show: true,
                  props: row,
                }),
              )
            }
          />
        </S.CellWrapper>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default CouponData;
