import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 538px;
  padding-bottom: 70px;
  @media ${devices.xs} {
    width: 375px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const RadioWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const RadioLabel = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 20px;
  margin: 0px 19px;

  & .MuiFormGroup-root {
    justify-content: space-between;
    width: 100%;
  }

  &.radio {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 22px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubArticle = styled.div`
  margin: 10px 20px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #f4f4f4;

  @media ${devices.xs} {
    width: 100%;
    background: none;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 18px;
  }
`;

export const BtnWrapper = styled.div`
  margin-right: 18px;
  width: 174px;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;
