import {
  ApproveBtnSVG,
  AvatarSVG,
  CalendarIconSVG,
  CloseSVG,
  EditBtnSVG,
  EmailIconSVG,
  GenderIconSVG,
  PhoneIconSVG,
  RefProviderSVG,
  ResetApproveBtnSVG,
} from 'assets/icons';
import { DatePicker, HoldCard, Input, SelectInput, UserStatusPin } from '../..';
import * as S from './styles';
import { UserProfile } from 'types/UserProfileTypes';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useParams } from 'react-router-dom';
import {
  useUpdateProfileAvatarMutation,
  useUpdateProfileMutation,
} from 'store/api/user/profileApi';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/slices/message';
import AutoCompleteInput from '../../atoms/AutoComplete';
import { useDebounce } from 'use-debounce';
import { useGetMutAllRefProvidersMutation } from 'store/api/staff/staffApi';
import { emailValidator, phoneValidator } from 'constants/constants';
import ReactInputMask from 'react-input-mask';

type Props = {
  rows: UserProfile | undefined;
};

type FormValues = {
  email: string;
  sex: string;
  homePhone: string;
  cellPhone: string;
  emergencyContact: string;
  emergencyContactPhone: string;
  birthDate: Dayjs;
  firstName: string;
  lastName: string;
  MI: string;
  providerId: string;
};

const MainProfileInformation: React.FC<Props> = ({ rows }) => {
  const userInfo = useAppSelector(state => state.auth);

  const params = useParams();
  const dispatch = useDispatch();

  const [updateProfile] = useUpdateProfileMutation();
  const [updateAvatar] = useUpdateProfileAvatarMutation();

  const [searchValueLN, setSearchValueLN] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValueLN, 500);

  const [allRefProviders, allRefProvidersStatus] = useGetMutAllRefProvidersMutation({});

  const [data, setData] = useState([]);

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isEditable || debouncedSearchValue) {
      allRefProviders({
        lastName: debouncedSearchValue || undefined,
        page: 1,
        take: 50,
      })
        .unwrap()
        .then(res => setData(res?.rows));
    }
  }, [isEditable, debouncedSearchValue]);

  const MainProfileShape = yup.object().shape({
    email: emailValidator,
    firstName: yup.string().required('Is required'),
    lastName: yup.string().required('Is required'),
    cellPhone: phoneValidator,
    birthDate: yup.date().required('Is required'),
    sex: yup.string().required('Is required'),
    emergencyContactPhone: phoneValidator,
    emergencyContact: yup.string(),
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      sex: '',
      MI: '',
      homePhone: '',
      providerId: '',
      cellPhone: '',
      emergencyContact: '',
      emergencyContactPhone: '',
      birthDate: dayjs(),
    },
    validateOnChange: false,
    validationSchema: MainProfileShape,
    onSubmit: async () => {
      avatar &&
        (await updateAvatar({
          id: params.patientId,
          avatar: avatar,
        })
          .unwrap()
          .then(res => {
            setIsEditable(false);
            resetForm();
            dispatch(
              setMessage({
                message: res.message,
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          }));

      await updateProfile({
        id: params.patientId,
        firstName: values.firstName,
        lastName: values.lastName,
        ...(values.homePhone ? { homePhone: values.homePhone } : { homePhone: null }),
        cellPhone: values.cellPhone,
        phone: values.cellPhone,
        sex: values.sex,
        ...(values.email ? { email: values.email } : { email: null }),
        dateOfBirth: dayjs(values.birthDate).format('MM/DD/YYYY'),
        emergencyContact: values.emergencyContact,
        emergencyContactPhone: values.emergencyContactPhone,
        mi: values.MI,
        ...(selectedValue && { providerId: selectedValue.id }),
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const location = useLocation();

  const [selectedValue, setSelectedValue] = useState<any>('');

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  useEffect(() => {
    setValues({
      ...values,
      firstName: rows?.profile?.firstName || '',
      lastName: rows?.profile?.lastName || '',
      email: rows?.email || '',
      sex: rows?.profile?.sex || '',
      cellPhone: rows?.phone || '',
      homePhone: rows?.profile?.homePhone || '',
      emergencyContact: rows?.profile?.emergencyContact || '',
      emergencyContactPhone: rows?.profile?.emergencyContactPhone || '',
      MI: rows?.profile?.mi || '',
      birthDate: dayjs(rows?.profile?.dateOfBirth) || dayjs(),
    });
    if (rows?.provider?.id) {
      setSelectedValue(rows?.provider);
    }
  }, [isEditable]);

  const [avatar, setAvatar] = useState(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = (e: any) => {
    const file = e.target.files.item(0);
    if (file) {
      setAvatar(file);
    }
  };

  const handleClearImg = (e: any) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setAvatar(null);
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {userInfo?.role &&
          ![Roles.PATIENT, Roles.BILLING].includes(userInfo?.role as Roles) &&
          location.pathname !== '/profile' && (
            <S.EditBtnWrapper>
              {isEditable ? (
                <S.ButtonsEdit>
                  <ResetApproveBtnSVG
                    onClick={() => {
                      setIsEditable(false);
                    }}
                  />
                  <ApproveBtnSVG
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </S.ButtonsEdit>
              ) : (
                <S.ButtonsEdit>
                  <EditBtnSVG onClick={() => setIsEditable(true)} />
                </S.ButtonsEdit>
              )}
            </S.EditBtnWrapper>
          )}

        <S.AvatarDiv>
          {isEditable ? (
            <S.AvatarClickZone>
              <label htmlFor="file">
                <S.AvatarZone>
                  {avatar ? (
                    <>
                      <img src={URL.createObjectURL(avatar)} alt="" />
                    </>
                  ) : (
                    <AvatarSVG />
                  )}
                  <S.AvatarHelper>
                    Your new photo must be in JPEG, or PNG format before you upload this,
                    <S.TextHelper>max size 50MB</S.TextHelper>
                  </S.AvatarHelper>
                  <input
                    ref={inputRef}
                    type="file"
                    id="file"
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg"
                    hidden
                  ></input>
                </S.AvatarZone>
              </label>
              {avatar && (
                <S.DeleteCircle onClick={handleClearImg}>
                  <CloseSVG />
                </S.DeleteCircle>
              )}
            </S.AvatarClickZone>
          ) : rows?.avatar ? (
            <img src={rows?.avatar.url} alt="" />
          ) : (
            <AvatarSVG />
          )}
          <S.Name>
            {isEditable ? (
              <S.InfoValue className="middle">
                <Input
                  label=""
                  id="firstName"
                  name="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  onChange={handleChangeInput}
                  value={values.firstName}
                />
              </S.InfoValue>
            ) : (
              rows?.profile?.firstName + ' '
            )}
            {isEditable ? (
              <S.InfoValue className="small">
                <Input
                  label=""
                  id="MI"
                  name="MI"
                  error={!!errors.MI}
                  helperText={errors.MI}
                  onChange={handleChangeInput}
                  value={values.MI}
                />
              </S.InfoValue>
            ) : (
              (rows?.profile?.mi || ' ') + ' '
            )}
            {isEditable ? (
              <S.InfoValue className="middle">
                <Input
                  label=""
                  id="lastName"
                  name="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  onChange={handleChangeInput}
                  value={values.lastName}
                />
              </S.InfoValue>
            ) : (
              rows?.profile?.lastName
            )}
            {rows?.isHoldAppointment &&
              rows?.role === Roles.PATIENT &&
              userInfo.role !== Roles.PATIENT && (
                <HoldCard text="HA" reason={rows.isHoldAppointment} />
              )}
            {rows?.isHoldBilling &&
              rows?.role === Roles.PATIENT &&
              userInfo.role !== Roles.PATIENT && (
                <HoldCard text="HB" reason={rows.isHoldBilling} />
              )}
          </S.Name>

          <UserStatusPin type={rows?.isBlocked || rows?.status} />
        </S.AvatarDiv>
        <S.MainInfoWrapper>
          <S.InfoRow>
            <S.RowArticle>
              <EmailIconSVG />
              <S.BlockText>
                <S.RowText>Email</S.RowText>
              </S.BlockText>
            </S.RowArticle>
            {isEditable ? (
              <S.InfoValue>
                <Input
                  label=""
                  id="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChangeInput}
                  value={values.email}
                />
              </S.InfoValue>
            ) : (
              <S.InfoValue>{rows?.email || '-'}</S.InfoValue>
            )}
          </S.InfoRow>
          <S.InfoRow>
            <S.RowArticle>
              <PhoneIconSVG />
              <S.BlockText>
                <S.RowText>Home Phone</S.RowText>
                <S.RowText>Cell Phone</S.RowText>
              </S.BlockText>
            </S.RowArticle>
            {isEditable ? (
              <>
                <S.EditInputs>
                  <S.InfoValue>
                    <ReactInputMask
                      mask="+19999999999"
                      onChange={handleChangeInput}
                      value={values.homePhone}
                    >
                      <Input
                        label=""
                        id="homePhone"
                        name="homePhone"
                        error={!!errors.homePhone}
                        helperText={errors.homePhone}
                      />
                    </ReactInputMask>
                  </S.InfoValue>
                  <S.InfoValue>
                    <ReactInputMask
                      mask="+19999999999"
                      onChange={handleChangeInput}
                      value={values.cellPhone}
                    >
                      <Input
                        label=""
                        id="cellPhone"
                        name="cellPhone"
                        error={!!errors.cellPhone}
                        helperText={errors.cellPhone}
                      />
                    </ReactInputMask>
                  </S.InfoValue>
                </S.EditInputs>
              </>
            ) : (
              <>
                <S.BlockText>
                  <S.InfoValue>{rows?.profile?.homePhone || '-'}</S.InfoValue>
                  <S.InfoValue>
                    {rows?.phone || rows?.profile?.cellPhone || '-'}
                  </S.InfoValue>
                </S.BlockText>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            <S.RowArticle>
              <GenderIconSVG />
              <S.BlockText>
                <S.RowText>Sex</S.RowText>
              </S.BlockText>
            </S.RowArticle>
            <S.BlockText>
              {isEditable ? (
                <S.InfoValue>
                  <SelectInput
                    label=""
                    id="sex"
                    name="sex"
                    error={!!errors.sex}
                    helperText={errors.sex}
                    value={values.sex}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setFieldError('sex', '');
                      handleChange(e);
                    }}
                  >
                    <MenuItem value={'male'}>
                      <S.MenuItemContent>{'Male'}</S.MenuItemContent>
                    </MenuItem>
                    <MenuItem value={'female'}>
                      <S.MenuItemContent>{'Female'}</S.MenuItemContent>
                    </MenuItem>
                  </SelectInput>
                </S.InfoValue>
              ) : (
                <S.InfoValue>
                  {rows?.profile?.sex ? ucFirst(rows?.profile?.sex) : '-'}
                </S.InfoValue>
              )}
            </S.BlockText>
          </S.InfoRow>
          <S.InfoRow>
            <S.RowArticle>
              <CalendarIconSVG />
              <S.BlockText>
                <S.RowText>Birth Date</S.RowText>
              </S.BlockText>
            </S.RowArticle>
            <S.BlockText>
              {isEditable ? (
                <S.InfoValue>
                  <DatePicker
                    label=""
                    disableFuture
                    value={values.birthDate}
                    onChange={value => {
                      setFieldError('birthDate', '');
                      setFieldValue('birthDate', value);
                    }}
                    id="birthDate"
                    name="birthDate"
                    error={!!errors.birthDate}
                    helperText={errors.birthDate ? 'Not valid date format' : ''}
                  />
                </S.InfoValue>
              ) : (
                <S.InfoValue>
                  {rows?.profile?.dateOfBirth
                    ? dayjs(rows?.profile?.dateOfBirth).format('MM/DD/YYYY')
                    : '-'}
                </S.InfoValue>
              )}
            </S.BlockText>
          </S.InfoRow>
          <S.InfoRow>
            <S.RowArticle>
              <PhoneIconSVG />
              <S.BlockText>
                <S.RowText>Emergency contact</S.RowText>
                <S.RowText>Contact Phone</S.RowText>
              </S.BlockText>
            </S.RowArticle>
            {isEditable ? (
              <>
                <S.EditInputs>
                  <S.InfoValue>
                    <Input
                      label=""
                      id="emergencyContact"
                      name="emergencyContact"
                      error={!!errors.emergencyContact}
                      helperText={errors.emergencyContact}
                      onChange={handleChangeInput}
                      value={values.emergencyContact}
                    />
                  </S.InfoValue>
                  <S.InfoValue>
                    <ReactInputMask
                      mask="+19999999999"
                      onChange={handleChangeInput}
                      value={values.emergencyContactPhone}
                    >
                      <Input
                        label=""
                        id="emergencyContactPhone"
                        name="emergencyContactPhone"
                        error={!!errors.emergencyContactPhone}
                        helperText={errors.emergencyContactPhone}
                      />
                    </ReactInputMask>
                  </S.InfoValue>
                </S.EditInputs>
              </>
            ) : (
              <>
                <S.BlockText>
                  <S.InfoValue>{rows?.profile?.emergencyContact || '-'}</S.InfoValue>
                  <S.InfoValue>{rows?.profile?.emergencyContactPhone || '-'}</S.InfoValue>
                </S.BlockText>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            <S.RowArticle>
              <RefProviderSVG />
              <S.BlockText>
                <S.RowText>Referring provider</S.RowText>
                {!isEditable && <S.RowText>Contact Phone</S.RowText>}
              </S.BlockText>
            </S.RowArticle>
            {isEditable ? (
              <>
                <S.EditInputs>
                  <S.InfoValue>
                    <AutoCompleteInput
                      data={data || []}
                      customRender={true}
                      mainLabel={''}
                      loading={allRefProvidersStatus.isLoading ? true : false}
                      searchValue={searchValueLN}
                      options={data || []}
                      setSearchValue={setSearchValueLN}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      getOptionLabel={(option: any) =>
                        option && option?.firstName + ' ' + option?.lastName
                      }
                      renderOption={(props: any, option: any) => {
                        return (
                          <S.OptionWrap {...props}>
                            {allRefProvidersStatus.isLoading ? (
                              'Searching...'
                            ) : (
                              <S.Content>
                                <S.UserName>
                                  {option?.firstName + ' ' + option?.lastName}
                                </S.UserName>
                              </S.Content>
                            )}
                          </S.OptionWrap>
                        );
                      }}
                    />
                  </S.InfoValue>
                </S.EditInputs>
              </>
            ) : (
              <>
                <S.BlockText>
                  <S.InfoValue>
                    {rows?.provider
                      ? rows?.provider?.firstName + ' ' + rows?.provider?.lastName
                      : '-'}
                  </S.InfoValue>
                  <S.InfoValue>{rows?.provider?.phone || '-'}</S.InfoValue>
                </S.BlockText>
              </>
            )}
          </S.InfoRow>

          <S.RowItems>
            {/* <S.Row>
              <S.BlockRow>
                <S.UpperArticle>ADDRESS</S.UpperArticle>
                <S.UpperInfoText>
                  {rows?.profile?.address || "-"}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row> */}
            {userInfo.role !== Roles.PATIENT && (
              <S.Row>
                <S.BlockRow>
                  <S.UpperArticle>Clinics</S.UpperArticle>
                  {rows?.clinics?.length ? (
                    rows?.clinics?.map(item => (
                      <S.UpperInfoText key={item.id}>
                        • {item.name} / {item.accountNumber}
                      </S.UpperInfoText>
                    ))
                  ) : (
                    <S.UpperInfoText>-</S.UpperInfoText>
                  )}
                </S.BlockRow>
              </S.Row>
            )}
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>INSURANCE COMPANY</S.UpperArticle>
                <S.UpperInfoText>
                  {rows?.profile?.insurances?.filter(insurance => insurance.isPrimary)[0]
                    ?.primaryInsuranceCompany || '-'}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>OLD ACCOUNT NUMBER</S.UpperArticle>
                <S.UpperInfoText>
                  {rows?.profile?.oldAccountNumber || '-'}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>CREATED</S.UpperArticle>
                <S.UpperInfoText>
                  <S.ReceptionistName>on </S.ReceptionistName>
                  {rows?.createdAt
                    ? dayjs(rows?.createdAt.slice(0, 10)).format('MM/DD/YYYY')
                    : '-'}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>UPDATED</S.UpperArticle>
                <S.UpperInfoText>
                  <S.ReceptionistName>on </S.ReceptionistName>
                  {rows?.profile?.updatedAt
                    ? dayjs(rows?.profile?.updatedAt.slice(0, 10)).format('MM/DD/YYYY')
                    : '-'}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          </S.RowItems>
        </S.MainInfoWrapper>
      </form>
    </>
  );
};

export default MainProfileInformation;
