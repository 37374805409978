import dayjs from 'dayjs';
import { AppointmentProcessInterface, Step } from '../../../types/AppointmentNewTypes';
import {
  DigitalTemplateType,
  DigitalTemplateTypeByName,
} from '../../../types/TemplateType';
import { ptTherapyFormik } from './config/pt-therapy-Formik';
import { ptInitialFormik } from './config/pt-initial-Formik';
import { acuInsuranceFormik } from './config/acu-insurance-formik';
import { initialvisiformFormik } from './config/initialvisitform-formik';
import { clientRegFormik } from './config/client-reg-formik';

export const chooseAppointmentProcessFormik = (
  name?: string,
  data?: AppointmentProcessInterface,
  currentStep?: Step,
) => {
  switch (name) {
    case DigitalTemplateTypeByName.ACUPUNCTURE_INSURANCE:
      return acuInsuranceFormik(name, data, currentStep);
    case DigitalTemplateTypeByName.INITIAL:
      return initialvisiformFormik(name, data, currentStep);
    case DigitalTemplateTypeByName.PT_THERAPY:
      return ptTherapyFormik(name, data, currentStep);
    case DigitalTemplateTypeByName.PT_INITIAL:
      return ptInitialFormik(name, data, currentStep);
    case DigitalTemplateTypeByName.CLIENT_REGISTRATION:
      return clientRegFormik(name, data, currentStep);
    default:
      return;
  }
};
