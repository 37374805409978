import React from 'react';
import { getRoleName } from 'helpers/functions/roleName';
import { InsuranceLogs } from 'types/insuranceTypes';
import { Roles } from 'types/Roles';
import * as S from './styles';

export enum InsuranceHistoryOperation {
  CREATE = 'CREATE_COVERED_SERVICE',
  UPDATE = 'UPDATE_COVERED_SERVICE',
  DELETE = 'DELETE_COVERED_SERVICE',
  CREATE_INSURANCE = 'CREATE_INSURANCE',
  UPDATE_INSURANCE = 'UPDATE_INSURANCE',
  DELETE_INSURANCE = 'DELETE_INSURANCE',
  UPLOAD_INSURANCE_FILE = 'UPLOAD_INSURANCE_FILE',
  DELETE_INSURANCE_FILE = 'DELETE_INSURANCE_FILE',
  UPDATE_COMMON_INFORMATION = 'UPDATE_COMMON_INFORMATION',
  CREATE_RELATIVE_INSURANCE = 'CREATE_RELATIVE_INSURANCE',
  DELETE_RELATIVE_INSURANCE = 'DELETE_RELATIVE_INSURANCE',
  CREATE_INSURANCE_GROUP_NUMBER = 'CREATE_INSURANCE_GROUP_NUMBER',
  UPDATE_INSURANCE_GROUP_NUMBER = 'UPDATE_INSURANCE_GROUP_NUMBER',
  DELETE_INSURANCE_GROUP_NUMBER = 'DELETE_INSURANCE_GROUP_NUMBER',
}

export const getTextHistory = (item: InsuranceLogs) => {
  switch (item.operation) {
    case InsuranceHistoryOperation.CREATE_INSURANCE:
      return (
        <S.RowArticle>
          <S.GreenArticle>{`Create Insurance:  `}</S.GreenArticle>
          <S.SpanWeight>#{item?.insuranceId ?? '-'}</S.SpanWeight>
          by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.CREATE:
      return (
        <S.RowArticle>
          <S.GreenArticle>Created covered service: </S.GreenArticle>
          <S.SpanWeight>{item.service ?? 'Unknown'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );

    case InsuranceHistoryOperation.UPDATE:
      return (
        <S.RowArticle>
          {!!item?.changeList?.length &&
            item?.changeList.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <S.BlueArticle>Updated covered service: </S.BlueArticle>
                  <S.SpanWeight>{item.service ?? 'Unknown'}</S.SpanWeight>
                  <S.SpanWeight>"{i?.key}" </S.SpanWeight>
                  from <S.SpanWeight>{i?.from || '" - "'} </S.SpanWeight>
                  to <S.SpanWeight>{i?.to || '" - "'} </S.SpanWeight>
                  by{' '}
                  <S.SpanBy>
                    {item?.insuranceEditor?.profile?.firstName +
                      ' ' +
                      item?.insuranceEditor?.profile?.lastName +
                      ' '}
                  </S.SpanBy>
                </React.Fragment>
              );
            })}
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.DELETE:
      return (
        <S.RowArticle>
          <S.RedArticle>Delete covered service: </S.RedArticle>
          <S.SpanWeight>{item.service ?? 'Unknown'}</S.SpanWeight>{' '}
          <S.SpanWeight>{item?.insurance && `from ${item?.insurance}`} </S.SpanWeight>
          by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.DELETE_INSURANCE:
      return (
        <S.RowArticle>
          <S.RedArticle>Delete insurance: </S.RedArticle>
          <S.SpanWeight>#{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.UPDATE_INSURANCE:
      return (
        <S.RowArticle>
          {!!item?.changeList?.length &&
            item?.changeList.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <S.BlueArticle>Updated: </S.BlueArticle>
                  <S.SpanWeight>{`${item?.insuranceId ?? '-'}, `}</S.SpanWeight>{' '}
                  <S.SpanWeight>"{i?.key}" </S.SpanWeight>
                  from <S.SpanWeight>{i?.from || '" - "'} </S.SpanWeight>
                  to <S.SpanWeight>{i?.to || '" - "'} </S.SpanWeight>
                  by{' '}
                  <S.SpanBy>
                    {item?.insuranceEditor?.profile?.firstName +
                      ' ' +
                      item?.insuranceEditor?.profile?.lastName +
                      ' '}
                  </S.SpanBy>
                </React.Fragment>
              );
            })}
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.UPLOAD_INSURANCE_FILE:
      return (
        <S.RowArticle>
          <S.GreenArticle>Upload photo: </S.GreenArticle>
          to <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.UPDATE_COMMON_INFORMATION:
      return (
        <S.RowArticle>
          {!!item?.changeList?.length &&
            item?.changeList.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <S.BlueArticle>Updated: </S.BlueArticle>
                  <S.SpanWeight>"{i?.key}" </S.SpanWeight>
                  from <S.SpanWeight>{i?.from || '" - "'} </S.SpanWeight>
                  to <S.SpanWeight>{i?.to || '" - "'} </S.SpanWeight>
                  by{' '}
                  <S.SpanBy>
                    {item?.insuranceEditor?.profile?.firstName +
                      ' ' +
                      item?.insuranceEditor?.profile?.lastName +
                      ' '}
                  </S.SpanBy>
                </React.Fragment>
              );
            })}
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.DELETE_INSURANCE_FILE:
      return (
        <S.RowArticle>
          <S.RedArticle>Delete photo: </S.RedArticle>
          from <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.DELETE_RELATIVE_INSURANCE:
      return (
        <S.RowArticle>
          <S.RedArticle>Delete relative: </S.RedArticle>
          <S.SpanWeight>{`${item?.primaryInsuranceInfo}`}</S.SpanWeight> from{' '}
          <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );

    case InsuranceHistoryOperation.CREATE_RELATIVE_INSURANCE:
      return (
        <S.RowArticle>
          <S.GreenArticle>Add relative: </S.GreenArticle>
          <S.SpanWeight>{`${item?.primaryInsuranceInfo}`}</S.SpanWeight> to{' '}
          <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );

    case InsuranceHistoryOperation.CREATE_INSURANCE_GROUP_NUMBER:
      return (
        <S.RowArticle>
          <S.GreenArticle>Add group number: </S.GreenArticle>
          <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.UPDATE_INSURANCE_GROUP_NUMBER:
      return (
        <S.RowArticle>
          {!!item?.changeList?.length &&
            item?.changeList.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <S.BlueArticle>Updated group number: </S.BlueArticle>
                  <S.SpanWeight>"{i?.key}" </S.SpanWeight>
                  from <S.SpanWeight>{i?.from || '" - "'} </S.SpanWeight>
                  to <S.SpanWeight>{i?.to || '" - "'} </S.SpanWeight>
                  by{' '}
                  <S.SpanBy>
                    {item?.insuranceEditor?.profile?.firstName +
                      ' ' +
                      item?.insuranceEditor?.profile?.lastName +
                      ' '}
                  </S.SpanBy>
                </React.Fragment>
              );
            })}
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    case InsuranceHistoryOperation.DELETE_INSURANCE_GROUP_NUMBER:
      return (
        <S.RowArticle>
          <S.RedArticle>Delete group number: </S.RedArticle>
          from <S.SpanWeight>{item?.insuranceId ?? '-'}</S.SpanWeight> by{' '}
          <S.SpanBy>
            {item?.insuranceEditor?.profile?.firstName +
              ' ' +
              item?.insuranceEditor?.profile?.lastName +
              ' '}
          </S.SpanBy>
          <S.GraySpan>({getRoleName(item?.insuranceEditor?.role as Roles)})</S.GraySpan>
        </S.RowArticle>
      );
    default:
      return '-';
  }
};
