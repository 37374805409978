import { useEffect, useState } from 'react';
import {
  AvatarSVG,
  CalendarIconSVG,
  ClinicSVG,
  DotsSVG,
  NoteIconSVG,
  PinSVG,
  PlacePinSVG,
} from '../../../../../assets/icons';
import NotificationPopover from '../../../NotificationPopover';
import * as S from './styles';
import { Note } from '../../../../../types/NoteTypes';
import dayjs from 'dayjs';
import {
  useNoteDeleteMutation,
  useNoteUpdateMutation,
} from '../../../../../store/api/notes/noteApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../../store/slices/message';
import { Dialog } from '../../../..';
import ConfirmDeleteDialog from '../../../../molecules/ConfirmDeleteDialog';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
  pinnedCount: number;
};

type Props = {
  allNotes?: Response<Note>;
  note: Note;
  setEditedRow: React.Dispatch<React.SetStateAction<Note | null>>;
};

const NoteRecord: React.FC<Props> = ({ note, allNotes, setEditedRow }) => {
  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);
  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const [deleteNote, statusDel] = useNoteDeleteMutation({});

  const [updateNote, statusUpd] = useNoteUpdateMutation({});

  const handleOpenDeleteDialog = () => {
    setAnchorElReport(null);
    handleChangeDialog();
  };

  const handlePinNote = () => {
    if (note.isPinned) {
      updateNote({ id: note.id, isPinned: false });
    } else {
      updateNote({ id: note.id, isPinned: true });
    }
  };

  const handleSuccessDelete = () => {
    deleteNote({ id: note.id })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Note was successfully deleted',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <ConfirmDeleteDialog
          onClose={handleChangeDialog}
          titleText={'note'}
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
      <S.Wrapper>
        <NotificationPopover
          open={Boolean(anchorElReport)}
          anchorEl={anchorElReport}
          onClose={() => setAnchorElReport(null)}
        >
          <S.ContentWrapper>
            {note.isPinned ? (
              <S.ContentRow onClick={handlePinNote}>Unpin</S.ContentRow>
            ) : (
              <S.ContentRow
                onClick={handlePinNote}
                className={allNotes && allNotes.pinnedCount >= 3 ? 'disabled' : ''}
              >
                Pin
              </S.ContentRow>
            )}
            <S.ContentRow
              onClick={() => {
                setAnchorElReport(null);
                setEditedRow(note);
              }}
            >
              Edit
            </S.ContentRow>
            <S.ContentRow onClick={handleOpenDeleteDialog}>Delete</S.ContentRow>
          </S.ContentWrapper>
        </NotificationPopover>
        <S.Content>
          <S.Line>
            {note.isPinned && (
              <S.Pin>
                <PinSVG />
              </S.Pin>
            )}
            <S.Dots onClick={handleAnchorReport}>
              <DotsSVG />
            </S.Dots>
          </S.Line>
          <S.ThirdBlock>
            <NoteIconSVG />
            <S.Text>{note.text}</S.Text>
          </S.ThirdBlock>
          <S.ArticleWrap>
            <S.Receptionist>
              <S.DateCreate>
                <S.Span>Created:</S.Span>{' '}
                {dayjs(note.createdAt).format('MM/DD/YYYY h:mm A')}
              </S.DateCreate>{' '}
              <S.ReceptionistName>
                ( {note.receptionist?.profile?.firstName}{' '}
                {note.receptionist?.profile?.lastName} )
              </S.ReceptionistName>
            </S.Receptionist>
          </S.ArticleWrap>
          {!dayjs(note.createdAt).isSame(dayjs(note.updatedAt)) && (
            <S.ArticleWrap>
              <S.Receptionist>
                <S.DateCreate>
                  <S.Span>Updated:</S.Span>{' '}
                  {dayjs(note.updatedAt).format('MM/DD/YYYY h:mm A')}
                </S.DateCreate>{' '}
                <S.ReceptionistName>
                  ( {note.updatedBy?.profile?.firstName}{' '}
                  {note.updatedBy?.profile?.lastName} )
                </S.ReceptionistName>
              </S.Receptionist>
            </S.ArticleWrap>
          )}
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default NoteRecord;
