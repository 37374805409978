import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
  max-height: 250px;
  gap: 15px;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 8px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 300px;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 343px;
  width: 100%;
  align-items: center;

  &:hover {
    background-color: #ebf5f9;
    cursor: pointer;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  min-width: 150px;
  padding: 5px 20px;

  svg {
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
  }
`;

export const Avatar = styled.div``;

export const Name = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 350px;
`;

export const Date = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  min-width: 170px;
`;

export const SendVia = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 170px;
`;
