import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import { debounce, update } from 'lodash';
import { ChangeEvent } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const handleChangeInputRef = (
  e: ChangeEvent<HTMLInputElement>,
  name: string,
  ref: React.ForwardedRef<Record<string, unknown>>,
  setIsDirty?: () => void,
) => {
  if (typeof ref !== 'function' && ref && ref?.current !== null) {
    setIsDirty && setIsDirty();
    switch (e.target?.type) {
      case 'checkbox': {
        ref.current[name] = e.target.checked;
        break;
      }
      case 'number': {
        ref.current[name] = Number(e.target.value);
        break;
      }
      case 'radio': {
        if (e.target.value === 'true') {
          ref.current[name] = true;
        } else if (e.target.value === 'false') {
          ref.current[name] = false;
        } else ref.current[name] = e.target.value;
        break;
      }
      default: {
        ref.current[name] = e.target.value;
        break;
      }
    }
  }
};

export const handleChangeDatePicker = (
  newValue: any,
  name: string,
  ref: React.ForwardedRef<Record<string, unknown>>,
  setIsDirty?: () => void,
) => {
  if (typeof ref !== 'function' && ref && ref?.current !== null) {
    setIsDirty && setIsDirty();
    ref.current[name] = dayjs(newValue).format('MM/DD/YYYY');
  }
};
