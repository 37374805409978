import dayjs from 'dayjs';
import { AppointmentsNew } from '../../../../../types/AppointmentNewTypes';
import * as S from './styles';

type Props = {
  selectedAppointment?: AppointmentsNew;
};

const AppointmentHistory: React.FC<Props> = ({ selectedAppointment }) => {
  return (
    <S.Wrapper>
      <S.Line />
      <S.WrapperHistory>
        <S.Side>
          <S.Text>Created by: {selectedAppointment?.createdBy ?? '-'}</S.Text>
          <S.Text>
            Time created:{' '}
            {dayjs(selectedAppointment?.createdAt).format('h:mm A, MM/DD/YYYY') ?? '-'}
          </S.Text>
        </S.Side>
        <S.Side>
          <S.Text>Last Edited by: {selectedAppointment?.updatedBy ?? '-'} </S.Text>
          <S.Text>
            Time edited:{' '}
            {dayjs(selectedAppointment?.updatedAt).format('h:mm A, MM/DD/YYYY') ?? '-'}{' '}
          </S.Text>
        </S.Side>
      </S.WrapperHistory>

      {selectedAppointment?.billingWhoChecked?.profile?.firstName && (
        <S.WrapperHistoryBilling>
          <S.Side>
            <S.Text>
              Checked by:{' '}
              {selectedAppointment?.billingWhoChecked?.profile?.firstName
                ? selectedAppointment.billingWhoChecked.profile.firstName +
                  ' ' +
                  selectedAppointment.billingWhoChecked.profile.lastName
                : '-'}{' '}
            </S.Text>
            <S.Text>
              Time checked:{' '}
              {dayjs(selectedAppointment?.checkedDate).format('h:mm A, MM/DD/YYYY') ??
                '-'}{' '}
            </S.Text>
          </S.Side>
        </S.WrapperHistoryBilling>
      )}
    </S.Wrapper>
  );
};

export default AppointmentHistory;
