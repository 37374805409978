import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 38px;
  min-height: 38px;
  border-bottom: 1px solid var(--line, #d7d7d7);
  background: #f9f9fc;
`;

export const Information = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 15px;
  gap: 10px;
  height: 100%;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 150px;
  width: 150px;
`;
