import { TextField } from '@mui/material';
import styled, { css } from 'styled-components';

export const SearchInputWrapper = styled.div``;

export const SearchInputItem = styled(TextField)<{ $isSearch?: boolean }>`
  width: 100%;
  border-radius: 5px;

  & .Mui-focused {
    border: 0.5px solid var(--line, #0084b1) !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    border-radius: 5px;
    padding: 9px 14px 9px 0px;
    line-height: normal;
    height: none;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${props =>
      props.$isSearch
        ? css`
            background: #e5f0f6;
            border: 0.5px solid #0084b1;
          `
        : css`
            background: #fff;
            border: 0.5px solid var(--line, #d7d7d7);
          `}
  }

  & .MuiInputBase-input {
    padding: 0;
  }
`;
