import { FormikProps } from 'formik';
import { Input, RadioButton, SelectInput } from '../../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import { MenuItem, RadioGroup, SelectChangeEvent } from '@mui/material';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { DebouncedFunc } from 'lodash';
import { PageACAInsuranceType } from '../../../pages/PageACAInsurance/PageACAInsuranceType';

type Props = {
  formik: FormikProps<PageACAInsuranceType>;
  setIsDirty?: () => void;
  // ref: React.MutableRefObject<{}>;
};
const CoInsurancePolicy = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Line>
        <S.Caption> What is the co/insurance on policy?</S.Caption>
        <S.Text>In-Network:</S.Text>
        <S.InputWrapper>
          <SelectInput
            label=""
            defaultValue={formik.values?.insuranceCoOnPolicy.inNetwork}
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleChangeInputRef(
                e as ChangeEvent<HTMLInputElement>,
                'insuranceCoOnPolicy.inNetwork',
                ref,
                setIsDirty,
              )
            }
          >
            <MenuItem value={'50%/50%'}>
              <S.MenuItemContent>50%/50%</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'40%/60%'}>
              <S.MenuItemContent>40%/60%</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'60%/40%'}>
              <S.MenuItemContent>60%/40%</S.MenuItemContent>
            </MenuItem>
          </SelectInput>
        </S.InputWrapper>

        <S.Text>Out-Network:</S.Text>
        <S.InputWrapper>
          <SelectInput
            label=""
            defaultValue={formik.values?.insuranceCoOnPolicy.outNetwork}
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleChangeInputRef(
                e as ChangeEvent<HTMLInputElement>,
                'insuranceCoOnPolicy.inNetwork',
                ref,
                setIsDirty,
              )
            }
          >
            <MenuItem value={'50%/50%'}>
              <S.MenuItemContent>50%/50%</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'40%/60%'}>
              <S.MenuItemContent>40%/60%</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'60%/40%'}>
              <S.MenuItemContent>60%/40%</S.MenuItemContent>
            </MenuItem>
          </SelectInput>
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.Caption>Does the patient have a copay?</S.Caption>
        <S.Text>In</S.Text>
        <S.InputWrapper>
          <Input
            isSum="$"
            type="number"
            defaultValue={formik.values?.copay?.in}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'copay.in', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.Text>Out</S.Text>
        <S.InputWrapper>
          <Input
            isSum="$"
            type="number"
            defaultValue={formik.values?.copay?.out}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'copay.out', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.Caption>4. Is a primary physician’s referral required?</S.Caption>
        <RadioGroup
          defaultValue={formik.values?.isReferralRequired}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'isReferralRequired', ref, setIsDirty)
          }
        >
          <S.RadioItems>
            <RadioButton value={true} rightPos label="Yes" />
            <RadioButton value={false} rightPos label="No" />
          </S.RadioItems>
        </RadioGroup>
      </S.Line>
      <S.Line>
        <S.Caption>5. Is prior authorization required?</S.Caption>
        <RadioGroup
          defaultValue={formik.values?.isPriorAuthRequired}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'isPriorAuthRequired', ref, setIsDirty)
          }
        >
          <S.RadioItems>
            <RadioButton value={true} rightPos label="Yes" />
            <RadioButton value={false} rightPos label="No" />
          </S.RadioItems>
        </RadioGroup>
      </S.Line>
      <S.Line>
        <S.Caption>6. Are these procedure codes covered?</S.Caption>
        <S.BlockCheckbox>
          <CheckBoxWithLabel
            label="97813"
            defaultChecked={formik.values?.procCodeCovered[97813]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'procCodeCovered.97813', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="97814"
            defaultChecked={formik.values?.procCodeCovered[97814]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'procCodeCovered.97814', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="97810"
            defaultChecked={formik.values?.procCodeCovered[97810]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'procCodeCovered.97810', ref, setIsDirty)
            }
          />
          <CheckBoxWithLabel
            label="97811"
            defaultChecked={formik.values?.procCodeCovered[97811]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'procCodeCovered.97811', ref, setIsDirty)
            }
          />
        </S.BlockCheckbox>
      </S.Line>
      <S.Line>
        <Input
          label="Note"
          defaultValue={formik.values?.notes}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeInputRef(e, 'notes', ref, setIsDirty)
          }
        />
      </S.Line>
      <S.Line>
        <S.Block>
          <S.InputWrapper className="px360">
            <Input
              label="Associate’s Name"
              defaultValue={formik.values?.associatesName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'associatesName', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper className="px360">
            <Input
              label="Reference #"
              defaultValue={formik.values?.reference}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'reference', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Block>
      </S.Line>
    </>
  ),
);

export default CoInsurancePolicy;
