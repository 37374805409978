import { useEffect, useState } from 'react';
import { SwitchCustom } from '../../../../../../components';
import * as S from './styles';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useHideNotificationPopupsMutation } from '../../../../../../store/api/notifications/notificationsApi';
import { useAuthMeMutation } from '../../../../../../store/api/auth/authApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setUser } from '../../../../../../store/api/auth/authSlice';

const HideNotify = () => {
  const [isShow, setIsShow] = useState(false);
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.auth);

  const [changePopup, status] = useHideNotificationPopupsMutation({});
  const [me] = useAuthMeMutation();

  useEffect(() => {
    setIsShow(userInfo.isNotificationsEnabled);
  }, [userInfo]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Article>Show pop-up notifications</S.Article>
        <S.Element>
          <S.Label>{isShow ? 'On' : 'Off'}</S.Label>
          <SwitchCustom
            disabled={status.isLoading}
            value={isShow}
            checked={isShow}
            onChange={() => {
              changePopup({})
                .unwrap()
                .then(res =>
                  me({})
                    .unwrap()
                    .then(resMe => dispatch(setUser(resMe))),
                );
              setIsShow(!isShow);
            }}
          />
        </S.Element>
      </S.Content>
    </S.Wrapper>
  );
};

export default HideNotify;
