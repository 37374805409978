import { Dialog, DialogContent } from '@mui/material';
import * as S from './styles';
import React from 'react';
import { DialogHeader } from '../../../pages/Auth/components/SignUpDetails/styles';
import { CloseBtnSVG } from '../../../assets/icons';

type Props = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const DialogItem: React.FC<Props> = ({ open, onClose, children }) => {
  return (
    <S.DialogItem open={open} onClose={onClose}>
      <DialogHeader>
        <CloseBtnSVG onClick={() => onClose()} />
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
    </S.DialogItem>
  );
};

export default DialogItem;
