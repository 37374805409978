import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import * as S from '../../styles';
import {
  TooltipDatePicker,
  TooltipSelectInput,
  RadioButton,
  TooltipInput,
  Input,
  Dialog,
} from 'components';
import { StatesUSA } from 'constants/statesUSA';
import { MenuItem, RadioGroup, SelectChangeEvent, Tooltip } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { Relation } from 'types/enums/AppointmentEnum';
import { ReadBack } from '../ReadBack';
import { useFormik } from 'formik';
import { InsuranceInfoFormType } from 'types/AppointmentNewTypes';
import dayjs from 'dayjs';
import { insuranceClaimSchema } from 'pages/Billing/components/Claims/formikClaim';
import { ClaimActionEnum } from '../../ClaimForm';
import PayerSearchDialog from 'components/molecules/PayerSearchDialog';
import { PayerRow } from 'types/Payer';
import { SearchIconSVG } from 'assets/icons';

type Props = {
  initialValues: Partial<InsuranceInfoFormType>;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  setIsDirty: (isDirty: boolean) => void;
};

export const InsuranceInfoClaim = memo(
  ({ initialValues, getData, isSave, setIsSave, setIsDirty }: Props) => {
    const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<PayerRow | null>(null);

    const insuranceFormik = useFormik<Partial<InsuranceInfoFormType>>({
      initialValues,
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: insuranceClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await insuranceFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(insuranceFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (insuranceFormik.dirty) {
        setIsDirty(true);
      }
    }, [insuranceFormik.dirty]);

    useEffect(() => {
      if (selectedRow) {
        insuranceFormik.setValues({
          ...insuranceFormik.values,
          insuredPayerId: selectedRow.payerId,
          insurancePlanNameOrProgramName: selectedRow.payerName,
        });
      }
    }, [selectedRow]);

    return (
      <S.BlockWrapper>
        <S.Article>Insurance Info</S.Article>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              isRequired
              label="1.a Insured I.D. Number"
              value={insuranceFormik.values.insuredIdNumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredIdNumber', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredIdNumber}
              tooltipHelperText={insuranceFormik.errors.insuredIdNumber}
            />
          </S.InputWrapper>
          <RadioGroup
            value={insuranceFormik.values.patientRelationshipToInsured}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              insuranceFormik.setFieldValue(
                'patientRelationshipToInsured',
                e.target.value,
              );
            }}
          >
            <S.RadioItem>
              <S.Label>
                <S.LabelArticle>6. Patient Relationship to Insured *</S.LabelArticle>
              </S.Label>
              <S.RadioButtons>
                <RadioButton label="Self" value={Relation.SELF} />
                <RadioButton label="Spouse" value={Relation.SPOUSE} />
                <RadioButton label="Child" value={Relation.CHILD} />
                <RadioButton label="Other" value={Relation.OTHER} />
              </S.RadioButtons>
            </S.RadioItem>
          </RadioGroup>
        </S.Line>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              isRequired
              label="4.Insured's Name"
              value={insuranceFormik.values.insuredName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredName', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredName}
              tooltipHelperText={insuranceFormik.errors.insuredName}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <TooltipDatePicker
              isRequired
              label="11 a.Insured's date of Birth"
              value={dayjs(insuranceFormik.values.insuredBirthDate)}
              onChange={date => insuranceFormik.setFieldValue('insuredBirthDate', date)}
              error={!!insuranceFormik.errors.insuredBirthDate}
              tooltipHelperText={insuranceFormik.errors.insuredBirthDate}
            />
          </S.InputWrapper>
          <RadioGroup
            value={insuranceFormik.values.insuredGender}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              insuranceFormik.setFieldValue('insuredGender', e.target.value)
            }
          >
            <S.RadioItem>
              <S.Label>
                <S.LabelArticle>Gender *</S.LabelArticle>
              </S.Label>
              <Tooltip
                title={insuranceFormik.errors.insuredGender ?? ''}
                placement={'right'}
              >
                <S.RadioButtons
                  className={insuranceFormik.errors.insuredGender ? 'error' : ''}
                >
                  <RadioButton label="M" value={'Male'} />
                  <RadioButton label="F" value={'Female'} />
                </S.RadioButtons>
              </Tooltip>
            </S.RadioItem>
          </RadioGroup>
        </S.Line>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              isRequired
              label="7. Insured's Address Person's"
              value={insuranceFormik.values.insuredAddress}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredAddress', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredAddress}
              tooltipHelperText={insuranceFormik.errors.insuredAddress}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px180">
            <TooltipInput
              isRequired
              label="City"
              value={insuranceFormik.values.insuredCity}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredCity', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredCity}
              tooltipHelperText={insuranceFormik.errors.insuredCity}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px180">
            <TooltipSelectInput
              isRequired
              label="State"
              id="state"
              name="state"
              value={insuranceFormik.values.insuredState}
              onChange={(e: SelectChangeEvent<unknown>) =>
                insuranceFormik.setFieldValue('insuredState', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredState}
              tooltipHelperText={insuranceFormik.errors.insuredState}
            >
              {StatesUSA.map(state => (
                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                  <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                </MenuItem>
              ))}
            </TooltipSelectInput>
          </S.InputWrapper>
          <S.InputWrapper className="px180">
            <TooltipInput
              isRequired
              label="Zip Code"
              value={insuranceFormik.values.insuredZip}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredZip', e.target.value)
              }
              error={!!insuranceFormik.errors.insuredZip}
              tooltipHelperText={insuranceFormik.errors.insuredZip}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px180">
            <ReactInputMask
              mask="+19999999999"
              value={insuranceFormik.values.insuredPhone ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('insuredPhone', e.target.value)
              }
            >
              <TooltipInput
                label="Phone"
                isRequired
                error={!!insuranceFormik.errors.insuredPhone}
                tooltipHelperText={insuranceFormik.errors.insuredPhone}
              />
            </ReactInputMask>
          </S.InputWrapper>
        </S.Line>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              label="11 c.Insurance plan name or program name"
              value={insuranceFormik.values.insurancePlanNameOrProgramName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue(
                  'insurancePlanNameOrProgramName',
                  e.target.value,
                )
              }
              error={!!insuranceFormik.errors.insurancePlanNameOrProgramName}
              tooltipHelperText={insuranceFormik.errors.insurancePlanNameOrProgramName}
            />
          </S.InputWrapper>
          <S.InputWrapper className={'px180'}>
            <ReactInputMask
              id="insuredPayerId"
              name="insuredPayerId"
              mask={'*****'}
              value={insuranceFormik.values?.insuredPayerId ?? ''}
              maskPlaceholder={'_'}
              onChange={e => {
                insuranceFormik.setFieldValue('insuredPayerId', e.target.value);
              }}
            >
              <Input label="PayerID" />
            </ReactInputMask>
          </S.InputWrapper>
          <S.SearchBtn onClick={() => setIsOpenSearchDialog(true)}>
            <SearchIconSVG />
            Search
          </S.SearchBtn>
          <S.Label>
            <S.LabelArticle>11 b.Other Claim ID</S.LabelArticle>
            <S.Line>
              <S.ConnectLine>
                <S.InputWrapper className="px90">
                  <TooltipInput
                    label=""
                    value={insuranceFormik.values.otherClaimIdType}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      insuranceFormik.setFieldValue('otherClaimIdType', e.target.value)
                    }
                    error={!!insuranceFormik.errors.otherClaimIdType}
                    tooltipHelperText={insuranceFormik.errors.otherClaimIdType}
                  />
                </S.InputWrapper>
                <S.InputWrapper className="px180">
                  <TooltipInput
                    label=""
                    value={insuranceFormik.values.otherClaimId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      insuranceFormik.setFieldValue('otherClaimId', e.target.value)
                    }
                    error={!!insuranceFormik.errors.otherClaimId}
                    tooltipHelperText={insuranceFormik.errors.otherClaimId}
                  />
                </S.InputWrapper>
              </S.ConnectLine>
            </S.Line>
          </S.Label>
        </S.Line>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              isRequired
              label="11. Insured's Policy Group or FECA Number"
              value={insuranceFormik.values.insuredPolicyGroupFECANumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue(
                  'insuredPolicyGroupFECANumber',
                  e.target.value,
                )
              }
              error={!!insuranceFormik.errors.insuredPolicyGroupFECANumber}
              tooltipHelperText={insuranceFormik.errors.insuredPolicyGroupFECANumber}
            />
          </S.InputWrapper>
          <RadioGroup
            value={insuranceFormik.values.isAnotherHealthBenefitPlan}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              insuranceFormik.setFieldValue(
                'isAnotherHealthBenefitPlan',
                e.target.value === 'true',
              );
              insuranceFormik.setFieldValue('otherInsuredName', '');
            }}
          >
            <S.RadioItem>
              <S.Label>
                <S.LabelArticle>d. Is There another heath benefit plan? *</S.LabelArticle>
              </S.Label>
              <S.RadioButtons>
                <RadioButton label="Yes" value={true} />
                <RadioButton label="No" value={false} />
              </S.RadioButtons>
            </S.RadioItem>
          </RadioGroup>
        </S.Line>

        <S.Line>
          <S.InputWrapper>
            <TooltipInput
              label="8. Reserved for NUCC use"
              value={insuranceFormik.values.reservedForNUCC}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('reservedForNUCC', e.target.value)
              }
            />
          </S.InputWrapper>
          <S.InputWrapper className="px480">
            <TooltipInput
              label="9. Other Insured’s Name (Last Name, First Name, Middle Initial)"
              value={insuranceFormik.values.otherInsuredName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                insuranceFormik.setFieldValue('otherInsuredName', e.target.value)
              }
              disabled={!insuranceFormik.values.isAnotherHealthBenefitPlan}
            />
          </S.InputWrapper>
        </S.Line>

        <ReadBack insuranceFormik={insuranceFormik} />

        <Dialog
          open={isOpenSearchDialog}
          onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
        >
          <PayerSearchDialog
            onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Dialog>
      </S.BlockWrapper>
    );
  },
);
