import dayjs from 'dayjs';
import { CoveredServicesInformation } from 'types/enums/AppointmentEnum';

export const configVRCount = (count: number) => {
  switch (count) {
    case 9999:
      return 'UV';
    default:
      return count;
  }
};

export const configColor = (service: CoveredServicesInformation) => {
  const { startDate, endDate } = service.insuranceInfo;
  const currentDate = dayjs();

  const startCheck = startDate && currentDate.isAfter(startDate);
  const endCheck = endDate ? currentDate.isBefore(endDate) : true;

  if (startCheck && endCheck && service.count > 0) {
    return '#9BC15F';
  } else {
    return '#C66060';
  }
};
