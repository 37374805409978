import { Font, StyleSheet } from '@react-pdf/renderer';
import InterRegular from '../../../../../../../fonts/Inter-Regular.ttf';
import InterBold from '../../../../../../../fonts/Inter-Bold.ttf';
import InterMedium from '../../../../../../../fonts/Inter-Medium.ttf';

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontWeight: 400,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
    {
      src: InterMedium,
      fontWeight: 600,
    },
  ],
});

export const generateStylesConfig = () => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      color: '#202E5F',
      flexDirection: 'column',
      gap: 10,
      fontFamily: 'Inter',
      margin: 10,
      padding: 10,
    },
    text: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Inter',
      color: '#202E5F',
    },
    grayText: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Inter',
      color: '#848A9B',
    },
    articleText: {
      fontSize: 12,
      textDecoration: 'underline',
      fontWeight: 400,
      fontFamily: 'Inter',
      color: '#202E5F',
    },
    article: {
      fontSize: 12,
      fontFamily: 'Inter',
      fontWeight: 700,
      color: '#202E5F',
    },
    textWithPadding: {
      fontSize: 12,
      fontFamily: 'Inter',
      fontWeight: 400,
      color: '#202E5F',
      marginRight: 100,
    },
    textWithBigPadding: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Inter',
      color: '#202E5F',
      marginRight: 120,
    },
    span: {
      fontSize: 12,
      fontWeight: 400,
      color: '#848A9B',
      fontFamily: 'Inter',
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontFamily: 'Inter',
      gap: 10,
    },
    element: {
      display: 'flex',
      fontFamily: 'Inter',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 5,
    },
    placeholder: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Inter',
      color: '#848A9B',
    },
    viewer: {
      fontFamily: 'Inter',
      width: window.innerWidth / 2,
      height: window.innerHeight / 1.5,
    },
  });
  return styles;
};
