import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 18px;
  width: 100%;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 18px;
  width: 100%;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 16px;
  margin-right: 9px;
`;

export const InputWrapper = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
  width: 100%;
`;

export const Role = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

export const ServicesBox = styled.div`
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
`;

export const Services = styled.div`
  color: var(--icon, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: -10px;

  &:after {
    content: ', ';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`;

export const Note = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  word-break: normal;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 300px;
  flex-wrap: wrap;
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
`;

export const RowArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;

  align-items: center;
  gap: 8px;
`;

export const InfoValue = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;

  width: 140px;
`;

export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EditInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RowItems = styled.div``;

export const RowText = styled.div`
  width: 130px;
`;

export const BlockRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0.5px solid var(--line, #d7d7d7);
`;

export const UpperArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const ReceptionistName = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UpperInfoText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AvatarDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin-top: 20px;

  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & svg {
    width: 70px;
    height: 70px;
  }

  & img {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    object-fit: cover;
  }
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const AvatarClickZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const AvatarZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 70px;
  }
`;

export const DeleteCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #c66060;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 70px;
  right: 115px;
  cursor: pointer;
`;

export const AvatarHelper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media ${devices.xs} {
    width: 310px;
    margin-top: 8px;
  }
`;

export const TextHelper = styled.span`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectInputOutput = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;

  & svg {
    position: absolute;
  }
`;

export const ServiceChip = styled.div`
  color: var(--text, #0084b1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;

  background-color: #acdef2;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    cursor: pointer;
    width: 18px;
    margin-top: 4px;
  }
`;

export const SelectInputOutputText = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
