import { AnyIfEmpty } from 'react-redux';
import { Appointments } from '../../../types/AppointmentsTypes';
import { InitialDocumentsFolders, TemplateFolders } from '../../../types/TemplateType';
import { Treatment } from '../../../types/TreatmentType';
import { api } from '../api';

type Response<T> = {};

export const treatmentApi = api.injectEndpoints({
  endpoints: builder => ({
    treatmentCreate: builder.mutation({
      query: data => ({
        url: '/treatment',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Appointments', 'User'],
    }),
    treatmentGet: builder.query<Treatment, any>({
      query: ({ id, ...data }) => ({
        url: `/treatment/${id}`,
        params: { ...data },
      }),
      providesTags: ['Appointments', 'User'],
    }),
    treatmentUpdate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/treatment/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Appointments', 'User'],
    }),
    foldersDocumentsGet: builder.query<TemplateFolders, any>({
      query: ({ userId, clinicId, ...data }) => ({
        url: `/template-folder/${userId}/${clinicId}`,
        params: { ...data },
      }),
      providesTags: ['Documents'],
    }),
    initialDocuments: builder.mutation<InitialDocumentsFolders[], any>({
      query: ({ userId, clinicId, ...data }) => ({
        url: `/template-folder/${userId}/initial`,
        method: 'GET',
        params: { ...data },
      }),
    }),
    otherDocuments: builder.mutation<InitialDocumentsFolders[], any>({
      query: ({ userId, clinicId, ...data }) => ({
        url: `/template-folder/${userId}/${clinicId}/other`,
        method: 'GET',
        params: { ...data },
      }),
    }),
    formsDocuments: builder.mutation<InitialDocumentsFolders[], any>({
      query: ({ userId, clinicId, appointmentId, ...data }) => ({
        url: `/template-folder/${userId}/${clinicId}/forms/${appointmentId}`,
        method: 'GET',
        params: { ...data },
      }),
    }),
  }),
});

export const {
  useTreatmentCreateMutation,
  useTreatmentGetQuery,
  useTreatmentUpdateMutation,
  useFoldersDocumentsGetQuery,
  useOtherDocumentsMutation,
  useInitialDocumentsMutation,
  useFormsDocumentsMutation,
} = treatmentApi;
