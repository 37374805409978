import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 538px;
  padding-bottom: 70px;
  @media ${devices.xs} {
    width: 375px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const OptionWrap = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 20px;
  margin: 0px 19px;
`;

export const SelectedCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubArticle = styled.div`
  margin: 10px 28px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Name = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #f4f4f4;

  @media ${devices.xs} {
    width: 100%;
    background: none;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 18px;
  }
`;

export const BtnWrapper = styled.div`
  margin-right: 18px;
  width: 174px;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const LiWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Avatar = styled.div`
  img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    object-fit: cover;
  }
`;

export const Visits = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  flex-wrap: wrap;
  max-width: 200px;
  gap: 5px;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DateOfBirth = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Pins = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  max-width: 250px;
  height: auto;
  flex-wrap: wrap;
`;

export const UserSpan = styled.span`
  color: var(--icon, #727785);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const InputWrapper = styled.div`
  width: 100%;

  & .MuiButtonBase-root.MuiChip-root {
    display: none;
    color: var(--icon, #848a9b);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 5px;
    margin-right: 10px;
    background: #e5f0f6;
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-root {
    padding: 0;

    & .Mui-focused {
      border: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 5px;
    }
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: 38px;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
