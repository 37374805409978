import { useNavigate } from 'react-router-dom';
import { PlaceHolderSuccessSVG, PlaySVG } from '../../../../../../assets/icons';
import { getRouteTemplate } from '../../configTemplate';
import { TemplateNameType } from '../../../../../../types/TemplateType';
import { Dialog, SuccessDialog } from '../../../../../../components';
import { useState } from 'react';
import StartPDFDialog from '../StartPDFDialog';
import * as S from '../../styles';

type Props = {
  row: any;
};

const Play: React.FC<Props> = ({ row }) => {
  const navigate = useNavigate();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

  const [userId, setUserId] = useState('');
  return (
    <>
      <Dialog
        open={isShowConfirmDialog}
        onClose={() => setIsShowConfirmDialog(!isShowConfirmDialog)}
      >
        <SuccessDialog
          helperText="All changes will be lost"
          successText="Yes"
          onClose={() => setIsShowConfirmDialog(!isShowConfirmDialog)}
          titleText="Are you sure want to close this popup?"
          onSuccess={() => setIsOpenDialog(!isOpenDialog)}
        />
      </Dialog>
      <Dialog
        open={isOpenDialog}
        onClose={() => {
          setIsShowSuccess(false);
          !isShowSuccess
            ? setIsShowConfirmDialog(!isShowConfirmDialog)
            : setIsOpenDialog(!isOpenDialog);
        }}
      >
        {!isShowSuccess ? (
          <StartPDFDialog
            onClose={() => setIsOpenDialog(!isOpenDialog)}
            row={row}
            isOpen={isOpenDialog}
            setUserId={setUserId}
            setIsShowSuccess={setIsShowSuccess}
          />
        ) : (
          <S.PlaceholderContent>
            <PlaceHolderSuccessSVG />
            <S.Text>The Document was saved successfully!</S.Text>
            <S.Text>
              You can find it{' '}
              <S.Span
                onClick={() =>
                  navigate(`/patients/${userId}`, {
                    state: { templateDocuments: true },
                  })
                }
              >
                here
              </S.Span>{' '}
              in the documents section
            </S.Text>
          </S.PlaceholderContent>
        )}
      </Dialog>
      <PlaySVG
        className="px18"
        onClick={() => {
          row?.type === TemplateNameType.DIGITAL && navigate(`${getRouteTemplate(row)}`);
          row?.type === TemplateNameType.PDF && setIsOpenDialog(true);
        }}
      />
    </>
  );
};

export default Play;
