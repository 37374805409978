import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const CredentialsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  @media ${devices.xs} {
    margin-top: 45px;
  }
`;
