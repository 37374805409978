import styled from 'styled-components';

export const Wrapper = styled.div`
  & .MuiSwitch-root {
    padding: 0;
  }

  & .MuiSwitch-track {
    margin: 0;
    width: 48px;
    height: 28px;
    border-radius: 12px;
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: #0084b1;
    opacity: 1;
  }

  & .MuiSwitch-switchBase {
    padding: 3px 3px;
  }

  & .MuiSwitch-thumb {
    height: 22px;
    width: 22px;
    background-color: #ffffff;
  }
`;
