import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { InsuranceWithGroupsType } from 'types/InsuranceType';
import { InsuranceTableCell } from './InsuranceTableCell';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { Dialog } from 'components';
import { setMessage } from 'store/slices/message';
import { useInsuranceWithGroupNumbersDeleteMutation } from 'store/api/insuranceRelative/insuranceRelativeApi';
import { useAppDispatch } from 'hooks/useAppDispatch';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: InsuranceWithGroupsType[] | undefined;
  headers: HeaderValue[];
  clickable?: boolean;
};

export const InsuranceTableRow = ({ data, clickable, headers }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showConfirmDialog, setShowConfirmDialog] = useState<string | null>(null);
  const [deleteInsurance] = useInsuranceWithGroupNumbersDeleteMutation({});

  const handleSuccessDelete = () => {
    !!showConfirmDialog &&
      deleteInsurance({
        id: showConfirmDialog,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Insurance was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        })
        .finally(() => setShowConfirmDialog(null));
  };

  return (
    <>
      {data &&
        data.map((row, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && navigate(`${row.id}`);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <InsuranceTableCell
                  header={header.key}
                  row={row}
                  setShowConfirmDialog={setShowConfirmDialog}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}

      <Dialog open={!!showConfirmDialog} onClose={() => setShowConfirmDialog(null)}>
        <ConfirmDeleteDialog
          onClose={() => setShowConfirmDialog(null)}
          titleText="insurance"
          helperText="This process is irreversible"
          onSuccess={handleSuccessDelete}
        />
      </Dialog>
    </>
  );
};
