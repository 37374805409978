import { LinearProgress, Tab } from '@mui/material';
import {
  LogoutDiv,
  MainProfileInformation,
  SwitchCustom,
  TabsItem,
  WorkSchedule,
} from '../../../../components';
import { useUserProfileGetQuery } from '../../../../store/api/user/profileApi';
import * as S from './styles';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Roles } from '../../../../types/Roles';
import HideNotify from './components/HideNotify';
import StaffMainProfile from '../../../../components/organismus/StaffMainProfile';
import StaffHealthRecords from 'pages/SuperAdmin/components/ProfileStaff/components/StaffHealthRecords';

const Profile = () => {
  const profile = useUserProfileGetQuery({});
  const [tabIndex, setTabIndex] = useState(0);
  const userInfo = useAppSelector(state => state.auth);

  return (
    <S.ProfileWrapper>
      {profile.isLoading || profile.isFetching ? (
        <S.Loader>
          <LinearProgress />
        </S.Loader>
      ) : (
        <>
          <S.SubtitleBar>
            <S.SubtitleText>My Profile</S.SubtitleText>
            <S.SubtitleItems></S.SubtitleItems>
          </S.SubtitleBar>
          {userInfo.role === Roles.DOCTOR && (
            <S.TabWrapper>
              <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
                <Tab disableRipple label="Profile Info"></Tab>
                <Tab disableRipple label="Schedule"></Tab>
                <Tab disableRipple label="Health records"></Tab>
              </TabsItem>
            </S.TabWrapper>
          )}
          <S.Content>
            <S.ProfileInformation
              className={userInfo.role === Roles.DOCTOR ? 'doctorscroll' : ''}
            >
              {userInfo.role === Roles.PATIENT ? (
                <MainProfileInformation rows={profile.currentData} />
              ) : (
                <StaffMainProfile row={profile.currentData} />
              )}
            </S.ProfileInformation>

            {tabIndex === 0 && (
              <S.LogoutDivWrapper
                className={userInfo.role === Roles.DOCTOR ? 'doctorscroll' : ''}
              >
                <HideNotify />

                <LogoutDiv signature={profile.data?.signature} />
              </S.LogoutDivWrapper>
            )}
            {tabIndex === 1 && (
              <S.LogoutDivWrapper
                className={userInfo.role === Roles.DOCTOR ? 'doctorscroll' : ''}
              >
                <WorkSchedule />
              </S.LogoutDivWrapper>
            )}
            {tabIndex === 2 && (
              <S.LogoutDivWrapper
                className={userInfo.role === Roles.DOCTOR ? 'doctorscroll' : ''}
              >
                <StaffHealthRecords />
              </S.LogoutDivWrapper>
            )}
          </S.Content>
        </>
      )}
    </S.ProfileWrapper>
  );
};

export default Profile;
