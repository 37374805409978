import styled from 'styled-components';
import { devices } from '../../../../../../../../constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const InputAutoCompleteWrapper = styled.div`
  width: 100%;

  & .MuiButtonBase-root.MuiChip-root {
    color: var(--icon, #848a9b);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 5px;
    margin-right: 10px;
    background: #e5f0f6;
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-root {
    padding: 0;

    & .Mui-focused {
      border: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 5px;
    }
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: 38px;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const SubArticle = styled.div`
  margin-top: 21px;
  margin-bottom: 13px;
  width: 362px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const InputWrapper = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media ${devices.xs} {
    width: 250px;
  }
`;

export const Patient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PatientTitle = styled.div`
  padding-left: 6px;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 5px;

  svg,
  img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    object-fit: cover;
  }
`;

export const LiWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Avatar = styled.div`
  img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    object-fit: cover;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DateOfBirth = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Pins = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  max-width: 250px;
  height: auto;
  flex-wrap: wrap;
`;
