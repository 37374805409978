import dayjs from 'dayjs';
import * as S from '../styles';
import {
  AvatarSVG,
  ClinicLogoSVG,
  ClinicMenuIconSVG,
  ClinicSVG,
} from '../../../../../assets/icons';
import { Clinics } from '../../../../../types/ClinicTypes';
import { UserStatusPin } from '../../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';

type Props = {
  row: any;
  header: string;
};

const ClinicsData: React.FC<Props> = ({ row, header }) => {
  const navigate = useNavigate();
  switch (header) {
    case 'ClinicName': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.logo ? <img src={row.logo.url} alt="avatar" /> : <ClinicLogoSVG />}
          </S.Avatar>
          {row.name || '-'}
        </S.CellWrapper>
      );
    }

    case 'Phone': {
      return row.phone ? (
        <S.CellColumnWrapper>
          {row.phone.map((item: string) => (
            <S.ColumnText key={item}>{item}</S.ColumnText>
          ))}
        </S.CellColumnWrapper>
      ) : (
        '-'
      );
    }

    case 'Address': {
      return row.state
        ? row?.state + ', ' + row?.city + ', ' + row?.street + ', ' + row?.zipCode
        : '-';
    }
    case 'LocalAdmin': {
      return row.localAdmin?.profile?.firstName
        ? row.localAdmin.profile.firstName + ' ' + row.localAdmin.profile.lastName
        : '-';
    }
    case 'Email': {
      return row.email || '-';
    }
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin type={row.status} />
        </S.CellWrapper>
      );
    }

    case 'Chatlink': {
      return (
        <S.LinkWrapper
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            navigate('/chats');
          }}
        >
          Clinic chat
        </S.LinkWrapper>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default ClinicsData;
