import { AppointmentStatus } from '../../../../../types/StatusTypes';

export const configStatuses = (status: AppointmentStatus | string | undefined) => {
  switch (status) {
    case AppointmentStatus.ACTIVE:
      return ['canceled', 'active', 'completed'];
    case AppointmentStatus.CANCELED:
      return ['canceled'];
    case AppointmentStatus.COMPLETED:
      return ['completed'];
    case AppointmentStatus.PENDING:
      return ['canceled', 'pending', 'active'];
    default:
      return;
  }
};
