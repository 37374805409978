import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InvInfPrepData from './InvInfPostData';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: Array<Object> | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
};

export const InvInfPostRow: React.FC<Props> = ({ headers, data, clickable }) => {
  const navigate = useNavigate();

  return (
    <>
      {data &&
        data.map((row: any, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && navigate('/invoices/' + row.id);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <InvInfPrepData key={header.key} header={header.key} row={row} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};
