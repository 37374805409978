import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const NotificationRowWrapper = styled.div<{ $isRead: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin-left: 12px;
  margin-right: 12px;
  height: auto;
  width: auto;
  padding: 5px 0px;
  cursor: pointer;
  border-radius: 8px;

  background: ${props => (props.$isRead ? '#E1EBEE' : '#fff')};

  @media ${devices.xs} {
    gap: 10px;
  }
`;

export const AvatarDiv = styled.div`
  margin-left: 3px;
  background-color: #e1ebee;
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    object-fit: cover;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-right: 10px;
`;

export const Title = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #202e5f;
`;

export const Message = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #202e5f;
`;

export const VisitName = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 12px;
  }
`;

export const Approved = styled.span`
  color: #79a030;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 12px;
  }
`;

export const Canceled = styled.span`
  color: #c66060;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 12px;
  }
`;

export const DefaultText = styled.span`
  color: var(--text, #202e5f);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 12px;
  }
`;

export const Specialist = styled.span`
  cursor: pointer;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 12px;
  }
`;
