import GenerateACAInsurance from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GenerateACAInsurance';
import GenerateClientRegistrationForm from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GenerateClientRegistrationForm';
import GenerateInitialVisitForm from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GenerateInitialVisitForm';
import GeneratePTInitial from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GeneratePTInitial';
import GeneratePTInsurance from '../../../pages/SuperAdmin/components/Templates/components/GenerateEmptyDigital/GeneratePTInsurance';
import { AppointmentProcessInterface, Step } from '../../../types/AppointmentNewTypes';
import {
  DigitalTemplateType,
  DigitalTemplateTypeByName,
} from '../../../types/TemplateType';
import { getCurrentStep } from './functions/appProcessFunctions';

export const configGenerateDigitalPDF = (
  data?: AppointmentProcessInterface,
  signature?: any,
) => {
  switch (getCurrentStep(data)?.digitalForm?.type) {
    case DigitalTemplateType.PT_THERAPY: {
      return (
        <GeneratePTInsurance
          data={getCurrentStep(data)?.digitalForm}
          signature={signature}
        />
      );
    }
    case DigitalTemplateType.ACUPUNCTURE_INSURANCE:
      return (
        <GenerateACAInsurance
          data={getCurrentStep(data)?.digitalForm}
          signature={signature}
        />
      );
    case DigitalTemplateType.INITIAL:
      return (
        <GenerateInitialVisitForm
          data={getCurrentStep(data)?.digitalForm}
          signature={signature}
        />
      );
    case DigitalTemplateType.PT_INITIAL:
      return (
        <GeneratePTInitial
          data={getCurrentStep(data)?.digitalForm}
          signature={signature}
        />
      );
    case DigitalTemplateType.CLIENT_REGISTRATION:
      return (
        <GenerateClientRegistrationForm
          data={getCurrentStep(data)?.digitalForm}
          signature={signature}
        />
      );
    default:
      return <></>;
  }
};
