import { ServiceType } from 'types/ServicesType';
import * as S from './styles';

type Props = {
  service: ServiceType;
};

export const CurrentServiceCard = ({ service }: Props) => {
  return (
    <>
      <S.Item>
        <S.ColorBox color={service.color} border="#4E4E4E" />
        <S.Text>{service.serviceName}</S.Text>
      </S.Item>
      {service.isUseForFirstTimeVisit && (
        <S.Item>
          <S.ColorBox color={service.firstVisitColor} border="#4E4E4E" />
          <S.Text>{service.serviceName} (FT)</S.Text>
        </S.Item>
      )}
    </>
  );
};
