import styled from 'styled-components';
import { devices } from '../../constants/device';

export const AuthWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(117.33% 117.32% at 50% 25.19%, #fff 0%, #f1f1fa 100%);

  @media ${devices.xs} {
    align-items: start;
  }
`;

export const LoginBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 520px;
  height: auto;
  padding-bottom: 24px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px -2px 27px 0px rgba(0, 84, 113, 0.2);

  @media ${devices.xs} {
    width: 346px;
    background: none;
    box-shadow: none;
  }

  & .logo {
    margin-top: 33px;
    width: 110px;
    height: 49px;
  }
`;
