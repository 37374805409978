import { useNavigate } from 'react-router-dom';
import { AvatarSVG, EmailIconSVG, PhoneIconSVG } from '../../../../../../../assets/icons';
import Button from '../../../../../../atoms/Button';
import HoldCard from '../../../../../../atoms/HoldCard';
import * as S from './styles';
import { AppointmentsNew } from '../../../../../../../types/AppointmentNewTypes';

type Props = {
  row: AppointmentsNew;
};

const UserInformationPopoverContent: React.FC<Props> = ({ row }) => {
  const navigate = useNavigate();

  return (
    <S.ProfileContent>
      <S.Content>
        <S.AvatarBlock>
          {row.patient?.avatar?.url ? (
            <img src={row.patient?.avatar?.url} alt="avatar" />
          ) : (
            <AvatarSVG />
          )}
        </S.AvatarBlock>
        <S.Info>
          <S.Name>
            {row.patient?.profile?.firstName + ' ' + row.patient?.profile?.lastName}
            {row?.patient?.isHoldAppointment && (
              <HoldCard text="HA" reason={row.patient.isHoldAppointment} />
            )}
            {row?.patient?.isHoldBilling && (
              <HoldCard text="HB" reason={row.patient.isHoldBilling} />
            )}
          </S.Name>

          <S.Phone>
            <PhoneIconSVG />
            {row.patient?.phone}
          </S.Phone>
          <S.Email>
            <EmailIconSVG />
            {row.patient?.email || '-'}
          </S.Email>
        </S.Info>
      </S.Content>
      <S.BtnWrapper>
        <Button
          text="View profile"
          onClick={() => navigate(`/patients/${row.patient?.id}`)}
        />
      </S.BtnWrapper>
    </S.ProfileContent>
  );
};

export default UserInformationPopoverContent;
