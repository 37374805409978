import { AttachSVG, DetachSVG } from '../../../../../../assets/icons';
import { Input, SearchInput, SelectInput } from '../../../../../../components';
import {
  searchKeys,
  searchOperators,
  SearchOperatorsEnum,
  SearchOperatorsForKey,
} from '../../../../../../helpers/FilterSearch/filterSearch';
import * as S from './styles';
import { MenuItem, SelectChangeEvent } from '@mui/material';

type Props = {
  searchKey: string;
  setSearchKey: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  handleSearch: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  operator: string;
  setOperator: React.Dispatch<React.SetStateAction<string>>;

  searchKey2: string;
  setSearchKey2: React.Dispatch<React.SetStateAction<string>>;
  searchValue2: string;
  handleSearch2: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  operator2: string;
  setOperator2: React.Dispatch<React.SetStateAction<string>>;
  setSearchValue2: React.Dispatch<React.SetStateAction<string>>;
  isShowSecondFilter: boolean;
  setIsShowSecondFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchPatients = ({
  searchKey,
  setSearchKey,
  searchValue,
  handleSearch,
  operator,
  setOperator,
  searchKey2,
  setSearchKey2,
  searchValue2,
  handleSearch2,
  operator2,
  setOperator2,
  isShowSecondFilter,
  setIsShowSecondFilter,
  setSearchValue2,
}: Props) => {
  return (
    <S.SearchLines>
      <S.Wrapper>
        <S.SelectWrapper className="xl">
          <SelectInput
            label=""
            background="#fff"
            value={searchKey}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setSearchKey(e.target.value as string);
            }}
          >
            {searchKeys.map(item => {
              return (
                <MenuItem
                  key={item.name}
                  value={item.name}
                  disabled={searchKey2 === item.name && isShowSecondFilter}
                >
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
          </SelectInput>
        </S.SelectWrapper>
        <S.SelectWrapper>
          <SelectInput
            label=""
            value={operator}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setOperator(e.target.value as string);
            }}
            background="#fff"
          >
            <MenuItem value={SearchOperatorsEnum.START_WITH}>
              <S.MenuItemContent>Starts With</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={SearchOperatorsEnum.EQUALS_TO}>
              <S.MenuItemContent>Equals to</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={SearchOperatorsEnum.ILIKE}>
              <S.MenuItemContent>Contains</S.MenuItemContent>
            </MenuItem>
          </SelectInput>
        </S.SelectWrapper>
        <S.SelectWrapper className="xl">
          <SearchInput
            isSearch={searchValue?.length > 0}
            label=""
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
          />
        </S.SelectWrapper>
        {!isShowSecondFilter && (
          <AttachSVG
            color="#0084B1"
            onClick={() => {
              if (searchKey === searchKey2) {
                const newKey = searchKeys.find(key => key.name !== searchKey2);
                newKey?.name && setSearchKey2(newKey.name);
              }
              setIsShowSecondFilter(true);
            }}
          />
        )}
      </S.Wrapper>
      {isShowSecondFilter && (
        <S.Wrapper>
          <S.SelectWrapper className="xl">
            <SelectInput
              label=""
              background="#fff"
              value={searchKey2}
              onChange={(e: SelectChangeEvent<unknown>) => {
                setSearchKey2(e.target.value as string);
              }}
            >
              {searchKeys.map(item => {
                return (
                  <MenuItem
                    key={item.name}
                    value={item.name}
                    disabled={searchKey === item.name && isShowSecondFilter}
                  >
                    <S.MenuItemContent>{item.name}</S.MenuItemContent>
                  </MenuItem>
                );
              })}
            </SelectInput>
          </S.SelectWrapper>
          <S.SelectWrapper>
            <SelectInput
              label=""
              value={operator2}
              onChange={(e: SelectChangeEvent<unknown>) => {
                setOperator2(e.target.value as string);
              }}
              background="#fff"
            >
              <MenuItem value={SearchOperatorsEnum.START_WITH}>
                <S.MenuItemContent>Starts With</S.MenuItemContent>
              </MenuItem>
              <MenuItem value={SearchOperatorsEnum.EQUALS_TO}>
                <S.MenuItemContent>Equals to</S.MenuItemContent>
              </MenuItem>
              <MenuItem value={SearchOperatorsEnum.ILIKE}>
                <S.MenuItemContent>Contains</S.MenuItemContent>
              </MenuItem>
            </SelectInput>
          </S.SelectWrapper>
          <S.SelectWrapper className="xl">
            <SearchInput
              isSearch={searchValue2?.length > 0}
              label=""
              placeholder="Search"
              value={searchValue2}
              onChange={handleSearch2}
            />
          </S.SelectWrapper>
          {isShowSecondFilter && (
            <DetachSVG
              onClick={() => {
                setSearchValue2('');
                setIsShowSecondFilter(false);
              }}
            />
          )}
        </S.Wrapper>
      )}
    </S.SearchLines>
  );
};

export default SearchPatients;
