import styled from 'styled-components';

export const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  &.name {
    justify-content: start;
  }
`;

export const Value = styled.div``;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const SearchWrap = styled.div`
  width: 100%;
  flex-wrap: wrap;
  word-break: break-all;
  display: flex;
  gap: 5px;

  flex-direction: column;
  align-items: center;
`;
