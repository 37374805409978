export enum Roles {
  PATIENT = 'patient',
  RECEPTIONIST = 'receptionist',
  DOCTOR = 'doctor',
  BILLING = 'financial_specialist',
  UNAUTH = 'unauth',
  SUPER_ADMIN = 'super_admin',
  LOCATION_MANAGER = 'location_manager',
}

export type RefType = {
  current: any;
};
