import { TooltipDatePicker, TooltipInput } from 'components';
import * as S from '../../styles';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import { InsuranceInfoFormType } from 'types/AppointmentNewTypes';

type Props = {
  insuranceFormik: FormikProps<Partial<InsuranceInfoFormType>>;
};

export const ReadBack = ({ insuranceFormik }: Props) => {
  return (
    <>
      <S.SmallArticle>
        Read back of form before completing & signing this form
      </S.SmallArticle>
      <S.CardWrapper className={'twoColumn'}>
        <S.Card>
          <S.Caption>
            12. PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize the release of any
            medical or other information necessary to process this claim. I also request
            payment of government benefits either to myself or to the party who accepts
            assignment below.
          </S.Caption>
          <S.LineFromBottom>
            <S.InputWrapper className="px240">
              <TooltipInput
                isRequired
                label="Signed"
                {...insuranceFormik.getFieldProps('patientSignature')}
                error={!!insuranceFormik.errors.patientSignature}
                tooltipHelperText={insuranceFormik.errors.patientSignature}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px240">
              <TooltipDatePicker
                isRequired
                label="Date"
                value={dayjs(insuranceFormik.values.patientSignatureDate)}
                onChange={date =>
                  insuranceFormik.setFieldValue(
                    'patientSignatureDate',
                    dayjs(date).format('YYYY-MM-DD'),
                  )
                }
                error={!!insuranceFormik.errors.patientSignatureDate}
                tooltipHelperText={insuranceFormik.errors.patientSignatureDate}
              />
            </S.InputWrapper>
          </S.LineFromBottom>
        </S.Card>
        <S.Card>
          <S.Caption>
            13. INSURED'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize Payment of medical
            benefits to the undersigned physician or supplier for services described
            below.
          </S.Caption>
          <S.LineFromBottom>
            <S.InputWrapper className="px240">
              <TooltipInput
                isRequired
                label="Signed"
                {...insuranceFormik.getFieldProps('insuredSignature')}
                error={!!insuranceFormik.errors.insuredSignature}
                tooltipHelperText={insuranceFormik.errors.insuredSignature}
              />
            </S.InputWrapper>
          </S.LineFromBottom>
        </S.Card>
      </S.CardWrapper>
    </>
  );
};
