import { Link, redirect, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components';
import * as S from './styles';

const ContactACA = () => {
  const navigate = useNavigate();

  const handleClickContact = () => {
    window.location.href = 'https://acaacupuncture.com/contact-us/';
  };

  return (
    <S.ContactWrapper>
      <S.Content>
        <S.Information>
          <S.Article>Contact ACA</S.Article>
          <S.Text>
            ACA brings the benefits of ancient remedies and modern medicine to address the
            needs of your health and well-being. We offer acupuncture, cosmetic
            acupuncture, tui-na massage, Chinese herbs, cupping services, and more.
          </S.Text>
        </S.Information>
        <S.ButtonWrapper>
          <Button text="Contact Us" onClick={handleClickContact} />
        </S.ButtonWrapper>
      </S.Content>
    </S.ContactWrapper>
  );
};

export default ContactACA;
