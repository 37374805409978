import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  canvas {
    aspect-ratio: 2;
  }
`;
