import { Checkbox } from '@mui/material';
import styled, { css } from 'styled-components';

export const CheckBoxWrapper = styled.div``;

export const CheckBoxItem = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: #555;
    opacity: 0.5;
    border-radius: 3px;

    &.Mui-checked {
      opacity: 1;
      color: #0084b1;
    }
  }

  &.MuiButtonBase-root {
    padding: 0;
  }

  & .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

  &.big {
    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Wrapper = styled.div<{ gap?: string; disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  ${props =>
    props.gap
      ? css`
          gap: ${props.gap};
        `
      : css`
          gap: 5px;
        `}
`;

export const Label = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.article {
    margin-left: 5px;
    font-weight: 500;
  }
`;
