import styled from '@emotion/styled';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div``;

export const PatientInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;
  margin-top: 15px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 490px;
`;

export const AccountNumber = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  margin-left: 5px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  &.half {
    width: 48%;
  }

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const OptionWrap = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const UserName = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SVG = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const DOB = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;

  &.yellow {
    background: yellow;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

export const CompleteWrapper = styled.div`
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-root {
    padding: 0;

    & .Mui-focused {
      border: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 5px;
    }
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: 38px;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }
`;

export const Label = styled.label`
  margin-left: 6px;
  margin-bottom: 2px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 3px;
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
