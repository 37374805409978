import { forwardRef, useRef, useState } from 'react';
import ACAInsurance from '../../../pages/SuperAdmin/components/Templates/components/ACAInsurance';
import InitialVisitForm from '../../../pages/SuperAdmin/components/Templates/components/InitialVisitForm';
import { useTemplatePrintPDFMutation } from '../../../store/api/templates/templateApi';
import { AppointmentsNew, Step } from '../../../types/AppointmentNewTypes';
import { DigitalTemplateTypeByName } from '../../../types/TemplateType';
import PDFDrawer from '../PDFDrawer';
import { chooseAppointmentProcessFormik } from './configFormik';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PTInitialEvaluation from '../../../pages/SuperAdmin/components/Templates/components/PTInitialEvaluation';
import PhysicalTherapyInsurance from '../../../pages/SuperAdmin/components/Templates/components/PhysicalTherapyInsurance';
import SuccessPlaceholder from './components/SuccessPlaceholder';
import ClientRegistration from '../../../pages/SuperAdmin/components/Templates/components/ClientRegistration';
import { Dialog } from '../..';

type Props = {
  data?: Step;
  fileUrl?: string;
  formik: any;
  finishClick: () => void;
  appointmentInformation?: AppointmentsNew;
};
const ConfigSelectedForm = forwardRef<Record<string, unknown>, Props>(
  ({ data, fileUrl, formik, finishClick, appointmentInformation }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
      setIsOpen(!isOpen);
    };

    const renderForm = () => {
      switch (data?.clinicTemplate?.template?.name) {
        case DigitalTemplateTypeByName.PDF_ACU_INFORMED: {
          if (fileUrl) {
            return <PDFDrawer file={fileUrl} />;
          } else {
            return <></>;
          }
        }
        case DigitalTemplateTypeByName.PDF_OFFICE_POL: {
          if (fileUrl) {
            return <PDFDrawer file={fileUrl} />;
          } else {
            return <></>;
          }
        }
        case DigitalTemplateTypeByName.PDF_INFORMED: {
          if (fileUrl) {
            return <PDFDrawer file={fileUrl} />;
          } else {
            return <></>;
          }
        }
        case DigitalTemplateTypeByName.ACUPUNCTURE_INSURANCE:
          return (
            <ACAInsurance
              digitalFormId={data?.digitalFormId}
              formik={formik}
              ref={ref}
              stepperValues={data}
              appointmentInformation={appointmentInformation}
            />
          );
        case DigitalTemplateTypeByName.INITIAL: {
          return (
            <InitialVisitForm
              digitalFormId={data?.digitalFormId}
              formik={formik}
              ref={ref}
              stepperValues={data}
              appointmentInformation={appointmentInformation}
            />
          );
        }
        case DigitalTemplateTypeByName.PT_INITIAL: {
          return (
            <PTInitialEvaluation
              digitalFormId={data?.digitalFormId}
              formik={formik}
              ref={ref}
              stepperValues={data}
              appointmentInformation={appointmentInformation}
            />
          );
        }
        case DigitalTemplateTypeByName.PT_THERAPY: {
          return (
            <PhysicalTherapyInsurance
              digitalFormId={data?.digitalFormId}
              formik={formik}
              ref={ref}
              stepperValues={data}
              appointmentInformation={appointmentInformation}
            />
          );
        }
        case DigitalTemplateTypeByName.CLIENT_REGISTRATION: {
          return (
            <ClientRegistration
              digitalFormId={data?.digitalFormId}
              formik={formik}
              ref={ref}
              stepperValues={data}
              appointmentInformation={appointmentInformation}
            />
          );
        }
        default:
          return <SuccessPlaceholder finishClick={finishClick} />;
      }
    };

    return (
      <>
        {/* <Dialog open={isOpen} onClose={handleClose}>
          asdasd
        </Dialog> */}
        {renderForm()}
      </>
    );
  },
);

export default ConfigSelectedForm;
