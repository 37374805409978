import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 230px;
  height: 35px;
  border-radius: 5px;
  background: #9bc15f;
  display: flex;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Text = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Row = styled.div<{ $isCurrent: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  height: 38px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;

  ${props =>
    props.$isCurrent &&
    css`
      pointer-events: none;
      opacity: 0.7;
      border-radius: 5px;
      background: rgba(0, 132, 177, 0.05);
      border: 1px solid #0084b1;
    `}

  &:hover {
    background-color: #ebf6f9;
  }
`;

export const RowText = styled.div`
  color: #202e5f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowList = styled.div`
  display: flex;
  min-width: 230px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
`;
