import { MenuItem, SelectChangeEvent, Table } from '@mui/material';
import * as S from './styles';
import AccTableHead from './components/AccTableHead';
import { getTableColumnColor } from 'helpers/functions/getTableColumnColor';
import { useBillingAccTableGetQuery } from 'store/api/billing/billingApi';
import { CheckBox, LinearLoader, SelectInput } from 'components';
import { BillingAccTableType } from 'types/AccTableTypes';
import AccTableBody from './components/AccTableBody';
import React, { useEffect, useState } from 'react';
import { getLastYears } from 'helpers/functions/getLastYears';
import { monthsName } from 'constants/constants';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/useAppSelector';

const AccountingTable = () => {
  const yearArr = getLastYears();
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const currentMonth = dayjs().month();
  const [filterYear, setFilterYear] = useState<number>(yearArr[0]);
  const [filterMonth, setFilterMonth] = useState<Array<number | string>>([currentMonth]);
  const [isAll, setIsAll] = useState(false);

  const getAccTableData = useBillingAccTableGetQuery({
    'month[]': isAll ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : filterMonth,
    year: filterYear,
    'clinicsId[]': selectedClinic?.id ? selectedClinic?.id : undefined,
  });

  const [monthName, setMonthName] = useState<string[]>([]);

  useEffect(() => {
    setMonthName([]);
    if (isAll) {
      setMonthName(['All']);
    } else {
      monthsName?.map(item => {
        if (filterMonth.includes(item.value)) {
          setMonthName(prev => [...prev, item.name]);
        }
      });

      if (filterMonth.length === 0) {
        setFilterMonth([currentMonth]);
      }
    }
  }, [filterMonth, isAll]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Accounting Table</S.SubtitleText>
        <S.SubtitleItems>
          <S.SelectInputWrapper>
            <SelectInput
              multiple
              label="Month"
              value={filterMonth}
              onChange={(e: SelectChangeEvent<unknown>) => {
                setFilterMonth(e.target.value as number[]);
              }}
              renderValue={() => monthName.join()}
            >
              <MenuItem
                onClick={() => setIsAll(!isAll)}
                value={'all'}
                disabled={getAccTableData.isFetching || getAccTableData.isLoading}
              >
                <S.MenuItemContent>
                  {' '}
                  <CheckBox checked={isAll} />
                  All
                </S.MenuItemContent>
              </MenuItem>
              {monthsName.map((month, index) => (
                <MenuItem
                  key={month.value + index}
                  value={month.value}
                  disabled={
                    getAccTableData.isFetching || getAccTableData.isLoading || isAll
                  }
                >
                  <S.MenuItemContent>
                    {' '}
                    <CheckBox checked={filterMonth.indexOf(month.value) > -1} />
                    {month.name}
                  </S.MenuItemContent>
                </MenuItem>
              ))}
            </SelectInput>
          </S.SelectInputWrapper>
          <SelectInput
            label="Year"
            value={filterYear}
            onChange={(e: SelectChangeEvent<unknown>) =>
              setFilterYear(e.target.value as number)
            }
          >
            {yearArr.map((year, index) => (
              <MenuItem
                key={year + index}
                value={year}
                disabled={getAccTableData.isFetching || getAccTableData.isLoading}
              >
                <S.MenuItemContent>{year}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.SubtitleItems>
      </S.SubtitleBar>

      <S.Content>
        {getAccTableData.isLoading || getAccTableData.isFetching ? (
          <LinearLoader />
        ) : (
          <S.TableWrapper>
            {getAccTableData.currentData?.data?.map(
              (tableData: BillingAccTableType, index: number) => {
                return (
                  <React.Fragment key={tableData?.doctorName + index}>
                    <S.ColumnWrapper $backgrColor={getTableColumnColor(index)}>
                      <Table>
                        <AccTableHead data={tableData} index={index} />
                        <AccTableBody data={tableData} index={index} />
                      </Table>
                    </S.ColumnWrapper>
                  </React.Fragment>
                );
              },
            )}
          </S.TableWrapper>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default AccountingTable;
