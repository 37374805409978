import ReactInputMask from 'react-input-mask';
import * as S from './styles';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import Input from '../../atoms/Input';
import { useChangeHealthCareMutationMutation } from '../../../store/api/staff/staffApi';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { ClearFiltersSVG, PlaceholderAttentionSVG } from '../../../assets/icons';
import SearchContentRow from '../../../pages/SuperAdmin/components/ProfileStaff/components/Dialog_AddInsuranceTo/components/SearchContentRow';
import CircularLoader from '../../atoms/CircuralLoader';
import Button from '../../atoms/Button';
import { FormikProps } from 'formik';
import { PayerRow } from '../../../types/Payer';

type Props = {
  onClose: () => void;
  selectedRow: PayerRow | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<PayerRow | null>>;
};

const PayerSearchDialog = ({ onClose, selectedRow, setSelectedRow }: Props) => {
  const [payerId, setPayerId] = useState('');
  const [payerName, setPayerName] = useState('');

  const [debouncedPayerId] = useDebounce(payerId, 500);
  const [debouncedPayerName] = useDebounce(payerName, 500);

  const [getPayerList, payerStatus] = useChangeHealthCareMutationMutation({});

  const dispatch = useAppDispatch();

  const [tempSelectedRow, setTempSelectedRow] = useState<PayerRow | null>(null);

  useEffect(() => {
    if (
      (debouncedPayerId || (debouncedPayerName && debouncedPayerName.length > 2)) &&
      //   !selectedRow &&
      !(debouncedPayerName as string).includes('_') &&
      !(debouncedPayerId as string).includes('_')
    ) {
      getPayerList({
        ...(debouncedPayerId && { payerID: debouncedPayerId }),
        ...(debouncedPayerName && { payerName: debouncedPayerName }),
      });
    }
  }, [debouncedPayerId, debouncedPayerName, tempSelectedRow]);

  useEffect(() => {
    if (tempSelectedRow) {
      setPayerId(tempSelectedRow.payerId);
      setPayerName(tempSelectedRow.payerName);
    }
  }, [tempSelectedRow]);

  const disableButton = useMemo(() => !tempSelectedRow, [tempSelectedRow]);

  return (
    <S.Content>
      <S.Article>Search</S.Article>
      <S.FilterBody>
        {tempSelectedRow && (
          <S.SVG onClick={() => setTempSelectedRow(null)}>
            <ClearFiltersSVG />
            Change Payer Information
          </S.SVG>
        )}
        <S.InputWrapper>
          <ReactInputMask
            mask={'*****'}
            value={payerId}
            maskPlaceholder={'_'}
            disabled={!!tempSelectedRow}
            onChange={e => {
              setPayerId(e.target.value);
            }}
          >
            <Input label="PayerID" placeholder="PayerID" />
          </ReactInputMask>
          <Input
            label="Payer Name (min 3 symbols)"
            placeholder="Payer Name"
            value={payerName}
            disabled={!!tempSelectedRow}
            onChange={e => setPayerName(e.target.value)}
          />
        </S.InputWrapper>
        {!tempSelectedRow && (
          <>
            <S.SearchResultDiv>
              <S.SearchText>
                Search result: {payerStatus.data?.searchResult || '0'}
              </S.SearchText>
            </S.SearchResultDiv>
            <S.BodyResult>
              {payerStatus.isLoading ? (
                <CircularLoader size={24} color="#0084B1" />
              ) : payerStatus.data?.searchResult && payerStatus.data?.searchResult > 0 ? (
                payerStatus.data?.row.map((item: PayerRow, index) => {
                  return (
                    <SearchContentRow
                      key={item.payerId + item.payerName}
                      row={item}
                      index={index}
                      selectedRow={tempSelectedRow}
                      setSelectedRow={setTempSelectedRow}
                    />
                  );
                })
              ) : (
                <S.Placeholder>
                  <PlaceholderAttentionSVG />
                  <S.PlaceholderText>
                    No result. Please, use filter to find insurance company
                  </S.PlaceholderText>
                </S.Placeholder>
              )}
            </S.BodyResult>
          </>
        )}
      </S.FilterBody>
      <S.ButtonWrapper>
        <Button
          text="Set"
          onClick={() => {
            onClose();
            setSelectedRow(tempSelectedRow);
          }}
          disabled={disableButton}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default PayerSearchDialog;
