import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div<{ $checked: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 5px 0px 0px;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: ${props => (props.$checked ? '#E7E8EC' : '#fff')};

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: #e2edf0;
  }
`;
