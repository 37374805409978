import { useEffect, useState } from 'react';
import { Button, Input } from '../../../../components';
import * as S from './styles';
import * as AuthS from '../../styles';
import logo from '../../../../assets/images/logo.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthMeMutation,
  useChangePhoneMutation,
  useResendCodeMutation,
  useSignUpMutation,
} from '../../../../store/api/auth/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../store/api/auth/authSlice';
import { setMessage } from '../../../../store/slices/message';

type FormValues = {
  code: string;
};

const VerificationCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signup] = useSignUpMutation();
  const [resendCode] = useResendCodeMutation();
  const [authMe] = useAuthMeMutation();
  const [changePhone] = useChangePhoneMutation();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate('/auth');
    }
  }, []);

  const verificationSchema = yup.object().shape({
    code: yup
      .string()
      .min(6, 'Must be exactly 6 characters')
      .max(6, 'Must be exactly 6 characters')
      .required('Is required'),
  });

  const { values, handleChange, handleSubmit, errors } = useFormik<FormValues>({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validationSchema: verificationSchema,
    onSubmit: async ({ code }) => {
      await signup({
        authField: state.authField,
        authFieldType: state.authFieldType,
        password: state.password,
        confirmPassword: state.confirmPassword,
        code: code,
      })
        .unwrap()
        .then(res => {
          authMe({})
            .unwrap()
            .then(res => {
              dispatch(setUser(res));
            });
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const [timerValue, setTimerValue] = useState(30);

  const [isWaiting, setIsWaiting] = useState(true);

  const tick = () => {
    if (timerValue > 1) {
      const newVal = timerValue - 1;
      setTimerValue(newVal);
    } else {
      setIsWaiting(true);
      setTimerValue(30);
    }
  };

  const startTimer = () => {
    if (isWaiting === false) {
      setTimeout(tick, 1000);
      return (
        <S.ReceiveCode>
          Please wait until the timer ends to resend the code:
          <S.ResendSpan> {timerValue}</S.ResendSpan>
        </S.ReceiveCode>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <AuthS.AuthWrapper>
      <AuthS.LoginBlock>
        <img className="logo" src={logo} alt="logotype"></img>
        <S.VerificationCodeWrapper>
          <S.Article>Verification code has been sent</S.Article>
          <S.SubArticle>
            Please enter the verification code that was sent to your{' '}
            {state?.authFieldType}: {state?.authField}
          </S.SubArticle>
          <form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <S.Items>
              <Input
                id="code"
                name="code"
                value={values.code}
                onChange={handleChange}
                label="Verification code"
                error={!!errors.code}
                helperText={errors.code}
              />
              {isWaiting && (
                <S.ReceiveCode>
                  Didn’t receive a code?{' '}
                  <S.ResendSpan
                    onClick={() => {
                      resendCode({
                        authField: state.authField,
                        authFieldType: state.authFieldType,
                      });
                      setIsWaiting(false);
                    }}
                  >
                    Resend
                  </S.ResendSpan>
                </S.ReceiveCode>
              )}
              <div>{startTimer()}</div>
              <S.ButtonWrapper>
                <Button text="Next" type="submit" />
              </S.ButtonWrapper>
              <S.ChangeEmailText
                onClick={() =>
                  changePhone({
                    authField: state.authField,
                    authFieldType: state.authFieldType,
                  }).then(() => navigate('/auth'))
                }
              >
                Change auth format
              </S.ChangeEmailText>
            </S.Items>
          </form>
        </S.VerificationCodeWrapper>
      </AuthS.LoginBlock>
    </AuthS.AuthWrapper>
  );
};

export default VerificationCode;
