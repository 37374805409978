import React, { useEffect, useState } from 'react';
import { useGetDiagnosisCodeQuery } from 'store/api/claims/claimsApi';
import { useDebounce } from 'use-debounce';
import * as S from './styles';
import SearchInput from 'components/atoms/SearchInput';
import { CustomTable, RadioButton } from 'components';
import { TableCell, TableRow } from '@mui/material';
import Button from 'components/atoms/Button';
import useInfinityScroll from 'hooks/useInfinityScroll';
import CircularLoader from 'components/atoms/CircuralLoader';

const headers = [
  { name: '', key: 'checkbox' },
  { name: 'Code', key: 'code' },
  /*{ name: 'Short Description', key: 'description' },*/
];

type Props = {
  onApplyClick: (code: string) => void;
  currentValue?: string;
  title?: string;
};

export const INJURY_CODE_LIMIT = 20;
export const ChooseInjuryCode = ({
  onApplyClick,
  currentValue,
  title = 'Choose Diagnosis',
}: Props) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  const getCodes = useGetDiagnosisCodeQuery({
    type: 'ICD-10',
    terms: debouncedSearch ?? undefined,
    count: INJURY_CODE_LIMIT,
    offset: (page - 1) * INJURY_CODE_LIMIT,
  });

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(page + 1),
    hasMore:
      (getCodes.currentData?.row?.length ?? 0) < (getCodes.currentData?.count ?? 0),
  });

  useEffect(() => {
    getCodes.refetch();
  }, [debouncedSearch, page]);

  useEffect(() => {
    if (currentValue) {
      setSelectedItem(getCodes.currentData?.row?.find(i => i === currentValue) ?? null);
    }
  }, [getCodes.currentData]);

  return (
    <S.Content>
      {getCodes.isFetching && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={16} />
        </S.Loader>
      )}
      <S.Article>{title}</S.Article>
      <S.SearchWrapper>
        <SearchInput
          isSearch={!!search}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </S.SearchWrapper>
      <S.Table className={'all'}>
        <CustomTable headers={headers}>
          {!!getCodes.currentData?.row.length &&
            getCodes.currentData.row?.map((row, index, { length }) => {
              const refLast = index === length - 1 ? setLastElement : null;

              return (
                <TableRow
                  key={`${row}-${index}`}
                  sx={{
                    '& .MuiTableCell-root': {
                      background: selectedItem === row ? '#e7e8ec' : '',
                    },
                  }}
                  ref={refLast}
                >
                  <TableCell>
                    <RadioButton
                      onClick={() =>
                        selectedItem === row
                          ? setSelectedItem(null)
                          : setSelectedItem(row)
                      }
                      checked={selectedItem === row}
                    />
                  </TableCell>
                  <TableCell>
                    <S.Text>{row}</S.Text>
                  </TableCell>
                  {/*<TableCell>
                    <S.Text>{row.description}</S.Text>
                  </TableCell>*/}
                </TableRow>
              );
            })}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
