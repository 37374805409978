import styled from 'styled-components';

export const Wrapper = styled.div``;

export const InputWrapper = styled.div`
  width: 170px;

  &.px360 {
    width: 360px;
  }

  &.px240 {
    width: 240px;
  }
`;

export const UserInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ResetButton = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 4px;
  gap: 20px 29px;

  &.noAlign {
    align-items: start;
  }
`;

export const ErrorText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--main, #c66060);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const BoldText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
