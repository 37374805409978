import React, { useEffect, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import * as S from './styles';
import { AddCircleBlueSVG, ArrowLeftSVG, SearchIconSVG } from 'assets/icons';
import {
  useInsuranceWithGroupsCreateMutation,
  useInsuranceWithGroupsUpdateMutation,
  useLazyGetOneInsuranceWithGroupsQuery,
} from 'store/api/insuranceRelative/insuranceRelativeApi';
import { useFormik } from 'formik';
import { emptyGroupNumber, NewInsuranceGroupsType, Schema } from './config';
import { Button, Dialog, TooltipInput } from 'components';
import PayerSearchDialog from 'components/molecules/PayerSearchDialog';
import { PayerRow } from 'types/Payer';
import { GroupNumberCard } from './GroupNumberCard';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CircularLoader from 'components/atoms/CircuralLoader';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';

export const InsuranceProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PayerRow | null>(null);

  const [showExitModal, setShowExitModal] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const blocker = useBlocker(isDirtyForm);

  const [getInsuranceInfo, status] = useLazyGetOneInsuranceWithGroupsQuery({});
  const [createInsurance, createStatus] = useInsuranceWithGroupsCreateMutation({});
  const [updateInsurance, updateStatus] = useInsuranceWithGroupsUpdateMutation({});

  const formik = useFormik<NewInsuranceGroupsType>({
    initialValues: {} as NewInsuranceGroupsType,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: values => {
      setIsDirtyForm(false);

      const data = {
        insuranceTemplateId: params?.insuranceId ?? undefined,
        insuranceName: values.insuranceName,
        payerId: values.payerId,
        insuranceGroupNumbers: values.groups?.map(i => {
          return {
            id: i.id || undefined,
            groupNumber: i.groupNumber,
            parentInsuranceName: i.parentInsuranceName,
            inNetMet: i.inNetMet || 0,
            inNetTotal: i.inNetTotal || 0,
            inOutMet: i.inOutMet || 0,
            inOutTotal: i.inOutTotal || 0,
            pocketLimitInFrom: i.pocketLimitInFrom || 0,
            pocketLimitInTo: i.pocketLimitInTo || 0,
            pocketLimitOutFrom: i.pocketLimitOutFrom || 0,
            pocketLimitOutTo: i.pocketLimitOutTo || 0,
            insuranceOnPolicyIn: i.insuranceOnPolicyIn || 0,
            insuranceOnPolicyOut: i.insuranceOnPolicyOut || 0,
            copayIn: i.copayIn || 0,
            copayOut: i.copayOut || 0,
            isReferallRequired: i.isReferallRequired ?? undefined,
            isAuthorizationRequired: i.isAuthorizationRequired ?? undefined,
            note: i.note || undefined,
            coveredServices: i.coveredServices?.length ? i.coveredServices : [],
          };
        }),
      };
      params?.insuranceId
        ? updateInsurance({ insuranceTemplateData: data })
            .unwrap()
            .then(res => {
              navigate('/insurance');
              dispatch(
                setMessage({
                  message: 'Group number was successfully edited',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            })
        : createInsurance({ ...data })
            .unwrap()
            .then(res => {
              navigate('/insurance');
              dispatch(
                setMessage({
                  message: 'Group number was successfully created',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
    },
  });

  const onAddGroupClick = async () => {
    const errors = await formik.validateForm();

    if (!Object.keys(errors)?.length) {
      setIsDirtyForm(true);
      await formik.setValues({
        ...formik.values,
        groups: formik.values.groups?.length
          ? [emptyGroupNumber(formik.values.insuranceName), ...formik.values?.groups]
          : [emptyGroupNumber(formik.values.insuranceName)],
      });
    }
  };

  useEffect(() => {
    if (params?.insuranceId) {
      getInsuranceInfo({ id: params?.insuranceId });
    }
  }, [params?.insuranceId]);

  const setInitValue = () => {
    setIsDirtyForm(false);
    if (status?.data) {
      formik.setValues({
        ...formik.values,
        insuranceId: status?.data?.insuranceId ?? '',
        insuranceName: status?.data?.insuranceName ?? '',
        payerId: status?.data?.payerId,
        groups: status?.data?.insuranceGroupNumbers?.map(i => ({
          id: i.id ?? undefined,
          groupNumber: i.groupNumber,
          parentInsuranceName: i.parentInsuranceName,
          inNetMet: i.inNetMet ?? 0,
          inNetTotal: i.inNetTotal ?? 0,
          inOutMet: i.inOutMet ?? 0,
          inOutTotal: i.inOutTotal ?? 0,
          pocketLimitInFrom: i.pocketLimitInFrom ?? 0,
          pocketLimitInTo: i.pocketLimitInTo ?? 0,
          pocketLimitOutFrom: i.pocketLimitOutFrom ?? 0,
          pocketLimitOutTo: i.pocketLimitIOutTo ?? 0,
          insuranceOnPolicyIn: i.insuranceOnPolicyIn ?? 0,
          insuranceOnPolicyOut: i.insuranceOnPolicyOut ?? 0,
          copayIn: i.copayIn ?? 0,
          copayOut: i.copayOut ?? 0,
          isReferallRequired: i.isReferallRequired,
          isAuthorizationRequired: i.isAuthorizationRequired,
          note: i.note,

          coveredServices: i.coveredServices?.length
            ? i.coveredServices?.map(covered => {
                return {
                  id: covered.id,
                  isUnlimited: covered?.isUnlimited,
                  typeServiceId: covered?.typeServiceId,
                  type: covered?.type,
                  visitAuthorizet: covered?.visitAuthorizet,
                };
              })
            : [],
        })),
      });
    }
  };

  useEffect(() => {
    if (status?.data) {
      setInitValue();
    }
  }, [status?.data]);

  useEffect(() => {
    if (selectedRow) {
      formik.setValues({
        ...formik.values,
        insuranceName: selectedRow.payerName,
        payerId: selectedRow.payerId,
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      setShowExitModal(true);
    }
  }, [blocker?.state]);

  return (
    <>
      {(status.isLoading || createStatus.isLoading || updateStatus.isLoading) && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={24} />
        </S.Loader>
      )}
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate(-1)}>
          <ArrowLeftSVG /> {status.data ? status.data?.insuranceName : 'Back'}
        </S.SubtitleText>
      </S.SubtitleBar>
      <S.Wrapper>
        <form>
          <S.Line>
            <S.InputWrapper className="px480">
              <TooltipInput
                isRequired
                value={formik.values.insuranceName}
                onChange={e => {
                  setIsDirtyForm(true);
                  formik.setFieldValue('insuranceName', e.target.value);
                }}
                label="Insurance Name"
                error={!!formik.errors.insuranceName}
                tooltipHelperText={formik.errors.insuranceName}
                disabled
              />
            </S.InputWrapper>
            <S.SearchBtn onClick={() => setIsOpenSearchDialog(true)}>
              <SearchIconSVG />
              Search
            </S.SearchBtn>
          </S.Line>

          <S.Line className={'padding'}>
            <S.ArticleText>Group Number</S.ArticleText>
            <S.SearchBtn onClick={onAddGroupClick} className={'withoutMargin'}>
              <AddCircleBlueSVG />
              Add Group
            </S.SearchBtn>
          </S.Line>

          <S.Content>
            {formik.values?.groups?.map((group, index) => (
              <GroupNumberCard
                key={index}
                formik={formik}
                index={index}
                setIsDirtyForm={setIsDirtyForm}
              />
            ))}
          </S.Content>
        </form>

        <Dialog open={isOpenSearchDialog} onClose={() => setIsOpenSearchDialog(false)}>
          <PayerSearchDialog
            onClose={() => setIsOpenSearchDialog(false)}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Dialog>
      </S.Wrapper>

      <S.ButtonsWrapper>
        <S.Button>
          <Button text={'Cancel'} backgroundColor={'#C66060'} onClick={setInitValue} />
        </S.Button>
        <S.Button>
          <Button text={'Save'} onClick={() => formik.handleSubmit()} />
        </S.Button>
      </S.ButtonsWrapper>

      <Dialog
        open={showExitModal}
        onClose={() => {
          setShowExitModal(false);
          //@ts-ignore
          blocker?.reset();
        }}
      >
        <ConfirmDeleteDialog
          onClose={() => {
            setShowExitModal(false);
            //@ts-ignore
            blocker?.reset();
          }}
          helperText={'All unsaved data will be lost'}
          fullTitle={'Are you sure you want to leave this page? '}
          closeAfterSuccess={false}
          onSuccess={() => {
            setShowExitModal(false);
            //@ts-ignore
            blocker?.proceed();
          }}
        />
      </Dialog>
    </>
  );
};
