import styled, { css } from 'styled-components';
import { devices } from '../../../constants/device';

export const MenuSectionItemWrapper = styled.div<{
  $active: boolean;
  $isHover: boolean;
}>`
  width: 77px;
  height: 38px;
  border-radius: 5px;
  background: var(--hover, #fff);
  cursor: pointer;
  color: var(--text, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  transition: 0.5s;

  ${props =>
    props.$isHover &&
    css`
      width: 255px;
      transition: 0.5s;
    `}

  &.hide {
    display: none;
  }

  @media ${devices.xs} {
    font-size: 10px;
    height: auto;
    background: none;
    width: auto;
    border-radius: none;
    opacity: 0.5;

    &.hide {
      display: inherit;
    }
  }

  ${props =>
    props.$active &&
    css`
      background: var(--hover, #f5f5f5);
      color: #202e5f;

      @media ${devices.xs} {
        opacity: 1;
      }
    `}
`;

export const Content = styled.div`
  margin-left: 24.5px;
  display: flex;
  align-items: center;
  gap: 17px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  @media ${devices.xs} {
    margin: 0;
    gap: 0px;

    flex-direction: column;

    & svg {
      width: 34px;
      height: 34px;
    }
  }
`;

export const Text = styled.div<{ $isHover: boolean }>`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;

  @media ${devices.xs} {
    visibility: hidden;
  }

  ${props =>
    props.$isHover &&
    css`
      visibility: visible;
      opacity: 1;
      transition: 1s;
    `}
`;

export const Circle = styled.div`
  border-radius: 50%;
  background-color: #0084b1;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
`;
