import { createSlice } from '@reduxjs/toolkit';

type MenuHover = {
  isHover: boolean;
};

const initialState: MenuHover = {
  isHover: false,
};

const drawerSlice = createSlice({
  name: 'isHover',
  initialState,
  reducers: {
    setIsHover: (state, action) => {
      return {
        ...state,
        isHover: action.payload.isHover,
      };
    },
  },
});

const { reducer, actions } = drawerSlice;

export const { setIsHover } = actions;
export default reducer;
