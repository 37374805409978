import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 15px;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ResetButton = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ErrorText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--main, #c66060);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.px13 {
    font-size: 13px;
  }
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RadioItems = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 4px;
  gap: 20px 29px;

  &.noAlign {
    align-items: start;
  }
`;

export const Caption = styled.div`
  /* font-size: 13px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  color: #848a9b; */

  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
`;

export const BlockCheckbox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 60px;
`;

export const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
`;

export const BlockItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const InputWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BoldText = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const InputWrapper = styled.div`
  width: 170px;
  display: flex;
  align-items: center;
  gap: 10px;
  &.px360 {
    width: 360px;
  }
  &.px240 {
    width: 360px;
  }
`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Btn = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
