import { useEffect, useState } from 'react';
import { Dialog, LinearLoader } from '../..';
import Button from '../../atoms/Button';
import AddNoteDialog from './components/AddNoteDialog';
import NoteRecord from './components/NoteRecord';
import * as S from './styles';
import { UserProfile } from '../../../types/UserProfileTypes';
import { useNotesGetAllQuery } from '../../../store/api/notes/noteApi';
import { useParams } from 'react-router-dom';
import { Note } from '../../../types/NoteTypes';

type Props = {
  userProfile?: UserProfile;
};

const AllNotesDrawer: React.FC<Props> = ({ userProfile }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const params = useParams();

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const allNotes = useNotesGetAllQuery({
    id: userProfile?.id,
  });

  const [editedRow, setEditedRow] = useState<Note | null>(null);

  useEffect(() => {
    if (editedRow) {
      handleChangeDialog();
    }
  }, [editedRow]);

  return (
    <S.Wrapper>
      <Dialog
        open={openDialog}
        onClose={() => {
          handleChangeDialog();
          setEditedRow(null);
        }}
      >
        <AddNoteDialog
          onClose={() => {
            handleChangeDialog();
            setEditedRow(null);
          }}
          userProfile={userProfile}
          editedRow={editedRow}
        />
      </Dialog>
      <S.Content>
        <S.ArticleWrap>
          <S.Article>All Notes</S.Article>
          <S.BtnWrapper>
            <Button text="Add note" onClick={handleChangeDialog} />
          </S.BtnWrapper>
        </S.ArticleWrap>
        <S.MainInformation>
          {allNotes.isFetching || allNotes.isLoading ? (
            <LinearLoader />
          ) : (
            <>
              {allNotes.currentData?.rows.map(note => {
                return (
                  <NoteRecord
                    key={note.id}
                    note={note}
                    allNotes={allNotes?.currentData}
                    setEditedRow={setEditedRow}
                  />
                );
              })}
            </>
          )}
        </S.MainInformation>
      </S.Content>
    </S.Wrapper>
  );
};

export default AllNotesDrawer;
