import { useDispatch } from 'react-redux';
import { Button } from '../../../../../../components';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { CloseBtnSVG } from '../../../../../../assets/icons';

type Props = {
  onClose: () => void;
};

const DialogChangeProfile: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleConfirmClick = () => {
    onClose();
    window.location.href = 'https://acaacupuncture.com/contact-us/';
  };

  return (
    <S.Content>
      <S.Article>Change Profile Info</S.Article>
      <S.SubArticle>
        You can not edit your profile. Please, ask receptionist to change profile info
      </S.SubArticle>
      <S.ButtonWrapper>
        <Button text="View contacts" onClick={handleConfirmClick} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default DialogChangeProfile;
