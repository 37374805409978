import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Wrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  margin-right: 15px;
  margin-bottom: 15px;
  svg {
    cursor: pointer;
  }

  @media ${devices.xs} {
    background: none;
  }
`;

export const Content = styled.div`
  padding: 30px 36px 10px 23px;
  display: flex;
  flex-direction: column;
`;

export const Loader = styled.div`
  display: flex;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DeleteCircleSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background: #c66060;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 55px;
  left: 70px;
  cursor: pointer;
`;

export const RequiredSpan = styled.span`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UploadPhotoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

export const AddPhotoBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
  cursor: pointer;

  svg {
    path {
      stroke: #0084b1;
    }
  }

  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const PhotoLabel = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 5px;
`;

export const ArticleSmallWrapper = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sub {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ButtonMark = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: #e5f0f6;
    border-radius: 10px;
  }

  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ArticleName = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const InputWrapper = styled.div`
  width: 240px;
`;

export const InsuranceItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
`;

export const ShowBtnsLogs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SubArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 7px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 10px;
`;

export const CheckBoxLabel = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CommonInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const ButtonAddInsurance = styled.div`
  border-radius: 5px;
  margin-top: 15px;
  background: #cfe9f2;
  width: 100%;
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    background-color: #addff0;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const ServiceRow = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Block = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;

  & .MuiAccordion-root {
    &:hover {
      background-color: rgba(240, 249, 250, 0.4);
    }
    width: 100%;
    box-shadow: none !important;
  }
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
`;

export const InsuredListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BlockContent = styled.div`
  margin-top: 23px;
`;

export const BottomLine = styled.div`
  opacity: 0.5;
  background: #848a9b;
  width: 100%;
  height: 1px;
`;

export const Item = styled.div`
  width: 208px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 4px;
`;

export const ItemArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ItemValue = styled.div<{ withCheckbox?: boolean }>`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
`;

export const UploadedPhotos = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 15px;
  /* margin-top: 15px;
  padding-bottom: 50px; */
`;

export const ImgWrapper = styled.div`
  img {
    max-width: 80px;
    max-height: 60px;
    height: 60px;
    width: 80px;
    object-fit: cover;
  }
  position: relative;
  cursor: zoom-in;
`;

export const CheckItem = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 208px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;

export const Label = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
