export type SearchOperatorsForKey = {
  name: string;
  key: string;
};

export const searchKeys: SearchOperatorsForKey[] = [
  {
    name: 'Patient First Name',
    key: 'firstName',
  },
  {
    name: 'Patient Last Name',
    key: 'lastName',
  },
  {
    name: 'Account Number',
    key: 'accountNumber',
  },
  {
    name: 'Patient Phone Number',
    key: 'phone',
  },
  {
    name: 'Patient Address',
    key: 'address',
  },
  {
    name: 'Employer Name',
    key: 'employerName',
  },
  {
    name: 'Insurance Name',
    key: 'insuranceName',
  },
  {
    name: 'Insurance Group Number',
    key: 'insuranceGroupNumber',
  },
  {
    name: 'Subscriber Name',
    key: 'subscriberName',
  },
  {
    name: 'Subscriber ID',
    key: 'subscriberId',
  },
];

export enum SearchOperatorsEnum {
  START_WITH = 'startsWith',
  EQUALS_TO = 'eq',
  SUBSTRING = 'substring',
  ILIKE = 'iLike',
}

type SearchOperatorsType = {
  name: string;
  key: SearchOperatorsEnum;
};

export const searchOperators: SearchOperatorsType[] = [
  {
    name: 'Starts with',
    key: SearchOperatorsEnum.START_WITH,
  },
  {
    name: 'Equals to',
    key: SearchOperatorsEnum.EQUALS_TO,
  },
  {
    name: 'Contains',
    key: SearchOperatorsEnum.ILIKE,
  },
];

export type FilterValuesType = {
  serverIsShowAllPatients: boolean;
  serverStatus: string[];
  serverDOBRange: string;
  serverStartDOB: string;
  serverEndDOB: string;
  serverSortLastVisit: string;
  serverStartLastVisit: string;
  serverEndLastVisit: string;
  serverExactDOB: string;
};

export type FilterCounterValuesType = {
  useShowAllPatients: boolean;
  useStatus: boolean;
  useDOBRange: boolean;
  useSortLastVisit: boolean;
  useSortLastVisitInRange: boolean;
};
