import { DateObject } from 'react-multi-date-picker';
import * as S from './styles';
import dayjs from 'dayjs';
import { CalendarIconSVG, HorizontalLineSVG } from '../../../../../../../../assets/icons';
import {
  useClinicScheduleCheckWorkingTimeQuery,
  useClinicScheduleCreateMutation,
  useClinicScheduleUpdateMutation,
} from '../../../../../../../../store/api/clinic-schedule/clinicScheduleApi';
import { useParams } from 'react-router-dom';
import {
  Button,
  CheckBox,
  LinearLoader,
  SelectInput,
} from '../../../../../../../../components';
import { useEffect, useState } from 'react';
import { convertAMtoPM } from '../../../../../../../../helpers/functions/convertAMtoPM';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { constantTimePickerOUT } from '../../../../../../../../constants/constants';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../../store/slices/message';

type Props = {
  date: DateObject[];
};

const ClinicScheduleCard: React.FC<Props> = ({ date }) => {
  const params = useParams();

  const dispatch = useAppDispatch();

  const checkWorkingTime = useClinicScheduleCheckWorkingTimeQuery({
    clinicId: params.clinicId,
    date: date[0].format('YYYY-MM-DD'),
  });

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isDayOff, setIsDayOff] = useState(false);

  useEffect(() => {
    if (checkWorkingTime.currentData?.time?.length) {
      setIsDayOff(false);
      setStartTime(checkWorkingTime.currentData.time[0]);
      setEndTime(
        checkWorkingTime.currentData.time[checkWorkingTime.currentData.time.length - 1],
      );
    } else {
      setIsDayOff(true);
      setStartTime('');
      setEndTime('');
    }
  }, [checkWorkingTime]);

  useEffect(() => {
    setEditable(false);
  }, [date]);

  const resetValues = () => {
    setStartTime(checkWorkingTime.currentData.time[0]);
    setEndTime(
      checkWorkingTime.currentData.time[checkWorkingTime.currentData.time.length - 1],
    );
  };
  const [editable, setEditable] = useState(false);

  const [createSpecial] = useClinicScheduleCreateMutation({});
  const [updateSpecial] = useClinicScheduleUpdateMutation({});

  const handleSave = () => {
    if (checkWorkingTime.currentData?.isSpecial) {
      updateSpecial({
        schedule: [
          {
            isSpecial: true,
            clinicId: params.clinicId,
            specialDate: date[0].format('YYYY-MM-DD'),
            isWorkDay: !isDayOff,
            workingHoursStart:
              startTime.length > 0
                ? startTime.slice(0, 5)
                : constantTimePickerOUT[0].value,
            workingHoursEnd:
              endTime.length > 0
                ? endTime.slice(0, 5)
                : constantTimePickerOUT[constantTimePickerOUT.length - 1].value,
            id: checkWorkingTime.currentData?.specialId,
          },
        ],
      })
        .unwrap()
        .then(res => {
          setEditable(false);
          dispatch(
            setMessage({
              message: 'Special schedule was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    } else {
      createSpecial({
        schedule: [
          {
            isSpecial: true,
            clinicId: params.clinicId,
            specialDate: date[0].format('YYYY-MM-DD'),
            isWorkDay: !isDayOff,
            workingHoursStart:
              startTime.length > 0
                ? startTime.slice(0, 5)
                : constantTimePickerOUT[0].value,
            workingHoursEnd:
              endTime.length > 0
                ? endTime.slice(0, 5)
                : constantTimePickerOUT[constantTimePickerOUT.length - 1].value,
          },
        ],
      })
        .unwrap()
        .then(res => {
          setEditable(false);
          dispatch(
            setMessage({
              message: 'Special schedule was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  return (
    <>
      {checkWorkingTime.isLoading || checkWorkingTime.isFetching ? (
        <LinearLoader />
      ) : (
        <S.CardWrapper $editable={editable}>
          <S.HeaderCard>
            <S.SubArticle>Day Schedule</S.SubArticle>
            <S.Date>{date[0].format('DD MMM, YYYY')}</S.Date>
          </S.HeaderCard>
          <S.ContentWrapper>
            <S.Content>
              {editable && (
                <S.ArticleWrap>
                  <S.Article14>Is it day off day?</S.Article14>
                  <CheckBox
                    id="isWorkDay"
                    name="isWorkDay"
                    value={isDayOff}
                    checked={isDayOff}
                    onChange={() => setIsDayOff(!isDayOff)}
                  />
                </S.ArticleWrap>
              )}
              <S.WorkingHours>
                <S.Article>Working Hours:</S.Article>
                {editable ? (
                  <S.Inputs>
                    <SelectInput
                      disabled={isDayOff}
                      label="Start"
                      value={
                        startTime ? startTime.slice(0, 5) : constantTimePickerOUT[0].value
                      }
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setStartTime(e.target.value as string);
                      }}
                    >
                      {constantTimePickerOUT.map(time => (
                        <MenuItem key={time.name} value={time.value}>
                          <S.MenuItemContent>{time.name}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </SelectInput>
                    <HorizontalLineSVG className="line" />
                    <SelectInput
                      disabled={isDayOff}
                      label="End"
                      value={
                        endTime
                          ? endTime.slice(0, 5)
                          : constantTimePickerOUT[constantTimePickerOUT.length - 1].value
                      }
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setEndTime(e.target.value as string);
                      }}
                    >
                      {constantTimePickerOUT.map(time => (
                        <MenuItem key={time.name} value={time.value}>
                          <S.MenuItemContent>{time.name}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </S.Inputs>
                ) : (
                  <S.Time>
                    {checkWorkingTime.currentData?.time?.length &&
                    checkWorkingTime.currentData?.isWorkDay ? (
                      convertAMtoPM(checkWorkingTime.currentData.time[0]) +
                      ' - ' +
                      convertAMtoPM(
                        checkWorkingTime.currentData.time[
                          checkWorkingTime.currentData.time.length - 1
                        ],
                      )
                    ) : (
                      <S.DayOff>Day Off</S.DayOff>
                    )}
                  </S.Time>
                )}
              </S.WorkingHours>
            </S.Content>
          </S.ContentWrapper>
          <S.Footer>
            {editable ? (
              <S.Btns>
                <S.ButtonWrapper>
                  <Button
                    backgroundColor="#848A9B"
                    text="Cancel"
                    onClick={() => {
                      resetValues();
                      setEditable(false);
                    }}
                  />
                </S.ButtonWrapper>
                <S.ButtonWrapper>
                  <Button text="Save" onClick={handleSave}></Button>
                </S.ButtonWrapper>
              </S.Btns>
            ) : (
              <S.ChangeScheduleWrapper onClick={() => setEditable(true)}>
                <CalendarIconSVG />
                <S.BtnText>Change schedule for {date[0].format('DD MMMM')}</S.BtnText>
              </S.ChangeScheduleWrapper>
            )}
          </S.Footer>
        </S.CardWrapper>
      )}
    </>
  );
};

export default ClinicScheduleCard;
