import styled from 'styled-components';

export const MessageWrapper = styled.div<{ $isMine?: boolean }>`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: ${props => (props.$isMine ? 'end' : 'start')};
`;

export const Content = styled.div<{ $isMine?: boolean }>`
  display: flex;
  width: 70%;
  flex-direction: ${props => (props.$isMine ? 'row-reverse' : 'row')};
`;

export const Avatar = styled.div`
  margin-top: 12px;
  & img {
    height: 48px;
    width: 48px;
    border-radius: 48px;
    object-fit: cover;
  }

  & svg {
    height: 48px;
    width: 48px;
  }
`;

export const TextWrapper = styled.div<{ $isMine?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  width: 100%;
  align-items: ${props => (props.$isMine ? 'end' : 'start')};
`;

export const UserInfo = styled.div<{ $isMine?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 9px;
  margin-bottom: 3px;
  /* flex-direction: ${props => (props.$isMine ? 'row-reverse' : 'row')}; */
`;

export const MessageText = styled.div<{ $isMine: boolean }>`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: normal;
  padding: 7px 20px 9px 12px;
  border-radius: 10px;
  width: 100%;
  background: ${props => (props.$isMine ? '#E1EBEE' : '#f5f5f5')};
`;

export const Text = styled.div`
  word-wrap: break-word;
  word-break: break-all;
  width: 100%;
`;

export const UserInfoText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-right: 25px;
`;

export const TimeText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 30px;
`;

export const Name = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Files = styled.div<{ $isMine: boolean }>`
  display: flex;
  justify-content: ${props => (props.$isMine ? 'flex-start' : 'flex-start')};
  border-radius: 10px;
  width: 100%;
  flex-wrap: ${props => (props.$isMine ? 'wrap' : 'wrap')};
  gap: 5px;
`;

export const FileWrapper = styled.div<{ $isMine: boolean }>`
  margin-top: 5px;
  width: 100%;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${props => (props.$isMine ? 'row' : 'row')};
  gap: 3px;

  padding: 7px 20px 9px 12px;
  width: 40%;

  border-radius: 10px;
  background: ${props => (props.$isMine ? '#d1dde8' : '#dbd9d9')};
`;

export const InfoSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Size = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
