import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(198, 96, 96, 0.2);
  cursor: help;

  color: #c66060;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;
