import { useEffect, useMemo, useState } from 'react';
import { SurveyType } from '../../../../../../types/SurveyType';
import Button from '../../../../../atoms/Button';
import CheckBox from '../../../../../atoms/CheckBox';
import Input from '../../../../../atoms/Input';
import * as S from './styles';
import AutocompleteMultiple from '../../../../../molecules/AutocompleteMultiple';
import { Patients } from '../../../../../../types/ClinicTypes';
import { usePatientsGetQuery } from '../../../../../../store/api/clinics/clinicApi';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useDebounce } from 'use-debounce';
import { CouponNoteSVG, NoteIconSVG, NoteSVG } from '../../../../../../assets/icons';
import { useSurveySendMutation } from '../../../../../../store/api/survey/surveyApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';

type Props = {
  onClose: () => void;
  row: SurveyType;
};

const SendSurveyDialog: React.FC<Props> = ({ onClose, row }) => {
  const dispatch = useAppDispatch();
  const [allPatients, setAllPatients] = useState(true);
  const [isPhone, setIsPhone] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [selectedValue, setSelectedValue] = useState<Array<any>>([]);

  const rowData = usePatientsGetQuery({
    q: debouncedSearchValue,
    id: selectedClinic.id,
    page: 1,
  });

  const [valueUsersToServer, setValueUsersToServer] = useState<any>([]);

  useEffect(() => {
    setValueUsersToServer(selectedValue.map((item: Patients) => item.user?.id));
  }, [selectedValue]);

  const disableButton = useMemo(
    () => (!allPatients && !selectedValue.length) || (!isPhone && !isEmail),
    [allPatients, selectedValue, isPhone, isEmail],
  );

  const [sendSurvey] = useSurveySendMutation({});

  const handleConfirmClick = () => {
    onClose();
    sendSurvey({
      id: row.id,
      viaPhone: isPhone,
      viaEmail: isEmail,
      toAllPatients: allPatients,
      patientIds: allPatients ? [] : valueUsersToServer,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(err =>
        dispatch(
          setMessage({
            message: err.data.message,
            type: 'error',
          }),
        ),
      );
  };

  return (
    <>
      <S.Content>
        <S.Article>Send Survey "{row.title}"</S.Article>
        <S.CheckBoxWrapper>
          <S.LabelCheckbox>Send All Clinic Patients</S.LabelCheckbox>
          <CheckBox
            checked={allPatients}
            value={allPatients}
            onChange={() => setAllPatients(!allPatients)}
          />
        </S.CheckBoxWrapper>
        <S.HelperText>
          <CouponNoteSVG />
          Uncheck the checkbox to select specific users
        </S.HelperText>

        <AutocompleteMultiple
          data={rowData?.currentData?.rows || []}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          valueUsers={selectedValue}
          setValueUsers={setSelectedValue}
          loading={rowData.isFetching || rowData.isLoading ? true : false}
          disabled={allPatients}
        />
        <S.LabelCheckboxStart>Send the Survey via:</S.LabelCheckboxStart>
        <S.InputWrapper>
          <S.CheckBoxViaWrapper>
            <CheckBox
              checked={isPhone}
              value={isPhone}
              onChange={() => setIsPhone(!isPhone)}
            />
            <S.LabelCheckbox>Phone Message</S.LabelCheckbox>
          </S.CheckBoxViaWrapper>
          <S.CheckBoxViaWrapper>
            <CheckBox
              checked={isEmail}
              value={isEmail}
              onChange={() => setIsEmail(!isEmail)}
            />
            <S.LabelCheckbox>Email</S.LabelCheckbox>
          </S.CheckBoxViaWrapper>
        </S.InputWrapper>
        <S.ButtonWrapper>
          <Button text="Send" onClick={handleConfirmClick} disabled={disableButton} />
        </S.ButtonWrapper>
      </S.Content>
    </>
  );
};

export default SendSurveyDialog;
