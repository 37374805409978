import { useState } from 'react';
import { AppointmentStatus } from '../../types/StatusTypes';
import { UserStatus } from '../../types/UserStatuses';

export const getAppointmentPinColorV2 = (status: string | undefined | boolean) => {
  switch (status) {
    case true: {
      return {
        backgroundColor: '#F4DFDF',
        borderColor: '#C66060',
        fontColor: '#C66060',
        text: 'BLOCKED',
      };
    }
    case UserStatus.VERIFIED_FULL:
      return {
        backgroundColor: '#E6EEDD',
        borderColor: '#79A030',
        fontColor: '#79A030',
        text: 'Active',
      };
    case UserStatus.VERIFIED_FIELD_DATA:
      return {
        backgroundColor: '#FFFAE1',
        borderColor: '#D17E00',
        fontColor: '#D17E00',
        text: 'Pending',
      };
    case UserStatus.VERIFIED_AUTH_FIELD:
      return {
        backgroundColor: '#FFECE1',
        borderColor: '#F95C01',
        fontColor: '#F95C01',
        text: 'Waiting',
      };
    case UserStatus.BUFFER:
      return {
        backgroundColor: '#E7DAEE',
        borderColor: '#A436E8',
        fontColor: '#A436E8',
        text: 'Unreg',
      };
    case UserStatus.UNVERIFIED:
      return {
        backgroundColor: '#F9F9FC',
        borderColor: '#D7d7d7',
        fontColor: '#d7d7d7',
        text: 'None',
      };
    case AppointmentStatus.ACTIVE:
      return {
        backgroundColor: '#9BC15F',
        borderColor: '#79A030',
        fontColor: '#fff',
        text: 'ACTIVE',
      };
    case AppointmentStatus.CANCELED:
      return {
        backgroundColor: '#C66060',
        borderColor: '#C66060',
        fontColor: '#fff',
        text: 'CANCELED',
      };
    case AppointmentStatus.COMPLETED:
      return {
        backgroundColor: '#A25FC1',
        borderColor: '#4570DE',
        fontColor: '#fff',
        text: 'COMPLETED',
      };
    case AppointmentStatus.PENDING:
      return {
        backgroundColor: '#FF9E68',
        borderColor: '#D17E00',
        fontColor: '#fff',
        text: 'PENDING',
      };
    case 'in_progress':
      return {
        backgroundColor: '#FFFAE1',
        borderColor: '#D17E00',
        fontColor: '#D17E00',
        text: 'IN PROGRESS',
      };
    case 'close':
      return {
        backgroundColor: '#DAE2F8',
        borderColor: '#4570DE',
        fontColor: '#4570DE',
        text: 'COMPLETED',
      };
    default: {
      return {
        backgroundColor: '#F9F9FC',
        borderColor: '#D7d7d7',
        fontColor: '#d7d7d7',
        text: 'Default',
      };
    }
  }
};
