import React from 'react';
import * as S from './styles';
import { ButtonProps } from '@mui/material';

type Props = {
  text: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  width?: string;
  height?: string;
  customStyle?: any;

  backgroundColor?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
} & ButtonProps;

const Button: React.FC<Props> = ({
  text,
  width,
  height,
  onClick,
  customStyle,
  children,
  backgroundColor,
  type,
  ...props
}) => {
  return (
    <S.StyledMUIButton
      onClick={onClick}
      {...props}
      variant="contained"
      style={customStyle ? customStyle : { height, width, backgroundColor }}
      type={type}
    >
      <S.IconWrap>
        {children}
        {text}
      </S.IconWrap>
    </S.StyledMUIButton>
  );
};

export default Button;
