import styled from 'styled-components';

export const ServiceBox = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ServiceCircle = styled.div<{ color: string; disabled: boolean }>`
  background-color: ${props => props.color};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border-radius: 50%;

  &.l {
    width: 34px;
    height: 34px;
  }

  &.xs {
    width: 22px;
    height: 22px;
  }
`;
