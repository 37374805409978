import { PopoverProps } from '@mui/material';
import * as S from './styles';

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children?: React.ReactNode;
  article?: string;
  showSeeMore?: boolean;
  disableRestoreFocus?: boolean;
  id?: string;
  margin?: number;
} & PopoverProps;

const NotificationPopover: React.FC<Props> = ({
  open,
  anchorEl,
  onClose,
  children,
  article,
  showSeeMore,
  disableRestoreFocus,
  id,
  margin,
  ...props
}) => {
  return (
    <S.NotificationWrapper
      id={id}
      onClose={onClose}
      open={open}
      disableRestoreFocus={disableRestoreFocus}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: margin || -10,
        horizontal: 'center',
      }}
      {...props}
    >
      <S.ContentWrapper>
        {article && <S.Article>{article}</S.Article>}
        <S.Content>{children}</S.Content>
      </S.ContentWrapper>
      {/* {showSeeMore && <S.SeeMore>See more</S.SeeMore>} */}
    </S.NotificationWrapper>
  );
};

export default NotificationPopover;
