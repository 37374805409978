import { Alert } from '@mui/material';
import styled, { css } from 'styled-components';
import { devices } from '../../../constants/device';

export const AlertItem = styled(Alert)`
  width: 100%;

  & .MuiAlert-message {
    overflow: hidden;
    width: 100%;
    padding-right: 15px;
  }

  &.MuiAlert-root {
    border-radius: 10px;
    padding: 0;
    align-items: center;
    height: 54px;
  }

  & .MuiAlert-icon {
    margin-left: 12px;

    @media ${devices.xs} {
      margin-left: 6px;
    }
  }
`;

export const AlertText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  min-width: 409px;
  display: flex;
  flex-direction: column;

  @media ${devices.xs} {
    width: 261px;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AlertItemContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 10px;
  width: 100%;

  & .closeBtn {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export const AlertWrapper = styled.div<{ $isDefault: boolean }>`
  ${props =>
    props.$isDefault &&
    css`
      box-shadow: 0px -2px 27px 0px rgba(0, 84, 113, 0.2);
      border-radius: 8px;
      border: 1px solid #ffc107;
    `}
  margin-left: 15px;
  margin-right: 15px;

  @media ${devices.xs} {
    max-width: 300px;
  }
`;
