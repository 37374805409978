import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import * as S from './styles';
import { AvatarSVG } from '../../../assets/icons';
import { ChangeEvent } from 'react';

type Props = {
  mainLabel?: string;
  valueUsers: Array<any>;
  setValueUsers: any;
  searchValue: string;
  setSearchValue: any;
  disabled?: boolean;
  loading?: boolean;
  data: Array<any>;
};

const AutocompleteMultiple: React.FC<Props> = ({
  mainLabel,
  valueUsers,
  setValueUsers,
  searchValue,
  setSearchValue,
  disabled,
  loading,
  data,
}) => {
  return (
    <S.InputWrapper>
      <S.Label htmlFor="input">{mainLabel}</S.Label>
      <Autocomplete
        value={valueUsers}
        onChange={(event, newValue) => {
          setValueUsers(newValue);
        }}
        disabled={disabled}
        multiple
        id="tags-filled"
        options={data || []}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.user?.profile?.id}>
              {loading ? (
                'Searching...'
              ) : (
                <S.LiWrapper>
                  <S.UserInfo>
                    {option.user?.avatar ? (
                      <S.Avatar>
                        <img src={option.user.avatar?.url} alt="ava" />
                      </S.Avatar>
                    ) : (
                      <AvatarSVG />
                    )}
                    <div>
                      {option.user.profile?.firstName +
                        ' ' +
                        option.user.profile?.lastName}
                    </div>
                  </S.UserInfo>
                  <S.DateOfBirth>{option.user.profile?.dateOfBirth}</S.DateOfBirth>
                </S.LiWrapper>
              )}
            </Box>
          );
        }}
        getOptionLabel={option =>
          option
            ? option.user?.profile?.firstName + ' ' + option.user?.profile?.lastName
            : ''
        }
        renderTags={(value: any, getTagProps) =>
          value.map((option: any, index: number) => (
            <Chip
              variant="outlined"
              label={
                option.user?.profile?.firstName + ' ' + option.user?.profile?.lastName
              }
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Search"
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
        )}
      />
    </S.InputWrapper>
  );
};

export default AutocompleteMultiple;
