import { useState } from 'react';
import { CustomPagination, CustomTable } from 'components';
import { useInvoicePrePaymentAllQuery } from 'store/api/invoices/invoiceApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import InvoicePrepaymentRow from './TableData/InvoicePrepaymentRow';

import * as S from './styles';

const headers = [
  { name: 'Year', key: 'Year' },
  { name: 'Clinic name', key: 'Clinic' },
  { name: 'Charged', key: 'Charged' },
  { name: 'Status', key: 'Status' },
  { name: 'Number of items', key: 'Number' },
];

const INVOICE_PRE_PAYMENT_LIMIT = 20;

const InvoicePrepayment = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [filter, setFilter] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useInvoicePrePaymentAllQuery({
    q: debouncedSearchValue || undefined,
    page,
    take: INVOICE_PRE_PAYMENT_LIMIT,
    clinicId: selectedClinic.id,
  });

  return (
    <S.TableBody>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'invoices'}
          limit={INVOICE_PRE_PAYMENT_LIMIT}
        />
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching || getList.isLoading}>
        <InvoicePrepaymentRow
          clickable
          data={getList?.currentData?.rows}
          headers={headers}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default InvoicePrepayment;
