import styled, { css } from 'styled-components';
import { devices } from '../../../constants/device';

export const MultiDatePickerWrapper = styled.div<{ $disabledClick?: boolean }>`
  width: 100%;

  ${props =>
    props.$disabledClick &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  & .rmdp-shadow {
    box-shadow: none;
  }

  & .rmdp-range {
    color: var(--text, #555);
    border: none;
    background: var(--hover-1, rgba(0, 132, 177, 0.08));
    box-shadow: none;
  }

  & .rmdp-wrapper {
    width: 100%;
    background: none;
    color: var(--text, #555);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    & span {
      font-size: 14px;

      @media ${devices.xs} {
        font-size: 16px;
      }
    }
  }

  .rmdp-day:hover span:hover {
    background: #0084b1;
    color: #fff;
    border-radius: 10%;

    opacity: 0.5;
    &.colored {
      background: #e17777 !important;
      color: #fff !important;
    }
  }

  .rmdp-selected {
    //background: #0084b1;
    box-shadow: none;
    border-radius: 15px;
  }

  .rmdp-selected span:not(.highlight) {
    background: #0084b1;
    box-shadow: none;
    border-radius: 15px;
  }

  .rmdp-week-day {
    font-size: 14px;
    color: var(--main, #0084b1);

    @media ${devices.xs} {
      font-size: 18px;
    }
  }

  .rmdp-range.start {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  .rmdp-range.end {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rmdp-selected {
    pointer-events: none;
    &.rmdp-day.rmdp-today span {
      background: #0084b1;

      color: var(--main, #e1ebee);
    }

    & .colored {
      background: #e17777 !important;
      color: var(--main, #fff) !important;
    }
  }

  .rmdp-day.rmdp-today span {
    background: #e1ebee;
    color: var(--main, #0084b1);
  }

  & .disabledClick {
    .rmdp-day {
      pointer-events: none;
      user-select: none;
    }
  }

  .rmdp-header {
    @media ${devices.xs} {
      margin-bottom: 14px;
    }
  }

  .rmdp-header-values {
    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media ${devices.xs} {
      font-size: 16px;
    }
  }

  .rmdp-arrow {
    border: solid #202e5f;
    border-width: 0 1px 1px 0;

    @media ${devices.xs} {
      font-size: 18px;
    }
  }

  .rmdp-week {
    @media ${devices.xs} {
      gap: 10px;
    }
  }
  .rmdp-arrow-container:hover {
    background-color: #0084b1;
  }
`;
