import { ClaimTypeEnum } from '../../types/InvoiceType';

export const getClaimTypeColor = (type: ClaimTypeEnum) => {
  switch (type) {
    case ClaimTypeEnum.BLANK:
      return '#9663BE';
    case ClaimTypeEnum.MANUAL:
      return '#DD8500';
    case ClaimTypeEnum.SYSTEM:
      return '#23b8eb';
    case ClaimTypeEnum.ON_THE_PATIENT_RECORDS:
      return '#4e8a89';
    case ClaimTypeEnum.ON_THE_PREVIOUS_CLAIM:
      return '#fa5ca6';
  }
};
