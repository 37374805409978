import { useDispatch } from 'react-redux';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';
import { TemplateFieldsForFill, TemplateType } from '../../../types/TemplateType';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const templateApi = api.injectEndpoints({
  endpoints: builder => ({
    templatesGet: builder.query<Response<TemplateType>, any>({
      query: params => ({
        url: `/template`,
        params: { ...params },
      }),
      providesTags: ['Templates'],
    }),
    templatesCreate: builder.mutation({
      query: body => {
        const newData = new FormData();
        newData.append('file', body.file);
        newData.append('name', body.name);
        newData.append('note', body.note);
        body.clinicId.map((item: string) => {
          newData.append('clinicId[]', item);
        });

        return {
          url: `/template`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Templates' }],
    }),
    templatesUpdate: builder.mutation({
      query: body => {
        const newData = new FormData();
        if (body.file) {
          newData.append('file', body.file);
        }
        newData.append('name', body.name);
        newData.append('note', body.note);
        body.clinicId.map((item: string) => {
          newData.append('clinicId[]', item);
        });

        return {
          url: `/template/${body.id}`,
          method: 'PUT',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Templates' }],
    }),
    templatesGetFieldById: builder.mutation<TemplateFieldsForFill[], any>({
      query: ({ id, ...params }) => {
        return {
          url: `/template/${id}/fields-for-fill`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    templatesGetById: builder.mutation<any, any>({
      query: ({ id, ...params }) => {
        return {
          url: `/template/${id}`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    templatesUpdateSelectedTypes: builder.mutation<any, any>({
      query: ({ id, ...params }) => {
        return {
          url: `/template-clinic/update-selected-types`,
          method: 'PATCH',
          body: { ...params },
        };
      },
      invalidatesTags: () => [{ type: 'Templates' }],
    }),
    templatePrintPDF: builder.mutation<any, any>({
      query: ({ id, ...params }) => {
        return {
          url: `/template/print-pdf`,
          method: 'GET',
          params: { ...params },
          responseHandler: async (response: any) => {
            const blobItem = await response.blob();
            const blobURL = URL.createObjectURL(blobItem);
            return blobURL;
          },
        };
      },
    }),
    templateInsertSignature: builder.mutation<any, any>({
      query: ({ id, file, ...params }) => {
        const newData = new FormData();
        newData.append('signature', file);

        return {
          url: `/template/insert-patient-signature`,
          method: 'POST',
          params: { ...params },
          body: newData,
          formData: true,
          responseHandler: async (response: any) => {
            const blobItem = await response.blob();
            const blobURL = URL.createObjectURL(blobItem);
            return blobURL;
          },
        };
      },
      invalidatesTags: ['Documents'],
    }),
    templateEmptyPrintPDF: builder.mutation<any, any>({
      query: ({ ...params }) => {
        return {
          url: `/template/print-empty-pdf`,
          method: 'GET',
          params: { ...params },
          responseHandler: async (response: any) => {
            const blobItem = await response.blob();
            const blobURL = URL.createObjectURL(blobItem);
            return blobURL;
          },
        };
      },
    }),
    digitalFormCreate: builder.mutation<any, any>({
      query: ({ id, params, ...body }) => {
        return {
          url: `/digital-form`,
          method: 'POST',
          body: { ...body },
          params: { ...params },
        };
      },
    }),
    digitalFormUpdate: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/digital-form/${id}`,
          method: 'PUT',
          body: { ...body },
        };
      },
    }),
    getPdfById: builder.mutation<any, any>({
      query: ({ id, ...params }) => {
        return {
          url: `/pdf-form/${id}`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    deleteTemplate: builder.mutation<any, any>({
      query: ({ id, ...params }) => {
        return {
          url: `/template/${id}`,
          method: 'DELETE',
          params: { ...params },
        };
      },
      invalidatesTags: () => [{ type: 'Templates' }],
    }),
    digitalFormSavePDF: builder.mutation<any, any>({
      query: ({ id, file, pdf, ...body }) => {
        const newData = new FormData();
        newData.append('signature', file);
        newData.append('pdf', pdf);
        return {
          url: `/digital-form/${id}/save-pdf`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: ['Documents', 'Templates'],
    }),

    addSignatureToPDF: builder.mutation<any, any>({
      query: ({ id, file, ...params }) => {
        const newData = new FormData();
        newData.append('signature', file);
        return {
          url: `/pdf-form/add-patient-signature`,
          method: 'POST',
          body: newData,
          formData: true,
          params: { ...params },
          responseHandler: async (response: any) => {
            const blobItem = await response.blob();
            const blobURL = URL.createObjectURL(blobItem);
            return blobURL;
          },
        };
      },
      invalidatesTags: ['Documents'],
    }),
    editStepList: builder.mutation<any, any>({
      query: ({ id, ...body }) => {
        return {
          url: `/appointment-process/${id}/edit`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: ['Appointments', 'Progress'],
    }),
  }),
});

export const {
  useTemplatesGetQuery,
  useTemplatesCreateMutation,
  useTemplatesUpdateMutation,
  useTemplatesGetFieldByIdMutation,
  useTemplatePrintPDFMutation,
  useTemplateEmptyPrintPDFMutation,
  useTemplateInsertSignatureMutation,
  useDigitalFormCreateMutation,
  useDigitalFormSavePDFMutation,
  useTemplatesUpdateSelectedTypesMutation,
  useTemplatesGetByIdMutation,
  useGetPdfByIdMutation,
  useAddSignatureToPDFMutation,
  useDeleteTemplateMutation,
  useEditStepListMutation,
  useDigitalFormUpdateMutation,
} = templateApi;
