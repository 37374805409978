import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  /* flex-direction: column; */
`;
