import { useInvoiceGetEditHistoryQuery } from 'store/api/invoices/invoiceApi';
import * as S from './styles';
import dayjs from 'dayjs';
import { getTextHistory } from './config';
import { InvoiceType } from 'types/enums/AppointmentEnum';
import { useEffect, useState } from 'react';
import useInfinityScroll from 'hooks/useInfinityScroll';

type Props = {
  props: {
    id: string;
    type: InvoiceType;
  };
};

const INVOICE_HISTORY_LIMIT = 20;

const ShowInvoiceHistory = ({ props: { id, type } }: Props) => {
  const [page, setPage] = useState(1);

  const getShowHistory = useInvoiceGetEditHistoryQuery({
    id: id,
    type: type,
    page,
    take: INVOICE_HISTORY_LIMIT,
  });

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(page + 1),
    hasMore: page < (getShowHistory.currentData?.lastPage ?? 0),
  });

  useEffect(() => {
    getShowHistory.refetch();
  }, [page]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.ArticleWrap>
          <S.Article>Show Edit History</S.Article>
        </S.ArticleWrap>
        <S.RowContent>
          {getShowHistory.data?.rows?.map((item, index, { length }) => {
            const refLast = index === length - 1 ? setLastElement : null;

            return (
              <S.RowItem key={item?.id} ref={refLast}>
                {getTextHistory(item)}
                <S.Time>{dayjs(item?.createdAt).format('h:mm a MM/DD/YYYY')}</S.Time>
              </S.RowItem>
            );
          })}
        </S.RowContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default ShowInvoiceHistory;
