import { FormikProps } from 'formik';
import { CredentialsFormValues } from '../CredentialsDoctor';
import * as S from '../styles';
import { UserProfile } from '../../../../../../../types/UserProfileTypes';

type Props = {
  formik: FormikProps<CredentialsFormValues>;
  profile: UserProfile | undefined;
};

const ViewCredentials: React.FC<Props> = ({ formik, profile }) => {
  return (
    <S.BlockContent>
      <S.Items>
        <S.Item>
          <S.ItemArticle>NPI</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.npi || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>TAX ID</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.taxId || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>Taxonomy</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.taxonomy || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>Taxanomy name</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.taxonomyName || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>NYS Acu License</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.nysAcuLicense || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>NYS Acu Lic Expiration</S.ItemArticle>
          <S.ItemValue>
            {profile?.credentials?.nysAcuLicenseExpiration || '-'}
          </S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>CAQH ID</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.caqhId || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>First Aid Certification</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.firstAidCertification || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>First Aid Cert Expiration</S.ItemArticle>
          <S.ItemValue>
            {profile?.credentials?.firstAidCertificationExpiration || '-'}
          </S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>Aetna ID</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.aetnaId || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>UHC ID</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.uhcId || '-'}</S.ItemValue>
        </S.Item>
        <S.Item>
          <S.ItemArticle>OXPH PIN</S.ItemArticle>
          <S.ItemValue>{profile?.credentials?.oxphId || '-'}</S.ItemValue>
        </S.Item>
      </S.Items>
    </S.BlockContent>
  );
};

export default ViewCredentials;
