import styled from 'styled-components';

export const PaginationText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;

  & svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    stroke: #848a9b;
    opacity: 1;

    &.disabled {
      stroke: #bfc2cb;
      pointer-events: none;
    }
  }
`;
