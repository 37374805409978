import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import CashTableData from "./CashTableData";

import InvoicePrepaymentData from './InvoicePrepaymentData';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: Array<Object> | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
};

const InvoicePrepaymentRow: React.FC<Props> = ({ headers, data, clickable }) => {
  const navigate = useNavigate();
  /*const a = {
    controlNumber: '123459999',
    tradingPartnerServiceId: '9496', // payerId
    tradingPartnerName: 'unknown',
    payerAddress: {
      address1: '123 address1',
      city: 'city1',
      state: 'wa',
      postalCode: '981010000',
    },
    submitter: {
      organizationName: 'happy doctors grouppractice',
      etin: '1942788757',
    },
    provider: {
      organizationName: 'happy doctors group',
      npi: '1234560789',
      address: {
        address1: '123 address1',
        city: 'city1',
        state: 'wa',
        postalCode: '981010000',
      },
      phoneNumber: '123456789',
      faxNumber: '123456789',
    },
    subscriber: {
      memberId: '0000000001',
      firstName: 'johnone',
      lastName: 'doeone',
    },
    claimInformation: {
      patientControlNumber: '12345',
      beginClaimServiceDate: '20050513',
      endClaimServiceDate: '20050514',
      serviceLines: [
        {
          payerClaimControlNumber: '123456789',
          serviceLineDateInformation: {
            submissionDate: '20050514',
          },
          attachmentDetails: {
            name: 'rightarm.jpg',
          },
        },
      ],
    },
  };*/
  return (
    <>
      {data &&
        data.map((row: any, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && navigate('/invoices/pre-payment/' + row.id);
            }}
          >
            {headers.map((header, index) => (
              <TableCell key={header.key + index}>
                <InvoicePrepaymentData key={header.key} header={header.key} row={row} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default InvoicePrepaymentRow;
