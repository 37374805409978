import { InitialVisitFormType } from '../../../../pages/SuperAdmin/components/Templates/components/pages/InitialVisitFormPage/InitialVisitiFormType';
import { AppointmentProcessInterface, Step } from '../../../../types/AppointmentNewTypes';
import { DigitalTemplateType } from '../../../../types/TemplateType';

export const initialvisiformFormik = (
  name?: string,
  data?: AppointmentProcessInterface,
  currentStep?: Step,
) => {
  const payload: InitialVisitFormType | undefined = currentStep?.digitalForm?.payload;
  return {
    mdType: DigitalTemplateType.INITIAL,
    accountNumber: payload?.accountNumber ?? '',
    patientName: payload?.patientName ?? '',
    birthDate: payload?.birthDate ?? '',
    gender: payload?.gender ?? '',
    symptoms: {
      majorComplaint: payload?.symptoms?.majorComplaint ?? '',
      feelWorse: {
        isMorning: payload?.symptoms?.feelWorse?.isMorning ?? false,
        isDaytime: payload?.symptoms?.feelWorse?.isDaytime ?? false,
        isEvening: payload?.symptoms?.feelWorse?.isEvening ?? false,
        isNight: payload?.symptoms?.feelWorse?.isNight ?? false,
        isRain: payload?.symptoms?.feelWorse?.isRain ?? false,
        isColdOrWarmWeather: payload?.symptoms?.feelWorse?.isColdOrWarmWeather ?? false,
        isUpStair: payload?.symptoms?.feelWorse?.isUpStair ?? false,
        isDownStair: payload?.symptoms?.feelWorse?.isDownStair ?? false,
      },
      haveYouEvenTry: {
        isAcupuncture: payload?.symptoms?.haveYouEvenTry?.isAcupuncture ?? false,
        whenAcupuncture: payload?.symptoms?.haveYouEvenTry?.whenAcupuncture ?? '',
        isChineseMedicineHerb:
          payload?.symptoms?.haveYouEvenTry?.isChineseMedicineHerb ?? false,
        whenChineseMedicineHerb:
          payload?.symptoms?.haveYouEvenTry?.whenChineseMedicineHerb ?? '',
      },
      listOfConditions: {
        conditions: [
          {
            name: '',
            years: 2,
            months: 5,
            days: 10,
          },
        ],
        whenFeelConditions: {
          isHotOrCold:
            payload?.symptoms.listOfConditions.whenFeelConditions?.isHotOrCold ?? false,
          isSpontaneously:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isSpontaneously ??
            false,
          isNightSweating:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isNightSweating ??
            false,
          isDizziness:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isDizziness ?? false,
          isColdOrWarmWeather:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isColdOrWarmWeather ??
            false,
          isUpStair:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isUpStair ?? false,
          isDownStair:
            payload?.symptoms?.listOfConditions.whenFeelConditions?.isDownStair ?? false,
        },
      },
      body: {
        appetite: payload?.symptoms?.body?.appetite ?? '',
        food: payload?.symptoms?.body?.food ?? '',
        doYouHave: {
          isChestPain: payload?.symptoms?.body?.doYouHave?.isChestPain ?? false,
          isPress: payload?.symptoms?.body?.doYouHave?.isPress ?? false,
          isPalpitation: payload?.symptoms?.body?.doYouHave?.isPalpitation ?? false,
        },
        sleep: {
          hours: payload?.symptoms?.body?.sleep?.hours ?? 0,
          quality: payload?.symptoms?.body?.sleep?.quality ?? '',
        },
        urine: {
          times: payload?.symptoms?.body?.urine?.times ?? 0,
          quality: payload?.symptoms?.body?.urine?.quality ?? '',
        },
        stool: {
          days: payload?.symptoms?.body?.stool?.days ?? 0,
          isHaveConstipation: payload?.symptoms?.body?.stool?.isHaveConstipation ?? false,
          isHaveLoose: payload?.symptoms?.body?.stool?.isHaveLoose ?? false,
        },
        menstruation: {
          startAge: payload?.symptoms?.body?.menstruation?.startAge ?? 0,
          cycleDays: payload?.symptoms?.body?.menstruation?.cycleDays ?? 0,
          lastDays: payload?.symptoms?.body?.menstruation?.lastDays ?? 0,
          amount: payload?.symptoms?.body?.menstruation?.amount ?? '',
          color: payload?.symptoms?.body?.menstruation?.color ?? '',
          clots: payload?.symptoms?.body?.menstruation?.clots ?? '',
          isCrampPain: payload?.symptoms?.body?.menstruation?.isCrampPain ?? false,
          isPregnant: payload?.symptoms?.body?.menstruation?.isPregnant ?? false,
          dateOfLastPeriod: payload?.symptoms?.body?.menstruation?.dateOfLastPeriod ?? '',
        },
      },
    },
    personal: {
      isSmoke: payload?.personal?.isSmoke ?? false,
      isDrinkAlcohol: payload?.personal?.isDrinkAlcohol ?? false,
    },
    medicalHistory: {
      isHypertension: payload?.medicalHistory.isHypertension ?? false,
      isDiabetes: payload?.medicalHistory.isDiabetes ?? false,
      isHeartDisease: payload?.medicalHistory.isHeartDisease ?? false,
      isPulmonaryDisease: payload?.medicalHistory.isPulmonaryDisease ?? false,
      isCancer: payload?.medicalHistory.isCancer ?? false,
      isAllergies: payload?.medicalHistory.isAllergies ?? false,
      other: payload?.medicalHistory.other ?? '',
      medicineAndDosage: {
        medicine: payload?.medicalHistory.medicineAndDosage.medicine ?? [],
        isAnySurgeries: payload?.medicalHistory.medicineAndDosage.isAnySurgeries ?? false,
        isAnyAccidents: payload?.medicalHistory.medicineAndDosage.isAnyAccidents ?? false,
        kindOfSurgeries: payload?.medicalHistory.medicineAndDosage.kindOfSurgeries ?? '',
        kindOfAccidents: payload?.medicalHistory.medicineAndDosage.kindOfAccidents ?? '',
      },
    },
    familyHistory: {
      isDiabetes: payload?.familyHistory.isDiabetes ?? false,
      isHeartDisease: payload?.familyHistory.isHeartDisease ?? false,
      isHighBloodPressure: payload?.familyHistory.isHighBloodPressure ?? false,
      isCancer: payload?.familyHistory.isCancer ?? false,
      other: payload?.familyHistory.other ?? '',
    },
    observation: {
      pulse: {
        rhythm: {
          isRegular: payload?.observation.pulse.rhythm.isRegular ?? false,
          isIrregular: payload?.observation.pulse.rhythm.isIrregular ?? false,
          isRapid: payload?.observation.pulse.rhythm.isRapid ?? false,
          isSlow: payload?.observation.pulse.rhythm.isSlow ?? false,
          isIntermittent: payload?.observation.pulse.rhythm.isIntermittent ?? false,
        },
        quality: {
          isFloating: payload?.observation.pulse.quality.isFloating ?? false,
          isWeak: payload?.observation.pulse.quality.isWeak ?? false,
          isDeep: payload?.observation.pulse.quality.isDeep ?? false,
          isTightSlipper: payload?.observation.pulse.quality.isTightSlipper ?? false,
          isEmpty: payload?.observation.pulse.quality.isEmpty ?? false,
          isThin: payload?.observation.pulse.quality.isThin ?? false,
          isWiry: payload?.observation.pulse.quality.isWiry ?? false,
          isOther: payload?.observation.pulse.quality.isOther ?? false,
        },
      },
      triggerPoint: {
        boneRight: {
          isNormal: payload?.observation.triggerPoint.boneRight.isNormal ?? false,
          isUpper: payload?.observation.triggerPoint.boneRight.isUpper ?? false,
          isMid: payload?.observation.triggerPoint.boneRight.isMid ?? false,
          isLower: payload?.observation.triggerPoint.boneRight.isLower ?? false,
        },
        boneLeft: {
          isNormal: payload?.observation.triggerPoint.boneLeft.isNormal ?? false,
          isUpper: payload?.observation.triggerPoint.boneLeft.isUpper ?? false,
          isMid: payload?.observation.triggerPoint.boneLeft.isMid ?? false,
          isLower: payload?.observation.triggerPoint.boneLeft.isLower ?? false,
        },
      },
      tongue: {
        body: {
          isNormal: payload?.observation.tongue.body.isNormal ?? false,
          isSmall: payload?.observation.tongue.body.isSmall ?? false,
          isBig: payload?.observation.tongue.body.isBig ?? false,
          isTeethPrint: payload?.observation.tongue.body.isTeethPrint ?? false,
        },
        color: {
          isNormal: payload?.observation.tongue.color.isNormal ?? false,
          isPurple: payload?.observation.tongue.color.isPurple ?? false,
          isPurpleSpots: payload?.observation.tongue.color.isPurpleSpots ?? false,
        },
        coating: {
          isNone: payload?.observation.tongue.coating.isNone ?? false,
          isWhite: payload?.observation.tongue.coating.isWhite ?? false,
          isYellow: payload?.observation.tongue.coating.isYellow ?? false,
          isGreasy: payload?.observation.tongue.coating.isGreasy ?? false,
          isThin: payload?.observation.tongue.coating.isThin ?? false,
          isWet: payload?.observation.tongue.coating.isWet ?? false,
          isSticky: payload?.observation.tongue.coating.isSticky ?? false,
          isCracked: payload?.observation.tongue.coating.isCracked ?? false,
          isDry: payload?.observation.tongue.coating.isDry ?? false,
        },
        localChange: {
          isWarm: payload?.observation.tongue.localChange.isWarm ?? false,
          isCold: payload?.observation.tongue.localChange.isCold ?? false,
          isSwelling: payload?.observation.tongue.localChange.isSwelling ?? false,
          isAtrophy: payload?.observation.tongue.localChange.isAtrophy ?? false,
          isDeformed: payload?.observation.tongue.localChange.isDeformed ?? false,
          isTenderness: payload?.observation.tongue.localChange.isTenderness ?? false,
          isTriggerPoints:
            payload?.observation.tongue.localChange.isTriggerPoints ?? false,
        },
      },
    },
    assessment: {
      chineseDiagnostic: {
        isQiAndBloodStagnation:
          payload?.assessment.chineseDiagnostic.isQiAndBloodStagnation ?? false,
        isDampnessStagnation:
          payload?.assessment.chineseDiagnostic.isDampnessStagnation ?? false,
        isPostTraumaticPainSyndrome:
          payload?.assessment.chineseDiagnostic.isPostTraumaticPainSyndrome ?? false,
        isQiAndBloodDeficiency:
          payload?.assessment.chineseDiagnostic.isQiAndBloodDeficiency ?? false,
        isDampnessAndHeat:
          payload?.assessment.chineseDiagnostic.isDampnessAndHeat ?? false,
        isOther: payload?.assessment.chineseDiagnostic.isOther ?? false,
        other: payload?.assessment.chineseDiagnostic.other ?? '',
      },
      westernMedicalDiagnostic: {
        isCervicalgia:
          payload?.assessment.westernMedicalDiagnostic.isCervicalgia ?? false,
        isSciatica: payload?.assessment.westernMedicalDiagnostic.isSciatica ?? false,
        isLumbago: payload?.assessment.westernMedicalDiagnostic.isLumbago ?? false,
        isWristPain: payload?.assessment.westernMedicalDiagnostic.isWristPain ?? false,
        isHipPain: payload?.assessment.westernMedicalDiagnostic.isHipPain ?? false,
        isGonalgia: payload?.assessment.westernMedicalDiagnostic.isGonalgia ?? false,
        isShoulderPain:
          payload?.assessment.westernMedicalDiagnostic.isShoulderPain ?? false,
        isAnklePain: payload?.assessment.westernMedicalDiagnostic.isAnklePain ?? false,
        isTensionHeadache:
          payload?.assessment.westernMedicalDiagnostic.isTensionHeadache ?? false,
        isMigraine: payload?.assessment.westernMedicalDiagnostic.isMigraine ?? false,
        isHeadache: payload?.assessment.westernMedicalDiagnostic.isHeadache ?? false,
        isChronicPainDueToTrauma:
          payload?.assessment.westernMedicalDiagnostic.isChronicPainDueToTrauma ?? false,
        painIntensity: payload?.assessment.westernMedicalDiagnostic.painIntensity ?? '',
        rom: payload?.assessment.westernMedicalDiagnostic.rom ?? '',
        frequencyOfPain:
          payload?.assessment.westernMedicalDiagnostic.frequencyOfPain ?? '',
      },
    },
    treatmentPlan: {
      isPromoteFlowOfQiAndBlood:
        payload?.treatmentPlan.isPromoteFlowOfQiAndBlood ?? false,
      isRelieveMusclePain: payload?.treatmentPlan.isRelieveMusclePain ?? false,
      isInactivatedLocalTriggerPoints:
        payload?.treatmentPlan.isInactivatedLocalTriggerPoints ?? false,
      isClearMind: payload?.treatmentPlan.isClearMind ?? false,
      isBoostTendonStrength: payload?.treatmentPlan.isBoostTendonStrength ?? false,
      treatmentProvided: {
        isAcupuncture: payload?.treatmentPlan.treatmentProvided.isAcupuncture ?? false,
        isAcupunctureWithElectricalStimulation:
          payload?.treatmentPlan.treatmentProvided
            .isAcupunctureWithElectricalStimulation ?? false,
        isCupping: payload?.treatmentPlan.treatmentProvided.isCupping ?? false,
        isManuelTherapy:
          payload?.treatmentPlan.treatmentProvided.isManuelTherapy ?? false,
        isTuiNa: payload?.treatmentPlan.treatmentProvided.isTuiNa ?? false,
        isElectromagneticHealthLamp:
          payload?.treatmentPlan.treatmentProvided.isElectromagneticHealthLamp ?? false,
        isEarSeed: payload?.treatmentPlan.treatmentProvided.isEarSeed ?? false,
        isMoxa: payload?.treatmentPlan.treatmentProvided.isMoxa ?? false,
        isHotPack: payload?.treatmentPlan.treatmentProvided.isHotPack ?? false,
        isTherapeuticExercise:
          payload?.treatmentPlan.treatmentProvided.isTherapeuticExercise ?? false,
      },
      insertionPointsAreChinese: {
        isLuHandTaiYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isLuHandTaiYin ?? false,
        isLiHandYangMind:
          payload?.treatmentPlan.insertionPointsAreChinese.isLiHandYangMind ?? false,
        isStFootYangMing:
          payload?.treatmentPlan.insertionPointsAreChinese.isStFootYangMing ?? false,
        isSpFootTaiYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isSpFootTaiYin ?? false,
        isHtHandShaoYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isHtHandShaoYin ?? false,
        isSiHandYaiyang:
          payload?.treatmentPlan.insertionPointsAreChinese.isSiHandYaiyang ?? false,
        isUbFootTaiYang:
          payload?.treatmentPlan.insertionPointsAreChinese.isUbFootTaiYang ?? false,

        isKiFootShaoYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isKiFootShaoYin ?? false,
        isPcHandJueYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isPcHandJueYin ?? false,
        isSjHandShaoYang:
          payload?.treatmentPlan.insertionPointsAreChinese.isSjHandShaoYang ?? false,
        isGbFootShaoYang:
          payload?.treatmentPlan.insertionPointsAreChinese.isGbFootShaoYang ?? false,
        isLivFootJueYin:
          payload?.treatmentPlan.insertionPointsAreChinese.isLivFootJueYin ?? false,
        isDuMeridian:
          payload?.treatmentPlan.insertionPointsAreChinese.isDuMeridian ?? false,
        isRenMeridian:
          payload?.treatmentPlan.insertionPointsAreChinese.isRenMeridian ?? false,
      },
    },
    acupuncturePoints: payload?.acupuncturePoints ?? '',
    provider: payload?.provider ?? '',
    signedBy: payload?.signedBy ?? '',
  };
};
