import { UserProfile } from '../../../types/UserProfileTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const searchApi = api.injectEndpoints({
  endpoints: builder => ({
    usersGetDeepSearch: builder.query<Response<any>, any>({
      query: ({ ...params }) => {
        return {
          url: `/user/deep-search`,
          params: { ...params },
        };
      },
      providesTags: ['User', 'SearchUsers'],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },

      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.lastPage = newItems.lastPage;
          return;
        }

        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    userDeepSearchMut: builder.mutation({
      query: ({ ...params }) => ({
        url: `/user/deep-search`,
        method: 'GET',
        params: { ...params },
      }),
      invalidatesTags: ['User', 'SearchUsers'],
    }),
  }),
});

export const {
  useUsersGetDeepSearchQuery,
  useLazyUsersGetDeepSearchQuery,
  useUserDeepSearchMutMutation,
} = searchApi;
