import * as S from './styles';
import Button from '../../../../atoms/Button';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { useDispatch } from 'react-redux';
import Input from '../../../../atoms/Input';
import { AvatarSVG } from '../../../../../assets/icons';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { UserProfile } from '../../../../../types/UserProfileTypes';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  useNoteCreateMutation,
  useNoteUpdateMutation,
} from '../../../../../store/api/notes/noteApi';
import { useParams } from 'react-router-dom';
import { setMessage } from '../../../../../store/slices/message';
import { Note } from '../../../../../types/NoteTypes';

type Props = {
  onClose: () => void;
  userProfile?: UserProfile;
  editedRow: Note | null;
};

const AddNoteDialog: React.FC<Props> = ({ onClose, userProfile, editedRow }) => {
  const [note, setNote] = useState('');
  const params = useParams();
  const dispatch = useDispatch();

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [createNote, status] = useNoteCreateMutation({});
  const [updateNote, statusUpdate] = useNoteUpdateMutation({});

  const disableButton = useMemo(() => !note, [note]);

  const handleConfirmClick = () => {
    onClose();
    createNote({
      patientId: userProfile?.id,
      clinicId: selectedClinic.id,
      text: note,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Note was successfully created',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleUpdateClick = () => {
    onClose();
    updateNote({
      id: editedRow?.id,
      text: note,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Note was successfully updated',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    if (editedRow) {
      setNote(editedRow.text);
    }
  }, [editedRow]);

  return (
    <S.Content>
      <S.Article>{editedRow ? 'Edit Note' : 'Create Note'}</S.Article>
      <S.Patient>
        <S.PatientTitle>Patient:</S.PatientTitle>
        <S.PatientInfo>
          {userProfile?.avatar ? (
            <img src={userProfile.avatar.url} alt="avatar" />
          ) : (
            <AvatarSVG />
          )}
          {userProfile?.profile.firstName} {userProfile?.profile.lastName}
        </S.PatientInfo>
      </S.Patient>
      <S.InputWrapper>
        <Input
          multiline
          rows={9}
          label="Note Body"
          value={note}
          onChange={handleChangeInput}
        />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button
          text="Save"
          onClick={editedRow ? handleUpdateClick : handleConfirmClick}
          disabled={disableButton}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AddNoteDialog;
