import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerMode } from '../../types/DrawerMode';

type IsDirty = {
  isDirty: boolean;
  isShowDialog: boolean;
  action: () => void;
  objectForUpd?: any;
};

const initialState: IsDirty = {
  isDirty: false,
  isShowDialog: false,
  action: () => {},
  objectForUpd: null,
};

const isDirtySlice = createSlice({
  name: 'isDirty',
  initialState,
  reducers: {
    setIsDirty: (state, action: PayloadAction<IsDirty>) => {
      return {
        ...state,
        isDirty: action.payload.isDirty,
        isShowDialog: action.payload.isShowDialog,
        action: action.payload.action,
      };
    },
  },
});

const { reducer, actions } = isDirtySlice;

export const { setIsDirty } = actions;
export default reducer;
