import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  titleText: string;
  successText?: string;
  helperText?: string;
  reason?: string;
};

const InformationDialog: React.FC<Props> = ({
  onClose,
  titleText,
  onSuccess,
  successText,
  helperText,
  reason,
}) => {
  const handleConfirmClick = () => {
    onClose();
    onSuccess();
  };
  return (
    <S.Content>
      <S.Article>{titleText}</S.Article>
      <S.HelperText>{helperText}</S.HelperText>
      {reason && (
        <S.Reason>
          <S.Span>Reason: </S.Span>
          {reason}
        </S.Reason>
      )}
      <S.Buttons>
        <S.ButtonWrapper>
          <Button text={successText || 'Ok'} onClick={handleConfirmClick} />
        </S.ButtonWrapper>
      </S.Buttons>
    </S.Content>
  );
};

export default InformationDialog;
