import { useDrop } from 'react-dnd';
import { AddCircleBlueSVG } from '../../../../../../../assets/icons';
import { convertAMtoPM } from '../../../../../../../helpers/functions/convertAMtoPM';
import * as S from '../../styles';
import PlannerRecord from '../PlannerRecord';
import { DragTypes } from '../../../../../../../types/DragTypes';
import { Response } from '../../../../../../../types/Response';
import { AppointmentsNew, Visits } from '../../../../../../../types/AppointmentNewTypes';
import {
  useAppointmentUpdateTimeMutation,
  useUpdateVisitServiceMutation,
} from '../../../../../../../store/api/appointment/appointmentApi';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../store/slices/message';
import { showDrawer } from '../../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../../types/DrawerMode';

type Props = {
  allAppointments?: Response<AppointmentsNew>;
  time: string;
  selectedDate: string;
  isWorkDay: boolean;
};

const TimeLine: React.FC<Props> = ({
  allAppointments,
  time,
  selectedDate,
  isWorkDay,
}) => {
  const [updateVisitTime, statusVisitLoading] = useUpdateVisitServiceMutation({});

  const dispatch = useAppDispatch();
  const [{ isActive }, drop] = useDrop(() => ({
    accept: DragTypes.BOX,
    drop: (item: Visits, monitor) => {
      item.startTime.slice(0, 5) !== time.slice(0, 5) &&
        updateVisitTime({
          body: [
            {
              id: item.id,
              startTime: time.slice(0, 5),
              visitLengthMinutes: item.visitLengthMinutes,
            },
          ],
        })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: res.message,
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
    },
    collect: monitor => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }));

  return (
    <S.TimeLine key={time} ref={drop} $isActive={isActive} $isWorkDay={isWorkDay}>
      <S.TimeColumn
        onClick={() =>
          dispatch(
            showDrawer({
              show: true,
              mode: DrawerMode.NEW_APPOINTMENT_TIME,
              props: { time: time, date: selectedDate },
            }),
          )
        }
      >
        <S.TimeText>{convertAMtoPM(time)}</S.TimeText>
        <AddCircleBlueSVG />
      </S.TimeColumn>
      <S.Records>
        <>
          {allAppointments?.rows &&
            allAppointments.rows.flatMap(item =>
              item.visits
                .filter(visit => visit.startTime.slice(0, 5) === time.slice(0, 5))
                .map(visit => (
                  <PlannerRecord
                    key={visit.id}
                    appointment={item}
                    visit={visit}
                    time={time}
                  />
                )),
            )}
        </>
        {isActive && (
          <S.AddRecord>
            <AddCircleBlueSVG />
          </S.AddRecord>
        )}
      </S.Records>
    </S.TimeLine>
  );
};

export default TimeLine;
